import { getToken } from "../../routes";
import { BASE_API_URL } from "../../utils/constants";

const base = BASE_API_URL;

export async function getRoiCampaignApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function getRoiCampaignRevenueApi(path, id) {
  const token = getToken();
  try {
    const url = `${base}${path}?campaignId=${id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function getRoiCampaignAthleteApi(path, id) {
  const token = getToken();
  try {
    const url = `${base}${path}?campaignId=${id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function getClickCountApi(path, id) {
  const token = getToken();
  const campaignId = localStorage.getItem("campaignId");
  try {
    const url = `${base}${path}?campaignId=${campaignId}&deliverableId=${id}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function getTrackLinkApi(path, body) {
  const token = getToken();
  const campaignId = localStorage.getItem("campaignId");
  try {
    const url = `${base}${path}?campaignId=${campaignId}&deliverableId=${body.deliverableId}&athleteId=${body.athleteId}&url=${body.url}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function getTopThreeCampaignsApi(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}
