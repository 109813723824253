import {
  athletesFeatured,
  registerAthlete,
  registrationStage,
  resendOtp,
  updateProfileDetails,
  updatePublicProfile,
  verifyOtp,
  getListApi,
  getAthletesProfileDetailsApi,
  athleteUpdatePhoneNumberApi,
  athleteregistrationDetailsApi,
} from "./api.request";

const ATHLETE_PATH_REGISTRATIONSTAGE = "/athlete/registrationStage";
const ATHLETE_PATH_VERIFYOTP = "/athlete/verifyOtp";
const ATHLETE_PATH_RESENDOTP = "/athlete/resendOtp";
const ATHLETE_PATH_UPDATEPHONENUMBER = "/athlete/updatePhoneNumber";
const ATHLETE_PATH_REGISTER = "/athlete/register";
const ATHLETE_PATH_UPDATEPROFILEDETAILS = "/athlete/updateProfileDetails";
const ATHLETE_PATH_UPDATEPUBLICPROFILE = "/athlete/updatePublicProfile";
const ATHLETE_PATH_FEATURED = "/athlete/featuredAthletes";

const ATHLETE_HOME_BRANDS_PATH = "/brand/brandsActiveCampaigns";
//const ATHLETE_PROFILE_DETAILS_PATH = "/athlete/profileDetails";

export const athleteRegistrationStage = async (body) =>
  registrationStage(`${ATHLETE_PATH_REGISTRATIONSTAGE}`, body);

export const athleteVerifyOtp = async (body) =>
  verifyOtp(`${ATHLETE_PATH_VERIFYOTP}`, body);

export const athleteRegister = async (body) =>
  registerAthlete(`${ATHLETE_PATH_REGISTER}`, body);

export const athleteUpdateProfileDetails = async (body) =>
  updateProfileDetails(`${ATHLETE_PATH_UPDATEPROFILEDETAILS}`, body);

export const athleteUpdatePublicProfile = async (body) =>
  updatePublicProfile(`${ATHLETE_PATH_UPDATEPUBLICPROFILE}`, body);

export const athleteResendOtp = async (body) =>
  resendOtp(`${ATHLETE_PATH_RESENDOTP}`, body);

export const athleteUpdatePhoneNumber = async (body) =>
  athleteUpdatePhoneNumberApi(`${ATHLETE_PATH_UPDATEPHONENUMBER}`, body);

export const featuredAthletes = async () =>
  athletesFeatured(`${ATHLETE_PATH_FEATURED}`);

export const getBrandsList = async (body) =>
  getListApi(`${ATHLETE_HOME_BRANDS_PATH}`, body);

export const getAthletesProfileDetails = async () =>
  getAthletesProfileDetailsApi();
