import { Grid, Typography, Button, useTheme, Box, Stack } from "@mui/material";
import React from "react";

import Pulse from "../../../../themes/@overrides/pulse";
import { PADDING_X, BASE_IMAGE_URL } from "../../../../utils/constants";

import TestimonialsImage1 from "../../../../assets/images/TestimonialsImage1.png";
import TestimonialsImage2 from "../../../../assets/images/TestimonialsImage2.png";
import TestimonialsImage3 from "../../../../assets/images/TestimonialsImage3.png";
import TestimonialsImage4 from "../../../../assets/images/TestimonialsImage4.png";
import TestimonialsImage5 from "../../../../assets/images/TestimonialsImage5.png";
import TestimonialsImage6 from "../../../../assets/images/TestimonialsImage6.png";

import { FormatQuote } from "@mui/icons-material";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Testimonials = () => {
  const theme = useTheme();

  const TESTIMONIALS_IMAGE_LIST = [
    {
      id: 1,
      name: "Victor Rosa, RB at UCONN",
      imgUrl: TestimonialsImage1,
      alt: "Testimonials 1",
      quote: `“Burrst has helped me monetize my NIL, connect further with fans, and start creating global brand awareness.” 
`,
    },
    {
      id: 2,
      name: "Curtis Appleton, Safety at University of Central Missouri ",
      imgUrl: TestimonialsImage2,
      alt: "Testimonials 2",
      quote: `“Amazing opportunity within NIL… check it out!”`,
    },
    {
      id: 3,
      name: "Dimitri Stanley, WR at Iowa State University ",
      imgUrl: TestimonialsImage3,
      alt: "Testimonials 3",
      quote: `“I’m excited about the direction of the sports tech industry and working with Burrst.”
`,
    },
    {
      id: 4,
      name: " Deontae Graham, OL at Jackson State University",
      imgUrl: TestimonialsImage4,
      alt: "Testimonials 4",
      quote: `“Burrst has facilitated everything allowing me to focus on my game and academics.”
`,
    },
    {
      id: 5,
      name: "Isaiah Wooden, WR at Southern Utah",
      imgUrl: TestimonialsImage5,
      alt: "Testimonials 5",
      quote: `“Opportunity to build connections with people on a professional level… propelled my career as a student-athlete.”`,
    },
    {
      id: 6,
      name: "Jaylen Dollar, DE at Elizabeth State University",
      imgUrl: TestimonialsImage6,
      alt: "Testimonials 6",
      quote: `“Being a part of the Burrst family has been tremendous… as an athlete we are always looking for ways to generate more income, make ourselves more marketable and add value.”
`,
    },
  ];

  return (
    <Box
      sx={{
        px: { xs: "10px", sm: PADDING_X },
        fontFamily: theme.fonts.livvic,
        mb: "30px",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="left"
            alignItems="center"
            textAlign="center"
            gap={3}
            mb="20px"
          >
            <Pulse />
            <Typography
              fontFamily={theme.fonts.russoOne}
              color={theme.colors.text.red}
              fontSize={{
                xs: theme.fonts.heading_xs,
                sm: theme.fonts.heading,
                xl: theme.fonts.beforeLogin.heading.xl,
              }}
            >
              <span style={{ color: theme.colors.text.greyDark }}>Our</span>{" "}
              Testimonials
            </Typography>
          </Box>
        </Grid>

        {/* -----------------Athlete Benefits------------------ */}
        <Grid
          item
          xs={12}
          sx={{
            mt: 2,
          }}
        >
          {/* ---------------Image Swiper----------------------- */}

          <Swiper
            pagination={{
              type: "fraction",
              currentClass: "fraction_current_page",
              totalClass: "fraction_total_page",
            }}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1}
            spaceBetween={20}
            autoplay={{
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            initialSlide={1}
            modules={[Pagination, Autoplay]}
            className="testimonial_swiper_container"
            style={{
              "--swiper-pagination-bottom": "10px",
              height: "400px",
              width: "100%",
            }}
          >
            {TESTIMONIALS_IMAGE_LIST?.map((detail, index) => (
              <SwiperSlide
                key={index}
                style={{
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    height: "100%",
                    pl: { xs: "15px", md: "30px" },
                    pr: { xs: "15px", md: "0" },
                    pt: { xs: "15px", md: "30px" },
                    pb: { xs: "0px", md: "30px" },
                    borderLeft: `5px solid rgba(48,48,48,1)`,
                    borderBottom: `5px solid rgba(48,48,48,1)`,
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      background: "rgba(48,48,48,1)",
                      width: "25%",
                      height: "5px",
                    },
                  }}
                >
                  {/* -------------------Contents----------------------- */}
                  <Box
                    sx={{
                      //  background: "blue",
                      height: { xs: "100%", md: "90%" },
                      width: { xs: "100%", md: "60%" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: {
                          xs: theme.fonts.subHeading_xs,
                          md: theme.fonts.subHeading,
                          xl: theme.fonts.beforeLogin.subHeading.xl,
                        },
                        pb: 1,
                        position: "relative",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          background: "white",
                          width: "80px",
                          height: "2px",
                        },
                      }}
                    >
                      {detail?.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: { xs: 3, md: 5 },
                      }}
                    >
                      <FormatQuote
                        sx={{ fontSize: { xs: "26px", md: "32px" }, my: 1 }}
                      />
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: { xs: "14px", md: "18px", xl: "22px" },
                        }}
                      >
                        {detail?.quote}
                      </Typography>
                    </Box>
                  </Box>

                  {/* -----------------------Image--------------------------- */}
                  <Box
                    sx={{
                      // background: "yellow",
                      height: "100%",
                      width: { xs: "100%", md: "40%" },
                      display: "flex",
                      justifyContent: { xs: "center", md: "right" },
                    }}
                  >
                    <Box
                      sx={{
                        background: theme.colors.testimonialsBackground,
                        height: { xs: "300px", md: "365px" },
                        width: "300px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={detail?.imgUrl}
                        alt={detail?.alt}
                        sx={{
                          height: "100%",
                          width: { xs: "70%", sm: "75%", md: "80%" },
                          objectFit: "fill",
                        }}
                      ></Box>
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
