import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import BrandsCard from "../subComponents/brands/brandsCard";
import Pulse from "../../../../../themes/@overrides/pulse";
import { Swiper, SwiperSlide } from "swiper/react";
import { getBrandsList } from "../../../../../services/athlete/athlete.service";
import NoActiveCampaign from "../../../../../assets/icons/NoActiveCampaign.svg";
import { BASE_IMAGE_URL } from "../../../../../utils/constants";

const Brands = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const BrandImage = `${BASE_IMAGE_URL}/BrandImage.png`;

  const [details, setDetails] = useState([]);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);
  const [apiData, setApiData] = useState({
    page: 1,
    size: 15,
  });

  const handleViewAll = () => {
    navigate("/dashboard/explore-brands");
  };

  const fetchData = async () => {
    setIsAllDataLoading(true);
    const { data } = await getBrandsList(apiData);
    setIsAllDataLoading(false);
    setDetails(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        paddingX: "30px",
        "@media (max-width:600px)": {
          paddingX: "10px",
        },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt="60px"
        mb="30px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Pulse />
          <Typography
            color={theme.colors.common.white}
            fontSize={theme.fonts.heading}
            fontFamily={theme.fonts.russoOne}
          >
            Brands
          </Typography>
        </Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.creamLight}
          sx={{ cursor: "pointer" }}
          onClick={handleViewAll}
        >
          View All
        </Typography>
      </Box>

      <Swiper
        grabCursor={true}
        slidesPerView={1.5}
        initialSlide={0}
        breakpoints={{
          0: { slidesPerView: 1, spaceBetween: 20 },
          400: { slidesPerView: 1.5, spaceBetween: 20 },
          500: { slidesPerView: 1.8, spaceBetween: 20 },
          600: { slidesPerView: 2, spaceBetween: 20 },
          700: { slidesPerView: 2.5, spaceBetween: 20 },
          900: { slidesPerView: 2.5, spaceBetween: 20 },
          1000: { slidesPerView: 2.8, spaceBetween: 20 },
          1200: { slidesPerView: 3.5, spaceBetween: 20 },
          1400: { slidesPerView: 4, spaceBetween: 20 },
          1500: { slidesPerView: 4.5, spaceBetween: 20 },
          1600: { slidesPerView: 5, spaceBetween: 20 },
        }}
      >
        {isAllDataLoading ? (
          Array.from({ length: 15 }, (_, index) => (
            <SwiperSlide key={index}>
              <BrandsCard isAllDataLoading={isAllDataLoading} />
            </SwiperSlide>
          ))
        ) : details?.length ? (
          details?.map((detail, index) => (
            <SwiperSlide key={index}>
              <BrandsCard detail={detail} isAllDataLoading={isAllDataLoading} />
            </SwiperSlide>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
              fontSize: { xs: "24px", sm: "30px" },
              minHeight: "200px",
              width: "100%",
              background: "rgba(18,18,18,1)",
              borderRadius: "5px",
            }}
          >
            <Box
              component="img"
              src={BrandImage}
              alt="Brand Image"
              sx={{ width: { xs: "70px", sm: "100px" } }}
            ></Box>
            No brands yet!
          </Box>
        )}
      </Swiper>
    </Box>
  );
};

export default Brands;
