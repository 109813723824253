import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Tooltip as ReactTooltip } from "react-tooltip";

import Sports from "../../../../../../assets/icons/Sports.svg";
import School from "../../../../../../assets/icons/School.svg";
import InstagramIcon from "../../../../../../assets/icons/InstagramPublicProfileIcon.svg";
import FacebookIcon from "../../../../../../assets/icons/FacebookPublicProfileIcon.svg";
import TwitterIcon from "../../../../../../assets/icons/TwitterPublicProfileIcon.svg";
import LinkedInIcon from "../../../../../../assets/icons/LinkedInPublicProfileIcon.svg";
import Youtubecon from "../../../../../../assets/icons/YoutubePublicProfileIcon.svg";
import TiktokIcon from "../../../../../../assets/icons/TikTokPublicProfileIcon.svg";
import BurrstLogoIcon from "../../../../../../assets/icons/BurrstLogoIcon.svg";
import DefaultImg from "../../../../../../assets/images/DefaultAvatar.jpg";
import InterestedIcon from "../../../../../../assets/icons/InterestediIcon.svg";
import {
  getappliedAthletes,
  removeApplication,
  respondToApplicant,
} from "../../../../../../services/campaign/campaign.service";

const ViewAplicantsCard = ({
  detail,
  callApi,
  setSelectedApplicantIds,
  setIgnoreApplicantIds,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showAcceptIgnoreButtons, setShowAcceptIgnoreButtons] = useState(true);
  const handleAcceptClick = async () => {
    setSelectedApplicantIds((prevIds) => [...prevIds, detail?._id]);
    setShowAcceptIgnoreButtons(false);
  };

  const handleWithdraw = async () => {
    setSelectedApplicantIds((prevIds) =>
      prevIds?.filter((prevId) => prevId !== detail?._id)
    );
    setShowAcceptIgnoreButtons(true);
  };

  const handleIgnore = async () => {
    setIgnoreApplicantIds((prevIds) => [...prevIds, detail?._id]);
    setShowAcceptIgnoreButtons(false);
  };

  const handleProfile = () => {
    navigate("/dashboard/home/athlete-profile", {
      state: {
        detail: detail,
      },
    });
  };

  return (
    <Box
      sx={{
        width: { xs: "90%", md: "42%" },
        my: "20px",
        mx: "auto",
        border: `1px solid grey`,
        background: theme.colors.common.black,
        overflow: "hidden",
      }}
    >
      <Box
        p="30px"
        // data-aos="fade-up"
        // data-aos-duration="1500"
        overflow="hidden"
      >
        <Box width="100%" display="flex" justifyContent="end">
          {detail?.interested ? (
            <>
              <Box
                data-tooltip-content={"Interested"}
                data-tooltip-id="my-tooltip"
                component="img"
                src={InterestedIcon}
                alt="Interested Icon"
                id="interestedIcon"
                sx={{ cursor: "pointer" }}
              ></Box>
              <ReactTooltip
                id="my-tooltip"
                place="left"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  fontFamily: theme.fonts.livvic,
                  fontSize: "16px",
                  fontWeight: 500,
                  backgroundColor: "#FFECEC",
                  color: "#AB0404",
                }}
                clickable={false}
                arrowColor="#FFECEC"
                border={true}
              />
            </>
          ) : (
            <Box height="20px"></Box>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            onClick={() => handleProfile()}
            sx={{
              width: "100px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Box
              component="img"
              src={detail.profileImage || DefaultImg}
              alt="Avatar"
              id="avatar"
              sx={{ width: "100px", height: "100px" }}
              onError={(e) => {
                e.target.src = DefaultImg;
              }}
            ></Box>
            <Box
              component="img"
              src={BurrstLogoIcon}
              alt="Burrst Logo Icon"
              sx={{
                position: "absolute",
                top: "-30%",
                right: "-30%",
              }}
              id="Burrst Logo Icon"
            ></Box>
          </Box>
          <Typography
            onClick={() => handleProfile()}
            variant="h4"
            mt={2}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightMedium}
            fontSize="24px"
            sx={{ cursor: "pointer" }}
          >
            {detail.firstName} {detail.lastName}
          </Typography>
        </Box>

        <Box display="flex" gap={1} mt={2}>
          <Box component="img" src={Sports} alt={`Sports ${detail._id}`}></Box>
          <Typography fontFamily={theme.fonts.livvic}>
            Sports: {detail.sportsList.slice(0, 3).join(", ")}
            {detail.sportsList.length > 3 && " ..."}
          </Typography>
        </Box>
        <Box display="flex" gap={1} my={2}>
          <Box component="img" src={School} alt={`Sports ${detail._id}`}></Box>
          <Typography fontFamily={theme.fonts.livvic}>
            School: {detail.school}
          </Typography>
        </Box>

        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} mb={3}>
          {detail?.instagramLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.instagramLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={InstagramIcon}
                  alt="Instagram Icon"
                />
              </Box>
              {detail?.instagramFollowerCount && (
                <Typography>
                  {detail?.instagramFollowerCount < 1000
                    ? detail?.instagramFollowerCount
                    : detail?.instagramFollowerCount < 1000000
                    ? (detail?.instagramFollowerCount % 1000 === 0
                        ? (detail?.instagramFollowerCount / 1000).toFixed(0)
                        : (detail?.instagramFollowerCount / 1000).toFixed(1)) +
                      "K"
                    : detail?.instagramFollowerCount % 1000000 === 0
                    ? (detail?.instagramFollowerCount / 1000000).toFixed(0) +
                      "M"
                    : (detail?.instagramFollowerCount / 1000000).toFixed(1) +
                      "M"}
                </Typography>
              )}
            </Box>
          )}
          {detail?.facebookLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.facebookLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={FacebookIcon}
                  alt="Facebook Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {detail?.twitterLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.twitterLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TwitterIcon}
                  alt="Twitter Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {detail?.linkedinLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.linkedinLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={LinkedInIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {/* -----------------Youtube Icon---------------------- */}
          {detail?.youtubeLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.youtubeLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={Youtubecon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {/* -----------------Tiktok Icon---------------------- */}
          {detail?.tiktokLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.tiktokLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TiktokIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              {detail?.tiktokFollowerCount && (
                <Typography>
                  {detail?.tiktokFollowerCount < 1000
                    ? detail?.tiktokFollowerCount
                    : detail?.tiktokFollowerCount < 1000000
                    ? (detail?.tiktokFollowerCount % 1000 === 0
                        ? (detail?.tiktokFollowerCount / 1000).toFixed(0)
                        : (detail?.tiktokFollowerCount / 1000).toFixed(1)) + "K"
                    : detail?.tiktokFollowerCount % 1000000 === 0
                    ? (detail?.tiktokFollowerCount / 1000000).toFixed(0) + "M"
                    : (detail?.tiktokFollowerCount / 1000000).toFixed(1) + "M"}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          width="100%"
          gap={1}
        >
          {showAcceptIgnoreButtons && (
            <>
              <Button
                onClick={handleIgnore}
                sx={{
                  width: "47%",
                  border: "1px solid #797979",
                  color: "#797979",
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                  textTransform: "none",
                  fontSize: "16px",
                }}
              >
                Ignore
              </Button>
              <Button
                onClick={handleAcceptClick}
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                  textTransform: "none",
                  fontSize: "16px",
                  width: "47%",
                  color: "white",
                  background: "#F30000",
                  border: "1px solid #F30000",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
              >
                Accept
              </Button>
            </>
          )}
          {!showAcceptIgnoreButtons && (
            <Button
              onClick={handleWithdraw}
              sx={{
                fontFamily: theme.fonts.livvic,
                fontWeight: 500,
                textTransform: "none",
                fontSize: "18px",
                width: "94%",
                color: "white",
                background: "#F30000",
                border: "1px solid #F30000",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
            >
              Withdraw
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ViewAplicantsCard;
