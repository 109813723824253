import axios from "axios";
import { useSelector } from "react-redux";
import { getToken } from "../../routes";
import { BASE_API_URL, USER_ID } from "../../utils/constants";

const base = BASE_API_URL;

const handleError = (error) => {
  if (error?.response?.data?.status === 500) return "Internal Server Error";
  else return error?.response?.data?.message || "Some Error Occurred";
};

export async function registrationStage(path, registrationToken) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${registrationToken}`,
      },
      body: JSON.stringify({
        registrationToken: registrationToken,
      }),
    });

    const results = await response.json();

    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}

export async function verifyOtp(path, body) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        registrationToken: body.registrationToken,
        phoneNo: body.phoneNo,
        phoneOtp: body.phoneOtp,
      }),
    });
    const results = await response.json();

    // if (response.status !== 200) {
    //   throw new Error(`API request failed with status ${response.status}`);
    // }

    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export async function registerAthlete(path, registrationData) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registrationData),
    });

    const results = await response.json();

    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function updateProfileDetails(path, formData) {
  try {
    const url = `${base}${path}`;
    const form = new FormData();

    for (const key in formData) {
      form.append(key, formData[key]);
    }

    // for (const key of Object.keys(formData)) {
    //   switch (key) {
    //     case "profileImage":
    //       if (formData.profileImage) {
    //         form.append("profileImage", formData.profileImage);
    //       }
    //       break;

    //     case "interests":
    //       form.append("interests", (formData.interests));
    //       break;

    //     case "sports":
    //       form.append("sports",(formData.sports));
    //       break;

    //     default:
    //       form.append(key, formData[key]);
    //       break;
    //   }
    // }

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      body: form,
    });

    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function updatePublicProfile(path, formData) {
  try {
    const url = `${base}${path}`;
    const form = new FormData();
    for (const key of Object.keys(formData)) {
      switch (key) {
        case "coverImage":
          if (formData?.coverImage) {
            form.append("coverImage", formData.coverImage);
          }
          break;
        case "videos":
          for (let i = 0; i < formData?.videos?.length; i++) {
            form.append("videos", formData?.videos[i]);
          }
          break;
        case "publicImages":
          for (let i = 0; i < formData?.publicImages?.length; i++) {
            form.append("publicImages", formData?.publicImages[i]);
          }
          break;
        default:
          form.append(key, formData[key]);
          break;
      }
    }
    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      body: form,
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function resendOtp(path, body) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: body.userId,
        phoneNo: body.phoneNo,
      }),
    });
    const results = await response.json();

    if (response.status !== 200) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export const athleteUpdatePhoneNumberApi = async (path, body) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios.put(url, body, {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const athletesFeatured = async (path) => {
  try {
    const url = `${base}${path}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const getListApi = async (path, body) => {
  const token = getToken();
  try {
    const url = `${base}${path}?page=${
      body?.page ? `${body?.page}` : ""
    }&size=${body?.size ? `${body?.size}` : ""}&search=${
      body?.search ? `${body?.search}` : ""
    }`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (results.status !== 200) {
      return { success: false, message: results.message };
    }
    return results;
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export async function getAthletesProfileDetailsApi(userId) {
  const token = getToken();
  const athleteId = !userId ? localStorage.getItem(USER_ID) : userId;
  try {
    const url = `${base}/athlete/profileDetails?athleteId=${athleteId}`;
    const response = await axios(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
}
