import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../../assets/icons/CrossIcon.svg";
import {
  brandChangePlan,
  brandCurrentSubscriptionPlans,
  brandSubscriptionPlans,
  getContactSales,
} from "../../../../services/brand/brand.service";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { authExchangeToken } from "../../../../services/auth/auth.service";
import { login } from "../../../../store/authSlice";

const ChangeSubscriptionModal = ({ handleCloseDialog, formData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedBox, setSelectedBox] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [planName, setPlanName] = useState(null);

  const handleBoxClick = (boxNumber, planId, planName) => {
    setSelectedBox(boxNumber);
    setSelectedId(planId);
    setPlanName(planName);
  };

  const handleSubmit = async () => {
    if (currentPlan?._id === selectedId) {
      toast.error("Select other plan");
      return;
    }
    if (planName === "SUPERSTAR") {
      const response = await getContactSales();
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
      } else toast.error(response);
    }
    setLoading(true);
    const response = await brandChangePlan(selectedId);
    setLoading(false);

    if (response?.status === 200) {
      handleCloseDialog();
      toast.success("Plan changed");
      const { data } = await authExchangeToken();
      if (data?.status === 200) {
        dispatch(
          login({
            userData: {
              token: data?.data?.token,
              usertype: data?.data?.usertype,
              userId: data?.data?.userId,
              plan: data?.data?.plan,
              isBrandAdmin: data?.data?.isBrandAdmin,
            },
          })
        );
      }
    } else {
      toast.error(response?.message || response?.status);
      console.error("Failed to change plan response:", response?.message);
    }
  };

  const currentPlanApi = async () => {
    setLoading(true);
    const response = await brandCurrentSubscriptionPlans();
    if (response?.status === 200) {
      setLoading(false);
      setCurrentPlan(response?.data);
      setSelectedBox(
        formData?.findIndex((plan) => plan?._id === response?.data?._id)
      );
      setSelectedId(response?.data?._id);
    } else {
      setLoading(false);
      console.error("Failed brandCurrentSubscriptionPlans:", response?.message);
    }
  };

  useEffect(() => {
    currentPlanApi();
  }, []);

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer", width: "20px" }}
        onClick={handleCloseDialog}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          py: "40px",
        }}
      >
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          sx={{ fontSize: { xs: "22px", md: "24px" } }}
        >
          Change Plan
        </Typography>
        <Typography fontFamily={theme.fonts.livvic}>
          Your new plan will be applied from the next billing date. To change
          your plan, choose one of the options below.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          mb="15px"
          mt="40px"
          gap={3}
        >
          {formData?.map((plan, index) => (
            <Box
              key={index}
              onClick={() => handleBoxClick(index, plan?._id, plan?.planName)}
              backgroundColor={selectedBox === index ? "#163315" : "#272727"}
              p="8px"
              border={selectedBox === index ? "1px solid #4BED45" : ""}
              sx={{ cursor: "pointer", width: { xs: "200px", md: "250px" } }}
            >
              {selectedBox === index ? (
                <Brightness1Icon sx={{ color: "#4BED45" }} />
              ) : (
                <CircleOutlinedIcon sx={{ color: "#A0A0A0" }} />
              )}
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontSize: { xs: "18px", md: "22px" },
                    fontFamily: theme.fonts.livvic,
                    fontWeight: 500,
                  }}
                >
                  {plan?.planName.charAt(0).toUpperCase() +
                    plan?.planName.slice(1)}
                </Typography>
                {(plan?.planName === "STARTER" ||
                  plan?.planName === "ALL-STAR") && (
                  <Typography
                    sx={{
                      fontSize: { xs: "30px", md: "38px" },
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "30px",
                        fontWeight: 400,
                        fontSize: "18px",
                      }}
                    >
                      $
                    </span>
                    {(plan?.amount / plan?.intervalCount).toFixed(2)}
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: "18px",
                      }}
                    >
                      /month
                    </span>
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontSize: { xs: "9px", md: "13px" },
                    fontFamily: theme.fonts.livvic,
                    fontWeight: 500,
                    mb: "10px",
                  }}
                >
                  {plan?.amount} Billed {plan?.planName}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="center" mt="30px">
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              background: "red",
              width: { xs: "160px", sm: "260px" },
              mt: 1,
              height: "45px",
              textTransform: "none",
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: { xs: "14px", sm: "18px" },
              fontFamily: theme.fonts.livvic,
              "&:hover": {
                background: theme.colors.divider.red,
              },
            }}
          >
            {loading ? <CircularProgress sx={{ color: "gray" }} /> : "SUBMIT"}
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default ChangeSubscriptionModal;
