import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";
import SingleAthleteIcon from "../../../../../assets/icons/SingleAthleteIcon.svg";
import SingleAthleteDisabledIcon from "../../../../../assets/icons/SingleAthleteDisabledIcon.svg";
import MultipleAthleteIcon from "../../../../../assets/icons/MultipleAthleteIcon.svg";
import MultipleAthleteDisabledIcon from "../../../../../assets/icons/MultipleAthleteDisabledIcon.svg";
import {
  createCampaign,
  getCampaignDetails,
  getCampaignPaymentModelList,
  updateCampaign,
} from "../../../../../services/campaign/campaign.service";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const campaignTypeList = [
  "Appearances",
  "Social Media Campaigns",
  "Brand Ambassador",
  "Non Profits & Causes",
  "Others",
];

const CampaignBasicInfo = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isBack = location?.state?.isBack;

  const [selectedValue, setSelectedValue] = useState("singleAthlete");
  const [paymentModelList, setPaymentModelList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({});

  const handleRadioButtonChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (
      formData?.paymentModel === "Revenue Share" &&
      value === "multipleAthlete"
    ) {
      setSelectedValue("singleAthlete");
    } else {
      setFormData((prevSelections) => ({
        ...prevSelections,
        multipleAthletes: value === "multipleAthlete",
      }));
    }
  };

  const handleBack = () => {
    navigate("/dashboard/campaigns");
  };

  const handleSelectionsChange = (field, value) => {
    if (field === "paymentModel" && value === "Revenue Share") {
      setSelectedValue("singleAthlete");
      setFormData((prevSelections) => ({
        ...prevSelections,
        [field]: value,
        multipleAthletes: false,
      }));
    } else {
      setFormData((prevSelections) => ({
        ...prevSelections,
        [field]: value,
      }));
    }
  };

  const handleNext = async () => {
    if (
      !formData.campaignName ||
      !formData.startDate ||
      !formData.paymentModel ||
      !formData.campaignType
    ) {
      toast.error("Please fill in all the required fields");
      return;
    }

    setLoading(true);
    let response = null;
    if (!formData?.endDate) {
      delete formData?.endDate;
    }
    if (isBack) {
      response = await updateCampaign(formData);
      if (response?.status === 200) {
        navigate("/dashboard/campaigns/additional-info", {
          state: { isBack: true },
        });
      } else {
        toast.error(response?.message || response?.status);
      }
    } else {
      response = await createCampaign(formData);
      if (response?.status === 200) {
        localStorage.setItem("campaignId", response?.data?.campaignId);
        navigate("/dashboard/campaigns/additional-info");
      } else {
        toast.error(response?.message || response?.status);
      }
    }
    setLoading(false);
  };

  const fetchCampaignData = async () => {
    const response = await getCampaignDetails();
    const updatedData = response?.data;
    setFormData((preValue) => ({
      ...preValue,
      campaignStage: "1",
      multipleAthletes: updatedData?.multipleAthletes
        ? updatedData?.multipleAthletes
        : selectedValue === "multipleAthlete",
      campaignName: updatedData?.campaignName || "",
      startDate: updatedData?.startDate ? dayjs(updatedData?.startDate) : null,
      endDate: updatedData?.endDate ? dayjs(updatedData?.endDate) : null,
      paymentModel: updatedData?.paymentModel,
      campaignType: updatedData?.campaignType,
      campaignId: updatedData?._id || "",
    }));
    if (updatedData?.multipleAthletes) setSelectedValue("multipleAthlete");
    else setSelectedValue("singleAthlete");
  };

  const fetchPaymentModelList = async () => {
    const response = await getCampaignPaymentModelList();
    if (response?.status === 200) {
      setPaymentModelList(response?.data);
    } else {
      toast.error(response?.message);
    }
  };

  useEffect(() => {
    fetchPaymentModelList();
    if (isBack) fetchCampaignData();
    else
      setFormData((preValue) => ({
        ...preValue,
        multipleAthletes: selectedValue === "multipleAthlete",
        campaignStage: "1",
      }));
  }, []);

  return (
    <Box
      sx={{
        px: { xs: "10px", sm: "30px" },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt="115px"
        mb="30px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Box
            component="img"
            src={LeftArrow}
            alt="Left Arrow"
            width="30px"
            sx={{ cursor: "pointer" }}
            onClick={handleBack}
          ></Box>
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.russoOne}
            fontSize={theme.fonts.heading}
          >
            New Campaign
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            color: "rgba(175,172,172,1)",
          }}
        >
          Step{" "}
          <span
            style={{
              color: "white",
            }}
          >
            1
          </span>
          /4
        </Typography>
      </Box>
      <Box
        my="30px"
        p="20px"
        overflow="hidden"
        sx={{
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Box
          sx={{
            width: "80%",
            mx: "auto",
            py: "30px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Campaign Name
              </Typography>

              <TextField
                name="campaignName"
                placeholder="Campaign Name"
                fullWidth
                value={formData?.campaignName}
                onChange={(e) =>
                  handleSelectionsChange("campaignName", e.target.value)
                }
                InputProps={{
                  sx: {
                    border: `1px solid ${theme.colors.text.greyLight}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Start Date
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="startDate"
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  value={formData?.startDate || null}
                  onChange={(selectedDate) => {
                    handleSelectionsChange(
                      "startDate",
                      dayjs(selectedDate).format("MM/DD/YYYY")
                    );
                    setFormData((preValue) => ({ ...preValue, endDate: "" }));
                  }}
                  disablePast
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                    width: "100% !important",
                    ".MuiInputBase-root,button": {
                      color: theme.colors.text.greyLight,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          border: "2px solid white",
                          borderRadius: theme.fonts.smallBorderRadius,
                        },
                      },
                    },
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.colors.common.white,
                          backgroundColor: theme.colors.background.formInput,
                        },
                        ".MuiPickersCalendarHeader-switchViewButton": {
                          color: theme.colors.common.white,
                        },
                        ".MuiDayCalendar-weekDayLabel": {
                          color: theme.colors.common.white,
                        },
                        ".MuiPickersDay-root": {
                          color: theme.colors.text.greyLight,
                          "&.Mui-disabled": {
                            color: theme.colors.text.greyDarker,
                          },
                        },
                        ".MuiSvgIcon-root": {
                          color: "white",
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                End Date
                <span
                  style={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: "16px",
                    fontWeight: theme.fonts.fontWeightRegular,
                    color: theme.colors.text.greyDark,
                  }}
                >
                  (Optional)
                </span>
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="endDate"
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  value={formData?.endDate || null}
                  onChange={(selectedDate) =>
                    handleSelectionsChange(
                      "endDate",
                      dayjs(selectedDate).format("MM/DD/YYYY")
                    )
                  }
                  shouldDisableDate={(date) => {
                    const startDate = formData?.startDate
                      ? dayjs(formData.startDate)
                      : null;

                    return (
                      startDate && date.isBefore(startDate.add(1, "day"), "day")
                    );
                  }}
                  disablePast
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                    width: "100% !important",
                    ".MuiInputBase-root,button": {
                      color: theme.colors.text.greyLight,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          border: "2px solid white",
                          borderRadius: theme.fonts.smallBorderRadius,
                        },
                      },
                    },
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.colors.common.white,
                          backgroundColor: theme.colors.background.formInput,
                        },
                        ".MuiPickersCalendarHeader-switchViewButton": {
                          color: theme.colors.common.white,
                        },
                        ".MuiDayCalendar-weekDayLabel": {
                          color: theme.colors.common.white,
                        },
                        ".MuiPickersDay-root": {
                          color: theme.colors.text.greyLight,
                          "&.Mui-disabled": {
                            color: theme.colors.text.greyDarker,
                          },
                        },
                        ".MuiSvgIcon-root": {
                          color: "white",
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Payment Model
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="paymentModel"
                options={paymentModelList || []}
                value={formData?.paymentModel || ""}
                onChange={(event, newValue) =>
                  handleSelectionsChange("paymentModel", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography
                  id="numberOfAthlete"
                  fullWidth
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "20px",
                    fontFamily: theme.fonts.livvic,
                    marginBottom: "1%",
                    color: "white",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Numbers of athlete(s)
                </Typography>

                <RadioGroup
                  aria-labelledby="numberOfAthlete"
                  defaultValue="singleAthlete"
                  name="radio-buttons-group"
                  value={selectedValue}
                  onChange={handleRadioButtonChange}
                  sx={{ width: "100%" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      background:
                        selectedValue === "singleAthlete"
                          ? "rgba(143, 65, 65, 1)"
                          : "#282828",
                      color:
                        selectedValue === "singleAthlete" ? "white" : "#7F7F7F",
                      borderRadius: theme.fonts.smallBorderRadius,
                      p: "5px 10px",
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleRadioButtonChange({
                        target: { value: "singleAthlete" },
                      })
                    }
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <img
                        src={
                          selectedValue === "singleAthlete"
                            ? SingleAthleteIcon
                            : SingleAthleteDisabledIcon
                        }
                        alt="Single Athlete"
                      />
                      Single Athlete
                    </Box>
                    <Radio
                      value="singleAthlete"
                      checked={selectedValue === "singleAthlete"}
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "rgba(243, 0, 0, 1)",
                        },
                      }}
                    />
                  </Box>

                  {formData?.paymentModel === "Revenue Share" ? (
                    <Box
                      sx={{
                        width: "100%",
                        background:
                          selectedValue === "multipleAthlete"
                            ? "rgba(143, 65, 65, 1)"
                            : "#282828",
                        color:
                          formData?.paymentModel === "Paid"
                            ? "white"
                            : "#7F7F7F",
                        borderRadius: theme.fonts.smallBorderRadius,
                        p: "5px 10px",
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        "Revenue share model only supports single athlete. Unable to select multiple athletes. Choose a different model for multiple athletes."
                      }
                      onClick={() =>
                        handleRadioButtonChange({
                          target: { value: "multipleAthlete" },
                        })
                      }
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          src={
                            formData?.paymentModel === "Paid"
                              ? MultipleAthleteIcon
                              : MultipleAthleteDisabledIcon
                          }
                          alt="Multiple Athletes"
                        />
                        Multiple Athletes
                      </Box>
                      <Radio
                        value="multipleAthlete"
                        checked={selectedValue === "multipleAthlete"}
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "rgba(243, 0, 0, 1)",
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        background:
                          selectedValue === "multipleAthlete"
                            ? "rgba(143, 65, 65, 1)"
                            : "#282828",
                        color:
                          selectedValue === "multipleAthlete"
                            ? "white"
                            : "#7F7F7F",
                        borderRadius: theme.fonts.smallBorderRadius,
                        p: "5px 10px",
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleRadioButtonChange({
                          target: { value: "multipleAthlete" },
                        })
                      }
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <img
                          src={
                            selectedValue === "multipleAthlete"
                              ? MultipleAthleteIcon
                              : MultipleAthleteDisabledIcon
                          }
                          alt="Multiple Athletes"
                        />
                        Multiple Athletes
                      </Box>
                      <Radio
                        value="multipleAthlete"
                        checked={selectedValue === "multipleAthlete"}
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "rgba(243, 0, 0, 1)",
                          },
                        }}
                      />
                    </Box>
                  )}
                </RadioGroup>
                <ReactTooltip
                  id="my-tooltip"
                  place="top-end"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    fontFamily: theme.fonts.livvic,
                    maxWidth: "100%",
                    fontSize: "16px",
                    backgroundColor: "#CCCCCC",
                    color: "black",
                  }}
                  clickable={false}
                  arrowColor="#CCCCCC"
                  border={true}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Campaign Type
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="campaignType"
                options={campaignTypeList || []}
                value={formData?.campaignType || ""}
                onChange={(event, newValue) =>
                  handleSelectionsChange("campaignType", newValue)
                }
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  p: "5px 10px",
                  mt: "20px",
                  height: "42px",
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.colors.common.white,
                  background: theme.colors.text.red,
                  cursor: "pointer",
                }}
                onClick={handleNext}
              >
                {loading ? <CircularProgress sx={{ color: "gray" }} /> : "Next"}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignBasicInfo;
