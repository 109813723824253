import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: false,
  userData: {
    token: null,
    usertype: null,
    userId: null,
    isBrandAdmin: false,
    plan: null,
  },
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.status = true;
      state.userData = action.payload.userData;
      localStorage.setItem("token", action?.payload?.userData?.token);
      localStorage.setItem("usertype", action.payload.userData?.usertype);
      localStorage.setItem("userId", action.payload.userData?.userId);
      localStorage.setItem("plan", action?.payload?.userData?.plan);
      localStorage.setItem(
        "isBrandAdmin",
        action?.payload?.userData?.isBrandAdmin
      );
    },

    logout: (state) => {
      state.status = false;
      state.userData = null;
      localStorage.clear();
      localStorage.removeItem("persist:root");
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
