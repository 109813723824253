import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

import EducationBackground from "../../../../../assets/images/EducationBackground.png";
import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";
import { Circle } from "@mui/icons-material";

const Education = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleClick = () => {
    window.open(
      "https://nilcertifications.cleankonnect.com/offers/AGdr9QzL?coupon_code=BURRST",
      "_blank"
    );
  };

  const offerDetailsList = [
    "Learn how to maximize NIL opportunities",
    "Get in front of Gen Z audiences ",
    "Understand the constantly changing NIL landscape",
    "Discover the events that sparked the transformative changes on July 1st 2021",
    "Uncover key data points about NIL ",
    "Explore the tax implications associated with NIL activities",
    "Unlock the added value of an NIL certification included at the end of the course",
  ];
  return (
    <Box
      sx={{
        paddingX: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
      }}
    >
      {/* -----------------Header------------------------- */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: "30px" }}>
        <Box
          component="img"
          src={LeftArrow}
          alt="Left Arrow"
          sx={{ cursor: "pointer", width: { xs: "30px", sm: "40px" } }}
          onClick={handleBack}
        ></Box>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontSize: theme.fonts.heading,
          }}
        >
          Education
        </Typography>
      </Box>

      <Box
        p="20px"
        sx={{
          background: theme.colors.dashboard.grey200,
          overflow: "hidden",
        }}
      >
        {/* -------------------Education Background Image------------------- */}
        <Box
          sx={{
            position: "relative",
            height: { xs: "25vh", sm: "35vh" },
            mb: "30px",
          }}
        >
          <Box
            component="img"
            src={EducationBackground}
            //   onError={handleImageError}
            alt="Education Background"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          ></Box>
          <Box
            sx={{
              position: "absolute",
              top: { xs: "25%", sm: "27%", xl: "32%" },
              left: "20px",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: { xs: "40px", sm: "60px" },
              }}
            >
              20%
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: { xs: "14px", sm: "20px" },
                }}
              >
                Discount using
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: { xs: "14px", sm: "20px" },
                }}
              >
                code{" "}
                <span
                  style={{
                    color: "red",
                    fontWeight: theme.fonts.fontWeightBold,
                  }}
                >
                  "BURRST"
                </span>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "10px",
              left: "20px",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Circle
              sx={{
                color: theme.colors.text.greyLight,
                fontSize: "10px",
              }}
            />
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: "12px",
                color: theme.colors.text.greyLight,
              }}
            >
              Terms & conditions applied
            </Typography>
          </Box>
        </Box>

        {/* ---------------------Description Container------------------------- */}

        <Box
          sx={{
            background: theme.colors.common.black,
            p: "20px",
            borderRadius: "10px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: theme.fonts.subHeading,
                mb: 2,
              }}
            >
              About CleanKonnect
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                color: theme.colors.text.greyLight,
                mb: 1,
              }}
            >
              Every aspect of modern life is going digital, including sports.
              While momentum is building slowly, there are several new
              opportunities brought by Web3 in both amateur and professional
              sectors.
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                color: theme.colors.text.greyLight,
                mb: 3,
              }}
            >
              Sports tech is fast-growing, with Web3 infrastructure being the
              key to unlocking new digital markets for the industry and access
              to younger generation
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: theme.fonts.subHeading,
                mb: 1,
              }}
            >
              NIL Certification Course
            </Typography>
            <List>
              {offerDetailsList?.map((element, index) => (
                <Box sx={{ display: "flex", alignItems: "center" }} key={index}>
                  <Circle
                    sx={{
                      color: theme.colors.text.greyLight,
                      fontSize: "10px",
                    }}
                  />
                  <ListItem sx={{ color: theme.colors.text.greyLight }}>
                    {element}
                  </ListItem>
                </Box>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              mt: 3,
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "red",
                width: { xs: "120px", sm: "150px" },
                mt: 1,
                height: "45px",
                textTransform: "none",
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: { xs: "14px", sm: "18px" },
                fontFamily: theme.fonts.livvic,
                cursor: "pointer",
                "&:hover": {
                  background: theme.colors.divider.red,
                },
              }}
              onClick={handleClick}
            >
              CLAIM
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Education;
