import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";

import DeleteBankIcon from "../../../../assets/icons/DeleteBankIcon.svg";
import SBIBankIcon from "../../../../assets/icons/SBIBankIcon.svg";
import HDFCBankIcon from "../../../../assets/icons/HDFCBankIcon.svg";
import DoneIcon from "@mui/icons-material/Done";
import {
  deleteAccount,
  getBankDetails,
  setDefaultAccount,
} from "../../../../services/accounts/accounts.service";
import { ToastContainer, toast } from "react-toastify";
import {
  brandChangePlan,
  startTrial,
} from "../../../../services/brand/brand.service";
import NoRoiDetailsIcon from "../../../../assets/icons/NoRoiDetailsIcon.svg";

const MyAccounts = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [bankDetails, setBankDetails] = useState([]);
  const [primaryAccount, setPrimaryAccount] = useState({});
  const [showSaveButton, setShowSaveButton] = useState(true);
  const backState = localStorage.getItem("route");

  const handleBack = () => {
    if (backState === "profile") {
      navigate("/dashboard/home/my-profile", {
        state: {
          isMyProfile: true,
        },
      });
      localStorage.removeItem("route");
      return;
    } else if (backState === "subscription") {
      navigate("/subscription");
      localStorage.removeItem("route");
      return;
    } else if (backState === "dashbordPlaidConnectModal") {
      navigate("/dashboard/home");
      localStorage.removeItem("route");
      return;
    } else if (backState === "viewDetailsPlaidConnectModal") {
      navigate(
        `/dashboard/campaigns/view-details/${location?.state?.campaignId}`
      );
      localStorage.removeItem("route");
      return;
    } else if (backState === "brandListPlaidConnectModal") {
      navigate("/dashboard/explore-brands/brands-list", {
        state: {
          back: "brandList",
        },
      });
      localStorage.removeItem("route");
      return;
    } else if (backState === "gracePeriod") {
      navigate("/dashboard/home");
      localStorage.removeItem("route");
      return;
    } else if (backState === "aiRecomPlaidConnectModal") {
      navigate("/dashboard/campaigns", {
        state: {
          back: "home",
        },
      });
      localStorage.removeItem("route");
      return;
    } else {
      navigate(-1);
    }
  };

  const subscriptionId = localStorage.getItem("subscriptionId");
  const trialStarted = location?.state?.trialStarted;

  const handleAddIcon = () => {
    navigate("/bank-details");
  };

  const handlePrimaryAccount = (index) => {
    const newData = bankDetails?.map((item, i) => ({
      ...item,
      primary: i === index ? !item?.primary : false,
    }));
    setBankDetails(newData);
    setPrimaryAccount(newData?.find((detail) => detail?.primary === true));
  };

  const handleAccountDelete = async (accountId) => {
    const response = await deleteAccount(accountId);
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
      fetchAccountDetails();
    } else toast.error(response);
  };

  const handlePrimaryAccountDelete = () => {
    setBankDetails(
      bankDetails?.map((item) => ({
        ...item,
        primary: false,
      }))
    );
    setPrimaryAccount(null);
  };
  const freeTrial = true;

  const handleContinue = async () => {
    // window.location.replace("burrst://bankList");
    handleSave();
    if (subscriptionId) {
      try {
        let response = {};
        if (!trialStarted) {
          response = await startTrial(subscriptionId);
        } else {
          response = await brandChangePlan(subscriptionId);
        }
        if (response?.status === 200) {
          if (!trialStarted) {
            localStorage.removeItem("subscriptionId");
            localStorage.setItem("freeTrial", freeTrial);
            navigate("/dashboard/home");
          } else {
            navigate("/dashboard/home/my-subscription");
          }
        } else {
          console.error(
            "Failed to fetch start trial response:",
            response?.message
          );
        }
      } catch (error) {
        console.error("Error fetching start trial response:", error);
      }
    } else if (backState === "gracePeriod") {
      navigate("/dashboard/home");
      localStorage.removeItem("route");
      return;
    } else {
      localStorage.removeItem("route");
      navigate("/dashboard/home/my-profile", {
        state: {
          isMyProfile: true,
        },
      });
    }
  };

  const handleSave = async (defaultPrimaryAccount) => {
    if (defaultPrimaryAccount || primaryAccount) {
      const response = await setDefaultAccount(
        defaultPrimaryAccount?.accountId || primaryAccount?.accountId
      );
      if (response?.data?.status === 200) {
        if (!defaultPrimaryAccount) toast.success(response?.data?.message);
        setShowSaveButton(false);
      } else toast.error(response);
    } else toast.error("Select One Primary Bank Account");
  };

  const fetchAccountDetails = async () => {
    const { data } = await getBankDetails();
    setBankDetails(data?.data);
    const primaryAccount = data?.data?.find(
      (detail) => detail?.primary === true
    );
    setPrimaryAccount(primaryAccount);
    if (primaryAccount && primaryAccount?.primary) setShowSaveButton(false);
    else if (!primaryAccount && !primaryAccount?.primary) {
      setPrimaryAccount(data?.data[0]);
      handleSave(data?.data[0]);
    }
  };

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  return (
    <Box
      mt="50px"
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* --BankIcon----------Top Header Icons-------------------------- */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box>
            <ArrowBackIcon
              onClick={handleBack}
              sx={{
                cursor: "pointer",
                fontSize: { xs: "30px", sm: "40px" },
                color: "white",
              }}
            />
          </Box>
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.russoOne}
            fontSize={{ xs: "24px", sm: theme.fonts.heading }}
          >
            My Accounts
          </Typography>
        </Box>

        <Box
          sx={{
            cursor: "pointer",
            background: "red",
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "30px",
            height: "30px",
            p: "5px",
            borderRadius: "50%",
          }}
          onClick={handleAddIcon}
        >
          <AddIcon />
        </Box>
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          my: "30px",
          px: "20px",
          pt: "40px",
          pb: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container spacing={3}>
          {primaryAccount?.accountId && (
            <Grid item xs={12}>
              <Typography
                fontWeight={theme.fonts.fontWeightBold}
                fontFamily={theme.fonts.livvic}
                variant="h5"
              >
                Primary Account
              </Typography>
            </Grid>
          )}

          {primaryAccount?.accountId && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "70px",
                    background: "black",
                    p: 1,
                    border: "1px solid grey",
                    borderRadius: "5px",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontFamily: theme.fonts.montserrat,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        fontWeight={theme.fonts.fontWeightBold}
                        fontFamily={theme.fonts.livvic}
                      >
                        {`***********${primaryAccount?.mask}`}
                      </Typography>
                      <Typography fontFamily={theme.fonts.livvic}>
                        {primaryAccount?.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "50%",
                        width: "25px",
                        height: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "rgba(7,93,5,1)",
                      }}
                    >
                      <DoneIcon
                        sx={{
                          color: "white",
                          zIndex: 1,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "70px",
                    height: "70px",
                    background: "black",
                    p: 1,
                    border: "1px solid grey",
                    borderRadius: "5px",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontFamily: theme.fonts.montserrat,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handlePrimaryAccountDelete}
                >
                  <img
                    src={DeleteBankIcon}
                    alt="Delete icon"
                    style={{
                      cursor: "pointer",
                      width: "30px",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography
              fontWeight={theme.fonts.fontWeightBold}
              fontFamily={theme.fonts.livvic}
              variant="h5"
            >
              Accounts
            </Typography>
          </Grid>

          {bankDetails?.map((bankDetail, index) => (
            <Grid item xs={12} key={index}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "70px",
                    background: "black",
                    p: 1,
                    border: "1px solid grey",
                    borderRadius: "5px",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontFamily: theme.fonts.montserrat,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                  onClick={() => handlePrimaryAccount(index)}
                >
                  {/* <Box
                    component="img"
                    src={HDFCBankIcon}
                    alt="HDFC Bank Icon"
                    height="100%"
                  ></Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        fontWeight={theme.fonts.fontWeightBold}
                        fontFamily={theme.fonts.livvic}
                      >
                        {`***********${bankDetail?.mask}`}
                      </Typography>
                      <Typography fontFamily={theme.fonts.livvic}>
                        {/* HDFC Bank */}
                        {bankDetail?.name}
                      </Typography>
                    </Box>
                    {bankDetail?.primary ? (
                      <Box
                        sx={{
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(7,93,5,1)",
                        }}
                      >
                        <DoneIcon
                          sx={{
                            color: "white",
                            zIndex: 1,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          borderRadius: "50%",
                          border: `2px solid ${theme.colors.text.greyDarker}`,
                          width: "25px",
                          height: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      ></Box>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "70px",
                    height: "70px",
                    background: "black",
                    p: 1,
                    border: "1px solid grey",
                    borderRadius: "5px",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontFamily: theme.fonts.montserrat,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleAccountDelete(bankDetail?.accountId)}
                >
                  <img
                    src={DeleteBankIcon}
                    alt="Delete icon"
                    style={{
                      cursor: "pointer",
                      width: "30px",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          ))}

          {bankDetails?.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width="100%"
              py="50px"
              px="10px"
            >
              <Box
                component="img"
                src={NoRoiDetailsIcon}
                alt="Burrst Logo Icon"
                sx={{
                  mb: "30px",
                  width: "70px",
                }}
                id="NoRoiDetailsIcon"
              ></Box>
              <Typography
                sx={{
                  fontWeight: theme.fonts.fontWeightMedium,
                  fontFamily: theme.fonts.livvic,
                  fontSize: { xs: "16px", md: "20px" },
                }}
              >
                You haven't added any account yet. Press the '+' button to add a
                new one.
              </Typography>
            </Box>
          )}
          {bankDetails?.length > 0 && (
            <Grid item xs={12}>
              {showSaveButton || !primaryAccount ? (
                <Button
                  variant="contained"
                  disabled={!bankDetails?.length}
                  sx={{
                    background: "red",
                    width: "150px",
                    textTransform: "none",
                    float: "right",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "16px",
                    fontFamily: theme.fonts.livvic,
                    "&:hover": {
                      background: theme.colors.divider.red,
                    },
                    "&.MuiButton-root.Mui-disabled": {
                      background: "rgba(255,0,0,0.6)",
                      color: "white",
                    },
                  }}
                  onClick={handleSave}
                >
                  SAVE
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    background: "red",
                    width: "150px",
                    textTransform: "none",
                    float: "right",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "16px",
                    fontFamily: theme.fonts.livvic,
                    "&:hover": {
                      background: theme.colors.divider.red,
                    },
                  }}
                  onClick={handleContinue}
                >
                  CONTINUE
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default MyAccounts;
