const Joi = require("joi");

const Schema = Joi.object({
  email: Joi.string(),
  temporaryPassword: Joi.string().required().messages({
    "any.required": `Temporary password is required`,
    "string.empty": `Temporary password is required`,
  }),
  newPassword: Joi.string()
    .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    .required()
    .messages({
      "any.required": `Password is required`,
      "string.empty": `Password is required`,
      "string.pattern.base": `Password is Invalid`,
    }),
});

export default Schema;
