import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Sports from "../../../../../../assets/icons/Sports.svg";
import School from "../../../../../../assets/icons/School.svg";

import InstagramIcon from "../../../../../../assets/icons/InstagramPublicProfileIcon.svg";
import FacebookIcon from "../../../../../../assets/icons/FacebookPublicProfileIcon.svg";
import TwitterIcon from "../../../../../../assets/icons/TwitterPublicProfileIcon.svg";
import LinkedInIcon from "../../../../../../assets/icons/LinkedInPublicProfileIcon.svg";
import Youtubecon from "../../../../../../assets/icons/YoutubePublicProfileIcon.svg";
import TiktokIcon from "../../../../../../assets/icons/TikTokPublicProfileIcon.svg";
import AthletesBrand from "../../../../../../assets/icons/AthletesBrandIcon.svg";
import BurrstLogoIcon from "../../../../../../assets/icons/BurrstLogoIcon.svg";
import DefaultImg from "../../../../../../assets/images/DefaultAvatar.jpg";

// import AIRecommended from "../../../../../../assets/images/AIRecommended.png";
import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import { useNavigate } from "react-router-dom";

const AthletesCard = ({ detail }) => {
  const theme = useTheme();
  const AIRecommended = `${BASE_IMAGE_URL}/AIRecommended.png`;
  const navigate = useNavigate();

  const socialMediaIconStyle = {
    backgroundColor: theme.colors.background.socialMedia,
    height: "18px",
    width: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    boxShadow: "0 0 6px -2px white",
    cursor: "pointer",
    "&:hover": {
      background: theme.colors.background.socialMediaLight,
      color: theme.colors.text.linkedIn,
      transition: "all 0.2s ease",
    },
  };

  const handleCoverImg = (e) => {
    e.target.src = DefaultImg;
  };

  const handleProfile = () => {
    navigate("/dashboard/home/athlete-profile", {
      state: {
        detail: detail,
      },
    });
  };
  return (
    <Box
      sx={{
        // width: "350px",
        // height: "auto",
        // mx: "auto",
        position: "relative",
        border: `1.6px solid grey`,
        backgroundColor: "rgba(18,18,18,1)",
        "&:hover": {
          "#athleteCover": {
            transform: "scale(1.05)",
            transition: "0.5s ease",
          },
        },
      }}
    >
      {/* -------------------------------Cover Image and Ai Recommended Image---------------------------- */}
      <Box
        sx={{
          overflow: "hidden",
          height: { xs: "230px", md: "220px" },
          width: "100%",
          // height: "25vh",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src={detail?.coverImage}
          alt="Cover Image"
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          onError={handleCoverImg}
        ></Box>
        {detail?.aiRecommended && (
          <Box
            component="img"
            src={AIRecommended}
            alt="AI Recommended"
            id="aiRecommended"
            sx={{
              height: "auto",
              width: "100px",
              position: "absolute",
              top: "0",
              right: "0",
            }}
          ></Box>
        )}
      </Box>

      {/* --------------------------Below Cover Image--------------------------- */}
      <Box m="0px 10px" pb="10px">
        {/* -----------Avatar and Name------------- */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: 1,
            mt: -4,
            mb: 1,
            width: "100%",
          }}
        >
          <Box
            onClick={handleProfile}
            sx={{
              width: "70px",
              height: "70px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Box
              component="img"
              src={detail?.profileImage || DefaultImg}
              alt="Avatar"
              id="avatar"
              sx={{ width: "70px", height: "70px", objectFit: "fill" }}
              onError={(e) => {
                e.target.src = DefaultImg;
              }}
            ></Box>
            <Box
              component="img"
              src={BurrstLogoIcon}
              alt="Burrst Logo Icon"
              sx={{
                position: "absolute",
                top: "-25px",
                right: "-25px",
                width: "50px",
                height: "50px",
              }}
              id="Burrst Logo Icon"
            ></Box>
          </Box>
          <Box sx={{ width: "calc(100% - 70px)" }}>
            <Typography
              mt={3}
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightMedium}
              sx={{
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={handleProfile}
            >
              {detail?.name}
            </Typography>
          </Box>
        </Box>

        {/* -------------Contents------------- */}
        <Box
          onClick={handleProfile}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            cursor: "pointer",
          }}
        >
          <Box component="img" src={Sports} alt={`Sports ${detail?._id}`}></Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "13px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Sports: {detail?.sportsList?.slice(0, 2).join(", ")}
          </Typography>
        </Box>
        <Box
          onClick={handleProfile}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            cursor: "pointer",
          }}
        >
          <Box component="img" src={School} alt={`Sports ${detail?._id}`}></Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "13px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            School: {detail?.school}
          </Typography>
        </Box>

        {/* --------------Social Media Icons--------------- */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            height: "30px",
            mb: 1.2,
            px: "2px",
            width: "100%",
            whiteSpace: "nowrap",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {/* -----------------Instagram Icon---------------------- */}
          {detail?.instagramLink && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Box
                sx={socialMediaIconStyle}
                onClick={() => window.open(detail?.instagramLink, "_blank")}
              >
                <img
                  width="10px"
                  height="10px"
                  src={InstagramIcon}
                  alt="Instagram Icon"
                />
              </Box>
              {detail?.instagramFollowerCount && (
                <Typography
                  sx={{ fontFamily: theme.fonts.livvic, fontSize: "12px" }}
                >
                  {detail?.instagramFollowerCount < 1000
                    ? detail?.instagramFollowerCount
                    : detail?.instagramFollowerCount < 1000000
                    ? (detail?.instagramFollowerCount % 1000 === 0
                        ? (detail?.instagramFollowerCount / 1000).toFixed(0)
                        : (detail?.instagramFollowerCount / 1000).toFixed(1)) +
                      "K"
                    : detail?.instagramFollowerCount % 1000000 === 0
                    ? (detail?.instagramFollowerCount / 1000000).toFixed(0) +
                      "M"
                    : (detail?.instagramFollowerCount / 1000000).toFixed(1) +
                      "M"}
                </Typography>
              )}
            </Box>
          )}

          {/* -----------------Facebook Icon---------------------- */}
          {detail?.facebookLink && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Box
                sx={socialMediaIconStyle}
                onClick={() => window.open(detail?.facebookLink, "_blank")}
              >
                <img
                  width="12px"
                  height="12px"
                  src={FacebookIcon}
                  alt="Facebook Icon"
                />
              </Box>
              <Typography
                sx={{ fontFamily: theme.fonts.livvic, fontSize: "12px" }}
              >
                N/A
              </Typography>
            </Box>
          )}

          {/* -----------------Twitter Icon---------------------- */}
          {detail?.twitterLink && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Box
                sx={socialMediaIconStyle}
                onClick={() => window.open(detail?.twitterLink, "_blank")}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TwitterIcon}
                  alt="Twitter Icon"
                />
              </Box>
              <Typography
                sx={{ fontFamily: theme.fonts.livvic, fontSize: "12px" }}
              >
                N/A
              </Typography>
            </Box>
          )}
          {/* -----------------Twitter Icon---------------------- */}
          {detail?.linkedinLink && (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Box
                sx={socialMediaIconStyle}
                onClick={() => window.open(detail?.linkedinLink, "_blank")}
              >
                <img
                  width="12px"
                  height="12px"
                  src={LinkedInIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography
                sx={{ fontFamily: theme.fonts.livvic, fontSize: "12px" }}
              >
                N/A
              </Typography>
            </Box>
          )}
          {/* -----------------Youtube Icon---------------------- */}
          {detail?.youtubeLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.youtubeLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={Youtubecon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {/* -----------------Tiktok Icon---------------------- */}
          {detail?.tiktokLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.tiktokLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TiktokIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              {detail?.tiktokFollowerCount && (
                <Typography>
                  {detail?.tiktokFollowerCount < 1000
                    ? detail?.tiktokFollowerCount
                    : detail?.tiktokFollowerCount < 1000000
                    ? (detail?.tiktokFollowerCount % 1000 === 0
                        ? (detail?.tiktokFollowerCount / 1000).toFixed(0)
                        : (detail?.tiktokFollowerCount / 1000).toFixed(1)) + "K"
                    : detail?.tiktokFollowerCount % 1000000 === 0
                    ? (detail?.tiktokFollowerCount / 1000000).toFixed(0) + "M"
                    : (detail?.tiktokFollowerCount / 1000000).toFixed(1) + "M"}
                </Typography>
              )}
            </Box>
          )}
        </Box>

        {/* -------------Matching Campaigns-------------- */}
        {detail?.matchingCampaigns?.length > 0 ? (
          <Box position="relative">
            <Box
              sx={{
                overflowY: "scroll",
                height: "115px",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "red",
                  borderRadius: "4px",
                },
                padding: "10px",
                border: `1px solid ${theme.colors.background.red100}`,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -10,
                  left: "calc((100% - 125px) / 2)",
                  background: "black",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    color: theme.colors.text.greyLight,
                    fontSize: "12px",
                    width: "125px",
                  }}
                >
                  Matching Campaign
                </Typography>
              </Box>
              {detail?.matchingCampaigns?.map((data, index) => (
                <Box key={index} mt={0.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      background: theme.colors.dashboard.grey100,
                      p: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "30px",
                      }}
                    >
                      <Box
                        component="img"
                        src={data?.brandLogo}
                        alt="Athletes Brand"
                        sx={{
                          width: "100%",
                          height: "100%",
                          background: "rgba(226, 224, 224, 1)",
                          objectFit: "fill",
                        }}
                        id="athletesBrand"
                      ></Box>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        width: "calc(100% - 40px)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: "14px",
                      }}
                    >
                      {data?.campaignName}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #1F1F1F",
                      background: "#0A0909",
                      p: "5px",
                      height: "50px",
                      display: "-webkit-box",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{ color: "white", fontSize: "14px" }}
                    >
                      {data?.brief}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              padding: "5px 10px",
              border: `1px solid ${theme.colors.background.red100}`,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              height: "115px",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: -10,
                left: "calc((100% - 125px) / 2)",
                background: "black",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  color: theme.colors.text.greyLight,
                  fontSize: "12px",
                  width: "125px",
                }}
              >
                Matching Campaign
              </Typography>
            </Box>
            <Typography
              color={theme.colors.text.greyLight}
              mt="40px"
              fontFamily={theme.fonts.livvic}
            >
              No Matching Campaign
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AthletesCard;
