import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import HeroBackground from "../../../../assets/images/Herobg.png";
import HeroMobileBackground from "../../../../assets/TestVideos/HeroMobileBackground.gif";
import HeroBubbleBackground from "../../../../assets/TestVideos/HeroBubbleBackground.gif";

import {
  NAVBAR_HEIGHT,
  BASE_IMAGE_URL,
  BASE_VIDEO_URL,
} from "../../../../utils/constants";

const Hero = () => {
  const theme = useTheme();
  const videoRef = useRef();

  const [os, setOs] = useState("");

  const heightOfHeroSection = "90vh";
  // const heightOfHeroSection_xs = "90vh";
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const Iphone15ProFrame1 = `${BASE_IMAGE_URL}/Iphone15ProFrame1.png`;

  const HeroMobileBackgroundVideoMp4 = `${BASE_VIDEO_URL}/heroMobileBackgroundVideo.mp4`;
  const HeroMobileBackgroundVideoWebM = `${BASE_VIDEO_URL}/webmp_heroMobileBackgroundVideo.webm`;

  const handleLoadedVideo = () => {
    setIsLoading(false);
  };

  const handleJoinTheBurrstFamily = () => {
    navigate("/login");
  };
  // alert(window.navigator.userAgent);
  const detectOS = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      setOs("Android");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOs("iOS");
    } else {
      setOs("Unknown");
    }
  };

  const playVideo = async () => {
    if (videoRef?.current) {
      try {
        videoRef.current.setAttribute("muted", "true");
        videoRef.current.muted = true;
        await videoRef.current.play();
      } catch (err) {
        console.log(err, "video play error");
      }
    }
  };

  useEffect(() => {
    detectOS();
    // playVideo();
  }, []);

  return (
    <Box
      sx={{
        mt: NAVBAR_HEIGHT,
        minHeight: heightOfHeroSection,
        position: "relative",
      }}
    >
      {/* <video
        id="background-video"
        ref={videoRef}
        loop
        muted
        autoPlay
        playsInline
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          transform: "rotate(180deg)",
        }}
      >
        <source src={HeroBackgroundVideo} type="video/mp4" />
      </video> */}

      <Box
        component="img"
        src={HeroBubbleBackground}
        sx={{
          width: "100%",
          height: { xs: "95vh", sm: "100%" },
          objectFit: "cover",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
          transform: "rotate(180deg)",
        }}
      ></Box>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "flex-start", sm: "center" },
            height: { xs: "55vh", sm: heightOfHeroSection },
          }}
        >
          <Box
            component="img"
            src={HeroBackground}
            alt="Hero Background"
            sx={{
              width: "100%",
              height: { xs: "95vh", sm: heightOfHeroSection },
              position: "absolute",
              left: 0,
              top: 0,
              objectFit: "cover",
              opacity: 0.6,
            }}
          ></Box>
          <Box
            sx={{
              width: { xs: "90%", sm: "70%" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
            data-aos="fade-right"
          >
            <Typography
              variant="h3"
              sx={{
                textTransform: "uppercase",
                color: theme.colors.text.red,
                fontFamily: theme.fonts.russoOne,
                "@media (max-width:480px)": {
                  fontSize: "32px",
                },
              }}
            >
              <Box component="span" color={theme.colors.text.greyLight}>
                The Next Generation
              </Box>
              {/* {<br />}
              of Superstars */}
            </Typography>
            <Typography
              variant="h4"
              fontFamily={theme.fonts.workSans}
              fontStyle="italic"
              fontWeight="bold"
              sx={{
                "@media (max-width:480px)": {
                  fontSize: "24px",
                },
              }}
            >
              Data-driven NIL Marketplace
            </Typography>
            <Typography
              textTransform="capitalize"
              fontFamily={theme.fonts.workSans}
              fontSize={{ xl: "20px" }}
            >
              Providing NIL Opportunities for Student-Athletes and ROI NIL
              Analytics for Brands.
            </Typography>
            <Button
              sx={{
                background: theme.colors.gradientBlackRed,
                color: theme.colors.common.white,
                fontFamily: theme.fonts.livvic,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "16px",
                maxWidth: "252px",
                height: "58px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                textTransform: "uppercase",
              }}
              onClick={handleJoinTheBurrstFamily}
            >
              Join The Burrst Family
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            height: { xs: "40vh", sm: heightOfHeroSection },
            // width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", lg: "left" },
          }}
        >
          <Box
            sx={{
              height: { xs: "100%", sm: "80%", lg: "500px" },
              // width: "100%",
            }}
            data-aos="fade-left"
          >
            <Box
              sx={{
                // width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", lg: "left" },
              }}
            >
              {isLoading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: { xs: "50%", lg: "95px" },
                    transform: {
                      xs: "translate(-50%,-50%)",
                      lg: "translate(0%,-50%)",
                    },
                  }}
                >
                  <CircularProgress
                    sx={{ color: theme.colors.text.greyLight }}
                  />
                </Box>
              )}

              <Box
                component="img"
                src={Iphone15ProFrame1}
                alt="Iphone Mobile"
                sx={{
                  height: "100%",
                  backgroundSize: "cover",
                  borderRadius: { xs: "20px", sm: "30px" },
                  position: "absolute",
                  left: { xs: "50%", lg: "-8px" },
                  top: { xs: "50%", lg: "0%" },
                  transform: { xs: "translate(-50%,-50%)", lg: "none" },
                  zIndex: 1,
                }}
              ></Box>

              <Box
                sx={{
                  height: "98%",
                  borderRadius: { xs: "20px", sm: "45px" },
                }}
              >
                {os === "iOS" ? (
                  <Box
                    component="img"
                    src={HeroMobileBackground}
                    onLoad={handleLoadedVideo}
                    sx={{
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: { xs: "20px", sm: "45px" },
                      display: isLoading ? "none" : "block",
                    }}
                  ></Box>
                ) : (
                  <video
                    id="background-video"
                    ref={videoRef}
                    autoPlay
                    muted
                    loop
                    playsInline
                    onLoadedData={handleLoadedVideo}
                    onPlaying={handleLoadedVideo}
                    style={{
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "20px",
                      // display: isLoading ? "none" : "block",
                    }}
                  >
                    <source
                      src={HeroMobileBackgroundVideoWebM}
                      type="video/webm"
                    />
                    <source
                      src={HeroMobileBackgroundVideoMp4}
                      type="video/mp4"
                    />
                  </video>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
