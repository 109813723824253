import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

import PodcastsPlayer from "../../../../../assets/icons/PodcastsPlayer.svg";
import CalendarIcon from "../../../../../assets/icons/CalendarIcon.svg";
import StopwatchIcon from "../../../../../assets/icons/StopwatchIcon.svg";
import DownloadIcon from "../../../../../assets/icons/DownloadIcon.svg";

import { keyframes } from "@emotion/react";

import { BASE_IMAGE_URL } from "../../../../../utils/constants";
import dayjs from "dayjs";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const PodcastsCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();

  const player = keyframes`

 100%{
    transform:scale(1.05);
  }
`;

  const PodcastsNextGeneration = `${BASE_IMAGE_URL}/PodcastsNextGeneration.png`;

  const handleMusicPlayer = () => {
    window.open(detail?.audio_url, "_blank");
  };

  return (
    <Box
      p="10px"
      sx={{
        border: `1px solid grey`,
        borderRadius: "10px",
        background: theme.colors.common.black,
        overflow: "hidden",
        width: { xs: "350px", sm: "450px", md: "100%" },
        // minHeight: { lg: "400px" },
        "&:hover": {
          "#podcastsPlayer": {
            animation: `${player} 0.5s ease-out infinite`,
          },
        },
      }}
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      {/* -----------Next Geneartion and Player------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        <Box sx={{ width: "50%" }}>
          {isAllDataLoading ? (
            <CustomSkeleton type="img" sx={{ width: "100%" }} />
          ) : (
            <Box
              component="img"
              src={detail?.artwork_url}
              alt="Podcasts Next Generation"
              id="nextGeneration"
              sx={{ width: "100%" }}
            ></Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.published_at && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Box
                    component="img"
                    src={CalendarIcon}
                    alt="Calendar Icon"
                  ></Box>
                  <Typography
                    variant="h4"
                    fontFamily={theme.fonts.livvic}
                    fontSize="12px"
                  >
                    {/* Feb 27, 2023 */}
                    {dayjs(detail?.published_at).format("MMM DD YYYY")}
                  </Typography>
                </Box>
              )
            )}
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Box
                  component="img"
                  src={StopwatchIcon}
                  alt="Stopwatch Icon"
                ></Box>
                <Typography
                  variant="h4"
                  fontFamily={theme.fonts.livvic}
                  fontSize="12px"
                >
                  {/* 24:08 */}
                  {(detail?.duration / 60).toFixed(2)}
                </Typography>
              </Box>
            )}
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Box
                  component="img"
                  src={DownloadIcon}
                  alt="Download Icon"
                ></Box>
                <Typography
                  variant="h4"
                  fontFamily={theme.fonts.livvic}
                  fontSize="12px"
                >
                  {/* 59 */}
                  {detail?.total_plays}
                </Typography>
              </Box>
            )}
          </Box>
          {isAllDataLoading ? (
            <CustomSkeleton type="img" />
          ) : (
            <Box
              component="img"
              src={PodcastsPlayer}
              alt="Podcasts Player"
              id="podcastsPlayer"
              height="100%"
              sx={{ cursor: "pointer" }}
              data-aos="fade-left"
              data-aos-duration="1500"
              onClick={handleMusicPlayer}
            ></Box>
          )}
        </Box>
      </Box>

      {/* -------------Heading------------- */}

      <Box sx={{ my: 1, height: { md: "50px" } }}>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
        >
          {/* #32 Brooke Donabedian - Women's Gymnastics at Temple University */}
          {isAllDataLoading ? (
            <>
              <CustomSkeleton type="p" sx={{ minWidth: "100%" }} />
              <CustomSkeleton type="p" />
            </>
          ) : (
            detail?.title
          )}
        </Typography>
      </Box>

      {/* -------------Description-------------- */}
      <Box
        sx={{
          p: "0 10px 0 15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "145px",
          background: theme.colors.dashboard.grey100,
        }}
      >
        <Box
          sx={{
            height: "125px",
            width: "100%",
            pr: "10px",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "black",
            },
            "&::-webkit-scrollbar-thumb": {
              background: theme.colors.text.red,
              borderRadius: "4px",
            },
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton
              type="p"
              count={4}
              sx={{ minWidth: "100%", mt: 1.8 }}
            />
          ) : (
            <Typography
              fontSize="14px"
              fontWeight={400}
              fontFamily={theme.fonts.livvic}
              dangerouslySetInnerHTML={{ __html: detail?.description }}
            ></Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PodcastsCard;
