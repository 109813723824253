import io from "socket.io-client";
import { BURRST_SITE, HOST_URL } from "./constants";

let chatSocket = null;

const initChatSocket = () => {
  const token = localStorage.getItem("token");
  chatSocket = io("wss://admin.burrst.io/messaging", {
    // autoConnect: false,
    transports: ["websocket"],
    path: HOST_URL === BURRST_SITE ? "/socket.io" : "/sandbox",
    reconnectionDelayMax: 10000,
    auth: {
      token: token,
    },
  });
};

export { chatSocket, initChatSocket };
