const Joi = require("joi");

const Schema = Joi.object({
  firstName: Joi.string()
    .required()
    .regex(/^[a-zA-Z\s.'\-À-ÖØ-öø-ÿ]*$/)
    .trim()
    .max(30)
    .messages({
      "string.max": `First name must be at most 30 characters long`,
      "any.required": `First name is required`,
      "string.empty": `First name is required`,
      "string.pattern.base": `First name must contains only alphabets`,
    }),
  lastName: Joi.string()
    .required()
    .regex(/^[a-zA-Z\s.'\-À-ÖØ-öø-ÿ]*$/)
    .trim()
    .max(30)
    .messages({
      "string.max": `Last name must be at most 30 characters long`,
      "any.required": `Last name is required`,
      "string.empty": `Last name is required`,
      "string.pattern.base": `Last name must contains only alphabets`,
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(50)
    .required()
    .messages({
      "any.required": `Email is required`,
      "string.empty": `Email is required`,
      "string.pattern.base": `Email is Invalid`,
      "string.max": `Email must be at most 50 characters long`,
    }),
  countryCode: Joi.string(),
  phoneNo: Joi.string().regex(/^\d+$/).required().min(7).max(15).messages({
    "string.max": `Phone number must be at most 15 characters long`,
    "string.min": `Phone number must be minimum 7 characters long`,
    "any.required": `Phone number is required`,
    "string.empty": `Phone number is required`,
    "string.pattern.base": `Phone number is Invalid`,
  }),
  password: Joi.string()
    .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    .required()
    .messages({
      "any.required": `Password is required`,
      "string.empty": `Password is invalid`,
      "string.pattern.base": `Password is Invalid`,
    }),
});

export default Schema;
