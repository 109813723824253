import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
  useTheme,
} from "@mui/material";

import { Helmet } from "react-helmet-async";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";

import AddDeliverableModal from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/addDeliverable.modal";

import DashboardDeliverableCard from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/campaigns.deliverable.card";
import {
  deleteDeliverable,
  getCampaignDetails,
} from "../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const CampaignsDeliverable = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const isBack = location?.state?.isBack;
  const campaignId = localStorage.getItem("campaignId");

  const [deliverableModalOpen, setDeliverableModalOpen] = useState(false);
  const [paymentModel, setPaymentModel] = useState([]);
  const [deliverables, setDeliverables] = useState([]);
  const [editDeliverable, setEditDeliverable] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const handleBack = () => {
    navigate("/dashboard/campaigns/additional-info", {
      state: {
        isBack: true,
      },
    });
  };

  const handleDeliverableModalClose = () => {
    setDeliverableModalOpen(false);
  };

  const handleDeliverableModalOpen = () => {
    setDeliverableModalOpen(true);
  };

  const handleEdit = (deliverable) => {
    setEditDeliverable(deliverable);
    handleDeliverableModalOpen();
  };

  const handleDelete = async (deliverableId) => {
    if (deliverables.length > 1) {
      const response = await deleteDeliverable({ campaignId, deliverableId });
      if (response?.status === 200) {
        toast.success("Deliverable deleted successfully.");
        setIsAdded(!isAdded);
      } else toast.error("Deliverable Deletion Failed.");
    } else toast.error("You cannot delete the only remaining deliverable");
  };

  const handleNext = async () => {
    if (isBack) {
      navigate("/dashboard/campaigns/contract", {
        state: { isBack: true },
      });
    } else {
      navigate("/dashboard/campaigns/contract");
    }
  };

  const totalCompensation = deliverables?.reduce((total, deliverable) => {
    const compensation = parseFloat(deliverable?.compensation) || 0;
    return total + compensation;
  }, 0);

  const fetchCampaignData = async () => {
    setDataLoading(true);
    const response = await getCampaignDetails();
    setDataLoading(false);
    if (response?.status === 200) {
      setPaymentModel(response?.data?.paymentModel);
      setDeliverables(response?.data?.deliverables);
    } else {
      toast.error(response?.message);
    }
  };

  useEffect(() => {
    fetchCampaignData();
  }, [isAdded]);

  return (
    <>
      <Helmet>
        <title>Campaigns | Burrst</title>
      </Helmet>
      <Box
        sx={{
          px: { xs: "10px", sm: "30px" },
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          mt="115px"
        >
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
            <Box
              component="img"
              src={LeftArrow}
              alt="Left Arrow"
              width="30px"
              sx={{ cursor: "pointer" }}
              onClick={handleBack}
            ></Box>
            <Typography
              color={theme.colors.common.white}
              fontFamily={theme.fonts.russoOne}
              fontSize={theme.fonts.heading}
            >
              New Campaign
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              color: "rgba(175,172,172,1)",
            }}
          >
            Step{" "}
            <span
              style={{
                color: "white",
              }}
            >
              3
            </span>
            /4
          </Typography>
        </Box>
        <Box
          mb="30px"
          mt="10px"
          overflow="hidden"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            background: theme.colors.dashboard.grey200,
            minHeight: "60vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              p: 3,
            }}
          >
            {!dataLoading && (
              <Box
                sx={{
                  borderRadius: "5px",
                  p: "5px 10px",
                  height: "42px",
                  width: "200px",
                  float: "right",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.colors.divider.red,
                  background: "rgba(255,230,230,1)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleDeliverableModalOpen();
                  setEditDeliverable(null);
                }}
              >
                + Add new deliverable
              </Box>
            )}
          </Box>
          {dataLoading ? (
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
              alignItems="center"
              gap="40px"
              width="100%"
              px="70px"
              mb="20px"
            >
              <CustomSkeleton
                count={2}
                sx={{
                  width: { xs: "100%", md: "30%" },
                  height: "350px",
                  borderRadius: "5px",
                }}
              />
            </Box>
          ) : deliverables?.length ? (
            <Box
              container
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
              sx={{
                width: "100%",
                height: "50vh",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "rgba(47,47,47,1)",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "grey",
                  borderRadius: "4px",
                },
              }}
            >
              {deliverables?.map((detail, index) => (
                <DashboardDeliverableCard
                  detail={detail}
                  paymentModel={paymentModel}
                  key={index}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                height: "30vh",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: "33px",
                  color: theme.colors.text.greyDarker,
                }}
              >
                No deliverable item to show
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#0E0E0E",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              p: "10px 40px 5px",
              boxShadow: "2px -10px 35px -18px #FFFFFF40",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                fontFamily={theme.fonts.livvic}
                color="#AFACAC"
                fontWeight={theme.fonts.fontWeightMedium}
                sx={{ fontSize: { xs: "16px", md: "18px" } }}
              >
                Total -
              </Typography>
              {paymentModel === "Revenue Share" ? (
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontWeight={600}
                  color="#FF0101"
                  sx={{ fontSize: { xs: "18px", md: "20px" } }}
                >
                  {`${(totalCompensation / (deliverables?.length || 1)).toFixed(
                    2
                  )}`}
                  %{" "}
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "#D9D9D9",
                    }}
                  >
                    of monthly revenue
                  </span>
                </Typography>
              ) : (
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontWeight={600}
                  color="#FF0101"
                  sx={{ fontSize: { xs: "18px", md: "20px" } }}
                >
                  ${totalCompensation}
                </Typography>
              )}
            </Box>
            <Button
              sx={{
                borderRadius: "5px",
                p: "5px 10px",
                my: "20px",
                height: "42px",
                width: "170px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: theme.colors.common.white,
                background: theme.colors.text.red,
                "&:hover": {
                  background: "rgba(255,0,0,0.6)",
                },
                "&.MuiButton-root.Mui-disabled": {
                  color: theme.colors.text.greyDark,
                  background: "rgba(67,67,67,1)",
                },
              }}
              disabled={!deliverables?.length}
              onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={deliverableModalOpen}
        PaperProps={{
          sx: {
            minWidth: "50vw",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <AddDeliverableModal
          paymentModel={paymentModel}
          handleDeliverableModalClose={handleDeliverableModalClose}
          isAdded={isAdded}
          setIsAdded={setIsAdded}
          editDeliverable={editDeliverable}
          campaignId={campaignId}
        />
      </Dialog>
    </>
  );
};

export default CampaignsDeliverable;
