import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import DefaultBrandLogo from "../../../../assets/logo/DefaultBrandLogo.png";
import ReactApexChart from "react-apexcharts";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";
import { useNavigate } from "react-router-dom";

const AthleteDealTrackerCard = ({ details, isAllDataLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const [isImageError, setIsImageError] = useState(false);

  const totalCompletion = details?.percentage;
  let progressValue;
  const isDecimal = !Number.isInteger(parseFloat(totalCompletion));

  if (isDecimal) {
    progressValue = parseFloat(totalCompletion).toFixed(2);
  } else {
    progressValue = totalCompletion;
  }

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
    // setIsImageError(true);
  };

  const options = {
    series: [progressValue],
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "55%",
        },
        dataLabels: {
          show: true,

          value: {
            color: "white",
            fontSize: "13px",
            offsetY: -10,
          },
        },
      },
    },
    colors: ["#AB0404"],
    labels: [""],
  };

  return (
    <Grid item xs={10} sm={6} md={5.5} lg={4}>
      <Box
        sx={{
          border: `1px solid ${theme.colors.text.greyDark}`,
          cursor: "pointer",
        }}
        onClick={() =>
          navigate(`/dashboard/campaigns/view-details/${details?._id}`)
        }
      >
        <Box
          sx={{
            backgroundColor: "#252525",
            px: "10px",
            py: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "95px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
              height: "100%",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton
                type="img"
                sx={{ width: "75px", height: "75px" }}
              />
            ) : (
              <Box
                sx={{
                  width: "75px",
                  height: "75px",
                  // background: isImageError ? "rgba(14,14,14,1)" : "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={details?.brandLogo}
                  alt="Brand Logo"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onError={handleImageError}
                ></Box>
              </Box>
            )}
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                fontSize: {
                  xs: theme.fonts.cardSubHeading_xs,
                  lg: theme.fonts.cardSubHeading,
                },
                fontWeight: theme.fonts.fontWeightMedium,
                width: "calc(100% - 90px)",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {isAllDataLoading ? (
                <CustomSkeleton type="p" />
              ) : (
                details?.campaignName
              )}
            </Typography>
          </Box>

          {isAllDataLoading ? (
            <CustomSkeleton
              type="circle"
              sx={{ width: "40px", height: "40px" }}
            />
          ) : (
            <ReactApexChart
              options={options}
              series={options?.series}
              type="radialBar"
              height={110}
              width={60}
            />
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: "black",
            width: "100%",
            height: "160px",
            px: "10px",
            pb: "10px",
          }}
        >
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: { xs: "16px", md: "18px" },
              py: "10px",
            }}
          >
            Activities
          </Typography>
          <Box
            sx={{
              height: "120px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                // display: "none",
                width: "2px",
              },
              // "&:hover::-webkit-scrollbar": {
              //   display: "block",
              //   width: "4px",
              // },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
                borderRadius: "4px",
              },
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" count={3} sx={{ mb: 2 }} />
            ) : (
              details?.deals?.map((data, index) => (
                <Box
                  key={index}
                  sx={{
                    background: theme.colors.background.formInput,
                    mb: 1,
                    p: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "80%",
                        fontSize: "14px",
                      }}
                    >
                      {data?.activities}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color:
                          data?.status === "Completed"
                            ? "rgba(36, 184, 33, 1)"
                            : data?.status === "Rejected"
                            ? "red"
                            : "grey",
                      }}
                    >
                      {data?.status}
                    </Typography>
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default AthleteDealTrackerCard;
