import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const ScreenLoader = ({ splashChangeTimer, SplashImages }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = (currentImageIndex + 1) % SplashImages.length;
      setCurrentImageIndex(nextIndex);
    }, splashChangeTimer);

    return () => clearTimeout(timer);
  }, [currentImageIndex]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100svh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Box className="loader">
        <span></span>
        <span></span>
        <span></span>
      </Box> */}

      <Box
        component="img"
        src={SplashImages[currentImageIndex]}
        alt={`Splash ${currentImageIndex}`}
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: { sm: "500px", xl: "600px" },
          objectFit: "contain",
        }}
      ></Box>
    </Box>
  );
};

export default ScreenLoader;
