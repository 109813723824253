import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";

import DefaultBrandLogo from "../../../../../assets/logo/DefaultBrandLogo.png";
import ExploreBrandsCardBackground from "../../../../../assets/icons/ExploreBrandsCardBackground.svg";
import { ChevronRight } from "@mui/icons-material";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const ExploreBrandsListCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
  };
  const handleMore = () => {
    localStorage.setItem("brandId", detail?._id);
    navigate("/dashboard/explore-brands/brands-list", {
      state: { detail },
    });
  };

  return (
    <Box
      p="15px"
      sx={{
        border: `1px solid grey`,
        borderRadius: "5px",
        background: theme.colors.common.black,
        overflow: "hidden",
        minWidth: { xs: "90%", sm: "400px", md: "100%" },
        height: { xs: "280px", sm: "140px", md: "120px" },
        position: "relative",
      }}
      // data-aos="fade-up"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: { xs: "wrap", sm: "nowrap" },
          gap: 3,
        }}
      >
        <Box
          sx={{
            // background: "rgba(197,197,197,1)",
            width: { xs: "100%", sm: "220px", md: "180px" },
            height: { xs: "20vh", sm: "110px", md: "90px" },
            textAlign: "center",
            display: { xs: "flex", sm: "block" },
            alignItems: "center",
            justifyContent: "center",
            py: 1,
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton
              type="img"
              sx={{ width: { xs: "50%", sm: "90%" }, height: "100%" }}
            />
          ) : (
            <Box
              component="img"
              src={detail?.brandLogo}
              alt="Brand Logo"
              id="brandLogo"
              onError={handleImageError}
              sx={{
                objectFit: "cover",
                width: { xs: "50%", sm: "90%" },
                height: "100%",
              }}
            ></Box>
          )}
        </Box>
        <Box
          component="img"
          src={ExploreBrandsCardBackground}
          alt="Explore Brands Background"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            width: { xs: "200px", sm: "250px", md: "200px" },
            display: { xs: "none", sm: "block" },
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            width: "100%",
          }}
        >
          <Typography
            fontSize={theme.fonts.cardSubHeading}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" sx={{ mb: 1 }} />
            ) : (
              detail?.brandName
            )}
          </Typography>

          <Typography
            fontFamily={theme.fonts.livvic}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="circle" />
            ) : (
              <Box
                component="span"
                sx={{
                  color: "red",
                  mr: 1,
                  fontWeight: theme.fonts.fontWeightBold,
                }}
              >
                {detail?.activeCampaigns}
              </Box>
            )}
            Active Campaigns
          </Typography>
          <Box
            sx={{
              display: "flex",
              // justifyContent: "right",
              alignItems: "center",
              position: "absolute",
              bottom: "5px",
              right: "5px",
              cursor: "pointer",
            }}
            onClick={isAllDataLoading ? null : handleMore}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              <>
                <Typography fontFamily={theme.fonts.livvic}>More</Typography>
                <ChevronRight />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreBrandsListCard;
