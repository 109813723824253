import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet-async";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  brandCurrentSubscriptionPlans,
  brandSubscriptionPlans,
  brandtrialRemaining,
} from "../../../../../services/brand/brand.service";
import { brandsubscriptionTransactionsList } from "../../../../../services/accounts/accounts.service";
import ChangeSubscriptionModal from "../../../../../components/afterLogin/brands/atheletsProfile/changeSubscription.modal";
import { MoreVert } from "@mui/icons-material";
import CancleSubscriptionModal from "../../../../../components/afterLogin/brands/atheletsProfile/cancleSubscriptionModal";
import dayjs from "dayjs";

const MySubscriptionPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  const [formData, setFormData] = useState([]);
  const [trialData, setTrialData] = useState(null);
  const [transactionList, setTransactionList] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancleModal, setCancleModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const open = Boolean(anchorEl);
  const isAdmin = localStorage.getItem("isBrandAdmin");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancle = () => {
    setCancleModal(true);
  };

  const handleCloseCancleModal = () => {
    setCancleModal(false);
  };

  const handleTransactionDetails = (data) => {
    navigate("transaction-details", {
      state: {
        currentPlan: currentPlan,
        value: data,
      },
    });
  };

  const handleChangePlan = () => {
    callSubscriptionPlansApi();
  };

  const handleCloseDialog = () => {
    setLoading(false);
    setOpenDialog(false);
  };

  const currentPlanApi = async () => {
    setLoading(true);
    const response = await brandCurrentSubscriptionPlans();
    setLoading(false);
    if (response.status === 200) {
      setCurrentPlan(response.data);
    } else {
      console.error("Failed brandCurrentSubscriptionPlans:", response.message);
    }
  };

  const callBrandTrialRemainingApi = async () => {
    const response = await brandtrialRemaining();
    if (response.status === 200) {
      setTrialData(response.data);
    } else {
      console.error("Failed to fetch trial remaining:", response.message);
    }
  };

  const callScriptionTransactionsListApi = async () => {
    const response = await brandsubscriptionTransactionsList();
    if (response.status === 200) {
      setTransactionList(response.data.data);
    } else {
      console.error(
        "Failed to fetch brand subscription Transactions List:",
        response.message
      );
    }
  };

  const callSubscriptionPlansApi = async () => {
    try {
      setLoading(true);
      const response = await brandSubscriptionPlans();
      if (response.status === 200) {
        setLoading(true);
        setFormData([...response.data]);
        setOpenDialog(true);
      } else {
        console.error("Failed to fetch campaigns list:", response.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns list:", error);
    }
  };

  useEffect(() => {
    currentPlanApi();
    callBrandTrialRemainingApi();
    callScriptionTransactionsListApi();
  }, []);

  return (
    <>
      <Helmet>
        <title>Subscription | Burrst</title>
      </Helmet>
      <Box
        mt={{ xs: "95px", sm: "115px" }}
        sx={{
          paddingX: { xs: "10px", md: "30px" },
          mb: "40px",
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", sm: "40px" },
              color: "white",
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography
              color="white"
              fontFamily={theme.fonts.russoOne}
              fontSize={{
                xs: theme.fonts.heading_xs,
                sm: theme.fonts.heading,
              }}
            >
              Subscription
            </Typography>
            {isAdmin === "true" && (
              <>
                <MoreVert sx={{ cursor: "pointer" }} onClick={handleClick} />
                <Menu
                  anchorEl={anchorEl}
                  id="profile-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  sx={{
                    filter: "drop-shadow(0px 2px 4px rgba(255,255,255,0.4))",
                    mt: 1,
                    "& .MuiPaper-root": {
                      background: "black",
                      color: theme.colors.text.greyDark,
                    },
                  }}
                >
                  <MenuItem
                    onClick={handleCancle}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    {/* <Box component="img" src={EditIcon} alt="Edit Icon"></Box> */}
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Cancel Subscription
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>
        <Box backgroundColor="#121212" padding="3.5%" minHeight="500px">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            backgroundColor="#000000"
            border="1px solid #797979"
            pt="30px"
            pb="40px"
          >
            <Typography
              fontWeight={theme.fonts.fontWeightBold}
              fontFamily={theme.fonts.livvic}
              sx={{ fontSize: { xs: "24px", md: "30px" } }}
            >
              {trialData?.trialOver
                ? new Date(transactionList?.nextDate).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    }
                  )
                : trialData?.remaining
                ? trialData?.remaining + " days left"
                : ""}
            </Typography>
            <Typography
              fontWeight={500}
              fontFamily={theme.fonts.livvic}
              sx={{ fontSize: { xs: "20", md: "22px" }, color: "#AFACAC" }}
            >
              {trialData?.trialOver ? "Next billing date" : "30-day free trial"}
            </Typography>
            {isAdmin === "true" && (
              <Button
                onClick={handleChangePlan}
                variant="contained"
                sx={{
                  background: "red",
                  width: { xs: "160px", sm: "260px" },
                  mt: 1,
                  height: "45px",
                  textTransform: "none",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: { xs: "14px", sm: "18px" },
                  fontFamily: theme.fonts.livvic,
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "gray" }} />
                ) : (
                  "Change Plan"
                )}
              </Button>
            )}
          </Box>

          {transactionList?.transactions?.length !== 0 && (
            <Box>
              <Typography
                fontWeight={500}
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontSize: { xs: "20", md: "22px" },
                  mt: "20px",
                  mb: "5px",
                }}
              >
                Transactions
              </Typography>
              {transactionList?.transactions?.map((data, index) => (
                <Box
                  key={index}
                  onClick={() => handleTransactionDetails(data)}
                  px="20px"
                  backgroundColor="#000000"
                  border="1px solid #797979"
                  sx={{ cursor: "pointer" }}
                >
                  <Typography
                    mt="15px"
                    fontSize="18px"
                    fontWeight={theme.fonts.fontWeightRegular}
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "@media (max-width:600px)": {
                        fontSize: "16px",
                      },
                    }}
                  >
                    {/* {currentPlan?.planName === "yearly" ? "Yearly" : "Monthly"}{" "} */}
                    Monthly subscription
                    <Box
                      color="#10D20C"
                      component="span"
                      fontWeight={theme.fonts.fontWeightRegular}
                    >
                      ${data?.amount_paid}
                    </Box>
                  </Typography>
                  <Typography
                    mb="15px"
                    fontSize="14px"
                    fontWeight={theme.fonts.fontWeightRegular}
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      color: "#A0A0A0",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      "@media (max-width:600px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {dayjs(data?.created)?.format("hh:mm A [on] MMM DD YYYY")}

                    {data?.status === "failed" && (
                      <Box
                        display="flex"
                        alignItems="center"
                        color="#FF0000"
                        component="span"
                        fontWeight={theme.fonts.fontWeightRegular}
                      >
                        <WarningAmberIcon sx={{ width: "12px", mr: "2px" }} />
                        Failed
                        {/* {campaignData.totalCompensation} */}
                      </Box>
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            sx: {
              minWidth: "40vw",
              background: theme.colors.dashboard.grey200,
              color: theme.colors.common.white,
              borderRadius: theme.fonts.smallBorderRadius,
            },
          }}
          sx={{
            backdropFilter: "blur(3px)",
            backgroundColor: "rgb(255,255,255,0.3)",
          }}
        >
          <ChangeSubscriptionModal
            handleCloseDialog={handleCloseDialog}
            formData={formData}
          />
        </Dialog>
        <Dialog
          open={cancleModal}
          onClose={handleCloseCancleModal}
          PaperProps={{
            sx: {
              minWidth: "40vw",
              background: theme.colors.dashboard.grey200,
              color: theme.colors.common.white,
              borderRadius: theme.fonts.smallBorderRadius,
            },
          }}
          sx={{
            backdropFilter: "blur(3px)",
            backgroundColor: "rgb(255,255,255,0.3)",
          }}
        >
          <CancleSubscriptionModal
            handleCloseCancleModal={handleCloseCancleModal}
            formData={formData}
          />
        </Dialog>
      </Box>
    </>
  );
};

export default MySubscriptionPage;
