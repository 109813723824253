import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";

import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import LibraryAddCheckRoundedIcon from "@mui/icons-material/LibraryAddCheckRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";

import dayjs from "dayjs";
import { saveAs } from "file-saver";

import DownloadBoldIcon from "../../../../../assets/icons/DownloadBoldIcon.svg";
import ContractFileIcon from "../../../../../assets/icons/ContractFileIcon.svg";
import PDFIcon from "../../../../../assets/icons/PDFIcon.png";
import AdidasBlack from "../../../../../assets/icons/AdidasBlack.svg";

import { getContractDetails } from "../../../../../services/campaign/campaign.service";

const BrandsAfterContractPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [contractDetails, setContractDetails] = useState(null);
  const [isImageError, setIsImageError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleImageError = (e) => {
    e.target.src = AdidasBlack;
    setIsImageError(true);
  };

  const handleCopyToClipboard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(contractDetails?.transaction?.last_tx);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleDownload = (downloadLink, fileName) => {
    if (downloadLink) saveAs(downloadLink, fileName);
  };

  const fetchContractDetails = async () => {
    const response = await getContractDetails(
      location?.state?.campaignDetails?._id
    );
    setContractDetails(response?.data?.data);
  };

  useEffect(() => {
    fetchContractDetails();
  }, []);

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* ----------------Top Header-------------------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: "40px",
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          fontSize={theme.fonts.heading}
        >
          Contract
        </Typography>
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          my: "30px",
          p: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: { sm: "flex" },
                alignItems: { xs: "center", sm: "flex-start" },
                gap: 3,
                height: { xs: "auto", sm: "200px" },
              }}
            >
              <Box
                sx={{
                  background: theme.colors.text.greyLight,
                  p: "10px",
                  minWidth: { xs: "250px", sm: "170px", md: "180px" },
                  maxWidth: { xs: "250px", sm: "auto" },
                  height: { xs: "250px", sm: "90%", md: "95%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: { xs: 2, sm: 0 },
                }}
              >
                <Box
                  component="img"
                  src={contractDetails?.brandLogo || AdidasBlack}
                  alt="Brand Logo"
                  onError={handleImageError}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: isImageError ? "contain" : "cover",
                  }}
                ></Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  maxHeight: { xs: "auto", sm: "180px", md: "195px" },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.russoOne,
                    fontSize: theme.fonts.subHeading,
                  }}
                >
                  {contractDetails?.brandName}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    background: "black",
                    padding: "5px 10px",
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      wordBreak: "break-all",
                    }}
                  >
                    {contractDetails?.transaction?.last_tx}
                  </Box>
                  {isCopied ? (
                    <LibraryAddCheckRoundedIcon />
                  ) : (
                    <ContentCopyRoundedIcon
                      onClick={handleCopyToClipboard}
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    height: "90%",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "3px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "red",
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: "16px",
                    }}
                  >
                    {contractDetails?.brief}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              fontSize={theme.fonts.sectionHeading}
              mb={1}
            >
              Contract File
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                minWidth: "300px",
                maxWidth: "400px",
                border: `1px solid ${theme.colors.text.greyDarker}`,
                padding: "10px",
                background: "rgba(29,29,29,1)",
                position: "relative",
              }}
            >
              <Box
                component="img"
                src={PDFIcon}
                alt="Contract File"
                width="20%"
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  flexWrap: "wrap",
                  wordBreak: "break-all",
                  pr: 5,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: theme.fonts.sectionBodyText,
                  }}
                >
                  {contractDetails?.contractFilename
                    ?.split(".")
                    .slice(0, -1)
                    .join("") || "Contract File Name"}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: theme.fonts.fontWeightMedium,
                  }}
                >
                  {contractDetails?.contractFilesize || "20 KB"}
                </Typography>
              </Box>
              <Box
                component="img"
                src={DownloadBoldIcon}
                alt="Download Icon"
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: { xs: "30%", sm: "30px" },
                  width: "30px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleDownload(
                    contractDetails?.contract,
                    contractDetails?.contractFilename || "Contract File Name"
                  )
                }
              ></Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                background: "black",
                p: "20px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: theme.fonts.sectionHeading,
                  }}
                >
                  Status
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    width: "140px",
                    background: "rgba(217,255,217,0.58)",
                    color: "rgba(7,93,5,1)",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "rgba(7,93,5,1)",
                    }}
                  >
                    <DoneIcon
                      sx={{
                        color: "white",
                        zIndex: 1,
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: theme.fonts.sectionHeading,
                    }}
                  >
                    Success
                  </Typography>
                </Box>
              </Box>

              {/* -----------Status Content---------------------- */}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", sm: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    textAlign: { xs: "left", sm: "right" },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: theme.colors.text.greyLight,
                    }}
                  >
                    From
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionBodyText,
                      wordBreak: "break-all",
                    }}
                  >
                    {contractDetails?.transaction?.owner}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", sm: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    textAlign: { xs: "left", sm: "right" },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: theme.colors.text.greyLight,
                    }}
                  >
                    To
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionBodyText,
                      wordBreak: "break-all",
                    }}
                  >
                    {contractDetails?.transaction?.id}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", sm: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    textAlign: { xs: "left", sm: "right" },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: theme.colors.text.greyLight,
                    }}
                  >
                    Timestamp
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionBodyText,
                      wordBreak: "break-all",
                    }}
                  >
                    {dayjs(contractDetails?.transaction?.createdAt).format(
                      "MMM DD YYYY hh:mm:ss"
                    )}
                  </Typography>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", sm: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    textAlign: { xs: "left", sm: "right" },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: theme.colors.text.greyLight,
                    }}
                  >
                    Height
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionBodyText,
                      wordBreak: "break-all",
                    }}
                  >
                    1,234,876
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", sm: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    textAlign: { xs: "left", sm: "right" },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: theme.colors.text.greyLight,
                    }}
                  >
                    Size
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionBodyText,
                      wordBreak: "break-all",
                    }}
                  >
                    {`${contractDetails?.transaction?.data_size} Byte${
                      contractDetails?.transaction?.data_size > 1 ? "s" : ""
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BrandsAfterContractPage;
