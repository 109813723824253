import axios from "axios";
import { getToken } from "../../routes";
import { BASE_API_URL } from "../../utils/constants";

const base = BASE_API_URL;

const handleError = (error) => {
  if (error?.response?.data?.status === 500) return "Internal Server Error";
  else return error?.response?.data?.message || "Some Error Occurred";
};

export const getApi = async (path, filter = "") => {
  const token = getToken();

  try {
    const url = `${base}${path}${filter ? `?${filter}` : ""}`;
    const response = await axios.get(url, {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const createApi = async (path, body) => {
  const token = getToken();

  try {
    const url = `${base}${path}`;
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteApi = async (path, accountId) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios.delete(url, {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      data: {
        accountId: accountId,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
};
