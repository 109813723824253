import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
// import BurrstLogo from "../../src/assets/logo/burrst_png.png";
import { NavLink, useNavigate } from "react-router-dom";
import { PADDING_X } from "../utils/constants";
import { useState } from "react";
import { BASE_IMAGE_URL } from "../utils/constants";

const MENU_ITEM_LIST = [
  {
    id: 1,
    link: "/",
    label: "Home",
  },
  {
    id: 2,
    link: "/athletes",
    label: "Athletes",
  },
  {
    id: 1,
    link: "/brands",
    label: "Brands",
  },
  {
    id: 1,
    link: "/mission",
    label: "Mission",
  },
  {
    id: 1,
    link: "/demo",
    label: "Demo",
  },
];

function Navbar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [state, setState] = useState({
    top: false,
  });

  const [selectedItem, setSelectedItem] = useState("Home");

  const handleLogo = () => {
    navigate("/");
  };

  const linkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
      color: isActive ? theme.colors.common.white : theme.colors.text.greyDark,
      fontSize: "18px",
    };
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown") {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (
    <Box
      sx={{
        width: "100%",
        background: theme.colors.common.black,
      }}
      role="presentation"
      onClick={toggleDrawer("top", false)}
      onKeyDown={toggleDrawer("top", false)}
    >
      <Box
        width="100%"
        height={{ xs: "60px", sm: "70px" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingY="10px"
        paddingX={{ xs: "10px", sm: PADDING_X }}
      >
        <Box
          component="img"
          src={BurrstLogo}
          alt="Burrst Logo"
          width={{ xs: "120px", sm: "150px" }}
          height="55px"
          sx={{
            // filter: "none",
            WebkitFilter: "blur(0px)",
            MozFilter: "blur(0px)",
            msFilter: "blur(0px)",
            filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=‘0’)",
          }}
        />
        <ClearIcon
          onClick={toggleDrawer("top", false)}
          sx={{
            color: theme.colors.text.greyDark,
            fontSize: "30px",
            cursor: "pointer",
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          borderTop: "1px solid #918E8E",
          color: "white",
        }}
      >
        <List>
          {MENU_ITEM_LIST?.map((list, index) => (
            <ListItem
              key={index}
              selected={selectedItem === list?.label}
              onClick={() => {
                setSelectedItem(list?.label);
                navigate(list?.link);
              }}
              sx={{
                paddingX: { xs: "10px", sm: PADDING_X },
                cursor: "pointer",
                "&:hover": {
                  backgroundColor:
                    selectedItem !== list?.label
                      ? "rgba(25, 118, 210, 0.04)"
                      : "rgba(25, 118, 210, 0.08)",
                },
              }}
            >
              <ListItemText
                sx={{
                  fontSize: { xs: "16px", sm: "20px" },
                  color:
                    selectedItem === list?.label
                      ? theme.colors.common.white
                      : theme.colors.text.greyLight,
                  fontWeight: theme.fonts.fontWeightBold,
                  fontFamily: theme.fonts.livvic,
                  // display: "flex",
                  // justifyContent: "center",
                  // textAlign: "left",
                  // alignItems: "center",
                }}
                primary={list?.label}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 1,
          px: { xs: "10px", sm: "60px" },
        }}
      >
        <Button
          onClick={() => navigate("/login")}
          sx={{
            color: theme.colors.common.white,
            fontFamily: theme.fonts.livvic,
            textTransform: "none",
            minWidth: "120px",
            maxWidth: "150px",
            // width: "80%",
            height: "40px",
            background: "red",
            "&:hover": {
              background: theme.colors.divider.red,
            },
          }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box
      position="fixed"
      top={0}
      left={{ xs: 0, xl: "calc((100% - 1536px) / 2)" }}
      sx={{
        padding: { xs: "10px", sm: `15px ${PADDING_X}` },
        background: theme.colors.common.black,
        width: "100%",
        maxWidth: "1536px",
        zIndex: 99,
        fontFamily: theme.fonts.livvic,
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2} textAlign="left">
          <Box
            sx={{
              "@media (max-width:600px)": {
                width: "22vw",
              },
              "@media (min-width:468px) and (max-width:1200px)": {
                width: "16vw",
              },
            }}
          >
            <Box
              component="img"
              src={BurrstLogo}
              alt="Burrst Logo"
              sx={{ width: "90%", cursor: "pointer" }}
              onClick={handleLogo}
            ></Box>
          </Box>
        </Grid>
        {isMobile ? (
          <Grid item xs={10} textAlign="right">
            <MenuIcon
              onClick={toggleDrawer("top", true)}
              style={{
                color: theme.colors.common.white,
                fontSize: "2rem",
                cursor: "pointer",
              }}
            />

            <SwipeableDrawer
              anchor="top"
              open={state["top"]}
              onClose={toggleDrawer("top", false)}
              onOpen={toggleDrawer("top", true)}
            >
              {list}
            </SwipeableDrawer>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={8}
              textAlign="center"
              display="flex"
              justifyContent="center"
            >
              <Box
                color={theme.colors.common.white}
                display="flex"
                justifyContent="center"
                gap={{ md: 2, lg: 4 }}
              >
                <NavLink to="/" style={linkStyles} className="topNavbarLinks">
                  Home
                </NavLink>

                <NavLink
                  to="/athletes"
                  style={linkStyles}
                  className="topNavbarLinks"
                >
                  Athletes
                </NavLink>

                <NavLink
                  to="/brands"
                  style={linkStyles}
                  className="topNavbarLinks"
                >
                  Brands
                </NavLink>

                <NavLink
                  to="/mission"
                  style={linkStyles}
                  className="topNavbarLinks"
                >
                  Mission
                </NavLink>

                <NavLink
                  to="/demo"
                  style={linkStyles}
                  className="topNavbarLinks"
                >
                  Demo
                </NavLink>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              textAlign="right"
              display="flex"
              flexDirection="row-reverse"
            >
              <Button
                variant="contained"
                sx={{
                  background: "red",
                  width: "150px",
                  height: "45px",
                  textTransform: "uppercase",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "16px",
                  fontFamily: theme.fonts.livvic,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                }}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
export default Navbar;
