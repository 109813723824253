import { Box } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BrandsBeforeContractPage from "../brands/brands.before.contract.page";
import BrandsAfterContractPage from "../brands/brands.after.contract.page";

const ContractPage = () => {
  const location = useLocation();

  const [isSigned, setIsSigned] = useState(
    location?.state?.campaignDetails?.signed
  );

  return isSigned ? (
    <BrandsAfterContractPage />
  ) : (
    <BrandsBeforeContractPage setIsSigned={setIsSigned} />
  );
};

export default ContractPage;
