import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CustomSkeleton from "../../../../../../themes/@overrides/customSkeleton";

const RevenueCard = ({ details, isAllDataLoading }) => {
  const theme = useTheme();

  return (
    <>
      {isAllDataLoading ? (
        <CustomSkeleton
          sx={{ width: "100%", height: "100px", borderRadius: "5px" }}
        />
      ) : (
        <Box
          sx={{
            mr: "auto",
            backgroundColor: "#1F1F1F",
            borderRadius: "5px",
            overflow: "hidden",
            p: "15px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                fontFamily={theme.fonts.livvic}
                color="#C5C5C5"
                sx={{
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                {details?.campaignName && details?.campaignName?.length > 25
                  ? `${details?.campaignName?.substring(0, 25)}...`
                  : details?.campaignName}
              </Typography>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: { xs: "20px", md: "22px" },
                  fontWeight: 700,
                  mt: "3px",
                }}
              >
                $ {details?.totalRevenue}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "right",
                mt: "10px",
              }}
            >
              {details?.change ? (
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    px: "10px",
                    backgroundColor:
                      details?.change > 0 ? "#34BD311F" : "#FF000021", //#34BD311F
                    borderRadius: "20px",
                    color: details?.change > 0 ? "#24B821" : "#FF0000", //#24B821(green)
                  }}
                >
                  {details?.change < 0 ? (
                    <ArrowDownwardIcon fontSize="25px" />
                  ) : (
                    <ArrowUpwardIcon />
                  )}
                  {Math.floor(details?.change)}%
                </Typography>
              ) : (
                <Box sx={{ height: "25px" }}></Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default RevenueCard;
