import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
  useTheme,
} from "@mui/material";

// import CampaignBrand from "../../../../../../assets/images/CampaignBrand.png";
// import CampaignBrandBackground from "../../../../../../assets/images/CampaignBrandBackground.png";

import BurrstRecommendedBackground from "../../../../../../assets/icons/BurrstRecommendedBackground.svg";

import DefaultBrandLogo from "../../../../../../assets/logo/DefaultBrandLogo.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import {
  applyToCampaign,
  withdrawFromCampaign,
} from "../../../../../../services/campaign/campaign.service";
import dayjs from "dayjs";
import CustomSkeleton from "../../../../../../themes/@overrides/customSkeleton";
import ConnectPlaidAccModal from "../../connectPlaidAcc.modal";

const CampaignsBurrstRecommendedCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isApplied, setIsApplied] = useState(null);
  const [isPlaidConnected, setIsPlaidConnected] = useState(false);
  const profileState = "aiRecomPlaidConnectModal";

  useEffect(() => {
    if (detail) {
      setIsApplied(detail?.applied);
    }
  }, [detail]);

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
  };

  const backState = "campaignList";

  const handleViewDetails = () => {
    localStorage.setItem("back", backState);
    navigate(`/dashboard/campaigns/view-details/${detail?._id}`);
  };

  const handlePlaidConnectedModalClose = () => {
    setIsPlaidConnected(false);
  };

  const confirmAction = async () => {
    return new Promise((resolve) => {
      const confirmed = window.confirm(
        "Are you sure you want to apply for this campaign?"
      );
      resolve(confirmed);
    });
  };

  const handleApplyNow = async () => {
    const confirmed = await confirmAction();
    if (confirmed) {
      setLoading(true);
      const { data } = await applyToCampaign(detail?._id);
      setLoading(false);
      if (data?.status === 200) setIsApplied(true);
      else if (data?.status === 400 && data?.data?.plaidAccount === false) {
        setIsPlaidConnected(true);
      }
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);
    const { data } = await withdrawFromCampaign(detail?._id, detail?.selected);
    if (data?.status === 200) setIsApplied(false);
    setLoading(false);
  };

  return (
    <Box
      sx={{
        // minWidth: { xs: "320px", sm: "450px" },
        height: { xs: "240px" },
        mx: "auto",
        border: `1px solid grey`,
        background: theme.colors.common.black,
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "start", height: "80px", mb: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
            width: "calc(100% - 80px)",
            pl: "15px",
            pt: "20px",
            zIndex: 3,
          }}
        >
          <Typography
            fontSize={theme.fonts.cardSubHeading}
            fontWeight={theme.fonts.fontWeightBold}
            fontFamily={theme.fonts.livvic}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {isAllDataLoading ? <CustomSkeleton type="p" /> : detail?.brandName}
          </Typography>
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            detail?.endDate && (
              <Typography
                fontFamily={theme.fonts.livvic}
                color={theme.colors.text.greyLight}
                width="90%"
                fontSize="13px"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                End date- {dayjs(detail?.endDate).format("MMM DD YYYY")}
              </Typography>
            )
          )}
        </Box>
        <Box
          sx={{
            width: "80px",
            height: "80px",
            position: "relative",
            zIndex: 2,
            mb: 2,
          }}
        >
          <Box
            component="img"
            src={BurrstRecommendedBackground}
            alt="AI Recommended Background"
            sx={{
              position: "absolute",
              top: 0,
              right: -15,
              zIndex: -5,
              // width: { xs: "150px", sm: "165px" },
              width: "180px",
              height: "190px",
            }}
          ></Box>
          {isAllDataLoading ? (
            <CustomSkeleton
              type="img"
              sx={{ width: "80%", height: "80%", mt: 1 }}
            />
          ) : (
            <Box
              component="img"
              src={detail?.brandLogo}
              alt="Brand Logo"
              onError={handleImageError}
              width="80%"
              height="80%"
              mt={1}
              sx={{ objectFit: "cover" }}
            ></Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          p: "15px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Typography
          fontSize={theme.fonts.cardHighlights}
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            height: "45px",
            // gap: 1,
          }}
        >
          Compensation
          <Box
            component="span"
            sx={{
              color: "red",
              fontSize: "14px",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : detail?.sharePercentage ? (
              `${detail?.sharePercentage.toFixed(2)}% of monthly revenue.`
            ) : (
              `$${detail?.totalCompensation}`
            )}
          </Box>
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            fontSize: "20px",
            fontWeight: theme.fonts.fontWeightMedium,
            fontFamily: theme.fonts.livvic,
            color: theme.colors.text.greyLight,
          }}
        >
          <Typography
            fontSize={theme.fonts.cardHighlights}
            fontWeight={theme.fonts.fontWeightMedium}
            fontFamily={theme.fonts.livvic}
          >
            Activities
          </Typography>
          <Box
            fontFamily={theme.fonts.livvic}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {/* {detail?.activities[0]?.split(",").map((activity, index) => {
              const activitiesLength = detail?.activities[0]?.split(",").length;
              const remainingActivities = activitiesLength - 2;
              return index < 2 ? (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid grey",
                    p: "4px 10px",
                    borderRadius: "2px",
                    // fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: { xs: "9px", sm: "10px" },
                  }}
                >
                  {activity}
                </Box>
              ) : index === 2 ? (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid grey",
                    p: "4px 10px",
                    borderRadius: "2px",
                    // fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: { xs: "9px", sm: "10px" },
                  }}
                >
                  {`+${remainingActivities} Other${
                    remainingActivities !== 1 ? "s" : ""
                  }`}
                </Box>
              ) : null;
            })} */}
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.activities?.map((activity, index) => {
                const activitiesLength = detail?.activities?.length;
                const remainingActivities = activitiesLength - 1;
                return index < 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: { xs: "10px", sm: "12px" },
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {activity}
                  </Box>
                ) : index === 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: { xs: "10px", sm: "12px" },
                    }}
                  >
                    {`+${remainingActivities} Other${
                      remainingActivities !== 1 ? "s" : ""
                    }`}
                  </Box>
                ) : null;
              })
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent:
              detail?.signed || !detail?.selected ? "space-between" : "right",
            alignItems: "center",
            mt: 1,
            gap: 0.5,
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            (detail?.signed || !detail?.selected) && (
              <Button
                variant="contained"
                disabled={detail?.signed || loading}
                sx={{
                  background: "red",
                  maxWidth: { xs: "120px", sm: "150px" },
                  height: "40px",
                  textTransform: "none",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: { xs: "14px", sm: "18px" },
                  fontFamily: theme.fonts.livvic,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                  "@media (max-width:350px)": {
                    maxWidth: "100px",
                    fontSize: "12px",
                    height: "35px",
                  },
                }}
                onClick={isApplied ? handleWithdraw : handleApplyNow}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : detail?.signed ? (
                  "Signed"
                ) : isApplied ? (
                  "Withdraw"
                ) : (
                  "Apply Now"
                )}
              </Button>
            )
          )}

          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            <Box
              sx={{
                color: "rgba(217,217,217,1)",
                cursor: "pointer",
                zIndex: 2,
                height: "40px",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                gap: 0.5,
              }}
              onClick={handleViewDetails}
            >
              <Typography
                fontFamily={theme.fonts.montserrat}
                fontSize="12px"
                noWrap
              >
                View Details
              </Typography>
              <ArrowForwardIosIcon fontSize="16px" />
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        open={isPlaidConnected}
        onClose={handlePlaidConnectedModalClose}
        PaperProps={{
          sx: {
            width: "100vw",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <ConnectPlaidAccModal
          profileState={profileState}
          handleClose={handlePlaidConnectedModalClose}
        />
      </Dialog>
    </Box>
  );
};

export default CampaignsBurrstRecommendedCard;
