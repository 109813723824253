import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import { Helmet } from "react-helmet-async";
import Pulse from "../../themes/@overrides/pulse";
import TeamCard from "../../components/beforeLogin/home/subComponents/teamCard";

import GrantSapkin from "../../assets/images/GrantSapkin.png";
import DanLust from "../../assets/images/DanLust.png";
import SuvenduGiri from "../../assets/images/SuvenduGiri.png";
import GauravRay from "../../assets/images/GauravRay.png";
import { useNavigate } from "react-router-dom";

const TEAM_LIST = [
  {
    id: 1,
    name: "Grant Sapkin",
    imgUrl: GrantSapkin,
    designation: "Founder & CEO",
    bio: "Grant is the visionary Founder and CEO of Burrst, who strives to give all student-athletes opportunities to profit from their name, image, and likeness (NIL) and help brands measure the returns on their investment in collaborating with athletes.",
  },
  {
    id: 2,
    name: "Dan Lust",
    imgUrl: DanLust,
    designation: "Legal Advisor",
    bio: "Dan is one the nation’s leading Sports Law attorneys and is featured regularly on shows across the country for his insights. He is also a law professor and NIL educator at New York Law School.",
  },
  {
    id: 3,
    name: "Suvendu Giri",
    imgUrl: SuvenduGiri,
    designation: "UX Strategist",
    bio: "Suvendu exemplifies an unwavering commitment to innovation, consistently seeking out novel ideas, cutting-edge technologies, and unconventional approaches. His profound expertise in web development serves as a catalyst, propelling Burrst to the vanguard of the industry.",
  },
  {
    id: 4,
    name: "Gaurav Roy",
    imgUrl: GauravRay,
    designation: "Content Specialist",
    bio: "Gaurav embarked on his cryptocurrency journey in 2017, starting off as an aspiring crypto trader. Soon after, he delved into studying numerous cryptocurrency projects. Presently, he contributes to Burrst by providing content writing services in the sports tech domain.",
  },
];

const Team = () => {
  const theme = useTheme();

  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };
  const handleLoadedVideo = (e) => {
    setIsVideoLoading(false);
  };

  const handleBookDemo = () => {
    window.open(
      "https://calendly.com/grantsapkin/burrst-demo",
      "blank",
      "noopener noreferrer"
    );
  };

  return (
    <>
      <Helmet>
        <title>Team | Burrst</title>
        <meta
          name="description"
          content="Meet our dedicated team of experts committed to delivering exceptional service and innovative solutions. Learn more about the talented individuals driving our success."
        />
        <link rel="canonical" href={`${HOST_URL}/team`} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            >
              TEAM
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Team
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            my: "40px",
            px: { xs: "10px", sm: PADDING_X },
            width: "100%",
            maxWidth: "1536px",
          }}
        >
          {/* -----------------------Header---------------------------------- */}

          <Box display="flex" justifyContent="left" alignItems="center" gap={2}>
            <Pulse />
            <Typography
              fontFamily={theme.fonts.russoOne}
              color={theme.colors.text.red}
              fontSize={{
                xs: theme.fonts.heading_xs,
                sm: theme.fonts.heading,
                md: theme.fonts.heading_xs,
                lg: theme.fonts.heading,
              }}
            >
              <span style={{ color: theme.colors.text.greyDark }}>Meet</span>{" "}
              Our Team
            </Typography>
          </Box>

          <Box
            sx={{
              my: "30px",
              overflow: "hidden",
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: { xs: "center", md: "left" } }}
            >
              {TEAM_LIST?.map((detail, index) => {
                return (
                  <Grid key={index} item xs={10} sm={9} md={6} xl={4}>
                    <TeamCard detail={detail} />{" "}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Team;
