import React from "react";
import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import Dobicon from "../../../../../assets/icons/DOB.Icon.svg";
import Gendericon from "../../../../../assets/icons/Gender.con.svg";
import Sportsicon from "../../../../../assets/icons/Sports.iocn.svg";
import SchoolIcon from "../../../../../assets/icons/SchoolIcon.svg";
import TeamIcon from "../../../../../assets/icons/TeamIcon.svg";
import GradeIcon from "../../../../../assets/icons/GradeIcon.svg";
import Locationicon from "../../../../../assets/icons/Loctaion.icon.svg";
import EditIcon from "../../../../../assets/icons/EditIcon.svg";
import Travelingicon from "../../../../../assets/icons/travel.svg";
import Readingicon from "../../../../../assets/icons/reading.svg";
import Swimmingicon from "../../../../../assets/icons/swim.svg";

const PersonalDetails = ({ detail }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const isMyProfile = location?.state?.isMyProfile;

  const handleEdit = () => {
    navigate("/athletes/profile", { state: { detail, isMyProfile } });
  };

  return (
    <Grid my="20px" backgroundColor="#000000" padding="30px" borderRadius="5px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          px: { sm: "20px" },
        }}
      >
        <Typography
          fontFamily={theme.fonts.montserrat}
          fontWeight={theme.fonts.fontWeightBold}
          fontSize={theme.fonts.sectionHeading}
        >
          Personal Details
        </Typography>
        {isMyProfile && (
          <Box
            component="img"
            src={EditIcon}
            alt="Edit Icon"
            sx={{ cursor: "pointer" }}
            onClick={handleEdit}
          ></Box>
        )}
      </Box>
      <Divider sx={{ borderColor: "white", mb: 4, mt: 1 }} />
      <Grid
        backgroundColor="#121212"
        mx={{ xs: "0", sm: "20px" }}
        px="10px"
        py="10px"
        borderRadius="5px"
      >
        <Grid>
          <Box display="flex" alignItems="center" mb="15px">
            <img src={Dobicon} alt="DOB icon" />
            <Typography
              ml="10px"
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightRegular}
              fontSize={theme.fonts.sectionSubHeading}
            >
              {detail?.dob ||
                new Date(detail?.dateOfBirth).toLocaleDateString() ||
                null}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="15px">
            <img src={Gendericon} alt="Gender icon" />
            <Typography
              ml="10px"
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightRegular}
              fontSize={theme.fonts.sectionSubHeading}
            >
              {detail?.gender}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="15px">
            <img src={Sportsicon} alt="Sports icon" />
            <Typography
              ml="10px"
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightRegular}
              fontSize={theme.fonts.sectionSubHeading}
              sx={{ wordBreak: "break-all" }}
            >
              {detail?.sports?.join(",")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="15px">
            <img
              src={
                detail?.educationInstitutionType === "Professional"
                  ? TeamIcon
                  : SchoolIcon
              }
              alt={
                detail?.educationInstitutionType === "Professional"
                  ? "Team Icon"
                  : "School Icon"
              }
            />
            <Typography
              ml="10px"
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightRegular}
              fontSize={theme.fonts.sectionSubHeading}
            >
              {detail?.school}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="15px">
            <img src={GradeIcon} alt="Grade icon" />
            <Typography
              ml="10px"
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightRegular}
              fontSize={theme.fonts.sectionSubHeading}
            >
              {detail?.grade}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb="15px">
            <img
              src={Locationicon}
              alt="Location icon"
              style={{ marginLeft: "5px" }}
            />
            <Typography
              ml="12px"
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightRegular}
              fontSize={theme.fonts.sectionSubHeading}
            >
              {detail?.city !== "N/A" ? detail?.city + "," : ""}
              {detail?.state !== "N/A" ? detail?.state + "," : ""}
              {detail?.country !== "N/A" ? detail?.country : ""}
            </Typography>
          </Box>
        </Grid>
        <Typography
          mb="15px"
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightRegular}
          fontSize={theme.fonts.sectionSubHeading}
        >
          Interests :
        </Typography>

        <Grid display="flex" alignItems="center" flexWrap="wrap">
          {detail?.interests?.map((interest, index) => (
            <Box
              key={index}
              mb="10px"
              mr="15px"
              display="flex"
              alignItems="center"
              border="1px solid #918E8E"
              borderRadius="5px"
              padding="4px"
              paddingX="8px"
            >
              {/* <img
              src={Travelingicon}
              alt="Traveling icon"
              style={{ marginLeft: "5px" }}
            /> */}
              <Typography
                fontFamily={theme.fonts.montserrat}
                fontWeight={theme.fonts.fontWeightRegular}
                fontSize="14px"
              >
                {interest}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
