import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";

// import CampaignBrand from "../../../../../../assets/images/CampaignBrand.png";
// import CampaignBrandBackground from "../../../../../../assets/images/CampaignBrandBackground.png";

import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import { Info } from "@mui/icons-material";

const CampaignsListCard = ({ detail }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const CampaignBrand = `${BASE_IMAGE_URL}/CampaignBrand.png`;
  const CampaignBrandBackground = `${BASE_IMAGE_URL}/CampaignBrandBackground.png`;

  const [open, setOpen] = useState(false);

  const handleViewDetails = () => {
    localStorage.setItem("campaignId", detail?._id);
    navigate(`view-details/${detail?._id}`);
  };

  return (
    <Box
      onClick={handleViewDetails}
      sx={{
        cursor: "pointer",
        width: "500px",
        my: "20px",
        mx: "auto",
        border: `1px solid grey`,
        background: theme.colors.common.black,
        borderRadius: "5px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {!detail?.contract && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 3,
          }}
        >
          <Tooltip
            title="Incomplete"
            placement="top-end"
            arrow
            open={window.innerWidth > 1200 ? undefined : open}
            onClose={() => setOpen(false)}
          >
            <IconButton
              sx={{ p: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <Info sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "68%",
            p: "15px",
          }}
        >
          <Typography
            fontWeight={theme.fonts.fontWeightBold}
            fontFamily={theme.fonts.livvic}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: { xs: "18px", md: "20px" },
              zIndex: 3,
            }}
          >
            {detail?.campaignName}
          </Typography>
          <Typography
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.greyLight}
            width="90%"
            height="50px"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              zIndex: 3,
            }}
          >
            {detail?.brief}
          </Typography>

          <Typography
            minHeight="40px"
            sx={{ fontSize: { xs: "16px", md: "18px" } }}
            fontWeight={theme.fonts.fontWeightMedium}
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.greyLight}
          >
            Compensation:-{" "}
            {detail?.totalCompensation ? (
              <Box
                component="span"
                sx={{
                  color: "red",
                }}
              >
                ${detail?.totalCompensation}
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  color: "red",
                }}
              >
                {detail?.sharePercentage?.toFixed(2)}%{" "}
                <span style={{ fontSize: "15px" }}>of monthly revenue</span>
              </Box>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "32%",
            height: "100%",
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            component="img"
            src={CampaignBrandBackground}
            alt="Campaign Brand Background"
            id="brandBackground"
            sx={{
              width: { xs: "140px", md: "auto" },
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: -1,
            }}
          ></Box>
          <Box
            component="img"
            src={detail?.brandLogo}
            //src={CampaignBrand}
            alt="Campaign Brand"
            id="brandImg"
            mt={3}
            sx={{
              width: { xs: "90px", md: "120px" },
              height: { xs: "90px", md: "120px" },
              // objectFit: "cover",
            }}
          ></Box>
        </Box>
      </Box>
      <Typography
        fontWeight={theme.fonts.fontWeightMedium}
        fontFamily={theme.fonts.livvic}
        color={theme.colors.text.greyLight}
        sx={{
          fontSize: { xs: "18px", md: "20px" },
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: "0 15px 10px",
        }}
      >
        Activities:-{" "}
        {detail?.deliverables.slice(0, 2).map((deliverable, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              fontWeight: 500,
              color: "#D9D9D9",
              borderRadius: "5px",
              border: "1px solid #797979",
              padding: "2px 8px",
              fontSize: { xs: "14px", md: "16px" },
            }}
          >
            {/* {deliverable.activities} */}
            {deliverable.activities.replace(/"/g, "")}
          </Box>
        ))}
        {detail?.deliverables.length > 2 && (
          <Box
            component="span"
            sx={{
              color: "#D9D9D9",
            }}
          >
            . . .
          </Box>
        )}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "0 15px 10px",
        }}
      >
        <Typography
          sx={{ fontSize: { xs: "18px", md: "20px" } }}
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
        >
          View Applicants:-{" "}
          <Box component="span" fontWeight={theme.fonts.fontWeightMedium}>
            {detail?.applications?.length}
          </Box>
        </Typography>
        <Typography
          sx={{ cursor: "pointer" }}
          color={theme.colors.divider.grey}
          onClick={() => handleViewDetails()}
        >
          {"View Details >"}
        </Typography>
      </Box>
    </Box>
  );
};

export default CampaignsListCard;
