import React from "react";
import { Box, Divider, TextField, Typography, useTheme } from "@mui/material";

import DeleteIcon from "../../../../../../assets/icons/DeleteIcon.svg";
import EditIcon from "../../../../../../assets/icons/EditIcon.svg";

const DashboardDeliverableCard = ({
  detail,
  paymentModel,
  handleDelete,
  handleEdit,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        maxHeight: { xs: "auto", sm: "350px" },
        mx: "3px",
        width: "500px",
        my: "20px",
        border: `1px solid grey`,
        background: theme.colors.common.black,
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.7,
          px: "15px",
          pt: "5px",
          pb: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize="20px"
            fontFamily={theme.fonts.livvic}
            sx={{
              color: theme.colors.text.greyLight,
            }}
          >
            {detail?.deliverableName || "Deliverable"}
          </Typography>
        </Box>
        <Divider color="grey" />

        <Typography
          fontSize="18px"
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          Activities{" "}
          <Box component="span" color="white">
            {detail?.activities?.replaceAll(/"/g, "")}
          </Box>
        </Typography>

        <Typography
          fontSize="18px"
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          Compensation{" "}
          {paymentModel === "Revenue Share" ? (
            <Box component="span" color="red">
              {detail?.compensation}%{" "}
              <span
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                of monthly revenue
              </span>
            </Box>
          ) : (
            <Box component="span" color="red">
              ${detail?.compensation}
            </Box>
          )}
        </Typography>
        <Typography
          fontSize="18px"
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          Product/Service URL
          <Box component="span" color="white">
            {detail?.deliverableUrl && (
              <>
                {detail?.deliverableUrl?.length > 20
                  ? `${detail?.deliverableUrl?.substring(0, 20)}...`
                  : detail?.deliverableUrl}
              </>
            )}
          </Box>
        </Typography>
      </Box>
      {detail?.additionalProjectFiles?.length > 0 && (
        <Box p="0 15px 10px">
          <Typography
            fontSize="18px"
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.greyLight}
            mb={1}
          >
            Additional Project Files{" "}
          </Typography>
          <Box
            height="90px"
            paddingBottom="10px"
            paddingRight="5px"
            sx={{
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "2px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
                borderRadius: "4px",
              },
            }}
          >
            {detail?.additionalProjectFiles?.map((data, index) => (
              <TextField
                key={index}
                name="projectFiles"
                placeholder={data?.name ? data?.name : data}
                fullWidth
                autoComplete="off"
                size="small"
                InputProps={{
                  readOnly: true,
                  sx: {
                    border: `1px solid ${theme.colors.text.greyLightDark}`,
                    background: theme.colors.common.black,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  marginBottom: "5px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                }}
              />
            ))}
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
          p: "0 15px 10px",
        }}
      >
        <img
          src={DeleteIcon}
          alt="Delete"
          onClick={() => handleDelete(detail?._id)}
          style={{ cursor: "pointer" }}
        />
        <img
          src={EditIcon}
          alt="Edit"
          onClick={() => handleEdit(detail)}
          style={{ cursor: "pointer" }}
        />
      </Box>
    </Box>
  );
};

export default DashboardDeliverableCard;
