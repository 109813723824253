import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import { myProfileReducer, otherProfileReducer } from "./myProfileSlice";
import chatSlice from "./chatSlice";
import notificationsReducer from "./notificationsReducer";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, authSlice);

const store = configureStore({
  reducer: {
    authData: persistedReducer,
    myProfile: myProfileReducer,
    otherProfile: otherProfileReducer,
    chat: chatSlice,
    notifications: notificationsReducer,
  },
});

export default store;

export const persistor = persistStore(store);
