import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";

import CrossIcon from "../../../assets/icons/CrossIcon.svg";

import OtpSentImage from "../../../assets/images/verifyOtp.svg";

import { BASE_IMAGE_URL } from "../../../utils/constants";

const SentOtpModal = ({
  handleOtpModalClose,
  formData,
  registrationToken,
  userId,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const OtpSentImage = `${BASE_IMAGE_URL}/OtpSentImage.png`;

  const handleOk = () => {
    navigate("/athletes/otp-verification", {
      state: {
        countryCode: formData?.countryCode,
        phoneNo: formData?.phoneNo,
        registrationToken: registrationToken,
        userId: userId,
      },
    });
  };
  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleOtpModalClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: "40px",
          gap: 3,
        }}
      >
        <Box
          component="img"
          src={OtpSentImage}
          alt="Otp Sent"
          width="150px"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h5"
        >
          Verify OTP
        </Typography>
        <Typography fontFamily={theme.fonts.livvic}>
          A temporary OTP has been sent to your phone number.
          <br /> Please use it to verify your account in the next screen.
        </Typography>

        <Button
          // fullWidth
          sx={{
            background: theme.colors.background.registerButton,
            color: "white",
            height: "50px",
            width: "50%",
            mt: "10px",
            fontSize: "22px",
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            borderRadius: "10px",
            "&:hover": {
              background: theme.colors.divider.red,
            },
          }}
          onClick={handleOk}
        >
          OK
        </Button>
      </Box>
    </DialogContent>
  );
};

export default SentOtpModal;
