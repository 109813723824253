import { useTheme } from "@emotion/react";
import { ArrowCircleRight, ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const ATHLETES_LIST_ITEMS = [
  "Connecting with brands that share your vision.",
  "Crafting compelling content and showcasing noteworthy moments.",
  "Mastering effective communication to build strong brand relationships.",
  "Simplifying agreements for better understanding and collaboration.",
];
const BRANDS_LIST_ITEMS = [
  "Engaging athletes through the campaign-building process.",
  "Providing recommendations of suitable student-athletes for your brand.",
  "Thoroughly scanning contracts for any potential issues.",
  "Refining agreements through careful rewriting.",
];

const AIContents = () => {
  const theme = useTheme();

  const centerFlexStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
  };
  return (
    <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: { xs: "column", xl: "row" },
    //     alignItems: { xl: "center" },
    //     justifyContent: { xl: "space-between" },
    //   }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box sx={centerFlexStyle}>
            <ArrowCircleRight
              sx={{
                color: "red",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: {
                xs: theme.fonts.cardHeading,
                xl: theme.fonts.beforeLogin.subHeading.xl,
              },
            }}
          >
            For Athletes
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {ATHLETES_LIST_ITEMS?.map((list, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 0.5,
                pl: "10px",
              }}
            >
              <ArrowForward
                sx={{
                  fontSize: "16px",
                  color: "red",
                  mt: 0.5,
                }}
              />

              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  color: theme.colors.text.greyLight,
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                }}
              >
                {list}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0.5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box sx={centerFlexStyle}>
            <ArrowCircleRight
              sx={{
                color: "red",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: {
                xs: theme.fonts.cardHeading,
                fontSize: { xl: theme.fonts.beforeLogin.subHeading.xl },
              },
            }}
          >
            For Brands
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {BRANDS_LIST_ITEMS?.map((list, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 0.5,
                pl: "10px",
              }}
            >
              <ArrowForward
                sx={{
                  fontSize: "16px",
                  color: "red",
                  mt: 0.5,
                }}
              />

              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  color: theme.colors.text.greyLight,
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                }}
              >
                {list}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AIContents;
