import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
// import ApplePodcasts from "../../../../assets/images/ApplePodcasts.png";
// import SpotifyPodcasts from "../../../../assets/images/SpotifyPodcasts.png";

import { dashboardAthletesDetails } from "../../../../_mock/dummyData";
import PodcastsCard from "../../../../components/afterLogin/brands/podcasts/subComponents/podcasts.card";

import { BASE_IMAGE_URL } from "../../../../utils/constants";
import Pulse from "../../../../themes/@overrides/pulse";
import { Helmet } from "react-helmet-async";
import MoreArticlesCard from "../../../../components/afterLogin/athletes/articles/subComponents/moreArticlesCard";
import LatestArticlesCard from "../../../../components/afterLogin/athletes/articles/subComponents/latestArticlesCard";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { getArticlesList } from "../../../../services/articles/article.service";

const Articles = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [articles, setArticles] = useState({});
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const fetchArticlesList = async () => {
    setIsAllDataLoading(true);
    const { data } = await getArticlesList();
    setIsAllDataLoading(false);
    setArticles(data);
  };

  useEffect(() => {
    fetchArticlesList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Articles | Burrst</title>
      </Helmet>
      <Box
        sx={{
          paddingX: { xs: "10px", sm: "30px" },
          mt: { xs: "95px", sm: "115px" },
        }}
      >
        <Box mb="30px">
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
            <Pulse />
            <Typography
              color={theme.colors.common.white}
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: "32px", md: "40px" }}
            >
              Latest Articles
            </Typography>
          </Box>
        </Box>

        {/* ----------------Latest Articles-------------- */}

        <Swiper
          pagination={true}
          grabCursor={true}
          centeredSlides={true}
          // loop={true}
          slidesPerView={2}
          autoplay={{
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          initialSlide={0}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            0: { slidesPerView: 1 },
            600: { slidesPerView: 1.2, spaceBetween: 20 },
            900: { slidesPerView: 2.2, spaceBetween: 20 },
          }}
          style={{
            "--swiper-pagination-color": "red",
            "--swiper-pagination-bullet-inactive-color": "#fff",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
            // "--swiper-pagination-bullet-size": "16px",
            "--swiper-pagination-bottom": "0px",
            height: "350px",
          }}
        >
          {isAllDataLoading
            ? Array.from({ length: 4 }, (_, index) => (
                <SwiperSlide key={index}>
                  <LatestArticlesCard isAllDataLoading={isAllDataLoading} />
                </SwiperSlide>
              ))
            : articles?.latestArticles?.map((detail, index) => (
                <SwiperSlide key={index}>
                  <LatestArticlesCard detail={detail} />
                </SwiperSlide>
              ))}
        </Swiper>

        {/* <Box
          mb={5}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: 2,
          }}
        >
          {dashboardAthletesDetails?.map((detail, index) => (
            <Box sx={{ width: "700px" }}>
              <LatestArticlesCard detail={detail} key={index} />
            </Box>
          ))}
        </Box> */}

        {/* ------------------------More Articles----------------------- */}
        <Grid
          container
          spacing={3}
          mb={5}
          sx={{ justifyContent: { xs: "center", sm: "left" } }}
        >
          <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              fontSize={{ xs: "28px", md: "36px" }}
              width="100%"
              mt={4}
              mb={1}
            >
              More Articles
            </Typography>
          </Grid>

          {isAllDataLoading
            ? Array.from({ length: 10 }, (_, index) => (
                <Grid
                  key={index}
                  item
                  xs={11}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={3}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "left" },
                  }}
                >
                  <MoreArticlesCard isAllDataLoading={isAllDataLoading} />
                </Grid>
              ))
            : articles?.moreArticles?.map((detail, index) => (
                <Grid
                  key={index}
                  item
                  xs={11}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={3}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "left" },
                  }}
                >
                  <MoreArticlesCard detail={detail} key={index} />
                </Grid>
              ))}
        </Grid>
      </Box>
    </>
  );
};

export default Articles;
