import { BASE_API_URL } from "../../utils/constants";

const base = BASE_API_URL;

export const sportsList = async (path, extend) => {
  const token = localStorage.getItem("token");
  try {
    const url = `${base}${path}?extend=${extend}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { success: false, message: results.message };
    }
    return results;
  } catch (error) {
    return { success: false, message: "network error" };
  }
};
