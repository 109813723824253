import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";

// import CampaignBrand from "../../../../../../assets/images/CampaignBrand.png";
// import CampaignBrandBackground from "../../../../../../assets/images/CampaignBrandBackground.png";
import DefaultBrandLogo from "../../../../../../assets/logo/DefaultBrandLogo.png";

import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import dayjs from "dayjs";
import { ChevronRight } from "@mui/icons-material";
import {
  applyToCampaign,
  withdrawFromCampaign,
} from "../../../../../../services/campaign/campaign.service";
import { useNavigate } from "react-router-dom";
import CustomSkeleton from "../../../../../../themes/@overrides/customSkeleton";

const TodaysTaskCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  // const CampaignBrand = `${BASE_IMAGE_URL}/CampaignBrand.png`;
  // const CampaignBrandBackground = `${BASE_IMAGE_URL}/CampaignBrandBackground.png`;
  const navigate = useNavigate();

  // const [isImageError, setIsImageError] = useState(false);

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
    // setIsImageError(true);
  };

  const handleViewDetails = () => {
    navigate(`/dashboard/campaigns/view-details/${detail?._id}`);
  };

  return (
    <Box
      sx={{
        mx: "auto",
        border: `1px solid grey`,
        borderRadius: "5px",
        overflow: "hidden",
      }}
      onClick={isAllDataLoading ? null : handleViewDetails}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          background: "rgba(37,37,37,1)",
          p: "15px",
          height: "140px",
        }}
      >
        {isAllDataLoading ? (
          <CustomSkeleton type="img" sx={{ width: "140px", height: "100%" }} />
        ) : (
          <Box
            sx={{
              width: "140px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // py: 1,
            }}
          >
            <Box
              component="img"
              src={detail?.brandLogo}
              alt="Brand Logo"
              id="brandLogo"
              onError={handleImageError}
              sx={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            ></Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "calc(100% - 140px)",
          }}
        >
          <Typography
            fontSize={theme.fonts.cardHeading}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {isAllDataLoading ? <CustomSkeleton type="p" /> : detail?.brandName}
          </Typography>

          <Typography
            fontFamily={theme.fonts.livvic}
            fontSize="14px"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.endDate && (
                <>
                  <span style={{ color: "rgba(217,217,217,1)" }}>
                    End Date-&nbsp;
                  </span>
                  {dayjs(detail?.endDate).format("MMM DD YYYY")}
                </>
              )
            )}
          </Typography>
        </Box>
      </Box>

      {/* -----------------------------Below Image------------------------------------- */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: "15px",
        }}
      >
        <Typography
          fontSize={theme.fonts.cardSubHeading}
          fontWeight={theme.fonts.fontWeightBold}
          fontFamily={theme.fonts.livvic}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            detail?.campaignName
          )}
        </Typography>

        <Box
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Typography
            fontSize={theme.fonts.cardHighlights}
            fontWeight={theme.fonts.fontWeightMedium}
            fontFamily={theme.fonts.livvic}
          >
            Activities
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: theme.fonts.livvic,
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.activities?.map((activity, index) => {
                const activitiesLength = detail?.activities?.length;
                const remainingActivities = activitiesLength - 1;
                return index < 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: "12px",
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {activity}
                  </Box>
                ) : index === 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: "12px",
                      maxWidth: "90px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`+${remainingActivities} Other${
                      remainingActivities !== 1 ? "s" : ""
                    }`}
                  </Box>
                ) : null;
              })
            )}
          </Box>
        </Box>
        {isAllDataLoading ? (
          <CustomSkeleton type="p" sx={{ minWidth: "100%", height: "8px" }} />
        ) : (
          <LinearProgress
            variant="determinate"
            value={detail?.completion}
            sx={{
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "red",
              },
            }}
          />
        )}
        <Typography
          fontSize={theme.fonts.cardHighlights}
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          Progress
          <span
            style={{
              color: "red",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              `${(detail?.completion).toFixed(2)}%`
            )}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default TodaysTaskCard;
