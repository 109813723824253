import { cityList, countryList, interestsList, stateList } from "./api.request";

const INTERESTLIST_PATH = "/data/interestsList";
const COUNTRY_PATH = "/data/countryList";
const STATE_PATH = "/data/statesList";
const CITY_PATH = "/data/citiesList";

export const dataInterestList = async (body) =>
  interestsList(`${INTERESTLIST_PATH}`, body);

export const dataCountryList = async (body) =>
  countryList(`${COUNTRY_PATH}`, body);

export const dataStateList = async (country) =>
  stateList(`${STATE_PATH}`, country);

export const dataCityList = async (country, state) =>
  cityList(`${CITY_PATH}`, country, state);
