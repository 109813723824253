import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CompletedProjectsListCard from "../../../../../components/afterLogin/athletes/home/subComponents/statistics/statistics.completedProjects.list.card";
import { getCompletedProjects } from "../../../../../services/campaign/campaign.service";
import NoActiveCampaign from "../../../../../assets/icons/NoActiveCampaign.svg";

const CompletedProjectsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [completedProjects, setCompletedProjects] = useState([]);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleBack = () => {
    navigate(-1);
  };

  const fetchCompletedProjects = async () => {
    const response = await getCompletedProjects();
    setIsAllDataLoading(false);
    setCompletedProjects(response?.data?.data);
  };

  useEffect(() => {
    fetchCompletedProjects();
  }, []);

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* ----------------Top Header Icons-------------------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", sm: "40px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
        >
          Completed Projects
        </Typography>
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          my: "30px",
          px: "20px",
          pt: "40px",
          pb: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
          minHeight: { xs: "calc(100vh - 425px)", sm: "calc(100vh - 450px)" },
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent:
              completedProjects?.length > 0
                ? { xs: "center", sm: "left" }
                : "center",
          }}
        >
          {isAllDataLoading ? (
            Array.from({ length: 10 }, (_, index) => (
              <Grid item xs={10} sm={6} md={6} lg={4} xl={3}>
                <CompletedProjectsListCard
                  isAllDataLoading={isAllDataLoading}
                />
              </Grid>
            ))
          ) : completedProjects?.length ? (
            completedProjects?.map((detail, index) => (
              <Grid item xs={10} sm={6} md={6} lg={4} xl={3}>
                <CompletedProjectsListCard detail={detail} key={index} />
              </Grid>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
                fontSize: { xs: "24px", sm: "30px" },
                minHeight: "400px",
                width: "100%",
                background: "rgba(18,18,18,1)",
                borderRadius: "5px",
              }}
            >
              <Box
                component="img"
                src={NoActiveCampaign}
                alt="No Active Campaign"
                sx={{ width: { xs: "70px", sm: "100px" } }}
              ></Box>
              No completed campaigns yet!
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default CompletedProjectsList;
