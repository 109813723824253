import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

import Sports from "../../../../../../assets/icons/Sports.svg";
import School from "../../../../../../assets/icons/School.svg";
import InstagramIcon from "../../../../../../assets/icons/InstagramPublicProfileIcon.svg";
import FacebookIcon from "../../../../../../assets/icons/FacebookPublicProfileIcon.svg";
import TwitterIcon from "../../../../../../assets/icons/TwitterPublicProfileIcon.svg";
import LinkedInIcon from "../../../../../../assets/icons/LinkedInPublicProfileIcon.svg";
import Youtubecon from "../../../../../../assets/icons/YoutubePublicProfileIcon.svg";
import TiktokIcon from "../../../../../../assets/icons/TikTokPublicProfileIcon.svg";
import BurrstLogoIcon from "../../../../../../assets/icons/BurrstLogoIcon.svg";
import DefaultImg from "../../../../../../assets/images/DefaultAvatar.jpg";
import { showInterestAiAthlete } from "../../../../../../services/campaign/campaign.service";

const AIrecommendedCard = ({
  detail,
  handleInterested,
  handleNotInterested,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: { xs: "90%", md: "43%" },
        my: "20px",
        mx: "auto",
        border: `1px solid grey`,
        background: theme.colors.common.black,
        overflow: "hidden",
      }}
    >
      <Box
        // data-aos="fade-up"
        // data-aos-duration="1500"
        overflow="hidden"
        sx={{ p: { xs: "10px", md: "30px" } }}
      >
        <Box
          // height="180px"
          overflow="hidden"
          sx={{
            pt: "20px",
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "100px",
              height: "100px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={detail?.profileImage || DefaultImg}
              alt="Avatar"
              id="avatar"
              sx={{ width: "100%", height: "100%" }}
              onError={(e) => {
                e.target.src = DefaultImg;
              }}
            ></Box>
            <Box
              component="img"
              src={BurrstLogoIcon}
              alt="Burrst Logo Icon"
              sx={{
                position: "absolute",
                top: "-30%",
                right: "-30%",
              }}
              id="Burrst Logo Icon"
            ></Box>
          </Box>
          <Typography
            display="flex"
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightMedium}
            sx={{ fontSize: { xs: "18px", md: "20px" } }}
          >
            {detail?.name}
          </Typography>
        </Box>

        <Box display="flex" gap={1} mt={2} maxHeight="30px" overflow="hidden">
          <Box component="img" src={Sports} alt={`Sports ${detail?.id}`}></Box>
          <Typography
            fontFamily={theme.fonts.livvic}
            sx={{ fontSize: { xs: "16px", md: "18px" } }}
          >
            Sports: {detail?.sports.slice(0, 3).join(", ")}
            {detail?.sports.length > 2 && "..."}
          </Typography>
        </Box>
        <Box display="flex" gap={1} my={2} maxHeight="30px" overflow="hidden">
          <Box component="img" src={School} alt={`Sports ${detail?.id}`}></Box>
          <Typography
            fontFamily={theme.fonts.livvic}
            sx={{ fontSize: { xs: "16px", md: "18px" } }}
          >
            School: {detail?.school}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          gap={2}
          mb={3}
          height="70px"
        >
          {detail?.instagramLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.instagramLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={InstagramIcon}
                  alt="Instagram Icon"
                />
              </Box>
              {detail?.instagramFollowerCount && (
                <Typography>
                  {detail?.instagramFollowerCount < 1000
                    ? detail?.instagramFollowerCount
                    : detail?.instagramFollowerCount < 1000000
                    ? (detail?.instagramFollowerCount % 1000 === 0
                        ? (detail?.instagramFollowerCount / 1000).toFixed(0)
                        : (detail?.instagramFollowerCount / 1000).toFixed(1)) +
                      "K"
                    : detail?.instagramFollowerCount % 1000000 === 0
                    ? (detail?.instagramFollowerCount / 1000000).toFixed(0) +
                      "M"
                    : (detail?.instagramFollowerCount / 1000000).toFixed(1) +
                      "M"}
                </Typography>
              )}
            </Box>
          )}
          {detail?.facebookLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.facebookLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={FacebookIcon}
                  alt="Facebook Icon"
                />
              </Box>
              <Typography sx={{ fontSize: { xs: "15px", md: "17px" } }}>
                N/A
              </Typography>
            </Box>
          )}
          {detail?.twitterLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.twitterLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TwitterIcon}
                  alt="Twitter Icon"
                />
              </Box>
              <Typography sx={{ fontSize: { xs: "15px", md: "17px" } }}>
                N/A
              </Typography>
            </Box>
          )}
          {detail?.linkedinLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.linkedinLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={LinkedInIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography sx={{ fontSize: { xs: "15px", md: "17px" } }}>
                N/A
              </Typography>
            </Box>
          )}
          {/* -----------------Youtube Icon---------------------- */}
          {detail?.youtubeLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.youtubeLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={Youtubecon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {/* -----------------Tiktok Icon---------------------- */}
          {detail?.tiktokLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() => window.open(detail?.tiktokLink, "_blank")}
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TiktokIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              {detail?.tiktokFollowerCount && (
                <Typography>
                  {detail?.tiktokFollowerCount < 1000
                    ? detail?.tiktokFollowerCount
                    : detail?.tiktokFollowerCount < 1000000
                    ? (detail?.tiktokFollowerCount % 1000 === 0
                        ? (detail?.tiktokFollowerCount / 1000).toFixed(0)
                        : (detail?.tiktokFollowerCount / 1000).toFixed(1)) + "K"
                    : detail?.tiktokFollowerCount % 1000000 === 0
                    ? (detail?.tiktokFollowerCount / 1000000).toFixed(0) + "M"
                    : (detail?.tiktokFollowerCount / 1000000).toFixed(1) + "M"}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          width="100%"
          gap={1}
        >
          {!detail?.interested && (
            <>
              <Button
                onClick={() => handleNotInterested(detail?._id)}
                sx={{
                  width: "50%",
                  border: "1px solid #797979",
                  color: "#797979",
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                  textTransform: "none",
                  fontSize: { xs: "14px", md: "16px" },
                }}
              >
                Not Interested
              </Button>
              <Button
                onClick={() => handleInterested(detail?._id)}
                sx={{
                  width: "47%",
                  color: "white",
                  background: "#F30000",
                  border: "1px solid #F30000",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                  textTransform: "none",
                  fontSize: { xs: "14px", md: "16px" },
                }}
              >
                Interested
              </Button>
            </>
          )}

          {detail?.interested && (
            <Button
              onClick={() => handleInterested(detail?._id)}
              sx={{
                width: "94%",
                color: "white",
                background: "#F30000",
                border: "1px solid #F30000",
                "&:hover": {
                  backgroundColor: "darkred",
                },
                fontFamily: theme.fonts.livvic,
                fontWeight: 500,
                textTransform: "none",
                fontSize: "18px",
              }}
            >
              Interested
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AIrecommendedCard;
