import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBrandDetails } from "../services/brand/brand.service";
import { getAthletesProfileDetailsApi } from "../services/athlete/api.request";

const initialMyProfileState = {
  isMyProfileDataLoading: false,
  myProfile: null,
};

const initialOtherProfileState = {
  isOtherProfileDataLoading: false,
  otherProfile: null,
};

export const fetchMyProfile = createAsyncThunk(
  "fetchMyProfile",
  async ({ userType, userId }) => {
    let response = [];
    if (userType === "BRAND") {
      response = await getBrandDetails(userId);
    } else if (userType === "ATHLETE") {
      response = await getAthletesProfileDetailsApi(userId);
    }

    if (response?.data?.status === 200) {
      return response?.data?.data;
    } else {
      console.error("Failed Response Dashboard Navbar: ", response);
    }
  }
);
export const fetchOtherProfile = createAsyncThunk(
  "fetchOtherProfile",
  async ({ userType, userId }) => {
    let response = [];
    if (userType === "BRAND") {
      response = await getBrandDetails(userId);
    } else if (userType === "ATHLETE") {
      response = await getAthletesProfileDetailsApi(userId);
    }

    if (response?.data?.status === 200) {
      return response?.data?.data;
    } else {
      console.error("Failed Response Dashboard Navbar: ", response);
    }
  }
);

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState: initialMyProfileState,
  extraReducers: (builder) => {
    builder.addCase(fetchMyProfile.pending, (state, action) => {
      state.isMyProfileDataLoading = true;
      state.myProfile = null;
    });
    builder.addCase(fetchMyProfile.fulfilled, (state, action) => {
      state.isMyProfileDataLoading = false;
      state.myProfile = action.payload;
    });
    builder.addCase(fetchMyProfile.rejected, (state, action) => {
      state.isMyProfileDataLoading = false;
      state.myProfile = null;
    });
  },
});

const otherProfileSlice = createSlice({
  name: "otherProfile",
  initialState: initialOtherProfileState,
  extraReducers: (builder) => {
    builder.addCase(fetchOtherProfile.pending, (state, action) => {
      state.isOtherProfileDataLoading = true;
      state.otherProfile = null;
    });
    builder.addCase(fetchOtherProfile.fulfilled, (state, action) => {
      state.isOtherProfileDataLoading = false;
      state.otherProfile = action.payload;
    });
    builder.addCase(fetchOtherProfile.rejected, (state, action) => {
      state.isOtherProfileDataLoading = false;
      state.otherProfile = null;
    });
  },
});

export const myProfileReducer = myProfileSlice.reducer;
export const otherProfileReducer = otherProfileSlice.reducer;
