import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import "../styles/sideNavbar.css";

import DashboardIcon from "../assets/icons/DashboardIcon.svg";
import AnalyticsIcon from "../assets/icons/AnalyticsIcon.svg";
import ChatIcon from "../assets/icons/ChatIcon.svg";
// import ProfileIcon from "../assets/icons/ProfileIcon.svg";
import ExploreIcon from "../assets/icons/ExploreIcon.svg";
import CampaignsIcon from "../assets/icons/CampaignsIcon.svg";
import DealTrackerIcon from "../assets/icons/DealTrackerIcon.svg";
import EarningsIcon from "../assets/icons/EarningsIcon.svg";
import WalletIcon from "../assets/icons/WalletIcon.svg";
import AthletesIcon from "../assets/icons/AthletesIcon.svg";
import ResourcesIcon from "../assets/icons/ResourcesIcon.svg";
import PodcastIcon from "../assets/icons/PodcastIcon.svg";
import NewsletterIcon from "../assets/icons/NewsletterIcon.svg";
import EducationIcon from "../assets/icons/EducationIcon.svg";
import FAQsIcon from "../assets/icons/FAQsIcon.svg";
import UsersIcon from "../assets/icons/User.svg";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const SideNavbar = ({ showNavbarDetails, setShowNavbarDetails }) => {
  const theme = useTheme();
  const [userType, setUserType] = useState("");
  const isAdmin = localStorage.getItem("isBrandAdmin");

  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
  }, [userType]);

  const sideNavLinks = [
    {
      path: "/dashboard/home",
      iconName: DashboardIcon,
      alt: "Dashboard Icon",
      description: "Home",
    },
    {
      path: "/dashboard/analytics",
      iconName: AnalyticsIcon,
      alt: "Analytics Icon",
      description: "ROI Analytics",
    },
    {
      path: "/dashboard/deal-tracker",
      iconName: DealTrackerIcon,
      alt: "Deal Tracker Icon",
      description: "Deal Tracker",
    },
    {
      path: "/dashboard/chat",
      iconName: ChatIcon,
      alt: "Chat Icon",
      description: "Chat",
    },
    {
      path: "/dashboard/explore-brands",
      iconName: ExploreIcon,
      alt: "Explore Icon",
      description: "Explore Brands",
    },
    {
      path: "/dashboard/campaigns",
      iconName: CampaignsIcon,
      alt: "Campaigns Icon",
      description: "Campaigns",
    },
    {
      path: "/dashboard/earnings",
      iconName: EarningsIcon,
      alt: "Earnings Icon",
      description: "Earnings",
    },
    {
      path: "/dashboard/athletes",
      iconName: AthletesIcon,
      alt: "Athletes Icon",
      description: "Athletes",
    },
    {
      path: "/dashboard/wallet",
      iconName: WalletIcon,
      alt: "Wallet Icon",
      description: "Wallet",
    },

    {
      path: "/dashboard/users",
      iconName: UsersIcon,
      alt: "Users Icon",
      description: "Users",
    },
    {
      path: "/dashboard/resources",
      iconName: ResourcesIcon,
      alt: "Resources Icon",
      description: "Resources",
      subMenu: [
        {
          path: "/dashboard/resources/podcast",
          iconName: PodcastIcon,
          alt: "Podcast Icon",
          description: "Podcast",
        },
        {
          path: "/dashboard/resources/newsletter",
          iconName: NewsletterIcon,
          alt: "Newsletter Icon",
          description: "Newsletter",
        },
        {
          path: "/dashboard/resources/education",
          iconName: EducationIcon,
          alt: "Education Icon",
          description: "Education",
        },
        {
          path: "/dashboard/resources/faqs",
          iconName: FAQsIcon,
          alt: "FAQs Icon",
          description: "FAQs",
        },
      ],
    },
    // {
    //   path: "/dashboard/articles",
    //   iconName: ArticleIcon,
    //   alt: "Articles Icon",
    //   description: "Articles",
    // },
  ];

  const filteredNavLinks = sideNavLinks?.filter((link) => {
    if (userType === "BRAND" && isAdmin !== "true") {
      return (
        link?.iconName !== ExploreIcon &&
        link?.iconName !== EarningsIcon &&
        link?.iconName !== UsersIcon
      );
    }
    if (userType === "BRAND") {
      return link?.iconName !== ExploreIcon && link?.iconName !== EarningsIcon;
      // isAdmin === false
      // ? link?.iconName !== EarningsIcon
      // : null;
    }
    if (userType === "ATHLETE") {
      return (
        link?.iconName !== AnalyticsIcon &&
        link?.iconName !== AthletesIcon &&
        link?.iconName !== WalletIcon &&
        link?.iconName !== UsersIcon
      );
    }
    return true;
  });

  const linkStyles = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
      color: isActive ? theme.colors.common.white : theme.colors.text.greyDark,
      background: isActive ? theme.colors.gradientBlackRed : "rgba(18,18,18,1)",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      gap: "6px",
      padding: "4px 30px",
      borderLeft: isActive ? `4px solid red` : 0,
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
      fontFamily: theme.fonts.livvic,
      cursor: "pointer",
      height: "40px",
      // position: "relative",
      // "&::before": {
      //   content: '""',
      //   position: "absolute",
      //   top: 0,
      //   left: 0,
      //   background: theme.colors.gradientBlackRed,
      //   zIndex: 2,
      //   width: "7px",
      //   height: "100%",
      //   borderTopRightRadius: "5px",
      //   borderBottomRightRadius: "5px",
      // },
    };
  };
  const resourceLinkStyles = ({ isActive }) => {
    return {
      display: "flex",
      alignItems: "center",
      gap: "2px",
      color: isActive ? "white" : theme.colors.text.greyDark,
      fontWeight: isActive ? "bold" : "normal",
      width: "100%",
      padding: "6px 20px 6px 40px",
      fontSize: "14px",
    };
  };

  const [open, setOpen] = useState(false);

  const handleResources = (e) => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: { xs: "50px", sm: "70px" },
        left: "0",
        pt: "45px",
        pb: "100px",
        display: "flex",
        flexDirection: "column",
        gap: { xs: 0.5, md: 1 },
        height: "100vh",
        width: showNavbarDetails ? "200px" : "95px",
        color: "white",
        background: "rgba(18,18,18,1)",
        zIndex: 2,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "2px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "grey",
          borderRadius: "4px",
        },
      }}
      id="targetContainer"
    >
      {filteredNavLinks?.map((sideNavLink, index) => (
        <Box key={index}>
          {sideNavLink?.subMenu ? (
            <>
              <Box
                sx={linkStyles}
                className="linkStyles"
                onClick={handleResources}
                mb="2px"
              >
                <Box
                  component="img"
                  src={sideNavLink?.iconName}
                  alt={sideNavLink?.alt}
                  width="22px"
                  height="22px"
                  mr="2px"
                ></Box>
                <Box component="span" className="activeText">
                  {showNavbarDetails && sideNavLink?.description}
                </Box>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Box>
              {open && (
                <Box>
                  {sideNavLink.subMenu?.map((subMenuItem, subIndex) => (
                    <NavLink
                      to={subMenuItem?.path}
                      className="resourceStyle"
                      style={resourceLinkStyles}
                      key={subIndex}
                      onClick={() =>
                        setShowNavbarDetails(window.innerWidth > 900)
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <Box
                          component="img"
                          src={subMenuItem?.iconName}
                          alt={subMenuItem?.alt}
                          width="14px"
                          height="14px"
                        ></Box>
                      </Box>

                      <Box component="span" className="activeText">
                        {showNavbarDetails && subMenuItem?.description}{" "}
                      </Box>
                    </NavLink>
                  ))}
                </Box>
              )}
            </>
          ) : (
            <NavLink
              to={sideNavLink?.path}
              style={linkStyles}
              className="linkStyles"
              onClick={() => setShowNavbarDetails(window.innerWidth > 900)}
            >
              <Box
                component="img"
                src={sideNavLink?.iconName}
                alt={sideNavLink?.alt}
                width="22px"
                height="22px"
                mr="2px"
              ></Box>
              <Box component="span" className="activeText">
                {showNavbarDetails && sideNavLink?.description}
              </Box>
            </NavLink>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SideNavbar;
