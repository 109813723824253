import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useMemo } from "react";

import colors from "./colors";
import fonts from "./fonts";

const Theme = ({ children }) => {
  const themeOptions = useMemo(
    () => ({
      colors,
      fonts,
    }),
    []
  );

  const theme = createTheme(themeOptions);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
