import React, { useState } from "react";
import {
  Button,
  DialogContent,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";

const DeclineSelectedAthleteUpdateModal = ({
  handleDeclineDialogClose,
  campaignId,
  athleteId,
  deliverableId,
  callApi,
  navigate,
}) => {
  const theme = useTheme();
  const [description, setDescription] = useState("");

  const handleSave = () => {
    if (!description || description.trim() === "") {
      toast.error("Reason cannot be empty");
      return;
    }

    callApi({
      campaignId: campaignId,
      athleteId: athleteId,
      deliverableId: deliverableId,
      accept: false,
      rejectionReason: description,
    });
    // navigate("/dashboard/campaigns/selected-athlete");
    // window.location.reload();
  };

  const handleClose = () => {
    handleDeclineDialogClose();
  };

  return (
    <DialogContent
      sx={{
        paddingX: { xs: "10px", md: "30px" },
        backgroundColor: "black",
        border: `0.5px solid #121212`,
        color: "white",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      {" "}
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Grid display="flex" justifyContent="end">
        <CloseIcon
          onClick={handleClose}
          sx={{
            color: "#797979",
            cursor: "pointer",
          }}
        />
      </Grid>
      <Grid>
        <Typography
          sx={{
            color: "white",
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: theme.fonts.fontWeightMedium,
            fontFamily: theme.fonts.livvic,
          }}
        >
          Reason for rejection
        </Typography>
        <TextField
          name="description"
          multiline
          rows={8}
          fullWidth
          autoComplete="off"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          InputProps={{
            sx: {
              border: `1px solid ${theme.colors.common.white}`,
              background: theme.colors.background.formInput,
              color: theme.colors.common.white,
              borderRadius: "10px",
            },
          }}
          sx={{
            marginTop: "5px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                fieldset: {
                  borderColor: theme.colors.common.white,
                },
              },
            },
          }}
        />
      </Grid>
      <Grid
        mt="40px"
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          onClick={handleSave}
          sx={{
            width: "45%",
            background: "#FF0101",
            color: "white",
            "&:hover": {
              backgroundColor: "darkred",
            },
            fontFamily: theme.fonts.montserrat,
            fontWeight: theme.fonts.fontWeightBold,
            textTransform: "none",
            fontSize: { xs: "18px", md: "24px" },
          }}
        >
          SAVE
        </Button>
      </Grid>
    </DialogContent>
  );
};

export default DeclineSelectedAthleteUpdateModal;
