import axios from "axios";
import { getToken } from "../../routes";
import { getFirebaseToken } from "../../firebase";
import { BASE_API_URL } from "../../utils/constants";

const base = BASE_API_URL;

let instagramBrowser;

if ("serviceWorker" in navigator) {
  // alert("service worker supported.");
  instagramBrowser = true;
} else {
  // alert("Not supported");
  instagramBrowser = false;
}

// export const getToken = () => {
//   const token = localStorage?.getItem("token");
//   return token;
// };

const handleError = (error) => {
  if (error?.response?.status === 500) return "Internal Server Error";
  else
    return (
      error?.response?.message ||
      error?.response?.data?.message ||
      "Some Error Occurred"
    );
};

export const exchangeTokenApi = async (path) => {
  try {
    const url = `${base}${path}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return response;
  } catch (error) {
    return { data: handleError(error) };
  }
};

export async function login(path, data) {
  try {
    const url = `${base}${path}`;
    const fbToken = instagramBrowser === true ? await getFirebaseToken() : null;
    if (fbToken) {
      data.firebaseToken = fbToken ? fbToken : undefined;
      data.deviceName = "web";
    }
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const results = await response.json();
    if (![200, 301, 302].includes(results.status)) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function logout(path) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    const results = await response.json();
    if (results.status !== 200) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}

export async function forgotPassword(path, email) {
  try {
    const url = `${base}${path}?email=${email}`;
    const response = await fetch(url);
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}

export async function resetPassword(path, body) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: body.email,
        temporaryPassword: body.temporaryPassword,
        newPassword: body.newPassword,
      }),
    });
    const results = await response.json();

    if (response.status !== 200) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}
export async function changePassword(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios.put(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
}

export const authDeleteAccountApi = async (path) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};
