import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

// ------------------------Layouts----------------------
import Layout from "./layouts/layout";
import DashboardLayout from "./layouts/dashboardLayout";
import MinimalLayout from "./layouts/minimalLayout";

// -------------------------auth--------------------
import Login from "./pages/auth/login";
import ForgotPassword from "./pages/auth/forgotPassword";
import ResetPassword from "./pages/auth/resetPassword";
import OtpVerification from "./pages/auth/otpVerification";

// ----------------------registration-------------------

import MyProfileAthlete from "./pages/beforeLogin/registration/myProfile.athlete.page";
import PublicProfileAthlete from "./pages/beforeLogin/registration/publicProfile.athlete.page";
import PublicProfileBrand from "./pages/beforeLogin/registration/publicProfile.brand.page";
import RegisterAthlete from "./pages/beforeLogin/registration/register.athlete.page";
import RegisterBrand from "./pages/beforeLogin/registration/register.brand.page";

// -----------------------Before Login----------------
import Home from "./pages/beforeLogin/home";
import UserTypeSelection from "./pages/beforeLogin/userTypeSelection";

// ---------------------After Login---------------

// ------------Brands-----------------

import AthletesList from "./pages/afterLogin/brands/home/athletes/athletes.list.page";
import CampaignsList from "./pages/afterLogin/brands/home/myCampaign/campaigns.list.page";
import CampaignsBasicInfo from "./pages/afterLogin/brands/home/myCampaign/campaigns.basicInfo.page";
import CampaignsAdditionalInfo from "./pages/afterLogin/brands/home/myCampaign/campaigns.additionalInfo.page";
import CampaignsDeliverable from "./pages/afterLogin/brands/home/myCampaign/campaigns.deliverable.page";
import CampaignsContract from "./pages/afterLogin/brands/home/myCampaign/campaigns.contract.page";
import CampaignViewDetails from "./pages/afterLogin/brands/home/myCampaign/campaign.viewdetails.page";
import CampaignActivityDetails from "./pages/afterLogin/brands/home/myCampaign/campaign.activitydetails.page";
import CampaignViewApplicants from "./pages/afterLogin/brands/home/myCampaign/campaign.viewapplicant.page";
import CampaignAiRecommended from "./pages/afterLogin/brands/home/myCampaign/campaign.airecommended.page";
import CampaignSelectedAthlete from "./pages/afterLogin/brands/home/myCampaign/campaign.selectedathlete.page";
import CampaignSignedAthleteUpdatePage from "./pages/afterLogin/brands/home/myCampaign/campaign.signedAthleteUpdate.page";
import CampaignAthleteProfileDetails from "./pages/afterLogin/brands/athletesProfile/athletesProfile.page";

import BrandsHome from "./pages/afterLogin/brands/home/home.list.page";
import Podcasts from "./pages/afterLogin/brands/podcasts/podcasts.list.page";

import OnGoingRoiAnalyticsPage from "./pages/afterLogin/brands/roiAnalytics/onGoingRoiAnalytics.page";
import CompletedRoiAnalyticsPage from "./pages/afterLogin/brands/roiAnalytics/completedRoiAnalytics.page";

import SubscriptionPage from "./pages/afterLogin/brands/subscription.page";
// import BrandsArticles from "./pages/afterLogin/brands/articles/articles.list.page";

// ------------Athletes---------------------

import AthletesHome from "./pages/afterLogin/athletes/home/home.list.page";
import AthletesCampaignsList from "./pages/afterLogin/athletes/home/campaigns/campaigns.list.page";
import AthletesCampaignsViewDetails from "./pages/afterLogin/athletes/home/campaigns/campaigns.viewDetails.page";
import ActivityDetails from "./pages/afterLogin/athletes/home/campaigns/activitydetails.page";
import Articles from "./pages/afterLogin/athletes/articles/articles.list.page";

//import DashboardExplore from "./pages/dashboard.explore";

// ------------------Error--------------------

import PageNotFound from "./pages/error/404";
import ArticlesDetails from "./pages/afterLogin/athletes/articles/articles.details.page";
import BrandsList from "./pages/afterLogin/athletes/home/brands/brands.list.page";
import StatisticsPage from "./pages/afterLogin/athletes/home/statistics/statistics.page";
import BrandsCampaignsList from "./pages/afterLogin/athletes/home/brands/brands.campaigns.list.page";
import BrandsMyProfile from "./pages/afterLogin/athletes/home/brands/brands.myProfile.page";
import MyAccounts from "./pages/afterLogin/athletes/home/myAccounts.page";
import BankDetails from "./pages/afterLogin/athletes/home/bankDetails.page";

import { ATHLETE, BRAND } from "./utils/constants";
import ContractPage from "./pages/afterLogin/athletes/home/campaigns/contract.page";
import CompletedProjectsList from "./pages/afterLogin/athletes/home/statistics/statistics.completedProjects.list";
import ChatPage from "./pages/afterLogin/athletes/chat/chat.page";
import Education from "./pages/afterLogin/common/resources/education/education.page";
import FAQs from "./pages/afterLogin/common/resources/faqs/faqs.page";
import Earnings from "./pages/afterLogin/athletes/earnings/earnings.page";
import EarningDetails from "./pages/afterLogin/athletes/earnings/earnings.details.page";
import ViewRoiDetailsPage from "./pages/afterLogin/brands/roiAnalytics/viewRoiDetails.page";
import ChangePassword from "./pages/auth/changePassword";
import Wallet from "./pages/afterLogin/brands/wallet/wallet.page";
import Transactions from "./pages/afterLogin/brands/wallet/transactions.page";
import Notifications from "./pages/afterLogin/common/notifications/notifications";
import DealTrackerPage from "./pages/afterLogin/brands/dealTracker/dealTracker.page";
import MySubscriptionPage from "./pages/afterLogin/brands/athletesProfile/mySubscription/mySubscription.page";
import MySubscriptionTransactionPage from "./pages/afterLogin/brands/athletesProfile/mySubscription/mySubscriptionTransactionDetails.page";
import ChatAdminPage from "./pages/afterLogin/athletes/chat/chat.admin.page";
import AthleteDealTrackerPage from "./pages/afterLogin/athletes/dealTracker/dealTracker.page";
import AthletesDescription from "./pages/beforeLogin/athletes";
import BrandsDescription from "./pages/beforeLogin/brands";
import Mission from "./pages/beforeLogin/mission";
import Demo from "./pages/beforeLogin/demo";
import PrivacyPolicy from "./pages/beforeLogin/privacyPolicy";
import TermsOfUse from "./pages/beforeLogin/termsOfUse";
import ContactUs from "./pages/beforeLogin/contactUs";
import Team from "./pages/beforeLogin/team";
import UsersListPage from "./pages/afterLogin/brands/users/usersList.page";
// import ChangePlan from "./pages/changePlan";
// import MyAccountsFromMobile from "./pages/afterLogin/athletes/home/myAccounts.fromMobile.page";
// import WalletMobile from "./pages/wallet.mobile";
// import EarningsMobile from "./pages/earnings.mobile";

export const getToken = () => {
  const token = localStorage?.getItem("token");
  return token;
};
export const getUserId = () => {
  const userId = localStorage?.getItem("userId");
  return userId;
};

// const Loadable = (Component) => (props) => {
//   return (
//     <Suspense fallback={<ScreenLoader />}>
//       <Component {...props} />
//     </Suspense>
//   );
// };

// const Home = lazy(() => import("./pages/beforeLogin/home"));

const Router = () => {
  const data =
    useSelector((state) => state?.authData?.userData?.usertype) ||
    localStorage.getItem("usertype");

  const isAdmin = useSelector(
    (state) => state?.authData?.userData?.isBrandAdmin
  );

  return (
    <Routes>
      {/* ---------------------------Authentication----------------------------- */}

      <Route path="/login" element={<MinimalLayout />}>
        <Route index element={<Login />} />
      </Route>

      {/* ------------------------Before Login---------------------------------- */}

      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="athletes" element={<AthletesDescription />} />
        <Route path="brands" element={<BrandsDescription />} />
        <Route path="mission" element={<Mission />} />
        <Route path="demo" element={<Demo />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="team" element={<Team />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />

      <Route path="/user-type-selection" element={<MinimalLayout />}>
        <Route index element={<UserTypeSelection />} />
      </Route>

      <Route path="/athletes" element={<MinimalLayout />}>
        <Route path="register" element={<RegisterAthlete />} />
        <Route path="otp-verification" element={<OtpVerification />} />
        <Route path="profile" element={<MyProfileAthlete />} />
        <Route path="public-profile" element={<PublicProfileAthlete />} />
      </Route>

      <Route path="/brands" element={<MinimalLayout />}>
        <Route path="register" element={<RegisterBrand />} />
        <Route path="public-profile" element={<PublicProfileBrand />} />
      </Route>

      <Route path="/forgot-password" element={<MinimalLayout />}>
        <Route index element={<ForgotPassword />} />
      </Route>

      <Route path="/reset-password" element={<MinimalLayout />}>
        <Route index element={<ResetPassword />} />
      </Route>

      <Route path="/subscription">
        <Route index element={<SubscriptionPage />} />
        {/* <Route path=":encryptedDetails" element={<SubscriptionPage />} /> */}
      </Route>

      {/* <Route path="/changePlan/:data" element={<ChangePlan />} /> */}
      {/* <Route path="/wallet/:data" element={<WalletMobile />} /> */}
      {/* <Route path="/earnings/:data" element={<EarningsMobile />} /> */}

      {data === ATHLETE && (
        <Route path="/my-accounts">
          <Route index element={<MyAccounts />} />
          {/* <Route path=":encryptedDetails" element={<MyAccountsFromMobile />} /> */}
        </Route>
      )}
      <Route path="/bank-details" element={<BankDetails />} />
      <Route path="/change-password" element={<MinimalLayout />}>
        <Route index element={<ChangePassword />} />
      </Route>

      {/* --------------------------After Login--------------------------- */}
      {(data === BRAND || data === ATHLETE) && (
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Navigate replace={true} to="home" />} />
          {data === BRAND ? (
            <>
              <Route path="home">
                <Route index element={<BrandsHome />} />

                <Route path="my-profile" element={<BrandsMyProfile />} />

                <Route path="my-subscription">
                  <Route
                    index
                    element={
                      isAdmin ? (
                        <MySubscriptionPage />
                      ) : (
                        <Navigate to="/dashboard/home" />
                      )
                    }
                  />
                  <Route
                    path="transaction-details"
                    element={<MySubscriptionTransactionPage />}
                  />
                </Route>

                <Route
                  path="athlete-profile"
                  element={<CampaignAthleteProfileDetails />}
                />

                {/* <Route path="my-accounts" element={<MyAccountsPage />} />
                <Route path="link-bank" element={<LinkBankAccPage />} /> */}
              </Route>
              <Route path="athletes" element={<AthletesList />} />
              <Route path="campaigns">
                <Route index element={<CampaignsList />} />
                <Route path="basic-info" element={<CampaignsBasicInfo />} />
                <Route
                  path="additional-info"
                  element={<CampaignsAdditionalInfo />}
                />
                <Route path="deliverable" element={<CampaignsDeliverable />} />
                <Route path="contract" element={<CampaignsContract />} />
                <Route
                  path="view-details/:campaignId"
                  element={<CampaignViewDetails />}
                />
                <Route
                  path="activity-details"
                  element={<CampaignActivityDetails />}
                />
                <Route
                  path="view-applicants"
                  element={<CampaignViewApplicants />}
                />
                <Route
                  path="ai-recommended"
                  element={<CampaignAiRecommended />}
                />
                <Route
                  path="selected-athlete"
                  element={<CampaignSelectedAthlete />}
                />
                <Route
                  path="signed-athlete"
                  element={<CampaignSignedAthleteUpdatePage />}
                />
              </Route>
              <Route path="wallet">
                <Route index element={<Wallet />} />
                <Route path="transactions" element={<Transactions />} />
                {/* <Route path="contract" element={<ContractPage />} /> */}
              </Route>
              <Route
                path="users"
                element={
                  isAdmin ? (
                    <UsersListPage />
                  ) : (
                    <Navigate to="/dashboard/home" />
                  )
                }
              />
            </>
          ) : data === ATHLETE ? (
            <>
              <Route path="home">
                <Route index element={<AthletesHome />} />
                <Route
                  path="my-profile"
                  element={<CampaignAthleteProfileDetails />}
                />

                <Route path="statistics">
                  <Route index element={<StatisticsPage />} />
                  <Route
                    path="completed-projects"
                    element={<CompletedProjectsList />}
                  />
                </Route>
                <Route path="my-accounts" element={<MyAccounts />} />
                <Route path="bank-details" element={<BankDetails />} />
              </Route>

              <Route path="campaigns">
                <Route index element={<AthletesCampaignsList />} />
                <Route
                  path="view-details/:campaignId"
                  element={<AthletesCampaignsViewDetails />}
                />
                <Route path="activity-details" element={<ActivityDetails />} />
                <Route path="contract" element={<ContractPage />} />
              </Route>

              <Route path="statistics">
                <Route index element={<StatisticsPage />} />
                <Route
                  path="completed-projects"
                  element={<CompletedProjectsList />}
                />
              </Route>
            </>
          ) : null}
          {data === ATHLETE ? (
            <>
              <Route path="explore-brands">
                <Route index element={<BrandsList />} />
                <Route path="brands-list">
                  <Route index element={<BrandsCampaignsList />} />
                  <Route path="brand-profile" element={<BrandsMyProfile />} />
                </Route>
              </Route>

              <Route path="earnings">
                <Route index element={<Earnings />} />
                <Route path="details" element={<EarningDetails />} />
              </Route>

              <Route path="deal-tracker" element={<AthleteDealTrackerPage />} />
            </>
          ) : null}

          {data === BRAND && (
            <>
              <Route path="analytics">
                <Route
                  index
                  //path="ongoing-roi"
                  element={<OnGoingRoiAnalyticsPage />}
                />
                <Route
                  path="completed"
                  element={<CompletedRoiAnalyticsPage />}
                />
                <Route path="roi-details" element={<ViewRoiDetailsPage />} />
              </Route>
              <Route path="deal-tracker" element={<DealTrackerPage />} />
            </>
          )}

          <Route path="chat">
            <Route index element={<ChatPage />} />
            <Route path="admin" element={<ChatAdminPage />} />
          </Route>

          <Route path="notifications" element={<Notifications />} />

          <Route path="resources">
            <Route path="podcast">
              <Route index element={<Podcasts />} />
            </Route>
            <Route path="newsletter">
              <Route index element={<Articles />} />
              <Route path=":newsletterId" element={<ArticlesDetails />} />
            </Route>
            <Route path="education">
              <Route index element={<Education />} />
            </Route>
            <Route path="faqs">
              <Route index element={<FAQs />} />
            </Route>
          </Route>

          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="team" element={<Team />} />
        </Route>
      )}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
