const {
  login,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
  authDeleteAccountApi,
  exchangeTokenApi,
} = require("./api.request");

const AUTH_PATH_LOGIN = "/auth/login";
const AUTH_PATH_LOGOUT = "/auth/logout";
const AUTH_PATH_FORGOTPASSWORD = "/auth/forgotPassword";
const AUTH_PATH_RESETPASSWORD = "/auth/resetPassword";
const AUTH_PATH_CHANGE_PASSWORD = "/auth/changePassword";
const AUTH_PATH_DELETEACCOUNT = "/auth/deleteProfile";
const AUTH_PATH_EXCHANGE_TOKEN = "/auth/exchangeToken";

export const authLogIn = async (body) => login(`${AUTH_PATH_LOGIN}`, body);

export const authExchangeToken = async () =>
  exchangeTokenApi(AUTH_PATH_EXCHANGE_TOKEN);

export const authLogOut = async () => logout(`${AUTH_PATH_LOGOUT}`);

export const authForgotPassword = async (email) =>
  forgotPassword(`${AUTH_PATH_FORGOTPASSWORD}`, email);

export const authResetPassword = async (body) =>
  resetPassword(`${AUTH_PATH_RESETPASSWORD}`, body);

export const authChangePassword = async (body) =>
  changePassword(`${AUTH_PATH_CHANGE_PASSWORD}`, body);

export const authDeleteAccount = async () =>
  authDeleteAccountApi(`${AUTH_PATH_DELETEACCOUNT}`);
// 301(stage-1,2,3),302,200
