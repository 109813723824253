const Joi = require("joi");

const Schema = Joi.object({
  brandId: Joi.string(),
  registrationToken: Joi.string(),
  videoHighlights: Joi.array(),
  images: Joi.array().optional(),
  // brandLogo: Joi.object().messages({
  //   "object.base": `Brand logo is required and must be a valid file.`,
  // }),
  websiteUrl: Joi.string()
    .regex(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]{1,5})?(\/\S*)?$/
    )
    .messages({
      "string.empty": `Website URL cannot be empty`,
      "string.pattern.base": `Website URL must be a valid URL`,
    }),
  companyName: Joi.string().required().trim().max(30).messages({
    "string.max": `Company name must be at most 30 characters long`,
    "any.required": `Company name is required`,
    "string.empty": `Company name is required`,
  }),
  // einNumber: Joi.string().required().trim().messages({
  //   "any.required": `EIN number is required`,
  //   "string.empty": `EIN number is required`,
  // }),
  businessCategory: Joi.array().required().messages({
    "any.required": `Business category is required`,
    "array.empty": `Business category cannot be empty`,
  }),
  country: Joi.string().messages({
    "any.required": `Country is required`,
    "string.empty": `Country is required`,
  }),
  state: Joi.string().messages({
    "any.required": `State is required`,
    "string.empty": `State is required`,
  }),
  city: Joi.string().messages({
    "any.required": `City is required`,
    "string.empty": `City is required`,
  }),
  about: Joi.string().max(5000).min(50).trim().messages({
    "string.max": `About must be at most 5000 characters long`,
    "string.min": `About must be minimum 50 characters long`,
    "string.empty": `About is required`,
    "any.required": `About are required`,
  }),
  appendImages: Joi.boolean(),
  appendVideos: Joi.boolean(),
}).unknown(true);

export default Schema;
