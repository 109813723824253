import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import AthleteBenefits1 from "../../assets/images/AthleteBenefits1.png";
import AthleteBenefits2 from "../../assets/images/AthleteBenefits2.png";
import AthleteBenefits3 from "../../assets/images/AthleteBenefits3.png";

import ExploreAthletesImage1 from "../../assets/images/ExploreAthletesImage1.png";
import ExploreAthletesImage2 from "../../assets/images/ExploreAthletesImage2.png";
import ExploreAthletesImage3 from "../../assets/images/ExploreAthletesImage3.png";
import ExploreAthletesImage4 from "../../assets/images/ExploreAthletesImage4.png";

import { Helmet } from "react-helmet-async";
import { ArrowCircleRight } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import Pulse from "../../themes/@overrides/pulse";
import { featuredAthletes } from "../../services/athlete/athlete.service";
import FeaturedAthletesCard from "../../components/beforeLogin/home/subComponents/featuredAthletesCard";
import { useNavigate } from "react-router-dom";

const ATHLETE_BENEFITS_IMAGE_LIST = [
  {
    id: 1,
    imgUrl: AthleteBenefits1,
    alt: "Athlete Benefits 1",
  },
  {
    id: 2,
    imgUrl: AthleteBenefits2,
    alt: "Athlete Benefits 2",
  },
  {
    id: 3,
    imgUrl: AthleteBenefits3,
    alt: "Athlete Benefits 3",
  },
];

const EXPLORE_ATHLETES_LIST = [
  {
    _id: 1,
    firstName: "Victor",
    lastName: "Rosa",
    profileImage: ExploreAthletesImage1,
    alt: "Explore Athletes 1",
    school: "RB at UCONN",
    sports: ["Football"],
  },
  {
    _id: 2,
    firstName: "Isaiah",
    lastName: "Wooden",
    profileImage: ExploreAthletesImage2,
    alt: "Explore Athletes 2",
    school: "WR at Southern Utah",
    sports: ["Football"],
  },
  {
    _id: 3,
    firstName: "Jaylen",
    lastName: "Dollar",
    profileImage: ExploreAthletesImage3,
    alt: "Explore Athletes 3",
    school: "DE at Elizabeth State University",
    sports: ["Football"],
  },
  {
    _id: 4,
    firstName: "Curtis",
    lastName: "Appleton",
    profileImage: ExploreAthletesImage4,
    alt: "Explore Athletes 4",
    school: "Safety at University of Central Missouri",
    sports: ["Football"],
  },
];

const AthletesDescription = () => {
  const theme = useTheme();
  const [athletesList, setAthletesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const athleteBenefitsContainerStyle = {
    display: "flex",
    alignItems: "flex-start",
    gap: 1,
  };

  const athleteBenefitsContentStyle = {
    fontFamily: theme.fonts.livvic,
    fontSize: {
      xs: "14px",
      lg: theme.fonts.beforeLogin.body.lg,
      xl: theme.fonts.beforeLogin.body.xl,
    },
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    mb: { xs: 1.5, sm: 0 },
  };

  const fetchAthletes = async () => {
    const response = await featuredAthletes();
    setIsLoading(false);
    if (response?.status === 200) {
      setAthletesList(response?.data?.data);
    } else {
      console.error(response?.message);
    }
  };

  useEffect(() => {
    fetchAthletes();
  }, []);

  return (
    <>
      <Helmet>
        <title>Athletes Description | Burrst</title>
        <meta
          name="description"
          content="Streamline your workflow with our cutting-edge NIL deal tracking operating system."
        />
        <link rel="canonical" href={`${HOST_URL}/athletes-description`} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{
                xs: theme.fonts.heading_xs,
                sm: theme.fonts.heading,
              }}
            >
              ATHLETES
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Athletes
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* ----------------------Athlete Benefits----------------------- */}
        <Box
          sx={{
            background:
              "linear-gradient(90deg, rgba(250,0,0,0.05) 4%, rgba(250,0,0,0.05) 16%, rgba(0,0,0,1) 30%)",
            px: { xs: "10px", sm: PADDING_X },
            width: "100%",
            maxWidth: "1536px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              pt: "20px",
              mb: "20px",
            }}
          >
            <Pulse />
            <Typography
              sx={{
                fontFamily: theme.fonts.russoOne,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: {
                  xs: theme.fonts.subHeading_xs,
                  sm: theme.fonts.subHeading,
                  xl: theme.fonts.beforeLogin.heading.xl,
                },
                color: theme.colors.text.greyDark,
              }}
            >
              Athlete
              <span style={{ color: "red", marginLeft: 8 }}>Benefits</span>
            </Typography>
          </Box>

          {/* ----------------Content and Images------------------------- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" },
              gap: 2,
              pb: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: { xs: "100%", md: "50%", xl: "60%" },
              }}
            >
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Collaborate with like-minded brands for exciting{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    NIL opportunities.
                  </span>{" "}
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Streamline your workflow with our cutting-edge{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    NIL deal tracking
                  </span>{" "}
                  operating system.
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Personalize your profile with eye-catching{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    images
                  </span>{" "}
                  and captivating{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    video highlights.
                  </span>{" "}
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    Transparent
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    secure brand
                  </span>{" "}
                  agreements through smart contracts.
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    Chat
                  </span>{" "}
                  and engage directly with brands to build strong connections.
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Earn{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    90% on NIL deals
                  </span>{" "}
                  through our marketplace in USD via Stripe.
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Access exclusive{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    content,
                  </span>{" "}
                  including{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    podcasts, newsletters, events,
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    educational
                  </span>{" "}
                  resources to benefit further after your athletic career.
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Gain insights into{" "}
                  <span
                    style={{
                      color: "white",
                      //  fontWeight: "bold"
                    }}
                  >
                    brand contracts
                  </span>{" "}
                  with the help of our{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    AI tool.
                  </span>{" "}
                  (COMING SOON)
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Measure performance and assess the impact of{" "}
                  <span
                    style={{
                      color: "white",
                      //  fontWeight: "bold"
                    }}
                  >
                    brand collaborations
                  </span>{" "}
                  with advanced analytics. (COMING SOON)
                </Typography>
              </Box>
              <Box sx={athleteBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Utilize{" "}
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    AI features
                  </span>{" "}
                  to level up your content creation and find perfect brand
                  matches. (COMING SOON)
                </Typography>
              </Box>
            </Box>

            {/* ---------------Image Swiper----------------------- */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%", xl: "40%" },
              }}
            >
              <Swiper
                pagination={true}
                grabCursor={true}
                centeredSlides={true}
                effect={"coverflow"}
                slidesPerView={3}
                // loop={true}
                autoplay={{
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                initialSlide={1}
                modules={[Pagination, EffectCoverflow, Autoplay]}
                coverflowEffect={{
                  // rotate: 40,
                  stretch: 0,
                  depth: 100,
                  slideShadows: true,
                }}
                breakpoints={{
                  0: { slidesPerView: 1 },
                  350: { slidesPerView: 2 },
                  500: { slidesPerView: 3 },
                  900: { slidesPerView: 2 },
                  1050: { slidesPerView: 3 },
                }}
                style={{
                  "--swiper-pagination-color": "red",
                  "--swiper-pagination-bullet-inactive-color": "#fff",
                  "--swiper-pagination-bullet-inactive-opacity": "1",
                  "--swiper-pagination-bullet-horizontal-gap": "6px",
                  "--swiper-pagination-bottom": "0px",
                  height: "350px",
                  width: "100%",
                }}
              >
                {ATHLETE_BENEFITS_IMAGE_LIST?.map((detail, index) => (
                  <SwiperSlide key={index} style={{ height: "90%" }}>
                    <Box
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Box
                        component="img"
                        src={detail?.imgUrl}
                        alt={detail?.alt}
                        sx={{
                          height: "100%",
                        }}
                      ></Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        </Box>

        {/* --------------------Explore Athletes-------------------------- */}
        <Box
          sx={{
            px: { xs: "10px", sm: PADDING_X },
            mb: "30px",
            width: "100%",
            maxWidth: "1536px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              my: "30px",
            }}
          >
            <Pulse />
            <Typography
              sx={{
                fontFamily: theme.fonts.russoOne,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: {
                  xs: theme.fonts.subHeading_xs,
                  sm: theme.fonts.subHeading,
                  xl: theme.fonts.beforeLogin.heading.xl,
                },
                color: theme.colors.text.greyDark,
              }}
            >
              Explore
              <span style={{ color: "red", marginLeft: 8 }}>Athletes</span>
            </Typography>
          </Box>

          <Swiper
            grabCursor={true}
            initialSlide={0}
            spaceBetween={20}
            breakpoints={{
              0: { slidesPerView: 1 },
              320: { slidesPerView: 1.2 },
              380: { slidesPerView: 1.4 },
              440: { slidesPerView: 1.6 },
              500: { slidesPerView: 1.8 },
              560: { slidesPerView: 2 },
              620: { slidesPerView: 2.2 },
              680: { slidesPerView: 2.4 },
              740: { slidesPerView: 2.6 },
              800: { slidesPerView: 2.8 },
              860: { slidesPerView: 3 },
              920: { slidesPerView: 3.2 },
              980: { slidesPerView: 3.4 },
              1040: { slidesPerView: 3.6 },
              1100: { slidesPerView: 3.8 },
              1160: { slidesPerView: 4 },
              1220: { slidesPerView: 4.2 },
              1280: { slidesPerView: 4.4 },
              1340: { slidesPerView: 4.6 },
              1400: { slidesPerView: 4.8 },
              1460: { slidesPerView: 5 },
              1520: { slidesPerView: 5.2 },
              1560: { slidesPerView: 5.4 },
            }}
            style={{ padding: "0 20px", height: "360px" }}
          >
            {isLoading
              ? Array.from({ length: 5 }, (_, index) => (
                  <SwiperSlide key={index}>
                    <FeaturedAthletesCard index={index} isLoading={isLoading} />
                  </SwiperSlide>
                ))
              : athletesList?.length
              ? athletesList?.map((detail, index) => (
                  <SwiperSlide key={index}>
                    <FeaturedAthletesCard detail={detail} index={index} />
                  </SwiperSlide>
                ))
              : EXPLORE_ATHLETES_LIST?.map((detail, index) => (
                  <SwiperSlide key={index}>
                    <FeaturedAthletesCard detail={detail} index={index} />
                  </SwiperSlide>
                ))}
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default AthletesDescription;
