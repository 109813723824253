import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ViewRoiDetailsCard from "../../../../components/afterLogin/brands/roiAnalytics/viewRoiDetails.card";
import ReactApexChart from "react-apexcharts";
import {
  getRoiCampaignAthlete,
  getRoiCampaignRevenue,
} from "../../../../services/product/product.service";
import { toast } from "react-toastify";
import CopyClipboardIcon from "../../../../assets/icons/CopyClipboardIcon.svg";

const ViewRoiDetailsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState(null);
  const [roiAthleteData, setRoiAthleteData] = useState(null);
  const id = location?.state?.id;
  const campaignName = location?.state?.campaignName;
  const val = [1, 2, 3, 4];
  const handleBack = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(false);
  const [revLoading, setRevLoading] = useState(false);

  const formatCampaignRevenueData = (details) => {
    let dates = details?.map((item) => item?.date);

    let activities = details?.map((item) =>
      item?.data?.map((it) => ({
        activities: it?.activities,
        totalRevenue: it?.totalRevenue,
      }))
    );

    let allActivities = activities?.flat();

    let uniqueActivityLabels = new Set();

    let uniqueActivities = allActivities?.filter((item) => {
      if (!uniqueActivityLabels?.has(item?.activities)) {
        uniqueActivityLabels?.add(item?.activities);
        return true;
      }
      return false;
    });

    let res = {};

    details?.map((item) => {
      uniqueActivities?.forEach((it) => {
        if (!res[it?.activities]) {
          res[it?.activities] = [];
        }
        let d = item?.data?.filter((i) => i?.activities === it?.activities);

        if (d?.length) {
          res[it?.activities]?.push(d[0]?.totalRevenue);
        } else {
          res[it?.activities]?.push(0);
        }
      });
      return 0;
    });

    return { dates, activities: uniqueActivities, revenue: res };
  };

  const { activities, revenue } = formatCampaignRevenueData(formData?.roi);

  let dataSets = [];

  activities?.forEach((item, index) => {
    const color = [
      "#900505",
      "#FF0000",
      "#FF4D00",
      "#FF9900",
      "#F6F97B",
      "#900595",
      "#FF0200",
      "#FF4D10",
      "#FF99E0",
      "#FFF97B",
    ];
    dataSets?.push({
      label: item?.activities,
      data: revenue[item?.activities],
      color: color[index % color.length],
    });
  });

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    colors: [
      "#900505",
      "#FF0000",
      "#FF4D00",
      "#FF9900",
      "#F6F97B",
      "#900595",
      "#FF0200",
      "#FF4D10",
      "#FF99E0",
      "#FFF97B",
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    theme: {
      mode: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    xaxis: {
      categories: (formData?.roi ?? [])?.map((item) =>
        new Date(item?.date).toLocaleDateString("en-US", { month: "short" })
      ),
    },
    yaxis: {
      title: {
        text: "",
      },
      min: 0,
      max: Math.max(...dataSets?.flatMap((dataset) => dataset?.data)) + 1000,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
          const labelName = dataSets[seriesIndex]?.label;
          return labelName + ": $" + val;
        },
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
  };

  const callCampaignRevenueRio = async () => {
    setRevLoading(true);
    const response = await getRoiCampaignRevenue(id);
    setRevLoading(false);
    if (response?.status === 200) {
      setFormData(response?.data);
    } else {
      toast.error("Unable to fetch details");
    }
  };

  const callCampaignAthleteRio = async () => {
    setLoading(true);
    const response = await getRoiCampaignAthlete(id);
    setLoading(false);
    if (response?.status === 200) {
      setRoiAthleteData(response?.data);
    } else {
      setLoading(false);
      toast.error("Unable to fetch details");
    }
  };

  useEffect(() => {
    callCampaignRevenueRio();
    callCampaignAthleteRio();
  }, []);

  console.log("===================", dataSets);

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Grid my="20px" display="flex" alignItems="center">
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", md: "35px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          ml="10px"
          sx={{
            fontSize: { xs: "30px", md: theme.fonts.heading },
          }}
        >
          {campaignName}
        </Typography>
      </Grid>
      <Grid backgroundColor="#121212" paddingTop="1%">
        {revLoading ? (
          <Box display="flex" justifyContent="center" my="100px">
            <CircularProgress sx={{ color: "gray" }} />
          </Box>
        ) : (
          <>
            <Box sx={{ marginX: "50px", marginTop: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #918E8E",
                  pb: "8px",
                  mb: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: 600,
                    fontSize: { xs: "20px", md: "22px" },
                  }}
                >
                  Activities
                </Typography>
                <Box
                  component="img"
                  src={CopyClipboardIcon}
                  alt="Copy Clipboard Icon"
                  sx={{
                    width: "35px",
                  }}
                />
              </Box>
              {dataSets?.map((data, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    px: "10px",
                    py: "5px",
                  }}
                >
                  <Box
                    width="16px"
                    height="16px"
                    borderRadius="20%"
                    backgroundColor={data.color}
                    mr="8px"
                  />
                  <Typography
                    color="white"
                    mr="2px"
                    sx={{ fontSize: { xs: "14px", md: "16px" } }}
                  >
                    Deliverable{index + 1}
                  </Typography>
                  <Typography
                    color="white"
                    fontSize={{ xs: "12px", md: "14px" }}
                  >
                    ({data?.label})
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box
              backgroundColor="#272727"
              marginX="40px"
              marginTop="20px"
              py="15px"
              px="15px"
            >
              <ReactApexChart
                mx="10px"
                options={options}
                series={dataSets}
                type="bar"
                height={350}
                width="100%"
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              marginX="40px"
              borderRadius="4px"
              marginBottom="20px"
              py="10px"
              sx={{ border: "1px solid #797979", backgroundColor: "black" }}
            >
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
                sx={{ fontSize: { xs: "22px", md: "28px" } }}
              >
                ${formData?.deliverables?.totalRevenue}
              </Typography>
              <Typography
                fontFamily={theme.fonts.livvic}
                color="#AFACAC"
                sx={{ fontSize: { xs: "18px", md: "20px" } }}
              >
                Total Revenue
              </Typography>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              paddingX="40px"
              alignItems="center"
              mb="5px"
            >
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={500}
                mr="5px"
                sx={{ fontSize: { xs: "18px", md: "20px" } }}
              >
                This week
              </Typography>
            </Box>
          </>
        )}

        <Grid
          container
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          {loading ? (
            <Box display="flex" justifyContent="center" my="100px">
              <CircularProgress sx={{ color: "gray" }} />
            </Box>
          ) : (
            roiAthleteData?.map((details, index) => (
              <ViewRoiDetailsCard details={details} key={index} />
            ))
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewRoiDetailsPage;
