import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Input,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { Helmet } from "react-helmet-async";
import CloseIcon from "../../../assets/icons/CrossIcon.svg";
// import BurrstLogo from "../../../assets/logo/BurrstLogo.svg";
import MyProfileAthleteBackground from "../../../assets/images/BackgroundImage2.png";
import RegistrationBackground from "../../../assets/images/RegistrationBackground.jpg";

import PlusIconPortfolio from "../../../assets/icons/PlusIconPortfolio.svg";
import BrowseFileIcon from "../../../assets/icons/BrowseFileIcon.svg";
import YoutubePublicProfileIcon from "../../../assets/icons/YoutubePublicProfileIcon.svg";
import InstagramPublicProfileIcon from "../../../assets/icons/InstagramPublicProfileIcon.svg";
import FacebookPublicProfileIcon from "../../../assets/icons/FacebookPublicProfileIcon.svg";
import TwitterPublicProfileIcon from "../../../assets/icons/TwitterPublicProfileIcon.svg";
import LinkedInPublicProfileIcon from "../../../assets/icons/LinkedInPublicProfileIcon.svg";
import TikTokPublicProfileIcon from "../../../assets/icons/TikTokPublicProfileIcon.svg";
import { toast } from "react-toastify";
import Schema from "../../../joi.schema/beforeLogin/athlete/athlete.PublicProfile";
import { PADDING_X, BASE_IMAGE_URL } from "../../../utils/constants";

import { athleteUpdatePublicProfile } from "../../../services/athlete/athlete.service";
import AthleteRegSuccessfulModal from "../../../components/beforeLogin/athletes/subComponents/athleteRegSuccessfulModal";

import { Cancel } from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import { login } from "../../../store/authSlice";
import { useDispatch } from "react-redux";

const PublicProfileAthlete = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const inputImageRef = useRef(null);
  const inputVideoRef = useRef(null);
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  // const MyProfileAthleteBackground = `${BASE_IMAGE_URL}/MyProfileAthleteBackground.svg`;
  //const MyProfileAthleteBackground = `${BASE_IMAGE_URL}/MyProfileAthleteBackground.png`;
  const [loading, setLoading] = useState(false);
  const detail = location?.state?.detail;
  const isMyProfile = location?.state?.isMyProfile;
  if (isMyProfile) {
    localStorage.setItem("isMyProfile", isMyProfile);
  }
  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    instagramLink: detail?.instagramLink || "",
    facebookLink: detail?.facebookLink || "",
    twitterLink: detail?.twitterLink || "",
    linkedinLink: detail?.linkedinLink || "",
    tiktokLink: detail?.tiktokLink || "",
    youtubeLink: detail?.youtubeLink || "",
    bio: detail?.bio || "",
    videos: detail?.videos || [],
    publicImages: detail?.images || [],
    coverImage: detail?.coverImage || null,
    appendImages: false,
    appendVideos: false,
    registrationToken: location.state.registrationToken,
    loginToken: null,
  });

  useEffect(() => {
    if (isMyProfile) {
      delete formData.registrationToken;
      setFormData({ ...formData, loginToken: token });
    }
  }, []);

  const [successRegModalOpen, setSuccessRegModalOpen] = useState(false);

  const [editData, setEditData] = useState({
    instagramLink: detail?.instagramLink,
    facebookLink: detail?.facebookLink,
    twitterLink: detail?.twitterLink,
    linkedinLink: detail?.linkedinLink,
    tiktokLink: detail?.tiktokLink,
    youtubeLink: detail?.youtubeLink,
    bio: detail?.bio,
  });

  const handleSelectionsChange = (field, value) => {
    setFormData((prevSelections) => ({
      ...prevSelections,
      [field]: value,
    }));
  };

  const handleImageInputClick = () => {
    inputImageRef.current.click();
  };

  const handleVideoInputClick = () => {
    inputVideoRef.current.click();
  };

  const handleDeleteImage = (index) => {
    setFormData((prevFormData) => {
      const newImages = [...prevFormData.publicImages];
      newImages.splice(index, 1);
      return {
        ...prevFormData,
        publicImages: newImages,
      };
    });
  };

  const handleVideoChange = (e) => {
    const files = e.target.files;
    const newVideos = Array.from(files).map(
      (file) => file
      //url: URL.createObjectURL(file),
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      videos: prevFormData.videos.concat(newVideos),
    }));
  };

  const maxLength = 5000;

  const handleOtpModalClose = () => {
    setSuccessRegModalOpen(false);
  };

  const handleBurrstLogo = () => {
    navigate("/");
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const newImages = Array.from(files).map((file) => file);

    setFormData((prevFormData) => ({
      ...prevFormData,
      publicImages: prevFormData.publicImages.concat(newImages),
    }));
  };

  const handleDeleteVideo = (index, event) => {
    setFormData((prevFormData) => {
      const newVideos = [...prevFormData.videos];
      newVideos.splice(index, 1);
      return {
        ...prevFormData,
        videos: newVideos,
      };
    });
    event.stopPropagation();
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      coverImage: file,
    });
  };

  const handleSubmit = async () => {
    const { error } = Schema.validate(isMyProfile ? editData : formData);

    if (error) {
      error?.details?.forEach((errors) => {
        toast.error(errors.message);
      });
    } else {
      setLoading(true);
      const response = await athleteUpdatePublicProfile(formData);
      setLoading(false);
      if (response?.status === 200) {
        if (!isMyProfile) {
          localStorage.removeItem("registrationToken");
          localStorage.removeItem("isMyProfile");
          localStorage.setItem(
            "registrationToken",
            response?.data?.registrationToken
          );
          dispatch(
            login({
              userData: {
                token: response.data.loginToken,
                usertype: response.data.usertype,
                userId: response.data.userId,
              },
            })
          );
          localStorage.setItem("token", response?.data?.loginToken);
          localStorage.setItem("usertype", response.data?.usertype);
          localStorage.setItem("userId", response?.data?.userId);
          localStorage.setItem("loginToken", response?.data?.loginToken);
          setSuccessRegModalOpen(true);
        }
        //navigate("/login", { replace: true });
        else {
          navigate("/dashboard/home/my-profile", {
            replace: true,
            state: { isMyProfile: true },
          });
        }
      } else {
        toast.error(response?.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Public Profile Athlete | Burrst</title>
      </Helmet>
      <Box
      // sx={{
      //   backgroundImage: `url(${MyProfileAthleteBackground})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: {
      //     xs: "cover",
      //     sm: "cover",
      //     md: "cover",
      //     lg: "cover",
      //   },
      //   backgroundAttachment: "fixed",
      //   overflowY: "auto",
      //   height: { xs: "80vh", sm: "80vh", md: "90vh", lg: "100vh" },
      //   "&::-webkit-scrollbar": {
      //     width: "8px",
      //   },
      //   "&::-webkit-scrollbar-thumb": {
      //     backgroundColor: theme.colors.background.formInput,
      //     borderRadius: "10px",
      //   },
      //   "&::-webkit-scrollbar-track": {
      //     backgroundColor: "transparent",
      //   },
      // }}
      >
        <Box
          component="img"
          src={RegistrationBackground}
          alt="MyProfileAthlete Background"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            objectFit: "cover",
            zIndex: "-1",
            height: "100vh",
            width: "80vw",
          }}
        ></Box>
        <Grid
          container
          spacing={2}
          sx={{
            background: `url(${MyProfileAthleteBackground}) no-repeat`,
            backgroundSize: "cover",
            py: "40px",
            paddingX: PADDING_X,
            "@media (max-width:600px)": {
              paddingX: "10px",
            },
          }}
        >
          {/* <ToastContainer position="top-right" autoClose={5000} /> */}

          <Box
            sx={{
              marginBottom: "2%",
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              component="img"
              src={BurrstLogo}
              onClick={handleBurrstLogo}
              alt="Burrst Logo"
              width="200px"
              style={{
                cursor: "pointer",
              }}
            ></Box>
          </Box>
          <Grid container spacing={3} padding="15px">
            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontSize="38px"
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  marginBottom: "2%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Public Profile
                <span style={{ color: theme.colors.background.registerButton }}>
                  .
                </span>
              </Typography>
              {!isMyProfile ? (
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize="20px"
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  {`Portfolio Images (Image highlights)`}
                </Typography>
              ) : null}

              {/* ----------------Images--------------------- */}
              {!isMyProfile ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 2,
                  }}
                >
                  {formData?.publicImages.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "130px",
                        width: "150px",
                        borderRadius: theme.fonts.smallBorderRadius,
                      }}
                    >
                      <Box
                        component="img"
                        onLoad={() => URL.revokeObjectURL(image)}
                        src={URL.createObjectURL(image)}
                        alt="Portfolio Image"
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: theme.fonts.smallBorderRadius,
                        }}
                      ></Box>
                      <Box
                        component="img"
                        src={CloseIcon}
                        alt="Close Icon"
                        sx={{
                          position: "absolute",
                          marginBottom: "112px",
                          marginLeft: "125px",
                          //left: "10%",
                          //right: "0%",
                          width: "10px",
                          cursor: "pointer",
                          display: "flex",
                          //justifyItems: "end",
                          //alignItems: "flex-start",
                          color: theme.colors.common.white,
                        }}
                        onClick={() => handleDeleteImage(index)}
                      ></Box>
                    </Box>
                  ))}

                  <Box
                    component="label"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: { xs: "100px", md: "130px" },
                      width: { xs: "120px", md: "150px" },
                      cursor: "pointer",
                      borderRadius: theme.fonts.smallBorderRadius,
                      border: `1px solid ${theme.colors.text.greyDarker}`,
                    }}
                  >
                    <Box
                      component="img"
                      src={PlusIconPortfolio}
                      alt="Plus Icon Portfolio"
                    ></Box>
                    <Input
                      id="imageInput"
                      type="file"
                      inputProps={{ accept: "image/*" }}
                      onChange={handleImageChange}
                      sx={{ display: "none" }}
                    />
                  </Box>
                </Box>
              ) : null}
            </Grid>

            {/* ------------------------Grid Contents----------------------- */}
            {!isMyProfile ? (
              <Grid item xs={12} lg={6}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "20px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Cover Image
                </Typography>
                <Box
                  onClick={handleImageInputClick}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    background: theme.colors.background.formInput,
                    borderRadius: theme.fonts.smallBorderRadius,
                    cursor: "pointer",
                    border: `1px solid ${theme.colors.common.white}`,
                  }}
                >
                  <Box
                    //component="label"
                    sx={{
                      width: "50px",
                      height: "50px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      component="img"
                      src={BrowseFileIcon}
                      alt="Browse File Icon"
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></Box>
                    {/* <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    onChange={handleCoverImageChange}
                    sx={{ display: "none" }}
                  /> */}
                  </Box>
                  {formData?.coverImage && formData?.coverImage?.size > 0 ? (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{ paddingY: "10px", paddingX: "5px" }}
                    >
                      {formData?.coverImage?.name}
                    </Typography>
                  ) : (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{
                        paddingY: "12px",
                        paddingX: "5px",
                        fontSize: "16px",
                        fontWeight: theme.fonts.fontWeightRegular,
                        color: theme.colors.text.greyDark,
                      }}
                    >
                      Browse files
                    </Typography>
                  )}
                  <input
                    accept="image/*"
                    type="file"
                    ref={inputImageRef}
                    //inputProps={{ accept: "image/*" }}
                    onChange={handleCoverImageChange}
                    style={{ display: "none" }}
                  />
                </Box>
              </Grid>
            ) : null}

            {!isMyProfile ? (
              <Grid item xs={12} lg={6}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "20px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Video highlights
                  <span
                    style={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: "16px",
                      fontWeight: theme.fonts.fontWeightRegular,
                      color: theme.colors.text.greyDark,
                    }}
                  >
                    {" "}
                    (Optional)
                  </span>
                </Typography>
                <Box
                  onClick={handleVideoInputClick}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    background: theme.colors.background.formInput,
                    borderRadius: theme.fonts.smallBorderRadius,
                    cursor: "pointer",
                    border: `1px solid ${theme.colors.common.white}`,
                    px: "10px",
                    py: "2px",
                  }}
                >
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      component="img"
                      src={YoutubePublicProfileIcon}
                      alt="Youtube Public Profile Icon"
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></Box>
                  </Box>
                  {formData?.videos && formData?.videos?.length > 0 ? (
                    <Box sx={{ width: "calc(100% - 50px)" }}>
                      <Swiper
                        grabCursor={true}
                        spaceBetween={20}
                        breakpoints={{
                          0: { slidesPerView: 1.5 },
                          400: { slidesPerView: 2.2 },
                          600: { slidesPerView: 3.2 },
                          900: { slidesPerView: 3.4 },
                          1200: { slidesPerView: 2.2 },
                          1600: { slidesPerView: 3.2 },
                          2000: { slidesPerView: 3.5 },
                        }}
                      >
                        {formData?.videos.map((video, index) => (
                          <SwiperSlide key={index}>
                            <Button
                              endIcon={
                                <Cancel
                                  sx={{
                                    width: "30px",
                                    cursor: "pointer",
                                    color: theme.colors.background.formInput,
                                  }}
                                  onClick={(event) =>
                                    handleDeleteVideo(index, event)
                                  }
                                />
                              }
                              sx={{
                                fontSize: "10px",
                                fontWeight: theme.fonts.fontWeightRegular,
                                color: theme.colors.text.greyDark,
                                backgroundColor:
                                  theme.colors.background
                                    .autocompleteRenderTagsLightgray,
                                borderRadius: "12px",
                                border: `1px solid grey`,
                                textTransform: "none",
                                width: "100%",
                                height: "30px",
                                cursor: "grab",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: theme.fonts.livvic,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "calc(100% - 30px)",
                                }}
                              >
                                {video?.name}
                              </Typography>
                            </Button>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Box>
                  ) : (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{
                        paddingY: "12px",
                        paddingX: "5px",
                        fontSize: "16px",
                        fontWeight: theme.fonts.fontWeightRegular,
                        color: theme.colors.text.greyDark,
                      }}
                    >
                      Upload your video
                    </Typography>
                  )}
                  <input
                    accept="video/*"
                    type="file"
                    ref={inputVideoRef}
                    onChange={handleVideoChange}
                    multiple
                    style={{ display: "none" }}
                  />
                </Box>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "20px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Bio{" "}
                  <span
                    style={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: "16px",
                      fontWeight: theme.fonts.fontWeightRegular,
                      color: theme.colors.text.greyDark,
                    }}
                  >
                    (Minimum 50 characters)
                  </span>
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="20px"
                  color={
                    formData?.bio?.length === maxLength
                      ? theme.colors.text.red
                      : theme.colors.text.greyDark
                  }
                >
                  {formData?.bio?.length}/{maxLength}
                </Typography>
              </Box>
              <TextField
                name="bio"
                placeholder="Write about your career and dreams as an athlete"
                multiline
                rows={3}
                inputProps={{ maxLength }}
                fullWidth
                // autoComplete="off"
                value={formData?.bio || ""}
                //onChange={(e) => setBio(e.target.value)}
                onChange={(e) => handleSelectionsChange("bio", e.target.value)}
                InputProps={{
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Social Media{" "}
                <span
                  style={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: "16px",
                    fontWeight: theme.fonts.fontWeightRegular,
                    color: theme.colors.text.greyDark,
                  }}
                >
                  (Minimum two)
                </span>
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                name="instagramUrl"
                placeholder="Enter your Instagram url"
                fullWidth
                // autoComplete="off"
                value={formData?.instagramLink}
                onChange={(e) =>
                  handleSelectionsChange("instagramLink", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={InstagramPublicProfileIcon}
                        alt="Instagram Public Profile Icon"
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                name="facebookUrl"
                placeholder="Enter your Facebook url"
                fullWidth
                // autoComplete="off"
                value={formData?.facebookLink}
                onChange={(e) =>
                  handleSelectionsChange("facebookLink", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={FacebookPublicProfileIcon}
                        alt="Facebook Public Profile Icon"
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                name="twitterUrl"
                placeholder="Enter your Twitter url"
                fullWidth
                // autoComplete="off"
                value={formData?.twitterLink}
                onChange={(e) =>
                  handleSelectionsChange("twitterLink", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={TwitterPublicProfileIcon}
                        alt="Twitter Public Profile Icon"
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                name="linkedIn"
                placeholder="Enter your LinkedIn url"
                fullWidth
                // autoComplete="off"
                value={formData?.linkedinLink}
                onChange={(e) =>
                  handleSelectionsChange("linkedinLink", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={LinkedInPublicProfileIcon}
                        alt="LinkedIn Public Profile Icon"
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                name="tikTokUrl"
                placeholder="Enter your TikTok url"
                fullWidth
                // autoComplete="off"
                value={formData?.tiktokLink}
                onChange={(e) =>
                  handleSelectionsChange("tiktokLink", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={TikTokPublicProfileIcon}
                        alt="TikTok Public Profile Icon"
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                name="youtubeUrl"
                placeholder="Enter your Youtube url"
                fullWidth
                // autoComplete="off"
                value={formData?.youtubeLink}
                onChange={(e) =>
                  handleSelectionsChange("youtubeLink", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={YoutubePublicProfileIcon}
                        alt="Youtube Public Profile Icon"
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            {/* -------------------------Submit Button------------------------ */}

            <Grid item xs={12} textAlign="center">
              <Button
                fullWidth
                sx={{
                  background: theme.colors.background.registerButton,
                  color: "white",
                  height: "50px",
                  maxWidth: "400px",
                  fontSize: "22px",
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                  borderRadius: "10px",
                  mt: 3,
                  py: 3.5,
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                }}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress size={40} sx={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </Button>
              <Dialog
                open={successRegModalOpen}
                //onClose={handleOtpModalClose}
                PaperProps={{
                  sx: {
                    width: "80vw",
                    background: theme.colors.common.black,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                sx={{
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgb(255,255,255,0.3)",
                }}
              >
                <AthleteRegSuccessfulModal />
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PublicProfileAthlete;
