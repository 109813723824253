import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../../assets/icons/CrossIcon.svg";
import SubscriptionPaymentFailedIcon from "../../../../assets/icons/GracePeriodStartedIcon.svg";
import Support from "../../../../assets/icons/GracePeriodSupportIcon.svg";
import {
  brandsubscriptionTransactionsList,
  retryPayment,
} from "../../../../services/accounts/accounts.service";
import { useNavigate } from "react-router-dom";
import { brandSubscribedCheck } from "../../../../services/brand/brand.service";
import { toast } from "react-toastify";

const SubscriptionPaymentFailedModal = ({ handleDialogClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const profileState = "gracePeriod";
  const [subscriptionDetail, setSubscriptionDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchScriptionTransactionsListApi = async () => {
    const response = await brandsubscriptionTransactionsList();
    if (response?.status === 200) {
      setSubscriptionDetail(response?.data?.data[0]);
    } else {
      console.error(
        "Failed to fetch brand subscription Transactions List:",
        response.message
      );
    }
  };

  const handleTry = async () => {
    console.log("subscriptionDetail check", subscriptionDetail);
    if (
      subscriptionDetail?.status &&
      subscriptionDetail?.status !== "success"
    ) {
      setIsLoading(true);
      const response = await retryPayment(subscriptionDetail?._id);

      if (response?.status === 200) {
        const subscriptionResponse = await brandSubscribedCheck();
        localStorage.setItem(
          "gracePeriod",
          subscriptionResponse?.data?.gracePeriod
        );
      } else {
        toast.error("Payment failed.");
      }
      setIsLoading(false);
      handleDialogClose();
    } else {
      toast.error("Please try again later or Contact support.");
    }
  };

  const handleSupport = () => {
    navigate("/dashboard/chat/admin");
  };

  const handleChangeBankAcc = () => {
    localStorage.setItem("route", profileState);
    navigate("/my-accounts");
  };

  useEffect(() => {
    fetchScriptionTransactionsListApi();
  }, []);

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleDialogClose}
      ></Box>
      <Box
        component="img"
        src={Support}
        alt="Support Icon"
        position="absolute"
        left="25px"
        top="40px"
        sx={{
          cursor: "pointer",
          background: "#252525",
          py: "5px",
          px: "5px",
          borderRadius: "5px",
        }}
        onClick={handleSupport}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: "40px",
          gap: 3,
        }}
      >
        <Box
          component="img"
          src={SubscriptionPaymentFailedIcon}
          alt="Grace period started icon"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h6"
        >
          Grace period started
        </Typography>
        <Typography fontFamily={theme.fonts.livvic} color="#D9D9D9">
          Your subscription payment failed. Don't worry, you can still access
          your account for the next 5 days. Click "TRY AGAIN" to attempt the
          payment again.
          <span style={{ fontSize: "14px" }}>(Ignore if already done)</span>
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          gap="15px"
        >
          {/* <Button
            onClick={handleChangeBankAcc}
            variant="contained"
            sx={{
              height: "50px",
              border: "3px solid #797979",
              background: "#000000",
              borderRadius: "10px",
              width: { xs: "100%", md: "60%" },
              mt: 1,
              textTransform: "none",
              color: "#8E8E8E",
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: { xs: "14px", sm: "18px" },
              fontFamily: theme.fonts.livvic,
              "&:hover": {
                background: "#000000",
              },
            }}
            // startIcon={<Box component="img" src={Support}></Box>}
          >
            CHANGE PAYMENT DETAILS
          </Button> */}
          <Button
            // fullWidth
            sx={{
              background: theme.colors.background.registerButton,
              color: "white",
              height: "50px",
              width: { xs: "100%", md: "60%" },
              mt: "10px",
              fontSize: { xs: "14px", sm: "18px" },
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              borderRadius: "10px",
              "&:hover": {
                background: theme.colors.divider.red,
              },
              "&.MuiButton-root.Mui-disabled": {
                background: "rgba(255,0,0,0.6)",
                color: "white",
              },
            }}
            onClick={handleTry}
            disabled={isLoading}
          >
            TRY AGAIN
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default SubscriptionPaymentFailedModal;
