import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

// import CampaignBrand from "../../../../../../assets/images/CampaignBrand.png";
// import CampaignBrandBackground from "../../../../../../assets/images/CampaignBrandBackground.png";
import AdidasBlack from "../../../../../../assets/icons/AdidasBlack.svg";

import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import { getBrandsList } from "../../../../../../services/athlete/athlete.service";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CustomSkeleton from "../../../../../../themes/@overrides/customSkeleton";

const BrandsCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  // const CampaignBrand = `${BASE_IMAGE_URL}/CampaignBrand.png`;
  // const CampaignBrandBackground = `${BASE_IMAGE_URL}/CampaignBrandBackground.png`;
  const navigate = useNavigate();

  const handleImageError = (e) => {
    e.target.src = AdidasBlack;
  };

  const handleMore = () => {
    localStorage.setItem("brandId", detail?._id);
    navigate("/dashboard/explore-brands/brands-list", {
      state: { detail },
    });
  };

  return (
    <Box
      sx={{
        // minWidth: "300px",
        // mb: "30px",
        mr: "auto",
        border: `1px solid grey`,
        borderRadius: "5px",
        overflow: "hidden",
        p: "15px",
      }}
    >
      {isAllDataLoading ? (
        <CustomSkeleton type="img" sx={{ width: "100%", height: "100px" }} />
      ) : (
        <Box
          sx={{
            background: "rgba(197,197,197,1)",
            width: "100%",
            height: "100px",
            textAlign: "center",
            py: 1,
            mb: 1,
          }}
        >
          <Box
            component="img"
            src={detail?.brandLogo}
            alt="Brand Logo"
            id="brandLogo"
            onError={handleImageError}
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              // mixBlendMode: "color-burn",
            }}
          ></Box>
        </Box>
      )}
      <Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightBold}
          mb={1}
          textTransform="capitalize"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {isAllDataLoading ? <CustomSkeleton type="p" /> : detail?.brandName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            color="rgba(217,217,217,1)"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              <>
                <span style={{ color: "red" }}>{detail?.activeCampaigns}</span>{" "}
                Active Campaigns
              </>
            )}
          </Typography>
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleMore}
            >
              <Typography color="rgba(217,217,217,1)">More</Typography>
              <ChevronRight />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BrandsCard;
