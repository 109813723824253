import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Sports from "../../../../assets/icons/Sports.svg";
import School from "../../../../assets/icons/School.svg";
import DefaultImg from "../../../../assets/images/DefaultAvatar.jpg";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";

const FeaturedAthletesCard = ({ detail, index, isLoading }) => {
  const theme = useTheme();

  const handleProfileImage = (e) => {
    e.target.src = DefaultImg;
  };

  return (
    <Box
      sx={{
        height: "95%",
        padding: "5px",
        position: "relative",
        borderRadius: theme.fonts.smallBorderRadius,
        "&::before,&::after": {
          content: '""',
          position: "absolute",
          left: "0",
          width: "100%",
          height: "100%",
        },
        "&::before": {
          top: "auto",
          bottom: "0",
          borderRadius: theme.fonts.smallBorderRadius,
          background: theme.colors.gradientRedBlack,
          backgroundRepeat: "repeat-x",
          transition: "all 0.5s",
          zIndex: "-2",
        },
        "&::after": {
          top: "0",
          borderRadius: theme.fonts.smallBorderRadius,
          background: theme.colors.background.redBlackGradient,
          zIndex: "-1",
        },
        "&:hover": {
          "#athleteImg": {
            transform: "scale(1.05)",
            transition: "0.5s ease",
          },
        },
        "&:hover::before": {
          transform: "rotate(2deg) translateX(-8px) translateY(12px)",
        },
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          borderRadius: theme.fonts.smallBorderRadius,
          height: "200px",
          width: "100%",
          background: theme.colors.exploreAthletesBackground,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        {isLoading ? (
          <CustomSkeleton type="img" sx={{ height: "150px", width: "150px" }} />
        ) : (
          <Box
            component="img"
            src={detail?.profileImage}
            alt={detail?.alt}
            sx={{
              height: "95%",
              width: "80%",
              objectFit: "cover",
              objectPosition: "center top",
            }}
            onError={handleProfileImage}
          ></Box>
        )}
      </Box>
      <Box m="10px 5px">
        <Box
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <CustomSkeleton type="p" sx={{ minWidth: "80%" }} />
          ) : (
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightMedium}
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                fontSize: { xs: "16px", sm: "18px" },
                wordBreak: "break-all",
              }}
            >
              {`${detail?.firstName || ""} ${detail?.lastName || ""}`}
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center" gap={1} mt={1}>
          {isLoading ? (
            <CustomSkeleton
              type="circle"
              sx={{ height: "15px", width: "15px" }}
            />
          ) : (
            <Box component="img" src={Sports} alt={`Sports ${index}`}></Box>
          )}
          {isLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {detail?.sports?.join(",")}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          {isLoading ? (
            <CustomSkeleton
              type="circle"
              sx={{ height: "15px", width: "15px" }}
            />
          ) : (
            <Box component="img" src={School} alt={`School ${index}`}></Box>
          )}
          {isLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {detail?.school}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturedAthletesCard;
