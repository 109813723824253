import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import DealTrackerCard from "../../../../components/afterLogin/brands/dealTracker/dealTracker.card";
import {
  athleteDealTracker,
  brandDealTracker,
} from "../../../../services/campaign/campaign.service";
import AthleteDealTrackerCard from "../../../../components/afterLogin/athletes/dealTracker/dealTracker.card";

const AthleteDealTrackerPage = () => {
  const theme = useTheme();
  const [dealTrackerData, setDealTrackerData] = useState(null);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const fetchDealTracker = async () => {
    const response = await athleteDealTracker();
    setIsAllDataLoading(false);
    if (response?.status === 200) {
      setDealTrackerData(response?.data);
    } else {
      console.error("Failed to fetch deal tracker:", response?.message);
    }
  };

  useEffect(() => {
    fetchDealTracker();
  }, []);

  return (
    <Box
      sx={{
        paddingX: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
      }}
    >
      <Typography
        color="white"
        fontFamily={theme.fonts.livvic}
        fontWeight={theme.fonts.fontWeightMedium}
        sx={{
          fontSize: { xs: theme.fonts.heading_xs, sm: theme.fonts.heading },
          mb: 3,
        }}
      >
        Deal Tracker
      </Typography>

      <Grid
        container
        spacing={4}
        sx={{
          mb: "30px",
          justifyContent: {
            xs: "center",
            sm: "left",
            md: "center",
            lg: "left",
          },
        }}
      >
        {isAllDataLoading ? (
          Array.from({ length: 10 }, (_, index) => (
            <AthleteDealTrackerCard
              isAllDataLoading={isAllDataLoading}
              key={index}
            />
          ))
        ) : dealTrackerData?.length ? (
          dealTrackerData?.map((details, index) => (
            <AthleteDealTrackerCard details={details} key={index} />
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: { xs: "24px", sm: "30px" },
              minHeight: {
                xs: "calc(95vh - 95px)",
                sm: "calc(95vh - 115px)",
              },
              width: "100%",
              background: "rgba(18,18,18,1)",
              ml: "30px",
              mt: "30px",
            }}
          >
            No active campaigns yet!
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default AthleteDealTrackerPage;
