import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import TheEcosystemBackground from "../../assets/images/TheEcosystemBackground.png";
import BurrstSmallLogo from "../../assets/logo/BurrstSmallLogo.svg";
import BurrstLogo from "../../assets/logo/BurrstLogo.svg";
import FacebookLogo from "../../assets/logo/FacebookLogo.png";
import InstagramLogo from "../../assets/logo/InstagramLogo.png";
import TwitterLogo from "../../assets/logo/TwitterLogo.png";
import TikTokLogo from "../../assets/logo/TikTokLogo.png";
import YoutubeLogo from "../../assets/logo/YoutubeLogo.png";
import LinkedInLogo from "../../assets/logo/LinkedInLogo.png";

import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import Pulse from "../../themes/@overrides/pulse";
import { Close, Done } from "@mui/icons-material";
import { contactUs } from "../../services/beforeLogin/beforeLogin.service";
import { ToastContainer, toast } from "react-toastify";
import Schema from "../../joi.schema/beforeLogin/contactUs/contactUs.schema";
import FontsLoader from "../fontsLoader";

const SocialMediaLogo = ({ sx }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        ...sx,
      }}
    >
      <NavLink
        to="https://www.facebook.com/burrstsports"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Box
          component="img"
          src={FacebookLogo}
          alt="Facebook Logo"
          sx={{
            width: "24px",
            height: "24px",
          }}
        ></Box>
      </NavLink>
      <NavLink
        to="https://www.instagram.com/burrstsports"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Box
          component="img"
          src={InstagramLogo}
          alt="Instagram Logo"
          sx={{
            width: "24px",
            height: "24px",
          }}
        ></Box>
      </NavLink>
      <NavLink
        to="https://twitter.com/burrstsports"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Box
          component="img"
          src={TwitterLogo}
          alt="Twitter Logo"
          sx={{
            width: "24px",
            height: "24px",
          }}
        ></Box>
      </NavLink>
      <NavLink
        to="https://www.tiktok.com/@burrst"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Box
          component="img"
          src={TikTokLogo}
          alt="TikTok Logo"
          sx={{
            width: "24px",
            height: "24px",
          }}
        ></Box>
      </NavLink>
      <NavLink
        to="https://www.youtube.com/channel/UCSCPHO3hDSqDA5AiSICXuGw"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Box
          component="img"
          src={YoutubeLogo}
          alt="Youtube Logo"
          sx={{
            width: "24px",
            height: "24px",
          }}
        ></Box>
      </NavLink>
      <NavLink
        to="https://www.linkedin.com/company/burrst-sports/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Box
          component="img"
          src={LinkedInLogo}
          alt="LinkedIn Logo"
          sx={{
            width: "24px",
            height: "24px",
          }}
        ></Box>
      </NavLink>
    </Box>
  );
};

const ContactUs = () => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const { error } = Schema.validate(formData);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({
      ...preValue,
      [name]: value,
    }));
  };

  const handleSend = async () => {
    if (error) {
      error?.details?.forEach((err) => {
        toast.error(err?.message);
      });
    } else {
      setIsLoading(true);
      const response = await contactUs(formData);
      setIsLoading(false);
      if (response?.status === 200) {
        setFormData({});
        setModalOpen(true);
      } else {
        toast.error(response?.message);
      }
    }
  };

  const handleIframeLoad = () => {
    setIsIframeLoaded(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Contact | Burrst</title>
        <meta
          name="description"
          content="Get in touch with us for any inquiries, support, or feedback. Visit our Contact Us page for various ways to reach our dedicated team. We're here to help!"
        />
        <link rel="canonical" href={`${HOST_URL}/contact-us`} />
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        {/* <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            >
              CONTACT
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Contact
              </Typography>
            </Box>
          </Box>
        </Box> */}

        {/* <Box
          sx={{
            px: { xs: "10px", sm: PADDING_X },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: { xs: "530px", md: "500px" },
            width: "100%",
            maxWidth: "1536px",
            my: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: { xs: "100%", sm: "80%" },
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                height: "100%",
                background: "rgba(30,30,30,1)",
              }}
            ></Box>
            <Box
              sx={{
                width: "50%",
                height: "100%",
                display: { xs: "none", md: "block" },
                // background: "rgba(42, 0, 0, 0.5)",
                background:
                  "linear-gradient(rgba(42, 0, 0, 0.4),rgba(0, 0, 0, 1) ,rgba(42, 0, 0, 0.4) )",
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: 1,
                // background: "yellow",
                padding: { xs: "20px", sm: "30px" },
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  border: "1px solid red",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "50%" },
                    height: "100%",
                    p: "20px",
                    position: "relative",
                    "&::before": {
                      content: '"Contact Us"',
                      color: "red",
                      position: "absolute",
                      top: { xs: -16, sm: -18 },
                      textAlign: "center",
                      width: { xs: "120px", sm: "150px" },
                      left: {
                        xs: "calc((100% - 100px) / 2)",
                        sm: "calc((100% - 130px) / 2)",
                      },
                      background: "rgba(30,30,30,1)",
                      py: "5px",
                      fontSize: { xs: "16px", sm: "20px" },
                      fontFamily: theme.fonts.russoOne,
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: -7,
                      left: {
                        xs: "calc((100% - 145px) / 2)",
                        sm: "calc((100% - 175px) / 2)",
                      },
                      zIndex: 1,
                      textAlign: "right",
                      pl: "15px",
                      background: "rgba(30,30,30,1)",
                    }}
                  >
                    <Pulse />
                  </Box>

                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: "14px",
                        }}
                      >
                        First Name
                      </Typography>
                      <TextField
                        name="firstName"
                        value={formData?.firstName || ""}
                        onChange={handleFormDataChange}
                        fullWidth
                        // autoComplete="off"
                        size="small"
                        InputProps={{
                          sx: {
                            border: `1px solid ${theme.colors.common.white}`,
                            background: "black",
                            color: theme.colors.common.white,
                            borderRadius: "10px",
                            fontSize: "14px",
                          },
                        }}
                        sx={{
                          marginTop: "5px",
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused": {
                              fieldset: {
                                borderColor: theme.colors.common.white,
                              },
                            },
                          },
                          "& input, & textarea, & select": {
                            borderRadius: "10px",
                            WebkitTextFillColor: "#FFFFFF",
                            WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                            transition: "background-color 5000s ease-in-out 0s",
                          },
                          // "& input:hover, & input:focus, & textarea:hover, & textarea:focus, & select:hover, & select:focus":
                          //   {
                          //     borderColor: "theme.colors.common.white",
                          //   },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: "14px",
                        }}
                      >
                        Last Name
                      </Typography>
                      <TextField
                        name="lastName"
                        value={formData?.lastName || ""}
                        onChange={handleFormDataChange}
                        fullWidth
                        // autoComplete="off"
                        size="small"
                        InputProps={{
                          sx: {
                            border: `1px solid ${theme.colors.common.white}`,
                            background: "black",
                            color: theme.colors.common.white,
                            borderRadius: "10px",
                            fontSize: "14px",
                          },
                        }}
                        sx={{
                          marginTop: "5px",
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused": {
                              fieldset: {
                                borderColor: theme.colors.common.white,
                              },
                            },
                          },
                          "& input, & textarea, & select": {
                            borderRadius: "10px",
                            WebkitTextFillColor: "#FFFFFF",
                            WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                            transition: "background-color 5000s ease-in-out 0s",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: "14px",
                        }}
                      >
                        Email
                      </Typography>
                      <TextField
                        name="email"
                        type="email"
                        value={formData?.email || ""}
                        onChange={handleFormDataChange}
                        fullWidth
                        // autoComplete="off"
                        size="small"
                        InputProps={{
                          sx: {
                            border: `1px solid ${theme.colors.common.white}`,
                            background: "black",
                            color: theme.colors.common.white,
                            borderRadius: "10px",
                            fontSize: "14px",
                          },
                        }}
                        sx={{
                          marginTop: "5px",
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused": {
                              fieldset: {
                                borderColor: theme.colors.common.white,
                              },
                            },
                          },
                          "& input, & textarea, & select": {
                            borderRadius: "10px",
                            WebkitTextFillColor: "#FFFFFF",
                            WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                            transition: "background-color 5000s ease-in-out 0s",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: "14px",
                        }}
                      >
                        Message
                      </Typography>
                      <TextField
                        name="message"
                        value={formData?.message || ""}
                        onChange={handleFormDataChange}
                        fullWidth
                        // autoComplete="off"
                        multiline
                        InputProps={{
                          sx: {
                            border: `1px solid ${theme.colors.common.white}`,
                            background: "black",
                            color: theme.colors.common.white,
                            borderRadius: "10px",
                            fontSize: "14px",
                            "&::-webkit-scrollbar": {
                              width: "0px",
                            },
                            "& textArea": {
                              overflow: "hidden",
                              maxHeight: "70px",
                            },
                          },
                        }}
                        sx={{
                          marginTop: "5px",
                          "& .MuiOutlinedInput-root": {
                            height: "80px",
                            "&.Mui-focused": {
                              fieldset: {
                                borderColor: theme.colors.common.white,
                              },
                            },
                          },
                          "& input, & textarea, & select": {
                            WebkitTextFillColor: "#FFFFFF",
                            WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                            transition: "background-color 5000s ease-in-out 0s",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "right", mt: 2 }}>
                      <Button
                        variant="contained"
                        disabled={isLoading}
                        sx={{
                          background: "red",
                          minWidth: "120px",
                          maxWidth: "150px",
                          height: "40px",
                          fontWeight: theme.fonts.fontWeightBold,
                          fontSize: "14px",
                          fontFamily: theme.fonts.livvic,
                          "&:hover": {
                            background: theme.colors.divider.red,
                          },
                          "&.MuiButton-root.Mui-disabled": {
                            background: "rgba(255,0,0,0.5)",
                            color: "white",
                          },
                        }}
                        onClick={handleSend}
                      >
                        {isLoading ? (
                          <CircularProgress sx={{ color: "white" }} />
                        ) : (
                          "SEND"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <SocialMediaLogo
                        sx={{ mt: 2, display: { xs: "flex", md: "none" } }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    height: "100%",
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={BurrstSmallLogo}
                      alt="Burrst Small Logo"
                      sx={{ width: "200px", height: "200px" }}
                    ></Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 4,
                      }}
                    >
                      <Box
                        component="img"
                        src={BurrstLogo}
                        alt="Burrst Logo"
                        sx={{ width: "150px", height: "auto" }}
                      ></Box>
                      <SocialMediaLogo />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box> */}

        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            px: { xs: "10px", sm: PADDING_X },
            width: "100%",
            maxWidth: "1536px",
            border: "none",
            position: "relative",
          }}
        >
          {!isIframeLoaded && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: {
                  xs: "calc(100svh - 95px)",
                  sm: "calc(100svh - 115px)",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontsLoader />
            </Box>
          )}
          <Box
            component="iframe"
            id="iFrameContactUs"
            title="Contact Us"
            src="https://forms.monday.com/forms/embed/fd66c97023d0a18ca7b19919395b2666?r=use1"
            width="100%"
            sx={{
              border: "0",
              minHeight: isIframeLoaded
                ? "1000px"
                : {
                    xs: "calc(100svh - 95px)",
                    sm: "calc(100svh - 115px)",
                  },
              visibility: isIframeLoaded ? "visible" : "hidden",
            }}
            onLoad={handleIframeLoad}
          ></Box>
        </Box>
      </Box>

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            minWidth: { xs: "80vw", sm: "60vw", md: "40vw" },
            background: "black",
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <DialogContent sx={{ p: "10px", position: "relative" }}>
          <Close
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: theme.colors.text.greyDark,
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
              gap: 2,
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: "5px",
                background: "rgba(14,14,14,1)",
                borderRadius: "50%",
              }}
            >
              <Done />
            </Box>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              fontSize="20px"
            >
              Thank You!
            </Typography>
            <Typography fontFamily={theme.fonts.livvic}>
              Thank you for reaching out to us!
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactUs;
