import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";

import LatestArticles from "../../../../../assets/images/LatestArticles.webp";
import MoreArticles from "../../../../../assets/images/MoreArticles.webp";
import ArticlesUserIcon from "../../../../../assets/icons/ArticlesUserIcon.svg";
import ArticlesCalendarIcon from "../../../../../assets/icons/ArticlesCalendarIcon.svg";

import { BASE_IMAGE_URL } from "../../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const MoreArticlesCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const PodcastsNextGeneration = `${BASE_IMAGE_URL}/PodcastsNextGeneration.png`;

  const [isImageError, setIsImageError] = useState(false);

  const handleImageError = (e) => {
    e.target.src = MoreArticles;
    setIsImageError(true);
  };

  const handleCardClick = () => {
    navigate(`/dashboard/resources/newsletter/${detail?._id}`);
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.colors.text.greyDark}`,
        background: theme.colors.common.black,
        overflow: "hidden",
        cursor: "pointer",
        minWidth: { xs: "250px", sm: "100%" },
        maxWidth: { xs: "350px", sm: "100%" },
      }}
      onClick={isAllDataLoading ? null : handleCardClick}
    >
      <Box
        sx={{
          p: "10px",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 1,
          height: { sm: "105px" },
          width: "100%",
        }}
      >
        {/* -----------------------------Image--------------------------- */}
        <Box
          sx={{
            width: { xs: isAllDataLoading ? "100%" : "auto", sm: "100px" },
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton
              type="img"
              sx={{ minWidth: "100%", minHeight: "100%" }}
            />
          ) : (
            <Box
              component="img"
              src={detail?.featuredImage}
              alt="More Articles"
              onError={handleImageError}
              sx={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
            ></Box>
          )}
        </Box>

        {/* -----------------------Author and Calendar Box----------------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: { sm: "calc(100% - 100px)" },
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" sx={{ mb: 2 }} />
          ) : (
            <Box
              sx={{
                height: "40px",
                mb: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightMedium}
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  fontSize: { xs: "13px", lg: "16px" },
                }}
              >
                {detail?.articleTitle}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              height: "40px",
            }}
          >
            {/* -------------------------User Icon and Author Name----------------------------- */}
            {isAllDataLoading ? (
              <CustomSkeleton type="p" sx={{ mb: 2 }} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Box
                  component="img"
                  src={ArticlesUserIcon}
                  alt="Stopwatch Icon"
                ></Box>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize="12px"
                  color={theme.colors.text.red}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {detail?.authorName}
                </Typography>
              </Box>
            )}

            {/* ------------------Calendar Icon and Date--------------------------- */}
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.createdAt && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Box
                    component="img"
                    src={ArticlesCalendarIcon}
                    alt="Calendar Icon"
                  ></Box>
                  <Typography
                    variant="h4"
                    fontFamily={theme.fonts.livvic}
                    fontSize="12px"
                  >
                    {dayjs(detail?.createdAt).format("MMM DD YYYY")}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MoreArticlesCard;
