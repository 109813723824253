import {
  getClickCountApi,
  getRoiCampaignApi,
  getRoiCampaignAthleteApi,
  getRoiCampaignRevenueApi,
  getTopThreeCampaignsApi,
  getTrackLinkApi,
} from "./api.request";

const ROICAMPAIGN_PATH = "/product/newROICampaigns";
const ROICAMPAIGNREVENUE_PATH = "/product/roiRevenue";
const ROICAMPAIGNATHLETE_PATH = "/product/roiAthlete";
const CLICKCOUNT_PATH = "/product/clickCount";
const TRACKLINK_PATH = "/product/link";
const TOPTHREECAMPAIGNS_PATH = "/product/dashboard";

export const getRoiCampaign = async () =>
  getRoiCampaignApi(`${ROICAMPAIGN_PATH}`);

export const getRoiCampaignRevenue = async (id) =>
  getRoiCampaignRevenueApi(`${ROICAMPAIGNREVENUE_PATH}`, id);

export const getRoiCampaignAthlete = async (id) =>
  getRoiCampaignAthleteApi(`${ROICAMPAIGNATHLETE_PATH}`, id);

export const getClickCount = async (id) =>
  getClickCountApi(`${CLICKCOUNT_PATH}`, id);

export const getTrackLink = async (body) =>
  getTrackLinkApi(`${TRACKLINK_PATH}`, body);

export const getTopThreeCampaigns = async (body) =>
  getTopThreeCampaignsApi(`${TOPTHREECAMPAIGNS_PATH}`, body);
