import { Box, useTheme } from "@mui/material";
import React from "react";
import { keyframes } from "@emotion/react";

const Pulse = () => {
  const theme = useTheme();
  const pulse = keyframes`
    100% {
      transform: scale(3);
      opacity: 0;
    }
`;
  return (
    <Box
      sx={{
        height: "12px",
        width: "12px",
        position: "relative",
        placeItems: "center",
        background: theme.colors.gradientBlackRedDot,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&::before,&::after": {
          content: '""',
          position: "absolute",
          height: "100%",
          width: "100%",
          background: theme.colors.text.red,
          borderRadius: "50%",
          zIndex: -1,
          opacity: 0.7,
        },

        "&::before": {
          animation: `${pulse} 2s ease-out infinite`,
        },

        "&::after": {
          animation: `${pulse} 2s 1s ease-out infinite`,
        },
      }}
    ></Box>
  );
};

export default Pulse;
