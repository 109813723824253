import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet-async";

import Hero from "../../components/beforeLogin/home/sections/hero";
import RecentNewsAndArticles from "../../components/beforeLogin/home/sections/recentNewsAndArticles";
import CoreValues from "../../components/beforeLogin/home/sections/coreValues";
import TheEcosystem from "../../components/beforeLogin/home/sections/theEcosystem";
import OurPartners from "../../components/beforeLogin/home/sections/ourPartners";
import Podcasts from "../../components/beforeLogin/home/sections/podcasts";
import Benefits from "../../components/beforeLogin/home/sections/benefits";
import Testimonials from "../../components/beforeLogin/home/sections/testimonials";
import { HOST_URL } from "../../utils/constants";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home | Burrst</title>
        <meta
          name="description"
          content="The Next Generation. Our goal is to give all student-athletes opportunities to profit from their name, image, and likeness (NIL) and help brands measure the returns on their investment in collaborating with athletes."
        />
        <link rel="canonical" href={`${HOST_URL}`} />
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <Hero />
        </Box>
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <TheEcosystem />
        </Box>
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <Benefits />
        </Box>
        {/* <FeaturedAthletes />
        <FeaturedCampaigns /> */}
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <RecentNewsAndArticles />
        </Box>
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <Podcasts />
        </Box>
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <Testimonials />
        </Box>
        {/* <WeeklyNewsletter /> */}
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <CoreValues />
        </Box>
        <Box sx={{ width: "100%", maxWidth: "1536px" }}>
          <OurPartners />
        </Box>
      </Box>
    </>
  );
};

export default Home;
