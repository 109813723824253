import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../../assets/icons/CrossIcon.svg";
import CancleSubscriptionIcon from "../../../../assets/icons/CancleSubsIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import { brandCancleSubscriptionPlans } from "../../../../services/brand/brand.service";

const CancleSubscriptionModal = ({ handleCloseCancleModal }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handleKeep = () => {
    handleCloseCancleModal();
  };

  const handleCancle = async () => {
    setLoading(true);
    const response = await brandCancleSubscriptionPlans();
    setLoading(false);

    if (response.status === 200) {
      toast.success("Subscription cancled successfully");
    } else {
      toast.error("Subscription cancled failed");
      console.error("Failed brandCancleSubscriptionPlans:", response.message);
    }
  };

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer", width: "20px" }}
        onClick={handleCloseCancleModal}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: "40px",
        }}
      >
        <Box
          component="img"
          src={CancleSubscriptionIcon}
          alt="Cancle Subscription Icon"
          sx={{
            width: "90px",
            mb: "30px",
          }}
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          sx={{ fontSize: { xs: "22px", md: "24px" } }}
        >
          Are you sure?
        </Typography>
        <Typography fontFamily={theme.fonts.livvic} color="#A0A0A0">
          Are you sure you want to cancel your subscription?
        </Typography>

        <Box display="flex" justifyContent="center" mt="30px" gap={2}>
          <Button
            onClick={handleKeep}
            variant="contained"
            sx={{
              px: "25PX",
              border: "3px solid #797979",
              background: "#000000",
              width: { xs: "160px", sm: "260px" },
              mt: 1,
              textTransform: "none",
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: { xs: "14px", sm: "18px" },
              fontFamily: theme.fonts.livvic,
              "&:hover": {
                background: "#000000",
              },
            }}
          >
            KEEP PLAN
          </Button>
          <Button
            onClick={handleCancle}
            variant="contained"
            sx={{
              px: "25PX",
              border:
                "3px solid linear-gradient(90.36deg, rgba(0, 0, 0, 0.96) -9.59%, rgba(70, 0, 0, 0.970952) 21.87%, #FF0000 105.31%)",
              background: "red",
              width: { xs: "160px", sm: "260px" },
              mt: 1,
              textTransform: "none",
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: { xs: "14px", sm: "18px" },
              fontFamily: theme.fonts.livvic,
              "&:hover": {
                background: theme.colors.divider.red,
              },
            }}
          >
            {loading ? <CircularProgress sx={{ color: "gray" }} /> : "SUBMIT"}
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default CancleSubscriptionModal;
