import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { myCampaignAiRecommended } from "../../../../../_mock/dummyData";
import AIrecommendedCard from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/airecommended.card";
import {
  getAiRecommendedAthletes,
  showInterestAiAthlete,
} from "../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";

const AIrecommended = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState([]);
  const [formData, setFormData] = useState({
    athleteId: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState({
    page: 1,
    pageSize: 10,
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleViewApplicants = () => {
    navigate("/dashboard/campaigns/view-applicants");
  };

  useEffect(() => {
    callApi();
  }, [formData, apiData]);

  const callApi = async () => {
    setLoading(true);

    const response = await getAiRecommendedAthletes(apiData);

    if (response.status === 200) {
      setLoading(false);
      setResponseData(response.data);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setLoading(false);
      console.error(
        "Failed to fetch AI recommended athlete:",
        response.message
      );
    }
  };

  const handlePrev = () => {
    if (apiData?.page > 1) {
      callApi(
        setApiData((prevFormData) => ({
          ...prevFormData,
          page: apiData?.page - 1,
        }))
      );
    }
  };

  const handleNext = () => {
    callApi(
      setApiData((prevFormData) => ({
        ...prevFormData,
        page: apiData?.page + 1,
      }))
    );
  };

  const handleInterested = async (id) => {
    setFormData((prevData) => ({
      ...prevData,
      athleteId: id,
      type: "INTERESTED",
    }));
    const res = await callShowInterestApi({
      athleteId: id,
      type: "INTERESTED",
    });
    if (res === 200) {
      toast.success("Notification sent to athlete.");
    }
  };

  const handleNotInterested = async (id) => {
    setFormData((prevData) => ({
      ...prevData,
      athleteId: id,
      type: "NOT_INTERESTED",
    }));
    const res = await callShowInterestApi({
      athleteId: id,
      type: "NOT_INTERESTED",
    });
    if (res === 200) {
      toast.success("Updated list.");
    }
  };

  // useEffect(() => {
  //   // callShowInterestApi();
  // }, [formData]);

  const callShowInterestApi = async (formData) => {
    const response = await showInterestAiAthlete(formData);

    if (response.status === 200) {
      callApi();

      setFormData({
        ...formData,
        athleteId: "",
        type: "",
      });
      return response.status;
    } else {
      toast.error(response.message);
      console.error(
        "Failed to fetch AI recommended show interest api response:",
        response.message
      );
    }
  };

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "60px",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      <Grid my="20px" display="flex" alignItems="center">
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "35px", md: "40px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          ml="15px"
          sx={{
            fontSize: { xs: "25px", md: "35px" },
          }}
        >
          AI recommended
        </Typography>
      </Grid>
      <Grid backgroundColor="#121212" padding="1%">
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: "3px",
            marginX: "2.5%",
            marginY: "25px",
            borderRadius: "50px",
            border: "1px solid #900505",
            background:
              "linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, #900505, #900505)",
          }}
        >
          <Button
            onClick={() => handleViewApplicants()}
            sx={{
              width: "50%",
              borderRadius: "50px",
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              textTransform: "none",
              fontSize: "22px",
              color: "#C5C5C5",

              "@media (max-width:468px)": {
                fontSize: "14px",
              },
            }}
          >
            View Applicants
          </Button>
          <Button
            sx={{
              width: "50%",
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              textTransform: "none",
              fontSize: "22px",
              color: "white",
              background: "red",
              borderRadius: "50px",
              "&:hover": {
                backgroundColor: "darkred",
              },
              "@media (max-width:468px)": {
                fontSize: "14px",
              },
            }}
          >
            AI recommended
          </Button>
        </Grid>
        {loading ? (
          <Box display="flex" justifyContent="center" my="100px">
            <CircularProgress sx={{ color: "gray" }} />
          </Box>
        ) : responseData?.data?.length > 0 ? (
          <>
            <Grid
              container
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              {responseData?.data?.map((details, index) => (
                <AIrecommendedCard
                  detail={details}
                  key={index}
                  handleInterested={handleInterested}
                  handleNotInterested={handleNotInterested}
                  formData={formData}
                />
              ))}
            </Grid>
            <Box
              sx={{
                gap: "20px",
                mb: "30px",
                pb: "30px",
                px: "50px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: theme.colors.dashboard.grey200,
              }}
            >
              <Button
                onClick={handlePrev}
                disabled={apiData?.page === 1}
                sx={{
                  px: { xs: "50px", md: "70px" },
                  textTransform: "none",
                  color: "white",
                  fontSize: { xs: "18px", md: "20px" },
                  borderRadius: "5px",
                  backgroundColor: "#DD0000",
                  marginTop: "10px",
                  fontWeight: 500,
                  "&.MuiButton-root.Mui-disabled": {
                    background: "#252525",
                    color: "#c5c5c5",
                  },
                  "&:hover": {
                    backgroundColor: "red",
                  },
                }}
              >
                Previous
              </Button>
              <Typography sx={{ mt: "5px", fontSize: "20px", fontWeight: 600 }}>
                {apiData?.page}
              </Typography>
              <Button
                onClick={handleNext}
                disabled={formData?.length < 10}
                sx={{
                  px: { xs: "50px", md: "80px" },
                  textTransform: "none",
                  color: "white",
                  fontSize: { xs: "18px", md: "20px" },
                  borderRadius: "5px",
                  backgroundColor: "#DD0000",
                  marginTop: "10px",
                  fontWeight: 500,
                  "&.MuiButton-root.Mui-disabled": {
                    background: "#252525",
                    color: "#c5c5c5",
                  },
                  "&:hover": {
                    backgroundColor: "red",
                  },
                }}
              >
                Next
              </Button>
            </Box>
          </>
        ) : (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontFamily={theme.fonts.livvic}
            fontWeight={500}
            py="100px"
            sx={{ fontSize: { xs: "20px", md: "22px" } }}
          >
            No AI recommendation
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default AIrecommended;
