import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../assets/icons/CrossIcon.svg";
// import { BASE_IMAGE_URL } from "../../../utils/constants";
import verifiedImage from "../../../assets/images/OtpVerifiedImage.png";

const PasswordResetSuccessfulModal = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMyProfile = location?.state?.isMyProfile;

  // const verifiedImage = `${BASE_IMAGE_URL}/OtpVerifiedImage.png`;

  const handleOk = () => {
    if (isMyProfile) {
      navigate("/dashboard/home/my-profile", { state: { isMyProfile: true } });
    } else {
      localStorage.clear();
      navigate("/login");
    }
  };

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      {/* <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handlesuccessModalClose}
      ></Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: "40px",
          gap: 3,
        }}
      >
        <Box
          component="img"
          src={verifiedImage}
          alt="Otp Verified"
          width="200px"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h5"
        >
          Successful!
        </Typography>
        <Typography fontFamily={theme.fonts.livvic}>
          Password changed successfully
        </Typography>

        <Button
          // fullWidth
          sx={{
            background: theme.colors.background.registerButton,
            color: "white",
            height: "50px",
            width: "50%",
            mt: "10px",
            fontSize: "22px",
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            borderRadius: "10px",
            "&:hover": {
              background: theme.colors.divider.red,
            },
          }}
          onClick={handleOk}
        >
          OK
        </Button>
      </Box>
    </DialogContent>
  );
};

export default PasswordResetSuccessfulModal;
