import {
  REDIRECT_TO_DASHBOARD,
  REDIRECT_TO_SUBSCRIPTION,
} from "../../utils/constants";
import {
  athleteList,
  registerBrand,
  updateBrandProfile,
  getBrandsMyProfileApi,
  getDetailsApi,
  getListApi as getBrandsCampaignsListApi,
  brandSubscriptionPlansApi,
  brandSubscribedCheckApi,
  startTrialApi,
  brandtrialRemainingApi,
  brandBusinessCategoriesApi,
  brandChangePlanApi,
  brandCancleSubscriptionPlansApi,
  brandCurrentSubscriptionPlansApi,
  brandUserSubscribeApi,
  brandUserCreateApi,
  brandUserListApi,
  contactSalesApi,
  updatePaymentApi,
  brandCountApi,
  getFeaturedBrandsApi,
} from "./api.request";

const BRAND_PATH_REGISTRATION = "/brand/brandRegistration";
const BRAND_PATH_UPDATEPROFILE = "/brand/updateBrandProfile";
const BRAND_PATH_GETATHLETELIST = "/brand/athleteList";
const BRAND_DETAILS_PATH = "/brand/brandDetails";
const BRANDS_LIST_PATH = "/brand/brandCampaignsList";
const BRANDS_SUBSCRIPTIONPLAN = "/brand/subscriptionPlans";
const BRANDS_SUBSCRIBED_CHECK = "/brand/brandSubscribed";
const BRANDS_STARTTRIAL = "/brand/startTrial";
const BRANDS_TRIALREMAINING = "/brand/trialRemaining";
const BRANDS_BUSINESSCATEGORIES = "/brand/businessCategories";
const BRANDS_CHANGESUBSCRIPTIONPLAN = "/brand/changePlan";
const BRANDS_CANCLESUBSCRIPTIONPLAN = "/brand/cancelSubscription";
const BRANDS_CURRENTSUBSCRIPTIONPLAN = "/brand/currentPlan";
const BRANDS_USERCREATE = "/brand/createUser";
const BRANDS_USERSUBSCRIBE = "/brand/userSubscribe";
const BRANDS_USERLIST = "/brand/userList";
const BRANDS_CONTACT_SALES = "/brand/contactSales";
const BRANDS_UPDATE_PAYMENT_DETAILS = "/brand/updatePaymentDetails";
const BRAND_COUNT_PATH = "/brand/brandRegistering";
const BRANDS_PATH_FEATURED = "/brand/featuredBrands";

export const brandCurrentSubscriptionPlans = async () =>
  brandCurrentSubscriptionPlansApi(`${BRANDS_CURRENTSUBSCRIPTIONPLAN}`);

export const getContactSales = async () =>
  contactSalesApi(`${BRANDS_CONTACT_SALES}`);

export const getBrandCount = async () => brandCountApi(`${BRAND_COUNT_PATH}`);

export const updatePaymentDetails = async () =>
  updatePaymentApi(BRANDS_UPDATE_PAYMENT_DETAILS);

export const brandCancleSubscriptionPlans = async () =>
  brandCancleSubscriptionPlansApi(`${BRANDS_CANCLESUBSCRIPTIONPLAN}`);

export const brandRegistration = async (body) =>
  registerBrand(`${BRAND_PATH_REGISTRATION}`, body);

export const brandProfileUpdate = async (body) =>
  updateBrandProfile(`${BRAND_PATH_UPDATEPROFILE}`, body);

export const brandGetAthleteList = async (body) => {
  const result = await athleteList(`${BRAND_PATH_GETATHLETELIST}`, body);
  return result;
};

export const getBrandsMyProfile = async (body) => {
  const result = await getBrandsMyProfileApi(`${BRAND_DETAILS_PATH}`, body);
  return result;
};

export const getBrandDetails = async (userId) =>
  getDetailsApi(`${BRAND_DETAILS_PATH}`, userId);

export const getBrandsCampaignsList = async (
  brandId,
  searchBy,
  sortBy,
  sortOrder
) =>
  getBrandsCampaignsListApi(
    `${BRANDS_LIST_PATH}?brandId=${brandId}`,
    searchBy,
    sortBy,
    sortOrder
  );

export const brandSubscriptionPlans = async () =>
  brandSubscriptionPlansApi(`${BRANDS_SUBSCRIPTIONPLAN}`);

export const brandSubscribedCheck = async () =>
  brandSubscribedCheckApi(`${BRANDS_SUBSCRIBED_CHECK}`);

export const startTrial = async (body) =>
  startTrialApi(`${BRANDS_STARTTRIAL}`, body);

export const brandtrialRemaining = async () =>
  brandtrialRemainingApi(`${BRANDS_TRIALREMAINING}`);

export const brandBusinessCategories = async () =>
  brandBusinessCategoriesApi(`${BRANDS_BUSINESSCATEGORIES}`);

export const brandChangePlan = async (body) =>
  brandChangePlanApi(`${BRANDS_CHANGESUBSCRIPTIONPLAN}`, body);

export const brandUserCreate = async (body) =>
  brandUserCreateApi(`${BRANDS_USERCREATE}`, body);

export const brandUserSubscribe = async (body) =>
  brandUserSubscribeApi(`${BRANDS_USERSUBSCRIBE}`, body);

export const brandUserList = async () => brandUserListApi(`${BRANDS_USERLIST}`);

export const getFeaturedBrands = async () =>
  getFeaturedBrandsApi(`${BRANDS_PATH_FEATURED}`);
