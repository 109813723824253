import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MyCampaignsCard from "../subComponents/myCampaigns/myCampaignsCard";
import { dashboardCampaignDetails } from "../../../../../_mock/dummyData";
import Pulse from "../../../../../themes/@overrides/pulse";
import { myCampaignsList } from "../../../../../services/campaign/campaign.service";
import EmptyCampaignImage from "../../../../../assets/images/DashboardEmptyCampaign.png";
import { Swiper, SwiperSlide } from "swiper/react";

const MyCampaigns = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const value = [1, 2, 3, 4, 5, 6];
  const [apiData, setApiData] = useState({
    page: 1,
    pageSize: 10,
  });

  const callApi = async () => {
    const response = await myCampaignsList(apiData);

    if (response.status === 200) {
      setFormData([...response?.data]);
      setDataStatus(true);
      setLoading(false);
    } else {
      console.error("Failed to fetch campaigns list:", response?.message);
    }
  };

  const handleViewAll = () => {
    navigate("/dashboard/campaigns");
  };

  const handleAddNewCampaign = () => {
    navigate("/dashboard/campaigns/basic-info");
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box
      sx={{
        paddingX: "30px",
        "@media (max-width:600px)": {
          paddingX: "10px",
        },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt="40px"
        mb="20px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Pulse />
          <Typography
            color={theme.colors.common.white}
            sx={{ fontSize: { xs: "30px", md: theme.fonts.heading } }}
            fontFamily={theme.fonts.russoOne}
          >
            My Campaigns
          </Typography>
        </Box>
        {formData && formData?.length > 0 ? (
          <Typography
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.creamLight}
            sx={{ cursor: "pointer" }}
            onClick={handleViewAll}
          >
            View All
          </Typography>
        ) : null}
      </Box>

      {loading ? (
        <Box display="flex" flexDirection="row" overflow="hidden">
          {value?.map((index) => (
            <Box
              key={index}
              ml="20px"
              mb="20px"
              sx={{ minWidth: { xs: "90%", sm: "400px" } }}
            >
              <Skeleton
                sx={{
                  bgcolor: theme.colors.text.greyDarker,
                  "&::after": { bgcolor: theme.colors.text.greyDark },
                  borderRadius: "4px",
                  width: "100%",
                }}
                variant="rectangular"
                height={220}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          {/* {formData?.map((detail, index) => (
          <MyCampaignsCard detail={detail} key={index} />
        ))} */}
          {formData && formData?.length > 0 ? (
            <Swiper
              grabCursor={true}
              initialSlide={0}
              spaceBetween={32}
              breakpoints={{
                0: { slidesPerView: 1 },
                450: { slidesPerView: 1.2 },
                600: { slidesPerView: 1.5 },
                750: { slidesPerView: 1.8 },
                900: { slidesPerView: 1.7 },
                1050: { slidesPerView: 2 },
                1200: { slidesPerView: 2.3 },
                1350: { slidesPerView: 2.6 },
                1500: { slidesPerView: 2.9 },
                1650: { slidesPerView: 3.2 },
                1800: { slidesPerView: 3.3 },
                1950: { slidesPerView: 3.6 },
                2100: { slidesPerView: 3.9 },
                2250: { slidesPerView: 4.2 },
                2400: { slidesPerView: 4.5 },
                2550: { slidesPerView: 4.8 },
                2700: { slidesPerView: 5.1 },
                2850: { slidesPerView: 5.4 },
                2900: { slidesPerView: 5.7 },
              }}
              style={{ marginBottom: "30px" }}
            >
              {formData?.map((detail, index) => (
                <SwiperSlide key={index}>
                  <MyCampaignsCard detail={detail} key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              width="100%"
              sx={{ gap: { xs: 5, md: 15 } }}
            >
              <Box
                component="img"
                src={EmptyCampaignImage}
                alt="Otp Verified"
                sx={{ width: { xs: "200px", md: "250px" } }}
              ></Box>

              <Box display="flex" flexDirection="column">
                <Typography
                  fontFamily={theme.fonts.livvic}
                  color={theme.colors.text.creamLight}
                  sx={{ fontSize: { xs: "16px", md: "20px" } }}
                >
                  You have not created any campaigns that can be displayed.
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    width: "100%",
                    p: 3,
                  }}
                >
                  <Box
                    fontWeight={theme.fonts.fontWeightMedium}
                    sx={{
                      fontSize: { xs: "18px", md: "20px" },
                      fontFamily: theme.fonts.livvic,
                      borderRadius: "5px",
                      p: "5px 10px",
                      height: "42px",
                      width: { xs: "250px", md: "270px" },
                      float: "right",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: theme.colors.divider.red,
                      background: "rgba(255,230,230,1)",
                      cursor: "pointer",
                    }}
                    onClick={handleAddNewCampaign}
                  >
                    + Create a new campaign
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MyCampaigns;
