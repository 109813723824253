import { Box, List, ListItem, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import TheEcosystemBackground from "../../assets/images/TheEcosystemBackground.png";

import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import { KeyboardArrowUp } from "@mui/icons-material";

const TermsOfUse = () => {
  const theme = useTheme();

  const [isVisible, setIsVisible] = useState(false);
  const [rightPosition, setRightPosition] = useState(20);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const hyperLinkStyle = {
    fontWeight: "bold",
    color: "white",
    textDecoration: "underline",
    textUnderlineOffset: 4,
  };

  const listStyling = {
    listStyle: "upper-roman",
    pl: { xs: 2, sm: 3, lg: 4 },
    display: "flex",
    flexDirection: "column",
    gap: 1,
  };

  const listStylingBox = {
    display: "flex",
    flexDirection: "column",
    gap: 1,
  };

  const listItemStyle = (numBeforeListItem, leftPosition = 34) => ({
    display: "list-item",
    p: 0,
    // position: "relative",
    // "&::before": {
    //   content: `'${numBeforeListItem}'`,
    //   position: "absolute",
    //   background: "blue",
    //   left: -leftPosition + "px",
    //   // top: "1px",
    //   // top: { xs: "3px", sm: "1px" },
    // },
  });

  const handleScroll = () => {
    const scrolledUpAndOutOfViewport = window.scrollY > 400;
    setIsVisible(scrolledUpAndOutOfViewport);
  };

  const calculateRightPosition = () => {
    const maxContainerWidth = 1536;

    const viewportWidth = Math.max(window.innerWidth, maxContainerWidth);

    const marginRight = 20;

    setRightPosition((viewportWidth - maxContainerWidth) / 2 + marginRight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", calculateRightPosition);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", calculateRightPosition);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms Of Use | Burrst</title>
        <meta
          name="description"
          content="Read our Terms of Use to understand your rights and responsibilities when accessing our services. Ensure compliance to maintain a safe and fair environment for all."
        />
        <link rel="canonical" href={`${HOST_URL}/terms-of-use`} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            >
              TERMS OF USE
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Terms Of Use
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            // py: "60px",
            px: { xs: "10px", sm: PADDING_X },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
            minHeight: "440px",
            width: "100%",
            maxWidth: "1536px",
            my: "30px",
          }}
        >
          <Typography sx={{ fontFamily: theme.fonts.livvic, fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>Last Modified: </span> March
            31, 2024
          </Typography>
          <Typography sx={{ fontFamily: theme.fonts.livvic, fontSize: "16px" }}>
            <span style={{ fontWeight: "bold" }}>IMPORTANT NOTICE : </span>{" "}
            PLEASE NOTE THAT THESE TERMS OF USE REQUIRE THAT DISPUTES BE
            RESOLVED IN ILLINOIS. PLEASE REVIEW SECTION 15 BELOW ENTITLED
            "GOVERNING LAW AND JURISDICTION" FOR COMPLETE DETAILS.
          </Typography>

          <Typography sx={{ fontFamily: theme.fonts.livvic, fontSize: "16px" }}>
            BurrstSports LLC ("Burrst", “we”, “us” or “our”) operates the Burrst
            app, a mobile application and website which facilitates name, image,
            and likeness deals for Athletes and Brands and any other services
            necessary to provide the Burrst app (collectively, the “App”).
            Burrst allows several types of users to interact using our App,
            including: Brands and Athletes. Individuals who play a sport at
            their university are “Athletes” and individuals part of a company
            are “Brands.” You must accept these Terms of Use in order to use the
            App. If you do not understand or have questions about these Terms of
            Use, immediately stop all use of the App and contact{" "}
            <NavLink
              to="mailto:grant@burrst.io"
              target="_blank"
              rel="noopener noreferrer"
              style={hyperLinkStyle}
            >
              grant@burrst.io
            </NavLink>
            .
          </Typography>

          <Typography sx={{ fontFamily: theme.fonts.livvic, fontSize: "16px" }}>
            The below Table of Contents enumerates the sections of our Terms for
            your convenience.
          </Typography>

          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Table of Contents
          </Typography>

          <List
            sx={{
              listStyle: "decimal",
              pl: 4,
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#acceptance" style={hyperLinkStyle}>
                Acceptance of the Terms of Use
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#eligibility" style={hyperLinkStyle}>
                Eligibility and User Accounts
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#intellectual-property" style={hyperLinkStyle}>
                Burrst Intellectual Property
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#limited-license" style={hyperLinkStyle}>
                Limited License to Use the App
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#fees" style={hyperLinkStyle}>
                Fees
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#content-and-submissions" style={hyperLinkStyle}>
                Content and Submissions
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#enforcement-rights" style={hyperLinkStyle}>
                Conduct, General Prohibitions and Enforcement Rights
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#dmca-copyright" style={hyperLinkStyle}>
                DMCA Copyright Policy
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#apple-terms" style={hyperLinkStyle}>
                Apple Device and Application Terms
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#third-party" style={hyperLinkStyle}>
                Third Party Services and Resources
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#termination" style={hyperLinkStyle}>
                Termination
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#disclaimer" style={hyperLinkStyle}>
                Disclaimer of Warranties
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#limitations" style={hyperLinkStyle}>
                Limitations of Liability
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#indemnification" style={hyperLinkStyle}>
                Indemnification
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#governing-law" style={hyperLinkStyle}>
                Governing Law and Jurisdiction
              </a>
            </ListItem>
            <ListItem sx={{ display: "list-item", p: 0 }}>
              <a href="#miscellaneous" style={hyperLinkStyle}>
                Miscellaneous
              </a>
            </ListItem>
          </List>

          <List sx={{ listStyle: "decimal", pl: "20px" }}>
            <ListItem id="acceptance" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Acceptance of the Terms of Use.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("1.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      By signing up for, installing, accessing and/or using the
                      App in any manner, you indicate that you have read and
                      understand this Terms of Use Agreement (the "Terms"),
                      which incorporates by reference our Privacy Policy located
                      at{" "}
                      <NavLink
                        to="/privacy-policy"
                        rel="noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        Privacy Policy
                      </NavLink>
                      , that you meet the Eligibility requirements provided in
                      the Eligibility section (see below), and that you agree to
                      be bound by these Terms in their entirety. If you pay for
                      a subscription with Burrst, these Terms shall also include
                      the terms of that subscription. Your continued use of the
                      App constitutes your agreement to the most current version
                      of the Terms. If at any time you do not agree to the
                      Terms, you must cease your use of the App.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("1.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      You can review the most current version of the Terms at
                      any time on this page. We reserve the right to update,
                      change or replace any part of these Terms by posting
                      updates and/or changes to our website. It is your
                      responsibility to check this page periodically for
                      changes. Your continued use of or access to our App
                      following the posting of any changes constitutes
                      acceptance of those changes.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("1.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      To provide the App, we need information about you. Please
                      refer to our{" "}
                      <NavLink
                        to="/privacy-policy"
                        rel="noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        Privacy Policy
                      </NavLink>{" "}
                      to help you understand what information we collect, how we
                      use that information and what choices you have regarding
                      your information when you use our App.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="eligibility" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Eligibility and User Accounts.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("2.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Eligibility.</strong> You represent and warrant
                      that you (i) are an individual person who is at least the
                      applicable age of majority in your legal state of
                      residence; and (ii) you have not previously been suspended
                      or removed from the App. If you have not yet reached the
                      applicable age of majority in your legal state of
                      residence, you may not, under any circumstances or for any
                      reason, use the App. If you are an entity, organization,
                      or company, the individual accepting these Terms on your
                      behalf represents and warrants that they have the
                      authority to bind you to these Terms and you agree to be
                      bound by these Terms.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      You are solely responsible for ensuring that these Terms
                      are in compliance with all laws, rules and regulations
                      applicable to you and the right to access the App is
                      revoked where these Terms or use of the App is prohibited
                      or to the extent offering or provision of the App
                      conflicts with any applicable law, rule or regulation.
                      Further, the App is offered only for your use, and not for
                      the use or benefit of any third party.
                    </Typography>

                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      We may, in our sole discretion, refuse to offer the App to
                      any person or entity and change our eligibility criteria
                      at any time.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("2.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Registration.</strong>The use of the App requires
                      you to create an account by providing a valid email
                      address, username, password, and other information as
                      prompted by the registration process ( an “Account”). You
                      must provide accurate and complete information and keep
                      your Account information updated. If you provide any
                      information that is inaccurate, not current or incomplete,
                      or we have reasonable grounds to suspect that such
                      information is inaccurate, not current or incomplete, or
                      otherwise violates our Terms, we may deny you access to
                      the App, terminate your Account, and/or take such other
                      action as we deem appropriate, at our sole discretion.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("2.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      You shall not: (i) select or use as a username a name of
                      another person with the intent to impersonate that person;
                      (ii) use as a username a name subject to any rights of a
                      person other than you without appropriate authorization;
                      or (iii) use, as a username, a name that is otherwise
                      offensive, vulgar or obscene, as determined by us in our
                      sole discretion. You are solely responsible for the
                      activity that occurs on your Account, and for keeping your
                      Account password and login credentials secure. You may
                      never use another person’s user Account or registration
                      information or permit another user to use your Account.
                      You must notify us immediately at{" "}
                      <NavLink
                        to="mailto:support@burrst.io"
                        target="_blank"
                        rel="rel=noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        support@burrst.io
                      </NavLink>{" "}
                      of any change in your eligibility to use the App, breach
                      of security or unauthorized use of your Account. You
                      should never publish, distribute or post login information
                      for your Account. You shall have the ability to delete
                      your Account, directly by emailing us at{" "}
                      <NavLink
                        to="mailto:support@burrst.io"
                        target="_blank"
                        rel="rel=noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        support@burrst.io.
                      </NavLink>
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("2.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      You may only establish, maintain, use and control one
                      Account for the App. Each Account for the App may only be
                      owned, maintained, used and controlled by one individual.
                      For avoidance of doubt, users may not "co-own" accounts
                      for the App. In the event Burrst determines that you have
                      opened, maintained, used or controlled more than one
                      Account, in addition to any other rights that Burrst may
                      have, Burrst reserves the right to suspend or terminate
                      any or all of your accounts.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("2.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      Without limiting anything in these Terms, we reserve the
                      right, in our sole discretion, to restrict, suspend, or
                      terminate your Account, and/or your access to all or any
                      part of the App at any time, for any or no reason, with or
                      without prior notice, and without liability, including in
                      the event that we stop offering any features or portions
                      of the App. We expressly reserve the right to restrict,
                      suspend and/or terminate your access to any part of the
                      App if we determine, in our sole discretion, that you have
                      violated any of the terms of these Terms, or if your
                      Account has been inactive for 12 months or longer. We also
                      reserve the right to create verified user account and to
                      require additional information from you in order to
                      provide you with a verified user account, should we choose
                      to create such verified accounts.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem
              id="intellectual-property"
              sx={{ display: "list-item", p: 0 }}
            >
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Burrst Intellectual Property.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("3.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      We own or have been granted a license under any and all
                      intellectual property rights relating to the App, the
                      Burrst brand, trade name, trade dress, and other content
                      of the App, including: copyright, trademark, service mark,
                      trade name, trade dress, proprietary logo, insignia,
                      business identifier, and/or other text and graphics that
                      has or provides the "look and feel" of the Burrst brand
                      image, as well as all of the Content, except User Content,
                      including the design, text, graphics, visual interfaces,
                      programming, software, computer code, photographs, video
                      and audio contained on the App (the "Intellectual
                      Property").
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("3.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      Your use of the App does not grant you any rights or
                      licenses relating to the Intellectual Property, including,
                      but not limited to, any copyrights, trademark rights,
                      patent rights, database rights, moral rights, sui generis
                      rights and other intellectual property and proprietary
                      rights therein, except as expressly provided for in these
                      Terms. None of the Intellectual Property may be used,
                      reproduced, published, transmitted, distributed,
                      displayed, performed, exhibited, modified, used to create
                      derivative works, sold, re-sold or used in any sale, or
                      exploited in any way, in whole or in part, except as
                      provided for herein, and unless you obtain our prior
                      written consent. You may not reproduce, modify, display,
                      sell, or distribute the Intellectual Property, or use it
                      in any other way for public or commercial purpose. The
                      foregoing limitations include, but are not limited to,
                      copying or adapting the code used on our App, or the
                      underlying services.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("3.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      All other intellectual property, such as User Content,
                      product names, names of services, trademarks, service
                      marks and other intellectual property is the property of
                      their respective owners and may only be used as permitted
                      by those owners.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="limited-license" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Limited License to Use the App.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("4.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      Subject to your compliance with these Terms, Burrst grants
                      you a limited, non-exclusive, non-transferable,
                      non-sublicenseable license to install a copy of the App on
                      a mobile device and to view the App solely for your own
                      personal purposes. Except as expressly permitted in these
                      Terms or under applicable law, you may not: (a) copy,
                      modify, or create derivative works based on the App; (b)
                      distribute, transfer, sublicense, lease, lend, or rent the
                      App to any third party; (c) reverse engineer, decompile,
                      or disassemble the App; or (d) make the functionality of
                      the App available to multiple users through any means.
                      Burrst reserves all rights in and to the App not expressly
                      granted to you under these Terms.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("4.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      The App is intended for your personal use only and may not
                      be used in connection with any commercial endeavors except
                      those that are specifically permitted or approved by us.
                      Any use of the App in violation of this provision,
                      including but not limited to collecting usernames and/or
                      email addresses of account holders by electronic or other
                      means for the purpose of sending unsolicited email or
                      unauthorized framing of or linking to the App, is
                      prohibited.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="fees" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Fees.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("5.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      Burrst charges service fees (and applicable taxes) to
                      users as part of their use of the App. Except as otherwise
                      provided by Bursst on the App, service fees are
                      non-refundable. Burrst reserves the right to change the
                      service fees at any time, and will provide users notice of
                      any fee changes before they become effective.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("5.")}>
                  <Box sx={listStylingBox}>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: "bold",
                      }}
                    >
                      Brand Membership.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Subscription.</strong> All Brands are required to
                      pay a monthly fee in order to use the App (the “Brand
                      Subscription”). The specific monthly fee and the different
                      aspects of the App that will be made available to the
                      Brand as part of the Brand Subscription shall be provided
                      on the subscription registration page.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      Brand Subscription begins as soon as your initial payment
                      is processed. YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW
                      EACH MONTH WITHOUT NOTICE UNTIL YOU CANCEL. YOU AUTHORIZE
                      US TO STORE YOUR PAYMENT METHOD(S) AND TO AUTOMATICALLY
                      CHARGE YOUR PAYMENT METHOD(S) EVERY MONTH UNTIL YOU
                      CANCEL. WE WILL AUTOMATICALLY CHARGE YOU THE THEN-CURRENT
                      RATE FOR YOUR BRAND SUBSCRIPTION, PLUS APPLICABLE TAXES
                      (SUCH AS VAT OR GST IF THE RATE DOES NOT INCLUDE IT),
                      EVERY MONTH UPON RENEWAL UNTIL YOU CANCEL, AS PROVIDED
                      BELOW.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Cancellation.</strong>You can cancel your
                      subscription anytime via your account page or by
                      contacting us at{" "}
                      <NavLink
                        to="mailto:support@burrst.io"
                        target="_blank"
                        rel="rel=noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        support@burrst.io.
                      </NavLink>{" "}
                      If you cancel within 14 days of your initial order, you’ll
                      be fully refunded. Should you cancel after 14 days, your
                      payment is non-refundable, and your service will continue
                      until the end of that month’s billing period.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>
                        Changes to the Price and Subscription Plans.
                      </strong>{" "}
                      We may change our subscription plans and the price of our
                      service from time to time. We will notify you at least one
                      month before any price changes or changes to your
                      subscription plan will become effective. If you do not
                      wish to accept the price change or change to your
                      subscription plan, you can cancel your membership before
                      the change takes effect.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("5.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Athlete’s Service Fee.</strong> Athletes are not
                      required to pay a subscription fee. Instead, each Athlete
                      agrees that Burrst shall deduct from the proceeds of each
                      deal made through the App a fee of ten percent (10%) of
                      the total transaction price. The fee is calculated from
                      the transaction subtotal and is automatically deducted
                      from the payout to the Athlete.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem
              id="content-and-submissions"
              sx={{ display: "list-item", p: 0 }}
            >
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Limited License to Use the App.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("6.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Rights Granted to You by Us.</strong> Subject to
                      your compliance with these Terms, Burrst grants you a
                      non-exclusive, non-transferable, non-sublicensable,
                      revocable, limited license to access and view the Content
                      solely for your personal and non-commercial use while you
                      are accessing the App. For clarity, the Content is being
                      provided to you solely for the purpose of browsing our
                      App, and therefore, may not be used for such purposes as:
                      collecting and reselling/licensing user information.
                      "Content" means, without limitation, the text, graphics,
                      photos, works of authorship of any kind, and information
                      or other materials that are generated, provided, or
                      otherwise made available to or through the App, including
                      User Content. “User Content” means anything that a user of
                      the App provides us or otherwise makes available through
                      the App. User Content may include, but is not limited to
                      personal biographical information, artwork, images, etc.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("6.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Feedback Ownership.</strong> We may offer users
                      the opportunity to provide feedback/suggestions. In the
                      event that you provide us with any feedback, suggestions
                      or ideas regarding the App, whether solicited or
                      unsolicited, including without limitation: any flaws,
                      errors, bugs, anomalies, problems with the App;
                      suggestions and/or ideas on how to improve or change the
                      App; or suggestions and/or ideas otherwise related to the
                      App (collectively, “Feedback”), you hereby assign to us
                      all rights in the Feedback, including but not limited to,
                      the right to use such Feedback and related information in
                      any manner we deem appropriate, without compensation or
                      notice to, or approval from, you and you expressly waive
                      all moral rights you may have therein.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Rights Granted by You to Us.</strong> By making
                      any User Content available to Burrst and/or through the
                      App, though any media and formats now known or hereafter
                      developed, you grant to Burrst a non-exclusive,
                      transferable, sublicenseable, worldwide, unrestricted,
                      assingnable, royalty-free, perpetual license (or, if not
                      permitted under applicable law, a license for the whole
                      duration, including for any extension thereof, of all
                      relevant rights under any applicable law), to use, copy,
                      modify, create derivative works based upon, publicly
                      display, publish, publicly perform, market, promote,
                      distribute or otherwise make available such User Content,
                      in whole or in part, in any form, media or technology,
                      whether known or hereafter developed, alone or as part of
                      other works, with no payment or other compensation to you
                      for the purpose of operating and providing the App and
                      Content to you and to others, as authorized in these
                      Terms. To the extent any User Content you submit includes
                      your name, likeness, voice, video, or photograph
                      ("Attributes"), you acknowledge and agree that the
                      foregoing license rights granted by this Section shall
                      apply to the same.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("6.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Your Representations and Warranties.</strong> You
                      are solely responsible for all your User Content. By
                      submitting User Content, you represent and warrant that
                      you own all parts of your User Content or that you have
                      all rights, releases and permissions that are necessary to
                      grant us the license rights in your User Content under
                      these Terms. By including or otherwise depicting any third
                      party in your User Content, you represent and warrant that
                      you have obtained any and all necessary rights, permission
                      and/or releases required to: (i) include or otherwise
                      depict such third party (and, if such third party is a
                      Minor, the consent of the Minor’s parent/legal guardian);
                      and (ii) grant Burrst the license rights granted by these
                      Terms, which shall include the right to the use of such
                      third party’s Attributes. Further, you agree to cooperate
                      with Burrst, upon its request, in taking any additional
                      action or obtaining any documentation reasonably requested
                      by Burrst to effectuate the grant of those rights from
                      such third parties. You also represent and warrant that
                      neither your User Content, nor your use and provision of
                      your User Content to be made available through the App,
                      nor any use of your User Content by Burrst, or other users
                      of the App, on or through the App will infringe,
                      misappropriate, defame any third party, or violate a third
                      party’s intellectual property rights, or rights of
                      publicity or privacy, or result in the violation of any
                      applicable law or regulation.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("6.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong> Availability of Content. </strong>
                      We do not guarantee that any Content will be made
                      available on and/or through the App. We do not practice
                      editorial control over: the User Content; or content
                      posted by third parties.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      We reserve the right to, but do not have any obligation
                      to, (i) remove, edit, modify or otherwise manipulate any
                      Content in our sole discretion, at any time, without
                      notice to you and for any reason (including, but not
                      limited to, upon receipt of claims or allegations from
                      third parties or authorities relating to such Content or
                      if we are concerned that you may have violated these
                      Terms), or for no reason at all and (ii) to remove or
                      block any Content from the App.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("6.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong> AI Assisted Contract Review. </strong>As part of
                      using our App, Burrst may make available to its users
                      automated contract review services that utilized
                      generative AI to offer commentary and suggestions
                      ("Automated Review Services") for users who choose to
                      review their own legal documents. However, Burrst does not
                      at any time review the outputs of these Automated Review
                      Services for legal sufficiency; draw legal conclusions,
                      provide legal advice, opinions or recommendations about
                      your legal rights, remedies, defenses, options, selection
                      of forms, or strategies; or apply the law to the facts of
                      your particular licensing transaction. Burrst is not a law
                      firm and may not perform the services performed by an
                      attorney.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("6.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      BY USING ANY OF THESE AUTOMATED REVIEW SERVICES, YOU
                      UNDERSTAND AND AGREE THAT BURRST AND ITS AUTOMATED REVIEW
                      SERVICES ARE NOT A SUBSTITUTE FOR THE ADVICE OR SERVICES
                      OF AN ATTORNEY. FURTHER, THE AUTOMATED REVIEW SERVICES
                      CONTAINED ON OUR APP ARE NOT LEGAL ADVICE AND ARE NOT
                      GUARANTEED TO BE CORRECT, COMPLETE OR UP-TO-DATE.
                      THEREFORE, YOU SHOULD CONSULT A LICENSED ATTORNEY TO
                      ASSIST YOU WITH YOUR LICENSING TRANSACTION. AS BURRST IS
                      NOT A LAW FIRM, PLEASE NOTE THAT COMMUNICATIONS BETWEEN
                      YOU, BURRST AND OTHER USERS ARE NOT BY THEMSELVES
                      PROTECTED AS PRIVILEGED COMMUNICATIONS UNDER THE
                      ATTORNEY-CLIENT PRIVILEGE OR WORK PRODUCT DOCTRINE.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem
              id="enforcement-rights"
              sx={{ display: "list-item", p: 0 }}
            >
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Conduct, General Prohibitions and Enforcement Rights.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("7.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      As a condition of use, you agree not to use the App for
                      any purpose that is prohibited by these Terms. You agree
                      that you are responsible for your own activities, conduct
                      and User Content while using the App, and for any
                      consequences thereof. Violation of our Terms may result in
                      the termination and cancellation of your Account. You
                      acknowledge and agree that we may terminate any Account at
                      any time for any reason (including, but not limited to,
                      our independent assessment or the receipt of claims or
                      allegations from third parties or authorities).
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("7.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      Although Burrst is not obligated to monitor access to or
                      use of the App or Content or to review or edit any
                      Content, we have the right to do so for the purpose of
                      operating the App, to ensure compliance with these Terms,
                      to comply with applicable law or other legal requirements,
                      and for any other reason in our sole discretion. We
                      reserve the right to remove or disable access to any
                      Content, at any time and without notice. Burrst may remove
                      any Content we consider to be objectionable or in
                      violation of these Terms. We have the right to investigate
                      violations of these Terms or conduct that affects the App.
                      We may also consult and cooperate with law enforcement
                      authorities to prosecute users and others who violate the
                      law.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("7.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      You shall not (directly or indirectly): (i) decipher,
                      decompile, disassemble, reverse engineer or otherwise
                      attempt to derive any source code or underlying ideas or
                      algorithms of any part of the App (including without
                      limitation any application), except to the limited extent
                      applicable laws specifically prohibit such restriction,
                      (ii) modify, translate, or otherwise create derivative
                      works of any part of the App, (iii) copy, rent, lease,
                      distribute, or otherwise transfer any of the rights that
                      you receive hereunder, unless expressly permitted by these
                      Terms, or (vi) use or develop any application that
                      interacts with the App or Content without Burrst's written
                      permission. You shall abide by all applicable local,
                      state, national and international laws and regulations.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("7.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      We reserve the right to access, read, preserve, and
                      disclose any information as we reasonably believe is
                      necessary to (i) satisfy any applicable law, regulation,
                      legal process or governmental request, (ii) enforce these
                      Terms, including investigation of potential violations
                      hereof, (iii) detect, prevent, or otherwise address fraud,
                      security or technical issues, (iv) respond to user support
                      requests, or (v) protect the rights, property or safety of
                      us, our users and the public.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="dmca-copyright" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                DMCA Copyright Policy.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("8.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      If you believe that any content, including User Content,
                      or other materials, uploaded, displayed, or offered on or
                      through the App constitutes an infringement of your
                      copyrights, we will respond promptly to any properly
                      submitted notice containing the information detailed
                      below. Pursuant to the U.S. Copyright Act, Title 17,
                      United States Code, Section 512(c)(2), written
                      notifications of claimed copyright infringement should be
                      sent to our Designated Agent at the following contact
                      information:{" "}
                      <NavLink
                        to="mailto:support@burrst.io"
                        target="_blank"
                        rel="rel=noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        support@burrst.io.
                      </NavLink>
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("8.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      To be effective, the notification must be a written
                      communication that includes the following:
                    </Typography>
                  </Box>

                  <List
                    sx={{
                      listStyle: "lower-alpha",
                      pl: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.8,
                    }}
                  >
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        A physical or electronic signature of person authorized
                        to act on behalf of the owner of an exclusive right that
                        is allegedly infringed;
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        Identification of the copyrighted work claimed to have
                        been infringed, or if multiple copyrighted works at a
                        single online site are covered by a single notification,
                        a representative list of such works at that site;
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        Identification of the material that is claimed to be
                        infringing or to be the subject of infringing activity
                        and that is to be removed or access to which is to be
                        disabled, and information reasonably sufficient to
                        permit us to locate the material;
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        Information reasonably sufficient to permit us to
                        contact the complaining party, such as an address,
                        telephone number, and if available, an electronic mail
                        address at which the complaining party may be contacted;
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        A statement that the complaining party has a good faith
                        belief that use of the material in the manner complained
                        of is not authorized by the copyright owner, its agent,
                        or the law;
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        A statement that the information in the notification is
                        accurate, and under penalty of perjury, that the
                        complaining party is authorized to act on behalf of the
                        owner of an exclusive right that is allegedly infringed.
                      </Typography>
                    </ListItem>
                  </List>

                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    If you believe in good faith that a notice of copyright
                    infringement has been wrongly filed against you, then the
                    Digital Millennium Copyright Act (“DMCA”) permits you to
                    send us a counter-notice.
                  </Typography>
                  <Typography sx={{ fontFamily: theme.fonts.livvic, my: 2 }}>
                    Notices and counter-notices with respect to the Service
                    should be sent to:
                  </Typography>
                  <Typography sx={{ fontFamily: theme.fonts.livvic, ml: 4 }}>
                    BURRSTSPORTS LLC,
                    <br />
                    123 North Desplaines Street
                    <br />
                    Chicago, IL 60661
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("8.")}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      If you receive a notification from us that User Content
                      you made available on or through the App has been the
                      subject of a Notification of Claimed Infringement, then
                      you will have the right to provide us with what is called
                      a “Counter Notification.” To be effective, a Counter
                      Notification must be in writing, provided to our
                      Designated Agent through one of the methods identified in
                      Section 8.2 above, and include substantially the following
                      information
                    </Typography>
                  </Box>

                  <List
                    sx={{
                      listStyle: "lower-alpha",
                      pl: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.8,
                    }}
                  >
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        Your physical or electronic signature;
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        Identification of the material that has been removed or
                        to which access has been disabled and the location at
                        which the material appeared before it was removed or
                        access to it was disabled;
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        A statement under penalty of perjury that you have a
                        good faith belief that the material was removed or
                        disabled as a result of mistake or misidentification of
                        the material to be removed or disabled; and
                      </Typography>
                    </ListItem>
                    <ListItem sx={listItemStyle("")}>
                      <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                        Your name, address, and telephone number, and a
                        statement that the you consent to the jurisdiction of
                        Federal District Court for the judicial district in
                        which the address is located, or if your address is
                        outside of the United States, for any judicial district
                        in which we may be found, and that you will accept
                        service of process from the person who provided the
                        notification or an agent of such person.
                      </Typography>
                    </ListItem>
                  </List>

                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      A party submitting a Counter Notification should consult a
                      lawyer or see 17 U.S.C. § 512 to confirm the party’s
                      obligations to provide a valid counter notification under
                      the Copyright Act.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      We reserve the right to seek damages from any party that
                      submits a notification of claimed infringement or counter
                      notification in violation of the law.
                    </Typography>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      Consult your legal advisor and see 17 U.S.C. § 512 before
                      filing a notice or counter-notice as there are penalties
                      for false claims under the DMCA. The foregoing is not
                      intended to provide you with legal advice.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="apple-terms" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Apple Device and Application Terms.
              </span>{" "}
              In the event you are accessing the App via an application on a
              device provided by Apple, Inc. ("Apple") or an application
              obtained through the Apple App Store (in either case, an
              “Application”), the following shall apply:
              <List
                sx={{
                  listStyle: "lower-alpha",
                  pl: 3,
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.8,
                }}
              >
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    Both you and Burrst acknowledge that these Terms are
                    concluded between you and Burrst only, and not with Apple,
                    and that Apple is not responsible for the Application or the
                    Content;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    The Application is licensed to you on a limited,
                    non-exclusive, non-transferable, non-sublicensable basis, as
                    provide in Section 4 (Limited License to Use the App),
                    solely to be used in connection with the App for your
                    private, personal, non-commercial use, subject to all the
                    terms and conditions of these Terms as they are applicable
                    to the App;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    You will only use the Application in connection with an
                    Apple device that you own or control;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    You acknowledge and agree that Apple has no obligation
                    whatsoever to furnish any maintenance and support App with
                    respect to the Application;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    In the event of any failure of the Application to conform to
                    any applicable warranty, including those implied by law, you
                    may notify Apple of such failure; upon notification, Apple’s
                    sole warranty obligation to you will be to refund to you the
                    purchase price, if any, of the Application;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    You acknowledge and agree that Burrst, and not Apple, is
                    responsible for addressing any claims you or any third party
                    may have in relation to the Application;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    You acknowledge and agree that, in the event of any third
                    party claim that the Application or your possession and use
                    of the Application infringes that third party’s intellectual
                    property rights, Burrst, and not Apple, will be responsible
                    for the investigation, defense, settlement and discharge of
                    any such infringement claim;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    You represent and warrant that you are not located in a
                    country subject to a U.S. Government embargo, or that has
                    been designated by the U.S. Government as a "terrorist
                    supporting" country, and that you are not listed on any U.S.
                    Government list of prohibited or restricted parties;
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    You acknowledge and agree that, in your use of the
                    Application, you will comply with any applicable third party
                    terms of agreement which may affect or be affected by such
                    use; and
                  </Typography>
                </ListItem>
                <ListItem sx={listItemStyle("")}>
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    Both you and Burrst acknowledge and agree that Apple and
                    Apple’s subsidiaries are third party beneficiaries of these
                    terms, and that upon your acceptance of these terms, Apple
                    will have the right (and will be deemed to have accepted the
                    right) to enforce these terms against you as the third party
                    beneficiary hereof.
                  </Typography>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="third-party" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Third Party Services and Resources.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("10.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Third Party Links.</strong> App may contain links
                      to third party websites or resources. Burrst provides
                      these links only as a convenience and is not responsible
                      for the content, products, or services on or available
                      from those websites or resources, or links displayed on
                      such websites. We do not monitor or control the linked
                      sites and make no representations regarding, and are not
                      liable or responsible for the accuracy, completeness,
                      timeliness, reliability or availability of, any of the
                      content uploaded, displayed, or distributed, or products,
                      or services available at these sites. The presence of a
                      link to a third party site does not constitute or imply
                      our endorsement, sponsorship, or recommendation of the
                      third party or of the content, products, or services
                      contained on, or available through, the third party site.
                      We reserve the right to disable links from or to third
                      party sites. To the extent permitted under applicable law,
                      you acknowledge sole responsibility for and assume all
                      risk arising from, your use of any third party websites or
                      resources.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("10.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Third Party Resources.</strong> Burrst is not
                      responsible for the availability or quality of your
                      devices or any third party services ("Third Party
                      Resources"), including cell phone networks, hotspots,
                      wireless internet and other services. Such Third Party
                      Resources may affect your ability to utilize the App and
                      you hereby waive and release Burrst and any other party
                      involved in creating or delivering the App from all
                      claims, demands, causes of action, damages, losses,
                      expenses or liability which may arise out of, result from,
                      or relate in any way to such third party services.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("10.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Third Party Merchants / Providers.</strong> Third
                      Party Merchants / Providers. The App may enable you to
                      order and receive products, information and services from
                      businesses that are not owned or operated by us. The
                      purchase, payment, warranty, guarantee, delivery,
                      maintenance, and all other matters concerning the
                      merchandise, services or information, opinion or advice
                      ordered or received from such businesses are solely
                      between you and such businesses. We do not endorse,
                      warrant, or guarantee such products, information, or
                      services, and are not liable for the accuracy,
                      completeness, or usefulness of such information or the
                      quality or availability of such products or services. We
                      will not be a party to or in any way be responsible for
                      monitoring any transaction between you and third party
                      providers of such products, services, or information, or
                      for ensuring the confidentiality of your credit card
                      information. Any separate charges or obligations you incur
                      in your dealings with these third parties are your
                      responsibility.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="termination" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Termination.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("11.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      We may suspend or terminate your access to and use of the
                      App, at our sole discretion, at any time and without
                      notice to you, including if (a) you fail to comply with
                      these Terms; (b) we suspect fraud, or misuse by you of
                      Content or App; (c) we suspect any other unlawful activity
                      associated with your Account, or (d) for any reason, in
                      our sole and absolute discretion. We may also change,
                      suspend, or discontinue any aspect of the App at any time.
                      You agree that if we terminate your access to and use of
                      the App, we will not refund you any monetary amounts that
                      you may have paid to us, nor will be liable for any
                      damages you may incur as a result of our termination of
                      your access to and use of the App. If your Account is
                      inactive (i.e., not used or logged-into) for a period of
                      time, we will notify you via the App or in the App prior
                      to termination of your Account.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("11.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      You may terminate your Account at any time directly by
                      emailing us at{" "}
                      <NavLink
                        to="mailto:support@burrst.io"
                        target="_blank"
                        rel="rel=noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        support@burrst.io.
                      </NavLink>{" "}
                      or using any termination functionality that may be offered
                      through the App. Upon termination of these Terms for any
                      reason, then all of your User Content may be made
                      inaccessible through the App, although copies of your User
                      Content may remain stored on back-up storage media
                      maintained by or for us. You grant us a royalty-free
                      license to retain such back-up copies of your User Content
                      on storage media maintained by or for us. If you stop
                      using the App but keep User Content on the App, then these
                      Terms will continue to apply in full force and effect for
                      so long as such User Content is available on or through
                      the App. Upon termination of the App, your Account, or
                      these Terms, for any reason, the following provisions of
                      these Terms will survive: "Content and Submissions"
                      excluding "Rights Granted to You", "Conduct, General
                      Prohibitions and Enforcement Rights", "Apple Device and
                      Application Terms", "Third Party Services and Resources",
                      "Disclaimer of Warranties", "Limitations of Liability",
                      "Indemnification", "Governing Law and Jurisdiction",
                      "Miscellaneous" and any other provision that provides for
                      itself for survival or which by its nature should survive
                      termination of these Terms.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="disclaimer" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Disclaimer of Warranties.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("12.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      THE APP, CONTENT AND USER CONTENT ARE PROVIDED "AS IS",
                      “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY KIND, EXPRESS
                      OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                      WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND
                      FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
                      IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE,
                      ALL OF WHICH ARE EXPRESSLY DISCLAIMED. BURRST, AND OUR
                      AFFILIATES AND EACH OF OUR AND THEIR OFFICERS, DIRECTORS,
                      EMPLOYEES, REPRESENTATIVES, AGENTS, CONTRACTORS,
                      SUPPLIERS, PARTNERS, SUBSIDIARIES AND/OR RELATED COMPANIES
                      DO NOT WARRANT THAT: (I) THE APP WILL BE SECURE OR
                      AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY
                      DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT,
                      USER CONTENT, OR SOFTWARE AVAILABLE AT OR THROUGH THE APP
                      IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV)
                      THE RESULTS OF USING THE APP WILL MEET YOUR REQUIREMENTS.
                      YOUR USE OF THE APP IS SOLELY AT YOUR OWN RISK.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("12.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      YOU ASSUME ALL RISKS RELATING TO YOUR ONLINE OR OFFLINE
                      COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE
                      APP AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR
                      INTERACT AS A RESULT OF YOUR USE OF THE APP. YOU
                      UNDERSTAND THAT BURRST DOES NOT SCREEN OR INQUIRE INTO THE
                      BACKGROUND OF ANY USERS OF THE APP. BURRST MAKES NO
                      REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS
                      OF THE APP. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN
                      ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF
                      THE APP AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE
                      OR INTERACT AS A RESULT OF YOUR USE OF THE APP,
                      PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("12.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      The views and opinions of the users of the App are their
                      own and not necessarily representative of the views and
                      opinions of Burrst or its officers, directors and/or
                      employees, and Burrst assumes no responsibility for the
                      Content, User Content, accuracy, or views of or opinions
                      expressed by such users of the App.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="limitations" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Limitation of Liability.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("13.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, YOU AGREE
                      THAT NEITHER BURRST, NOR OUR AFFILIATES AND EACH OF OUR
                      AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES,
                      AGENTS, CONTRACTORS, SUPPLIERS, PARTNERS, SUBSIDIARIES
                      AND/OR RELATED COMPANIES WILL BE LIABLE TO YOU FOR ANY
                      INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, OR
                      CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF
                      DATA OR GOODWILL, SERVICE INTERRUPTION, DEVICE DAMAGE OR
                      SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES, ARISING
                      OUT OF OR IN CONNECTION WITH THESE TERMS, OR FROM THE USE
                      OF OR INABILITY TO USE THE APP, CONTENT OR USER CONTENT ON
                      THE APP, OR FROM ANY COMMUNICATIONS, INTERACTIONS, OR
                      MEETINGS WITH OTHER USERS OF THE APP OR PERSONS WITH WHOM
                      YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE
                      APP, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
                      NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY,
                      AND WHETHER OR NOT BURRST HAS BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES, EVEN IF A LIMITED REMEDY SET
                      FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
                      PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                      LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                      DAMAGES, SO THE FOREGOING LIMITATION OF LIABILITY SHALL
                      APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
                      APPLICABLE JURISDICTION.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("13.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT
                      WILL THE TOTAL LIABILITY OF BURRST, AND EACH OF OUR
                      OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES, AGENTS,
                      PARTNERS, SUBSIDIARIES AND/OR RELATED COMPANIES, ARISING
                      OUT OF OR IN CONNECTION WITH THESE TERMS, OR FROM THE USE
                      OF OR INABILITY TO USE THE APP, CONTENT OR USER CONTENT
                      EXCEED THE LESSER OF (I) ANY AMOUNTS THAT YOU HAVE PAID TO
                      US; OR ONE HUNDRED DOLLARS ($100.00 USD). THE EXCLUSIONS
                      AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
                      ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN BURRST AND
                      YOU.
                    </Typography>
                  </Box>
                </ListItem>

                <ListItem sx={listItemStyle("13.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS
                      ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                      CERTAIN DAMAGES. IF THESE LAWS APPLY TO A USER, SOME OR
                      ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS
                      MAY NOT APPLY TO SUCH USER, AND SUCH USER MAY HAVE
                      ADDITIONAL RIGHTS.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("13.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      We are not responsible for any disputes or disagreements
                      between you and any third party you interact with using
                      the App, including other users. You assume all risk
                      associated with dealing with third parties. You agree to
                      resolve disputes directly with the other party. You
                      release us of all claims, demands, and damages in disputes
                      among users of the App and will not involve us in such
                      disputes. You should use caution and common sense when
                      using the App and dealing with other users.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="indemnification" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Indemnification.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("14.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      YOU SHALL DEFEND, INDEMNIFY, AND HOLD HARMLESS BURRST, AND
                      OUR AFFILIATES AND EACH OF OUR AND THEIR OFFICERS,
                      DIRECTORS, EMPLOYEES, REPRESENTATIVES, AGENTS,
                      CONTRACTORS, SUPPLIERS, PARTNERS, SUBSIDIARIES AND/OR
                      RELATED COMPANIES FROM ALL LIABILITIES, CLAIMS, AND
                      EXPENSES, INCLUDING REASONABLE ATTORNEYS’ FEES, THAT ARISE
                      FROM OR RELATE TO YOUR USE OR MISUSE OF, OR ACCESS TO, THE
                      APP, CONTENT, OR OTHERWISE FROM YOUR USER CONTENT,
                      VIOLATION OF THESE TERMS, OR INFRINGEMENT BY YOU, OR ANY
                      THIRD PARTY USING YOUR ACCOUNT OR IDENTITY IN THE APP, OF
                      ANY INTELLECTUAL PROPERTY OR OTHER RIGHT OF ANY PERSON OR
                      ENTITY, INCLUDING OTHER USERS AND THIRD PARTIES. WE
                      RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND
                      CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION
                      BY YOU (WIHTOUT LIMITING YOUR INDEMNIFICATION OBLIGATIONS
                      WITH RESPECT TO THAT MATTER), IN WHICH EVENT YOU WILL
                      ASSIST AND COOPERATE WITH US IN ASSERTING ANY AVAILABLE
                      DEFENSES.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="governing-law" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Governing Law and Jurisdiction.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("15.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>GOVERNING LAW.</strong> YOUR USE OF THE APP AND
                      ANY DISPUTE ARISING OUT OF OR IN CONNECTION WITH THE APP
                      INCLUDING, WITHOUT LIMITATION, THE TERMS, AND THE PRIVACY
                      POLICY SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE
                      WITH THE LAWS OF THE STATE OF ILLINOIS WITHOUT GIVING
                      EFFECT TO ANY CONFLICT OF LAWS PROVISIONS.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("15.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>JURISDICTION.</strong> YOU AND WE AGREE THAT ANY
                      SUIT, ACTION OR PROCEEDING SHALL BE BROUGHT IN THE STATE
                      OF ILLINOIS AND HEREBY IRREVOCABLY CONSENT TO BE SUBJECT
                      TO THE PERSONAL JURISDICTION THEREOF AND WAIVE ANY CLAIM
                      OF FORUM NON CONVENIENS OR LACK OF PERSONAL JURISDICTION
                      THAT YOU OR WE MAY OTHERWISE HAVE.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>

            <ListItem id="miscellaneous" sx={{ display: "list-item", p: 0 }}>
              <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                Miscellaneous.
              </span>

              <List sx={listStyling}>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Entire Agreement.</strong> The Terms, together
                      with the Privacy Policy, constitute the entire agreement
                      between you and Burrst governing your use of the App,
                      superseding any prior agreements between you and Burrst
                      with respect to the App.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Waiver and Severability.</strong> The provisions
                      of these Terms are intended to be interpreted in a manner
                      which makes them valid, legal and enforceable. The failure
                      of Burrst to enforce any right of the provisions in these
                      Terms shall not constitute a waiver of such right or
                      provision. In the event that any provision of these Terms
                      is found to be partially or wholly invalid, illegal or
                      unenforceable, such provision shall be modified or
                      restricted to the extent and in the manner necessary to
                      render it valid, legal and enforceable. It is expressly
                      understood and agreed that such modification or
                      restriction may be accomplished unilaterally by Burrst, or
                      alternatively, by disposition of an arbitrator or a court
                      of law. If such provision cannot under any circumstances
                      be so modified or restricted, it shall be excised from
                      these Terms without affecting the validity, legality or
                      enforceability of any of the remaining provisions.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Force Majeure.</strong> Neither Burrst, any user,
                      nor any other party involved in creating, producing, or
                      delivering the App or Content shall be liable with respect
                      to any damages, injuries, nonperformance or delay in
                      performance by reason of any act of God, weather, fire,
                      flood, acts of terror or foreign enemy, satellite or
                      network failure, governmental order or regulation, trade
                      dispute, pandemic, or any other cause beyond its
                      respective control.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Notice.</strong> Any notices or other
                      communications provided by Burrst under these Terms will
                      be given: (a) via email; or (b) by posting to the App. For
                      notices made by email, the date of receipt will be deemed
                      the date on which such notice is transmitted to any email
                      address you provided.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Waiver.</strong> Burrst’s failure to enforce any
                      right or provision of these Terms will not be considered a
                      waiver of such right or provision. The waiver of any such
                      right or provision will be effective only if in writing
                      and signed by a duly authorized representative of Burrst.
                      Except as expressly set forth in these Terms, the exercise
                      by either party of any of its remedies under these Terms
                      will be without prejudice to its other remedies under
                      these Terms or otherwise.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Assignment.</strong> These Terms are not
                      assignable, transferable or sublicensable by you except
                      with our prior written consent. You may not assign or
                      transfer these Terms, by operation of law or otherwise,
                      without Burrst’s prior written consent. Any attempt by you
                      to assign or transfer these Terms, without such consent,
                      is void. Burrst may freely assign or transfer these Terms
                      without restriction, and the transferor or assignor shall
                      not remain jointly and severally liable. Subject to the
                      foregoing, these Terms will bind and inure to the benefit
                      of the parties, their successors and permitted assigns.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Headings.</strong> The section and paragraph
                      headings in these Terms are for convenience only and shall
                      not affect their interpretation.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Relationships.</strong> The App is not in any way
                      endorsed, sponsored, administered by, or associated with
                      Apple Inc., Google LLC, Alphabet Inc., or their respective
                      parents, subsidiaries or affiliates.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 40)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong>Agency.</strong> No agency, partnership, joint
                      venture, or employment relationship is created as a result
                      of these Terms and neither party has any authority of any
                      kind to bind the other in any respect.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 44)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong> Electronic Communications. </strong>
                      When you use the App or send emails to us, you are
                      communicating with us electronically. You consent to
                      receive communications from us electronically. We will
                      communicate with you by email or by posting notices on or
                      through this App. You agree that all agreements, notices,
                      disclosures and other communications that we provide you
                      electronically satisfy any legal requirement that such
                      communications be in writing.
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem sx={listItemStyle("16.", 44)}>
                  <Box sx={listStylingBox}>
                    <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                      <strong> Contact Information. </strong>
                      If you have any questions about these Terms or the App,
                      please contact Burrst at{" "}
                      <NavLink
                        to="mailto:support@burrst.io"
                        target="_blank"
                        rel="rel=noopener noreferrer"
                        style={hyperLinkStyle}
                      >
                        support@burrst.io.
                      </NavLink>{" "}
                      or 123 North Desplaines Street, Chicago, IL 60661.
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </ListItem>
          </List>
        </Box>

        {isVisible && (
          <Box sx={{ width: "100%", maxWidth: "1536px" }}>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                background: theme.colors.gradientBlackRedDot,
                position: "fixed",
                bottom: { xs: "10px", sm: "20px" },
                right: { xs: "10px", sm: `${rightPosition}px` },
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <KeyboardArrowUp />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TermsOfUse;
