import React from "react";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../../assets/icons/CrossIcon.svg";
import AccountConnect from "../../../../assets/icons/ConnectPlaidAccount.svg";
import { useNavigate } from "react-router-dom";

const ConnectPlaidAccModal = ({ handleClose, profileState, campaignId }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleConnect = () => {
    localStorage.setItem("route", profileState);
    if (campaignId) {
      localStorage.setItem("campaignId", campaignId);
    }
    navigate("/bank-details", {
      state: {
        campaignId: campaignId,
      },
    });
  };

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: "40px",
          gap: 3,
        }}
      >
        <Box component="img" src={AccountConnect} alt="Otp Verified"></Box>
        <Typography fontFamily={theme.fonts.livvic} color="#D9D9D9">
          You have not connected your bank account yet. Please connect it before
          applying for any campaign.{" "}
        </Typography>

        <Button
          // fullWidth
          sx={{
            background: theme.colors.background.registerButton,
            color: "white",
            height: "50px",
            width: { xs: "auto", md: "50%" },
            mt: "20px",
            fontSize: { xs: "18px", md: "22px" },
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            borderRadius: "5px",
            "&:hover": {
              background: theme.colors.divider.red,
            },
          }}
          onClick={handleConnect}
        >
          Connect Account
        </Button>
      </Box>
    </DialogContent>
  );
};

export default ConnectPlaidAccModal;
