import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import BurrstLogo from "../../assets/logo/BurrstLogo.svg";
import AthleteImage from "../../assets/icons/AthleteIcon.svg";
// import BrandImage from "../../assets/images/BrandImage.png";

import { PADDING_X, BASE_IMAGE_URL } from "../../utils/constants";

const UserTypeSelection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  // const AthleteImage = `${BASE_IMAGE_URL}/AthleteImage.png`;
  const BrandImage = `${BASE_IMAGE_URL}/BrandImage.png`;

  const handleBurrstLogo = () => {
    navigate("/");
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleAthlete = () => {
    navigate("/athletes/register");
  };

  const handleBrand = () => {
    navigate("/brands/register");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <Helmet>
        <title>User Type Selection | Burrst</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: { xs: "10px", sm: PADDING_X },
          minHeight: { xs: "70vh", xl: "95vh" },
          //   background: `url(${LoginBackground}) no-repeat`,
          //   backgroundSize: "contain",
          // position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "20px",
            top: "20px",
            width: "35px",
            height: "35px",
            cursor: "pointer",
            backgroundColor: theme.colors.common.black,
            color: theme.colors.text.greyLight,
            borderRadius: "5px",
            boxShadow: "0px 0px 7px 0px rgba(197, 197, 197, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleBack}
        >
          <ArrowBackIcon sx={{ fontSize: "25px" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            placeItems: "center",
            gap: 4,
          }}
        >
          <Box
            component="img"
            src={BurrstLogo}
            onClick={handleBurrstLogo}
            alt="Burrst Logo"
            sx={{
              width: { xs: "250px", md: "300px" },
              cursor: "pointer",
            }}
          ></Box>
          <Typography
            textTransform="uppercase"
            fontSize="30px"
            fontFamily={theme.fonts.russoOne}
            color={theme.colors.text.greyLight}
          >
            The Next Generation
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 4,
            }}
          >
            <Box
              sx={{
                border: "2px solid grey",
                borderRadius: theme.fonts.smallBorderRadius,
                height: "300px",
                width: "300px",
                padding: "5px",
                cursor: "pointer",
                "&:hover": {
                  background: theme.colors.gradientRedBlackTopToBottom,
                  boxShadow: `0 0 10px 5px ${theme.colors.background.red100}`,
                  "#athlete": {
                    transform: "scale(1.05)",
                  },
                },
              }}
              onClick={handleAthlete}
            >
              <Box
                sx={{
                  height: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <img
                  id="athlete"
                  src={AthleteImage}
                  alt="Athlete"
                  height="80%"
                />
              </Box>
              <Box
                sx={{
                  height: "40%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Typography fontFamily={theme.fonts.livvic}>I am an</Typography>
                <Typography
                  variant="h5"
                  fontFamily={theme.fonts.livvic}
                  fontWeight={theme.fonts.fontWeightMedium}
                >
                  ATHLETE
                </Typography>
                <Box
                  sx={{ position: "absolute", bottom: "10px", right: "10px" }}
                >
                  <Typography
                    fontFamily={theme.fonts.livvic}
                  >{`choose >`}</Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                border: "2px solid grey",
                borderRadius: theme.fonts.smallBorderRadius,
                height: "300px",
                width: "300px",
                padding: "5px",
                cursor: "pointer",
                "&:hover": {
                  background: theme.colors.gradientRedBlackTopToBottom,
                  boxShadow: `0 0 10px 5px ${theme.colors.background.red100}`,
                  "#brand": {
                    transform: "scale(1.05)",
                  },
                },
              }}
              onClick={handleBrand}
            >
              <Box
                sx={{
                  height: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <img id="brand" src={BrandImage} alt="Brand" height="80%" />
              </Box>
              <Box
                sx={{
                  height: "40%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Typography fontFamily={theme.fonts.livvic}>
                  We are a
                </Typography>
                <Typography
                  variant="h5"
                  fontFamily={theme.fonts.livvic}
                  fontWeight={theme.fonts.fontWeightMedium}
                >
                  BRAND
                </Typography>
                <Box
                  sx={{ position: "absolute", bottom: "10px", right: "10px" }}
                >
                  <Typography
                    fontFamily={theme.fonts.livvic}
                  >{`choose >`}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.greyLight}
            sx={{ fontSize: "22px" }}
          >
            Already have an account?{" "}
            <Typography
              variant="h5"
              fontWeight={theme.fonts.fontWeightMedium}
              fontFamily={theme.fonts.livvic}
              color={theme.colors.text.red}
              display="inline"
              sx={{ cursor: "pointer" }}
              onClick={handleLogin}
            >
              Login
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserTypeSelection;
