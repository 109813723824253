import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  AttachFile,
  MoreVert,
  Search,
  Send,
  SentimentSatisfied,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { chatSocket } from "../../../../../utils/socket";
import { v4 as uuidv4 } from "uuid";
import {
  setChatRoomId,
  setIsUserSelected,
  setSelectedUser,
} from "../../../../../store/actions/chatActions";
import EmojiPicker from "emoji-picker-react";
import dayjs from "dayjs";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const ChatRightSidebar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUserId = localStorage.getItem("userId");
  const scrollRef = useRef();

  const { selectedUser, chatRoomId, isAllDataLoading } = useSelector(
    (state) => state?.chat
  );

  const [typedMessage, setTypedMessage] = useState("");

  const [messageData, setMessageData] = useState([]);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleBack = () => {
    dispatch(setIsUserSelected(false));
    dispatch(setChatRoomId(null));
    dispatch(setSelectedUser(null));
  };

  const checkUser = (userId) => {
    if (userId !== currentUserId) {
      return "left";
    } else return "right";
  };

  const handleTypedMessage = (e) => {
    setTypedMessage(e.target.value);
  };

  const pickEmoji = (e) => {
    setTypedMessage((prevData) => prevData + e?.emoji);
  };

  const handleMessageSend = async () => {
    if (typedMessage.trim().length !== 0) {
      const id = uuidv4();
      await chatSocket?.emit("message", id, chatRoomId, typedMessage);
      setTypedMessage("");
    }
  };

  const handleKeyDownSendMessage = (event) => {
    if ((event.keyCode === 13 || event.key === "Enter") && event.shiftKey) {
      handleMessageSend();
      event.preventDefault();
    }
  };

  const lastMessages = async (chatRoomId) => {
    const randomId = uuidv4();
    await chatSocket?.emit("last_messages", randomId, chatRoomId);
  };

  const connectChatRoom = async (chatRoomId) => {
    const randomId = uuidv4();
    chatSocket?.off("chatroom_connected");

    chatSocket?.emit("chatroom_connect", randomId, chatRoomId);

    chatSocket?.on("chatroom_connected", async (id, res) => {
      if (res === "connection successful") {
        lastMessages(chatRoomId);

        chatSocket?.on("get_last_messages", (res, data) => {
          setMessageData(data);
        });

        chatSocket?.on("new_message", (res) => {
          setMessageData((prevData) => [...prevData, res]);
        });

        chatSocket?.on("your_message", (res) => {
          setMessageData((prevData) => [...prevData, res]);
        });
      } else {
        console.log("chatRoom not Connected");
      }
    });
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageData]);

  useEffect(() => {
    if (chatRoomId) {
      connectChatRoom(chatRoomId);
    }

    return () => {
      chatSocket?.off("chatroom_connected");
      chatSocket?.off("get_last_messages");
      chatSocket?.off("new_message");
      chatSocket?.off("your_message");
    };
  }, [chatRoomId]);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          height: "70px",
          background: "rgba(14,14,14,1)",
          px: "10px",
          py: "5px",
          borderBottom: `1px solid ${theme.colors.text.greyDark}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ArrowBack
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "24px", sm: "30px" },
              color: "white",
            }}
          />

          {isAllDataLoading ? (
            <CustomSkeleton type="circle" />
          ) : (
            <Avatar
              src={
                selectedUser?.image ||
                selectedUser?.profileImage ||
                selectedUser?.brandLogo
              }
            />
          )}
          <Box>
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              <Typography
                fontFamily={theme.fonts.livvic}
                fontSize="16px"
                fontWeight={theme.fonts.fontWeightMedium}
              >
                {selectedUser?.name || selectedUser?.brandName}
              </Typography>
            )}
            {/* <Typography
              fontFamily={theme.fonts.livvic}
              fontSize="10px"
              color={theme.colors.text.greyDark}
            >
              last seen {FormatTime(selectedUser?.lastMessage?.date)}
            </Typography> */}
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Search />
          <MoreVert />
        </Box> */}
      </Box>

      <Box
        sx={{
          height: "calc(500px - 130px)",
          px: "20px",
          py: "10px",
          mb: "70px",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "grey",
            borderRadius: "4px",
          },
        }}
        ref={scrollRef}
      >
        {isAllDataLoading
          ? Array.from({ length: 6 }, (_, index) => (
              <Box key={index}>
                <Box sx={{ mb: 2 }}>
                  <CustomSkeleton
                    sx={{ width: "120px", height: "30px", borderRadius: "5px" }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <CustomSkeleton
                    sx={{ width: "120px", height: "30px", borderRadius: "5px" }}
                  />
                </Box>
              </Box>
            ))
          : messageData?.map((message) => (
              <Stack
                key={message?._id}
                direction="row"
                justifyContent={() => checkUser(message?.userId)}
              >
                <Box
                  sx={{
                    minWidth: "60px",
                    maxWidth: "60%",
                    my: "3px",
                  }}
                >
                  <Box
                    sx={{
                      px: "10px",
                      py: "5px",
                      ...(message?.userId === currentUserId
                        ? {
                            borderTopLeftRadius: "3px",
                            background: theme.colors.background.myMessage,
                          }
                        : {
                            borderTopRightRadius: "3px",
                            background: theme.colors.background.othersMessage,
                          }),
                      borderBottomLeftRadius: "3px",
                      borderBottomRightRadius: "3px",
                      position: "relative",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        width: "10px",
                        height: "10px",
                        ...(message?.userId === currentUserId
                          ? {
                              right: 0,
                              transform: "translateX(10px) rotate(-90deg)",
                              clipPath: "polygon(100% 0%, 50% 0%, 100% 100%)",
                              background: theme.colors.background.myMessage,
                            }
                          : {
                              left: 0,
                              transform: "translateX(-10px) rotate(90deg)",
                              clipPath: "polygon(0% 0%, 0% 100%, 50% 0%)",
                              background: theme.colors.background.othersMessage,
                            }),

                        // backgroundImage:
                        //   "linear-gradient(45deg, #013A6B 50%, #004E95 50%)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: { xs: "12px", sm: "14px" },
                      }}
                    >
                      {message?.message}

                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: { xs: "7px", sm: "8px" },
                          display: "flex",
                          justifyContent: "right",
                          color: theme.colors.text.greyDark,
                        }}
                      >
                        {dayjs(message?.timestamp).format("HH:mm")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            ))}
      </Box>

      <Box
        sx={{
          px: "20px",
          position: "absolute",
          bottom: "10px",
          width: "100%",
        }}
      >
        {isAllDataLoading ? (
          <CustomSkeleton
            sx={{ width: "100%", height: "40px", borderRadius: "10px" }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              border: "none",
              background: theme.colors.text.greyLight,
              color: "black",
              borderRadius: "10px",
              height: "40px",
              overflow: "hidden",
            }}
          >
            <SentimentSatisfied
              sx={{
                cursor: "pointer",
                color: showEmojiPicker ? "black" : theme.colors.text.greyDarker,
                zIndex: 1,
                width: "40px",
              }}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            />
            <TextField
              name="message"
              placeholder="Message"
              multiline
              value={typedMessage || ""}
              onChange={handleTypedMessage}
              onKeyDown={handleKeyDownSendMessage}
              fullWidth
              autoComplete="off"
              InputProps={{
                sx: {
                  border: "0",
                  padding: 0,
                  background: theme.colors.text.greyLight,
                  color: "black",
                  borderRadius: "10px",
                  width: "100%",
                  "&::-webkit-scrollbar": {
                    width: "0px",
                  },
                  "& textArea": {
                    overflow: "hidden",
                    maxHeight: "40px",
                  },
                },
              }}
              sx={{
                "& fieldset": { border: "none" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    fieldset: {
                      border: "0",
                      transition: "border 0.2s ease-out",
                    },
                  },
                  "&:hover": {
                    border: "0",
                  },
                },
              }}
            />
            <Send
              onClick={handleMessageSend}
              sx={{
                cursor: "pointer",
                zIndex: 1,
                width: "40px",
                color: theme.colors.text.greyDarker,
              }}
            />
          </Box>
        )}

        {showEmojiPicker && (
          <EmojiPicker
            onEmojiClick={pickEmoji}
            theme="dark"
            lazyLoadEmojis={true}
            previewConfig={{ showPreview: false }}
            style={{
              minWidth: "280px",
              maxWidth: "300px",
              height: "320px",
              position: "absolute",
              bottom: "50px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ChatRightSidebar;
