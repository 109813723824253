import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import TheEcosystemBackground from "../../assets/images/TheEcosystemBackground.png";

import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Burrst</title>
        <meta
          name="description"
          content="BurrstSports LLC (“we” or “us”) values our customers and users and respects their privacy."
        />
        <link rel="canonical" href={`${HOST_URL}/privacy-policy`} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            >
              PRIVACY POLICY
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            // py: "60px",
            px: { xs: "10px", sm: PADDING_X },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
            minHeight: "440px",
            width: "100%",
            maxWidth: "1536px",
            my: "30px",
          }}
        >
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "16px",
            }}
          >
            <span style={{ fontWeight: "bold" }}>BurrstSports LLC</span> (“we”
            or “us”) values our customers and users and respects their privacy.
            Our Policy oversees the gathering and utilization of data related to
            our website (referred to as the "Site"), applications, and provided
            services (collectively known as the "Services"). This Privacy Policy
            encompasses the nature, purpose, utilization, and sharing of
            information that identifies or can be utilized to identify our
            valued users of the Services. By utilizing our Services, you confirm
            your consent to this Privacy Policy and the collection and
            processing of Personal Information in accordance with the terms
            stated herein.
          </Typography>

          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              What Information is Collected?
            </span>
            <br />
            Burrst revolutionizes the athlete endorsement industry by providing
            comprehensive technology solutions. Our platform supports the entire
            life cycle, offering services that educate, assess, plan, and
            facilitate sharing, creation, measurement, tracking, disclosure,
            regulation, listing, browsing, and booking.
          </Typography>

          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "16px",
            }}
          >
            In addition to connecting athletes and influencers (referred to as
            "End Users") with individuals and companies seeking social media
            posts, shoutouts, commercials, live appearances, and endorsements
            (referred to as "Customers"), we also collaborate with agents and
            representatives (such as parents or legal guardians) of End Users.
            Collectively, our Users form a vital community.
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "16px",
            }}
          >
            To fulfill our obligations to Users, we collect certain Personal
            Information such as name, address, phone number, date of birth,
            brand information, email address, and payment details. Please note
            that we do not collect educational or student records as defined
            within FERPA, such as grades or transcripts. Our use of Personal
            Information complies with our Terms of Service and Privacy Policy.
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "16px",
            }}
          >
            Additionally, we may gather publicly available Directory
            Information, including name, school, sport, position, jersey number,
            and class year. This, together with certain Non-Personal Information
            (like IP addresses and Session IDs), helps us prevent fraud, abuse,
            and enhance User experience.
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "16px",
            }}
          >
            Rest assured, we maintain data security and adhere to strict
            acceptable use policies, such as the FullStory Acceptable Use
            Policy. For further details, please refer to our Automated Data
            Collection Policy.
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "16px",
            }}
          >
            At Burrst, we are dedicated to empowering athletes and influencers
            while prioritizing privacy and exceptional user experience.
          </Typography>

          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              How Information is Collected?
            </span>
            <br />
            During the account creation process or upon invitation to the
            Platform, Users may provide Personal Information, or it may be
            shared by a User's associate. If received from an associate, we
            retain this information until the User's account is created or the
            invitation is declined. We may also gather Non-Personal information
            from various sources, including publicly available directories (such
            as athletics websites or institution directories) and social media
            platforms, or when a third party is involved, as further explained
            herein.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
