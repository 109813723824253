import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Input,
  ListItem,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import MessageIcon from "../../../../../assets/icons/MessageIcon.svg";

import ContactPersonNameIcon from "../../../../../assets/icons/ContactPersonNameIcon.svg";
import ProfileWebsiteUrlIcon from "../../../../../assets/icons/ProfileWebsiteUrlIcon.svg";
import LocationIcon from "../../../../../assets/icons/LocationIcon.svg";
import EinNumberIcon from "../../../../../assets/icons/EinNumberIcon.svg";
import BusinessCategoryIcon from "../../../../../assets/icons/BusinessCategoryIcon.svg";
import EditIcon from "../../../../../assets/icons/EditIcon.svg";
import BankDetailsIcon from "../../../../../assets/icons/BankDetailsIcon.svg";
import ChangePasswordIcon from "../../../../../assets/icons/ChangePasswordIcon.svg";
import MySubscriptionIcon from "../../../../../assets/icons/MySubscriptionIcon.svg";
import DeleteAccountIcon from "../../../../../assets/icons/DeleteAccountIcon.svg";

import DefaultImg from "../../../../../assets/images/DefaultImage.png";
import DefaultVideo from "../../../../../assets/TestVideos/video2.mp4";

import AthleteImageUploadIcon from "../../../../../assets/icons/AthleteImageUploadIcon.svg";
import MyProfileAthleteIcon from "../../../../../assets/icons/MyProfileAthleteIcon.svg";

import {
  AddCircle,
  ArrowForwardIos,
  ChevronRight,
  Close,
  MoreVert,
} from "@mui/icons-material";
import {
  brandProfileUpdate,
  getBrandsMyProfile,
  updatePaymentDetails,
} from "../../../../../services/brand/brand.service";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyProfile,
  fetchOtherProfile,
} from "../../../../../store/myProfileSlice";
import { Helmet } from "react-helmet-async";
import { getToken } from "../../../../../routes";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast, ToastContainer } from "react-toastify";
import {
  getChatUsers,
  setIsUserSelected,
  setSelectedUser,
} from "../../../../../store/actions/chatActions";
import CrossIcon from "../../../../../assets/icons/CrossIcon.svg";
import CancleSubscriptionIcon from "../../../../../assets/icons/CancleSubsIcon.svg";
import { authDeleteAccount } from "../../../../../services/auth/auth.service";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const BrandsMyProfile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = getToken();

  const brandId = location?.state?.detail?.brandId || null;

  const isMyProfile = location?.state?.isMyProfile;

  const { myProfile, isMyProfileDataLoading } = useSelector(
    (state) => state.myProfile
  );
  const { otherProfile, isOtherProfileDataLoading } = useSelector(
    (state) => state.otherProfile
  );

  const profile = isMyProfile ? myProfile : otherProfile;

  const isAllDataLoading = isMyProfile
    ? isMyProfileDataLoading
    : isOtherProfileDataLoading;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);
  const [isImage, setIsImage] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isProfileImageLoading, setIsProfileImageLoading] = useState(false);
  // const [brandsMyProfile, setBrandsMyProfile] = useState({});
  const [backState, setBackState] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const isAdmin = localStorage.getItem("isBrandAdmin");

  const itemCount = 6;

  const images = profile?.images || [];
  const videos = profile?.videoHighlights || [];

  const handleUpload = async (e, uploadFileType) => {
    const file = e.target.files;
    let response;
    if (file) {
      if (uploadFileType === "IMAGE") {
        setIsImageLoading(true);
        response = await brandProfileUpdate({
          images: file,
          appendImages: true,
          loginToken: token,
        });
        setIsImageLoading(false);
      } else if (uploadFileType === "VIDEO") {
        setIsVideoLoading(true);
        response = await brandProfileUpdate({
          videoHighlights: file,
          appendVideos: true,
          loginToken: token,
        });
        setIsVideoLoading(false);
      }
      if (response?.status === 200) {
        setIsUpdated(!isUpdated);
      } else {
        console.error("Failed Response VideosAndImages Page", response);
      }
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let response;

      setIsProfileImageLoading(true);
      response = await brandProfileUpdate({
        brandLogo: file,
        loginToken: token,
      });
      setIsProfileImageLoading(false);

      if (response?.status === 200) {
        setIsUpdated(!isUpdated);
      } else {
        console.error("Failed Response VideosAndImages Page", response);
      }
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (index, deleteType) => {
    let response;
    if (deleteType === "IMAGE") {
      response = await brandProfileUpdate({
        removeImages: images[index],
        loginToken: token,
      });
    } else if (deleteType === "VIDEO") {
      response = await brandProfileUpdate({
        removeVideos: videos[index],
        loginToken: token,
      });
    }
    if (response?.status === 200) {
      setIsUpdated(!isUpdated);
    } else {
      console.error("Failed Response VideosAndImages Page", response);
    }
  };

  const featuredImages = images.slice(
    0,
    images.length > itemCount ? itemCount : images.length
  );
  const featuredVideos = videos.slice(
    0,
    videos.length > itemCount ? itemCount : videos.length
  );

  const hasMoreItems = (isImage ? images.length : videos.length) > itemCount;

  const handleBack = () => {
    if (isMyProfile === true) {
      navigate("/dashboard/home");
    } else {
      navigate(-1);
    }
  };

  const handleMessage = () => {
    dispatch(setSelectedUser({ ...profile, brandId: profile?._id }));
    dispatch(setIsUserSelected(true));

    setTimeout(() => {
      dispatch(getChatUsers());
      navigate("/dashboard/chat");
    }, 1000);
  };

  const handleEdit = () => {
    navigate("/brands/public-profile", {
      state: { detail: profile, isMyProfile },
    });
    handleClose();
  };

  const profileState = "profile";

  const handleBankDetails = async () => {
    // localStorage.setItem("route", profileState);
    // navigate("/my-accounts", {
    //   state: {
    //     backState: backState,
    //   },
    // });
    const { data } = await updatePaymentDetails();
    if (data?.status === 200)
      window.open(data?.data?.link, "_self", "noopener noreferrer");
    else toast.error(data);
    handleClose();
  };
  const handleChangePassword = () => {
    navigate("/change-password");
    handleClose();
  };

  const handleMySubscription = () => {
    navigate("/dashboard/home/my-subscription");
    handleClose();
  };

  const handleDeleteAccount = () => {
    setIsDeleteAccount(true);
  };

  const handleKeep = () => {
    handleDeleteAccountClose();
  };

  const handleDeleteAccountApi = async () => {
    setLoading(true);
    const response = await authDeleteAccount();
    setLoading(false);
    if (response.status === 200) {
      toast.success("Account deleted successfully");
      localStorage.clear();
      navigate("/");
    } else {
      toast.error("Failed to delete account");
      console.error("Failed brandCancleSubscriptionPlans:", response.message);
    }
  };

  const handleDeleteAccountClose = () => {
    setIsDeleteAccount(false);
  };

  const handleImage = () => {
    setIsImage(true);
  };

  const handleVideo = () => {
    setIsImage(false);
  };

  useEffect(() => {
    isMyProfile
      ? dispatch(fetchMyProfile({ userType: "BRAND", userId: brandId }))
      : dispatch(fetchOtherProfile({ userType: "BRAND", userId: brandId }));
  }, [isUpdated]);

  const handleUrl = () => {
    if (profile?.websiteUrl) {
      window.open(profile?.websiteUrl, "_blank");
    } else {
      console.error("Contract link is not available");
    }
  };

  return (
    <>
      <Helmet>
        {isMyProfile ? (
          <title>Profile | Burrst</title>
        ) : (
          <title>Brand Profile | Burrst</title>
        )}
      </Helmet>
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      {isAllDataLoading ? (
        <Box
          mt={{ xs: "95px", sm: "115px" }}
          sx={{
            px: { xs: "10px", sm: "30px" },
            mb: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              mb: "20px",
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <ArrowBackIcon
                onClick={handleBack}
                sx={{
                  cursor: "pointer",
                  fontSize: { xs: "30px", sm: "40px" },
                  color: "white",
                }}
              />

              <Typography
                color="white"
                fontFamily={theme.fonts.russoOne}
                fontSize={{
                  xs: theme.fonts.heading_xs,
                  sm: theme.fonts.heading,
                }}
              >
                Profile
              </Typography>
            </Box>
            <CustomSkeleton type="circle" />
          </Box>

          <Box
            sx={{
              background: "#121212",
              px: { xs: "10px", sm: "20px" },
              py: "10px",
            }}
          >
            <CustomSkeleton
              type="img"
              sx={{ minWidth: "100%", height: "200px", mb: 2 }}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <CustomSkeleton
                type="img"
                sx={{ minWidth: "75px", height: "75px" }}
              />

              <Box sx={{ width: "100%", mb: 2 }}>
                <CustomSkeleton type="p" count={2} sx={{ mb: 2 }} />
              </Box>
            </Box>

            <CustomSkeleton
              type="p"
              count={3}
              sx={{ minWidth: "100%", mb: 2 }}
            />
          </Box>
        </Box>
      ) : (
        profile && (
          <Box
            mt={{ xs: "95px", sm: "115px" }}
            sx={{
              paddingX: { xs: "10px", md: "30px" },
              mb: "30px",
            }}
          >
            {/* ----------------Header-------------------------- */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
                mb: "20px",
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <ArrowBackIcon
                  onClick={handleBack}
                  sx={{
                    cursor: "pointer",
                    fontSize: { xs: "30px", sm: "40px" },
                    color: "white",
                  }}
                />

                <Typography
                  color="white"
                  fontFamily={theme.fonts.russoOne}
                  fontSize={{
                    xs: theme.fonts.heading_xs,
                    sm: theme.fonts.heading,
                  }}
                >
                  Profile
                </Typography>
              </Box>
              {isMyProfile && (
                <MoreVert sx={{ cursor: "pointer" }} onClick={handleClick} />
              )}
              <Menu
                anchorEl={anchorEl}
                id="profile-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                sx={{
                  filter: "drop-shadow(0px 2px 4px rgba(255,255,255,0.4))",
                  mt: 1,
                  "& .MuiPaper-root": {
                    background: "black",
                    color: theme.colors.text.greyDark,
                  },
                }}
              >
                {isAdmin === "true" && (
                  <MenuItem
                    onClick={handleEdit}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: 1,
                      borderBottom: `1px solid ${theme.colors.text.greyDarker}`,
                      py: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={EditIcon}
                      alt="Edit Icon"
                      sx={{
                        height: "20px",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Edit Profile
                    </Typography>
                  </MenuItem>
                )}
                {isAdmin === "true" && (
                  <MenuItem
                    onClick={handleBankDetails}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: 1,
                      borderBottom: `1px solid ${theme.colors.text.greyDarker}`,
                      py: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={BankDetailsIcon}
                      alt="Bank Details Icon"
                      sx={{
                        height: "20px",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Change Payment Details
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleChangePassword}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: 1,
                    py: 1,
                    cursor: "pointer",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={ChangePasswordIcon}
                    alt="Change Password Icon"
                    sx={{
                      height: "20px",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                    }}
                  >
                    Change Password
                  </Typography>
                </MenuItem>
                {isAdmin === "true" && (
                  <MenuItem
                    onClick={handleMySubscription}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: 1,
                      borderTop: `1px solid ${theme.colors.text.greyDarker}`,
                      borderBottom: `1px solid ${theme.colors.text.greyDarker}`,
                      py: 1,
                      cursor: "pointer",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={MySubscriptionIcon}
                      alt="MySubscription Icon"
                      sx={{
                        height: "20px",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      My subscription
                    </Typography>
                  </MenuItem>
                )}
                {isAdmin === "true" && (
                  <MenuItem
                    onClick={handleDeleteAccount}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      gap: 1,
                      py: 1,
                      cursor: "pointer",
                      color: "#FF0000",
                      "&:hover": {
                        color: "#FF0000",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={DeleteAccountIcon}
                      alt="Delete Icon"
                      sx={{
                        height: "20px",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Delete Account
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>

            {/* ---------------------Delete Account Modal---------------------- */}
            <Dialog
              open={isDeleteAccount}
              onClose={handleDeleteAccountClose}
              PaperProps={{
                sx: {
                  minWidth: "50vw",
                  height: { xs: "600px", sx: "400px", md: "500px" },
                  background: theme.colors.common.black,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  pb: "10px",
                },
              }}
              sx={{
                backdropFilter: "blur(3px)",
                backgroundColor: "rgb(255,255,255,0.3)",
              }}
            >
              <DialogContent
                sx={{
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: theme.colors.background.formInput,
                    borderRadius: "4px",
                  },
                }}
              >
                <Box
                  component="img"
                  src={CrossIcon}
                  alt="Cross Icon"
                  position="absolute"
                  right="20px"
                  top="20px"
                  sx={{ cursor: "pointer", width: "20px" }}
                  onClick={handleDeleteAccountClose}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: "40px",
                  }}
                >
                  <Box
                    component="img"
                    src={CancleSubscriptionIcon}
                    alt="Cancle Subscription Icon"
                    sx={{
                      border: "5px solid #0C0C0C",
                      borderRadius: "50%",
                      width: "90px",
                      mb: "30px",
                    }}
                  ></Box>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    fontWeight={theme.fonts.fontWeightMedium}
                    sx={{ fontSize: { xs: "22px", md: "24px" } }}
                  >
                    Delete Account
                  </Typography>
                  <Typography
                    mt="10px"
                    textAlign="center"
                    fontFamily={theme.fonts.livvic}
                    color="#A0A0A0"
                  >
                    Are you sure you want to delete your account? If you delete
                    your account, you will permanently lose your profile,
                    message, and photos.
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexWrap="wrap"
                    mt="35px"
                    gap={2}
                  >
                    <Button
                      onClick={handleKeep}
                      variant="contained"
                      sx={{
                        px: "25PX",
                        border: "3px solid #797979",
                        background: "#000000",
                        width: { xs: "160px", sm: "260px" },
                        mt: 1,
                        // textTransform: "none",
                        fontWeight: theme.fonts.fontWeightBold,
                        fontSize: { xs: "14px", sm: "18px" },
                        fontFamily: theme.fonts.livvic,
                        "&:hover": {
                          background: "#000000",
                        },
                      }}
                    >
                      cancel
                    </Button>
                    <Button
                      onClick={handleDeleteAccountApi}
                      variant="contained"
                      sx={{
                        px: "25PX",
                        border:
                          "3px solid linear-gradient(90.36deg, rgba(0, 0, 0, 0.96) -9.59%, rgba(70, 0, 0, 0.970952) 21.87%, #FF0000 105.31%)",
                        background: "red",
                        width: { xs: "160px", sm: "260px" },
                        mt: 1,
                        // textTransform: "none",
                        fontWeight: theme.fonts.fontWeightBold,
                        fontSize: { xs: "14px", sm: "18px" },
                        fontFamily: theme.fonts.livvic,
                        "&:hover": {
                          background: theme.colors.divider.red,
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress sx={{ color: "gray" }} />
                      ) : (
                        "DELETE ACCOUNT"
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>

            {/* ----------------Content Box----------------------- */}

            <Box
              sx={{
                mb: "30px",
                p: "20px",
                overflow: "hidden",
                background: theme.colors.dashboard.grey200,
              }}
            >
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  position: "relative",
                  gap: 2,
                }}
              >
                <Box sx={{ mb: { xs: 2, sm: 0 } }}>
                  <Box
                    sx={{
                      backgroundColor: theme.colors.background.formInput,
                      border: `3px solid ${theme.colors.text.greyDarker}`,
                      borderRadius: theme.fonts.smallBorderRadius,
                      overflow: "none",
                      width: "150px",
                      height: "150px",
                      position: "relative",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isProfileImageLoading ? (
                      <CircularProgress sx={{ color: "white" }} />
                    ) : (
                      <Box
                        component="img"
                        src={profile?.brandLogo || DefaultImg}
                        alt="Avatar"
                        id="avatar"
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                        onError={(e) => {
                          e.target.src = DefaultImg;
                        }}
                      ></Box>
                    )}
                    {isMyProfile && isAdmin === "true" && (
                      <Box
                        component="label"
                        sx={{
                          position: "absolute",
                          bottom: "-2.2px",
                          right: "-2.2px",
                          width: "26px",
                          height: "26px",
                          background: theme.colors.text.greyDarker,
                          borderTopLeftRadius: "50%",
                          borderTopRightRadius: "50%",
                          borderBottomLeftRadius: "50%",
                          borderBottomRightRadius: "10px",
                          padding: "6px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            background: "black",
                          },
                        }}
                      >
                        <Box
                          component="img"
                          src={AthleteImageUploadIcon}
                          alt="Athlete Image Upload Icon"
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                        ></Box>
                        <Input
                          type="file"
                          inputProps={{ accept: "image/*" }}
                          onChange={handleProfileImageChange}
                          sx={{ display: "none" }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box>
                  <Typography
                    fontSize={theme.fonts.subHeading}
                    fontFamily={theme.fonts.livvic}
                    fontWeight={theme.fonts.fontWeightBold}
                  >
                    {profile?.name}
                  </Typography>

                  <Typography fontFamily={theme.fonts.livvic}>
                    {profile?.about}
                  </Typography>

                  {!isMyProfile && (
                    <Button
                      fontFamily={theme.fonts.livvic}
                      fontWeight={theme.fonts.fontWeightBold}
                      variant="contained"
                      size="small"
                      startIcon={
                        <Box
                          component="img"
                          src={MessageIcon}
                          alt="Message Icon"
                        ></Box>
                      }
                      sx={{
                        background: "green",
                        width: "120px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "none",
                        position: { xs: "static", sm: "absolute" },
                        mt: { xs: 2, sm: 0 },
                        bottom: 0,
                        "&:hover": {
                          background: "darkGreen",
                        },
                      }}
                      onClick={handleMessage}
                    >
                      Message
                    </Button>
                  )}
                </Box>
              </Box>
              {/* ------------Image/Video Toggle---------------- */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                  mt: "30px",
                }}
              >
                <Box
                  sx={{
                    width: "85%",
                    color: "white",
                    p: "2px",
                    background: "black",
                    border: "1px solid rgba(144,5,5,1)",
                    borderRadius: "25px",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontFamily: theme.fonts.montserrat,
                    zIndex: 1,
                  }}
                >
                  <Grid container>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          textAlign: "center",
                          py: 1.5,
                          background: isImage ? "rgba(243, 0, 0, 1)" : "black",
                          borderRadius: "25px",
                          cursor: "pointer",
                        }}
                        onClick={handleImage}
                      >
                        Images
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          textAlign: "center",
                          py: 1.5,
                          background: isImage ? "black" : "rgba(243, 0, 0, 1)",
                          borderRadius: "25px",
                          cursor: "pointer",
                        }}
                        onClick={handleVideo}
                      >
                        Videos
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {/* -----------------Images/Videos Container--------------- */}

              <Box
                sx={{
                  background: theme.colors.background.greyBlackGradient,
                  overflow: "hidden",
                  position: "relative",
                  top: "-22px",
                  py: "60px",
                  px: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "90%", md: "85%", lg: "95%" },
                  }}
                >
                  {isMyProfile && isAdmin === "true" && (
                    <Box
                      component="label"
                      sx={{
                        border: "1px solid grey",
                        borderRadius: "5px",
                        width: {
                          xs: "50px",
                          sm: "80px",
                          md: "100px",
                          lg: "120px",
                        },
                        minHeight: { xs: "150px", lg: "160px", xl: "180px" },
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "left",
                        cursor: "pointer",
                      }}
                    >
                      {isImageLoading || isVideoLoading ? (
                        <CircularProgress sx={{ color: "white" }} />
                      ) : (
                        <AddCircle sx={{ fontSize: "30px" }} />
                      )}
                      {isImage
                        ? !isImageLoading && (
                            <input
                              type="file"
                              multiple
                              accept="image/*"
                              onChange={(e) => handleUpload(e, "IMAGE")}
                              style={{ display: "none" }}
                            />
                          )
                        : !isVideoLoading && (
                            <input
                              type="file"
                              multiple
                              accept="video/*"
                              onChange={(e) => handleUpload(e, "VIDEO")}
                              style={{ display: "none" }}
                            />
                          )}
                    </Box>
                  )}
                  {isImage ? (
                    <Swiper
                      grabCursor={true}
                      initialSlide={0}
                      spaceBetween={20}
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        400: { slidesPerView: 1.5 },
                        500: { slidesPerView: 1.8 },
                        600: { slidesPerView: 2 },
                        700: { slidesPerView: 2.5 },
                        900: { slidesPerView: 2.5 },
                        1000: { slidesPerView: 2.8 },
                        1200: { slidesPerView: 3.5 },
                        1400: { slidesPerView: 4 },
                        1500: { slidesPerView: 4.5 },
                        1600: { slidesPerView: 5 },
                      }}
                    >
                      {featuredImages?.map((img, index) => (
                        <SwiperSlide key={index}>
                          <Box
                            sx={{
                              position: "relative",
                            }}
                          >
                            <Box
                              component="img"
                              src={img || DefaultImg}
                              alt="Avatar"
                              sx={{
                                width: "100%",
                                height: {
                                  xs: "150px",
                                  lg: "160px",
                                  xl: "180px",
                                },
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              onError={(e) => {
                                e.target.src = DefaultImg;
                              }}
                            ></Box>
                            {isMyProfile && isAdmin === "true" && (
                              <Close
                                sx={{
                                  position: "absolute",
                                  right: "5px",
                                  top: 0,
                                  color: theme.colors.text.greyLight,
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDelete(index, "IMAGE")}
                              />
                            )}
                          </Box>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Swiper
                      grabCursor={true}
                      initialSlide={0}
                      spaceBetween={20}
                      breakpoints={{
                        0: { slidesPerView: 1 },
                        400: { slidesPerView: 1.5 },
                        500: { slidesPerView: 1.8 },
                        600: { slidesPerView: 2 },
                        700: { slidesPerView: 2.5 },
                        900: { slidesPerView: 2.5 },
                        1000: { slidesPerView: 2.8 },
                        1200: { slidesPerView: 3.5 },
                        1400: { slidesPerView: 4 },
                        1500: { slidesPerView: 4.5 },
                        1600: { slidesPerView: 5 },
                      }}
                    >
                      {featuredVideos?.map((video, index) => (
                        <SwiperSlide key={index}>
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%",
                              height: { xs: "150px", lg: "160px", xl: "180px" },
                            }}
                          >
                            <video
                              muted
                              loop
                              controls
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            >
                              <source
                                src={video || DefaultVideo}
                                type="video/mp4"
                                onError={(e) => {
                                  e.target.src = DefaultVideo;
                                }}
                              />
                            </video>
                            {isMyProfile && isAdmin === "true" && (
                              <Close
                                sx={{
                                  position: "absolute",
                                  right: "5px",
                                  top: 0,
                                  color: theme.colors.text.greyLight,
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDelete(index, "VIDEO")}
                              />
                            )}
                          </Box>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}

                  {hasMoreItems && (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "20px",
                        bottom: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                        cursor: "pointer",
                      }}
                      onClick={handleDialogOpen}
                    >
                      <Typography fontFamily={theme.fonts.livvic}>
                        View More
                      </Typography>
                      <ChevronRight />
                    </Box>
                  )}
                </Box>

                {/* --------------------Image/Video Dialog--------------------- */}
                <Dialog
                  open={openDialog}
                  onClose={handleDialogClose}
                  PaperProps={{
                    sx: {
                      minWidth: "60vw",
                      height: "80%",
                      background: theme.colors.common.black,
                      color: theme.colors.common.white,
                      borderRadius: theme.fonts.smallBorderRadius,
                      pb: "20px",
                    },
                  }}
                  sx={{
                    backdropFilter: "blur(3px)",
                    backgroundColor: "rgb(255,255,255,0.3)",
                  }}
                >
                  <DialogContent
                    sx={{
                      backgroundColor: "black",
                      overflowY: "hidden",
                    }}
                  >
                    <Box display="flex" justifyContent="end" height="40px">
                      <Close
                        onClick={handleDialogClose}
                        sx={{
                          color: theme.colors.text.greyLight,
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        height: "100%",
                        p: "20px",
                        backgroundColor: "#121212",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "5px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "transparent",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: theme.colors.background.formInput,
                          borderRadius: "4px",
                        },
                      }}
                    >
                      <Grid container spacing={2} pb="20px">
                        {isImage
                          ? images?.map((image, index) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                key={index}
                              >
                                <Box sx={{ position: "relative" }}>
                                  <Box
                                    component="img"
                                    src={image || DefaultImg}
                                    alt="image"
                                    id="image"
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                    onError={(e) => {
                                      e.target.src = DefaultImg;
                                    }}
                                  />
                                  {isMyProfile && (
                                    <Close
                                      onClick={() =>
                                        handleDelete(index, "IMAGE")
                                      }
                                      sx={{
                                        color: theme.colors.text.greyLight,
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: 0,
                                        right: "5px",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Grid>
                            ))
                          : videos?.map((video, index) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                key={index}
                              >
                                <Box sx={{ position: "relative" }}>
                                  <video
                                    muted
                                    loop
                                    controls
                                    style={{
                                      width: "100%",
                                      height: "150px",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <source
                                      src={video || DefaultVideo}
                                      type="video/mp4"
                                      onError={(e) => {
                                        e.target.src = DefaultVideo;
                                      }}
                                    />
                                  </video>
                                  {isMyProfile && (
                                    <Close
                                      onClick={() =>
                                        handleDelete(index, "VIDEO")
                                      }
                                      sx={{
                                        color: theme.colors.text.greyLight,
                                        cursor: "pointer",
                                        position: "absolute",
                                        top: 0,
                                        right: "5px",
                                      }}
                                    />
                                  )}
                                </Box>
                              </Grid>
                            ))}
                      </Grid>
                    </Box>
                  </DialogContent>
                </Dialog>

                {/* <Box
                    sx={{
                      position: "absolute",
                      right: "50px",
                      bottom: "30px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Typography fontFamily={theme.fonts.livvic}>
                      View More
                    </Typography>
                    <ArrowForwardIos fontSize="25px" />
                  </Box> */}
              </Box>

              {/* --------------Brand Details---------------------- */}
              <Grid container rowSpacing={4} mt="30px">
                <Grid item xs={12} sx={{ p: "30px", background: "black" }}>
                  <Box
                    sx={{
                      borderBottom: `1px solid ${theme.colors.text.greyDarker}`,
                      py: 1.5,
                      pb: "10px",
                      px: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontFamily={theme.fonts.livvic}
                      fontWeight={theme.fonts.fontWeightBold}
                    >
                      Brand Details
                    </Typography>

                    {/* {isMyProfile && (
                    <Box
                      component="img"
                      src={EditIcon}
                      alt="Edit Icon"
                      sx={{ cursor: "pointer" }}
                      onClick={handleEdit}
                    ></Box>
                  )} */}
                  </Box>

                  <Box
                    sx={{
                      p: "20px",
                      background: theme.colors.dashboard.grey200,
                      mt: "30px",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={ContactPersonNameIcon}
                            alt="Contact Person Name"
                          ></Box>
                          <Typography
                            sx={{
                              fontFamily: theme.fonts.livvic,
                              fontSize: "18px",
                              color: theme.colors.text.greyLight,
                            }}
                          >
                            Contact Person Name
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: theme.fonts.livvic,
                            fontSize: "18px",
                            fontWeight: theme.fonts.fontWeightMedium,
                          }}
                        >
                          {/* Serena Williams */}
                          {profile?.contactPersonName}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={ProfileWebsiteUrlIcon}
                            alt="Profile Website Url Icon"
                          ></Box>
                          <Typography
                            sx={{
                              fontFamily: theme.fonts.livvic,
                              fontSize: "18px",
                              color: theme.colors.text.greyLight,
                            }}
                          >
                            Website Url
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontFamily: theme.fonts.livvic,
                            fontSize: "18px",
                            fontWeight: theme.fonts.fontWeightMedium,
                            color: "rgba(51,159,237,1)",
                            wordBreak: "break-word",
                          }}
                          onClick={handleUrl}
                        >
                          {/* https://www.facebook.com/Serena Williams/ */}
                          {profile?.websiteUrl}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={LocationIcon}
                            alt="Location Icon"
                          ></Box>
                          <Typography
                            sx={{
                              fontFamily: theme.fonts.livvic,
                              fontSize: "18px",
                              color: theme.colors.text.greyLight,
                            }}
                          >
                            Location
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: theme.fonts.livvic,
                            fontSize: "18px",
                          }}
                        >
                          {/* 123 Main Street, Anytown, United States Postal Code: 12345 */}
                          {/* {`${profile?.city}, ${profile?.state}, ${profile?.country}`} */}
                          {profile?.city !== "N/A" ? profile?.city + "," : ""}
                          {profile?.state !== "N/A" ? profile?.state + "," : ""}
                          {profile?.country !== "N/A" ? profile?.country : ""}
                        </Typography>
                      </Grid>
                      {/* {isMyProfile && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={EinNumberIcon}
                            alt="Ein Number Icon"
                          ></Box>
                          <Typography
                            sx={{
                              fontFamily: theme.fonts.livvic,
                              fontSize: "18px",
                              color: theme.colors.text.greyLight,
                            }}
                          >
                            EIN Number
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: theme.fonts.livvic,
                            fontSize: "18px",
                          }}
                        >
                          {profile?.einNumber}
                        </Typography>
                      </Grid>
                    )} */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={BusinessCategoryIcon}
                            alt="Business Category Icon"
                          ></Box>
                          <Typography
                            sx={{
                              fontFamily: theme.fonts.livvic,
                              fontSize: "18px",
                              color: theme.colors.text.greyLight,
                            }}
                          >
                            Business Category
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: theme.fonts.livvic,
                            fontSize: "18px",
                            textTransform: "capitalize",
                          }}
                        >
                          {/* Fashion and Apparel */}
                          {profile?.businessCategory}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default BrandsMyProfile;
