import { Box, Button, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Athletes from "../../../../components/afterLogin/brands/home/sections/athletes";
import MyCampaigns from "../../../../components/afterLogin/brands/home/sections/myCampaigns";
import SocialMedia from "../../../../components/afterLogin/brands/home/sections/socialMediaSection";
import { useTheme } from "@emotion/react";
import SubscriptionPaymentFailedModal from "../../../../components/afterLogin/brands/home/subscriptionPaymentFailed.modal";
import FreeTrialStartedModal from "../../../../components/afterLogin/brands/home/freeTrialStarted.modal";

const Home = () => {
  const theme = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [showFreeTrialDialog, setShowFreeTrialDialog] = useState(false);
  const gracePeriod = localStorage.getItem("gracePeriod");
  const freeTrial = localStorage.getItem("freeTrial");

  useEffect(() => {
    if (gracePeriod === "true") {
      const timeout = setTimeout(() => {
        setShowDialog(true);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, []);

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (freeTrial === "true") {
      const timeout = setTimeout(() => {
        setShowFreeTrialDialog(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, []);

  const handleFreeTrialDialogClose = () => {
    setShowFreeTrialDialog(false);
    localStorage.removeItem("freeTrial");
  };

  return (
    <>
      <Helmet>
        <title>Home | Burrst</title>
      </Helmet>
      <Box>
        <SocialMedia />
        <Athletes />
        <MyCampaigns />
      </Box>
      <Dialog
        open={showDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            width: "80vw",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <SubscriptionPaymentFailedModal handleDialogClose={handleDialogClose} />
      </Dialog>
      <Dialog
        open={showFreeTrialDialog}
        // onClose={handleFreeTrialDialogClose}
        PaperProps={{
          sx: {
            width: "80vw",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <FreeTrialStartedModal handleDialogClose={handleFreeTrialDialogClose} />
      </Dialog>
    </>
  );
};

export default Home;
