import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import CrossIcon from "../../../assets/icons/CrossIcon.svg";
import EditPhoneNuber from "../../../assets/images/EditPhoneNumber.svg";

import Flag from "react-flagkit";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { dataCountryList } from "../../../services/data/data.service";
import { toast } from "react-toastify";
import { athleteUpdatePhoneNumber } from "../../../services/athlete/athlete.service";
import { OTP_TIMER } from "../../../utils/constants";

const EditPhoneNumberModal = ({
  formData,
  setFormData,
  resendOtpData,
  setResendOtpData,
  fetchRegistrationData,
  handleEditNumberModalClose,
}) => {
  const theme = useTheme();

  const [changeNumberData, setChangeNumberData] = useState({
    registrationToken: localStorage.getItem("registrationToken"),
    countryCode: "+1",
    phoneNo: "",
  });
  const [isCountryList, setCountryList] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [countrySet, setCountrySet] = useState("US");

  const handlePhoneNo = () => {
    setCountryList(true);
  };
  const handlePhoneNoModalClose = () => {
    setCountryList(false);
  };

  const handleCountrySelect = (country) => {
    setChangeNumberData({
      ...changeNumberData,
      countryCode: country?.callingCode?.includes("+")
        ? country?.callingCode
        : `+${country?.callingCode}`,
    });

    setCountrySet(country?.countryCode);
    handlePhoneNoModalClose();
  };

  const handleChange = (field, value) => {
    setChangeNumberData({
      ...changeNumberData,
      [field]: value,
    });
  };

  const handleSubmit = async () => {
    if (
      !isNaN(changeNumberData?.phoneNo) &&
      changeNumberData?.phoneNo.length >= 7 &&
      changeNumberData?.phoneNo?.length <= 15
    ) {
      const response = await athleteUpdatePhoneNumber(changeNumberData);

      if (response?.status === 200) {
        setFormData({
          ...formData,
          phoneNo: changeNumberData?.phoneNo,
        });
        setResendOtpData({
          ...resendOtpData,
          phoneNo: `${changeNumberData?.countryCode}${changeNumberData?.phoneNo}`,
        });
        toast.success("Phone number edited successfully");
        fetchRegistrationData();
        localStorage.setItem("otpTimer", OTP_TIMER);
        handleEditNumberModalClose();
      } else {
        toast.error("Failed to edit phone number");
      }
    } else {
      toast.error("Invalid phone number");
    }
  };

  const fetchCountryOptions = async () => {
    try {
      const response = await dataCountryList(true);

      if (response?.status === 200) {
        setCountryOptions(response?.data);
      } else {
        console.error("Failed to fetch country options:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching country options", error);
    }
  };

  useEffect(() => {
    fetchCountryOptions();
  }, []);

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer", width: "15px" }}
        onClick={handleEditNumberModalClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   textAlign: "center",
          py: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={EditPhoneNuber}
            alt="Edit Phone Number Img"
            sx={{
              width: "110px",
            }}
          ></Box>
          <Typography sx={{ fontFamily: theme.fonts.livvic, mt: "10px" }}>
            Please provide your updated phone number{" "}
          </Typography>
        </Box>
        <Box sx={{ mt: "30px" }}>
          <Typography
            fontFamily={theme.fonts.livvic}
            sx={{
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: {
                xs: theme.fonts.registrationSubHeading_xs,
                md: theme.fonts.registrationSubHeading_sm,
              },
              marginBottom: "8px",
            }}
          >
            Phone Number
          </Typography>

          <TextField
            name="phoneNumber"
            fullWidth
            // autoComplete="off"
            size="small"
            value={changeNumberData?.phoneNo}
            onChange={(e) => handleChange("phoneNo", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    color: theme.colors.common.white,
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      pr: 1,
                      mr: 1,
                    }}
                    onClick={handlePhoneNo}
                    borderRight={`1px solid ${theme.colors.common.white}`}
                    setConfirmPassword
                  >
                    <Flag country={countrySet} />
                    <ArrowDropDownIcon />
                  </Box>
                  {changeNumberData?.countryCode
                    ? changeNumberData?.countryCode.startsWith("+")
                      ? changeNumberData?.countryCode
                      : `+${changeNumberData?.countryCode}`
                    : null}
                </InputAdornment>
              ),
              sx: {
                border: `1px solid ${theme.colors.common.white}`,
                background: theme.colors.background.formInput,
                color: theme.colors.common.white,
                borderRadius: "10px",
                fontSize: theme.fonts.registrationSubHeading_xs,
                height: "45px",
              },
            }}
            InputLabelProps={{
              sx: {
                color: theme.colors.text.greyLight,
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused": {
                  fieldset: {
                    borderColor: theme.colors.common.white,
                  },
                },
              },
              "& input, & textarea, & select": {
                WebkitTextFillColor: "#FFFFFF",
                WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                transition: "background-color 5000s ease-in-out 0s",
              },
            }}
          />
          <Dialog
            open={isCountryList}
            onClose={handlePhoneNoModalClose}
            PaperProps={{
              sx: {
                width: { xs: "80vw", md: "60vw" },
                height: "80%",
                background: theme.colors.common.black,
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <DialogContent
              sx={{
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "red",
                  borderRadius: "4px",
                },
              }}
            >
              {countryOptions?.map((country, index) => (
                <Box
                  onClick={() => handleCountrySelect(country)}
                  key={index}
                  display="flex"
                  alignItems="center"
                >
                  <Flag
                    country={country?.countryCode}
                    sx={{ cursor: "pointer" }}
                  />
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    ml={1}
                    sx={{ cursor: "pointer" }}
                  >
                    {`${country?.name} (${country?.countryCode}-${country?.callingCode})`}
                  </Typography>
                </Box>
              ))}
            </DialogContent>
          </Dialog>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: "70px" }}>
          <Button
            // fullWidth
            sx={{
              background: theme.colors.background.registerButton,
              color: "white",
              height: "50px",
              width: "50%",
              fontSize: "22px",
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              borderRadius: "10px",
              "&:hover": {
                background: theme.colors.divider.red,
              },
              "&.MuiButton-root.Mui-disabled": {
                background: "rgba(255,0,0,0.6)",
                color: "white",
              },
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default EditPhoneNumberModal;
