import { createSlice } from "@reduxjs/toolkit";
import {
  getChatRoomId,
  getChatUsers,
  setChatRoomId,
  setIsUserSelected,
  setSelectedUser,
} from "./actions/chatActions";

const initialState = {
  isAllDataLoading: true,
  error: "",
  chatUsers: [],
  selectedUser: null,
  chatRoomId: "",
  isUserSelected: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  extraReducers(builder) {
    builder
      // getChatUsers---------------
      .addCase(getChatUsers.pending, (state, action) => {
        state.isAllDataLoading = true;
      })
      .addCase(getChatUsers.fulfilled, (state, action) => {
        state.chatUsers = action.payload.data;
        state.isAllDataLoading = false;
        state.error = "";
      })
      .addCase(getChatUsers.rejected, (state, action) => {
        state.isAllDataLoading = false;
        state.error = "Some Error Occurred in getUsers";
      })
      // setSelectedUsers-------------------
      .addCase(setSelectedUser.pending, (state, action) => {
        state.isAllDataLoading = true;
      })
      .addCase(setSelectedUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
        state.isAllDataLoading = false;
        state.error = "";
      })
      .addCase(setSelectedUser.rejected, (state, action) => {
        state.isAllDataLoading = false;
        state.error = "Some Error Occurred in setSelectedUser";
      })
      //  getChatRoomId---------------------------
      .addCase(getChatRoomId.pending, (state, action) => {
        state.isAllDataLoading = true;
      })
      .addCase(getChatRoomId.fulfilled, (state, action) => {
        state.chatRoomId = action.payload.data.chatroomId;
        state.isAllDataLoading = false;
        state.error = "";
      })
      .addCase(getChatRoomId.rejected, (state, action) => {
        state.isAllDataLoading = false;
        state.error = "Some Error Occurred in setSelectedUser";
      })
      // setChatRoomId--------------------
      .addCase(setChatRoomId.pending, (state, action) => {
        state.isAllDataLoading = true;
      })
      .addCase(setChatRoomId.fulfilled, (state, action) => {
        state.chatRoomId = action.payload;
        state.isAllDataLoading = false;
        state.error = "";
      })
      .addCase(setChatRoomId.rejected, (state, action) => {
        state.isAllDataLoading = false;
        state.error = "Some Error Occurred in setChatRoomId";
      })
      // setIsUserSelected--------------------
      .addCase(setIsUserSelected.pending, (state, action) => {
        state.isAllDataLoading = true;
      })
      .addCase(setIsUserSelected.fulfilled, (state, action) => {
        state.isUserSelected = action.payload;
        state.isAllDataLoading = false;
        state.error = "";
      })
      .addCase(setIsUserSelected.rejected, (state, action) => {
        state.isAllDataLoading = false;
        state.error = "Some Error Occurred in setIsUserSelected";
      });
  },
});

export default chatSlice.reducer;
