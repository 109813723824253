import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import LinkBankAccountIcon from "../../../../assets/icons/LinkBankAccountIcon.svg";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { getBankVerificationUrl } from "../../../../services/accounts/accounts.service";

const BankDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [newTabUrl, setNewTabUrl] = useState("");
  const backState = localStorage.getItem("route");
  const campId =
    localStorage.getItem("campaignId") || location?.state?.campaignId;

  const linkYourBankAccountLists = [
    "Agree to the privacy and policy",
    "Select your institution",
    "Login to the bank",
    "Give access to bank account",
  ];

  const handleBack = () => {
    if (backState === "dashbordPlaidConnectModal") {
      navigate("/dashboard/home");
      localStorage.removeItem("route");
      return;
    } else if (backState === "aiRecomPlaidConnectModal") {
      navigate("/dashboard/campaigns", {
        state: {
          back: "home",
        },
      });
      localStorage.removeItem("route");
      return;
    } else if (backState === "brandListPlaidConnectModal") {
      navigate("/dashboard/explore-brands/brands-list", {
        state: {
          back: "brandList",
        },
      });
      localStorage.removeItem("route");
      return;
    } else if (backState === "viewDetailsPlaidConnectModal") {
      navigate(`/dashboard/campaigns/view-details/${campId}`);
      localStorage.removeItem("route");
      localStorage.removeItem("campaignId");
      return;
    } else if (backState === "profile") {
      navigate("/dashboard/home/my-profile", {
        state: {
          isMyProfile: true,
        },
      });
      localStorage.removeItem("route");
      return;
    } else if (backState === "gracePeriod") {
      navigate("/dashboard/home");
      localStorage.removeItem("route");
      return;
    } else {
      navigate(-1);
    }
  };

  const handleContinue = () => {
    window.open(newTabUrl, "_self");
  };

  const fetchAccountsDetails = async () => {
    const { data } = await getBankVerificationUrl();
    setNewTabUrl(data?.data?.link);
  };

  useEffect(() => {
    fetchAccountsDetails();
  }, []);

  return (
    <Box
      mt="50px"
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* ----------------Top Header Icons-------------------------- */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          gap: 2,
        }}
      >
        <ArrowBackIcon
          onClick={handleBack}
          sx={{
            cursor: "pointer",
            fontSize: { xs: "30px", sm: "40px" },
            color: "white",
          }}
        />

        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
        >
          Bank Details
        </Typography>
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          my: "30px",
          px: "20px",
          pt: "40px",
          pb: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={3} lg={2}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={LinkBankAccountIcon}
                    alt="Link Bank Account Icon"
                    width={{ xs: "30vh", sm: "80%" }}
                  ></Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9} lg={10} py={1}>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.russoOne,
                    fontSize: {
                      xs: theme.fonts.subHeading_xs,
                      sm: theme.fonts.subHeading,
                    },
                    my: 1,
                  }}
                >
                  Link your bank account
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                  }}
                >
                  Securely connect your bank account to enable seamless
                  transactions.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Timeline
              position="left"
              sx={{
                padding: 0,
                my: 3,
                width: "100%",
                "& li:before": {
                  display: "none",
                },
              }}
            >
              {linkYourBankAccountLists?.map((element, index) => (
                <TimelineItem key={index}>
                  <TimelineContent sx={{ textAlign: "left" }}>
                    {element}
                  </TimelineContent>
                  <TimelineSeparator>
                    <TimelineDot sx={{ background: " rgba(255, 0, 0, 1)" }} />
                    {index !== linkYourBankAccountLists.length - 1 && (
                      <TimelineConnector
                        sx={{
                          background: "transparent",
                          borderRight: "2px dashed rgba(255, 0, 0, 1)",
                        }}
                      />
                    )}
                  </TimelineSeparator>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                background: "red",
                width: "150px",
                textTransform: "none",
                float: "right",
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "16px",
                fontFamily: theme.fonts.livvic,
                "&:hover": {
                  background: theme.colors.divider.red,
                },
              }}
              onClick={handleContinue}
            >
              CONTINUE
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BankDetails;
