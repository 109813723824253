import React, { useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import WalletIcon from "../../../../../../assets/icons/PaymentIcon.svg";
import { campaignRevenueMakePayment } from "../../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";

const RevenuePaymentModal = ({
  handlePatmentClose,
  amount,
  deliverableId,
  revenueId,
}) => {
  const theme = useTheme();
  const [makePayment, setMakePayment] = useState({
    deliverableId: deliverableId,
    revenueId: revenueId,
  });

  const handlePayLater = () => {
    handlePatmentClose();
  };

  const handleProceed = async () => {
    try {
      const response = await campaignRevenueMakePayment(makePayment);
      if (response.status === 200) {
        handlePatmentClose();
        toast.success("Payment done");
      } else {
        handlePatmentClose();
        toast.error(response.message || response.status);
      }
    } catch (error) {
      handlePatmentClose();
      toast.error(error.message || error.status);
    }
  };

  return (
    <DialogContent>
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer", width: "15px" }}
        onClick={handlePatmentClose}
      ></Box>
      <Box display="flex" justifyContent="center" mt="30px">
        <Box
          component="img"
          src={WalletIcon}
          alt="Wallet Icon"
          sx={{
            width: "100px",
          }}
        ></Box>
      </Box>
      <Box
        my="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          sx={{ fontSize: { xs: "20px", md: "22px" } }}
        >
          Payment
        </Typography>
        <Box
          sx={{
            my: "20px",
            py: "5px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#252525",
            width: "80%",
          }}
        >
          <Typography
            fontWeight={500}
            fontFamily={theme.fonts.livvic}
            sx={{ fontSize: { xs: "18px", md: "20px" }, color: "#A0A0A0" }}
          >
            Amount
          </Typography>
          <Typography
            fontWeight={500}
            fontFamily={theme.fonts.livvic}
            sx={{ fontSize: { xs: "18px", md: "20px" }, color: "red" }}
          >
            ${amount}
          </Typography>
        </Box>
        <Button
          onClick={handleProceed}
          sx={{
            mt: "5px",
            px: "40px",
            py: "10px",
            background: "#FF0101",
            color: "white",
            "&:hover": {
              backgroundColor: "darkred",
            },
            fontFamily: theme.fonts.montserrat,
            fontWeight: theme.fonts.fontWeightBold,
            textTransform: "none",
            fontSize: { xs: "14px", md: "16px" },
          }}
        >
          PROCEED
        </Button>
        <Typography
          onClick={handlePayLater}
          mt="20px"
          fontWeight={400}
          fontFamily={theme.fonts.livvic}
          sx={{
            textDecoration: "underline",
            fontSize: { xs: "16px", md: "18px" },
            color: "#A0A0A0",
            cursor: "pointer",
          }}
        >
          Pay later
        </Typography>
      </Box>
    </DialogContent>
  );
};

export default RevenuePaymentModal;
