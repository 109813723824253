import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { styled } from "@mui/system";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ChevronRightOutlined } from "@mui/icons-material";

import AdidasBlack from "../../../../../assets/icons/AdidasBlack.svg";
import ShieldIcon from "../../../../../assets/icons/ShieldIcon.png";
import AIExplainerIcon from "../../../../../assets/icons/AIExplainerIcon.svg";
import DownloadBoldIcon from "../../../../../assets/icons/DownloadBoldIcon.svg";
import BrowseIcon from "../../../../../assets/icons/BrowseIcon.svg";
import ContractFileIcon from "../../../../../assets/icons/ContractFileIcon.svg";
import CrossIcon from "../../../../../assets/icons/CrossIcon.svg";
import PDFIcon from "../../../../../assets/icons/PDFIcon.png";

import { saveAs } from "file-saver";
import SignatureCanvas from "react-signature-canvas";
import { useDropzone } from "react-dropzone";

import CongratulationsModal from "../../../../../components/afterLogin/athletes/home/subComponents/brands/brands.congratulations.modal";
import ConfirmationModal from "../../../../../components/afterLogin/athletes/home/subComponents/brands/brands.confirmation.modal";
import BrandsAiExplainerModal from "../../../../../components/afterLogin/athletes/home/subComponents/brands/brands.AiExplainer.modal";
import { campaignActivityDetails } from "../../../../../_mock/dummyData";
import { getAISummary } from "../../../../../services/campaign/campaign.service";

import { ToastContainer, toast } from "react-toastify";
import BrandsAiDisclaimerModal from "../../../../../components/afterLogin/athletes/home/subComponents/brands/brands.AiDisclaimer.modal";
import { useSelector } from "react-redux";

const BrandsBeforeContractPage = ({ setIsSigned }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const campaignDetails = location?.state?.campaignDetails;

  const [isImageError, setIsImageError] = useState(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [congratulationsModalOpen, setCongratulationsModalOpen] =
    useState(false);
  const [aiExplainerModalOpen, setAIExplainerModalOpen] = useState(false);
  const [signOption, setSignOption] = useState("DRAW");
  const [uploadedData, setUploadedData] = useState(null);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [signatureData, setSignatureData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState("");

  const signatureRef = useRef();

  const handleDisclaimerModalClose = () => {
    setDisclaimerModalOpen(false);
  };

  const handleDisclaimerModalOpen = () => {
    setDisclaimerModalOpen(true);
  };

  const handleCanvasClear = () => {
    signatureRef?.current?.clear();
    setSignatureData(null);
    setIsCanvasEmpty(true);
  };

  const handleSubmit = () => {
    setSignatureData(signatureRef?.current?.toDataURL());
    setConfirmationModalOpen(true);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleCongratulationsModalClose = () => {
    setCongratulationsModalOpen(false);
    setIsSigned(true);
    navigate("/dashboard/campaigns/contract", {
      state: { campaignDetails: { ...campaignDetails, signed: true } },
    });
  };

  const handleAIExplainer = async () => {
    setLoading(true);
    const response = await getAISummary(campaignDetails?._id);
    setLoading(false);
    if (response?.data?.status === 200) {
      signatureRef?.current?.clear();
      setSummary(response?.data?.data?.summary);
      setAIExplainerModalOpen(true);
      setIsCanvasEmpty(true);
      setUploadedData(null);
    } else toast.error(response);
  };

  const handleAIExplainerModalClose = () => {
    setAIExplainerModalOpen(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleImageError = (e) => {
    e.target.src = AdidasBlack;
    setIsImageError(true);
  };

  const handleDownload = (downloadLink, fileName) => {
    if (downloadLink) saveAs(downloadLink, fileName);
  };

  const handleSignOption = (e) => {
    setSignOption(e.target.value);
    setUploadedData(null);
    setIsCanvasEmpty(true);
  };

  const handleCanvas = () => {
    setIsCanvasEmpty(false);
  };

  const handleContractCrossIcon = () => {
    setUploadedData(null);
    setIsCanvasEmpty(true);
  };

  const uploadedDataType = uploadedData?.name?.split(".").pop();

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const uploadedFile = acceptedFiles[0];
      setUploadedData(uploadedFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "application/pdf": [".doc", ".docx", ".pdf"] },
  });

  const dropzoneStyle = () => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: 1,
      border: `2px dashed ${theme.colors.text.greyDarker}`,
      padding: "20px",
      cursor: "pointer",
      background: "rgba(29,29,29,1)",
    };
  };

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* ----------------Top Header Icons-------------------------- */}

      {/* <ToastContainer /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box>
            <ArrowBackIcon
              onClick={handleBack}
              sx={{
                cursor: "pointer",
                fontSize: "40px",
                color: "white",
              }}
            />
          </Box>
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.russoOne}
            fontSize={theme.fonts.heading}
          >
            Contract
          </Typography>
        </Box>

        {loading ? (
          <Box
            sx={{
              width: "150px",
              height: "40px",
              borderRadius: "5px",
              background: theme.colors.text.greyDark,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size="30px"
              sx={{
                color: "white",
              }}
            />
          </Box>
        ) : (
          <Box
            component="img"
            src={AIExplainerIcon}
            alt="AI Explainer Icon"
            width="150px"
            sx={{ cursor: "pointer" }}
            onClick={handleAIExplainer}
          ></Box>
        )}
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          my: "30px",
          p: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            {/* <Grid container columnSpacing={2}> */}
            {/* <Grid item xs={12} sm={4} lg={3} height="200px"> */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mb: "30px",
                px: "10px",
                py: "10px",
                border: "1px solid #383838",
                backgroundColor: "#191919",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "16px" },
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                }}
              >
                Before signing the contract, please carefully read the
                disclaimer.
              </Typography>
              <Button
                onClick={handleDisclaimerModalOpen}
                sx={{
                  fontSize: { xs: "12px", md: "15px" },
                  backgroundColor: "#383838",
                  fontFamily: theme.fonts.livvic,
                  color: "white",
                  textTransform: "none",
                }}
              >
                Show disclaimer
              </Button>
            </Box>
            <Box
              sx={{
                display: { sm: "flex" },
                alignItems: { xs: "center", sm: "flex-start" },
                gap: 3,
                height: { xs: "auto", sm: "200px" },
              }}
            >
              <Box
                sx={{
                  background: theme.colors.text.greyLight,
                  p: "10px",
                  minWidth: { xs: "250px", sm: "170px", md: "180px" },
                  maxWidth: { xs: "250px", sm: "auto" },
                  height: { xs: "250px", sm: "90%", md: "95%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: { xs: 2, sm: 0 },
                }}
              >
                <Box
                  component="img"
                  src={campaignDetails?.brandLogo}
                  alt="Brand Logo"
                  onError={handleImageError}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: isImageError ? "contain" : "cover",
                  }}
                ></Box>
              </Box>

              {/* </Grid> */}
              {/* <Grid item xs={12} sm={8} lg={9}> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  maxHeight: { xs: "auto", sm: "180px", md: "200px" },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.russoOne,
                    fontSize: theme.fonts.subHeading,
                  }}
                >
                  {/* Athlete my campaign */}
                  {campaignDetails?.brandName}
                </Typography>
                <Box
                  sx={{
                    height: "90%",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "3px",
                    },
                    "&::-webkit-scrollbar-track": {
                      // background: theme.colors.text.greyDark,
                      background: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "red",
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                    }}
                  >
                    {campaignDetails?.brief}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* </Grid> */}
            {/* </Grid> */}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: "rgba(217,217,217,0.1)",
                width: "100%",
                height: "100%",
                py: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box component="img" src={ShieldIcon} alt="Shield Icon"></Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              fontSize={theme.fonts.sectionHeading}
              mb={1}
            >
              Contract File
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                minWidth: "300px",
                maxWidth: "400px",
                border: `1px solid ${theme.colors.text.greyDarker}`,
                padding: "10px",
                background: "rgba(29,29,29,1)",
                position: "relative",
              }}
            >
              <Box
                component="img"
                src={
                  campaignDetails?.contractFiletype === "pdf"
                    ? PDFIcon
                    : ContractFileIcon
                }
                alt="Contract File"
                width="20%"
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  flexWrap: "wrap",
                  wordBreak: "break-all",
                  pr: 5,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: theme.fonts.sectionBodyText,
                  }}
                >
                  {
                    campaignDetails?.contractFilename
                    // .split(".")
                    // .slice(0, -1)
                    // .join("")
                  }
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: theme.fonts.fontWeightMedium,
                  }}
                >
                  {/* {`${(formData.contract.size / 1024).toFixed(
                    2
                  )} KB`} */}
                  45 KB
                </Typography>
              </Box>
              <Box
                component="img"
                src={DownloadBoldIcon}
                alt="Download Icon"
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: { xs: "30%", sm: "30px" },
                  width: "30px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleDownload(campaignDetails?.contract, "Contract File")
                }
              ></Box>
            </Box>
          </Grid>

          {campaignDetails?.selected && (
            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
                fontSize={theme.fonts.sectionHeading}
                mb={1}
              >
                Sign Document
              </Typography>

              <Select
                value={signOption}
                onChange={handleSignOption}
                sx={{
                  border: `1px solid ${theme.colors.text.greyDarker}`,
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "black",
                  color: "white",
                  height: "45px",
                  width: "100%",
                  "& .MuiSelect-icon": {
                    color: "white",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                    },
                  },
                }}
              >
                <MenuItem value="DRAW">Draw</MenuItem>
                <MenuItem value="UPLOAD">Upload File</MenuItem>
              </Select>
            </Grid>
          )}

          {campaignDetails?.selected && (
            <Grid item xs={12}>
              {signOption === "DRAW" ? (
                <Box>
                  <Button
                    variant="outlined"
                    disabled={isCanvasEmpty}
                    sx={{
                      textTransform: "none",
                      color: "white",
                      border: "1px solid grey",
                      borderBottom: "none",
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      background: "black",
                      "&:hover": {
                        border: "1px solid grey",
                        borderBottom: "none",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                      "&.MuiButton-root.Mui-disabled": {
                        background: "black",
                        color: "white",
                        border: "1px solid grey",
                        borderBottom: "none",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                    onClick={handleCanvasClear}
                  >
                    Clear
                  </Button>

                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    backgroundColor={theme.colors.text.greyDark}
                    clearOnResize={true}
                    onBegin={handleCanvas}
                    canvasProps={{
                      className: "signCanvas",
                    }}
                  />
                </Box>
              ) : !uploadedData ? (
                <Box sx={dropzoneStyle} {...getRootProps()}>
                  <Box component="img" src={BrowseIcon} alt="Browse Icon"></Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      color: "rgba(175,172,172,1)",
                    }}
                  >
                    Drag and drop a file here
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      color: "rgba(175,172,172,1)",
                    }}
                  >
                    or
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      textDecoration: "underline",
                    }}
                  >
                    Browse File
                  </Typography>
                  <Input {...getInputProps()} />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    minWidth: "300px",
                    maxWidth: "400px",
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    padding: "10px",
                    background: "rgba(29,29,29,1)",
                    position: "relative",
                  }}
                >
                  {uploadedDataType === "docx" || uploadedDataType === "doc" ? (
                    <Box
                      component="img"
                      src={ContractFileIcon}
                      alt="Contract File"
                      width="20%"
                    ></Box>
                  ) : (
                    <Box
                      component="img"
                      src={PDFIcon}
                      alt="Contract File"
                      width="20%"
                    ></Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1.5,
                      flexWrap: "wrap",
                      wordBreak: "break-all",
                      pr: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightMedium,
                        fontSize: theme.fonts.sectionSubHeading,
                      }}
                    >
                      {uploadedData?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightMedium,
                      }}
                    >{`${(uploadedData?.size / 1024).toFixed(
                      2
                    )} KB`}</Typography>
                  </Box>
                  <Box
                    component="img"
                    src={CrossIcon}
                    alt="Cross Icon"
                    sx={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={handleContractCrossIcon}
                  ></Box>
                </Box>
              )}
            </Grid>
          )}

          {campaignDetails?.selected && (
            <Grid item xs={12} textAlign="right">
              <Button
                variant="contained"
                disabled={isCanvasEmpty && !uploadedData?.name}
                sx={{
                  background: "red",
                  width: "150px",
                  textTransform: "none",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "16px",
                  fontFamily: theme.fonts.livvic,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                }}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </Grid>
          )}

          <Dialog
            open={confirmationModalOpen}
            // onClose={handleConfirmationModalClose}
            PaperProps={{
              sx: {
                minWidth: "50vw",
                background: "black",
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <ConfirmationModal
              handleModalClose={handleConfirmationModalClose}
              setCongratulationsModalOpen={setCongratulationsModalOpen}
              uploadedData={uploadedData}
              campaignDetails={campaignDetails}
              signatureData={signatureData}
              isCanvasEmpty={isCanvasEmpty}
            />
          </Dialog>

          <Dialog
            open={congratulationsModalOpen}
            // onClose={handleCongratulationsModalClose}
            PaperProps={{
              sx: {
                minWidth: "50vw",
                background: "black",
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <CongratulationsModal
              handleCongratulationsModalClose={handleCongratulationsModalClose}
            />
          </Dialog>

          <Dialog
            open={aiExplainerModalOpen}
            // onClose={handleAIExplainerModalClose}
            PaperProps={{
              sx: {
                minWidth: "50vw",
                maxHeight: "600px",
                background: "black",
                mx: "10px",
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <BrandsAiExplainerModal
              handleModalClose={handleAIExplainerModalClose}
              setConfirmationModalOpen={setConfirmationModalOpen}
              summary={summary}
              uploadedData={uploadedData}
              setUploadedData={setUploadedData}
              campaignDetails={campaignDetails}
              isCanvasEmpty={isCanvasEmpty}
              setIsCanvasEmpty={setIsCanvasEmpty}
              setSignatureData={setSignatureData}
            />
          </Dialog>

          <Dialog
            open={disclaimerModalOpen}
            PaperProps={{
              sx: {
                minWidth: "40vw",
                background: "black",
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <BrandsAiDisclaimerModal
              handleDialogClose={handleDisclaimerModalClose}
            />
          </Dialog>
        </Grid>
      </Box>
    </Box>
  );
};

export default BrandsBeforeContractPage;
