const Joi = require("joi");

const Schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": `Email is required`,
      "string.empty": `Email is required`,
      "string.pattern.base": `Email is Invalid`,
    }),
  password: Joi.string()
    // .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    .required()
    .messages({
      "any.required": `Password is required`,
      "string.empty": `Password is invalid`,
      // "string.pattern.base": `Password is Invalid`,
    }),
  deviceName: Joi.optional(),
  firebaseToken: Joi.optional(),
});

export default Schema;
