import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";

import BrowseIcon from "../../../../../../assets/icons/BrowseIcon.svg";
import ContractFileIcon from "../../../../../../assets/icons/ContractFileIcon.svg";
import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import PDFIcon from "../../../../../../assets/icons/PDFIcon.png";
import ConfirmationIcon from "../../../../../../assets/icons/ConfirmationIcon.svg";
import AI_Icon from "../../../../../../assets/icons/AI_Icon.svg";
import AiIcon from "../../../../../../assets/icons/AiIcon.svg";
import ErrorIcon from "../../../../../../assets/icons/ErrorIcon.svg";
import BlueCrossIcon from "../../../../../../assets/icons/BlueCrossIcon.svg";
import { ChevronRightOutlined } from "@mui/icons-material";
import { getAISummary } from "../../../../../../services/campaign/campaign.service";

import SignatureCanvas from "react-signature-canvas";
import { useDropzone } from "react-dropzone";
import BrandsAiDisclaimerModal from "./brands.AiDisclaimer.modal";

const BrandsAiExplainerModal = ({
  handleModalClose,
  setConfirmationModalOpen,
  summary,
  uploadedData,
  setUploadedData,
  setSignatureData,
  isCanvasEmpty,
  setIsCanvasEmpty,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
  const signatureRef = useRef();

  const campaignDetails = location?.state?.campaignDetails;

  const [signOption, setSignOption] = useState("DRAW");

  const handleCanvasClear = () => {
    signatureRef.current.clear();
    setSignatureData(null);
    setIsCanvasEmpty(true);
  };

  const handleSubmit = () => {
    setSignatureData(signatureRef?.current?.toDataURL());
    setConfirmationModalOpen(true);
    handleModalClose();
  };

  const handleSignOption = (e) => {
    setSignOption(e.target.value);
    setUploadedData(null);
    setIsCanvasEmpty(true);
  };

  const handleCanvas = () => {
    setIsCanvasEmpty(false);
  };

  const handleModalContractCrossIcon = () => {
    setUploadedData(null);
    setIsCanvasEmpty(true);
  };

  const uploadedDataType = uploadedData?.name?.split(".").pop();

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const uploadedFile = acceptedFiles[0];
      setUploadedData(uploadedFile);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "application/pdf": [".doc", ".docx", ".pdf"] },
  });

  const dropzoneStyle = () => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: 1,
      border: `2px dashed ${theme.colors.text.greyDarker}`,
      padding: "20px",
      cursor: "pointer",
      background: "rgba(29,29,29,1)",
    };
  };

  const handleDisclaimerModalClose = () => {
    setDisclaimerModalOpen(false);
  };

  const handleDisclaimerModalOpen = () => {
    setDisclaimerModalOpen(true);
  };

  return (
    <DialogContent
      sx={{
        background: "black",
        overflow: "hidden",
        p: 0,
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="15px"
        top="15px"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          handleModalClose();
          setUploadedData(null);
          setIsCanvasEmpty(true);
        }}
      ></Box>
      <Box
        sx={{
          mt: "45px",
          mb: "10px",
          px: "10px",
          maxHeight: "500px",
          overflowY: "scroll",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "grey",
            borderRadius: "4px",
          },
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid white",
            my: 1,
            pb: 2,
            display: "flex",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Box
            component="img"
            src={AiIcon}
            alt="AI Icon"
            sx={{ width: "34px" }}
          ></Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            AI Explainer
          </Typography>
        </Box>

        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mt: "5px",
                px: "10px",
                py: "3px",
                border: "1px solid #383838",
                backgroundColor: "#191919",
                borderRadius: "5px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "15px" },
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                }}
              >
                Before signing the contract, please carefully read the
                disclaimer.
              </Typography>
              <Button
                onClick={handleDisclaimerModalOpen}
                sx={{
                  fontSize: { xs: "12px", md: "15px" },
                  backgroundColor: "#383838",
                  fontFamily: theme.fonts.livvic,
                  color: "white",
                  textTransform: "none",
                }}
              >
                Show disclaimer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              fontSize={theme.fonts.sectionHeading}
              mb={1}
            >
              Information
            </Typography>
            <Typography fontFamily={theme.fonts.livvic}>{summary}</Typography>
          </Grid>

          {campaignDetails?.selected && (
            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
                fontSize={theme.fonts.sectionHeading}
                mb={1}
              >
                Sign Document
              </Typography>

              <Select
                value={signOption}
                onChange={handleSignOption}
                sx={{
                  border: `1px solid ${theme.colors.text.greyDarker}`,
                  outline: "none",
                  borderRadius: "10px",
                  backgroundColor: "black",
                  color: "white",
                  height: "45px",
                  width: "100%",
                  "& .MuiSelect-icon": {
                    color: "white",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                    },
                  },
                }}
              >
                <MenuItem value="DRAW">Draw</MenuItem>
                <MenuItem value="UPLOAD">Upload File</MenuItem>
              </Select>
            </Grid>
          )}

          {campaignDetails?.selected && (
            <Grid item xs={12}>
              {signOption === "DRAW" ? (
                <Box>
                  <Button
                    variant="outlined"
                    disabled={isCanvasEmpty}
                    sx={{
                      textTransform: "none",
                      color: "white",
                      border: "1px solid grey",
                      borderBottom: "none",
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      background: "black",
                      "&:hover": {
                        border: "1px solid grey",
                        borderBottom: "none",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                      "&.MuiButton-root.Mui-disabled": {
                        background: "black",
                        color: "white",
                        border: "1px solid grey",
                        borderBottom: "none",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                    onClick={handleCanvasClear}
                  >
                    Clear
                  </Button>
                  <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    backgroundColor={theme.colors.text.greyDark}
                    clearOnResize={true}
                    onBegin={handleCanvas}
                    canvasProps={{
                      className: "signCanvas",
                    }}
                  />
                </Box>
              ) : !uploadedData ? (
                <Box sx={dropzoneStyle} {...getRootProps()}>
                  <Box component="img" src={BrowseIcon} alt="Browse Icon"></Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      color: "rgba(175,172,172,1)",
                    }}
                  >
                    Drag and drop a file here
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      color: "rgba(175,172,172,1)",
                    }}
                  >
                    or
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      textDecoration: "underline",
                    }}
                  >
                    Browse File
                  </Typography>
                  <Input {...getInputProps()} />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    minWidth: "300px",
                    maxWidth: "400px",
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    padding: "10px",
                    background: "rgba(29,29,29,1)",
                    position: "relative",
                  }}
                >
                  {uploadedDataType === "docx" || uploadedDataType === "doc" ? (
                    <Box
                      component="img"
                      src={ContractFileIcon}
                      alt="Contract File"
                      width="20%"
                    ></Box>
                  ) : (
                    <Box
                      component="img"
                      src={PDFIcon}
                      alt="Contract File"
                      width="20%"
                    ></Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1.5,
                      flexWrap: "wrap",
                      wordBreak: "break-all",
                      pr: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightMedium,
                        fontSize: theme.fonts.sectionSubHeading,
                      }}
                    >
                      {uploadedData?.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightMedium,
                      }}
                    >{`${(uploadedData?.size / 1024).toFixed(
                      2
                    )} KB`}</Typography>
                  </Box>
                  <Box
                    component="img"
                    src={CrossIcon}
                    alt="Cross Icon"
                    sx={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={handleModalContractCrossIcon}
                  ></Box>
                </Box>
              )}
            </Grid>
          )}

          {campaignDetails?.selected && (
            <Grid item xs={12} textAlign="right">
              <Button
                variant="contained"
                disabled={isCanvasEmpty && !uploadedData?.name}
                sx={{
                  background: "red",
                  maxWidth: "150px",
                  textTransform: "none",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "16px",
                  fontFamily: theme.fonts.livvic,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.5)",
                    color: "white",
                  },
                }}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <Dialog
        open={disclaimerModalOpen}
        PaperProps={{
          sx: {
            minWidth: "40vw",
            background: "black",
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <BrandsAiDisclaimerModal
          handleDialogClose={handleDisclaimerModalClose}
        />
      </Dialog>
    </DialogContent>
  );
};

export default BrandsAiExplainerModal;
