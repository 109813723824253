import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import { Box } from "@mui/material";

const MinimalLayout = () => {
  return (
    <>
      <Outlet />
      {/* <Box sx={{ height: "20vh" }}>
        <Footer />
      </Box> */}
    </>
  );
};

export default MinimalLayout;
