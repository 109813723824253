import axios from "axios";
import { BASE_API_URL } from "../../utils/constants";

const base = BASE_API_URL;

const handleError = (error) => {
  if (error?.response?.data?.status === 500) return "Internal Server Error";
  else return error?.response?.data?.message || "Some Error Occurred";
};

export const postApi = async (path, body) => {
  try {
    const url = `${base}${path}`;
    const response = await axios.post(url, body);

    return response;
  } catch (error) {
    return handleError(error);
  }
};
