import { Box, Divider, useTheme } from "@mui/material";
import React from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import TwitterIcon from "../assets/icons/Twitter.svg";
import FacebookIcon from "../assets/icons/Facebook.svg";
import InstagramIcon from "../assets/icons/Instagram.svg";
import TikTokIcon from "../assets/icons/TikTok.svg";
import LinkedInIcon from "../assets/icons/LinkedIn.svg";
import YoutubeIcon from "../assets/icons/Youtube.svg";
// import PolicyBackground from "../../src/assets/images/policyBackground.png";
import { BASE_IMAGE_URL } from "../utils/constants";
import dayjs from "dayjs";

const Footer = () => {
  const theme = useTheme();

  const PolicyBackground = `${BASE_IMAGE_URL}/policyBackground.png`;

  const linkStyles = {
    color: theme.colors.common.white,
  };

  const iconStyles = {
    backgroundColor: theme.colors.background.socialMedia,
    height: "43px",
    width: "43px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
    "&:hover": {
      background: theme.colors.background.socialMediaLight,
      color: theme.colors.text.faceBook,
      transition: "all 0.2s ease",
    },
  };

  return (
    <Box sx={{ background: "black" }}>
      <Box
        sx={{
          maxHeight: "275px",
          padding: "30px 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 3,
          borderBottom: `1px solid ${theme.colors.divider.grey}`,
          background: `url(${PolicyBackground}) no-repeat,rgba(169,169,169,0.1)`,
          backgroundSize: "100% 100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
          fontFamily={theme.fonts.livvic}
          fontSize={{ xs: "12px", md: "16px" }}
        >
          <NavLink to="/contact-us" style={linkStyles}>
            Contact
          </NavLink>
          {/* <NavLink
            to="https://forms.monday.com/forms/fd66c97023d0a18ca7b19919395b2666?r=use1"
            rel="noopener noreferrer"
            target="_blank"
            style={linkStyles}
          >
            Contact
          </NavLink> */}
          |
          <NavLink
            to="https://site.burrst.io/blog"
            rel="noopener noreferrer"
            target="_blank"
            style={linkStyles}
          >
            Blogs
          </NavLink>
          |
          <NavLink to="/privacy-policy" style={linkStyles}>
            Privacy Policy
          </NavLink>
          |
          <NavLink to="/terms-of-use" style={linkStyles}>
            Terms of Use
          </NavLink>
          |
          <NavLink to="/team" style={linkStyles}>
            Team
          </NavLink>
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <NavLink
            to="https://www.facebook.com/burrstsports"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box sx={iconStyles}>
              <img
                width="22px"
                height="22px"
                src={FacebookIcon}
                alt="Facebook Icon"
              />
            </Box>
          </NavLink>
          <NavLink
            to="https://www.instagram.com/burrstsports"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box sx={iconStyles}>
              <img
                width="22px"
                height="22px"
                src={InstagramIcon}
                alt="Instagram Icon"
              />
            </Box>
          </NavLink>
          <NavLink
            to="https://twitter.com/burrstsports"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box sx={iconStyles}>
              <img
                width="22px"
                height="22px"
                src={TwitterIcon}
                alt="Twitter Icon"
              />
            </Box>
          </NavLink>
          <NavLink
            to="https://www.tiktok.com/@burrst"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box sx={iconStyles}>
              <img
                width="22px"
                height="22px"
                src={TikTokIcon}
                alt="TikTok Icon"
              />
            </Box>
          </NavLink>
          <NavLink
            to="https://www.youtube.com/channel/UCSCPHO3hDSqDA5AiSICXuGw"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box sx={iconStyles}>
              <img
                width="22px"
                height="22px"
                src={YoutubeIcon}
                alt="Youtube Icon"
              />
            </Box>
          </NavLink>
          <NavLink
            to="https://www.linkedin.com/company/burrst-sports/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box sx={iconStyles}>
              <img
                width="22px"
                height="22px"
                src={LinkedInIcon}
                alt="LinkedIn Icon"
              />
            </Box>
          </NavLink>
        </Box>

        {/* <Divider style={{ backgroundColor: theme.colors.divider.grey }} /> */}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontFamily={theme.fonts.livvic}
        height="70px"
      >
        {` © BurrstSports 2021-${dayjs().format("YYYY")}. All rights reserved.`}
      </Box>
    </Box>
  );
};

export default Footer;
