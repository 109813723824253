import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import BurrstLogo from "../../../../assets/logo/BurrstLogo.svg";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";

const ExploreBrandsCard = ({ detail, index, isLoading }) => {
  const theme = useTheme();

  const handleImageError = (e) => {
    e.target.src = BurrstLogo;
  };

  return (
    <Box
      sx={{
        border: `1px solid rgba(255, 0, 0, 0.5)`,
        borderRadius: "5px",
        background: theme.colors.common.black,
        overflow: "hidden",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          p: "10px",
          display: "flex",
          gap: 1,
          height: "105px",
        }}
      >
        {/* -----------------------Brand Logo--------------------- */}
        <Box
          sx={{
            width: "100px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${theme.colors.text.greyDarker}`,
            borderRadius: "5px",
          }}
        >
          {isLoading ? (
            <CustomSkeleton type="img" sx={{ width: "90%", height: "90%" }} />
          ) : (
            <Box
              component="img"
              src={detail?.logo}
              alt={`Brand Logo ${index}`}
              onError={handleImageError}
              sx={{
                objectFit: "fill",
                height: "90%",
                width: "90%",
              }}
            ></Box>
          )}
        </Box>

        {/* -----------------------Brand Name------------- */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "calc(100% - 100px)",
          }}
        >
          {isLoading ? (
            <CustomSkeleton type="p" sx={{ minWidth: "100%" }} />
          ) : (
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              fontSize="20px"
            >
              {detail?.name || ""}
            </Typography>
          )}
        </Box>
      </Box>

      {/* --------------------Contents------------ */}
      <Box
        sx={{
          p: "10px",
          background: "rgba(14,14,14,1)",
          height: { xs: "80px", sm: "90px", lg: "95px" },
        }}
      >
        {isLoading ? (
          <CustomSkeleton type="p" count={3} sx={{ minWidth: "100%", mb: 1 }} />
        ) : (
          <Typography
            fontFamily={theme.fonts.livvic}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              fontSize: { xs: "13px", sm: "14px", lg: "16px" },
              color: theme.colors.text.greyLight,
            }}
          >
            {detail?.about || ""}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ExploreBrandsCard;
