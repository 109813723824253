import React, { useState } from "react";
import {
  Avatar,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";

import SearchIcon from "../../../../../assets/icons/SearchIcon.svg";
import { DoneAll } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatRoomId,
  setIsUserSelected,
  setSelectedUser,
} from "../../../../../store/actions/chatActions";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const ChatLeftSidebar = () => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const { isAllDataLoading, chatUsers } = useSelector((state) => state?.chat);

  // const currentUserId = localStorage.getItem("userId");

  const [chatUserName, setChatUserName] = useState("");

  const filteredChatUser = chatUsers?.filter((chatUser) => {
    return chatUser?.name?.toLowerCase().includes(chatUserName?.toLowerCase());
  });

  const handleUserClick = (user) => {
    dispatch(setSelectedUser(user));
    dispatch(setChatRoomId(user?._id));
    dispatch(setIsUserSelected(true));
  };

  const handleUserSearch = (e) => {
    setChatUserName(e.target.value);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {/* ------------Search Box------------------ */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          height: "70px",
          background: "rgba(14,14,14,1)",
          px: "10px",
          py: "5px",
        }}
      >
        {isAllDataLoading ? (
          <CustomSkeleton
            sx={{ width: "100%", height: "40px", borderRadius: "5px" }}
          />
        ) : (
          <TextField
            name="search"
            placeholder="Search"
            value={chatUserName || ""}
            onChange={handleUserSearch}
            fullWidth
            autoComplete="off"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="Search Icon" />
                </InputAdornment>
              ),
              sx: {
                border: `1px solid ${theme.colors.text.greyLightDark}`,
                background: "rgba(14,14,14,1)",
                color: theme.colors.common.white,
                borderRadius: "5px",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused": {
                  fieldset: {
                    borderColor: theme.colors.common.white,
                    // border: "0",
                  },
                },
              },
            }}
          />
        )}
      </Box>

      {/* ----------------Chat User List------------------ */}
      <Box sx={{ pr: "2px" }}>
        <Box
          sx={{
            height: "calc(500px - 75px)",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "4px",
              // display: "none",
            },
            // "&:hover::-webkit-scrollbar": {
            //   display: "block",
            //   width: "4px",
            // },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
              borderRadius: "4px",
            },
          }}
        >
          {isAllDataLoading
            ? Array.from({ length: 7 }, (_, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    px: "10px",
                    py: "10px",
                    borderBottom: "1px solid black",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <CustomSkeleton type="circle" />
                    <CustomSkeleton type="p" />
                  </Box>

                  <CustomSkeleton type="circle" />
                </Box>
              ))
            : filteredChatUser?.map((user, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    px: "10px",
                    py: "10px",
                    borderBottom: "1px solid black",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUserClick(user)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Avatar src={user?.image} />
                    <Box>
                      <Typography
                        fontFamily={theme.fonts.livvic}
                        fontSize="16px"
                        fontWeight={theme.fonts.fontWeightMedium}
                      >
                        {user?.name}
                      </Typography>
                      {/* <Typography
                fontFamily={theme.fonts.livvic}
                fontSize="10px"
                color={theme.colors.text.greyDark}
              >
                {user?.lastMessage?.message}
              </Typography> */}
                    </Box>
                  </Box>

                  {/* {user?.status === "ACTIVE" && (
            <Circle sx={{ color: "rgba(30, 142, 223, 1)", width: "12px" }} />
          )}
          {user?.status === "INACTIVE" && (
            <Done sx={{ color: "grey", width: "17px" }} />
          )}
          {user?.status === "NOT_SEEN" && (
            <DoneAll sx={{ color: "grey", width: "15px" }} />
          )}
          {user?.status === "SEEN" && (
            <DoneAll sx={{ color: "rgba(30, 142, 223, 1)", width: "15px" }} />
          )} */}
                  {!user?.lastMessage?.readByMe && (
                    <DoneAll
                      sx={{
                        color: "grey",
                        width: "15px",
                      }}
                    />
                  )}
                  {user?.lastMessage?.readByMe && (
                    <DoneAll
                      sx={{
                        color: "rgba(30, 142, 223, 1)",
                        width: "15px",
                      }}
                    />
                  )}
                </Box>
              ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatLeftSidebar;
