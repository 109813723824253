import React from "react";
import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import BurrstLogoIcon from "../../../../assets/icons/BurrstLogoIcon.svg";
import DefaultImg from "../../../../assets/images/DefaultAvatar.jpg";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";

const DealTrackerCard = ({ details }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const totalCompletion = details?.totalCompletion;
  let progressValue;
  const isDecimal = !Number.isInteger(parseFloat(totalCompletion));

  if (isDecimal) {
    progressValue = parseFloat(totalCompletion).toFixed(2);
  } else {
    progressValue = totalCompletion;
  }

  const options = {
    series: [progressValue],
    chart: {
      height: 150,
      width: 100,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "65%",
        },
        dataLabels: {
          show: true,

          value: {
            color: "white",
            fontSize: "16px",
            offsetY: -8,
          },
        },
      },
    },
    colors: ["#AB0404"],
    labels: [""],
  };

  return (
    <Grid
      sx={{
        width: { xs: "90%", md: "43%" },
        my: "20px",
        mx: "auto",
        borderRadius: "2px",
        background: "#272727",
        overflow: "hidden",
        border: "1px solid #918E8E",
        cursor: "pointer",
      }}
      onClick={() =>
        navigate(`/dashboard/campaigns/view-details/${details?._id}`)
      }
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          backgroundColor: "#252525",
          px: "15px",
        }}
      >
        <Typography
          fontFamily={theme.fonts.livvic}
          sx={{
            fontSize: { xs: "18px", md: "20px" },
            fontWeight: theme.fonts.fontWeightMedium,
          }}
        >
          {details?.campaignName}
        </Typography>
        <Box position="relative">
          <ReactApexChart
            options={options}
            series={options.series}
            type="radialBar"
            height={150}
            width={100}
          />
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "black", width: "100%", height: "100%" }}>
        <Typography
          fontFamily={theme.fonts.livvic}
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: 500,
            px: "15px",
            pt: "8px",
          }}
        >
          Athletes
        </Typography>
        <Box
          height="180px"
          sx={{
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "red",
              borderRadius: "4px",
            },
          }}
        >
          {details?.athletes?.map((data, index) => (
            <Box
              key={index}
              sx={{
                //width: "100%",
                backgroundColor: "#252525",
                mx: "15px",
                mb: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  padding: "10px",
                }}
              >
                <Box
                  // onClick={() => handleProfile()}
                  sx={{
                    cursor: "pointer",
                    width: "50px",
                    height: "50px",
                    position: "relative",
                  }}
                >
                  <Box
                    component="img"
                    src={data?.athlete?.profileImage || DefaultImg}
                    alt="Avatar"
                    id="avatar"
                    sx={{ width: "100%", height: "100%" }}
                    onError={(e) => {
                      e.target.src = DefaultImg;
                    }}
                  ></Box>
                  <Box
                    component="img"
                    src={BurrstLogoIcon}
                    alt="Burrst Logo Icon"
                    sx={{
                      width: "30px",
                      position: "absolute",
                      top: "-30%",
                      right: "-30%",
                    }}
                    id="Burrst Logo Icon"
                  ></Box>
                </Box>
                <Box width="100%">
                  <Typography
                    // onClick={() => handleProfile()}
                    fontFamily={theme.fonts.livvic}
                    fontWeight={theme.fonts.fontWeightMedium}
                    sx={{
                      cursor: "pointer",
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    {data?.athlete?.name}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={data?.completion}
                    sx={{
                      "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#AB0404",
                      },
                    }}
                  />
                  <Typography
                    fontWeight={500}
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    Progress
                    <span
                      style={
                        {
                          //   color: "red",
                        }
                      }
                    >
                      {Number.isInteger(data?.completion)
                        ? data?.completion + "%"
                        : data?.completion.toFixed(2) + "%"}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default DealTrackerCard;
