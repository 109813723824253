import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  brandUserCreate,
  brandUserSubscribe,
} from "../../../../services/brand/brand.service";
import { toast } from "react-toastify";

const AddUserModal = ({ handleClose, callUserList }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const confirmAction = async () => {
    return new Promise((resolve) => {
      const confirmed = window.confirm(
        "Are you sure? This will deduct $30 from your saved payment method."
      );
      resolve(confirmed);
    });
  };

  const handlePay = async () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const areAllFieldsFilled =
      formData.email !== "" &&
      formData.firstName !== "" &&
      formData.lastName !== "";

    if (!areAllFieldsFilled) {
      toast.error("Please fill in all the fields");
      return;
    }

    if (!isValidEmail(formData.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    const confirmed = await confirmAction();
    if (confirmed) {
      setLoading(true);
      const response = await brandUserCreate(formData);
      setUserId(response?.data?.userId);
      if (response?.status === 200) {
        let result;
        if (response?.data?.data?.userId)
          result = await brandUserSubscribe(response?.data?.data?.userId);
        setLoading(false);
        if (result?.status === 200) {
          handleClose();
          toast.success("User created successfully");
          callUserList();
        } else if (result?.status === 400) {
          if (result?.data?.link) window.open(`${result?.data?.link}`, "_self");
        } else if (result?.status === 500) {
          alert(
            "Subscription failed. Please change your payment details from profile & try again"
          );
        } else {
          toast.error(result);
        }
      } else {
        setLoading(false);
        toast.error(response);
      }
    }
  };

  return (
    <DialogContent>
      <Grid display="flex" justifyContent="end">
        <CloseIcon
          onClick={handleClose}
          sx={{
            cursor: "pointer",
            backgroundColor: "#252525",
            color: "#7e7e7e",
            borderRadius: "50px",
            p: "2px",
          }}
        />
      </Grid>
      <Box sx={{ mx: { md: "30px" } }}>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: 500,
            fontSize: { xs: "15px", md: "18px" },
            marginBottom: "1%",
            mt: "20px",
          }}
        >
          Email
        </Typography>

        <TextField
          name="email"
          placeholder="User Email"
          fullWidth
          // autoComplete="off"
          value={formData?.email}
          onChange={(e) => handleChange("email", e.target.value)}
          InputProps={{
            sx: {
              border: `1px solid ${theme.colors.text.greyLight}`,
              background: theme.colors.background.formInput,
              color: theme.colors.common.white,
              borderRadius: theme.fonts.smallBorderRadius,
            },
          }}
          InputLabelProps={{
            sx: {
              color: theme.colors.text.greyLight,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                fieldset: {
                  borderColor: theme.colors.common.white,
                },
              },
            },
            "& input, & textarea, & select": {
              WebkitTextFillColor: "#FFFFFF",
              WebkitBoxShadow: "0 0 0px 1000px transparent inset",
              transition: "background-color 5000s ease-in-out 0s",
            },
          }}
        />

        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: 500,
            fontSize: { xs: "15px", md: "18px" },
            marginBottom: "1%",
            mt: "20px",
          }}
        >
          First Name
        </Typography>

        <TextField
          name="firstName"
          placeholder="User First Name"
          fullWidth
          // autoComplete="off"
          value={formData?.firstName}
          onChange={(e) => handleChange("firstName", e.target.value)}
          InputProps={{
            sx: {
              border: `1px solid ${theme.colors.text.greyLight}`,
              background: theme.colors.background.formInput,
              color: theme.colors.common.white,
              borderRadius: theme.fonts.smallBorderRadius,
            },
          }}
          InputLabelProps={{
            sx: {
              color: theme.colors.text.greyLight,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                fieldset: {
                  borderColor: theme.colors.common.white,
                },
              },
            },
            "& input, & textarea, & select": {
              WebkitTextFillColor: "#FFFFFF",
              WebkitBoxShadow: "0 0 0px 1000px transparent inset",
              transition: "background-color 5000s ease-in-out 0s",
            },
          }}
        />

        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: 500,
            fontSize: { xs: "15px", md: "18px" },
            marginBottom: "1%",
            mt: "20px",
          }}
        >
          Last Name
        </Typography>

        <TextField
          name="lastName"
          placeholder="User Last Name"
          fullWidth
          // autoComplete="off"
          value={formData?.lastName}
          onChange={(e) => handleChange("lastName", e.target.value)}
          InputProps={{
            sx: {
              border: `1px solid ${theme.colors.text.greyLight}`,
              background: theme.colors.background.formInput,
              color: theme.colors.common.white,
              borderRadius: theme.fonts.smallBorderRadius,
            },
          }}
          InputLabelProps={{
            sx: {
              color: theme.colors.text.greyLight,
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                fieldset: {
                  borderColor: theme.colors.common.white,
                },
              },
            },
            "& input, & textarea, & select": {
              WebkitTextFillColor: "#FFFFFF",
              WebkitBoxShadow: "0 0 0px 1000px transparent inset",
              transition: "background-color 5000s ease-in-out 0s",
            },
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: "40px",
          mb: "30px",
        }}
      >
        <Button
          sx={{
            borderRadius: "8px",
            py: "5px",
            width: "270px",
            color: theme.colors.common.white,
            background: theme.colors.text.red,
            cursor: "pointer",
            fontFamily: theme.fonts.livvic,
            fontSize: { xs: "16px", md: "20px" },
            fontWeight: theme.fonts.fontWeightMedium,
            "&:hover": {
              background: theme.colors.divider.red,
            },
            "&.MuiButton-root.Mui-disabled": {
              color: theme.colors.text.greyDarker,
            },
          }}
          disabled={loading}
          onClick={handlePay}
        >
          {loading ? <CircularProgress sx={{ color: "gray" }} /> : "Add"}
        </Button>
      </Box>
    </DialogContent>
  );
};

export default AddUserModal;
