import React, { useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import DefaultBrandLogo from "../../../../../../assets/logo/DefaultBrandLogo.png";

import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import { ArrowForwardIos, ChevronRight, Circle } from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import CustomSkeleton from "../../../../../../themes/@overrides/customSkeleton";

const CompletedProjectsListCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const campaignId = detail?._id;

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
  };

  const handleCardClick = () => {
    navigate(`/dashboard/campaigns/view-details/${detail?._id}`);
  };

  return (
    <Box
      sx={{
        border: `1px solid grey`,
        borderRadius: "5px",
        background: theme.colors.common.black,
        overflow: "hidden",
        cursor: "pointer",
      }}
      onClick={isAllDataLoading ? null : handleCardClick}
    >
      <Box
        sx={{
          p: "10px",
          display: "flex",
          gap: 1,
          height: "105px",
          background: "rgba(37,37,37,1)",
        }}
      >
        {/* -----------------------Brand Logo--------------------- */}
        <Box
          sx={{
            width: "100px",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="img" sx={{ width: "100%", height: "100%" }} />
          ) : (
            <Box
              component="img"
              src={detail?.brandLogo}
              alt="Brand Logo"
              onError={handleImageError}
              sx={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
            ></Box>
          )}
        </Box>

        {/* -----------------------Brand Name and Status------------- */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 0.5,
            height: "100%",
            width: "calc(100% - 100px)",
          }}
        >
          <Typography
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {isAllDataLoading ? <CustomSkeleton type="p" /> : detail?.brandName}
            {/* International Business Machines Learning */}
          </Typography>

          <Typography
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            color="red"
            fontSize="14px"
            lineHeight={1.1}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : detail?.sharePercentage ? (
              `${detail?.sharePercentage.toFixed(2)}% of monthly revenue.`
            ) : (
              `$${detail?.totalCompensation}`
            )}
          </Typography>
        </Box>
      </Box>

      {/* --------------------Campaign Name and Activities------------ */}
      <Box sx={{ px: "10px", py: "15px" }}>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightBold}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            mb: "10px",
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            detail?.campaignName
          )}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: theme.fonts.livvic,
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.activities?.map((activity, index) => {
                const activitiesLength = detail?.activities?.length;
                const remainingActivities = activitiesLength - 1;
                return index < 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: "12px",
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {activity}
                  </Box>
                ) : index === 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: "12px",
                      maxWidth: "90px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`+${remainingActivities} Other${
                      remainingActivities !== 1 ? "s" : ""
                    }`}
                  </Box>
                ) : null;
              })
            )}
          </Box>
          {isAllDataLoading ? (
            <CustomSkeleton type="circle" />
          ) : (
            <ChevronRight />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CompletedProjectsListCard;
