import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RegistrationBackground from "../../../assets/images/RegistrationBackground.jpg";
import InputUserIcon from "../../../assets/icons/InputUserIcon.svg";
import InputEmailIcon from "../../../assets/icons/InputEmailIcon.svg";

import InputPasswordLockIcon from "../../../assets/icons/InputPasswordLockIcon.svg";
import InputConfirmPasswordLockIcon from "../../../assets/icons/InputConfirmPasswordLockIcon.svg";
import { BASE_IMAGE_URL } from "../../../utils/constants";
import { toast } from "react-toastify";
import { brandRegistration } from "../../../services/brand/brand.service";
import Schema from "../../../joi.schema/beforeLogin/brand/brand.RegisterBrand.Schema";
import Flag from "react-flagkit";
import { dataCountryList } from "../../../services/data/data.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TrialFreeModal from "../../../components/beforeLogin/brands/trialFree.modal";

const RegisterBrand = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [countryName, setCountryName] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  const [isCountryList, setCountryList] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isTextFieldFocused, setTextFieldFocused] = useState(false);
  const [countrySet, setCountrySet] = useState("US");
  const [countryCode, setCountryCode] = useState("+1");
  const [formData, setFormData] = useState({
    name: "",
    contactPersonName: "",
    email: "",
    phoneNo: "",
    password: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleChange = (field, value) => {
    if (field === "phoneNo" && value?.length <= 15)
      setFormData({
        ...formData,
        [field]: value,
      });
    else if (field !== "phoneNo")
      setFormData({
        ...formData,
        [field]: value,
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCountrySearch = (e) => {
    const { value } = e.target;
    if (value.trim() === "") {
      setCountryName("");
    } else {
      setCountryName(value);
    }
  };

  const handlePhoneNo = () => {
    setCountryList(true);
  };

  const handlePhoneNoModalClose = () => {
    setCountryList(false);
  };

  const handleCountrySelect = (country) => {
    setCountryCode(country?.callingCode);

    setCountrySet(country?.countryCode);
    handlePhoneNoModalClose();
  };

  const handleRegister = async () => {
    const { error } = Schema.validate(formData);

    if (error) {
      error?.details?.forEach((errors) => {
        toast.error(errors.message);
      });
    } else if (confirmPassword !== formData?.password) {
      toast.error("Password does not match Confirm Password");
      return;
    } else {
      try {
        setLoading(true);
        const response = await brandRegistration(formData);
        setLoading(false);
        if (response?.status === 200) {
          window.localStorage.setItem("brandId", response?.data.brandId);
          window.localStorage.setItem(
            "registrationToken",
            response?.data.registrationToken
          );
          navigate("/brands/public-profile", {
            state: {
              brandId: response?.data.brandId,
              registrationToken: response?.data.registrationToken,
            },
          });
        } else {
          toast.error(response?.message || response?.status);
        }
      } catch (error) {
        toast.error(error.message || error.status);
      }
    }
  };

  const handleInputChange = (event) => {
    setConfirmPassword(event.target.value);
    const value = event.target.value;
    setInputValue(value);
  };

  const handleTextFieldFocus = () => {
    setTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setTextFieldFocused(false);
  };

  const containsNumber = /\d/.test(inputValue);
  const containsAlphabet = /[A-Z]/i.test(inputValue);
  const containsSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(inputValue);

  const fetchCountryOptions = async () => {
    try {
      const response = await dataCountryList(true);

      if (response?.status === 200) {
        setCountryOptions(response?.data);
      } else {
        console.error("Failed to fetch country options:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching country options", error);
    }
  };

  useEffect(() => {
    fetchCountryOptions();
    const timeout = setTimeout(() => {
      setShowDialog(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Helmet>
        <title>Brand Registration | Burrst</title>
      </Helmet>
      <Box>
        <Box
          sx={{
            position: "absolute",
            left: "20px",
            top: "20px",
            width: "35px",
            height: "35px",
            cursor: "pointer",
            backgroundColor: theme.colors.common.black,
            color: theme.colors.text.greyLight,
            borderRadius: "5px",
            boxShadow: "0px 0px 7px 0px rgba(197, 197, 197, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleBack}
        >
          <ArrowBackIcon sx={{ fontSize: "25px" }} />
        </Box>
        <Box
          component="img"
          src={RegistrationBackground}
          alt="Registration Background"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            objectFit: "cover",
            zIndex: "-1",
            height: "100vh",
          }}
        ></Box>
        <Grid
          container
          spacing={2}
          sx={{
            paddingX: { xs: "10px", md: "30px" },
            pt: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", md: "center" },
              textAlign: "left",
              height: { xs: "250px", sm: "350px", md: "600px", xl: "95vh" },
              mb: { xs: "20px", md: "0" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "90%", sm: "80%", md: "auto" },
              }}
            >
              <img
                src={BurrstLogo}
                alt="Burrst Logo"
                width="40%"
                style={{ marginBottom: 15 }}
              />
              <Typography
                color={theme.colors.text.greyLight}
                fontFamily={theme.fonts.russoOne}
                fontWeight={theme.fonts.fontWeightMedium}
                fontSize={{ xs: "24px", sm: "38px", md: "34px", lg: "46px" }}
              >
                THE NEXT GENERATION
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xl: "center" },
              height: { xs: "700px", xl: "95vh" },
              overflowY: { md: "scroll" },
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: theme.colors.background.formInput,
                borderRadius: "4px",
              },
            }}
          >
            <Box width={{ xs: "90%", sm: "80%" }}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: 900,
                  marginBottom: "20px",
                  fontSize: { xs: "24px", sm: "26px" },
                }}
              >
                Registration
                <span style={{ color: theme.colors.background.registerButton }}>
                  .
                </span>
              </Typography>
              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12}>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontWeight: theme.fonts.fontWeightBold,
                      fontSize: {
                        xs: theme.fonts.registrationSubHeading_xs,
                        md: theme.fonts.registrationSubHeading_sm,
                      },
                      marginBottom: "5px",
                    }}
                  >
                    Brand Name
                  </Typography>
                  <TextField
                    name="brandName"
                    placeholder="Brand Name"
                    fullWidth
                    size="small"
                    // autoComplete="off"
                    value={formData?.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    // error={!!formErrors.name}
                    // helperText={formErrors.name}
                    InputProps={{
                      sx: {
                        border: `1px solid ${theme.colors.common.white}`,
                        background: theme.colors.background.formInput,
                        color: theme.colors.common.white,
                        borderRadius: "10px",
                        fontSize: theme.fonts.registrationSubHeading_xs,
                        height: "45px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                      "& input, & textarea, & select": {
                        WebkitTextFillColor: "#FFFFFF",
                        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                        transition: "background-color 5000s ease-in-out 0s",
                      },
                      // "& input:hover, & input:focus, & textarea:hover, & textarea:focus, & select:hover, & select:focus":
                      //   {
                      //     borderColor: "theme.colors.common.white",
                      //   },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontWeight: theme.fonts.fontWeightBold,
                      fontSize: {
                        xs: theme.fonts.registrationSubHeading_xs,
                        md: theme.fonts.registrationSubHeading_sm,
                      },
                      marginBottom: "5px",
                    }}
                  >
                    Contact Person Name
                  </Typography>
                  <TextField
                    name="contactPersonName"
                    placeholder="Contact Person Name"
                    fullWidth
                    size="small"
                    // autoComplete="off"
                    value={formData?.contactPersonName}
                    onChange={(e) =>
                      handleChange("contactPersonName", e.target.value)
                    }
                    // error={!!formErrors.contactPersonName}
                    // helperText={formErrors.contactPersonName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={InputUserIcon} alt="Input User Icon" />
                        </InputAdornment>
                      ),
                      sx: {
                        border: `1px solid ${theme.colors.common.white}`,
                        background: theme.colors.background.formInput,
                        color: theme.colors.common.white,
                        borderRadius: "10px",
                        fontSize: theme.fonts.registrationSubHeading_xs,
                        height: "45px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                      "& input, & textarea, & select": {
                        WebkitTextFillColor: "#FFFFFF",
                        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                        transition: "background-color 5000s ease-in-out 0s",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontWeight: theme.fonts.fontWeightBold,
                      fontSize: {
                        xs: theme.fonts.registrationSubHeading_xs,
                        md: theme.fonts.registrationSubHeading_sm,
                      },
                      marginBottom: "5px",
                    }}
                  >
                    Email
                  </Typography>
                  <TextField
                    name="email"
                    placeholder="Company Email"
                    fullWidth
                    size="small"
                    // autoComplete="off"
                    value={formData.email}
                    onChange={(e) =>
                      handleChange("email", e.target.value.toLowerCase())
                    }
                    // error={!!formErrors.email}
                    // helperText={formErrors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={InputEmailIcon} alt="Input Email Icon" />
                        </InputAdornment>
                      ),
                      sx: {
                        border: `1px solid ${theme.colors.common.white}`,
                        background: theme.colors.background.formInput,
                        color: theme.colors.common.white,
                        borderRadius: "10px",
                        fontSize: theme.fonts.registrationSubHeading_xs,
                        height: "45px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                      "& input, & textarea, & select": {
                        WebkitTextFillColor: "#FFFFFF",
                        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                        transition: "background-color 5000s ease-in-out 0s",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontWeight: theme.fonts.fontWeightBold,
                      fontSize: {
                        xs: theme.fonts.registrationSubHeading_xs,
                        md: theme.fonts.registrationSubHeading_sm,
                      },
                      marginBottom: "5px",
                    }}
                  >
                    Phone Number
                  </Typography>
                  <TextField
                    name="phoneNumber"
                    fullWidth
                    size="small"
                    // autoComplete="off"
                    value={formData?.phoneNo}
                    onChange={(e) => handleChange("phoneNo", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            color: theme.colors.common.white,
                          }}
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              pr: 1,
                              mr: 1,
                            }}
                            onClick={handlePhoneNo}
                            borderRight={`1px solid ${theme.colors.common.white}`}
                          >
                            <Flag country={countrySet} />
                            <ArrowDropDownIcon />
                          </Box>
                          {countryCode
                            ? countryCode.startsWith("+")
                              ? countryCode
                              : `+${countryCode}`
                            : null}
                        </InputAdornment>
                      ),
                      sx: {
                        border: `1px solid ${theme.colors.common.white}`,
                        background: theme.colors.background.formInput,
                        color: theme.colors.common.white,
                        borderRadius: "10px",
                        fontSize: theme.fonts.registrationSubHeading_xs,
                        height: "45px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                      "& input, & textarea, & select": {
                        WebkitTextFillColor: "#FFFFFF",
                        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                        transition: "background-color 5000s ease-in-out 0s",
                      },
                    }}
                  />
                  <Dialog
                    open={isCountryList}
                    onClose={handlePhoneNoModalClose}
                    PaperProps={{
                      sx: {
                        width: "60vw",
                        height: "80%",
                        background: theme.colors.common.black,
                        color: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    }}
                    sx={{
                      backdropFilter: "blur(3px)",
                      backgroundColor: "rgb(255,255,255,0.3)",
                    }}
                  >
                    <DialogContent
                      sx={{
                        position: "relative",
                        pt: 0,
                        "&::-webkit-scrollbar": {
                          width: "3px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "transparent",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "red",
                          borderRadius: "4px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "sticky",
                          top: 0,
                          left: 0,
                          background: "black",
                          zIndex: 3,
                          py: 2,
                        }}
                      >
                        <TextField
                          name="searchCountry"
                          placeholder="Search by country name"
                          autoComplete="off"
                          autoFocus
                          value={countryName || ""}
                          onChange={handleCountrySearch}
                          fullWidth
                          size="small"
                          InputProps={{
                            sx: {
                              border: `1px solid ${theme.colors.text.greyLightDark}`,
                              background: theme.colors.common.black,
                              color: theme.colors.common.white,
                              borderRadius: theme.fonts.smallBorderRadius,
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              color: theme.colors.text.greyLight,
                            },
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused": {
                                fieldset: {
                                  borderColor: theme.colors.common.white,
                                },
                              },
                            },
                          }}
                        />
                      </Box>
                      {countryOptions
                        ?.filter((option) =>
                          option?.name
                            ?.toLowerCase()
                            ?.includes(countryName?.toLowerCase())
                        )
                        ?.map((country, index) => (
                          <Box
                            onClick={() => handleCountrySelect(country)}
                            key={index}
                            display="flex"
                            alignItems="center"
                          >
                            <Flag
                              country={country?.countryCode}
                              sx={{ cursor: "pointer" }}
                            />
                            <Typography
                              fontFamily={theme.fonts.livvic}
                              ml={1}
                              sx={{ cursor: "pointer" }}
                            >
                              {`${country?.name} (${country?.countryCode}-${country?.callingCode})`}
                            </Typography>
                          </Box>
                        ))}
                    </DialogContent>
                  </Dialog>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontWeight: theme.fonts.fontWeightBold,
                      fontSize: {
                        xs: theme.fonts.registrationSubHeading_xs,
                        md: theme.fonts.registrationSubHeading_sm,
                      },
                      marginBottom: "5px",
                    }}
                  >
                    Password
                  </Typography>
                  <TextField
                    name="password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    size="small"
                    value={confirmPassword}
                    // autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={InputPasswordLockIcon}
                            alt="Input Password Icon"
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <Visibility
                                sx={{ color: theme.colors.text.greyLight }}
                              />
                            ) : (
                              <VisibilityOff
                                sx={{ color: theme.colors.text.greyLight }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        border: `1px solid ${theme.colors.common.white}`,
                        background: theme.colors.background.formInput,
                        color: theme.colors.common.white,
                        borderRadius: "10px",
                        fontSize: theme.fonts.registrationSubHeading_xs,
                        height: "45px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                      "& input, & textarea, & select": {
                        WebkitTextFillColor: "#FFFFFF",
                        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                        transition: "background-color 5000s ease-in-out 0s",
                      },
                    }}
                    onChange={handleInputChange}
                    onFocus={handleTextFieldFocus}
                    onBlur={handleTextFieldBlur}
                  />
                  {isTextFieldFocused && (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        "@media (max-width: 480px)": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      Password must be at least
                      <span
                        style={{
                          color: inputValue.length >= 8 ? "#FFFFFF" : "#FF0000",
                        }}
                      >
                        {inputValue.length >= 8
                          ? " 8 character "
                          : " 8 characters "}
                      </span>
                      and contain
                      {containsNumber ? (
                        <span style={{ color: "#FFFFFF" }}> 1 number</span>
                      ) : (
                        <span style={{ color: "#FF0000" }}> 1 number</span>
                      )}
                      ,
                      <br />
                      {containsAlphabet ? (
                        <span style={{ color: "#FFFFFF" }}>1 alphabet </span>
                      ) : (
                        <span style={{ color: "#FF0000" }}>1 alphabet </span>
                      )}
                      and
                      {containsSymbol ? (
                        <span style={{ color: "#FFFFFF" }}> 1 symbol</span>
                      ) : (
                        <span style={{ color: "#FF0000" }}> 1 symbol</span>
                      )}
                      .
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontWeight: theme.fonts.fontWeightBold,
                      fontSize: {
                        xs: theme.fonts.registrationSubHeading_xs,
                        md: theme.fonts.registrationSubHeading_sm,
                      },
                      marginBottom: "5px",
                    }}
                  >
                    Confirm Password
                  </Typography>
                  <TextField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    size="small"
                    // autoComplete="off"
                    value={formData?.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    // error={!!formErrors.password}
                    // helperText={formErrors.password}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={InputConfirmPasswordLockIcon}
                            alt="Input Confirm Password Icon"
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? (
                              <Visibility
                                sx={{ color: theme.colors.text.greyLight }}
                              />
                            ) : (
                              <VisibilityOff
                                sx={{ color: theme.colors.text.greyLight }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        border: `1px solid ${theme.colors.common.white}`,
                        background: theme.colors.background.formInput,
                        color: theme.colors.common.white,
                        borderRadius: "10px",
                        fontSize: theme.fonts.registrationSubHeading_xs,
                        height: "45px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                      "& input, & textarea, & select": {
                        WebkitTextFillColor: "#FFFFFF",
                        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                        transition: "background-color 5000s ease-in-out 0s",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size="small"
                    sx={{
                      background: theme.colors.background.registerButton,
                      color: "white",
                      height: "45px",
                      fontSize: "22px",
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      borderRadius: "10px",
                      mt: 2,
                      "&.MuiButton-root.Mui-disabled": {
                        background: "rgba(255,0,0,0.6)",
                        color: "white",
                      },
                      "&:hover": {
                        background: theme.colors.divider.red,
                      },
                    }}
                    disabled={loading}
                    onClick={handleRegister}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "gray" }} />
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Dialog
                open={showDialog}
                onClose={handleDialogClose}
                PaperProps={{
                  sx: {
                    width: "80vw",
                    background: theme.colors.common.black,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                sx={{
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgb(255,255,255,0.3)",
                }}
              >
                <TrialFreeModal handleDialogClose={handleDialogClose} />
              </Dialog>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RegisterBrand;
