import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Avatar,
  Backdrop,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Tooltip,
} from "@mui/material";
import { Button, Typography, useTheme } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

// import BurrstLogo from "../../src/assets/logo/burrst_png.png";
import NotificationIcon from "../../src/assets/icons/NotificationIcon.svg";

// import DashboardAthleteAvatar from "../../src/assets/images/DefaultAvatar.jpg";
import { BASE_IMAGE_URL } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../store/authSlice";
import { getAthletesProfileDetailsApi } from "../services/athlete/api.request";
import {
  AccountCircleOutlined,
  Close,
  Logout,
  NotificationsOff,
} from "@mui/icons-material";
import { getNotifications } from "../services/notifications/notifications.service";
import { fetchMyProfile } from "../store/myProfileSlice";
import { authExchangeToken } from "../services/auth/auth.service";
import { toast } from "react-toastify";
import { fetchNotifications } from "../store/notificationsReducer";

function DashboardNavbar({ showNavbarDetails, setShowNavbarDetails }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const DashboardAthleteAvatar = `${BASE_IMAGE_URL}/DefaultAvatar.jpg`;
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;

  const dispatch = useDispatch();
  const { myProfile } = useSelector((state) => state.myProfile);

  const userType = localStorage.getItem("usertype");

  const avatarImage =
    userType === "ATHLETE"
      ? myProfile?.profileImage
      : userType === "BRAND" && myProfile?.brandLogo;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const notificationOpen = Boolean(notificationAnchorEl);

  const notificationsList = useSelector((state) => state?.notifications?.list);

  const notificationCount = 4;

  const filteredNotificationList =
    notificationsList?.[0]?.notifications?.length > notificationCount
      ? notificationsList?.[0]?.notifications?.slice(0, notificationCount)
      : notificationsList?.[0]?.notifications;

  let popoverPositionFromRight;

  if (window.innerWidth <= 600) {
    popoverPositionFromRight = 240;
  } else if (window.innerWidth <= 900) {
    popoverPositionFromRight = 350;
  } else if (window.innerWidth <= 1200) {
    popoverPositionFromRight = 400;
  } else if (window.innerWidth <= 1500) {
    popoverPositionFromRight = 500;
  } else {
    popoverPositionFromRight = 510;
  }

  const handleLogo = () => {
    navigate("/dashboard/home");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleViewAll = () => {
    navigate("/dashboard/notifications");
  };

  const handleProfile = () => {
    navigate("/dashboard/home/my-profile", {
      state: { isMyProfile: true },
    });
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  };

  const handleTokenChange = async () => {
    const { data } = await authExchangeToken();
    if (data?.status === 200) {
      dispatch(
        login({
          userData: {
            token: data?.data?.token,
            usertype: data?.data?.usertype,
            userId: data?.data?.userId,
            plan: data?.data?.plan,
            isBrandAdmin: data?.data?.isBrandAdmin,
          },
        })
      );
    } else {
      dispatch(logout());
      navigate("/");
      toast.error(data);
    }
  };

  useEffect(() => {
    dispatch(fetchMyProfile({ userType }));
    dispatch(fetchNotifications());
    if (userType === "BRAND") handleTokenChange();
  }, []);

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      sx={{
        padding: `15px 30px`,
        background: "rgba(18,18,18,1)",
        width: "100%",
        // maxWidth: "1536px",
        height: { xs: "70px", sm: "80px", md: "90px" },
        borderBottom: "1px solid grey",
        zIndex: 99,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: theme.fonts.livvic,
        "@media (max-width:600px)": {
          padding: "10px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          width: {
            xs: "22vw",
            sm: "18vw",
            md: "180px",
          },
        }}
      >
        <Box
          component="img"
          src={BurrstLogo}
          alt="Burrst Logo"
          sx={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={handleLogo}
        ></Box>
        <Box>
          <MenuIcon
            onClick={() => {
              setShowNavbarDetails(!showNavbarDetails);
            }}
            sx={{
              color: {
                xs: showNavbarDetails ? "grey" : theme.colors.common.white,
              },
              fontSize: "2rem",
              cursor: "pointer",
              borderRadius: "5px",
              background: {
                xs: showNavbarDetails ? "black" : "transparent",
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            src={NotificationIcon}
            alt="NotificationIcon"
            sx={{ width: "25px", cursor: "pointer" }}
            onClick={handleNotificationClick}
          ></Box>
          {notificationsList?.[0]?.unreadCount > 0 && (
            <Box
              sx={{
                position: "absolute",
                right: "1px",
                top: "0.5px",
                width: "13px",
                height: "13px",
                background: "rgba(237, 29, 36, 1)",
                borderRadius: "50%",
                p: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "9px",
              }}
            >
              {notificationsList?.[0]?.unreadCount || 0}
            </Box>
          )}
        </Box>

        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            position: "fixed",
            top: { xs: "70px", sm: "80px", md: "90px" },
            left: 0,
            height: {
              xs: "calc(100vh - 70px)",
              sm: "calc(100vh - 80px)",
              md: "calc(100vh - 90px)",
            },
            pointerEvents: "none",
          }}
          open={notificationOpen}
          onClick={handleNotificationClose}
        ></Backdrop>

        <Box sx={{ position: "relative" }}>
          <Popover
            open={notificationOpen}
            anchorEl={notificationAnchorEl}
            onClose={handleNotificationClose}
            onClick={handleNotificationClose}
            anchorOrigin={{
              vertical: 50,
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal:
                window.innerWidth <= 600 ? "center" : popoverPositionFromRight,
            }}
            sx={{
              "& .MuiPopover-paper": {
                backgroundColor: "black",
                filter: "drop-shadow(0px 4px 4px rgba(255,255,255,0.3))",
                color: "white",
                width: { xs: "300px", sm: "400px", md: "470px", lg: "550px" },
                // border: "1px solid grey",
              },
            }}
          >
            <Box width="100%">
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.cardSubHeading,
                  }}
                >
                  Notifications
                </Typography>
                <Button
                  sx={{
                    minWidth: "80px",
                    maxWidth: "100px",
                    height: "32px",
                    background: "rgba(237, 29, 36, 1)",
                    color: "white",
                    textTransform: "none",
                    "&:hover": {
                      background: "rgba(237, 29, 36, 1)",
                    },
                  }}
                >
                  {`${notificationsList?.[0]?.unreadCount || 0} Unread`}
                </Button>
              </Box>
              {notificationsList?.[0]?.notifications?.length > 0 ? (
                <>
                  <Box>
                    {filteredNotificationList?.map((detail, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            px: 2,
                            py: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                            borderTop: `1px solid ${theme.colors.text.greyDark}`,
                            width: "100%",
                            background: !detail?.read
                              ? "rgba(30,30,30,1)"
                              : "black",
                          }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <Typography
                              sx={{
                                fontFamily: theme.fonts.livvic,
                              }}
                            >
                              {detail?.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: theme.fonts.livvic,
                                color: !detail?.read
                                  ? theme.colors.text.greyLight
                                  : theme.colors.text.greyDark,
                                fontSize: "14px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {detail?.body}
                            </Typography>
                          </Box>
                          {/* <Close
                      sx={{
                        color: theme.colors.text.greyDark,
                        cursor: "pointer",
                        width: "5%",
                      }}
                      onClick={handleNotificationDelete}
                    /> */}
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderTop: `1px solid ${theme.colors.text.greyDark}`,
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "40px",
                        background: "rgba(14, 14, 14, 1)",
                        color: "white",
                        border: `1px solid ${theme.colors.text.greyDark}`,
                        "&:hover": {
                          background: "rgba(4,4,4,1)",
                        },
                      }}
                      onClick={handleViewAll}
                    >
                      View All
                    </Button>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "250px",
                    gap: 2,
                    color: theme.colors.text.greyDark,
                    borderTop: `1px solid ${theme.colors.text.greyDark}`,
                  }}
                >
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: "5px",
                      background: "rgba(14,14,14,1)",
                      borderRadius: "50%",
                    }}
                  >
                    <NotificationsOff />
                  </Box>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    fontWeight={theme.fonts.fontWeightBold}
                  >
                    No New Notifications
                  </Typography>
                </Box>
              )}
            </Box>
          </Popover>
          <Box
            className="tooltipLikeIcon"
            sx={{
              position: "absolute",
              top: "25px",
              right: "10px",
              width: "20px",
              height: "20px",
              rotate: "45deg",
              background: "black",
              display: notificationOpen ? "block" : "none",
              zIndex: 1000,
              // border: `1px solid ${theme.colors.text.greyDark}`,
            }}
          ></Box>
        </Box>

        <Box sx={{ position: "relative" }}>
          <Tooltip title="Profile">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Box
                component="img"
                src={avatarImage || DashboardAthleteAvatar}
                alt="Avatar"
                id="avatar"
                onError={(e) => {
                  e.target.src = DashboardAthleteAvatar;
                }}
                sx={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              ></Box>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            sx={{
              filter: "drop-shadow(0px 2px 4px rgba(255,255,255,0.4))",
              mt: 1,
              "& .MuiPaper-root": {
                background: "black",
                color: theme.colors.text.greyDark,
              },
            }}
          >
            <MenuItem
              onClick={handleClose}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: 1,
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <Box
                component="img"
                src={avatarImage || DashboardAthleteAvatar}
                alt="Popup Avatar"
                id="popupAvatar"
                onError={(e) => {
                  e.target.src = DashboardAthleteAvatar;
                }}
                sx={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
                onClick={handleClick}
              ></Box>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                }}
              >
                {myProfile?.name}
              </Typography>
            </MenuItem>
            <Divider style={{ background: theme.colors.text.greyDarker }} />
            <MenuItem
              onClick={handleProfile}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: 1,
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <AccountCircleOutlined />
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                }}
              >
                Profile
              </Typography>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <Button
                fullWidth
                sx={{
                  background: theme.colors.background.registerButton,
                  color: "white",
                  height: "40px",
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                }}
                onClick={handleLogout}
              >
                <Logout fontSize="small" color="white" />
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                  }}
                >
                  LOGOUT
                </Typography>
              </Button>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
}
export default DashboardNavbar;
