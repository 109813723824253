import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OnGoingRoiAnalyticschartCard from "../../../../components/afterLogin/brands/roiAnalytics/onGoingRoiAnalyticschart.card";
import { getRoiCampaign } from "../../../../services/product/product.service";
import { ToastContainer, toast } from "react-toastify";
import NoRoiDetailsIcon from "../../../../assets/icons/NoRoiDetailsIcon.svg";

const CompletedRoiAnalyticsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const value = [1, 2, 3];
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleOngoing = () => {
    navigate("/dashboard/analytics");
  };

  useEffect(() => {
    fetchRoiCampaign();
  }, []);

  const fetchRoiCampaign = async () => {
    setLoading(true);
    const response = await getRoiCampaign();
    setLoading(false);

    if (response.status === 200) {
      setFormData(response.data.completed);
    }
  };

  const handleClick = (id, campaignName) => {
    navigate("/dashboard/analytics/roi-details", {
      state: {
        id: id,
        campaignName: campaignName,
      },
    });
  };

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      {" "}
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Grid my="20px" display="flex" alignItems="center">
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", md: "35px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          ml="10px"
          sx={{
            fontSize: { xs: "30px", md: theme.fonts.heading },
          }}
        >
          ROI Analytics
        </Typography>
      </Grid>
      <Grid
        backgroundColor="#121212"
        paddingTop="1%"
        sx={{
          minHeight: { xs: "calc(100vh - 425px)", sm: "calc(100vh - 450px)" },
        }}
      >
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: "3px",
            marginX: "50px",
            marginY: "25px",
            borderRadius: "50px",
            border: "1px solid #900505",
            background:
              "linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, #900505, #900505)",
          }}
        >
          <Button
            onClick={() => handleOngoing()}
            sx={{
              width: "50%",
              borderRadius: "50px",
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              textTransform: "none",
              fontSize: "22px",
              color: "#C5C5C5",

              "@media (max-width:468px)": {
                fontSize: "14px",
              },
            }}
          >
            Ongoing
          </Button>
          <Button
            sx={{
              width: "50%",
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              textTransform: "none",
              fontSize: "22px",
              color: "white",
              background: "red",
              borderRadius: "50px",
              "&:hover": {
                backgroundColor: "darkred",
              },
              "@media (max-width:468px)": {
                fontSize: "14px",
              },
            }}
          >
            Completed
          </Button>
        </Grid>
        <Grid
          container
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          {loading ? (
            <Box display="flex" justifyContent="center" my="100px">
              <CircularProgress sx={{ color: "gray" }} />
            </Box>
          ) : formData?.length > 0 ? (
            formData?.map((details, index) => (
              <OnGoingRoiAnalyticschartCard
                key={index}
                details={details}
                handleClick={handleClick}
              />
            ))
          ) : (
            <Box
              py="45px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              backgroundColor="#1F1F1F"
              width="90%"
              mb="40px"
            >
              <Box
                component="img"
                src={NoRoiDetailsIcon}
                alt="Burrst Logo Icon"
                sx={{
                  mb: "30px",
                  width: "70px",
                }}
                id="NoRoiDetailsIcon"
              ></Box>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={600}
                sx={{ fontSize: { xs: "20px", md: "22px" }, color: "#C5C5C5" }}
              >
                No Completed Campaign
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompletedRoiAnalyticsPage;
