import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import Marquee from "react-fast-marquee";

// import OurPartnersBackground from "../../../../assets/images/OurPartners.png";
import { ourPartnersDetails } from "../../../../_mock/dummyData";
import { PADDING_X } from "../../../../utils/constants";
import { NavLink } from "react-router-dom";

const OurPartners = () => {
  const theme = useTheme();

  // const OurPartnersBackground = `${BASE_IMAGE_URL}/OurPartners.png`;
  return (
    <Box
      sx={{
        height: { xs: "30vh", sm: "40vh" },
        // background: `url(${OurPartnersBackground}) no-repeat`,
        // backgroundSize: "100% 100%",
        borderTop: `1px solid ${theme.colors.divider.red}`,
        padding: {
          xs: `10px 0 10px 10px`,
          sm: `${PADDING_X} 0 ${PADDING_X} ${PADDING_X}`,
        },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography
        // variant="h3"
        pr={3}
        fontFamily={theme.fonts.russoOne}
        color={theme.colors.text.greyDark}
        fontSize={{
          xs: theme.fonts.heading_xs,
          sm: theme.fonts.heading,
          xl: theme.fonts.beforeLogin.heading.xl,
        }}
        borderRight={`1px solid ${theme.colors.divider.red}`}
      >
        Our
        <br /> <span style={{ color: "red" }}>Partners</span>
      </Typography>

      <Marquee pauseOnHover speed={70} delay={2}>
        {ourPartnersDetails?.map((detail, index) => (
          <NavLink
            key={index}
            to={detail?.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src={detail?.imgUrl}
              alt={`Our Partner ${detail?.id}`}
              sx={{
                mr: 10,
                height: "13vh",
                background: "black",
                "@media (max-width:768px)": {
                  height: "8vh",
                },
              }}
            ></Box>
          </NavLink>
        ))}
      </Marquee>
    </Box>
  );
};

export default OurPartners;
