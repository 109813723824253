import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import DemoVideo from "../../assets/TestVideos/DemoVideo.mp4";

import { Helmet } from "react-helmet-async";
import Pulse from "../../themes/@overrides/pulse";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const Demo = () => {
  const theme = useTheme();

  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const handleLoadedVideo = (e) => {
    setIsVideoLoading(false);
  };

  const handleBookDemo = () => {
    window.open(
      "https://calendly.com/grantsapkin/burrst-demo",
      "blank",
      "noopener noreferrer"
    );
  };

  return (
    <>
      <Helmet>
        <title>Demo | Burrst</title>
        <meta
          name="description"
          content="Discover how we help thousands of student-athletes seize NIL opportunities and enable brands to leverage ROI-driven analytics for greater awareness and revenue."
        />
        <link rel="canonical" href={`${HOST_URL}/demo`} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            >
              DEMO
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Demo
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            my: "40px",
            px: { xs: "10px", sm: PADDING_X },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            gap: 4,
            width: "100%",
            maxWidth: "1536px",
          }}
        >
          {/* -----------------------Contents---------------------------------- */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Box
              display="flex"
              justifyContent="left"
              alignItems="center"
              gap={2}
            >
              <Pulse />
              <Typography
                fontFamily={theme.fonts.russoOne}
                color={theme.colors.text.red}
                fontSize={{
                  xs: theme.fonts.heading_xs,
                  sm: theme.fonts.heading,
                  md: theme.fonts.heading_xs,
                  lg: theme.fonts.heading,
                }}
              >
                <span style={{ color: theme.colors.text.greyDark }}>
                  Join The
                </span>{" "}
                Burrst Family
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "16px", sm: "18px", lg: "20px", xl: "22px" },
              }}
            >
              Discover how we are impacting thousands of student-athletes to
              seize NIL opportunities, establish a solid foundation for life
              beyond the game, and enable brands to leverage ROI-driven NIL
              analytics for increased awareness and revenue.
            </Typography>
            <Button
              variant="contained"
              sx={{
                background: "red",
                maxWidth: "170px",
                height: "45px",
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: { xs: "14px", sm: "16px" },
                fontFamily: theme.fonts.livvic,
                "&:hover": {
                  background: theme.colors.divider.red,
                },
              }}
              onClick={handleBookDemo}
            >
              BOOK A DEMO
            </Button>
          </Box>

          {/* -----------------------------Video-------------------------------- */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {/* <ReactPlayer
            url={DemoVideo}
            loop
            controls
            muted
            stopOnUnmount
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              border: `1px solid ${theme.colors.text.greyDarker}`,
            }}
          /> */}
            {isVideoLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <CircularProgress sx={{ color: "white" }} />
              </Box>
            )}

            <video
              autoPlay
              muted
              loop
              controls
              playsInline
              onLoadedData={handleLoadedVideo}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                border: `1px solid ${theme.colors.text.greyDarker}`,
              }}
            >
              <source src={DemoVideo} type="video/mp4" />
            </video>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Demo;
