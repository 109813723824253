import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CampaignsCard from "../subComponents/campaigns/campaignsCard";
import TodaysTaskCard from "../subComponents/todaysTask/todaysTaskCard";
import { dashboardCampaignDetails } from "../../../../../_mock/dummyData";
import Pulse from "../../../../../themes/@overrides/pulse";

import { Swiper, SwiperSlide } from "swiper/react";
import { getMyTasks } from "../../../../../services/campaign/campaign.service";
import NoActiveCampaign from "../../../../../assets/icons/NoActiveCampaign.svg";

const TodaysTask = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [myTasks, setMyTasks] = useState([]);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleViewAll = () => {
    navigate("/dashboard/deal-tracker");
  };

  const fetchCampaignsList = async () => {
    setIsAllDataLoading(true);
    const { data } = await getMyTasks();
    setIsAllDataLoading(false);
    setMyTasks(data?.data);
  };

  useEffect(() => {
    fetchCampaignsList();
  }, []);

  return (
    <Box
      sx={{
        px: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
      }}
    >
      {isAllDataLoading ? (
        <Swiper
          grabCursor={true}
          slidesPerView={2.2}
          initialSlide={0}
          breakpoints={{
            0: { slidesPerView: 1, spaceBetween: 40 },
            500: { slidesPerView: 1.3, spaceBetween: 20 },
            600: { slidesPerView: 1.5, spaceBetween: 20 },
            750: { slidesPerView: 1.8, spaceBetween: 20 },
            900: { slidesPerView: 1.8, spaceBetween: 20 },
            1200: { slidesPerView: 2.5, spaceBetween: 20 },
            1500: { slidesPerView: 3, spaceBetween: 20 },
          }}
        >
          {Array.from({ length: 10 }, (_, index) => (
            <SwiperSlide key={index}>
              <TodaysTaskCard isAllDataLoading={isAllDataLoading} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            mt="60px"
            mb="30px"
          >
            <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
              <Pulse />
              <Typography
                color={theme.colors.common.white}
                fontSize={theme.fonts.heading}
                fontFamily={theme.fonts.russoOne}
              >
                My Tasks
              </Typography>
            </Box>
            <Typography
              fontFamily={theme.fonts.livvic}
              color={theme.colors.text.creamLight}
              sx={{ cursor: "pointer" }}
              onClick={handleViewAll}
            >
              View All
            </Typography>
          </Box>
          {myTasks && myTasks?.length > 0 ? (
            <Swiper
              grabCursor={true}
              slidesPerView={2.2}
              initialSlide={0}
              breakpoints={{
                0: { slidesPerView: 1, spaceBetween: 40 },
                500: { slidesPerView: 1.3, spaceBetween: 20 },
                600: { slidesPerView: 1.5, spaceBetween: 20 },
                750: { slidesPerView: 1.8, spaceBetween: 20 },
                900: { slidesPerView: 1.8, spaceBetween: 20 },
                1200: { slidesPerView: 2.5, spaceBetween: 20 },
                1500: { slidesPerView: 3, spaceBetween: 20 },
              }}
            >
              {myTasks?.slice(0, 10)?.map((detail, index) => (
                <SwiperSlide key={index}>
                  <TodaysTaskCard
                    detail={detail}
                    isAllDataLoading={isAllDataLoading}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
                fontSize: { xs: "24px", sm: "30px" },
                minHeight: "250px",
                width: "100%",
                background: "rgba(18,18,18,1)",
                borderRadius: "5px",
                mt: "30px",
              }}
            >
              <Box
                component="img"
                src={NoActiveCampaign}
                alt="No Active Campaign"
                sx={{ width: { xs: "70px", sm: "100px" } }}
              ></Box>
              No active campaigns yet!
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TodaysTask;
