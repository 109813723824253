import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import TheEcosystemBackground from "../../assets/images/TheEcosystemBackground.png";

import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const Mission = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <Helmet>
        <title>Mission | Burrst</title>
        <meta
          name="description"
          content="Our goal is to enable all student-athletes to profit from their NIL and help brands measure the ROI from collaborating with these athletes effectively."
        />
        <link rel="canonical" href={`${HOST_URL}/mission`} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            >
              MISSION
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Mission
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            // py: "60px",
            px: { xs: "10px", sm: PADDING_X },
            background: `url(${TheEcosystemBackground}) no-repeat`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 2,
            minHeight: "440px",
            width: "100%",
            maxWidth: "1536px",
          }}
        >
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: { xs: "16px", sm: "18px", lg: "20px", xl: "22px" },
            }}
          >
            Our goal is to give all student-athletes opportunities to profit
            from their name, image, and likeness (NIL) and help brands measure
            the returns on their investment in collaborating with athletes.{" "}
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: { xs: "16px", sm: "18px", lg: "20px", xl: "22px" },
            }}
          >
            Through our marketplace platform, we help athletes collaborate with
            brands allowing them to build a strong foundation to benefit from
            even after their athletic career. We recognize the importance of
            analytics and offer brands ROI NIL data, empowering them to gain
            profound insights into their campaigns.
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: { xs: "16px", sm: "18px", lg: "20px", xl: "22px" },
            }}
          >
            Burrst serves as a bridge connecting athletes and brands utilizing
            AI and smart contract technologies, guaranteeing rightful
            compensation for the promotional efforts made by each athlete.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Mission;
