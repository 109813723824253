import axios from "axios";
import { getToken } from "../../routes";
import {
  BASE_API_URL,
  REDIRECT_TO_DASHBOARD,
  REDIRECT_TO_SUBSCRIPTION,
  REDIRECT_TO_USERS_LIST,
  USER_ID,
} from "../../utils/constants";

const base = BASE_API_URL;

const handleError = (error) => {
  if (error?.response?.data?.status === 500) return "Internal Server Error";
  else return error?.response?.data?.message || "Some Error Occurred";
};

export async function registerBrand(path, registrationData) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registrationData),
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}

export async function updateBrandProfile(path, formData) {
  try {
    const url = `${base}${path}`;
    const form = new FormData();
    for (const key of Object.keys(formData)) {
      switch (key) {
        case "brandLogo":
          if (formData?.brandLogo) {
            form.append("brandLogo", formData.brandLogo);
          }
          break;
        case "videoHighlights":
          for (let i = 0; i < formData?.videoHighlights?.length; i++) {
            form.append("videoHighlights", formData?.videoHighlights[i]);
          }
          break;
        case "images":
          for (let i = 0; i < formData?.images?.length; i++) {
            form.append("images", formData?.images[i]);
          }
          break;
        default:
          form.append(key, formData[key]);
          break;
      }
    }
    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      body: form,
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}

export async function athleteList(path, body) {
  try {
    const url = `${base}${path}?${
      body?.search ? `searchBy=name&search=${body?.search}` : ""
    }${body?.filter ? `&filter=${body?.filter}` : ""}${
      body?.sortBy ? `&sortBy=${body?.sortBy}` : ""
    }${body?.sortOrder ? `&sortOrder=${body?.sortOrder}` : ""}${
      body?.page ? `&page=${body?.page}` : ""
    }${body?.size ? `&size=${body?.size}` : ""}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${body?.token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}

export async function getBrandsMyProfileApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}

export const contactSalesApi = async (path) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios.get(url, {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const brandCountApi = async (path) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios.get(url, {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const updatePaymentApi = async (path) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios.put(
      url,
      { redirectUri: REDIRECT_TO_DASHBOARD },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (error) {
    return { data: handleError(error) };
  }
};

export async function getDetailsApi(path, userId) {
  const token = getToken();
  const brandId = !userId ? localStorage.getItem(USER_ID) : userId;
  try {
    const url = `${base}${path}?brandId=${brandId}`;
    const response = await axios(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
}

export async function getListApi(
  path,
  searchBy = "",
  sortBy = "",
  sortOrder = -1
) {
  const token = getToken();

  try {
    const url = `${base}${path}&search=${searchBy}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: "network error" };
  }
}

export async function brandSubscriptionPlansApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function brandSubscribedCheckApi(path) {
  const token = localStorage.getItem("token");
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function startTrialApi(path, body) {
  const token = localStorage.getItem("token");
  try {
    const url = `${base}${path}?subscriptionId=${body}&redirectUri=${encodeURIComponent(
      REDIRECT_TO_SUBSCRIPTION
    )}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function brandtrialRemainingApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function brandBusinessCategoriesApi(path) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function brandChangePlanApi(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        planId: body, // Assuming body is an object with a planId property
      }),
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function brandCancleSubscriptionPlansApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function brandCurrentSubscriptionPlansApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function brandUserCreateApi(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
}

export async function brandUserSubscribeApi(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}?brandUserId=${body}&redirectUri=${encodeURIComponent(
      REDIRECT_TO_USERS_LIST
    )}`;
    const response = await axios(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
}

export async function brandUserListApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
}

export const getFeaturedBrandsApi = async (path) => {
  try {
    const url = `${base}${path}`;
    const response = await axios.get(url);
    return response;
  } catch (error) {
    return error;
  }
};
