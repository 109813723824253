import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const RecentNewsAndArticlesCard = ({ detail }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: theme.fonts.largeBorderRadius,
        mx: "auto",
        height: "430px",
        overflow: "hidden",
        "&:hover": {
          "#recentNewsAndArticleImg": {
            transform: "scale(1.05)",
            transition: "0.5s ease",
          },
        },
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          borderRadius: theme.fonts.largeBorderRadius,
          height: "60%",
        }}
        data-aos="fade-down"
      >
        <Box
          component="img"
          src={detail?.featuredImage}
          alt="Featured"
          sx={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: theme.fonts.largeBorderRadius,
            mb: "10px",
          }}
          id="recentNewsAndArticleImg"
        ></Box>
      </Box>

      <Box
        borderLeft={`1px solid ${theme.colors.text.greyDarker}`}
        paddingLeft={3}
        color={theme.colors.text.creamLight}
        height="40%"
        data-aos="fade-up"
      >
        <Typography mt={2} color={theme.colors.text.greyLight}>
          {dayjs(detail?.createdAt).format("MMM DD YYYY")}
        </Typography>
        <Typography
          mt={2}
          fontWeight={theme.fonts.fontWeightBold}
          color={theme.colors.text.greyLight}
          sx={{
            fontSize: theme.fonts.sectionHeading,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            height: "60px",
          }}
        >
          {detail?.articleTitle}
        </Typography>
        <Link
          to={detail?.originalUrl}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            mt={2}
            variant="h6"
            color={theme.colors.text.red}
            fontWeight={theme.fonts.fontWeightMedium}
            sx={{ cursor: "pointer" }}
          >
            Read More
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default RecentNewsAndArticlesCard;
