import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import DefaultImg from "../../../../assets/images/DefaultAvatar.jpg";
import BurrstLogoIcon from "../../../../assets/icons/BurrstLogoIcon.svg";

const ViewRoiDetailsCard = ({ details }) => {
  const theme = useTheme();
  //   const dataSets = [
  //     {
  //       label: "Instagram post",
  //       data: [2000, 500, 5000, 6000, 10000, 14000, 12000],
  //       color: theme.palette.primary.main,
  //     },
  //     {
  //       label: "Instagram reels",
  //       data: [1000, 7500, 3000, 9000, 4000, 15000, 10000],
  //       color: theme.palette.primary.main,
  //     },
  //     {
  //       label: "Facebook post",
  //       data: [3000, 12000, 6000, 4000, 12000, 11000, 17000],
  //       color: theme.palette.primary.main,
  //     },
  //   ];

  const formatCampaignRevenueData = (details) => {
    let dates = details?.map((item) => item?.date);

    let athletes = details?.map((item) =>
      item?.metrics?.map((it) => ({
        activities: it?.activities,
        deliverableId: it?.deliverableId,
      }))
    );

    let ath = athletes?.flat();

    let uniqueAthleteIds = new Set();

    let uniqueAthletes = ath?.filter((item) => {
      if (!uniqueAthleteIds?.has(item?.deliverableId)) {
        uniqueAthleteIds?.add(item?.deliverableId);
        return true;
      }
      return false;
    });

    let res = {};

    details.map((item) => {
      uniqueAthletes?.map((it) => {
        if (!res[it?.deliverableId]) {
          res[it?.deliverableId] = [];
        }
        let d = item?.metrics?.filter(
          (i) => i?.deliverableId === it?.deliverableId
        );

        if (d?.length) {
          res[it?.deliverableId]?.push(d[0]?.impressions);
        } else {
          res[it?.deliverableId]?.push(0);
        }
      });
      return 0;
    });

    return { dates, athletes: uniqueAthletes, impressions: res };
  };

  const { dates, athletes, impressions } = formatCampaignRevenueData(
    details?.data
  );

  let res = [];

  athletes?.map((item, index) => {
    const color = [
      "#900505",
      "#FF0000",
      "#FF4D00",
      "#FF9900",
      "#F6F97B",
      "#900595",
      "#FF0200",
      "#FF4D10",
      "#FF99E0",
      "#FFF97B",
    ];
    res?.push({
      label: item?.activities,
      data: impressions[item?.deliverableId],
      color: color[index % color.length],
    });
  });

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      background: "#272727",
    },
    colors: [
      "#900505",
      "#FF0000",
      "#FF4D00",
      "#FF9900",
      "#F6F97B",
      "#900595",
      "#FF0200",
      "#FF4D10",
      "#FF99E0",
      "#FFF97B",
    ],
    tooltip: {
      enabled: true,
    },
    theme: {
      mode: "dark",
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: details?.data?.map((item) => item?.date),
      title: {
        text: "",
        style: {
          color: "white",
          fontFamily: theme.fonts.livvic,
        },
      },
      labels: {
        style: {
          colors: "white",
          fontFamily: theme.fonts.livvic,
        },
      },
    },
    yaxis: {
      title: {
        text: "Social media Impression",
        style: {
          color: "white",
          fontFamily: theme.fonts.livvic,
          fontSize: "16px",
        },
      },
      labels: {
        style: {
          colors: "white",
          fontFamily: theme.fonts.livvic,
        },
      },
      min: 0,
      max: Math.max(...res?.flatMap((dataset) => dataset?.data)) + 1000,
    },
    labels: {
      style: {
        fontFamily: theme.fonts.livvic,
        colors: "white",
      },
    },
    legend: {
      labels: {
        colors: "white",
      },
    },
    stroke: {
      curve: "smooth",
    },
  };

  const chartSeries = res?.map((dataset) => ({
    name: dataset?.label,
    data: dataset?.data,
  }));

  console.log("-------------------", res);

  return (
    <Grid
      sx={{
        width: { xs: "90%", md: "43%" },
        mb: "30px",
        mx: "auto",
        borderRadius: "5px",
        background: "#272727",
        overflow: "hidden",
        padding: "10px",
      }}
    >
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Box
          //onClick={() => handleProfile()}
          sx={{
            width: "55px",
            height: "55px",
            position: "relative",
            cursor: "pointer",
            marginLeft: "30px",
            marginTop: "10px",
          }}
        >
          <Box
            component="img"
            src={details?.athleteProfileImage || DefaultImg}
            alt="Avatar"
            id="avatar"
            borderRadius="10px"
            sx={{ width: "100%", height: "100%", border: "2px solid #797979" }}
            onError={(e) => {
              e.target.src = DefaultImg;
            }}
          ></Box>
          <Box
            component="img"
            src={BurrstLogoIcon}
            alt="Burrst Logo Icon"
            sx={{
              width: "35px",
              position: "absolute",
              top: "-35%",
              right: "-30%",
            }}
            id="Burrst Logo Icon"
          ></Box>
        </Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={500}
          ml="10px"
          sx={{ fontSize: { xs: "16px", md: "18px" } }}
        >
          {details?.athleteName}
        </Typography>
      </Box>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
        //color="black"
      />
      <Box sx={{ display: "flex", alignItems: "center", mx: "10px" }}>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: 500,
            fontSize: { xs: "14px", md: "16px" },
          }}
        >
          Activities:
        </Typography>
        {res?.map((data, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              px: "10px",
              py: "5px",
            }}
          >
            <Box
              width="16px"
              height="16px"
              borderRadius="20%"
              backgroundColor={data.color}
              mr="5px"
            />
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "12px", md: "14px" },
              }}
            >
              {data?.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default ViewRoiDetailsCard;
