import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { useDropzone } from "react-dropzone";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";
import BrowseIcon from "../../../../../assets/icons/BrowseIcon.svg";
import ContractFileIcon from "../../../../../assets/icons/ContractFileIcon.svg";
import PDFIcon from "../../../../../assets/icons/PDFIcon.png";
import CrossIcon from "../../../../../assets/icons/CrossIcon.svg";

import CongratulationModal from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/congratulations.modal";

import { contractListItems } from "../../../../../_mock/dummyData";
import {
  createContract,
  getCampaignDetails,
} from "../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BASE_API_URL } from "../../../../../utils/constants";

const CampaignsContract = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const campaignData = location?.state?.campaignData;

  const plan = useSelector((state) => state?.authData?.userData?.plan);
  //const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    campaignId: localStorage.getItem("campaignId"),
    contract: campaignData?.contract ? campaignData?.contract : "",
    reupload: false,
  });
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const uploadedFile = acceptedFiles[0];

      setFormData((prevFormData) => ({
        ...prevFormData,
        contract: uploadedFile,
      }));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "application/pdf": [".doc", ".docx", ".pdf"] },
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    if (formData?.contract instanceof File) {
      try {
        setLoading(true);
        const response = await createContract(formData);
        const form = new FormData();
        form.append("campaignId", formData?.campaignId);

        if (plan === "ALL-STAR" || plan === "SUPERSTAR") {
          const analyzeResponse = await fetch(
            `${BASE_API_URL}/ai/analyzeBrandContract`,
            {
              method: "POST",
              mode: "cors",
              headers: {
                accept: "*/*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: form,
            }
          );
          const jsondata = await analyzeResponse.json();
          setAnalysis(jsondata?.data);
        }

        setLoading(false);
        if (response.status === 200) {
          setOpen(true);
        } else {
          toast.error(response.message || response.status);
        }
      } catch (error) {
        toast.error(error.message || error.status);
      }
    }
    if (campaignData) {
      setOpen(true);
      return;
    }
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleCrossIcon = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contract: null,
    }));
  };

  const dropzoneStyle = () => {
    return {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: 1,
      border: `2px dashed ${theme.colors.text.greyDarker}`,
      padding: "20px",
      cursor: "pointer",
      background: "rgba(29,29,29,1)",
    };
  };

  return (
    <Box
      sx={{
        paddingX: "30px",
        "@media (max-width:600px)": {
          paddingX: "10px",
        },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt="115px"
        mb="30px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Box
            component="img"
            src={LeftArrow}
            alt="Left Arrow"
            width="30px"
            sx={{ cursor: "pointer" }}
            onClick={handleBack}
          ></Box>
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.russoOne}
            variant="h3"
          >
            Contract
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            color: "rgba(175,172,172,1)",
          }}
        >
          Step{" "}
          <span
            style={{
              color: "white",
            }}
          >
            4
          </span>
          /4
        </Typography>
      </Box>
      <Box
        my="30px"
        p="20px"
        overflow="hidden"
        sx={{
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Box
          sx={{
            width: "80%",
            mx: "auto",
            py: "30px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightMedium,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Guidelines for a good contract .
              </Typography>

              <Timeline
                position="left"
                sx={{
                  padding: 0,
                  my: 3,
                  width: "100%",
                  "& li:before": {
                    display: "none",
                  },
                }}
              >
                {contractListItems?.map((element, index) => (
                  <TimelineItem key={index}>
                    <TimelineContent sx={{ textAlign: "left" }}>
                      {element}
                    </TimelineContent>
                    <TimelineSeparator>
                      <TimelineDot sx={{ background: " rgba(255, 0, 0, 1)" }} />
                      {index !== contractListItems?.length - 1 && (
                        <TimelineConnector
                          sx={{
                            background: "transparent",
                            borderRight: "2px dashed rgba(255, 0, 0, 1)",
                          }}
                        />
                      )}
                    </TimelineSeparator>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>

            <Grid item xs={12}>
              {formData?.contract ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    minWidth: "300px",
                    maxWidth: "400px",
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    padding: "10px",
                    background: "rgba(29,29,29,1)",
                    position: "relative",
                  }}
                >
                  <Box
                    component="img"
                    src={
                      campaignData?.contract
                        ? campaignData?.contract?.toLowerCase().endsWith(".pdf")
                          ? PDFIcon
                          : ContractFileIcon
                        : formData?.contract?.name
                            ?.toLowerCase()
                            .endsWith(".pdf")
                        ? PDFIcon
                        : ContractFileIcon
                    }
                    alt="Contract File"
                    width="20%"
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1.5,
                      flexWrap: "wrap",
                      wordBreak: "break-all",
                      pr: 4,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightMedium,
                      }}
                    >
                      {campaignData?.contract
                        ? campaignData?.contract
                        : formData?.contract?.name}
                    </Typography>
                    {!campaignData && (
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontWeight: theme.fonts.fontWeightMedium,
                        }}
                      >{`${(formData?.contract?.size / 1024).toFixed(
                        2
                      )} KB`}</Typography>
                    )}
                  </Box>
                  <Box
                    component="img"
                    src={CrossIcon}
                    alt="Cross Icon"
                    sx={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={handleCrossIcon}
                  ></Box>
                </Box>
              ) : (
                <Box sx={dropzoneStyle} {...getRootProps()}>
                  <Box component="img" src={BrowseIcon} alt="Browse Icon"></Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      color: "rgba(175,172,172,1)",
                    }}
                  >
                    Drag and drop a file here
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      color: "rgba(175,172,172,1)",
                    }}
                  >
                    or
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontSize: "20px",
                      textDecoration: "underline",
                    }}
                  >
                    Browse File
                  </Typography>
                  <input {...getInputProps()} />
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                sx={{
                  borderRadius: "5px",
                  p: "5px 10px",
                  mt: "20px",
                  height: "42px",
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.colors.common.white,
                  background: formData?.contract
                    ? theme.colors.text.red
                    : "rgba(65,65,65,1)",
                  cursor: "pointer",
                  fontWeight: theme.fonts.fontWeightMedium,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    color: theme.colors.text.greyDarker,
                  },
                }}
                disabled={formData?.contract ? false : true}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "gray" }} />
                ) : (
                  "SUBMIT"
                )}
              </Button>
            </Grid>
          </Grid>
          <Dialog
            open={open}
            //onClose={handleModalClose}
            PaperProps={{
              sx: {
                minWidth: "50vw",
                background: theme.colors.dashboard.grey200,
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <CongratulationModal
              handleModalClose={handleModalClose}
              //setFile={setFile}
              analysis={analysis}
              setFormData={setFormData}
            />
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignsContract;
