import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { MdAutorenew } from "react-icons/md";
// import { FaRobot } from "react-icons/fa";
import Joyride from "react-joyride";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";
import { sportList } from "../../../../../services/admin/admin.service";
import {
  createCampaign,
  getCampaignDetails,
  updateCampaign,
} from "../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BASE_API_URL } from "../../../../../utils/constants";

const steps = [
  {
    target: ".input-brief-box",
    content:
      "This is where you need to input a 1-2 line text about your campaign.",
  },
  {
    target: ".ai-button",
    content:
      "Then click on this button, AI will turn this into a detailed description.",
  },
  {
    target: ".change-button",
    content: "If you don't like the output, you can click here to change it.",
  },
];

const CampaignsAdditionalInfo = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const isBack = location?.state?.isBack;
  const campaignId = localStorage.getItem("campaignId");

  const plan = useSelector((state) => state?.authData?.userData?.plan);

  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [suggest, setSuggest] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [campaignDetails, setCampaignDetails] = useState({});

  console.log(campaignDetails, "camapaignDetails------------");

  const handleBack = () => {
    navigate("/dashboard/campaigns/basic-info", {
      state: {
        isBack: true,
      },
    });
  };

  const handleSelectionsChange = (field, value) => {
    setFormData((prevSelections) => ({
      ...prevSelections,
      [field]: value,
    }));
  };

  const handleSportsChange = (field, value) => {
    const isAllSelected = value.some((item) => item === "All");
    const updatedValue = isAllSelected ? ["All"] : value;

    setFormData((prevSelections) => ({
      ...prevSelections,
      [field]: updatedValue,
    }));
    if (isAllSelected && value.length > 1) {
      toast.error("You can't choose other sports when All sports is selected.");
    }
  };

  const handleSuggest = async () => {
    setSuggest(true);
  };

  const handleSuggestSubmit = async () => {
    if (formData?.suggestion === "") {
      toast.error("Please enter some suggestions.");
      return;
    }

    setSuggest(false);
    setLoading(true);
    const data = await fetch(
      `${BASE_API_URL}/ai/generateCampaignDescription?prompt=${formData?.suggestion}&threadId=${generated}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          method: "GET",
          mode: "cors",
        },
      }
    );
    const json = await data.json();
    handleSelectionsChange("brief", json.data.message);
    setLoading(false);
  };

  const handleAI = async () => {
    if (formData?.brief === "") {
      toast.error("Please enter a brief.");
      return;
    }

    setLoading(true);
    const data = await fetch(
      `${BASE_API_URL}/ai/generateCampaignDescription?prompt=${formData?.brief}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          method: "GET",
          mode: "cors",
        },
      }
    );
    const json = await data.json();
    setGenerated(json.data.threadId);
    handleSelectionsChange("brief", json.data.message);
    setLoading(false);
  };
  console.log("===============", formData);
  const handleNext = async () => {
    if (!formData?.sports || !formData?.brief) {
      toast.error("Please fill in all the required fields");
      return;
    }
    setLoadingNext(true);
    if (!formData?.location) {
      delete formData?.location;
    }
    const { suggestion, ...formDataWithoutSuggestion } = formData;
    let response = null;

    if (campaignDetails?.campaignPhase !== 1 && isBack) {
      response = await updateCampaign(formDataWithoutSuggestion);
      if (response?.status === 200) {
        navigate("/dashboard/campaigns/deliverable", {
          state: { isBack: true },
        });
      } else {
        toast.error(response.message || response.status);
      }
    } else {
      response = await createCampaign(formDataWithoutSuggestion);
      if (response?.status === 200) {
        navigate("/dashboard/campaigns/deliverable");
      } else {
        toast.error(response.message || response.status);
      }
    }
    setLoadingNext(false);
  };

  const fetchCampaignData = async () => {
    const response = await getCampaignDetails();
    const updatedData = response?.data;
    setCampaignDetails(response?.data);
    setFormData((preValue) => ({
      ...preValue,
      campaignStage: "2",
      campaignId: updatedData?._id || "",
      sports: updatedData?.sports || [],
      location: updatedData?.location || "",
      brief: updatedData?.brief || "",
    }));
  };

  const fetchSportsOptions = async () => {
    const response = await sportList(true);
    if (response?.status === 200) {
      setSportsOptions(response?.data);
    } else {
      toast.error(response?.message);
    }
  };

  useEffect(() => {
    fetchSportsOptions();
    if (isBack) fetchCampaignData();
    else
      setFormData((preValue) => ({
        ...preValue,
        campaignStage: "2",
        campaignId: campaignId || "",
      }));
  }, []);

  return (
    <Box
      sx={{
        paddingX: "30px",
        "@media (max-width:600px)": {
          paddingX: "10px",
        },
      }}
    >
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt="115px"
        mb="30px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Joyride
            steps={steps}
            debug={true}
            continuous={true}
            disableScrolling={true}
          />
          <Box
            component="img"
            src={LeftArrow}
            alt="Left Arrow"
            width="30px"
            sx={{ cursor: "pointer" }}
            onClick={handleBack}
          ></Box>
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.russoOne}
            fontSize={theme.fonts.heading}
          >
            New Campaign
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            color: "rgba(175,172,172,1)",
          }}
        >
          Step{" "}
          <span
            style={{
              color: "white",
            }}
          >
            2
          </span>
          /4
        </Typography>
      </Box>

      <Box
        my="30px"
        p="20px"
        overflow="hidden"
        sx={{
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Box
          sx={{
            width: "80%",
            mx: "auto",
            py: "30px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Sports
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="sports"
                multiple
                value={formData?.sports || []}
                freeSolo
                onChange={(event, newValue) =>
                  handleSportsChange("sports", newValue)
                }
                options={sportsOptions || []}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                      sx={{
                        color: theme.colors.common.white,
                        backgroundColor:
                          theme.colors.background
                            .autocompleteRenderTagsLightgray,
                      }}
                    />
                  ))
                }
                isOptionEqualToValue={(option, value) => value.includes(option)}
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        color: "white",
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Location{" "}
                <span
                  style={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: "16px",
                    fontWeight: theme.fonts.fontWeightRegular,
                    color: theme.colors.text.greyDark,
                  }}
                >
                  (Optional)
                </span>
              </Typography>

              <TextField
                name="location"
                placeholder="Enter city,state or zip code"
                fullWidth
                // autoComplete="off"
                value={
                  formData?.location !== "undefined" ? formData?.location : ""
                }
                onChange={(e) =>
                  handleSelectionsChange("location", e.target.value)
                }
                InputProps={{
                  sx: {
                    border: `1px solid ${theme.colors.text.greyLight}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "100%",
                    color: theme.colors.common.white,
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "20px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  <Box>
                    Brief{" "}
                    <span
                      style={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: "16px",
                        fontWeight: theme.fonts.fontWeightRegular,
                        color: theme.colors.text.greyDark,
                      }}
                    >
                      (Explain the campaign's purpose and a detailed description
                      of the activities to be carried out.)
                    </span>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {(plan === "ALL-STAR" || plan === "SUPERSTAR") && (
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          height="30px"
                          borderRadius="5px"
                          backgroundColor={theme.colors.background.formInput}
                        >
                          <IconButton
                            disabled={!generated}
                            className="change-button"
                            sx={{
                              // paddingBottom: "7px",
                              cursor: "pointer",
                            }}
                            color={theme.colors.common.white}
                            onClick={handleSuggest}
                          >
                            <MdAutorenew color="white" />
                            <Typography
                              sx={{
                                fontFamily: theme.fonts.livvic,
                                fontSize: "16px",
                                fontWeight: theme.fonts.fontWeightRegular,
                                color: "white",
                              }}
                            >
                              Regenerate
                            </Typography>
                          </IconButton>
                        </Box>

                        <IconButton
                          className="ai-button"
                          color={theme.colors.common.white}
                          onClick={handleAI}
                        >
                          {loading ? (
                            <CircularProgress size={18} color="primary" />
                          ) : (
                            <Box
                              px="8px"
                              py="3px"
                              fontSize="18px"
                              borderRadius="5px"
                              color="white"
                              fontFamily={theme.fonts.livvic}
                              backgroundColor={
                                theme.colors.background.formInput
                              }
                            >
                              {" "}
                              AI
                            </Box>
                          )}
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              <TextField
                name="brief"
                multiline
                rows={10}
                fullWidth
                // autoComplete="off"
                // value={brief || ""}
                // onChange={(e) => setBrief(e.target.value)}
                value={formData?.brief}
                onChange={(e) =>
                  handleSelectionsChange("brief", e.target.value)
                }
                InputProps={{
                  className: "input-brief-box",
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                inputProps={{ className: "input-brief-box" }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              ></TextField>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={suggest}
                // onClick={() => {}}
              >
                <Box display="flex" width="50vw">
                  <TextField
                    name="location"
                    placeholder="Write your suggestion"
                    fullWidth
                    // autoComplete="off"
                    value={formData?.suggestion}
                    onChange={(e) =>
                      handleSelectionsChange("suggestion", e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <Box
                          sx={{
                            borderRadius: "5px",
                            // p: "5px 10px",
                            // mt: "20px",
                            height: "42px",
                            // marginRight: "20px",
                            padding: "8px",
                            width: "80px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: theme.colors.common.white,
                            background: theme.colors.text.red,
                            cursor: "pointer",
                          }}
                          onClick={handleSuggestSubmit}
                        >
                          Submit
                        </Box>
                      ),
                      sx: {
                        border: `1px solid ${theme.colors.text.greyLight}`,
                        background: theme.colors.background.formInput,
                        color: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                      "& input, & textarea, & select": {
                        WebkitTextFillColor: "#FFFFFF",
                        WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                        transition: "background-color 5000s ease-in-out 0s",
                      },
                    }}
                  />
                </Box>
              </Backdrop>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  borderRadius: "5px",
                  p: "5px 10px",
                  mt: "20px",
                  height: "42px",
                  width: "170px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: theme.colors.common.white,
                  background: theme.colors.text.red,
                  cursor: "pointer",
                }}
                onClick={handleNext}
              >
                {loadingNext ? (
                  <CircularProgress sx={{ color: "gray" }} />
                ) : (
                  "Next"
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignsAdditionalInfo;
