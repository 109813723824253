import { getToken } from "../../routes";
import { BASE_API_URL } from "../../utils/constants";

const base = BASE_API_URL;

export const articleFeatured = async (path) => {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
    });

    const results = await response.json();

    if (results.status !== 200) {
      return { success: false, message: results.message };
    }
    return results;
  } catch (error) {
    return { success: false, message: "network error" };
  }
};

export const getListApi = async (path) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (results.status !== 200) {
      return { success: false, message: results.message };
    }
    return results;
  } catch (error) {
    return { success: false, message: "network error" };
  }
};
