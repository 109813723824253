import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

// import BurrstLogo from "../../assets/logo/BurrstLogo.svg";
import VerifiedOtpModal from "../../components/auth/subComponent/verifiedOtpModal";
import {
  athleteRegistrationStage,
  athleteResendOtp,
  athleteVerifyOtp,
} from "../../services/athlete/athlete.service";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";

import LoginBackground from "../../assets/images/BackgrounImage.png";
import { PADDING_X, BASE_IMAGE_URL, OTP_TIMER } from "../../utils/constants";
import EditPhoneNumberModal from "../../components/auth/subComponent/editPhoneNumber.modal";

const OtpVerification = () => {
  const theme = useTheme();
  const location = useLocation();
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  const [phoneNum, setPhoneNum] = useState(null);
  const [countryCodes, setCountryCodes] = useState(null);

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputOtpUseRef = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [editNumberModalOpen, setEditNumberModalOpen] = useState(false);

  const [timer, setTimer] = useState(
    Number(localStorage.getItem("otpTimer")) || OTP_TIMER
  );

  const [formData, setFormData] = useState({
    registrationToken: location?.state?.registrationToken,
    phoneNo: phoneNum,
    phoneOtp: "",
  });

  const [resendOtpData, setResendOtpData] = useState({
    phoneNo: `${countryCodes}${phoneNum}`,
    userId: location?.state?.userId,
  });

  const editNumber = () => {
    setEditNumberModalOpen(true);
  };

  const handleEditNumberModalClose = () => {
    setEditNumberModalOpen(false);
  };

  const handleOtpModalClose = () => {
    setOtpModalOpen(false);
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const updatedOtp = [
      ...otp?.map((data, id) => (id === index ? element.value : data)),
    ];
    setOtp(updatedOtp);

    const phoneOtp = updatedOtp.join("");
    setFormData({
      ...formData,
      phoneOtp,
    });
    if (inputOtpUseRef[index + 1]) {
      inputOtpUseRef[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      event.preventDefault();
      setOtp(otp?.map((d, idx) => (idx === index - 1 ? "" : d)));
      inputOtpUseRef[index - 1].current.focus();
    }
  };

  const handleVerifyOtp = async () => {
    if (otp?.some((field) => field === "")) {
      toast.error("OTP fields cannot be empty.");
      return;
    }
    const response = await athleteVerifyOtp(formData);

    if (response?.status === 200) {
      setOtpModalOpen(true);
    } else if (response?.status === 400) {
      toast.error("The OTP you entered is incorrect.");
    } else {
      toast.error(response?.error?.message);
    }
  };

  const startWorker = (timer) => {
    const worker = new Worker("/timerWorker.js");

    worker.onmessage = (event) => {
      setTimer(event?.data?.timer);
      if (event?.data?.timer <= 0) {
        localStorage.removeItem("otpTimer");
      } else localStorage.setItem("otpTimer", event?.data?.timer.toString());
    };

    worker.postMessage({ timer });
    return worker;
  };

  const handleResendOtp = async () => {
    const response = await athleteResendOtp(resendOtpData);

    if (response?.status === 200) {
      startWorker(OTP_TIMER);
      localStorage.setItem("otpTimer", OTP_TIMER.toString());
      setOtp(new Array(6).fill(""));
      toast.success("OTP send.");
    } else {
      toast.error(response?.message);
    }
  };

  const fetchRegistrationData = async () => {
    const response = await athleteRegistrationStage(
      location?.state?.registrationToken
    );

    if (response?.status === 200) {
      setResendOtpData({
        ...resendOtpData,
        phoneNo: `${response?.data?.user?.countryCode}${response?.data?.user?.phoneNo}`,
      });
      setFormData({
        ...formData,
        phoneNo: `${response?.data?.user?.countryCode}${response?.data?.user?.phoneNo}`,
      });
      setCountryCodes(response?.data?.user?.countryCode);
      setPhoneNum(response?.data?.user?.phoneNo);
    }
  };

  useEffect(() => {
    fetchRegistrationData();

    if (timer <= 0) {
      return;
    }

    const worker = startWorker(timer);

    return () => {
      worker.terminate();
      localStorage.setItem("otpTimer", OTP_TIMER);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Otp Verification | Burrst</title>
      </Helmet>
      <Grid
        sx={{
          backgroundImage: `url(${LoginBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: {
            xs: "cover",
            sm: "cover",
            md: "cover",
            lg: "contain",
          },
          backgroundAttachment: "fixed",
          overflowY: "auto",
          height: "100vh",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            // background: `url(${LoginBackground}) no-repeat`,
            // backgroundSize: "contain",
            mt: "20px",
            mb: "40px",
            paddingX: PADDING_X,
            "@media (max-width:600px)": {
              paddingX: "10px",
            },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              height: { sm: "60vh", md: "80vh", lg: "80vh" },
            }}
          >
            <Box>
              <img
                src={BurrstLogo}
                alt="Burrst Logo"
                width="40%"
                style={{ marginBottom: 15 }}
              />
              <Typography
                color={theme.colors.text.red}
                fontFamily={theme.fonts.russoOne}
                fontWeight={theme.fonts.fontWeightMedium}
                variant="h2"
                sx={{
                  "@media (max-width: 480px)": {
                    fontSize: "36px",
                  },
                }}
              >
                THE
              </Typography>
              <Typography
                color={theme.colors.common.white}
                fontFamily={theme.fonts.russoOne}
                sx={{
                  fontWeight: theme.fonts.fontWeightRegular,
                  fontSize: "60px",
                  "@media (max-width: 480px)": {
                    fontSize: "36px",
                  },
                }}
              >
                NEXT GENERATION
              </Typography>
              {/* <Typography
                color={theme.colors.text.red}
                fontFamily={theme.fonts.russoOne}
                sx={{
                  fontWeight: theme.fonts.fontWeightRegular,
                  fontSize: "60px",
                  "@media (max-width: 480px)": {
                    fontSize: "36px",
                  },
                }}
              >
                OF SUPERSTARS
              </Typography> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //height: "auto",
            }}
          >
            {/* <ToastContainer position="top-right" autoClose={5000} /> */}
            <Box
              width="70%"
              sx={{
                "@media (max-width: 900px)": {
                  width: "100%",
                },
                "@media (min-width:700px) and (max-width: 900px)": {
                  width: "80%",
                },
              }}
            >
              <Typography
                fontFamily={theme.fonts.livvic}
                variant="h4"
                sx={{
                  fontWeight: 900,
                  marginBottom: "5%",
                  "@media (max-width: 480px)": {
                    fontSize: "24px",
                  },
                }}
              >
                OTP Verification
                <span style={{ color: theme.colors.text.red }}>.</span>
              </Typography>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontSize="18px"
                color={theme.colors.text.greyDark}
              >
                Please enter the 6-digit code sent to your phone number
                <br />
                <span
                  style={{
                    fontWeight: theme.fonts.fontWeightMedium,
                    color: theme.colors.text.greyDark,
                  }}
                >
                  {formData?.phoneNo?.includes("+")
                    ? formData?.phoneNo
                    : `+${formData?.phoneNo}`}
                </span>{" "}
                for verification.{" "}
                <span
                  onClick={editNumber}
                  style={{
                    fontWeight: theme.fonts.fontWeightMedium,
                    color: theme.colors.common.white,
                    cursor: "pointer",
                  }}
                >
                  Change phone number?
                </span>
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                textAlign="center"
                gap={2}
                my={3}
              >
                {otp?.map((data, index) => (
                  <TextField
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    inputRef={inputOtpUseRef[index]}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 1,
                      tabIndex: index + 1,
                      sx: {
                        textAlign: "center",
                        padding: "5px",
                        width: "50px",
                        height: "50px",
                        "@media (max-width: 480px)": {
                          height: "30px",
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        background: theme.colors.background.otpForm,
                        color: theme.colors.common.white,
                        borderRadius: "10px",
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightMedium,
                        fontSize: "20px",
                        "@media (max-width: 480px)": {
                          fontSize: "16px",
                        },
                      },
                    }}
                    sx={{
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                          fieldset: {
                            borderColor: theme.colors.common.white,
                          },
                        },
                      },
                    }}
                  />
                ))}
              </Box>
              <Button
                fullWidth
                sx={{
                  background: theme.colors.background.registerButton,
                  color: "white",
                  height: "50px",
                  fontSize: "22px",
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                  borderRadius: "10px",
                  my: 3,
                  cursor: "pointer",
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                }}
                disabled={timer === 0}
                //onClick={() => setOtpModalOpen(true)}
                onClick={() => handleVerifyOtp()}
              >
                Verify
              </Button>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
              >
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize="20px"
                  color={theme.colors.text.greyDark}
                >
                  Didn’t receive any code?{" "}
                  <Box
                    component="span"
                    sx={{
                      fontWeight: theme.fonts.fontWeightMedium,
                      color:
                        timer === 0
                          ? theme.colors.common.white
                          : theme.colors.text.greyDark,
                      cursor: timer === 0 ? "pointer" : "default",
                    }}
                    onClick={timer === 0 ? handleResendOtp : null}
                  >
                    Resend again
                  </Box>
                </Typography>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize="20px"
                  color={theme.colors.text.greyDark}
                >
                  Request new code in{" "}
                  <span
                    style={{
                      color: theme.colors.text.red,
                      fontWeight: theme.fonts.fontWeightMedium,
                    }}
                  >
                    {timer} sec
                  </span>
                </Typography>
              </Box>
              <Dialog
                open={otpModalOpen}
                //onClose={handleOtpModalClose}
                PaperProps={{
                  sx: {
                    width: "80vw",
                    background: theme.colors.common.black,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                sx={{
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgb(255,255,255,0.3)",
                }}
              >
                <VerifiedOtpModal handleOtpModalClose={handleOtpModalClose} />
              </Dialog>
              <Dialog
                open={editNumberModalOpen}
                onClose={handleEditNumberModalClose}
                PaperProps={{
                  sx: {
                    width: "80vw",
                    background: theme.colors.common.black,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                sx={{
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgb(255,255,255,0.3)",
                }}
              >
                <EditPhoneNumberModal
                  formData={formData}
                  setFormData={setFormData}
                  resendOtpData={resendOtpData}
                  setResendOtpData={setResendOtpData}
                  fetchRegistrationData={fetchRegistrationData}
                  handleEditNumberModalClose={handleEditNumberModalClose}
                />
              </Dialog>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OtpVerification;
