import { useTheme } from "@emotion/react";
import { Skeleton } from "@mui/material";
import React from "react";

const CustomSkeleton = ({ type, count = 1, sx }) => {
  const theme = useTheme();
  switch (type) {
    case "img":
      return (
        <Skeleton
          animation="pulse"
          variant="rectangular"
          sx={{
            bgcolor: theme.colors.text.greyDarker,
            "&::after": { bgcolor: theme.colors.text.greyDark },
            width: "100px",
            height: "100px",
            ...sx,
          }}
        />
      );
    case `p`:
      return Array.from({ length: count }, (_, index) => (
        <Skeleton
          key={index}
          animation="wave"
          variant="text"
          sx={{
            bgcolor: theme.colors.text.greyDarker,
            "&::after": { bgcolor: theme.colors.text.greyDark },
            minWidth: "100px",
            maxWidth: "150px",
            height: "15px",
            ...sx,
          }}
        />
      ));
    case `circle`:
      return Array.from({ length: count }, (_, index) => (
        <Skeleton
          key={index}
          animation="pulse"
          variant="circular"
          sx={{
            bgcolor: theme.colors.text.greyDarker,
            "&::after": { bgcolor: theme.colors.text.greyDark },
            width: "30px",
            height: "30px",
            ...sx,
          }}
        />
      ));

    default:
      return Array.from({ length: count }, (_, index) => (
        <Skeleton
          key={index}
          animation="pulse"
          variant="rectangular"
          sx={{
            bgcolor: theme.colors.text.greyDarker,
            "&::after": { bgcolor: theme.colors.text.greyDark },
            width: "200px",
            height: "200px",
            ...sx,
          }}
        />
      ));
  }
};

export default CustomSkeleton;
