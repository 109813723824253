import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { Circle, ExpandMore, Search } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

import SupportAgentIcon from "../../../../../assets/icons/SupportAgentIcon.svg";
import EducationBackground from "../../../../../assets/images/EducationBackground.png";
import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";
import { getFAQs } from "../../../../../services/common/faqs/faq.service";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const FAQs = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const userType = localStorage?.getItem("usertype");

  const [keyword, setKeyword] = useState("");

  const [faqs, setFaqs] = useState(null);

  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleBack = () => {
    navigate(-1);
  };

  const handleKeywordSearch = (e) => {
    setKeyword(e.target.value);
  };

  const filteredAccordionData = faqs?.filter((data, index) => {
    return data?.question?.toLowerCase().includes(keyword.toLowerCase());
  });

  const fetchFAQs = async () => {
    const response = await getFAQs(userType);
    setIsAllDataLoading(false);
    setFaqs(response?.data?.data);
  };
  useEffect(() => {
    fetchFAQs();
  }, []);

  return (
    <Box
      sx={{
        paddingX: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
      }}
    >
      {/* -----------------Header------------------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          mb: "30px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            component="img"
            src={LeftArrow}
            alt="Left Arrow"
            sx={{ cursor: "pointer", width: { xs: "30px", sm: "40px" } }}
            onClick={handleBack}
          ></Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: theme.fonts.heading,
              fontWeight: theme.fonts.fontWeightMedium,
            }}
          >
            FAQs
          </Typography>
        </Box>
        {isAllDataLoading ? (
          <CustomSkeleton type="circle" />
        ) : (
          <Box
            component="img"
            src={SupportAgentIcon}
            alt="Support Agent Icon"
            sx={{ cursor: "pointer", width: "24px" }}
            onClick={() => navigate("/dashboard/chat/admin")}
          ></Box>
        )}
      </Box>

      <Box
        p="20px"
        mb="30px"
        sx={{
          background: theme.colors.dashboard.grey200,
          overflow: "hidden",
        }}
      >
        {/* -------------------Search------------------- */}
        <Box>
          {isAllDataLoading ? (
            <CustomSkeleton
              sx={{
                width: "100%",
                height: "40px",
                borderRadius: "5px",
              }}
            />
          ) : (
            <TextField
              name="search"
              value={keyword}
              onChange={handleKeywordSearch}
              placeholder="Enter your keyword"
              fullWidth
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: theme.colors.text.greyLight }} />
                  </InputAdornment>
                ),
                sx: {
                  // border: `1px solid ${theme.colors.text.greyDarker}`,
                  background: "rgba(56,56,56,1)",
                  color: theme.colors.common.white,
                  borderRadius: "5px",
                  height: "45px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    fieldset: {
                      borderColor: theme.colors.common.white,
                    },
                  },
                },
              }}
            />
          )}
        </Box>

        {/* ---------------------Accordion Container------------------------- */}

        <Box sx={{ mt: "20px" }}>
          <Box
            sx={{
              "& > .MuiAccordion-root:not(:last-child)": {
                marginBottom: "16px",
              },
            }}
          >
            {isAllDataLoading
              ? Array.from({ length: 5 }, (_, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      py: "10px",
                      borderRadius: "5px",
                    }}
                    defaultExpanded={index === 0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore sx={{ color: "white" }} />}
                    >
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: theme.fonts.sectionHeading,
                          width: "100%",
                        }}
                      >
                        <CustomSkeleton
                          type="p"
                          sx={{
                            minWidth: "90%",
                          }}
                        />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          color: theme.colors.text.greyLight,
                        }}
                      >
                        <CustomSkeleton type="p" count={2} sx={{ mb: 0.5 }} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              : filteredAccordionData?.map((accordion, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      backgroundColor: "black",
                      color: "white",
                      py: "10px",
                      borderRadius: "5px",
                    }}
                    defaultExpanded={index === 0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore sx={{ color: "white" }} />}
                    >
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontSize: theme.fonts.sectionHeading,
                        }}
                      >
                        {accordion?.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          color: theme.colors.text.greyLight,
                        }}
                      >
                        {accordion?.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FAQs;
