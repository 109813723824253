import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import EditIcon from "../../../../../assets/icons/Edit.svg";
import ShareIcon from "../../../../../assets/icons/Share.svg";
import BrandImage from "../../../../../assets/images/BrandAdidas.svg";
import RightArrow from "../../../../../assets/images/RightArrow.svg";
import SelectedAthleteIcon from "../../../../../assets/icons/BrandCampaignSelectedAthleteIcon.svg";
import MyContractIcon from "../../../../../assets/icons/ViewContractIcon.svg";
import ActivitiesDetailsIcon from "../../../../../assets/icons/BrandCampaignActivityDetailsIcon.svg";
import { getCampaignDetails } from "../../../../../services/campaign/campaign.service";
import CircleIcon from "@mui/icons-material/Circle";
import { HOST_URL } from "../../../../../utils/constants";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ContentCopyRounded,
  LibraryAddCheckRounded,
} from "@mui/icons-material";

const CampaignViewDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const { campaignId } = useParams();
  const [urlModalOpen, setUrlModalOpen] = useState(false);
  const [shortURL, setShortURL] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const longURL = `${HOST_URL}/dashboard/campaigns/view-details/${campaignId}`;

  const handleShareIcon = async () => {
    try {
      const response = await axios.get(
        `https://is.gd/create.php?format=json&url=${encodeURIComponent(
          longURL
        )}`
      );
      const shortURL = response?.data?.shorturl;
      if (shortURL) {
        setUrlModalOpen(true);
        setShortURL(shortURL);
      } else {
        toast.error(response?.data?.errormessage);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleUrlModalClose = () => {
    setUrlModalOpen(false);
    setShortURL("");
  };

  const handleCopyToClipboard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(shortURL);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    setTimeout(() => {
      handleUrlModalClose();
    }, 1500);
  };

  useEffect(() => {
    setShowTooltip(true);
    const timeoutId = setTimeout(() => {
      setShowTooltip(false);
    }, 8000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleViewApplicants = () => {
    navigate("/dashboard/campaigns/view-applicants");
  };

  const handleAiRecommended = () => {
    navigate("/dashboard/campaigns/ai-recommended");
  };

  const handleSelectedAthlete = () => {
    navigate("/dashboard/campaigns/selected-athlete");
  };

  const handleActivityDetails = () => {
    navigate("/dashboard/campaigns/activity-details");
  };

  const handleContract = () => {
    if (campaignData.contract) {
      window.open(campaignData.contract, "_blank");
    } else {
      console.error("Contract link is not available");
    }
  };

  const handleEdit = () => {
    navigate("/dashboard/campaigns/basic-info", {
      state: {
        isBack: true,
      },
    });
  };

  const fetchCampaignDetails = async () => {
    try {
      const response = await getCampaignDetails(campaignId);

      if (response.status === 200) {
        setCampaignData(response.data);
      } else {
        console.error("Failed to fetch campaigns details:", response.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns details:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("campaignId", campaignId);
    fetchCampaignDetails();
  }, []);

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:600px)": {
          paddingX: "10px",
        },
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "35px", md: "50px" },
              color: "white",
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row">
          {(campaignData?.status === "pending" ||
            campaignData?.status === "rejected") && (
            <Box
              onClick={handleEdit}
              component="img"
              src={EditIcon}
              alt="Edit Icon"
              sx={{
                width: { xs: "25px", md: "30px" },
                cursor: "pointer",
                marginRight: "20px",
              }}
            />
          )}
          <Box
            onClick={handleShareIcon}
            component="img"
            src={ShareIcon}
            alt="Edit Icon"
            sx={{ width: { xs: "22px", md: "28px" }, cursor: "pointer" }}
          />
          <Dialog
            open={urlModalOpen}
            onClose={handleUrlModalClose}
            PaperProps={{
              sx: {
                minWidth: { xs: "90vw", sm: "400px", md: "500px" },
                background: "black",
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography fontFamily={theme.fonts.livvic}>
                  Share URL
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    background: "black",
                    padding: "5px 10px",
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    borderRadius: "5px",

                    maxWidth: { xs: "200px", sm: "250px", md: "300px" },
                    color: "white",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {shortURL}
                  </Box>
                  {isCopied ? (
                    <LibraryAddCheckRounded />
                  ) : (
                    <ContentCopyRounded
                      onClick={handleCopyToClipboard}
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </Grid>
      <Grid backgroundColor="#121212" padding="3.5%">
        <Grid
          display="flex"
          flexDirection="row"
          justifyContent="left"
          alignItems="center"
          sx={{ flexWrap: { xs: "wrap", md: "none" } }}
        >
          <Box
            width="200px"
            // component="img"
            // src={BrandImage}
            // maxWidth="120px"
            // alt="Campaign Brand"
            // id="brandImg"
            //mt={3}
          >
            <Box
              component="img"
              //src={BrandImage}
              src={campaignData?.brandLogo}
              alt="Campaign Brand"
              padding="15px"
              backgroundColor="#191A1C"
              sx={{
                width: "180px",
                height: "180px",
                cursor: "pointer",
                marginRight: "20px",
              }}
            />
          </Box>
          <Box pl="20px" sx={{ width: { xs: "95%", md: "80%" } }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontSize="22px"
                mb="1%"
                fontWeight={theme.fonts.fontWeightBold}
                fontFamily={theme.fonts.livvic}
              >
                {campaignData?.campaignName}
              </Typography>
              {campaignData?.status === "approved" && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    background: "#24B82170",
                    px: "5px",
                    borderRadius: "30px",
                  }}
                >
                  <CircleIcon
                    fontSize="20px"
                    sx={{ marginRight: "4px", color: "#05FE00" }}
                  />
                  <Typography
                    mr="4px"
                    sx={{ fontSize: { xs: "12px", md: "14px" } }}
                  >
                    {" "}
                    Active
                  </Typography>
                </Box>
              )}
              {campaignData?.status === "rejected" && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    background: "#9e3838",
                    px: "5px",
                    borderRadius: "30px",
                  }}
                >
                  <CircleIcon
                    fontSize="20px"
                    sx={{ marginRight: "4px", color: "#FF0000" }}
                  />
                  <Typography
                    mr="4px"
                    sx={{ fontSize: { xs: "12px", md: "14px" } }}
                  >
                    {" "}
                    Rejected
                  </Typography>
                </Box>
              )}
              {campaignData?.status === "pending" && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    background: "#FD851770",
                    px: "5px",
                    borderRadius: "30px",
                  }}
                >
                  <CircleIcon
                    fontSize="20px"
                    sx={{ marginRight: "4px", color: "#F44515" }}
                  />
                  <Typography
                    mr="4px"
                    sx={{ fontSize: { xs: "12px", md: "14px" } }}
                  >
                    {" "}
                    Pending
                  </Typography>
                </Box>
              )}
            </Box>
            {campaignData?.endDate && (
              <Typography
                fontSize="16px"
                mb="1%"
                color="#C5C5C5"
                fontWeight={500}
                fontFamily={theme.fonts.livvic}
              >
                End date- {new Date(campaignData?.endDate).toLocaleDateString()}
              </Typography>
            )}
            <Box
              maxWidth="100%"
              maxHeight="100px"
              sx={{
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "2px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "red",
                  borderRadius: "4px",
                },
              }}
            >
              <Typography
                fontSize="15px"
                display="flex"
                flexWrap="wrap"
                color="#C5C5C5"
                fontWeight={theme.fonts.fontWeightRegular}
                fontFamily={theme.fonts.livvic}
                sx={{ wordBreak: "break-all" }}
              >
                {campaignData?.brief || ""}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          container
          paddingTop="30px"
          paddingBottom="20px"
          display="flex"
          justifyContent="center"
        >
          <Grid
            mt="1%"
            item
            xs={10}
            md={6}
            display="flex"
            justifyContent="left"
          >
            <Button
              onClick={() => handleViewApplicants()}
              sx={{
                width: "94%",
                border: "1px solid #797979",
                textTransform: "none",
                borderRadius: "10px",
                background: "#100e0e",
                color: "#FF0101",
                fontFamily: theme.fonts.montserrat,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "22px",
                "&:hover": {
                  backgroundColor: "black",
                },
                "@media (max-width:600px)": {
                  fontSize: "18px",
                },
              }}
            >
              View Applicants
            </Button>
          </Grid>
          <Grid
            mt="1%"
            item
            xs={10}
            md={6}
            display="flex"
            justifyContent="left"
          >
            <Button
              onClick={() => handleAiRecommended()}
              sx={{
                width: "95%",
                border: "1px solid #797979",
                textTransform: "none",
                borderRadius: "10px",
                background: "#100e0e",
                color: "#FF0101",
                fontFamily: theme.fonts.montserrat,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "22px",
                "&:hover": {
                  backgroundColor: "black",
                },
                "@media (max-width:600px)": {
                  fontSize: "18px",
                },
              }}
            >
              AI Recommendations
            </Button>
          </Grid>
        </Grid>
        <Grid pr="30px">
          <Typography
            mt="15px"
            fontSize="20px"
            fontWeight={theme.fonts.fontWeightRegular}
            fontFamily={theme.fonts.livvic}
            sx={{
              color: "#C5C5C5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            Compensation
            {/* <Box
              color="#FF0101"
              component="span"
              fontWeight={theme.fonts.fontWeightRegular}
            >
              ${campaignData?.totalCompensation}
            </Box> */}
            {campaignData.totalCompensation ? (
              <Box
                component="span"
                sx={{
                  color: "#FF0101",
                }}
              >
                ${campaignData.totalCompensation}
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  color: "#FF0101",
                }}
              >
                {campaignData.sharePercentage?.toFixed(2)}% of monthly revenue
              </Box>
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                borderRadius: "4px",
                py: "4px",
                px: "15px",
                backgroundColor: "#F7BF2E",
                color: "black",
                fontSize: "16px",
                fontFamily: theme.fonts.livvic,
              }}
            >
              10% platform fee will be deducted at the time of the payment
            </Typography>
          </Box>
          <Typography
            my="15px"
            fontSize="20px"
            fontWeight={theme.fonts.fontWeightRegular}
            fontFamily={theme.fonts.livvic}
            sx={{
              color: "#C5C5C5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            Number of Applicants
            <Box
              color="#FF0101"
              component="span"
              fontWeight={theme.fonts.fontWeightRegular}
            >
              {campaignData?.applications?.length}
            </Box>
          </Typography>
          <Typography
            my="15px"
            fontSize="20px"
            fontWeight={theme.fonts.fontWeightRegular}
            fontFamily={theme.fonts.livvic}
            sx={{
              color: "#C5C5C5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            Number of Athlete(s)
            <Box
              component="span"
              fontWeight={theme.fonts.fontWeightRegular}
              sx={{
                color: "white",
              }}
            >
              {campaignData?.multipleAthletes
                ? "Multiple Athletes"
                : "Single Athlete"}
            </Box>
          </Typography>
          <Typography
            my="15px"
            fontSize="20px"
            fontWeight={theme.fonts.fontWeightRegular}
            fontFamily={theme.fonts.livvic}
            sx={{
              color: "#C5C5C5",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            Campaign Type
            <Box
              component="span"
              fontWeight={theme.fonts.fontWeightRegular}
              sx={{
                color: "white",
              }}
            >
              {campaignData?.campaignType}
            </Box>
          </Typography>
          {/* <Typography
            my="15px"
            fontSize="20px"
            fontWeight={theme.fonts.fontWeightRegular}
            fontFamily={theme.fonts.livvic}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media (max-width:600px)": {
                fontSize: "16px",
              },
            }}
          >
            Additional skill and expertise
            <Box component="span" fontWeight={theme.fonts.fontWeightRegular}>
              {campaignData?.additionalSkills}
            </Box>
          </Typography> */}
          {campaignData?.location && campaignData?.location !== "undefined" && (
            <Typography
              my="15px"
              fontSize="20px"
              fontWeight={theme.fonts.fontWeightRegular}
              fontFamily={theme.fonts.livvic}
              sx={{
                color: "#C5C5C5",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width:600px)": {
                  fontSize: "16px",
                },
              }}
            >
              Location
              <Box
                component="span"
                fontWeight={theme.fonts.fontWeightRegular}
                sx={{
                  color: "white",
                }}
              >
                {campaignData?.location}
              </Box>
            </Typography>
          )}
        </Grid>
        <Grid
          container
          paddingTop="30px"
          paddingBottom="20px"
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Grid
            mt="1%"
            item
            xs={10}
            md={4}
            display="flex"
            justifyContent="left"
          >
            <Button
              onClick={handleContract}
              sx={{
                width: "95%",
                border: "1px solid #797979",
                textTransform: "none",
                borderRadius: "10px",
                background: "#100e0e",
                color: "white",
                fontFamily: theme.fonts.montserrat,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: { xs: "18px", sm: "22px" },
                "&:hover": {
                  backgroundColor: "black",
                },
              }}
            >
              <img
                src={MyContractIcon}
                alt="Selected Athlete"
                style={{
                  backgroundColor: "black",
                  borderRadius: "50%",
                  marginRight: "15px",
                }}
              />
              View Contract
              <img
                src={RightArrow}
                alt="Selected Athlete"
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </Grid>
          <Grid
            mt="1%"
            item
            xs={10}
            md={4}
            display="flex"
            //justifyContent="left"
          >
            <Button
              onClick={() => handleSelectedAthlete()}
              sx={{
                width: "95%",
                border: "1px solid #797979",
                textTransform: "none",
                borderRadius: "10px",
                background: "#100e0e",
                color: "white",
                fontFamily: theme.fonts.montserrat,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "22px",
                "&:hover": {
                  backgroundColor: "black",
                },
                "@media (max-width:600px)": {
                  fontSize: "18px",
                },
              }}
            >
              <img
                src={SelectedAthleteIcon}
                alt="Selected Athlete"
                style={{
                  backgroundColor: "black",
                  borderRadius: "50%",
                  padding: "6px",
                  marginRight: "15px",
                }}
              />
              Selected Athletes
              <img
                src={RightArrow}
                alt="Selected Athlete"
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </Grid>

          <Grid
            mt="1%"
            item
            xs={10}
            md={4}
            display="flex"
            justifyContent="left"
          >
            <Button
              onClick={() => handleActivityDetails()}
              sx={{
                width: "95%",
                border: "1px solid #797979",
                textTransform: "none",
                borderRadius: "10px",
                background: "#100e0e",
                color: "white",
                fontFamily: theme.fonts.montserrat,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "22px",
                "&:hover": {
                  backgroundColor: "black",
                },
                "@media (max-width:600px)": {
                  fontSize: "18px",
                },
              }}
            >
              <img
                src={ActivitiesDetailsIcon}
                alt="Selected Athlete"
                style={{
                  backgroundColor: "black",
                  borderRadius: "50%",
                  padding: "6px",
                  marginRight: "15px",
                }}
              />
              Activities Details
              <img
                src={RightArrow}
                alt="Selected Athlete"
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignViewDetails;
