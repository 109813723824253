import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";
import SupportAgentIcon from "../../../../../assets/icons/SupportIcon.svg";
import { Helmet } from "react-helmet-async";
import Pulse from "../../../../../themes/@overrides/pulse";
import ReplayIcon from "@mui/icons-material/Replay";
import dayjs from "dayjs";
import { retryPayment } from "../../../../../services/accounts/accounts.service";

const MySubscriptionTransactionPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.value;
  const currentPlan = location?.state?.currentPlan;

  const handleBack = () => {
    navigate(-1);
  };

  const handleRetry = async (id) => {
    // const response = await retryPayment(id);
  };

  const handleSupport = () => {
    navigate("/dashboard/chat/admin");
  };

  return (
    <>
      <Helmet>
        <title>Transactions | Burrst</title>
      </Helmet>
      <Box
        sx={{
          paddingX: { xs: "10px", sm: "50px" },
          mt: { xs: "95px", sm: "100px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              mr="15px"
              component="img"
              src={LeftArrow}
              alt="Left Arrow"
              sx={{ cursor: "pointer", width: { xs: "22px", sm: "25px" } }}
              onClick={handleBack}
            ></Box>
            <Pulse />

            <Typography
              sx={{
                mt: "22px",
                ml: "5px",
                fontFamily: theme.fonts.livvic,
                fontSize: {
                  xs: theme.fonts.subHeading_xs,
                  sm: theme.fonts.subHeading,
                },
                fontWeight: theme.fonts.fontWeightBold,
              }}
            >
              Transaction {data?.status === "success" ? "Successful" : "Failed"}
              <Typography sx={{ color: "#A0A0A0", fontSize: "14px" }}>
                {dayjs(data?.created)?.format("hh:mm A [on] MMM DD YYYY")}
              </Typography>
            </Typography>
          </Box>
          <Box
            component="img"
            onClick={handleSupport}
            src={SupportAgentIcon}
            alt="Support Agent Icon"
            sx={{ width: { xs: "18px", sm: "20px" }, cursor: "pointer" }}
          ></Box>
        </Box>
        <Box
          p="40px"
          mb="30px"
          mt="10px"
          sx={{
            background: theme.colors.dashboard.grey200,
            overflow: "hidden",
          }}
        >
          {data?.status !== "success" && (
            <Box
              sx={{
                border: `1px solid ${theme.colors.text.greyDark}`,
                background: "black",
                mb: "30px",
                p: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 400,
                }}
              >
                No amount was debited from your account for this failed payment.
                You can try again now.
              </Typography>
            </Box>
          )}
          {/* {transactionType === CREDIT ? ( */}
          <Box
            sx={{
              border: `1px solid ${theme.colors.text.greyDark}`,
              background: "black",
              mb: "30px",
            }}
          >
            <Box
              sx={{
                p: "20px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionHeading,
                    mb: 1,
                    mx: "10px",
                  }}
                >
                  Plan Details
                </Typography>
                {data?.status === "success" && (
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: "14px",
                      fontWeight: 600,
                      borderRadius: "5px",
                      px: "20px",
                      mb: 1,
                      mr: "15px",
                      color: "#383838",
                      backgroundColor: "#10D20C",
                    }}
                  >
                    Active
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  background: theme.colors.dashboard.grey200,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  padding: "12px",
                  mx: "8px",
                  mb: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                    }}
                  >
                    Amount
                  </Typography>
                  <Typography
                    sx={{
                      color: "#10D20C",
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      fontWeight: theme.fonts.fontWeightBold,
                    }}
                  >
                    ${data?.amount_paid}
                  </Typography>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                    }}
                  >
                    Duration
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      fontWeight: theme.fonts.fontWeightBold,
                    }}
                  >
                    {currentPlan?.planName === "yearly" ? "Yearly" : "Monthly"}
                  </Typography>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                    }}
                  >
                    Start date
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                    }}
                  >
                    {dayjs(currentPlan?.period_start)?.format("MM/DD/YYYY")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                    }}
                  >
                    End date
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                    }}
                  >
                    {dayjs(currentPlan?.period_end)?.format("MM/DD/YYYY")}
                  </Typography>
                </Box>
              </Box>
              {data?.status !== "success" && (
                <Box display="flex" justifyContent="end" mx="8px">
                  <Button
                    variant="contained"
                    onClick={() => handleRetry(data?.transactionId)}
                    sx={{
                      background: "red",
                      width: { xs: "160px", sm: "260px" },
                      mt: 1,
                      height: "45px",
                      textTransform: "none",
                      fontWeight: theme.fonts.fontWeightBold,
                      fontSize: { xs: "14px", sm: "18px" },
                      fontFamily: theme.fonts.livvic,
                      "&:hover": {
                        background: theme.colors.divider.red,
                      },
                    }}
                  >
                    <ReplayIcon style={{ marginRight: "5px" }} /> Retry
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              border: `1px solid ${theme.colors.text.greyDark}`,
              background: "black",
              mb: "10px",
              p: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "18px", md: "20px" },
                fontWeight: 500,
              }}
            >
              Benefits included
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 400,
              }}
            >
              <span
                style={{
                  color: "#900505",
                  marginRight: "8px",
                  fontSize: "25px",
                }}
              >
                &#8226;
              </span>
              Access to thousands of top athletes on the platform.
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 400,
              }}
            >
              <span
                style={{
                  color: "#900505",
                  marginRight: "8px",
                  fontSize: "25px",
                }}
              >
                &#8226;
              </span>
              Unlimited number of campaigns for promoting your brand.
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 400,
              }}
            >
              <span
                style={{
                  color: "#900505",
                  marginRight: "8px",
                  fontSize: "25px",
                }}
              >
                &#8226;
              </span>
              Safe and secure contracts through the use of blockchain
              technology.
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 400,
              }}
            >
              <span
                style={{
                  color: "#900505",
                  marginRight: "8px",
                  fontSize: "25px",
                }}
              >
                &#8226;
              </span>
              Secure monetary transactions achieved using Stripe.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MySubscriptionTransactionPage;
