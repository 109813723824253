import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DefaultImg from "../../../../assets/images/DefaultAvatar.jpg";
import AddUserModal from "../../../../components/afterLogin/brands/users/addUser.modal";
import {
  brandUserList,
  brandUserSubscribe,
} from "../../../../services/brand/brand.service";
import CloseIcon from "@mui/icons-material/Close";

const UsersListPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState(null);
  const [subscribeDialog, setSubscribeDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const isAdmin = localStorage.getItem("isBrandAdmin");

  const handleBack = () => {
    navigate(-1);
  };

  const handleCoverImg = (e) => {
    e.target.src = DefaultImg;
  };

  const handleAdd = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubscribeDialogClose = () => {
    setSubscribeDialog(false);
  };

  const handleSubscribeDialog = (id) => {
    setUserId(id);
    setSubscribeDialog(true);
  };

  const handleSubacribe = async () => {
    setLoading(true);
    const result = await brandUserSubscribe(userId);
    setLoading(false);
    if (result?.status === 200) {
      handleSubscribeDialogClose();
      toast.success("Subscription payment done successfully");
      callUserList();
    } else if (result?.status === 400) {
      if (result?.data?.link) window.open(`${result?.data?.link}`, "_self");
    } else if (result?.status === 500) {
      alert(
        "Subscription failed. Please change your payment details from profile & try again"
      );
    } else {
      toast.error(result);
    }
  };

  const callUserList = async () => {
    const response = await brandUserList();
    if (response?.status === 200) {
      setData(response?.data);
    } else toast.error(response);
  };

  useEffect(() => {
    callUserList();
  }, []);

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      {" "}
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Grid
        my="20px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box>
            <ArrowBackIcon
              onClick={handleBack}
              sx={{
                cursor: "pointer",
                fontSize: { xs: "30px", md: "35px" },
                color: "white",
              }}
            />
          </Box>
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightMedium}
            ml="10px"
            sx={{
              fontSize: { xs: "30px", md: theme.fonts.heading },
            }}
          >
            Users
          </Typography>
        </Box>
        {isAdmin === "true" && (
          <AddIcon
            onClick={handleAdd}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "35px", md: "40px" },
              color: "white",
            }}
          />
        )}
      </Grid>
      <Grid
        backgroundColor="#121212"
        paddingTop="1%"
        sx={{
          minHeight: { xs: "calc(100vh - 425px)", sm: "calc(100vh - 450px)" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", mt: "15px" }}>
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              borderRight: "2px solid #AB0404",
              borderLeft: "2px solid #AB0404",
              width: "95%",
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "30%",
                height: "2px",
                background: "#AB0404",
                // animation: "top-bottom-boxBorderMove 8s linear infinite",
              },
              "::after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "30%",
                height: "2px",
                background: "#AB0404",
                // animation: "top-bottom-boxBorderMove 8s linear infinite",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                px: "20px",
                py: "10px",
                borderTop: " 1px solid #383838",
                borderBottom: " 1px solid #383838",
              }}
            >
              <Typography
                sx={{
                  fontWeight: theme.fonts.fontWeightRegular,
                  fontSize: { xs: "20px", md: "24px" },
                  fontFamily: theme.fonts.livvic,
                }}
              >
                $30/month for every additional licensed user
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            mt: "30px",
            mx: "5%",
            pb: "20px",
          }}
        >
          {data?.data?.length > 0 ? (
            data?.data?.map((value, index) => (
              <Box
                key={index}
                onClick={
                  value?.subscribed === false
                    ? () => handleSubscribeDialog(value?.userId)
                    : null
                }
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // flexWrap: "wrap",
                  backgroundColor: "#272727",
                  p: "10px",
                  mb: "30px",
                  width: { xs: "100%", md: "45%" },
                  cursor: value?.subscribed === false ? "pointer" : "",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: "70%",
                  }}
                >
                  {/* <Box
                    component="img"
                    src={DefaultImg}
                    alt="User Image"
                    sx={{
                      width: { xs: "50px", md: "70px" },
                      height: { xs: "50px", md: "70px" },
                      objectFit: "cover",
                      borderRadius: "50px",
                      mr: "15px",
                    }}
                    onError={handleCoverImg}
                  ></Box> */}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        color: "#FFFFFF",
                        fontWeight: theme.fonts.fontWeightRegular,
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      {value?.firstName} {value?.lastName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "14px", md: "16px" },
                        color: "#A0A0A0",
                        fontWeight: theme.fonts.fontWeightRegular,
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      {value?.email}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {value?.subscribed === true ? (
                    <Typography
                      sx={{
                        px: "15px",
                        borderRadius: "10px",
                        fontSize: { xs: "12px", md: "14px" },
                        background: "#24B8213D",
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Active
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        px: "15px",
                        borderRadius: "10px",
                        fontSize: { xs: "12px", md: "14px" },
                        background: "#9e3838",
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Inactive
                    </Typography>
                  )}
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                  fontSize: { xs: "18px", md: "20px" },
                  color: "#C5C5C5",
                  my: "50px",
                }}
              >
                No user added. Click on '+' to add user
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "50vw",
            background: theme.colors.dashboard.grey200,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <AddUserModal handleClose={handleClose} callUserList={callUserList} />
      </Dialog>
      <Dialog
        open={subscribeDialog}
        onClose={handleSubscribeDialogClose}
        PaperProps={{
          sx: {
            minWidth: "40vw",
            background: theme.colors.dashboard.grey200,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <DialogContent>
          <Grid display="flex" justifyContent="end">
            <CloseIcon
              onClick={handleSubscribeDialogClose}
              sx={{
                cursor: "pointer",
                backgroundColor: "#252525",
                color: "#7e7e7e",
                borderRadius: "50px",
                p: "2px",
              }}
            />
          </Grid>
          <Typography sx={{ mt: "15px" }}>
            Are you sure. The account is inactive because of previous
            subscription payment has failed, do you want to subscribe again?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "40px",
              mb: "10px",
              gap: 3,
            }}
          >
            <Button
              sx={{
                borderRadius: "8px",
                py: "5px",
                width: "200px",
                color: theme.colors.common.white,
                border: "3px solid #797979",
                background: "#000000",
                cursor: "pointer",
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: theme.fonts.fontWeightMedium,
                "&:hover": {
                  background: "#000000",
                },
              }}
              onClick={handleSubscribeDialogClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                borderRadius: "8px",
                py: "5px",
                width: "200px",
                color: theme.colors.common.white,
                background: theme.colors.text.red,
                cursor: "pointer",
                fontFamily: theme.fonts.livvic,
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: theme.fonts.fontWeightMedium,
                "&:hover": {
                  background: theme.colors.divider.red,
                },
                "&.MuiButton-root.Mui-disabled": {
                  color: theme.colors.text.greyDarker,
                },
              }}
              disabled={loading}
              onClick={handleSubacribe}
            >
              {loading ? <CircularProgress sx={{ color: "gray" }} /> : "Pay"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default UsersListPage;
