import React from "react";
import PropTypes from "prop-types";

const ReactPushNotification = ({ title = "", body = "" }) => {
  return (
    <div>
      <h4>{title}</h4>
      <p>{body}</p>
    </div>
  );
};

ReactPushNotification.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ReactPushNotification;
