import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import Pulse from "../../../../../themes/@overrides/pulse";
import ReactApexChart from "react-apexcharts";
import { getTopThreeCampaigns } from "../../../../../services/product/product.service";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RevenueCard from "../subComponents/socialMediaSection/revenue.card";
import BrandAwarenessCard from "../subComponents/socialMediaSection/brandAwareness.card";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const SocialMedia = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState(null);
  const [isAllDataLoading, setisAllDataLoading] = useState(true);

  useEffect(() => {
    callTopThreeCampaigns();
  }, []);

  const callTopThreeCampaigns = async () => {
    try {
      setisAllDataLoading(true);
      const response = await getTopThreeCampaigns();
      setisAllDataLoading(false);
      if (response.status === 200) {
        setFormData(response?.data);
      } else {
        toast.error("Unable to fetch details");
        console.error(
          "Failed to fetch callTopThreeCampaigns:",
          response?.message
        );
      }
    } catch (error) {
      toast.error("Unable to fetch details");
      console.error("Error fetching callTopThreeCampaigns:", error);
    }
  };

  const socialMediaImpressionsNames = formData?.impressions?.map(
    (impression) => impression?.campaignName
  );
  // const socialMediaImpressionsTotalRevenues = formData?.impressions?.map(
  //   (impression) => impression?.totalImpressions
  // );

  const socialMediaImpressionsTotalRevenues = formData?.impressions
    ? formData?.impressions.map((impression) => impression?.totalImpressions)
    : [];

  return (
    <>
      <Box
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "left",
          mt: { xs: "95px", sm: "100px" },
          mb: "20px",
          paddingX: { xs: "10px", sm: "30px" },
          // alignItems: "center",
          // justifyContent: "center",
          // flexWrap: "wrap",
          // gap: 4,
        }}
      >
        {isAllDataLoading ? (
          <Swiper
            grabCursor={true}
            // slidesPerView={2.2}
            initialSlide={0}
            spaceBetween={20}
            breakpoints={{
              0: { slidesPerView: 1 },
              400: { slidesPerView: 1.5 },
              500: { slidesPerView: 1.8 },
              600: { slidesPerView: 2 },
              700: { slidesPerView: 2.5 },
              900: { slidesPerView: 2.5 },
              1000: { slidesPerView: 2.8 },
              1200: { slidesPerView: 3.5 },
              1400: { slidesPerView: 4 },
              1500: { slidesPerView: 4.5 },
              1600: { slidesPerView: 5 },
            }}
          >
            {Array.from({ length: 10 }, (_, index) => (
              <SwiperSlide key={index}>
                <Box mb="50px" sx={{ mt: { xs: "30px", sm: "50px" } }}>
                  <BrandAwarenessCard isAllDataLoading={isAllDataLoading} />
                </Box>
                <Box>
                  <RevenueCard isAllDataLoading={isAllDataLoading} />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>
            {/* ---------------------Revenue Box-------------------------- */}
            <>
              {formData?.revenues.length > 0 && (
                <Box
                  sx={{
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    // backgroundColor: "#121212",
                    p: "15px",
                    // width: { xs: "100%", sm: "80%", md: "350px", lg: "45%" },
                  }}
                >
                  <Typography
                    sx={{
                      mb: "10px",
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightBold,
                      color: "white",
                      fontSize: {
                        xs: theme.fonts.subHeading_xs,
                        sm: theme.fonts.subHeading,
                      },
                    }}
                  >
                    Revenue
                  </Typography>
                  <Swiper
                    grabCursor={true}
                    // slidesPerView={1.5}
                    initialSlide={0}
                    spaceBetween={20}
                    breakpoints={{
                      0: { slidesPerView: 1 },
                      400: { slidesPerView: 1.5 },
                      500: { slidesPerView: 1.8 },
                      600: { slidesPerView: 2 },
                      700: { slidesPerView: 2.5 },
                      900: { slidesPerView: 2.5 },
                      1000: { slidesPerView: 2.8 },
                      1200: { slidesPerView: 3.5 },
                      1400: { slidesPerView: 4 },
                      1500: { slidesPerView: 4.5 },
                      1600: { slidesPerView: 5 },
                    }}
                  >
                    {formData?.revenues?.map((details, index) => (
                      <SwiperSlide key={index}>
                        <RevenueCard details={details} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/* <Box
                  sx={{
                    minWidth: { xs: "100%", sm: "350px" },
                  }}
                >
                  <Box sx={{ height: "50px", width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightBold,
                        color: "white",
                        textAlign: "center",
                        fontSize: {
                          xs: theme.fonts.subHeading_xs,
                          sm: theme.fonts.subHeading,
                        },
                      }}
                    >
                      Revenue
                    </Typography>
                  </Box>

                  <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactApexChart
                    type="pie"
                    options={revenueOptions}
                    series={revenueTotal}
                    width="400px"
                    height="320px"
                  />
                </Box>
                </Box> */}
                </Box>
              )}
            </>
            {/* ----------------Social Media Impression Box------------------ */}
            <>
              {formData?.impressions.length > 0 && (
                <Box
                  sx={{
                    p: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      mb: "5px",
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightBold,
                      color: "white",
                      fontSize: {
                        xs: theme.fonts.subHeading_xs,
                        sm: theme.fonts.subHeading,
                      },
                    }}
                  >
                    Brand awareness
                  </Typography>

                  <Swiper
                    grabCursor={true}
                    // slidesPerView={1.5}
                    initialSlide={0}
                    spaceBetween={20}
                    breakpoints={{
                      0: { slidesPerView: 1 },
                      400: { slidesPerView: 1.5 },
                      500: { slidesPerView: 1.8 },
                      600: { slidesPerView: 2 },
                      700: { slidesPerView: 2.5 },
                      900: { slidesPerView: 2.5 },
                      1000: { slidesPerView: 2.8 },
                      1200: { slidesPerView: 3.5 },
                      1400: { slidesPerView: 4 },
                      1500: { slidesPerView: 4.5 },
                      1600: { slidesPerView: 5 },
                    }}
                  >
                    {
                      formData?.impressions?.map((details, index) => (
                        <SwiperSlide key={index}>
                          <BrandAwarenessCard details={details} />
                        </SwiperSlide>
                      ))
                      // ))
                    }
                  </Swiper>

                  {/* <Box
                  sx={{
                    minWidth: { xs: "100%", sm: "350px" },
                  }}
                >
                  <Box sx={{ height: "50px", width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: theme.fonts.fontWeightBold,
                        color: "white",
                        textAlign: "center",
                        fontSize: {
                          xs: theme.fonts.subHeading_xs,
                          sm: theme.fonts.subHeading,
                        },
                      }}
                    >
                      Brand awareness
                    </Typography>
                  </Box>

                  <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactApexChart
                    type="pie"
                    options={socialMediaImpressionsOptions}
                    series={socialMediaImpressionsTotalRevenues}
                    width="400px"
                    height="320px"
                  />
                </Box>
                </Box> */}
                </Box>
              )}
            </>
          </>
        )}
      </Box>
    </>
  );
};

export default SocialMedia;
