import React from "react";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import iIcon from "../../../../../../assets/icons/iIcon.svg";

const BrandsAiDisclaimerModal = ({ handleDialogClose }) => {
  const theme = useTheme();
  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleDialogClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: "40px",
          gap: 3,
        }}
      >
        <Box component="img" src={iIcon} alt="! Icon"></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          fontSize="24px"
        >
          Disclaimer
        </Typography>
        <Typography fontFamily={theme.fonts.livvic} color="#D9D9D9">
          Please be advised that the AI explainer provided herein is solely for
          informational purposes and does not constitute legal advice. Athletes
          are strongly encouraged to seek guidance from their legal advisors to
          comprehensively understand the terms of the contract. While the AI
          explainer offers a concise summary, athletes bear the responsibility
          of thoroughly reviewing the entire contract. By proceeding, athletes
          acknowledge and accept their accountability for decisions made
          concerning the contract. Neither the brand nor the owner of the AI
          explainer shall be held liable for any decisions made based on this
          information.
        </Typography>
      </Box>
    </DialogContent>
  );
};

export default BrandsAiDisclaimerModal;
