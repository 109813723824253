import { Grid, Typography, Button, useTheme, Box, Stack } from "@mui/material";
import React from "react";

import Pulse from "../../../../themes/@overrides/pulse";
import { PADDING_X, BASE_IMAGE_URL } from "../../../../utils/constants";
import AthleteBenefits1 from "../../../../assets/images/AthleteBenefits1.png";
import AthleteBenefits2 from "../../../../assets/images/AthleteBenefits2.png";
import AthleteBenefits3 from "../../../../assets/images/AthleteBenefits3.png";
import BrandBenefits1 from "../../../../assets/images/BrandBenefits1.png";
import BrandBenefits2 from "../../../../assets/images/BrandBenefits2.png";
import BrandBenefits3 from "../../../../assets/images/BrandBenefits3.png";
import { ArrowCircleRight, East } from "@mui/icons-material";
import { Pagination, Autoplay, EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

const Benefits = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const centerFlexStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: "50%",
    width: "16px",
    height: "16px",
  };

  const athleteBenefitsImagesList = [
    {
      id: 1,
      imgUrl: AthleteBenefits1,
      alt: "Athlete Benefits 1",
    },
    {
      id: 2,
      imgUrl: AthleteBenefits2,
      alt: "Athlete Benefits 2",
    },
    {
      id: 3,
      imgUrl: AthleteBenefits3,
      alt: "Athlete Benefits 3",
    },
  ];
  const brandBenefitsImagesList = [
    {
      id: 1,
      imgUrl: BrandBenefits1,
      alt: "Brand Benefits 1",
    },
    {
      id: 2,
      imgUrl: BrandBenefits2,
      alt: "Brand Benefits 2",
    },
    {
      id: 3,
      imgUrl: BrandBenefits3,
      alt: "Brand Benefits 3",
    },
  ];

  const handleAthletesViewMore = () => {
    navigate("/athletes-description");
  };

  const handleBrandsViewMore = () => {
    navigate("/brands-description");
  };

  return (
    <Box
      sx={{
        px: { xs: "10px", sm: PADDING_X },
        fontFamily: theme.fonts.livvic,
        my: "30px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            // gap={1}
            mb="20px"
          >
            <Box display="flex" alignItems="center" textAlign="center" gap={2}>
              <Pulse />
              <Typography
                fontFamily={theme.fonts.russoOne}
                color={theme.colors.text.greyDark}
                fontSize={{
                  xs: theme.fonts.heading_xs,
                  sm: theme.fonts.heading,
                  xl: theme.fonts.beforeLogin.heading.xl,
                }}
              >
                Athlete
              </Typography>
            </Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              color={theme.colors.text.greyDark}
              fontSize={{
                xs: theme.fonts.heading_xs,
                sm: theme.fonts.heading,
                xl: theme.fonts.beforeLogin.heading.xl,
              }}
            >
              &nbsp;and Brand&nbsp;
            </Typography>
            <Typography
              fontFamily={theme.fonts.russoOne}
              color={theme.colors.text.red}
              fontSize={{
                xs: theme.fonts.heading_xs,
                sm: theme.fonts.heading,
                xl: theme.fonts.beforeLogin.heading.xl,
              }}
            >
              Benefits
            </Typography>
          </Box>
        </Grid>

        {/* -----------------Athlete Benefits------------------ */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: 2,
            mt: 2,
            background:
              "linear-gradient(90deg, rgba(250,0,0,0.05) 4%, rgba(250,0,0,0.05) 16%, rgba(0,0,0,1) 30%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: { xs: "100%", md: "50%", xl: "60%" },
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: {
                  xs: theme.fonts.cardHeading,
                  xl: theme.fonts.beforeLogin.subHeading.xl,
                },
                color: "red",
              }}
            >
              Athlete Benefits
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  Connect with{" "}
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    brands
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    companies
                  </span>{" "}
                  that align with your passions.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  Stay ahead of the game, even on the go, with our
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    {" "}
                    NIL deal tracking system!
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  Transact with brands in a way that's both{" "}
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    transparent
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    secure.
                  </span>{" "}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={handleAthletesViewMore}
            >
              <Typography
                color={theme.colors.text.red}
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
              >
                View More
              </Typography>
              <East sx={{ color: "red", fontSize: "16px" }} />
            </Box>
          </Box>
          {/* ---------------Image Swiper----------------------- */}
          <Box sx={{ width: { xs: "100%", md: "50%", xl: "40%" } }}>
            <Swiper
              pagination={true}
              grabCursor={true}
              centeredSlides={true}
              effect={"coverflow"}
              slidesPerView={3}
              // loop={true}
              autoplay={{
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              initialSlide={1}
              modules={[Pagination, EffectCoverflow, Autoplay]}
              coverflowEffect={{
                // rotate: 40,
                stretch: 0,
                depth: 100,
                slideShadows: true,
              }}
              breakpoints={{
                0: { slidesPerView: 1 },
                350: { slidesPerView: 2 },
                500: { slidesPerView: 3 },
                900: { slidesPerView: 2 },
                1050: { slidesPerView: 3 },
              }}
              style={{
                "--swiper-pagination-color": "red",
                "--swiper-pagination-bullet-inactive-color": "#fff",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-horizontal-gap": "6px",
                "--swiper-pagination-bottom": "0px",
                height: "350px",
                width: "100%",
              }}
            >
              {athleteBenefitsImagesList?.map((detail, index) => (
                <SwiperSlide key={index} style={{ height: "90%" }}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={detail?.imgUrl}
                      alt={detail?.alt}
                      sx={{
                        height: "100%",
                      }}
                    ></Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Grid>

        {/* ---------------------Brand Benefits----------------------- */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: "center",
            gap: 2,
            mt: 2,
            background:
              "linear-gradient(270deg, rgba(250,0,0,0.05) 4%, rgba(250,0,0,0.05) 16%, rgba(0,0,0,1) 30%)",
          }}
        >
          {/* ---------------Image Swiper----------------------- */}
          <Box sx={{ width: { xs: "100%", md: "50%", xl: "40%" } }}>
            <Swiper
              pagination={true}
              grabCursor={true}
              centeredSlides={true}
              effect={"coverflow"}
              slidesPerView={3}
              // loop={true}
              autoplay={{
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              initialSlide={1}
              modules={[Pagination, EffectCoverflow, Autoplay]}
              coverflowEffect={{
                // rotate: 40,
                stretch: 0,
                depth: 100,
                slideShadows: true,
              }}
              breakpoints={{
                0: { slidesPerView: 1 },
                350: { slidesPerView: 2 },
                500: { slidesPerView: 3 },
                900: { slidesPerView: 2 },
                1050: { slidesPerView: 3 },
              }}
              style={{
                "--swiper-pagination-color": "red",
                "--swiper-pagination-bullet-inactive-color": "#fff",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-horizontal-gap": "6px",
                "--swiper-pagination-bottom": "0px",
                height: "350px",
                width: "100%",
              }}
            >
              {brandBenefitsImagesList?.map((detail, index) => (
                <SwiperSlide key={index} style={{ height: "90%" }}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={detail?.imgUrl}
                      alt={detail?.alt}
                      sx={{
                        height: "100%",
                      }}
                    ></Box>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: { xs: "100%", md: "50%", xl: "60%" },
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: {
                  xs: theme.fonts.cardHeading,
                  xl: theme.fonts.beforeLogin.subHeading.xl,
                },
                color: "red",
              }}
            >
              Brand Benefits
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  Track your{" "}
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    ROI
                  </span>{" "}
                  while teaming up with student-athletes.{" "}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  Tap into a network of over{" "}
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    3,600 eager student-athletes
                  </span>{" "}
                  ready to supercharge your brand.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: { xl: theme.fonts.beforeLogin.body.xl },
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  Leverage{" "}
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    {" "}
                    data and analytics
                  </span>{" "}
                  to gain critical insights for your
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    {" "}
                    NIL campaigns.
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={handleBrandsViewMore}
            >
              <Typography
                color={theme.colors.text.red}
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
              >
                View More
              </Typography>
              <East sx={{ color: "red", fontSize: "16px" }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Benefits;
