import React from "react";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../assets/icons/CrossIcon.svg";
import GracePeriodExpiredIcon from "../../../assets/icons/GracePeriodExpiredIcon.svg";
import SupportIcon from "../../../assets/icons/SupportIcon.svg";
import { useNavigate } from "react-router-dom";

const GracePeriodExpiredModal = ({ handleDialogClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleSupport = () => {
    navigate("/contact-us");
  };

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleDialogClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: "40px",
          gap: 3,
        }}
      >
        <Box
          component="img"
          src={GracePeriodExpiredIcon}
          alt="Grace Period ExpiredIcon"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h6"
        >
          Grace period expired
        </Typography>
        <Typography fontFamily={theme.fonts.livvic} color="#D9D9D9">
          We regret to inform you that your subscription grace period has ended,
          and access to the app has been temporarily suspended. To resolve this
          issue and regain access to the app, please connect with our support
          team. We're here to assist you in resolving any payment-related issues
          or concerns.
        </Typography>

        <Button
          // fullWidth
          sx={{
            background: theme.colors.background.registerButton,
            color: "white",
            height: "50px",
            width: "50%",
            mt: "10px",
            fontSize: "22px",
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            borderRadius: "10px",
            "&:hover": {
              background: theme.colors.divider.red,
            },
          }}
          onClick={handleSupport}
          startIcon={
            <Box component="img" src={SupportIcon} alt="Support Icon"></Box>
          }
        >
          SUPPORT
        </Button>
      </Box>
    </DialogContent>
  );
};

export default GracePeriodExpiredModal;
