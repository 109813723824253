import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Grid, useTheme } from "@mui/material";

import { ecosystem } from "../../../../_mock/dummyData";
import Pulse from "../../../../themes/@overrides/pulse";
import { PADDING_X, BASE_IMAGE_URL } from "../../../../utils/constants";
import AIContents from "../subComponents/ecosystem.AI.content";
import SmartContractsContent from "../subComponents/ecosystem.smartContracts.content";

import TheEcosystemBackground from "../../../../assets/images/TheEcosystemBackground.png";

const ECOSYSTEM_LIST = [
  {
    id: 1,
    name: "ai",
    label: "AI",
    description:
      "We are utilizing Artificial Intelligence in multiple ways to assist both athletes and brands.",
  },
  {
    id: 2,
    name: "smartContracts",
    label: "Smart Contracts",
    description:
      "Our platform harnesses the power of Smart Contracts to revolutionize the way athletes and brands transact. With a secure and transparent system, we eliminate the need for third-party intermediaries, saving valuable time and resources. This results in a more efficient and trustworthy process, ensuring seamless transactions for all parties involved.",
  },
];

const TheEcoSystem = () => {
  const theme = useTheme();

  const [selectedButton, setSelectedButton] = useState(ECOSYSTEM_LIST[0]);

  const handleSelectedButton = (detail) => {
    setSelectedButton(detail);
  };

  return (
    <Box
      sx={{
        py: "60px",
        px: { xs: "10px", sm: PADDING_X },
        background: `url(${TheEcosystemBackground}) no-repeat`,
        backgroundSize: "cover",
      }}
    >
      {/* ------------------Heading------------------------------ */}
      <Box display="flex" alignItems="center" gap={3}>
        <Pulse />
        <Typography
          color={theme.colors.text.red}
          fontFamily={theme.fonts.russoOne}
          fontSize={{
            xs: theme.fonts.heading_xs,
            sm: theme.fonts.heading,
            xl: theme.fonts.beforeLogin.heading.xl,
          }}
        >
          <span style={{ color: "#C5C5C5" }}>The</span> Ecosystem{" "}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          px: { xs: "0px", md: "24px" },
          py: "20px",
        }}
      >
        {/* --------------------------Buttons------------------------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            gap: 2,
          }}
        >
          {ECOSYSTEM_LIST?.map((detail, index) => {
            return (
              <Button
                key={index}
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightBold,
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  textAlign: "center",
                  borderRadius: theme.fonts.smallBorderRadius,
                  "&:hover": {
                    backgroundColor: "#3D2C2C",
                  },
                  background:
                    selectedButton?.id === detail?.id
                      ? "linear-gradient(89.59deg, rgba(0, 0, 0, 0.96) -0.2%, #FF0000 104.33%)"
                      : "#3D2C2C",
                  color:
                    selectedButton?.id === detail?.id ? "white" : "#918E8E",
                  border:
                    selectedButton?.id === detail?.id
                      ? "2px solid #FF0000"
                      : "2px solid #918E8E",
                }}
                onClick={() => handleSelectedButton(detail)}
              >
                {detail?.label}
              </Button>
            );
          })}
        </Box>

        {/* ------------------------------Contents---------------------------- */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: "15px",
            borderRight: `1px solid red`,
            width: "100%",
            background: "black",
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "1px",
              background: theme.colors.ecosystemBorder,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "1px",
              background: theme.colors.ecosystemBorder,
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightBold,
              fontSize: {
                xs: "24px",
                xl: theme.fonts.beforeLogin.subHeading.xl,
              },
              color: "red",
            }}
          >
            {selectedButton?.label}
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: { xl: theme.fonts.beforeLogin.body.xl },
            }}
          >
            {selectedButton?.description}
          </Typography>
          {selectedButton?.name === "ai" ? (
            <AIContents />
          ) : (
            <SmartContractsContent />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TheEcoSystem;
