import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ArrowForwardIos, InsertDriveFileOutlined } from "@mui/icons-material";

import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
import DownloadBoldIcon from "../../../../assets/icons/DownloadBoldIcon.svg";
import RegistrationBackground from "../../../../assets/images/RegistrationBackground.jpg";
import SupportAgentIcon from "../../../../assets/icons/SupportAgentIcon.svg";
import BurrstLogoIcon from "../../../../assets/icons/BurrstLogoIcon.svg";
import School from "../../../../assets/icons/School.svg";
import CreditedIcon from "../../../../assets/icons/CreditedIcon.svg";
import DebitedIcon from "../../../../assets/icons/DebitedIcon.svg";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { retryPayment } from "../../../../services/accounts/accounts.service";

const Transactions = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const detail = location?.state?.detail;

  const transactionType = detail?.type;
  const CREDIT = "credit";

  const handleBack = () => {
    navigate(-1);
  };

  const handleSupportIcon = () => {
    navigate("/dashboard/chat/admin");
  };

  const handleDownloadIconClick = (downloadLink, fileName) => {
    saveAs(downloadLink, fileName);
  };

  const handleResend = async (transactionId) => {
    const response = await retryPayment(transactionId);
  };

  return (
    <>
      <Helmet>
        <title>Transactions | Burrst</title>
      </Helmet>

      <Box
        sx={{
          paddingX: { xs: "10px", sm: "30px" },
          mt: { xs: "95px", sm: "115px" },
        }}
      >
        {/* -----------------Header------------------------- */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box
              component="img"
              src={LeftArrow}
              alt="Left Arrow"
              sx={{ cursor: "pointer", width: { xs: "24px", sm: "30px" } }}
              onClick={handleBack}
            ></Box>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: {
                  xs: theme.fonts.subHeading_xs,
                  sm: theme.fonts.subHeading,
                },
                fontWeight: theme.fonts.fontWeightBold,
              }}
            >
              {detail?.status !== "failed"
                ? "Transaction Successful"
                : "Transaction Failed"}
            </Typography>
          </Box>
          <Box
            component="img"
            src={SupportAgentIcon}
            alt="Support Agent Icon"
            sx={{ width: { xs: "20px", sm: "26px", cursor: "pointer" } }}
            onClick={handleSupportIcon}
          ></Box>
        </Box>
        <Box sx={{ mb: "30px", pl: { xs: "30px", sm: "40px" } }}>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              color: theme.colors.text.greyDark,
            }}
          >
            {`${dayjs(detail?.createdAt).format("hh:mm A")} on ${dayjs(
              detail?.createdAt
            ).format("MMM DD YYYY")}`}
          </Typography>
        </Box>

        <Box
          p="20px"
          mb="30px"
          sx={{
            background: theme.colors.dashboard.grey200,
            overflow: "hidden",
          }}
        >
          {/* ----------------------Optional Box-------------------------- */}
          {detail?.status === "failed" && (
            <Box
              sx={{
                border: `1px solid ${theme.colors.text.greyDark}`,
                mb: "30px",
                background: "black",
                p: "20px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    color: theme.colors.text.greyDark,
                  }}
                >
                  No amount was debited from your account for this failed
                  payment. You can try again now.
                </Typography>
              </Box>
            </Box>
          )}

          {/* -------------------First Box------------------- */}
          <Box
            sx={{
              border: `1px solid ${theme.colors.text.greyDark}`,
              mb: "30px",
              background: "black",
              p: "20px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box sx={{ width: "25px", height: "25px", mt: "3px" }}>
                <Box
                  component="img"
                  src={detail?.isCredited ? CreditedIcon : DebitedIcon}
                  alt={detail?.isCredited ? "Credited Icon" : "Debited Icon"}
                  sx={{ width: "100%", height: "100%" }}
                ></Box>
              </Box>
              <Box>{detail?.campaignName}</Box>
            </Box>
            <Box sx={{ color: theme.colors.text.greyDark, mt: 1.5 }}>
              {detail?.brief}
            </Box>
          </Box>
          {/* -------------------Second Box------------------- */}

          {transactionType === CREDIT ? (
            <Box
              sx={{
                border: `1px solid ${theme.colors.text.greyDark}`,
                background: "black",
                mb: "30px",
              }}
            >
              <Box
                sx={{
                  borderBottom: `1px solid ${theme.colors.text.greyDark}`,
                  p: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionHeading,
                    mb: 2,
                  }}
                >
                  Details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: theme.fonts.sectionSubHeading,
                        color: theme.colors.text.greyDark,
                      }}
                    >
                      Athletes
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: theme.fonts.sectionSubHeading,
                      }}
                    >
                      {`${detail?.athleteCount}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: theme.fonts.sectionSubHeading,
                        color: theme.colors.text.greyDark,
                      }}
                    >
                      Amount
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: theme.fonts.sectionSubHeading,
                      }}
                    >
                      {`$${detail?.amount}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ p: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: theme.colors.text.greyDark,
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: "rgba(36, 184, 33, 1)",
                    }}
                  >
                    {`$${detail?.athleteCount * detail?.amount}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                border: `1px solid ${theme.colors.text.greyDark}`,
                mb: "30px",
                background: "black",
                p: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { sm: "center" },
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    minWidth: "75px",
                    maxWidth: "80px",
                    height: "80px",
                    border: `2px solid ${theme.colors.text.greyLight}`,
                    borderRadius: "10px",
                    position: "relative",
                  }}
                >
                  <Box
                    component="img"
                    src={
                      detail?.athlete?.profileImage || RegistrationBackground
                    }
                    alt="Default Icon"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  ></Box>

                  <Box
                    component="img"
                    src={BurrstLogoIcon}
                    alt="Burrst Logo Icon"
                    sx={{
                      position: "absolute",
                      top: "-25px",
                      right: "-25px",
                      width: "50px",
                      height: "50px",
                    }}
                    id="Burrst Logo Icon"
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: { sm: "center" },
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: {
                          xs: theme.fonts.sectionHeading_xs,
                          sm: theme.fonts.sectionHeading,
                        },
                      }}
                    >
                      {detail?.athlete?.name}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        component="img"
                        src={School}
                        alt={`School Icon`}
                        sx={{ width: "22px", height: "22px" }}
                      ></Box>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          color: theme.colors.text.greyDark,
                        }}
                      >
                        {detail?.athlete?.school}
                      </Typography>
                    </Box>
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.cardSubHeading,
                      color: "red",
                    }}
                  >
                    {`$${detail?.amount}`}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  background: "rgba(14,14,14,1)",
                  py: 1,
                  px: 2,
                  border: "1px solid grey",
                  borderRadius: "5px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.montserrat,
                    fontWeight: theme.fonts.fontWeightMedium,
                  }}
                >
                  Details of the contract
                </Typography>

                <Box
                  component="img"
                  src={DownloadBoldIcon}
                  alt="Download Icon"
                  sx={{
                    cursor: "pointer",
                    width: "24px",
                  }}
                  onClick={() =>
                    handleDownloadIconClick(
                      detail?.contract,
                      detail?.contract.split("/").pop()
                    )
                  }
                ></Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                  my: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.cardSubHeading,
                  }}
                >
                  Activities
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.cardSubHeading,
                  }}
                >
                  {detail?.activityName}
                </Typography>
              </Box>

              <Box my={2}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontSize: theme.fonts.cardSubHeading,
                    mb: 1,
                  }}
                >
                  Proof file
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="#414141"
                  borderRadius="5px"
                  border="1px solid #797979"
                  py="5px"
                  px="10px"
                  maxWidth="400px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        background: "rgba(217,217,217,0.85)",
                        borderRadius: "5px",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        py: 2,
                        px: 1.5,
                        mr: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <InsertDriveFileOutlined
                        sx={{
                          color: "rgba(61,44,44,1)",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: "#C5C5C5",
                        fontSize: { xs: "16px", md: "18px" },
                        fontWeight: theme.fonts.fontWeightRegular,
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      {detail?.proofFile
                        ? detail?.proofFile.split("/").pop()
                        : ""}
                    </Typography>
                  </Box>
                  <Box
                    component="img"
                    src={DownloadBoldIcon}
                    alt="Download Icon"
                    sx={{
                      cursor: "pointer",
                      width: "24px",
                    }}
                    onClick={() =>
                      handleDownloadIconClick(
                        detail?.proofFile,
                        detail?.proofFile.split("/").pop()
                      )
                    }
                  ></Box>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  sx={{
                    fontSize: theme.fonts.cardSubHeading,
                    fontFamily: theme.fonts.livvic,
                    mb: 1,
                  }}
                >
                  URL
                  <span
                    style={{
                      color: theme.colors.text.greyDark,
                      fontWeight: theme.fonts.fontWeightRegular,
                    }}
                  >
                    (optional)
                  </span>
                </Typography>
                <TextField
                  name="url"
                  value={detail?.url || ""}
                  multiline
                  fullWidth
                  size="small"
                  autoComplete="off"
                  InputProps={{
                    sx: {
                      border: `1px solid ${theme.colors.text.greyDark}`,
                      background: "rgba(14,14,14,1)",
                      color: theme.colors.common.white,
                      borderRadius: "10px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          )}
          {detail?.status === "failed" && (
            <Box sx={{ display: "flex", justifyContent: "right" }}>
              <Button
                variant="contained"
                sx={{
                  background: "red",
                  width: "150px",
                  textTransform: "none",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "16px",
                  fontFamily: theme.fonts.livvic,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: theme.colors.text.red,
                    color: "white",
                  },
                }}
                // onClick={() => handleResend(detail?.transactionId)}
              >
                RESEND
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {/* <Box
          sx={{
            paddingX: { xs: "10px", sm: "30px" },
            mt: { xs: "95px", sm: "115px" },
            height: { xs: "calc(100vh - 95px)", sm: "calc(100vh - 115px)" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: { xs: "30px", sm: "40px" },
          }}
        >
          No Data Available
          <Box
            sx={{
              position: "fixed",
              left: { xs: "10px", sm: "35px" },
              top: { xs: "95px", sm: "115px" },
              width: "35px",
              height: "35px",
              cursor: "pointer",
              backgroundColor: theme.colors.common.black,
              color: theme.colors.text.greyLight,
              borderRadius: "5px",
              boxShadow: "0px 0px 7px 0px rgba(197, 197, 197, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleBack}
          >
            <ArrowBack sx={{ fontSize: "25px" }} />
          </Box>
        </Box>
        <Navigate to="/dashboard/wallet" /> */}
    </>
  );
};

export default Transactions;
