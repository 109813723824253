import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import DealTrackerCard from "../../../../components/afterLogin/brands/dealTracker/dealTracker.card";
import {
  athleteDealTracker,
  brandDealTracker,
} from "../../../../services/campaign/campaign.service";
import { ToastContainer, toast } from "react-toastify";
import NoRoiDetailsIcon from "../../../../assets/icons/NoRoiDetailsIcon.svg";

const DealTrackerPage = () => {
  const theme = useTheme();
  const [dealTrackerData, setDealTrackerData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    callBrandDealTrackerApi();
  }, []);

  const callBrandDealTrackerApi = async () => {
    setLoading(true);
    const response = await brandDealTracker();
    if (response?.status === 200) {
      setLoading(false);
      setDealTrackerData(response?.data);
    } else {
      setLoading(false);
      toast.error("Unable to fetch details");
      console.error("Failed to fetch deal tracker:", response?.message);
    }
  };

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Grid my="20px" display="flex" alignItems="center">
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          ml="10px"
          sx={{
            fontSize: { xs: "30px", md: theme.fonts.heading },
          }}
        >
          Deal Tracker
        </Typography>
      </Grid>
      <Grid
        backgroundColor="#121212"
        paddingTop="1%"
        sx={{
          minHeight: { xs: "calc(100vh - 425px)", sm: "calc(100vh - 450px)" },
        }}
      >
        <Grid
          container
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          {loading ? (
            <Box display="flex" justifyContent="center" my="100px">
              <CircularProgress sx={{ color: "gray" }} />
            </Box>
          ) : dealTrackerData?.length > 0 ? (
            dealTrackerData?.map((details, index) => (
              <DealTrackerCard details={details} key={index} />
            ))
          ) : (
            <Box
              py="80px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              backgroundColor="#1F1F1F"
              width="90%"
              my="20px"
            >
              <Box
                component="img"
                src={NoRoiDetailsIcon}
                alt="Burrst Logo Icon"
                sx={{
                  mb: "30px",
                  width: "70px",
                }}
                id="NoRoiDetailsIcon"
              ></Box>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={600}
                sx={{ fontSize: { xs: "20px", md: "22px" }, color: "#C5C5C5" }}
              >
                There is no deal tracker
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DealTrackerPage;
