import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";

import LeftRightArrowBox from "../../shared/leftRightArrowBox";
import RecentNewsAndArticlesCard from "../subComponents/recentNewsAndArticlesCard";

//import { recentNewsAndArticlesCardDetails } from "../../../../_mock/dummyData";
import Pulse from "../../../../themes/@overrides/pulse";
import { PADDING_X } from "../../../../utils/constants";
import { featuredArticles } from "../../../../services/articles/article.service";
import { Swiper, SwiperSlide } from "swiper/react";
import { East } from "@mui/icons-material";

const RecentNewsAndArticles = () => {
  const theme = useTheme();
  const [
    recentNewsAndArticlesCardDetails,
    setRecentNewsAndArticlesCardDetails,
  ] = useState([]);

  const handleViewMore = () => {
    window.open("https://burrst.beehiiv.com", "_blank", "noopener noreferrer");
  };

  const fetchArticle = async () => {
    // try {
    const response = await featuredArticles();

    if (response?.status === 200) {
      setRecentNewsAndArticlesCardDetails(response?.data);
    } else {
      console.error("Failed to fetch featured athletes:", response.message);
    }
    // } catch (error) {
    //   console.error("Error fetching featured athletes", error);
    // }
  };

  useEffect(() => {
    fetchArticle();
  }, []);

  return (
    <Box
      sx={{
        // minHeight: "480px",
        paddingX: { xs: "10px", sm: PADDING_X },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        mt="60px"
        mb="30px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box display="flex" alignItems="center" textAlign="center" gap={2}>
            <Pulse />
            <Typography
              color={theme.colors.text.greyDark}
              fontFamily={theme.fonts.russoOne}
              fontSize={{
                xs: theme.fonts.heading_xs,
                md: theme.fonts.heading,
                xl: theme.fonts.beforeLogin.heading.xl,
              }}
            >
              Recent&nbsp;
            </Typography>
          </Box>
          <Typography
            color={theme.colors.text.red}
            fontFamily={theme.fonts.russoOne}
            fontSize={{
              xs: theme.fonts.heading_xs,
              md: theme.fonts.heading,
              xl: theme.fonts.beforeLogin.heading.xl,
            }}
          >
            News & Articles
          </Typography>
        </Box>
        {/* <LeftRightArrowBox
          startIndex={startIndex}
          setStartIndex={setStartIndex}
          noOfCardsToDisplay={noOfCardsToDisplay}
          cardDetails={recentNewsAndArticlesCardDetails}
          hiddenCard={noOfCardsToDisplay - 1}
        /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={handleViewMore}
        >
          <Typography
            color={theme.colors.text.red}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
          >
            View More
          </Typography>
          <East sx={{ color: "red", fontSize: "16px" }} />
        </Box>
      </Box>

      <Box my="60px" py="10px">
        <Swiper
          grabCursor={true}
          initialSlide={0}
          spaceBetween={40}
          breakpoints={{
            0: { slidesPerView: 1 },
            400: { slidesPerView: 1.5 },
            600: { slidesPerView: 2 },
            900: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
            1600: { slidesPerView: 5 },
            2000: { slidesPerView: 6 },
          }}
        >
          {recentNewsAndArticlesCardDetails?.map((detail, index) => (
            <SwiperSlide key={index}>
              <RecentNewsAndArticlesCard detail={detail} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default RecentNewsAndArticles;
