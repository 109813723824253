import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import { chatSocket } from "../../utils/socket";
import { BASE_API_URL } from "../../utils/constants";

export const getChatUsers = createAsyncThunk(
  "chat/getUsers",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(`${BASE_API_URL}/chat/userList`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      return data;
    } catch (err) {
      return err?.error;
    }
  }
);
export const setSelectedUser = createAsyncThunk(
  "chat/setSelectedUser",
  async (user, { rejectWithValue, dispatch }) => {
    try {
      return user;
    } catch (err) {
      return err?.error;
    }
  }
);
export const setChatRoomId = createAsyncThunk(
  "chat/setChatRoomId",
  async (chatRoomId, { rejectWithValue, dispatch }) => {
    try {
      return chatRoomId;
    } catch (err) {
      return err?.error;
    }
  }
);
export const setIsUserSelected = createAsyncThunk(
  "chat/setIsUserSelected",
  async (isUserSelected, { rejectWithValue, dispatch }) => {
    try {
      return isUserSelected;
    } catch (err) {
      return err?.error;
    }
  }
);
export const getChatRoomId = createAsyncThunk(
  "chat/getChatRoomId",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const userType =
        localStorage.getItem("usertype").toLowerCase() === "brand"
          ? "athlete"
          : "brand";
      const { data } = await axios.get(
        `${BASE_API_URL}/chat/chatroom?${userType}Id=${id?.messengerUserId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return data;
    } catch (err) {
      return err?.error;
    }
  }
);
