import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import BrandImage from "../../../../../assets/icons/AdidasBlack.svg";
import dayjs from "dayjs";

const MatchingCampaignCards = ({ detail }) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <Box
        sx={{
          borderRadius: "5px",
          border: `1px solid ${theme.colors.text.greyDark}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1,
            height: "95px",
            py: "10px",
            px: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#D9D9D9",
              width: "100px",
              height: "95%",
            }}
          >
            <Box
              component="img"
              src={detail?.brandLogo || BrandImage}
              alt="Brand Logo"
              sx={{ width: "100%", height: "100%" }}
            ></Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{ color: "white", fontSize: theme.fonts.cardHighlights }}
            >
              {detail?.campaignName}
            </Typography>
            {detail?.endDate && (
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightMedium}
                sx={{
                  color: theme.colors.text.greyDark,
                  fontSize: "12px",
                }}
              >
                {`End date: ${dayjs(detail?.endDate).format("MMM DD YYYY")}`}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "5px",
            background: "#0E0E0E",
            p: "5px",
            height: "110px",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "2px",
              height: "2px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
              borderRadius: "4px",
            },
          }}
        >
          <Typography
            fontFamily={theme.fonts.livvic}
            sx={{ color: "white", fontSize: "14px", wordBreak: "break-all" }}
          >
            {detail?.brief}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default MatchingCampaignCards;
