import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";

import { Grid, Typography, useTheme, Box } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ViewActivitydetailsCard from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/viewActivitydetails.card";
import { campaignActivityDetails } from "../../../../../_mock/dummyData";
import { getCampaignDetails } from "../../../../../services/campaign/campaign.service";

const CampaignActivitydetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activityDetailsData, setActivityDetailsData] = useState([]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    callApi();
  }, []);

  const callApi = async () => {
    try {
      const response = await getCampaignDetails();

      if (response.status === 200) {
        setActivityDetailsData(response.data);
      } else {
        console.error("Failed to fetch activity details:", response.message);
      }
    } catch (error) {
      console.error("Error fetching activity details:", error);
    }
  };

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      <Grid my="20px" display="flex" alignItems="center">
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: "35px",
              color: "white",
              "@media (max-width:468px)": {
                fontSize: "25px",
              },
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          fontSize={theme.fonts.heading}
          ml="15px"
          sx={{
            "@media (max-width:468px)": {
              fontSize: "25px",
            },
          }}
        >
          Activities Details
        </Typography>
      </Grid>
      <Grid backgroundColor="#121212" padding="1%">
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          marginX="35px"
          borderRadius="4px"
          marginY="20px"
          py="10px"
          sx={{ border: "1px solid #797979", backgroundColor: "black" }}
        >
          <Typography
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            sx={{ fontSize: { xs: "22px", md: "28px" } }}
          >
            ${activityDetailsData?.totalRevenue}
          </Typography>
          <Typography
            fontFamily={theme.fonts.livvic}
            color="#AFACAC"
            sx={{ fontSize: { xs: "18px", md: "20px" } }}
          >
            Total Revenue
          </Typography>
        </Box>

        <Grid
          container
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          {activityDetailsData?.deliverables?.map((details, index) => (
            <ViewActivitydetailsCard
              details={details}
              key={index}
              callApi={callApi}
              paymentModel={activityDetailsData?.paymentModel}
              status={activityDetailsData?.status}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignActivitydetails;
