import { REDIRECT_TO_MY_ACCOUNTS } from "../../utils/constants";
import { getApi, deleteApi, createApi } from "./api.request";

const BANK_VERIFICATION_URL_PATH = "/account/plaidConnect";

const BANK_DETAILS_PATH = "/account/plaidAccountsList";

const DEFAULT_BANK_PATH = "/account/selectDefaultAccount";

const DELETE_BANK_PATH = "/account/deletePlaidAccount";

const WALLET_BALANACE_PATH = "/account/walletBalance";

const EARNINGS_BALANACE_PATH = "/account/myEarnings";

const SUBSCRIPTION_TRANSACTION_LIST = "/account/subscriptionTransactionsList";

const RETRY_PATH = "/account/retryPayment";

export const getBankVerificationUrl = async () => {
  return getApi(
    `${BANK_VERIFICATION_URL_PATH}?redirectUri=${encodeURIComponent(
      REDIRECT_TO_MY_ACCOUNTS
    )}`
  );
};

export const getBankDetails = async () => {
  return getApi(`${BANK_DETAILS_PATH}`);
};

export const setDefaultAccount = async (accountId) => {
  return getApi(`${DEFAULT_BANK_PATH}?accountId=${accountId}`);
};

export const deleteAccount = async (accountId) => {
  return deleteApi(`${DELETE_BANK_PATH}`, accountId);
};

export const getWalletBalance = async (filter) => {
  return getApi(`${WALLET_BALANACE_PATH}`, filter);
};

export const getEarnings = async () => {
  return getApi(`${EARNINGS_BALANACE_PATH}`);
};

export const brandsubscriptionTransactionsList = async () => {
  return getApi(`${SUBSCRIPTION_TRANSACTION_LIST}`);
};

export const retryPayment = async (body) => {
  return createApi(`${RETRY_PATH}`, body);
};
