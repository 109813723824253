import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import MyContractIcon from "../../../../../assets/icons/MyContractIcon.svg";
import StatisticsActiveCard from "../../../../../components/afterLogin/athletes/home/subComponents/statistics/statistics.active.card";
import { getAppliedCampaigns } from "../../../../../services/campaign/campaign.service";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";
import NoActiveCampaign from "../../../../../assets/icons/NoActiveCampaign.svg";

const StatisticsActive = ({ setIsActive }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeCampaignsList, setActiveCampaignsList] = useState([]);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleBack = () => {
    navigate(-1);
  };

  const handleViewCompletedProjects = () => {
    navigate("/dashboard/home/statistics/completed-projects");
  };

  const handleApplied = () => {
    // navigate("/dashboard/home/statistics/applied");
    setIsActive(false);
  };

  const fetchAppliedCampaigns = async () => {
    setIsAllDataLoading(true);
    const { data } = await getAppliedCampaigns();
    setIsAllDataLoading(false);
    setActiveCampaignsList(data?.data[0]?.selected);
  };

  useEffect(() => {
    fetchAppliedCampaigns();
  }, []);

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* ----------------Top Header Icons-------------------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", sm: "40px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color="white"
          fontFamily={theme.fonts.russoOne}
          fontSize={theme.fonts.heading}
        >
          Campaigns
        </Typography>
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          my: "30px",
          px: "20px",
          pt: "40px",
          pb: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
          minHeight: { xs: "calc(100vh - 425px)", sm: "calc(100vh - 450px)" },
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                color: "white",
                p: "2px",
                background: "black",
                border: "1px solid rgba(144,5,5,1)",
                borderRadius: "25px",
                fontWeight: theme.fonts.fontWeightBold,
                fontFamily: theme.fonts.montserrat,
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      py: 1.5,
                      background: "rgba(243, 0, 0, 1)",
                      borderRadius: "25px",
                      cursor: "pointer",
                    }}
                  >
                    Active
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      textAlign: "center",
                      py: 1.5,
                      background: "black",
                      borderRadius: "25px",
                      cursor: "pointer",
                    }}
                    onClick={handleApplied}
                  >
                    Applied
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {isAllDataLoading ? (
              <CustomSkeleton
                sx={{ width: "100%", height: "40px", borderRadius: "5px" }}
              />
            ) : (
              <Box
                sx={{
                  background: "black",
                  p: 1.5,
                  border: "1px solid grey",
                  borderRadius: "5px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleViewCompletedProjects}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={MyContractIcon}
                    alt="My Contract"
                    height="25px"
                  />
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.montserrat,
                      fontWeight: theme.fonts.fontWeightMedium,
                    }}
                  >
                    View Completed Projects
                  </Typography>
                </Box>

                <ArrowForwardIosIcon fontSize="25px" />
              </Box>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              {isAllDataLoading ? (
                Array.from({ length: 10 }, (_, index) => (
                  <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <StatisticsActiveCard isAllDataLoading={isAllDataLoading} />
                  </Grid>
                ))
              ) : activeCampaignsList?.length ? (
                activeCampaignsList?.map((detail, index) => (
                  <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <StatisticsActiveCard detail={detail} />
                  </Grid>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 2,
                    fontSize: { xs: "24px", sm: "30px" },
                    minHeight: "250px",
                    width: "100%",
                    background: "rgba(18,18,18,1)",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    component="img"
                    src={NoActiveCampaign}
                    alt="No Active Campaign"
                    sx={{ width: { xs: "70px", sm: "100px" } }}
                  ></Box>
                  No active campaigns yet!
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StatisticsActive;
