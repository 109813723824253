import { Grid, Typography, Button, useTheme, Box } from "@mui/material";
import React from "react";
import podcastImg from "../../../../assets/icons/Podcast2.svg";
// import podcastImg from "../../../../assets/images/Podcast-Cover-1.svg";
// import apple from "../../../../assets/images/Apple.svg";
// import spotify from "../../../../assets/images/Spotify.svg";
// import PodcastImage1 from "../../../../assets/images/PodcastImage1.svg";
// import PodcastImage2 from "../../../../assets/images/PodcastImage2.svg";
// import PodcastImage3 from "../../../../assets/images/PodcastImage3.svg";

import Pulse from "../../../../themes/@overrides/pulse";
import { PADDING_X, BASE_IMAGE_URL } from "../../../../utils/constants";
import { Link } from "react-router-dom";

const Podcasts = () => {
  const theme = useTheme();

  const apple = `${BASE_IMAGE_URL}/Apple.svg`;
  const spotify = `${BASE_IMAGE_URL}/Spotify.svg`;
  // const PodcastImage1 = `${BASE_IMAGE_URL}/PodcastImage1.svg`;
  // const PodcastImage2 = `${BASE_IMAGE_URL}/PodcastImage2.svg`;
  // const PodcastImage3 = `${BASE_IMAGE_URL}/PodcastImage3.svg`;

  return (
    <Box
      sx={{
        px: { xs: "10px", sm: PADDING_X },
        fontFamily: theme.fonts.livvic,
        mb: "30px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" flexWrap="wrap" alignItems="center" mb="20px">
            <Box
              display="flex"
              justifyContent="left"
              alignItems="center"
              textAlign="center"
              gap={2}
            >
              <Pulse />
              <Typography
                fontFamily={theme.fonts.russoOne}
                color={theme.colors.text.greyDark}
                fontSize={{
                  xs: theme.fonts.heading_xs,
                  sm: theme.fonts.heading,
                  xl: theme.fonts.beforeLogin.heading.xl,
                }}
              >
                The&nbsp;
              </Typography>
            </Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              color={theme.colors.text.red}
              fontSize={{
                xs: theme.fonts.heading_xs,
                sm: theme.fonts.heading,
                xl: theme.fonts.beforeLogin.heading.xl,
              }}
            >
              Next Generation
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightRegular}
            color={theme.colors.text.greyLight}
            sx={{
              fontSize: {
                xs: "16px",
                md: "20px",
              },
            }}
          >
            Welcome to The Next Generation where athletes share their inspiring
            stories in the exciting era of NIL and explore the fascinating world
            of sports technology.
            <br />
            <br />
            Join us for captivating conversations that uncover the untold
            journeys of athletes and dive into the cutting-edge innovations
            shaping the future of sports.
          </Typography>
          <Box
            sx={{
              my: { xs: 5, xl: 10 },
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              gap: 2,
            }}
          >
            <Link
              to="https://podcasts.apple.com/us/podcast/the-next-generation-of-superstars/id1660120217"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                sx={{
                  border: "1px solid #797979",
                  textTransform: "none",
                  px: "10px",
                  minWidth: {
                    xs: "280px",
                    sm: "350px",
                    md: "300px",
                    lg: "350px",
                  },
                  maxWidth: {
                    xs: "350px",
                    sm: "400px",
                    md: "350px",
                    lg: "400px",
                  },
                  height: "90px",
                  borderRadius: "10px",
                  background: "#100e0e",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  gap: 2,
                }}
              >
                <Box component="img" src={apple} alt="apple" height="90%"></Box>

                <Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.poppins,
                      fontWeight: 400,
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "18px",
                        lg: "20px",
                      },
                      color: "white",
                    }}
                  >
                    Listen on
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.poppins,
                      fontWeight: 600,
                      fontSize: {
                        xs: "18px",
                        sm: "22px",
                        md: "20px",
                        lg: "26px",
                      },
                      color: "white",
                    }}
                  >
                    Apple Podcasts
                  </Typography>
                </Box>
              </Button>
            </Link>
            <Link
              to="https://open.spotify.com/show/5iq0Qu8nTCNz8ZrURqSLRF?si=OfOGohkjR9uxWDx3puSpXA'"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                sx={{
                  border: "1px solid #797979",
                  textTransform: "none",
                  px: "10px",
                  backgroundColor: "#100e0e",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "left",
                  gap: 2,
                  minWidth: {
                    xs: "280px",
                    sm: "350px",
                    md: "300px",
                    lg: "350px",
                  },
                  maxWidth: {
                    xs: "350px",
                    sm: "400px",
                    md: "350px",
                    lg: "400px",
                  },
                  height: "90px",
                  borderRadius: "10px",
                }}
              >
                <Box
                  component="img"
                  src={spotify}
                  alt="spotify"
                  height="90%"
                ></Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.poppins,
                      fontWeight: 400,
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "18px",
                        lg: "20px",
                      },
                      color: "white",
                    }}
                  >
                    Listen on
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.poppins,
                      fontWeight: 600,
                      fontSize: {
                        xs: "18px",
                        sm: "22px",
                        md: "20px",
                        lg: "26px",
                      },
                      color: "white",
                    }}
                  >
                    Spotify Podcasts
                  </Typography>
                </Box>
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            background:
              "radial-gradient(circle, rgba(246,0,0,0.3) 0%, rgba(0,0,0,1) 70%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={podcastImg}
              alt="Image1"
              sx={{
                width: { xs: "90%", md: "100%", lg: "90%" },
                height: { xs: "90%", md: "100%", lg: "90%" },
              }}
            ></Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Podcasts;
