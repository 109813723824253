import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import DefaultBrandLogo from "../../../../../assets/logo/DefaultBrandLogo.png";
import ShareIcon from "../../../../../assets/icons/Share.svg";
import MyContractIcon from "../../../../../assets/icons/MyContractIcon.svg";
import ActivitiesDetailsIcon from "../../../../../assets/icons/ActivitiesDetailsIcon.svg";
import MessageIcon from "../../../../../assets/icons/MessageIcon.svg";
import {
  getCampaignsViewDetails,
  applyToCampaign,
  withdrawFromCampaign,
} from "../../../../../services/campaign/campaign.service";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  getChatUsers,
  setIsUserSelected,
  setSelectedUser,
} from "../../../../../store/actions/chatActions";

import axios from "axios";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";
import { HOST_URL } from "../../../../../utils/constants";
import { toast } from "react-toastify";
import {
  ContentCopyRounded,
  LibraryAddCheckRounded,
} from "@mui/icons-material";
import ConnectPlaidAccModal from "../../../../../components/afterLogin/athletes/home/connectPlaidAcc.modal";

const CampaignsViewDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const longURL = `${HOST_URL}/dashboard/campaigns/view-details/${campaignId}`;

  const [campaignDetails, setCampaignDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [isAllDataLoading, setisAllDataLoading] = useState(true);
  const [isApplied, setIsApplied] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const [urlModalOpen, setUrlModalOpen] = useState(false);
  const [shortURL, setShortURL] = useState("");
  const [isPlaidConnected, setIsPlaidConnected] = useState(false);
  const profileState = "viewDetailsPlaidConnectModal";
  const backState = localStorage.getItem("back");

  const handleBack = () => {
    if (backState === "home") {
      navigate("/dashboard/home");
    } else if (backState === "campaignList") {
      navigate("/dashboard/campaigns");
    } else if (backState === "brandList") {
      navigate("/dashboard/explore-brands/brands-list");
    } else navigate(-1);
    localStorage.removeItem("back");
  };

  const handlePlaidConnectedModalClose = () => {
    setIsPlaidConnected(false);
  };

  const handleMessageIcon = () => {
    dispatch(setSelectedUser(campaignDetails));
    dispatch(setIsUserSelected(true));

    setTimeout(() => {
      dispatch(getChatUsers());
      navigate("/dashboard/chat");
    }, 1000);
  };

  const handleCopyToClipboard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(shortURL);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    setTimeout(() => {
      handleUrlModalClose();
    }, 1500);
  };

  const handleShareIcon = async () => {
    try {
      const response = await axios.get(
        `https://is.gd/create.php?format=json&url=${encodeURIComponent(
          longURL
        )}`
      );
      const shortURL = response?.data?.shorturl;
      if (shortURL) {
        setUrlModalOpen(true);
        setShortURL(shortURL);
      } else {
        toast.error(response?.data?.errormessage);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleUrlModalClose = () => {
    setUrlModalOpen(false);
    setShortURL("");
  };

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
    setIsImageError(true);
  };

  const handleMyContract = () => {
    navigate("/dashboard/campaigns/contract", {
      state: { campaignDetails },
    });
  };

  const handleViewBrandProfile = () => {
    navigate("/dashboard/explore-brands/brands-list/brand-profile", {
      state: {
        detail: campaignDetails,
      },
    });
  };

  const handleActivityDetails = () => {
    navigate("/dashboard/campaigns/activity-details", {
      state: {
        campaignId,
      },
    });
  };

  const confirmAction = async () => {
    return new Promise((resolve) => {
      const confirmed = window.confirm(
        "Are you sure you want to apply for this campaign?"
      );
      resolve(confirmed);
    });
  };

  const handleApplyNow = async () => {
    const confirmed = await confirmAction();
    if (confirmed) {
      setLoading(true);
      const { data } = await applyToCampaign(campaignId);
      setLoading(false);
      if (data?.status === 200) fetchCampaignsDetails();
      else if (data?.status === 400 && data?.data?.plaidAccount === false) {
        setIsPlaidConnected(true);
      }
    }
  };
  const handleWithdraw = async () => {
    setLoading(true);
    const { data } = await withdrawFromCampaign(
      campaignId,
      campaignDetails?.selected
    );
    setLoading(false);
    if (data?.status === 200) fetchCampaignsDetails();
    else if (
      data?.status === 400 &&
      data?.data?.reason === "ALREADY_SELECTED"
    ) {
      toast.error("Your application has been selected by the brand.", {
        autoClose: 5000,
        onClose: () => {
          window.location.reload();
        },
      });
    }
  };

  const fetchCampaignsDetails = async () => {
    setisAllDataLoading(true);
    const { data } = await getCampaignsViewDetails(campaignId);
    setisAllDataLoading(false);
    setCampaignDetails(data?.data);
    setIsApplied(data?.data?.applied);
  };

  useEffect(() => {
    fetchCampaignsDetails();
  }, []);

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {" "}
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      {/* ----------------Top Header Icons-------------------------- */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", sm: "40px" },
              color: "white",
            }}
          />
          <Typography
            fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            fontWeight={theme.fonts.fontWeightBold}
            fontFamily={theme.fonts.livvic}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              campaignDetails?.brandName
            )}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            component="img"
            src={MessageIcon}
            alt="Message Icon"
            sx={{
              cursor: "pointer",
              width: "30px",
            }}
            onClick={handleMessageIcon}
          />
          <Box
            component="img"
            src={ShareIcon}
            alt="Edit Icon"
            sx={{ cursor: "pointer", width: "25px" }}
            onClick={handleShareIcon}
          />

          <Dialog
            open={urlModalOpen}
            onClose={handleUrlModalClose}
            PaperProps={{
              sx: {
                minWidth: { xs: "90vw", sm: "400px", md: "500px" },
                background: "black",
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography fontFamily={theme.fonts.livvic}>
                  Share URL
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    background: "black",
                    padding: "5px 10px",
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    borderRadius: "5px",

                    maxWidth: { xs: "200px", sm: "250px", md: "300px" },
                    color: "white",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {shortURL}
                  </Box>
                  {isCopied ? (
                    <LibraryAddCheckRounded />
                  ) : (
                    <ContentCopyRounded
                      onClick={handleCopyToClipboard}
                      sx={{ cursor: "pointer" }}
                    />
                  )}
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
      {/* ----------------Content Box----------------------- */}
      <Box
        sx={{
          my: "30px",
          p: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container rowSpacing={4}>
          <Grid
            item
            xs={12}
            sx={{
              display: { sm: "flex" },
              alignItems: { xs: "center", sm: "flex-start" },
              gap: 3,
              height: { xs: "auto", sm: "200px" },
            }}
          >
            <Box
              sx={{
                background: "rgba(25,26,28,1)",
                borderRadius: "5px",
                p: "10px",
                // minWidth: "150px",
                // minHeight: "130px",
                minWidth: { xs: "200px", sm: "170px", md: "180px" },
                maxWidth: { xs: "210px", sm: "auto" },
                height: { xs: "200px", sm: "90%", md: "95%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: { xs: 2, sm: 0 },
              }}
            >
              {isAllDataLoading ? (
                <CustomSkeleton
                  type="img"
                  sx={{ width: "100%", height: "100%" }}
                />
              ) : (
                <Box
                  component="img"
                  src={campaignDetails?.brandLogo}
                  alt="Brand Logo"
                  onError={handleImageError}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: isImageError ? "contain" : "cover",
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                gap: 0.5,
                // maxHeight: { xs: "auto", sm: "180px", md: "200px" },
                height: { xs: "auto", sm: "90%", md: "95%" },
              }}
            >
              <Typography
                fontSize={theme.fonts.sectionHeading}
                fontWeight={theme.fonts.fontWeightBold}
                fontFamily={theme.fonts.livvic}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton type="p" />
                ) : (
                  campaignDetails?.campaignName
                )}
              </Typography>
              {campaignDetails?.endDate && (
                <Typography
                  fontFamily={theme.fonts.livvic}
                  color={theme.colors.text.greyLight}
                >
                  {isAllDataLoading ? (
                    <CustomSkeleton type="p" />
                  ) : (
                    ` End date-
                ${dayjs(campaignDetails?.endDate).format("MMM DD YYYY")}`
                  )}
                </Typography>
              )}

              <Box
                sx={{
                  height: "90%",
                  pr: "5px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "3px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "red",
                    borderRadius: "4px",
                  },
                }}
              >
                <Typography
                  fontFamily={theme.fonts.livvic}
                  color={theme.colors.text.greyLight}
                  mt={isAllDataLoading ? "20px" : "0"}
                >
                  {isAllDataLoading ? (
                    <CustomSkeleton
                      type="p"
                      count={4}
                      sx={{
                        minWidth: {
                          // sm: "calc(100vw - 255px)",
                          // md: "calc(100vw - 500px)",
                          sm: "350px",
                          md: "400px",
                          lg: "600px",
                          xl: "800px",
                        },
                        maxWidth: "600px",
                        mb: 1,
                      }}
                    />
                  ) : (
                    campaignDetails?.brief
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                background: "black",
                py: 1,
                border: "1px solid grey",
                borderRadius: "5px",
                fontWeight: theme.fonts.fontWeightBold,
                fontFamily: theme.fonts.montserrat,
                cursor: "pointer",
              }}
              onClick={handleViewBrandProfile}
            >
              View Brand Profile
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    color: theme.colors.text.greyLight,
                  }}
                >
                  Compensation
                </Typography>

                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    fontWeight: theme.fonts.fontWeightMedium,
                    color: "red",
                  }}
                >
                  {isAllDataLoading ? (
                    <CustomSkeleton type="p" />
                  ) : campaignDetails?.sharePercentage ? (
                    `${campaignDetails?.sharePercentage}% of monthly revenue`
                  ) : (
                    `$${campaignDetails?.totalCompensation}`
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "right" },
                }}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton type="p" />
                ) : (
                  <Box
                    sx={{
                      background: "rgba(247, 191, 46, 1)",
                      py: "5px",
                      px: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      10% platform fee will be deducted at the time of the
                      payment
                    </Typography>
                  </Box>
                )}
              </Grid>

              {/* <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    color: theme.colors.text.greyLight,
                  }}
                >
                  Number of Applicants
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    fontWeight: theme.fonts.fontWeightMedium,
                  }}
                >
                  {isAllDataLoading ? (
                    <CustomSkeleton type="p" />
                  ) : (
                    campaignDetails?.numberOfApplications
                  )}
                </Typography>
              </Grid> */}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    color: theme.colors.text.greyLight,
                  }}
                >
                  Number of Athlete(s)
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    textAlign: "right",
                  }}
                >
                  {isAllDataLoading ? (
                    <CustomSkeleton type="p" />
                  ) : (
                    `${
                      campaignDetails?.multipleAthletes
                        ? "Multiple Athletes"
                        : "Single Athlete"
                    }`
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    color: theme.colors.text.greyLight,
                  }}
                >
                  Campaign Type
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    textAlign: "right",
                  }}
                >
                  {isAllDataLoading ? (
                    <CustomSkeleton type="p" />
                  ) : (
                    campaignDetails?.campaignType
                  )}
                </Typography>
              </Grid>
              {/* <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  gap: 0.5,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                    color: theme.colors.text.greyLight,
                  }}
                >
                  Additional skills and expertise
                </Typography>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: theme.fonts.sectionSubHeading,
                  }}
                >
                  {campaignDetails?.additionalSkills?.join(",")}
                </Typography>
              </Grid> */}
              {campaignDetails?.location && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                      color: theme.colors.text.greyLight,
                    }}
                  >
                    Location
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: theme.fonts.sectionSubHeading,
                    }}
                  >
                    {isAllDataLoading ? (
                      <CustomSkeleton type="p" />
                    ) : (
                      campaignDetails?.location
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Tooltip
                  title="Contract sign is pending"
                  placement="top"
                  open={
                    campaignDetails?.applied &&
                    campaignDetails?.selected &&
                    !campaignDetails?.signed
                      ? true
                      : false
                  }
                  arrow={true}
                  slotProps={{
                    tooltip: {
                      sx: {
                        background: "red",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "red",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      background: "black",
                      p: 1,
                      border: "1px solid grey",
                      borderRadius: "5px",
                      // width: { xs: "260px", sm: "100%" },
                      maxWidth: { xs: "90%", sm: "100%" },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={handleMyContract}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        component="img"
                        src={MyContractIcon}
                        alt="My Contract"
                        height="25px"
                      />
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.montserrat,
                          fontWeight: theme.fonts.fontWeightMedium,
                        }}
                      >
                        Contract
                      </Typography>
                    </Box>

                    <ArrowForwardIosIcon fontSize="25px" />
                  </Box>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  onClick={handleActivityDetails}
                  sx={{
                    cursor: "pointer",
                    background: "black",
                    p: 1,
                    border: "1px solid grey",
                    borderRadius: "5px",
                    // width: { xs: "260px", sm: "100%" },
                    maxWidth: { xs: "90%", sm: "100%" },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={ActivitiesDetailsIcon}
                      alt="Activities Details"
                      height="25px"
                    />
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.montserrat,
                        fontWeight: theme.fonts.fontWeightMedium,
                      }}
                    >
                      Activity Details
                    </Typography>
                  </Box>
                  <ArrowForwardIosIcon fontSize="25px" />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {campaignDetails &&
            (campaignDetails?.signed || !campaignDetails?.selected) && (
              <Grid item xs={12} textAlign="right">
                <Button
                  variant="contained"
                  disabled={campaignDetails?.signed || loading}
                  sx={{
                    background: theme.colors.text.red,
                    width: "150px",
                    height: "45px",
                    textTransform: "none",
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: theme.fonts.sectionSubHeading,
                    fontFamily: theme.fonts.livvic,
                    "&:hover": {
                      background: theme.colors.divider.red,
                    },
                    "&.MuiButton-root.Mui-disabled": {
                      background: "rgba(255,0,0,0.6)",
                      color: "white",
                    },
                  }}
                  onClick={isApplied ? handleWithdraw : handleApplyNow}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "white" }} />
                  ) : campaignDetails?.signed ? (
                    "Signed"
                  ) : isApplied ? (
                    "Withdraw"
                  ) : (
                    "Apply Now"
                  )}
                </Button>
              </Grid>
            )}
        </Grid>
      </Box>
      <Dialog
        open={isPlaidConnected}
        onClose={handlePlaidConnectedModalClose}
        PaperProps={{
          sx: {
            width: "100vw",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <ConnectPlaidAccModal
          profileState={profileState}
          campaignId={campaignId}
          handleClose={handlePlaidConnectedModalClose}
        />
      </Dialog>
    </Box>
  );
};

export default CampaignsViewDetails;
