const Joi = require("joi");

const Schema = Joi.object({
  name: Joi.string().required().trim().max(30).messages({
    "string.max": `Name must be at most 30 characters long`,
    "any.required": `Brand name is required`,
    "string.empty": `Brand name is required`,
  }),
  contactPersonName: Joi.string()
    .required()
    .regex(/^[a-zA-Z\s.'\-À-ÖØ-öø-ÿ]*$/)
    .trim()
    .max(30)
    .messages({
      "string.max": `Name must be at most 30 characters long`,
      "any.required": `Contact person name is required`,
      "string.empty": `Contact person name is required`,
      "string.pattern.base": `Contact person name must contains only alphabets`,
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": `Email is required`,
      "string.empty": `Email is required`,
      "string.pattern.base": `Email is Invalid`,
    }),
  phoneNo: Joi.string().regex(/^\d+$/).min(7).max(15).required().messages({
    "string.max": `Phone number must be at most 15 characters long`,
    "string.min": `Phone number must be minimum 7 characters long`,
    "any.required": `Phone number is required`,
    "string.empty": `Phone number is required`,
    "string.pattern.base": `Phone number is Invalid`,
  }),
  password: Joi.string()
    .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    .required()
    .messages({
      "any.required": `Password is required`,
      "string.empty": `Password is invalid`,
      "string.pattern.base": `Password is Invalid`,
    }),
});

export default Schema;
