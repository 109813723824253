const Joi = require("joi");

const Schema = Joi.object({
  oldPassword: Joi.string().required().messages({
    "any.required": `Current password is required.`,
    "string.empty": `Current password is required.`,
  }),
  newPassword: Joi.string()
    .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
    .required()
    .messages({
      "any.required": `Confirm Password is required.`,
      "string.empty": `Confirm Password is required.`,
      "string.pattern.base": `Password is Invalid`,
    }),
});

export default Schema;
