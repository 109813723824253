import React from "react";
import { Box, Button } from "@mui/material";
import ErrorImage from "../../assets/images/404.png";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";

const PageNotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Box
      sx={{
        background: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: { xs: "space-evenly", md: "center" },
        alignItems: "center",
        height: "95vh",
      }}
    >
      <Box
        component="img"
        src={ErrorImage}
        alt="404 Pic"
        sx={{
          // minWidth: { xs: "350px" },
          width: { xs: "100%", sm: "90%", md: "80%" },
          height: { xs: "350px", sm: "80%", md: "90%" },
        }}
      ></Box>
      <Button
        variant="contained"
        sx={{
          background: "red",
          width: "150px",
          height: "45px",
          textTransform: "none",
          fontWeight: theme.fonts.fontWeightBold,
          fontSize: "14px",
          fontFamily: theme.fonts.livvic,
          "&:hover": {
            background: theme.colors.divider.red,
          },
        }}
        onClick={handleGoBack}
      >
        Go Back
      </Button>
    </Box>
  );
};

export default PageNotFound;
