import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";

import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import CongratulationsIcon from "../../../../../../assets/icons/CongratulationsIcon.svg";
import AI_Icon from "../../../../../../assets/icons/AI_Icon.svg";
import ErrorIcon from "../../../../../../assets/icons/ErrorIcon.svg";
import BlueCrossIcon from "../../../../../../assets/icons/BlueCrossIcon.svg";
import AssistantIcon from "@mui/icons-material/Assistant";
import { blue, red } from "@mui/material/colors";
import { useSelector } from "react-redux";

const CongratulationModal = ({ handleModalClose, analysis, setFormData }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { plan } = useSelector((state) => state?.authData?.userData);

  const handleReUpload = () => {
    // setFormData(null);
    setFormData((prevFormData) => ({
      ...prevFormData,
      contract: null,
      reupload: true,
    }));
    handleModalClose();
  };

  const handleSkip = () => {
    localStorage.removeItem("campaignId");
    navigate("/dashboard/campaigns");
  };

  return (
    <DialogContent
      sx={{
        px: "40px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "grey",
          borderRadius: "4px",
        },
      }}
    >
      {/* <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="40px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleModalClose}
      ></Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "center",
          textAlign: "center",
          gap: 1,
        }}
      >
        <Box
          component="img"
          src={CongratulationsIcon}
          alt="Congratulations Icon"
          width="200px"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h5"
        >
          Congratulations!
        </Typography>
        <Typography fontFamily={theme.fonts.livvic}>
          Your contract file is uploaded
        </Typography>

        {(plan === "ALL-STAR" || plan === "SUPERSTAR") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              width: "100%",
              border: `1px solid ${theme.colors.text.greyDarker}`,
              padding: "5px 10px",
              background: "rgba(29,29,29,1)",
              my: 1,
            }}
          >
            <Box component="img" src={AI_Icon} alt="AI Icon"></Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                  fontSize: "20px",
                }}
              >
                AI suggestions for the uploaded Contract File
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  color: "rgba(197,197,197,1)",
                }}
              >
                Idea is to keep it simple and easy to understand
              </Typography>
            </Box>
          </Box>
        )}

        {plan === "ALL-STAR" || plan === "SUPERSTAR" ? (
          <Box
            sx={{
              textAlign: "left",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontWeight: theme.fonts.fontWeightMedium,
                fontSize: "20px",
              }}
            >
              Suggestions
            </Typography>
            <Box
              sx={{
                background: "rgba(175,172,172,1)",
                color: theme.colors.common.black,
                my: 1,
                p: "5px 10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box component="img" src={ErrorIcon} alt="Error Icon"></Box>
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: "18px",
                  }}
                >
                  Weak Arguments
                </Typography>
              </Box>
              <Box>
                {analysis?.weak_points && analysis?.weak_points.length > 0 ? (
                  analysis?.weak_points?.map((point) => {
                    return (
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          fontWeight: theme.fonts.fontWeightMedium,
                        }}
                      >
                        <span
                          style={{
                            color: red[800],
                          }}
                        >
                          &bull;&nbsp;{point}
                        </span>
                      </Typography>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      ml: "30px",
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                    }}
                  >
                    No weak arguments
                  </Typography>
                )}
                {/* <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                }}
              >
                Campaign will have to complete{" "}
                <span
                  style={{
                    color: theme.colors.text.red,
                  }}
                >
                  sooner.
                </span>
              </Typography> */}
              </Box>
            </Box>
            <Box
              sx={{
                background: "rgba(175,172,172,1)",
                color: theme.colors.common.black,
                my: 1,
                p: "5px 10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* <Box
                component="img"
                src={BlueCrossIcon}
                alt="Blue Cross Icon"
              ></Box> */}
                <AssistantIcon sx={{ color: blue[600] }} />
                <Typography
                  sx={{
                    fontFamily: theme.fonts.livvic,
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: "18px",
                  }}
                >
                  Recommended Changes
                </Typography>
              </Box>
              <Box>
                {analysis?.suggestions && analysis?.suggestions.length > 0 ? (
                  <div>
                    {analysis.suggestions?.map((point) => {
                      return (
                        <Typography
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            fontFamily: theme.fonts.livvic,
                            fontWeight: theme.fonts.fontWeightMedium,
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              color: "rgba(0,65,161,1)",
                              margin: "0 4px",
                              verticalAlign: "middle",
                            }}
                          >
                            &bull;&nbsp;{point}
                          </span>
                          {/* <Button
                      sx={{
                        borderRadius: "5px",
                        p: "2px 4px",
                        height: "18px",
                        width: "36px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme.colors.common.white,
                        background: theme.colors.text.red,
                        cursor: "pointer",
                        fontSize: 9,
                        fontWeight: theme.fonts.fontWeightMedium,
                        "&:hover": {
                          background: theme.colors.divider.red,
                        },
                        verticalAlign: "middle"
                      }}
                      // onClick={handleReUpload}
                    >
                      APPLY
                    </Button> */}
                        </Typography>
                      );
                    })}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {/* <Button
                      sx={{
                        borderRadius: "5px",
                        p: "4px 8px",
                        height: "30px",
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme.colors.common.white,
                        background: theme.colors.text.red,
                        cursor: "pointer",
                        fontSize: 12,
                        fontWeight: theme.fonts.fontWeightMedium,
                        "&:hover": {
                          background: theme.colors.divider.red,
                        },
                        verticalAlign: "middle"
                      }}
                      // onClick={handleReUpload}
                    >
                      APPLY ALL
                    </Button> */}
                    </div>
                  </div>
                ) : (
                  <Typography
                    sx={{
                      ml: "30px",
                      fontFamily: theme.fonts.livvic,
                      fontWeight: theme.fonts.fontWeightMedium,
                    }}
                  >
                    No recommended Changes
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography
            variant="h5"
            sx={{
              fontFamily: theme.fonts.livvic,
              fontWeight: "bold",
              mt: 2,
              mb: 1,
            }}
          >
            Upgrade your plan to get AI suggestions.
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "flex-end",
          justifyContent: "center",
          width: "100%",
          mt: 2,
        }}
      >
        <Button
          sx={{
            borderRadius: "5px",
            p: "5px 10px",
            height: "42px",
            width: "170px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme.colors.common.white,
            background: theme.colors.text.red,
            cursor: "pointer",
            fontWeight: theme.fonts.fontWeightMedium,
            "&:hover": {
              background: theme.colors.divider.red,
            },
          }}
          onClick={handleReUpload}
        >
          REUPLOAD
        </Button>

        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            color: theme.colors.text.greyDark,
            cursor: "pointer",
          }}
          onClick={handleSkip}
        >{`CONTINUE >`}</Typography>
      </Box>
    </DialogContent>
  );
};

export default CongratulationModal;
