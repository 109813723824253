import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
// import ApplePodcasts from "../../../../assets/images/ApplePodcasts.png";
// import SpotifyPodcasts from "../../../../assets/images/SpotifyPodcasts.png";

import { dashboardAthletesDetails } from "../../../../_mock/dummyData";
import PodcastsCard from "../../../../components/afterLogin/brands/podcasts/subComponents/podcasts.card";

import ApplePodcasts from "../../../../assets/icons/ApplePodcasts.svg";
import SpotifyPodcasts from "../../../../assets/icons/SpotifyPodcasts.svg";

import { BASE_IMAGE_URL } from "../../../../utils/constants";
import Pulse from "../../../../themes/@overrides/pulse";
import { Helmet } from "react-helmet-async";
import { getPodcasts } from "../../../../services/podcasts/podcast.service";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";

const Podcasts = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const ApplePodcasts = `${BASE_IMAGE_URL}/ApplePodcasts.png`;

  // const SpotifyPodcasts = `${BASE_IMAGE_URL}/SpotifyPodcasts.png`;

  const [podcastsDetails, setPodcastsDetails] = useState([]);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const fetchPodcasts = async () => {
    const { data } = await getPodcasts();
    setIsAllDataLoading(false);
    setPodcastsDetails(data);
  };

  useEffect(() => {
    fetchPodcasts();
  }, []);

  return (
    <>
      <Helmet>
        <title>Podcasts | Burrst</title>
      </Helmet>
      <Box
        sx={{
          paddingX: "30px",
          "@media (max-width:600px)": {
            paddingX: "10px",
          },
        }}
      >
        <Box mt="115px" mb="30px">
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
            <Pulse />
            <Typography
              color={theme.colors.common.white}
              fontFamily={theme.fonts.russoOne}
              variant="h3"
            >
              Podcasts
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={4} mb={5}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton
                    type="img"
                    sx={{
                      width: { xs: "100%", sm: "80%", md: "90%", lg: "80%" },
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <Link
                    to="https://podcasts.apple.com/us/podcast/the-next-generation-of-superstars/id1660120217"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      component="img"
                      src={ApplePodcasts}
                      alt="Apple Podcasts"
                      loading="lazy"
                      sx={{
                        width: { xs: "100%", sm: "80%", md: "90%", lg: "80%" },
                        cursor: "pointer",
                      }}
                    ></Box>
                  </Link>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                sx={{ textAlign: { xs: "center", md: "right" } }}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton
                    type="img"
                    sx={{
                      width: { xs: "100%", sm: "80%", md: "90%", lg: "80%" },
                      height: "80px",
                      borderRadius: "10px",
                    }}
                  />
                ) : (
                  <Link
                    to="https://open.spotify.com/show/5iq0Qu8nTCNz8ZrURqSLRF?si=OfOGohkjR9uxWDx3puSpXA'"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      component="img"
                      src={SpotifyPodcasts}
                      alt="Spotify Podcasts"
                      loading="lazy"
                      sx={{
                        width: { xs: "100%", sm: "80%", md: "90%", lg: "80%" },
                        cursor: "pointer",
                      }}
                    ></Box>
                  </Link>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              variant="h4"
              width="100%"
              mt={4}
              mb={1}
            >
              Episodes
            </Typography>
          </Grid>

          {isAllDataLoading
            ? Array.from({ length: 10 }, (_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{
                    display: { xs: "flex", md: "block" },
                    justifyContent: { xs: "center" },
                  }}
                  key={index}
                >
                  <PodcastsCard isAllDataLoading={isAllDataLoading} />
                </Grid>
              ))
            : podcastsDetails?.map((detail, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  sx={{
                    display: { xs: "flex", md: "block" },
                    justifyContent: { xs: "center" },
                  }}
                  key={index}
                >
                  <PodcastsCard detail={detail} />
                </Grid>
              ))}
        </Grid>
      </Box>
    </>
  );
};

export default Podcasts;
