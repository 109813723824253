import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getCampaignsViewDetails } from "../../../../../services/campaign/campaign.service";
import ActivityDetailsCard from "../../../../../components/afterLogin/athletes/home/subComponents/campaigns/activityDetails.card";

const ActivityDetailsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [activityDetails, setActivityDetails] = useState([]);
  const [isContractSigned, setIsContractSigned] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const fetchActivityDetails = async () => {
    const { data } = await getCampaignsViewDetails(location?.state?.campaignId);
    setIsContractSigned(data?.data?.signed);
    setActivityDetails(data?.data?.deliverables);
  };

  useEffect(() => {
    fetchActivityDetails();
  }, []);

  return (
    <Grid
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        justifyContent: "space-between",
        paddingX: { xs: "10px", sm: "30px" },
        mb: "10px",
      }}
    >
      <Grid my="20px" display="flex" alignItems="center" gap={1}>
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", sm: "40px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
        >
          Activities Details
        </Typography>
      </Grid>
      <Grid
        sx={{
          paddingX: { xs: "10px", md: "30px" },
          paddingY: "15px",
          backgroundColor: "#121212",
          border: `0.5px solid #121212`,
          color: "white",
          borderRadius: "5px",
        }}
      >
        {activityDetails?.map((detail, index) => (
          <ActivityDetailsCard
            detail={detail}
            campaignId={location?.state?.campaignId}
            key={index}
            isContractSigned={isContractSigned}
            fetchActivityDetails={fetchActivityDetails}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ActivityDetailsPage;
