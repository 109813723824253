import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { Circle, InsertDriveFileOutlined } from "@mui/icons-material";

import EducationBackground from "../../../../assets/images/EducationBackground.png";
import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
import DownloadBoldIcon from "../../../../assets/icons/DownloadBoldIcon.svg";
import DefaultBrandLogo from "../../../../assets/logo/DefaultBrandLogo.png";
import SupportAgentIcon from "../../../../assets/icons/SupportAgentIcon.svg";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

const EarningDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const detail = location?.state?.detail;

  const handleBack = () => {
    navigate(-1);
  };

  const handleSupportAgentIcon = () => {
    navigate("/dashboard/chat/admin");
  };

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
  };

  const handleDownloadIconClick = (downloadLink, fileName) => {
    saveAs(downloadLink, fileName);
  };

  return (
    <Box
      sx={{
        paddingX: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
      }}
    >
      {/* -----------------Header------------------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box
            component="img"
            src={LeftArrow}
            alt="Left Arrow"
            sx={{ cursor: "pointer", width: { xs: "24px", sm: "30px" } }}
            onClick={handleBack}
          ></Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: { xs: theme.fonts.heading_xs, sm: theme.fonts.heading },
            }}
          >
            {detail?.brandName}
          </Typography>
        </Box>
        <Box
          component="img"
          src={SupportAgentIcon}
          alt="Support Agent Icon"
          sx={{ width: "26px", cursor: "pointer" }}
          onClick={handleSupportAgentIcon}
        ></Box>
      </Box>
      <Box sx={{ mb: "30px", pl: { xs: "30px", sm: "40px" } }}>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            color: theme.colors.text.greyDark,
          }}
        >
          {dayjs(detail?.transaction?.createdAt).format("MM-DD-YYYY hh:mm:ss")}
        </Typography>
      </Box>

      <Box
        p="20px"
        mb="30px"
        sx={{
          background: theme.colors.dashboard.grey200,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            // height: "200px",
            border: `1px solid ${theme.colors.text.greyLight}`,
            mb: "30px",
            background: "black",
            p: "20px",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <Box
              sx={{
                minWidth: "120px",
                maxWidth: "125px",
                height: "120px",
                mb: { xs: 2, sm: 0 },
                // border: `1px solid ${theme.colors.text.greyLight}`,
              }}
            >
              <Box
                component="img"
                src={detail?.brandLogo}
                alt="Default Icon"
                onError={handleImageError}
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
              ></Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: {
                    xs: theme.fonts.subHeading_xs,
                    sm: theme.fonts.subHeading,
                  },
                  fontWeight: theme.fonts.fontWeightBold,
                }}
              >
                {detail?.campaignName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  color: theme.colors.text.greyLight,
                  pr: "10px",
                  height: { xs: "auto", sm: "75px" },
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: theme.colors.background.formInput,
                    borderRadius: "4px",
                  },
                }}
              >
                {detail?.brief}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              my: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: theme.fonts.cardSubHeading,
              }}
            >
              Activities
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: theme.fonts.cardSubHeading,
              }}
            >
              {detail?.activityName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              my: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: theme.fonts.cardSubHeading,
              }}
            >
              Compensation
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: theme.fonts.cardSubHeading,
                color: "rgba(16, 210, 12, 1)",
              }}
            >
              {`$${detail?.transaction?.amount}`}
            </Typography>
          </Box>

          <Box my={2}>
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                fontSize: theme.fonts.cardSubHeading,
                mb: 1,
              }}
            >
              Proof file
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              backgroundColor="#414141"
              borderRadius="5px"
              border="1px solid #797979"
              py="5px"
              px="10px"
              maxWidth="400px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    background: "rgba(217,217,217,0.85)",
                    borderRadius: "5px",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                    py: 2,
                    px: 1.5,
                    mr: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <InsertDriveFileOutlined
                    sx={{
                      color: "rgba(61,44,44,1)",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    color: "#C5C5C5",
                    fontSize: { xs: "12px", sm: "14px", md: "16px" },
                    fontWeight: theme.fonts.fontWeightRegular,
                    fontFamily: theme.fonts.livvic,
                  }}
                >
                  {detail?.activityDetails?.proofFile.split("/").pop()}
                </Typography>
              </Box>
              <Box
                component="img"
                src={DownloadBoldIcon}
                alt="Download Icon"
                sx={{
                  cursor: "pointer",
                  width: "24px",
                }}
                onClick={() =>
                  handleDownloadIconClick(
                    detail?.activityDetails?.proofFile,
                    detail?.activityDetails?.proofFile.split("/").pop()
                  )
                }
              ></Box>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              sx={{
                fontSize: theme.fonts.cardSubHeading,
                fontFamily: theme.fonts.livvic,
                mb: 1,
              }}
            >
              URL
              <span
                style={{
                  color: "#D9D9D9",
                  fontWeight: theme.fonts.fontWeightRegular,
                }}
              >
                (optional)
              </span>
            </Typography>
            <TextField
              name="url"
              value={detail?.activityDetails?.url || ""}
              multiline
              fullWidth
              size="small"
              autoComplete="off"
              InputProps={{
                sx: {
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: "10px",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    fieldset: {
                      borderColor: theme.colors.common.white,
                    },
                  },
                },
              }}
            />
          </Box>
          <Box my={2}>
            <Typography
              sx={{
                fontSize: theme.fonts.cardSubHeading,
                fontFamily: theme.fonts.livvic,
                mb: 1,
              }}
            >
              Description
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
              }}
            >
              {detail?.activityDetails?.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EarningDetails;
