import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
// import BurrstLogo from "../../assets/logo/BurrstLogo.svg";
import LoginBackground from "../../assets/images/BackgrounImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_IMAGE_URL } from "../../utils/constants";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Schema from "../../joi.schema/auth/resetPassword.Schema";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputPasswordLockIcon from "../../assets/icons/InputPasswordLockIcon.svg";
import InputConfirmPasswordLockIcon from "../../assets/icons/InputConfirmPasswordLockIcon.svg";
import { authResetPassword } from "../../services/auth/auth.service";
import PasswordResetSuccessfulModal from "../../components/auth/subComponent/passwordResetSuccessfulModal";

const ResetPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  //const LoginBackground = `${BASE_IMAGE_URL}/loginBackground.svg`;

  const [inputValue, setInputValue] = useState("");
  const [isTextFieldFocused, setTextFieldFocused] = useState(false);
  const [formData, setFormData] = useState({
    email: location.state.email,
    temporaryPassword: "",
    newPassword: "",
  });
  const [password, setPassword] = useState("");
  const [showTemporaryPassword, setShowTemporaryPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successPswrdModalOpen, setSuccessPswrdModalOpen] = useState(false);

  const handlesuccessModalClose = () => {
    setSuccessPswrdModalOpen(false);
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleInputChange = (event) => {
    setPassword(event.target.value);
    const value = event.target.value;
    setInputValue(value);
  };

  const handleTextFieldFocus = (event) => {
    setTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setTextFieldFocused(false);
  };

  const handleBurrstLogo = () => {
    navigate("/");
  };

  const handleBack = () => {
    navigate("/login");
  };

  const containsNumber = /\d/.test(inputValue);
  const containsUppercase = /[A-Z]/i.test(inputValue);
  const containsSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(inputValue);

  const handleSubmit = async () => {
    const { error } = Schema.validate(formData);

    if (error) {
      error?.details?.forEach((errors) => {
        toast.error(errors.message);
      });
    } else if (password === null) {
      toast.error("Confirm Password is required");
      return;
    } else if (password !== formData.newPassword) {
      toast.error("Confirm Password does not match New Password");
      return;
    } else {
      try {
        const response = await authResetPassword(formData);
        if (response.status === 200) {
          //alert("Password reset successsfullly.");
          setSuccessPswrdModalOpen(true);
          //navigate("/login");
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error(error.message);
        console.error("Error during login:", error.message);
      }
    }
  };

  useEffect(() => {}, [formData, password]);

  return (
    <>
      <Helmet>
        <title>Reset Password | Burrst</title>
      </Helmet>

      <Box
        sx={{
          position: "fixed",
          left: "20px",
          top: "20px",
          width: "35px",
          height: "35px",
          cursor: "pointer",
          backgroundColor: theme.colors.common.black,
          color: theme.colors.text.greyLight,
          borderRadius: "5px",
          boxShadow: "0px 0px 7px 0px rgba(197, 197, 197, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleBack}
      >
        <ArrowBackIcon sx={{ fontSize: "25px" }} />
      </Box>
      <Box
        component="img"
        src={LoginBackground}
        alt="Login Background"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          objectFit: "cover",
          zIndex: "-1",
          height: "100vh",
          // width: "100vw",
        }}
      ></Box>

      <Grid
        container
        spacing={2}
        sx={{
          paddingX: { xs: "10px", md: "30px" },
          pt: "20px",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "center", md: "center" },
            textAlign: "left",
            height: { xs: "250px", sm: "350px", md: "97vh" },
            mb: { xs: "20px", md: "0" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: "90%", sm: "80%", md: "auto" },
              mt: { xs: "60px", md: 0 },
            }}
          >
            <img
              src={BurrstLogo}
              onClick={handleBurrstLogo}
              alt="Burrst Logo"
              width="40%"
              style={{ cursor: "pointer", marginBottom: 15 }}
            />
            <Typography
              color={theme.colors.text.greyLight}
              fontFamily={theme.fonts.russoOne}
              fontWeight={theme.fonts.fontWeightMedium}
              fontSize={{ xs: "24px", sm: "38px", md: "34px", lg: "46px" }}
            >
              THE NEXT GENERATION
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: { xs: "flex-start", md: "center" },
            height: { xs: "auto", md: "97vh" },
            mb: { xs: "30px", md: "0" },
            overflowY: { md: "scroll" },
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: theme.colors.background.formInput,
              borderRadius: "4px",
            },
          }}
        >
          {/* <ToastContainer position="top-right" autoClose={5000} /> */}

          <Box width={{ xs: "90%", sm: "80%" }}>
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                fontWeight: 900,
                fontSize: { xs: "24px", sm: "26px" },
                marginBottom: "20px",
              }}
            >
              Reset Password
              <span style={{ color: "#FF0000" }}>.</span>
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightRegular,
                    fontSize: {
                      xs: theme.fonts.registrationSubHeading_xs,
                      md: theme.fonts.registrationSubHeading_sm,
                    },
                    marginBottom: "5px",
                  }}
                >
                  Temporary Password
                </Typography>

                <TextField
                  name="temporaryPassword"
                  type={showTemporaryPassword ? "text" : "password"}
                  placeholder="Temporary password"
                  fullWidth
                  size="small"
                  // autoComplete="off"
                  value={formData.temporaryPassword}
                  onChange={(e) =>
                    handleChange("temporaryPassword", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputPasswordLockIcon}
                          alt="Input Password Icon"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() =>
                            setShowTemporaryPassword(!showTemporaryPassword)
                          }
                        >
                          {showTemporaryPassword ? (
                            <Visibility
                              sx={{ color: theme.colors.text.greyLight }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{ color: theme.colors.text.greyLight }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      border: `1px solid ${theme.colors.common.white}`,
                      background: theme.colors.background.formInput,
                      color: theme.colors.common.white,
                      borderRadius: "10px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                    "& input, & textarea, & select": {
                      WebkitTextFillColor: "#FFFFFF",
                      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                    // "& input:hover, & input:focus, & textarea:hover, & textarea:focus, & select:hover, & select:focus":
                    //   {
                    //     borderColor: "theme.colors.common.white",
                    //   },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightRegular,
                    fontSize: {
                      xs: theme.fonts.registrationSubHeading_xs,
                      md: theme.fonts.registrationSubHeading_sm,
                    },
                    marginBottom: "5px",
                  }}
                >
                  New password
                </Typography>
                <TextField
                  name="password"
                  placeholder="New password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  size="small"
                  // autoComplete="off"
                  value={password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputPasswordLockIcon}
                          alt="Input Password Icon"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <Visibility
                              sx={{ color: theme.colors.text.greyLight }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{ color: theme.colors.text.greyLight }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      border: `1px solid ${theme.colors.common.white}`,
                      background: theme.colors.background.formInput,
                      color: theme.colors.common.white,
                      borderRadius: "10px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                    "& input, & textarea, & select": {
                      WebkitTextFillColor: "#FFFFFF",
                      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }}
                  onChange={handleInputChange}
                  onFocus={handleTextFieldFocus}
                  onBlur={handleTextFieldBlur}
                />

                {isTextFieldFocused && (
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    sx={{
                      fontWeight: 500,
                      fontSize: "16px",
                      "@media (max-width: 480px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    Password must be at least
                    <span
                      style={{
                        color: inputValue.length >= 8 ? "#FFFFFF" : "#FF0000",
                      }}
                    >
                      {inputValue.length >= 8
                        ? " 8 character "
                        : " 8 characters "}
                    </span>
                    and contain
                    {containsNumber ? (
                      <span style={{ color: "#FFFFFF" }}> 1 number</span>
                    ) : (
                      <span style={{ color: "#FF0000" }}> 1 number</span>
                    )}
                    ,
                    <br />
                    {containsUppercase ? (
                      <span style={{ color: "#FFFFFF" }}>1 alphabet </span>
                    ) : (
                      <span style={{ color: "#FF0000" }}>1 alphabet </span>
                    )}
                    and
                    {containsSymbol ? (
                      <span style={{ color: "#FFFFFF" }}> 1 symbol</span>
                    ) : (
                      <span style={{ color: "#FF0000" }}> 1 symbol</span>
                    )}
                    .
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightRegular,
                    fontSize: {
                      xs: theme.fonts.registrationSubHeading_xs,
                      md: theme.fonts.registrationSubHeading_sm,
                    },
                    marginBottom: "5px",
                  }}
                >
                  Confirm new password
                </Typography>
                <TextField
                  name="confirmPassword"
                  placeholder="Confirm new password"
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  size="small"
                  // autoComplete="off"
                  value={formData.password}
                  onChange={(e) => handleChange("newPassword", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputConfirmPasswordLockIcon}
                          alt="Input Confirm Password Icon"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <Visibility
                              sx={{ color: theme.colors.text.greyLight }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{ color: theme.colors.text.greyLight }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      border: `1px solid ${theme.colors.common.white}`,
                      background: theme.colors.background.formInput,
                      color: theme.colors.common.white,
                      borderRadius: "10px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                    "& input, & textarea, & select": {
                      WebkitTextFillColor: "#FFFFFF",
                      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  //onClick={() => navigate("/login")}
                  onClick={() => handleSubmit()}
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                    width: "100%",
                    height: "45px",
                    mt: "20px",
                    borderRadius: theme.fonts.smallBorderRadius,
                    backgroundColor: "#DD0000",
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: "18px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "darkred",
                    },
                  }}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Dialog
            open={successPswrdModalOpen}
            //onClose={handleOtpModalClose}
            PaperProps={{
              sx: {
                width: "80vw",
                background: theme.colors.common.black,
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <PasswordResetSuccessfulModal
              handlesuccessModalClose={handlesuccessModalClose}
            />
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
