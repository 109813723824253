import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotifications,
  markRead,
} from "../services/notifications/notifications.service";

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async () => {
    const response = await getNotifications();
    return response?.data?.data;
  }
);

export const markNotificationsAsRead = createAsyncThunk(
  "notifications/markNotificationsAsRead",
  async () => {
    await markRead();
    const response = await getNotifications();
    return response?.data?.data;
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    list: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(markNotificationsAsRead.fulfilled, (state, action) => {
        state.list = action.payload;
      });
  },
});

export default notificationsSlice.reducer;
