import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import {
  brandCurrentSubscriptionPlans,
  brandSubscribedCheck,
  brandSubscriptionPlans,
  getContactSales,
  startTrial,
} from "../../../services/brand/brand.service";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/authSlice";
import CryptoJS from "crypto-js";
import { PADDING_X } from "../../../utils/constants";
import { SupportAgent } from "@mui/icons-material";
import FontsLoader from "../../fontsLoader";
// import BurrstSmallLogo from "BurrstSmallLogo.svg";

// const PLANS_LIST = [
//   {
//     planName: "BENCHWARMER",
//     planDescription: "Get Started With NIL Using Our Basic Features.",
//     planBenefits: [
//       "ROI data and analytics behind your athlete collaborations",
//       "NIL Deal Tracking System",
//       "Customizable Profile",
//       "Smart Contract Agreements",
//       "Simple Campaign Creation",
//       "AI-Chat Support",
//       "10% Service Fee",
//     ],
//     amount: "170",
//     showButton: true,
//     isRecommended: false,
//   },
//   {
//     planName: "ALL-STAR",
//     planDescription:
//       "You’re a powerhouse and have experience working with student-athletes and their NIL.",
//     planBenefits: [
//       "Everything in BENCHWARMER, plus more!",
//       "Enhanced data and Analytics",
//       "AI-Recommended Athletes",
//       "Improved campaigns utilizing AI",
//       "Weekly Reports",
//       "Hands-on Support",
//     ],
//     amount: "370",
//     showButton: true,
//     isRecommended: true,
//   },
//   {
//     planName: "SUPERSTAR",
//     planDescription:
//       "Needing a tailored option to drive more brand awareness or revenue? Let’s get to it.",
//     planBenefits: [
//       "Everything in ALL-STAR, plus more!",
//       "Customizable Dashboard",
//       "Strategic Campaign Creation",
//       "Data specific to your brand",
//       "AI-Features Customization",
//     ],
//     amount: null,
//     showButton: false,
//     isRecommended: false,
//   },
// ];

const SubscriptionPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const plan = useSelector((state) => state?.authData?.userData?.plan);
  const freeTrial = true;
  const profileState = "subscription";
  const params = new URLSearchParams(window.location.search);
  const clientSecret = params.get("success");
  const route = localStorage.getItem("route");

  const [formData, setFormData] = useState([]);
  const [brandSubscribedChecked, setBrandSubscribedChecked] = useState(null);
  const [subscriptionPlanLoading, setSubscriptionPlanLoading] = useState(true);
  const [leftPosition, setLeftPosition] = useState(20);
  const [loading, setLoading] = useState(false);
  const [loadingSales, setLoadingSales] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const calculateLeftPosition = () => {
    const maxContainerWidth = 1536;

    const viewportWidth = Math.max(window.innerWidth, maxContainerWidth);

    const marginLeft = 20;

    setLeftPosition((viewportWidth - maxContainerWidth) / 2 + marginLeft);
  };

  const handleDialogOpen = () => {
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleBack = () => {
    if (!brandSubscribedChecked?.trialStarted) {
      localStorage.clear();
      navigate("/login");
    } else if (route === "subscription") {
      localStorage.clear();
      navigate("/login");
    } else {
      navigate(-1);
    }
  };

  const handleSubscribe = async (subscriptionId) => {
    localStorage.setItem("subscriptionId", subscriptionId);
    localStorage.setItem("route", profileState);
    // setLoading(true);
    handleDialogOpen();
    const { data } = await startTrial(subscriptionId);
    // setLoading(false);
    handleDialogClose();
    if (data?.data?.link) window.open(`${data?.data?.link}`, "_self");
  };

  const handleContactSales = async () => {
    handleDialogOpen();
    const response = await getContactSales();
    handleDialogClose();
    if (response?.data?.status === 200) {
      toast.success(response?.data?.message);
    } else toast.error(response);
  };

  const callSubscriptionApi = async () => {
    const subscriptionResponse = await brandSubscribedCheck();
    setBrandSubscribedChecked(subscriptionResponse?.data);
  };

  const callApi = async () => {
    const response = await brandSubscriptionPlans();
    setSubscriptionPlanLoading(false);

    if (response.status === 200) {
      setFormData([...response.data]);
    } else {
      console.error("Failed to fetch campaigns list:", response.message);
    }
  };

  useEffect(() => {
    callSubscriptionApi();
    callApi();

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    if (plan === "STARTER" || plan === "ALL-STAR" || plan === "SUPERSTAR") {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", calculateLeftPosition);
    return () => {
      window.removeEventListener("resize", calculateLeftPosition);
    };
  }, []);

  useEffect(() => {
    if (clientSecret === "true") {
      toast.success("Payment done successfully");
      localStorage.setItem("freeTrial", freeTrial);
      setTimeout(() => {
        navigate("/dashboard/home");
      }, 2000);
    } else if (clientSecret === "false") {
      toast.error("Payment failed! Please retry.");
    }
  }, [clientSecret]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
          mb: "30px",
        }}
      >
        {subscriptionPlanLoading ? (
          <FontsLoader />
        ) : (
          <Box
            sx={{
              px: { xs: "10px", sm: "30px" },
              width: "100%",
              maxWidth: "1536px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: { xs: "relative", sm: "fixed" },
                left: { xs: "0px", sm: `${leftPosition}px` },
                top: "20px",
                width: "35px",
                height: "35px",
                cursor: "pointer",
                backgroundColor: theme.colors.common.black,
                color: theme.colors.text.greyLight,
                borderRadius: "5px",
                boxShadow: "0px 0px 7px 0px rgba(197, 197, 197, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleBack}
            >
              <ArrowBackIcon sx={{ fontSize: "25px" }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                mb: 3,
                mt: 5,
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: {
                    xs: theme.fonts.heading_xs,
                    sm: theme.fonts.heading,
                  },
                  fontWeight: "bold",
                }}
              >
                Pick Your Gameplan
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: { xs: "14px", sm: "16px" },
                  color: "red",
                }}
              >
                *Note- $30/month for every additional license user
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 5,
              }}
            >
              {formData?.map((plan, index) => (
                <Box
                  key={index}
                  sx={{
                    border: plan?.details?.isRecommended
                      ? "1px solid red"
                      : "none",
                    position: "relative",
                    width: "300px",
                  }}
                >
                  {plan?.details?.isRecommended && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "18px",
                        background: "rgba(256,0,0,0.7)",
                        textAlign: "center",
                        fontSize: "12px",
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      RECOMMENDED
                    </Box>
                  )}
                  <Box
                    sx={{
                      px: "10px",
                      pt: "20px",
                      pb: "10px",
                      background: "rgba(39,39,39,1)",
                      height: "130px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {plan?.planName}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: "14px",
                        color: theme.colors.text.greyLight,
                      }}
                    >
                      {plan?.details?.planDescription}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      px: "10px",
                      py: "10px",
                      background: "rgba(56,56,56,1)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                        minHeight: {
                          xs: "auto",
                          sm: plan?.details?.isRecommended ? "232px" : "234px",
                        },
                      }}
                    >
                      {plan?.details?.planBenefits?.map(
                        (benefits, benefitsIndex) => (
                          <Box
                            key={benefitsIndex}
                            sx={{ display: "flex", gap: 0.5 }}
                          >
                            <Box
                              component="img"
                              src="/BurrstSmallLogo.svg"
                              alt="BurrstSmallLogo"
                              height="20px"
                            ></Box>
                            <Typography
                              sx={{
                                fontFamily: theme.fonts.livvic,
                                lineHeight: 1.2,
                              }}
                            >
                              {benefits}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>

                    <Typography
                      sx={{
                        height: "20px",
                        textAlign: "center",
                        fontFamily: theme.fonts.livvic,
                        mt: 1,
                        mb: 2,
                      }}
                    >
                      {plan?.details?.showButton
                        ? `$${plan?.amount}/month`
                        : ""}
                    </Typography>

                    <Box sx={{ textAlign: "center", my: 1 }}>
                      <Button
                        variant={
                          plan?.details?.isRecommended
                            ? "contained"
                            : "outlined"
                        }
                        fullWidth
                        sx={{
                          color: "white",
                          fontFamily: "livvic",
                          textTransform: "none",
                          background: plan?.details?.isRecommended
                            ? "rgba(255, 0, 0, 1)"
                            : "transparent",
                          border: plan?.details?.isRecommended
                            ? "none"
                            : "1px solid rgba(255, 0, 0, 1)",
                          borderRadius: "10px",
                          "&:hover": {
                            background: "rgba(255, 0, 0, 0.5)",
                            border: plan?.details?.isRecommended
                              ? "none"
                              : "1px solid rgba(255, 0, 0, 1)",
                          },
                        }}
                        onClick={
                          plan?.details?.showButton
                            ? () => handleSubscribe(plan?._id)
                            : () => handleContactSales()
                        }
                      >
                        {plan?.details?.showButton
                          ? // loading ? (
                            //   <CircularProgress sx={{ color: "gray" }} />
                            // ) : (
                            `Get Started`
                          : // )
                            // loadingSales ? (
                            //   <CircularProgress sx={{ color: "gray" }} />
                            // ) : (
                            "Contact Sales"}
                      </Button>
                    </Box>

                    <Typography
                      sx={{
                        height: { sm: "40px" },
                        fontFamily: theme.fonts.livvic,
                        mb: 1,
                      }}
                    >
                      {plan?.details?.showButton
                        ? `30-day free trial of ${plan?.planName} features`
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Dialog
              open={showDialog}
              PaperProps={{
                sx: {
                  width: "auto",
                  boxShadow: "none",
                  borderRadius: "50%",
                  backgroundColor: "rgb(255,255,255,0)",
                },
              }}
              sx={{
                backdropFilter: "blur(3px)",
                backgroundColor: "rgb(255,255,255,0.3)",
              }}
            >
              <DialogContent>
                <CircularProgress sx={{ color: "gray" }} />
              </DialogContent>
            </Dialog>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SubscriptionPage;
