import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Input,
  Typography,
  useTheme,
} from "@mui/material";
import BurrstLogoIcon from "../../../../../assets/icons/BurrstLogoIcon.svg";
import CoverImg from "../../../../../assets/images/LatestArticles.webp";
import AvatarImg from "../../../../../assets/images/AIrecommended.png";
import InstagramIcon from "../../../../../assets/icons/InstagramPublicProfileIcon.svg";
import FacebookIcon from "../../../../../assets/icons/FacebookPublicProfileIcon.svg";
import TwitterIcon from "../../../../../assets/icons/TwitterPublicProfileIcon.svg";
import LinkedInIcon from "../../../../../assets/icons/LinkedInPublicProfileIcon.svg";
import Youtubecon from "../../../../../assets/icons/YoutubePublicProfileIcon.svg";
import TiktokIcon from "../../../../../assets/icons/TikTokPublicProfileIcon.svg";
import ChatIcon from "../../../../../assets/icons/ChatIcon2.svg";
import EditIcon from "../../../../../assets/icons/EditIcon.svg";
import DefaultImg from "../../../../../assets/images/DefaultAvatar.jpg";
import DefaultCoverImg from "../../../../../assets/images/DefaultCoverImage.png";
import AthleteImageUploadIcon from "../../../../../assets/icons/AthleteImageUploadIcon.svg";
import {
  athleteUpdateProfileDetails,
  athleteUpdatePublicProfile,
} from "../../../../../services/athlete/athlete.service";
import { getToken } from "../../../../../routes";
import { useDispatch } from "react-redux";
import {
  getChatUsers,
  setIsUserSelected,
  setSelectedUser,
} from "../../../../../store/actions/chatActions";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const ProfileImages = ({ detail, isUpdated, setIsUpdated }) => {
  const theme = useTheme();
  const location = useLocation();
  const token = getToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMyProfile = location?.state?.isMyProfile;

  const [isBrandLogoError, setIsBrandLogoError] = useState(false);
  const [isProfileImageLoading, setIsProfileImageLoading] = useState(false);
  const [isCoverImageLoading, setIsCoverImageLoading] = useState(false);

  const handleCoverImg = () => {
    setIsBrandLogoError(true);
  };

  const handleMessage = () => {
    dispatch(setSelectedUser(detail));
    dispatch(setIsUserSelected(true));
    setTimeout(() => {
      dispatch(getChatUsers());
      navigate("/dashboard/chat");
    }, 1000);
  };

  const handleImageChange = async (e, editImageType) => {
    const file = e.target.files[0];
    if (file) {
      let response;

      if (editImageType === "coverImage") {
        setIsCoverImageLoading(true);
        response = await athleteUpdatePublicProfile({
          coverImage: file,
          loginToken: token,
        });
        setIsCoverImageLoading(false);
      } else if (editImageType === "profileImage") {
        setIsProfileImageLoading(true);
        response = await athleteUpdateProfileDetails({
          profileImage: file,
          loginToken: token,
        });
        setIsProfileImageLoading(false);
      }

      if (response?.status === 200) {
        setIsUpdated(!isUpdated);
      } else {
        console.error("Failed Response VideosAndImages Page", response);
      }
    }
  };

  return (
    <Grid my="10px" backgroundColor="#000000" borderRadius="5px">
      <Box>
        <Box
          sx={{
            height: { xs: "35vh", sm: "45vh", md: "50vh" },
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isCoverImageLoading ? (
            <CustomSkeleton
              type="img"
              sx={{ minWidth: "100%", height: "100%" }}
            />
          ) : (
            <>
              <Box
                component="img"
                src={detail?.coverImage}
                alt="Brand Logo"
                onError={handleCoverImg}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              ></Box>
            </>
          )}

          {isMyProfile && !isCoverImageLoading && (
            <Box component="label">
              <Box
                component="img"
                src={EditIcon}
                alt="Edit Icon"
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  width: "30px",
                }}
              ></Box>
              <Input
                type="file"
                inputProps={{ accept: "image/*" }}
                onChange={(e) => handleImageChange(e, "coverImage")}
                sx={{ display: "none" }}
              />
            </Box>
          )}
        </Box>
        <Box m="0px 15px 20px">
          <Box
            sx={{
              display: "flex",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              alignItems: "center",
              gap: { xs: 0, sm: 2 },
              mt: -4,
            }}
          >
            <Box
              sx={{
                width: "120px",
                height: "120px",
                position: "relative",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isProfileImageLoading ? (
                <CustomSkeleton
                  type="img"
                  sx={{ minWidth: "100%", height: "100%" }}
                />
              ) : (
                <Box
                  component="img"
                  src={detail?.profileImage || DefaultImg}
                  alt="Avatar"
                  id="avatar"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                  onError={(e) => {
                    e.target.src = DefaultImg;
                  }}
                ></Box>
              )}
              <Box
                component="img"
                src={BurrstLogoIcon}
                alt="Burrst Logo Icon"
                sx={{
                  position: "absolute",
                  top: "-32px",
                  right: "-30px",
                }}
                id="Burrst Logo Icon"
              ></Box>
              {isMyProfile && !isProfileImageLoading && (
                <Box
                  component="label"
                  sx={{
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    width: "26px",
                    height: "26px",
                    background: theme.colors.text.greyDarker,
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "5px",
                    padding: "6px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                      background: "black",
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={AthleteImageUploadIcon}
                    alt="Athlete Image Upload Icon"
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  ></Box>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    onChange={(e) => handleImageChange(e, "profileImage")}
                    sx={{ display: "none" }}
                  />
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: { xs: "100%", sm: "calc(100% - 120px)" },
                mt: { sm: 1 },
              }}
            >
              <Box
                marginTop="30px"
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontWeight={theme.fonts.fontWeightMedium}
                  fontSize={theme.fonts.sectionHeading}
                  sx={{ cursor: "pointer" }}
                >
                  {detail?.name || detail?.firstName + detail?.lastName}
                </Typography>
                {!isMyProfile && (
                  <Button
                    fontFamily={theme.fonts.livvic}
                    fontWeight={theme.fonts.fontWeightBold}
                    variant="contained"
                    size="small"
                    startIcon={
                      <Box
                        component="img"
                        src={ChatIcon}
                        alt="Message Icon"
                      ></Box>
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      background: "green",
                      width: "120px",
                      "&:hover": {
                        background: "darkGreen",
                      },
                    }}
                    onClick={handleMessage}
                  >
                    Message
                  </Button>
                )}
              </Box>
              <Box
                mt={2}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                gap={2}
                mb={2}
              >
                {detail.instagramLink && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      onClick={() =>
                        window.open(detail.instagramLink, "_blank")
                      }
                      backgroundColor={theme.colors.background.socialMedia}
                      height="20px"
                      width="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      boxShadow="0 0 6px -2px white"
                      sx={{
                        "&:hover": {
                          background: theme.colors.background.socialMediaLight,
                          color: theme.colors.text.linkedIn,
                          transition: "all 0.2s ease",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img
                        width="12px"
                        height="12px"
                        src={InstagramIcon}
                        alt="Instagram Icon"
                      />
                    </Box>
                    {detail?.instagramFollowerCount && (
                      <Typography>
                        {detail?.instagramFollowerCount < 1000
                          ? detail?.instagramFollowerCount
                          : detail?.instagramFollowerCount < 1000000
                          ? (detail?.instagramFollowerCount % 1000 === 0
                              ? (detail?.instagramFollowerCount / 1000).toFixed(
                                  0
                                )
                              : (detail?.instagramFollowerCount / 1000).toFixed(
                                  1
                                )) + "K"
                          : detail?.instagramFollowerCount % 1000000 === 0
                          ? (detail?.instagramFollowerCount / 1000000).toFixed(
                              0
                            ) + "M"
                          : (detail?.instagramFollowerCount / 1000000).toFixed(
                              1
                            ) + "M"}
                      </Typography>
                    )}
                  </Box>
                )}
                {detail.facebookLink && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      onClick={() => window.open(detail.facebookLink, "_blank")}
                      backgroundColor={theme.colors.background.socialMedia}
                      height="20px"
                      width="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      boxShadow="0 0 6px -2px white"
                      sx={{
                        "&:hover": {
                          background: theme.colors.background.socialMediaLight,
                          color: theme.colors.text.linkedIn,
                          transition: "all 0.2s ease",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img
                        width="12px"
                        height="12px"
                        src={FacebookIcon}
                        alt="Facebook Icon"
                      />
                    </Box>
                    {/* <Typography>N/A</Typography> */}
                  </Box>
                )}
                {detail.twitterLink && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      onClick={() => window.open(detail.twitterLink, "_blank")}
                      backgroundColor={theme.colors.background.socialMedia}
                      height="20px"
                      width="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      boxShadow="0 0 6px -2px white"
                      sx={{
                        "&:hover": {
                          background: theme.colors.background.socialMediaLight,
                          color: theme.colors.text.linkedIn,
                          transition: "all 0.2s ease",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img
                        width="12px"
                        height="12px"
                        src={TwitterIcon}
                        alt="Twitter Icon"
                      />
                    </Box>
                    {/* <Typography>N/A</Typography> */}
                  </Box>
                )}
                {detail.linkedinLink && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      onClick={() => window.open(detail.linkedinLink, "_blank")}
                      backgroundColor={theme.colors.background.socialMedia}
                      height="20px"
                      width="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      boxShadow="0 0 6px -2px white"
                      sx={{
                        "&:hover": {
                          background: theme.colors.background.socialMediaLight,
                          color: theme.colors.text.linkedIn,
                          transition: "all 0.2s ease",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img
                        width="12px"
                        height="12px"
                        src={LinkedInIcon}
                        alt="LinkedIn Icon"
                      />
                    </Box>
                    {/* <Typography>N/A</Typography> */}
                  </Box>
                )}
                {/* -----------------Youtube Icon---------------------- */}
                {detail?.youtubeLink && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      onClick={() => window.open(detail?.youtubeLink, "_blank")}
                      backgroundColor={theme.colors.background.socialMedia}
                      height="20px"
                      width="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      boxShadow="0 0 6px -2px white"
                      sx={{
                        "&:hover": {
                          background: theme.colors.background.socialMediaLight,
                          color: theme.colors.text.linkedIn,
                          transition: "all 0.2s ease",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img
                        width="12px"
                        height="12px"
                        src={Youtubecon}
                        alt="LinkedIn Icon"
                      />
                    </Box>
                    {/* <Typography>N/A</Typography> */}
                  </Box>
                )}
                {/* -----------------Tiktok Icon---------------------- */}
                {detail?.tiktokLink && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      onClick={() => window.open(detail?.tiktokLink, "_blank")}
                      backgroundColor={theme.colors.background.socialMedia}
                      height="20px"
                      width="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      boxShadow="0 0 6px -2px white"
                      sx={{
                        "&:hover": {
                          background: theme.colors.background.socialMediaLight,
                          color: theme.colors.text.linkedIn,
                          transition: "all 0.2s ease",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img
                        width="12px"
                        height="12px"
                        src={TiktokIcon}
                        alt="LinkedIn Icon"
                      />
                    </Box>
                    {detail?.tiktokFollowerCount && (
                      <Typography>
                        {detail?.tiktokFollowerCount < 1000
                          ? detail?.tiktokFollowerCount
                          : detail?.tiktokFollowerCount < 1000000
                          ? (detail?.tiktokFollowerCount % 1000 === 0
                              ? (detail?.tiktokFollowerCount / 1000).toFixed(0)
                              : (detail?.tiktokFollowerCount / 1000).toFixed(
                                  1
                                )) + "K"
                          : detail?.tiktokFollowerCount % 1000000 === 0
                          ? (detail?.tiktokFollowerCount / 1000000).toFixed(0) +
                            "M"
                          : (detail?.tiktokFollowerCount / 1000000).toFixed(1) +
                            "M"}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          backgroundColor="#121212"
          mx="2%"
          padding="1.5%"
          borderRadius="5px"
        >
          <Typography
            fontFamily={theme.fonts.montserrat}
            fontWeight={theme.fonts.fontWeightRegular}
            sx={{ fontSize: { xs: "15px", md: "18px" } }}
          >
            {/* "I've missed more than 9000 shots in my career. I've lost almost 300
            games. 26 times, I've been trusted to take the game-winning shot and
            missed. I've failed over and over and over again in my life. And
            that is why I succeed." */}
            {detail?.bio}
          </Typography>
        </Box>
        <Divider sx={{ mb: 4, mt: 1 }} />
      </Box>
    </Grid>
  );
};

export default ProfileImages;
