import { Suspense, useEffect, useRef } from "react";
import Router from "./routes";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";

import "@fontsource/russo-one";
import "@fontsource/poppins";
import "@fontsource/roboto";
import "@fontsource/work-sans";
import "@fontsource/livvic";
import "@fontsource/montserrat";

import React, { useState } from "react";
import { useTheme } from "@mui/material";

import { onMessageListener } from "./firebase";
import ReactPushNotification from "./pages/afterLogin/common/notifications/ReactPushNotification";
import ScreenLoader from "./pages/screenLoader";
import FontFaceObserver from "fontfaceobserver";

import Variant1 from "./assets/splashScreenImages/Variant1.png";
import Variant2 from "./assets/splashScreenImages/Variant2.png";
import Variant3 from "./assets/splashScreenImages/Variant3.png";
import Variant4 from "./assets/splashScreenImages/Variant4.png";
import Variant5 from "./assets/splashScreenImages/Variant5.png";
import Variant6 from "./assets/splashScreenImages/Variant6.png";
import splash1 from "./assets/splashScreenImages/splash1.png";
import splash2 from "./assets/splashScreenImages/splash2.png";
import splash3 from "./assets/splashScreenImages/splash3.png";
import splash4 from "./assets/splashScreenImages/splash4.png";
import splash5 from "./assets/splashScreenImages/splash5.png";
import splash6 from "./assets/splashScreenImages/splash6.png";
import splash7 from "./assets/splashScreenImages/splash7.png";
import splash8 from "./assets/splashScreenImages/splash8.png";
import splash9 from "./assets/splashScreenImages/splash9.png";
import splash10 from "./assets/splashScreenImages/splash10.png";
import splash11 from "./assets/splashScreenImages/splash11.png";
import FontsLoader from "./pages/fontsLoader";
import { ToastContainer, toast } from "react-toastify";
import { OTP_TIMER } from "./utils/constants";
import { fetchNotifications } from "./store/notificationsReducer";
import { useDispatch } from "react-redux";

const SplashImages = [
  splash1,
  splash2,
  splash3,
  // splash4,
  splash5,
  // splash6,
  splash7,
  splash8,
  splash9,
  // splash10,
  splash11,
  Variant1,
  Variant2,
  Variant3,
  Variant4,
  // Variant5,
  // Variant6,
];

function App() {
  const theme = useTheme();
  const splashChangeTimer = 200;

  const dispatch = useDispatch();

  let instagramBrowser;

  if ("serviceWorker" in navigator) {
    instagramBrowser = true;
  } else {
    instagramBrowser = false;
  }

  const [loading, setLoading] = useState(
    !sessionStorage.getItem("loadingShown")
  );
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isFontsLoaded, setIsFontsLoaded] = useState(false);

  if (instagramBrowser === true) {
    onMessageListener()
      .then((payload) => {
        setShow(true);
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
        dispatch(fetchNotifications());
      })
      .catch((err) => console.log("failed: ", err));
  }

  const showNotification = (title, body) => {
    toast.info(<ReactPushNotification title={title} body={body} />, {
      autoClose: 5000,
      closeOnClick: true,
    });
  };

  const loadFonts = async () => {
    try {
      const observers = [
        new FontFaceObserver(theme.fonts.russoOne),
        new FontFaceObserver(theme.fonts.roboto),
        new FontFaceObserver(theme.fonts.workSans),
        new FontFaceObserver(theme.fonts.livvic),
        new FontFaceObserver(theme.fonts.montserrat),
      ];
      await Promise.all(observers?.map((observer) => observer.load()));
      setIsFontsLoaded(true);

      const loadingShown = sessionStorage.getItem("loadingShown");
      if (!loadingShown) {
        setTimeout(() => {
          setLoading(false);
          sessionStorage.setItem("loadingShown", true);
        }, SplashImages.length * splashChangeTimer);
      }
    } catch (err) {
      console.log(err, "error in loadFonts");
    }
  };

  useEffect(() => {
    loadFonts();
    AOS.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      const key = event.key;
      if (key === "persist:root") {
        window.location.reload();
      }
      if (key === "otpTimer") {
        localStorage.setItem("otpTimer", OTP_TIMER);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <ToastContainer autoClose={5000} />
      {instagramBrowser === true &&
        show &&
        localStorage.getItem("token") &&
        notification?.title &&
        notification?.body &&
        showNotification(notification?.title, notification?.body)}

      {/* <Suspense fallback={<ScreenLoader />}> */}
      {loading ? (
        <ScreenLoader
          splashChangeTimer={splashChangeTimer}
          SplashImages={SplashImages}
        />
      ) : isFontsLoaded ? (
        <Router />
      ) : (
        <FontsLoader />
      )}
      {/* </Suspense> */}
    </>
  );
}

export default App;
