import { Box } from "@mui/material";
import React, { useState } from "react";
import StatisticsActive from "./statistics.active.list";
import StatisticsApplied from "./statistics.applied.list";
import { useLocation } from "react-router-dom";

const StatisticsPage = () => {
  const location = useLocation();
  const activeStatus = location?.state?.isActive;
  const [isActive, setIsActive] = useState(activeStatus);
  return isActive || isActive === undefined || isActive === null ? (
    <StatisticsActive setIsActive={setIsActive} />
  ) : (
    <StatisticsApplied setIsActive={setIsActive} />
  );
};

export default StatisticsPage;
