import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";

// import BurrstLogo from "../../assets/logo/BurrstLogo.svg";
import loginBackground from "../../assets/images/BackgrounImage.png";
import { authForgotPassword } from "../../services/auth/auth.service";
import { PADDING_X, BASE_IMAGE_URL } from "../../utils/constants";
import InputEmailIcon from "../../assets/icons/InputEmailIcon.svg";

const ForgotPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  //const loginBackground = `${BASE_IMAGE_URL}/loginBackground.svg`;
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  const [data, setData] = useState({
    email: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
  });

  const handleBurrstLogo = async () => {
    navigate("/");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleBack = () => {
    navigate("/login");
  };

  const handleSend = async () => {
    const errors = {};
    if (!data.email || !validateEmail(data.email)) {
      errors.email = "Invalid email address";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const isForgotPassword = await authForgotPassword(data.email);

      if (isForgotPassword.status === 200) {
        navigate("/reset-password", {
          state: {
            email: data.email,
          },
        });
      } else {
        toast.error(isForgotPassword.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error during login:", error.message);
    }
  };

  const handleChange = (field, value) => {
    setData({
      ...data,
      [field]: value,
    });
    setFormErrors({
      ...formErrors,
      [field]: "",
    });
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password | Burrst</title>
      </Helmet>
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Box>
        <Box
          component="img"
          src={loginBackground}
          alt="Login Background"
          sx={{
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        ></Box>
        <Grid
          container
          spacing={2}
          sx={{
            // background: `url(${loginBackground}) no-repeat`,
            // backgroundSize: "contain",
            mt: "20px",
            mb: "40px",
            paddingX: { xs: "10px", sm: PADDING_X },
          }}
        >
          <Box
            sx={{
              position: "fixed",
              left: "20px",
              top: "20px",
              width: "35px",
              height: "35px",
              cursor: "pointer",
              backgroundColor: theme.colors.common.black,
              color: theme.colors.text.greyLight,
              borderRadius: "5px",
              boxShadow: "0px 0px 7px 0px rgba(197, 197, 197, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleBack}
          >
            <ArrowBackIcon sx={{ fontSize: "25px" }} />
          </Box>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              height: { xs: "55vh", sm: "65vh", md: "90vh", lg: "90vh" },
            }}
          >
            <Box
              width={{ xs: "80%", sm: "70%", md: "90%", lg: "80%" }}
              mt={{ xs: "60px", md: 0 }}
            >
              <img
                src={BurrstLogo}
                onClick={handleBurrstLogo}
                alt="Burrst Logo"
                width="40%"
                style={{ cursor: "pointer", marginBottom: 15 }}
              />
              <Typography
                color={theme.colors.text.greyLight}
                fontFamily={theme.fonts.russoOne}
                fontWeight={theme.fonts.fontWeightMedium}
                fontSize={{ xs: "24px", sm: "38px", md: "34px", lg: "46px" }}
              >
                THE NEXT GENERATION
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box width={{ xs: "80%", sm: "70%" }} mt={{ xs: "40px", md: 0 }}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: 900,
                  fontSize: { xs: "20px", sm: "28px" },
                  mb: "10px",
                }}
              >
                Forgot Password<span style={{ color: "#FF0000" }}>.</span>{" "}
              </Typography>

              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "15px", sm: "20px" },
                  mb: "30px",
                }}
              >
                Enter your email address and we’ll send you a temporary password
                to reset your password
              </Typography>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightRegular,
                  fontSize: { xs: "15px", sm: "20px" },
                  mb: "5px",
                }}
              >
                Email
              </Typography>

              <TextField
                name="email"
                placeholder="Your Email"
                id="outlined-basic"
                variant="outlined"
                error={!!formErrors.email}
                helperText={formErrors.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={InputEmailIcon} alt="Input Email Icon" />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                    "& input, & textarea, & select": {
                      WebkitTextFillColor: "#FFFFFF",
                      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                    // "& input:hover, & input:focus, & textarea:hover, & textarea:focus, & select:hover, & select:focus":
                    //   {
                    //     borderColor: "theme.colors.common.white",
                    //   },
                  },
                  width: "100%",
                  mb: "20px",
                  height: "50px",
                  background: `
        linear-gradient(0deg, #252525, #252525),
        linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
                  borderRadius: "11.9px",
                  "&:hover": {
                    borderColor: "#FFFFFF",
                  },
                  "& fieldset": {
                    borderColor: "none",
                    borderWidth: 1,
                    borderRadius: "11.9px",
                    "&:hover": {
                      borderColor: "#FFFFFF",
                    },
                  },
                  "& input": {
                    color: "#FFFFFF",
                  },
                }}
                value={data.email}
                onChange={(e) =>
                  handleChange("email", e.target.value.toLowerCase())
                }
              />
              <Button
                onClick={handleSend}
                fontFamily={theme.fonts.livvic}
                sx={{
                  width: "100%",
                  height: "50px",
                  mt: "20px",
                  borderRadius: theme.fonts.smallBorderRadius,
                  backgroundColor: "#DD0000",
                  fontWeight: theme.fonts.fontWeightMedium,
                  fontSize: { xs: "15px", sm: "24px" },
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
              >
                SEND
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ForgotPassword;
