import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./dashboardNavbar";
import SideNavbar from "./sideNavbar";
import { Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import DashboardFooter from "./dashboardFooter";

const DashboardLayout = () => {
  const [showNavbarDetails, setShowNavbarDetails] = useState(
    window.innerWidth > 900
  );

  useEffect(() => {
    const handleResize = () => {
      setShowNavbarDetails(window.innerWidth > 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* <ToastContainer /> */}
      {/* <Box
        sx={
          {
            // maxWidth: "1536px",
          }
        }
      > */}
      <DashboardNavbar
        showNavbarDetails={showNavbarDetails}
        setShowNavbarDetails={setShowNavbarDetails}
      />
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "auto",
              md: showNavbarDetails ? "200px" : "95px",
            },
            display: {
              xs: showNavbarDetails ? "block" : "none",
              md: "block",
            },
          }}
        >
          <SideNavbar
            showNavbarDetails={showNavbarDetails}
            setShowNavbarDetails={setShowNavbarDetails}
          />
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: `calc(100% - ${showNavbarDetails ? 200 : 100}px)`,
            },
          }}
        >
          <Outlet />
          <DashboardFooter />
        </Box>
      </Box>
      {/* </Box> */}
    </>
  );
};

export default DashboardLayout;
