const Joi = require("joi");

const Schema = Joi.object({
  // profileImage: Joi.object().messages({
  //   "object.base": `Profile image is required and must be a valid file.`,
  // }),
  // profileImage: Joi.string().messages({
  //   "any.required": `Profile Image is required.`,
  //   "string.empty": `Profile Image cannot be empty.`,
  // }),
  dateOfBirth: Joi.string().required().messages({
    "any.required": `Date Of birth is required`,
    "string.empty": `Date Of birth is required`,
  }),
  gender: Joi.string().required().messages({
    "any.required": `Gender is required`,
    "string.empty": `Gender is required`,
  }),
  educationInstitutionType: Joi.string().required().messages({
    "any.required": `Educational Institution is required`,
    "string.empty": `Educational Institution is required`,
  }),
  school: Joi.string()
    .required()
    .regex(/^[a-z\s.]*$/i)
    .trim()
    .messages({
      "any.required": `Institution name is required`,
      "string.empty": `Institution name is required`,
      "string.pattern.base": `Institution name must contains only alphabets`,
    }),
  grade: Joi.string().required().messages({
    "any.required": `Grade is required`,
    "string.empty": `Grade is required`,
  }),
  sports: Joi.array().required().min(3).messages({
    "any.required": `At least 3 sports are required`,
    "array.min": `At least 3 sports are required`,
  }),
  interests: Joi.array().required().min(3).messages({
    "any.required": `At least 3 interests are required`,
    "array.min": `At least 3 interests are required`,
  }),
  country: Joi.string().required().messages({
    "any.required": `Country is required`,
    "string.empty": `Country is required`,
  }),
  state: Joi.string().required().messages({
    "any.required": `State is required`,
    "string.empty": `State is required`,
  }),
  city: Joi.string().required().messages({
    "any.required": `City is required`,
    "string.empty": `City is required`,
  }),
  registrationToken: Joi.string(),
}).unknown(true);

export default Schema;
