import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const TeamCard = ({ detail }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: "5px",
        background: theme.colors.teamCardBackground,
        overflow: "hidden",
        p: "15px",
        height: { md: "300px" },
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          "@media (max-width:400px)": {
            flexDirection: "column",
            // alignItems: "flex-start",
          },
        }}
      >
        {/* -----------------------Team Image--------------------- */}
        <Box
          sx={{
            width: { xs: "120px", sm: "150px" },
            height: { xs: "120px", sm: "150px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            background:
              "linear-gradient(rgba(0, 0, 0, 0.96) ,rgba(255, 0, 0, 0.64))",
          }}
        >
          <Box
            component="img"
            src={detail?.imgUrl}
            alt="Profile Image"
            sx={{ width: "100%", height: "100%" }}
          ></Box>
        </Box>

        {/* -----------------------Team Name and Designation------------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
            width: "calc(100% - 150px)",
            "@media (max-width:400px)": {
              width: "100%",
            },
          }}
        >
          <Typography
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            fontSize="20px"
          >
            {detail?.name}
          </Typography>

          <Typography
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
          >
            {detail?.designation}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography fontFamily={theme.fonts.livvic} fontSize="14px">
          {detail?.bio}
        </Typography>
      </Box>
    </Box>
  );
};

export default TeamCard;
