import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "../../../../../assets/icons/SearchIcon.svg";
import BrandsListDetailsCard from "../../../../../components/afterLogin/athletes/home/subComponents/brands/brands.list.details.card";
import { getBrandsCampaignsList } from "../../../../../services/brand/brand.service";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  SwapVert,
} from "@mui/icons-material";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";
import NoActiveCampaign from "../../../../../assets/icons/NoActiveCampaign.svg";

const BrandsCampaignsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const brandID = localStorage.getItem("brandId");
  const brandId = brandID ? brandID : location?.state?.detail?._id;
  const activeCampaigns = location?.state?.detail?.activeCampaigns;

  const [brandsCampaignsList, setBrandsCampaignsList] = useState([]);
  const [searchBy, setSearchBy] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const backState = location?.state?.back;
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const sortByOptionsList = [
    {
      id: 1,
      key: "aiRecommended",
      value: "Recommended",
    },
    {
      id: 2,
      key: "signed",
      value: "Signed",
    },
    {
      id: 3,
      key: "applied",
      value: "Applied",
    },
    {
      id: 4,
      key: "applied",
      value: "Not Applied",
      sortOrder: 1,
    },
  ];

  const handleBack = () => {
    if (backState === "brandList") {
      navigate("/dashboard/explore-brands");
    } else navigate(-1);
  };

  const handleSearchBy = (e) => {
    setSearchBy(e.target.value);
  };

  const handleSortBy = (e) => {
    setAnchorEl(e.target);
  };

  const handleSortByClose = () => {
    setAnchorEl(null);
  };

  const handleSortByClick = (menuItem) => {
    setSortBy((preItem) => (preItem?.id === menuItem?.id ? "" : menuItem));
    handleSortByClose();
  };

  const fetchBrandsList = async (sortOrder) => {
    setIsAllDataLoading(true);
    const { data } = await getBrandsCampaignsList(
      brandId,
      searchBy,
      sortBy?.key,
      sortOrder
    );
    setIsAllDataLoading(false);
    setBrandsCampaignsList(data);
  };

  useEffect(() => {
    let timer;
    if (sortBy?.sortOrder === 1) fetchBrandsList(1);
    else if (!searchBy) fetchBrandsList(-1);
    else if (searchBy) {
      timer = setTimeout(() => {
        fetchBrandsList(-1);
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [searchBy, sortBy]);

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* ----------------Header-------------------------- */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "30px", sm: "40px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          fontSize={theme.fonts.heading}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            brandsCampaignsList?.brandName
          )}
        </Typography>
      </Box>

      {/* ---------------------Active or Matching Campaign------------------- */}

      <Box
        sx={{
          mt: "30px",
          px: "20px",
          py: "40px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                background: "black",
                border: `1px solid ${theme.colors.text.greyDarker}`,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: 2,
                height: "100px",
                p: "20px",
              }}
            >
              <Box
                sx={{
                  color: "red",
                  border: "1px dotted grey",
                  borderRadius: "50%",
                  padding: "10px",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "26px",
                  fontFamily: theme.fonts.russoOne,
                  width: "50px",
                  height: "50px",
                }}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton type="circle" />
                ) : (
                  (activeCampaigns < 10
                    ? `0${activeCampaigns}`
                    : activeCampaigns) || "00"
                )}
              </Box>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
                fontSize={{ xs: "18px", lg: "24px" }}
                // noWrap
              >
                Active Campaigns
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                background: "black",
                border: `1px solid ${theme.colors.text.greyDarker}`,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: 2,
                height: "100px",
                p: "20px",
              }}
            >
              <Box
                sx={{
                  color: "red",
                  border: "1px dotted grey",
                  borderRadius: "50%",
                  padding: "10px",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "26px",
                  fontFamily: theme.fonts.russoOne,
                  width: "50px",
                  height: "50px",
                }}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton type="circle" />
                ) : (
                  (brandsCampaignsList?.aiRecommendedCount < 10
                    ? `0${brandsCampaignsList?.aiRecommendedCount}`
                    : brandsCampaignsList?.aiRecommendedCount) || "00"
                )}
              </Box>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
                fontSize={{ xs: "18px", lg: "24px" }}
                // noWrap
              >
                Matching Campaigns
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          mt: "20px",
          mb: "30px",
          px: "20px",
          py: "40px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              {isAllDataLoading ? (
                <CustomSkeleton
                  sx={{
                    width: "calc(100% - 100px)",
                    height: "40px",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <TextField
                  name="search"
                  placeholder="Search by activities"
                  value={searchBy || ""}
                  onChange={handleSearchBy}
                  fullWidth
                  autoComplete="off"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} alt="Search Icon" />
                      </InputAdornment>
                    ),
                    sx: {
                      border: `1px solid ${theme.colors.text.greyDarker}`,
                      background: "rgba(14,14,14,1)",
                      color: theme.colors.common.white,
                      borderRadius: "5px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                  }}
                />
              )}

              {isAllDataLoading ? (
                <CustomSkeleton
                  sx={{ width: "100px", height: "40px", borderRadius: "5px" }}
                />
              ) : (
                <Button
                  id="sortBy-button"
                  aria-controls={open ? "sortBy-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  sx={{
                    color: theme.colors.text.greyLightDark,
                    border: `1px solid ${theme.colors.text.greyDark}`,
                    borderRadius: "5px",
                    px: "10px",
                    py: "5px",
                    height: "40px",
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                  endIcon={<SwapVert />}
                  onClick={handleSortBy}
                >
                  Sort
                </Button>
              )}

              <Menu
                id="sortBy-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleSortByClose}
                MenuListProps={{ "aria-labelledby": "sortBy-button" }}
                anchorOrigin={{
                  vertical: 45,
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PopoverClasses={{
                  paper: "custom-paper-class",
                }}
                sx={{
                  "& .custom-paper-class": {
                    minWidth: "200px",
                    maxWidth: "220px",
                    backgroundColor: "black",
                    color: "white",
                    filter: "drop-shadow(0px 4px 4px rgba(255,255,255,0.3))",
                  },
                }}
              >
                {/* <Close
                    sx={{
                      position: "absolute",
                      right: 5,
                      top: 5,
                      color: theme.colors.text.greyDark,
                      cursor: "pointer",
                    }}
                    onClick={handleSortByClose}
                  /> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    px: "10px",
                    py: "5px",
                    // mt: "20px",
                  }}
                >
                  <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                    Sort By
                  </Typography>
                  <SwapVert />
                </Box>
                <Divider sx={{ background: theme.colors.text.greyDark }} />
                <Box sx={{ pt: "10px", px: "10px" }}>
                  {sortByOptionsList?.map((menuItem, index) => (
                    <MenuItem
                      onClick={() => handleSortByClick(menuItem)}
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "rgba(39,39,39,1)",
                        color: theme.colors.text.greyLight,
                        borderRadius: "5px",
                        mt: index === 0 ? 0 : 0.8,
                        px: "8px",
                        py: "3px",
                        fontSize: "14px",
                        fontFamily: theme.fonts.livvic,
                        "&:hover": {
                          background: "rgba(18,18,18,1)",
                          color: "white",
                        },
                      }}
                    >
                      <Typography>{menuItem?.value}</Typography>
                      {sortBy?.id === menuItem?.id ? (
                        <RadioButtonChecked sx={{ color: "red" }} />
                      ) : (
                        <RadioButtonUnchecked />
                      )}
                    </MenuItem>
                  ))}
                </Box>
              </Menu>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              {isAllDataLoading ? (
                Array.from({ length: 10 }, (_, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: { xs: "flex", md: "block" },
                      justifyContent: { xs: "center" },
                    }}
                  >
                    <BrandsListDetailsCard
                      isAllDataLoading={isAllDataLoading}
                    />
                  </Grid>
                ))
              ) : brandsCampaignsList?.campaigns?.length ? (
                brandsCampaignsList?.campaigns?.map((detail, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: { xs: "flex", md: "block" },
                      justifyContent: { xs: "center" },
                    }}
                  >
                    <BrandsListDetailsCard
                      detail={detail}
                      brandLogo={brandsCampaignsList?.brandLogo}
                      brandName={brandsCampaignsList?.brandName}
                    />
                  </Grid>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 2,
                    fontSize: { xs: "24px", sm: "30px" },
                    minHeight: "250px",
                    width: "100%",
                    background: "rgba(18,18,18,1)",
                    borderRadius: "5px",
                    mb: "30px",
                  }}
                >
                  <Box
                    component="img"
                    src={NoActiveCampaign}
                    alt="No Active Campaign"
                    sx={{ width: { xs: "70px", sm: "100px" } }}
                  ></Box>
                  No campaigns yet!
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BrandsCampaignsList;
