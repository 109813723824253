import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MoreVert } from "@mui/icons-material";

import { Helmet } from "react-helmet-async";

import ProfileImages from "./sections/profileImages";
import VideosandImages from "./sections/videos&images";
import PersonalDetails from "./sections/personalDetails";
import MatchingCampaign from "./sections/matchingCampaign";
import CrossIcon from "../../../../assets/icons/CrossIcon.svg";
import CancleSubscriptionIcon from "../../../../assets/icons/CancleSubsIcon.svg";
import DeleteAccountIcon from "../../../../assets/icons/DeleteAccountIcon.svg";
import EditIcon from "../../../../assets/icons/EditIcon.svg";
import BankDetailsIcon from "../../../../assets/icons/BankDetailsIcon.svg";
import ChangePasswordIcon from "../../../../assets/icons/ChangePasswordIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyProfile,
  fetchOtherProfile,
} from "../../../../store/myProfileSlice";
import { ToastContainer, toast } from "react-toastify";
import { authDeleteAccount } from "../../../../services/auth/auth.service";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";
import { getBankVerificationUrl } from "../../../../services/accounts/accounts.service";

const AthletesProfile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const page = localStorage.getItem("page");
  const [loading, setLoading] = useState(false);

  const userId = location?.state?.detail?._id || null;

  const isMyProfile = location?.state?.isMyProfile;

  const { myProfile, isMyProfileDataLoading } = useSelector(
    (state) => state.myProfile
  );
  const { otherProfile, isOtherProfileDataLoading } = useSelector(
    (state) => state.otherProfile
  );

  const profile = isMyProfile ? myProfile : otherProfile;

  const isAllDataLoading = isMyProfile
    ? isMyProfileDataLoading
    : isOtherProfileDataLoading;

  const [isDeleteAccount, setIsDeleteAccount] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [newTabUrl, setNewTabUrl] = useState("");
  const [accConnected, setAccConnect] = useState("");
  const profileState = "profile";

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBack = () => {
    navigate(-1);
    // navigate("/dashboard/home");
  };

  const handleEditPublucProfile = () => {
    navigate("/athletes/public-profile", {
      state: { detail: profile, isMyProfile },
    });
    handleClose();
  };

  const handleBankDetails = async () => {
    const { data } = await getBankVerificationUrl();
    setNewTabUrl(data?.data?.link);
    setAccConnect(data?.data?.accountConnected);

    if (data?.data?.accountConnected) {
      window.open("https://connect.stripe.com/express_login", "_blank");
    } else {
      localStorage.setItem("route", profileState);
      navigate("/bank-details");
    }
    handleClose();
  };

  const handleChangePassword = () => {
    navigate("/change-password");
    handleClose();
  };

  const handleDeleteAccount = () => {
    setIsDeleteAccount(true);
  };

  const handleDeleteAccountApi = async () => {
    setLoading(true);
    const response = await authDeleteAccount();
    setLoading(false);
    if (response?.status === 200) {
      toast.success("Account deleted successfully");
      localStorage.clear();
      navigate("/");
    } else {
      toast.error("Failed to delete account");
      console.error("Failed brandCancleSubscriptionPlans:", response?.message);
    }
  };

  const handleDeleteAccountClose = () => {
    setIsDeleteAccount(false);
  };

  const handleKeep = () => {
    handleDeleteAccountClose();
  };

  useEffect(() => {
    isMyProfile
      ? dispatch(fetchMyProfile({ userType: "ATHLETE", userId }))
      : dispatch(fetchOtherProfile({ userType: "ATHLETE", userId }));
  }, [isUpdated]);

  return (
    <>
      <Helmet>
        {isMyProfile ? (
          <title>Profile | Burrst</title>
        ) : (
          <title>Athlete Profile | Burrst</title>
        )}
      </Helmet>
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      {isAllDataLoading ? (
        <Box
          mt={{ xs: "95px", sm: "115px" }}
          sx={{
            px: { xs: "10px", sm: "30px" },
            mb: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              mb: "20px",
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <ArrowBackIcon
                onClick={handleBack}
                sx={{
                  cursor: "pointer",
                  fontSize: { xs: "30px", sm: "40px" },
                  color: "white",
                }}
              />

              <Typography
                color="white"
                fontFamily={theme.fonts.russoOne}
                fontSize={{
                  xs: theme.fonts.heading_xs,
                  sm: theme.fonts.heading,
                }}
              >
                Profile
              </Typography>
            </Box>
            <CustomSkeleton type="circle" />
          </Box>

          <Box
            sx={{
              background: "#121212",
              px: { xs: "10px", sm: "20px" },
              py: "10px",
            }}
          >
            <CustomSkeleton
              type="img"
              sx={{ minWidth: "100%", height: "200px", mb: 2 }}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
              <CustomSkeleton
                type="img"
                sx={{ minWidth: "75px", height: "75px" }}
              />

              <Box sx={{ width: "100%", mb: 2 }}>
                <CustomSkeleton type="p" count={2} sx={{ mb: 2 }} />
              </Box>
            </Box>

            <CustomSkeleton
              type="p"
              count={3}
              sx={{ minWidth: "100%", mb: 2 }}
            />
          </Box>
        </Box>
      ) : (
        profile && (
          <Box
            mt={{ xs: "95px", sm: "115px" }}
            sx={{
              px: { xs: "10px", sm: "30px" },
              mb: "30px",
            }}
          >
            {/* ----------------Header-------------------------- */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
                mb: "20px",
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <ArrowBackIcon
                  onClick={handleBack}
                  sx={{
                    cursor: "pointer",
                    fontSize: { xs: "30px", sm: "40px" },
                    color: "white",
                  }}
                />

                <Typography
                  color="white"
                  fontFamily={theme.fonts.russoOne}
                  fontSize={{
                    xs: theme.fonts.heading_xs,
                    sm: theme.fonts.heading,
                  }}
                >
                  Profile
                </Typography>
              </Box>
              {isMyProfile && (
                <MoreVert sx={{ cursor: "pointer" }} onClick={handleClick} />
              )}
              <Menu
                anchorEl={anchorEl}
                id="profile-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                sx={{
                  filter: "drop-shadow(0px 2px 4px rgba(255,255,255,0.4))",
                  mt: 1,
                  "& .MuiPaper-root": {
                    background: "black",
                    color: theme.colors.text.greyDark,
                  },
                }}
              >
                <MenuItem
                  onClick={handleEditPublucProfile}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  <Box component="img" src={EditIcon} alt="Edit Icon"></Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                    }}
                  >
                    Edit Public Profile
                  </Typography>
                </MenuItem>
                <Divider style={{ background: theme.colors.text.greyDarker }} />
                <MenuItem
                  onClick={handleBankDetails}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={BankDetailsIcon}
                    alt="Edit Icon"
                  ></Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                    }}
                  >
                    Bank Details
                  </Typography>
                </MenuItem>
                <Divider style={{ background: theme.colors.text.greyDarker }} />
                <MenuItem
                  onClick={handleChangePassword}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    "&:hover": {
                      color: "red",
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={ChangePasswordIcon}
                    alt="Edit Icon"
                  ></Box>
                  <Typography
                    sx={{
                      fontFamily: theme.fonts.livvic,
                    }}
                  >
                    Change Password
                  </Typography>
                </MenuItem>
                <Divider style={{ background: theme.colors.text.greyDarker }} />
                <MenuItem
                  onClick={handleDeleteAccount}
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                    color: "#FF0000",
                    "&:hover": {
                      color: "#FF0000",
                    },
                  }}
                >
                  <Box
                    component="img"
                    src={DeleteAccountIcon}
                    alt="Delete Icon"
                    sx={{ width: "25px" }}
                  ></Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 500,
                      fontFamily: theme.fonts.livvic,
                    }}
                  >
                    Delete Account
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            {/* ---------------------Delete Account Modal---------------------- */}
            <Dialog
              open={isDeleteAccount}
              onClose={handleDeleteAccountClose}
              PaperProps={{
                sx: {
                  minWidth: "50vw",
                  height: { xs: "600px", sx: "400px", md: "500px" },
                  background: theme.colors.common.black,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  pb: "10px",
                },
              }}
              sx={{
                backdropFilter: "blur(3px)",
                backgroundColor: "rgb(255,255,255,0.3)",
              }}
            >
              <DialogContent
                sx={{
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "5px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: theme.colors.background.formInput,
                    borderRadius: "4px",
                  },
                }}
              >
                <Box
                  component="img"
                  src={CrossIcon}
                  alt="Cross Icon"
                  position="absolute"
                  right="20px"
                  top="20px"
                  sx={{ cursor: "pointer", width: "20px" }}
                  onClick={handleDeleteAccountClose}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: "40px",
                  }}
                >
                  <Box
                    component="img"
                    src={CancleSubscriptionIcon}
                    alt="Cancle Subscription Icon"
                    sx={{
                      border: "5px solid #0C0C0C",
                      borderRadius: "50%",
                      width: "90px",
                      mb: "30px",
                    }}
                  ></Box>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    fontWeight={theme.fonts.fontWeightMedium}
                    sx={{ fontSize: { xs: "22px", md: "24px" } }}
                  >
                    Delete Account
                  </Typography>
                  <Typography
                    mt="10px"
                    textAlign="center"
                    fontFamily={theme.fonts.livvic}
                    color="#A0A0A0"
                  >
                    Are you sure you want to delete your account? If you delete
                    your account, you will permanently lose your profile,
                    message, and photos.
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexWrap="wrap"
                    mt="35px"
                    gap={2}
                  >
                    <Button
                      onClick={handleKeep}
                      variant="contained"
                      sx={{
                        px: "25PX",
                        border: "3px solid #797979",
                        background: "#000000",
                        width: { xs: "160px", sm: "260px" },
                        mt: 1,
                        // textTransform: "none",
                        fontWeight: theme.fonts.fontWeightBold,
                        fontSize: { xs: "14px", sm: "18px" },
                        fontFamily: theme.fonts.livvic,
                        "&:hover": {
                          background: "#000000",
                        },
                      }}
                    >
                      cancel
                    </Button>
                    <Button
                      onClick={handleDeleteAccountApi}
                      variant="contained"
                      sx={{
                        px: "25PX",
                        border:
                          "3px solid linear-gradient(90.36deg, rgba(0, 0, 0, 0.96) -9.59%, rgba(70, 0, 0, 0.970952) 21.87%, #FF0000 105.31%)",
                        background: "red",
                        width: { xs: "160px", sm: "260px" },
                        mt: 1,
                        // textTransform: "none",
                        fontWeight: theme.fonts.fontWeightBold,
                        fontSize: { xs: "14px", sm: "18px" },
                        fontFamily: theme.fonts.livvic,
                        "&.MuiButton-root.Mui-disabled": {
                          background: "rgba(255,0,0,0.6)",
                          color: "white",
                        },
                        "&:hover": {
                          background: theme.colors.divider.red,
                        },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress sx={{ color: "gray" }} />
                      ) : (
                        "DELETE ACCOUNT"
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
            <Box
              sx={{
                background: "#121212",
                px: { xs: "10px", sm: "20px" },
                py: "10px",
              }}
            >
              <ProfileImages
                detail={profile}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
              />
              <VideosandImages
                detail={profile}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
              />
              <PersonalDetails
                detail={profile}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
              />
              {!isMyProfile && (
                <MatchingCampaign
                  detail={profile}
                  isUpdated={isUpdated}
                  setIsUpdated={setIsUpdated}
                />
              )}
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default AthletesProfile;
