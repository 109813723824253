import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";

import Sports from "../../../../../../assets/icons/Sports.svg";
import School from "../../../../../../assets/icons/School.svg";
import InstagramIcon from "../../../../../../assets/icons/InstagramPublicProfileIcon.svg";
import FacebookIcon from "../../../../../../assets/icons/FacebookPublicProfileIcon.svg";
import TwitterIcon from "../../../../../../assets/icons/TwitterPublicProfileIcon.svg";
import LinkedInIcon from "../../../../../../assets/icons/LinkedInPublicProfileIcon.svg";
import Youtubecon from "../../../../../../assets/icons/YoutubePublicProfileIcon.svg";
import TiktokIcon from "../../../../../../assets/icons/TikTokPublicProfileIcon.svg";
import BurrstLogoIcon from "../../../../../../assets/icons/BurrstLogoIcon.svg";
import Pulse from "../../../../../../themes/@overrides/pulse";
import SignedAthleteupdateModal from "./signedAthleteupdate.modal";
import DefaultImg from "../../../../../../assets/images/DefaultAvatar.jpg";
import { useNavigate } from "react-router";

const SignedAthleteCard = ({ detail }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const handlePulseUpdate = () => {
    //setOpenDialog(true);
    navigate("/dashboard/campaigns/signed-athlete", {
      state: {
        detail: detail,
      },
    });
  };

  const handleDetails = () => {
    navigate("/dashboard/campaigns/signed-athlete", {
      state: {
        detail: detail,
      },
    });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const count = detail?.proofs?.reduce((acc, proof) => {
    if (proof.submitionStatus) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <Box
      sx={{
        minWidth: "45%",
        my: "20px",
        mx: "auto",
        border: `1px solid grey`,
        background: theme.colors.common.black,
        overflow: "hidden",
      }}
    >
      <Box
        p="30px"
        data-aos="fade-up"
        data-aos-duration="1500"
        overflow="hidden"
      >
        {count > 0 ? (
          <Box
            onClick={() => handlePulseUpdate()}
            sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
          >
            <Pulse />
          </Box>
        ) : null}
        <Box
          onClick={handleDetails}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "100px",
              height: "100px",
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={detail?.athlete?.profile?.profileImageLink || DefaultImg}
              alt="Avatar"
              id="avatar"
              sx={{ width: "100%", height: "100%" }}
              onError={(e) => (e.target.src = DefaultImg)}
            ></Box>
            <Box
              component="img"
              src={BurrstLogoIcon}
              alt="Burrst Logo Icon"
              sx={{
                position: "absolute",
                top: "-30%",
                right: "-30%",
              }}
              id="Burrst Logo Icon"
            ></Box>
          </Box>
          <Typography
            mt={2}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightMedium}
            sx={{ fontSize: { xs: "16px", md: "24px" } }}
          >
            {detail?.athlete?.firstName} {detail?.athlete?.lastName}
          </Typography>
        </Box>

        <Box display="flex" gap={1} my={2}>
          <Box component="img" src={School} alt={`School ${detail.id}`}></Box>
          <Typography fontFamily={theme.fonts.livvic}>
            School: {detail?.athlete?.profile?.school}
          </Typography>
        </Box>
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          {detail?.athlete?.profile?.instagramLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() =>
                  window.open(detail?.athlete?.profile?.instagramLink, "_blank")
                }
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={InstagramIcon}
                  alt="Instagram Icon"
                />
              </Box>
              {detail?.athlete?.profile?.instagramFollowerCount && (
                <Typography>
                  {detail?.athlete?.profile?.instagramFollowerCount < 1000
                    ? detail?.athlete?.profile?.instagramFollowerCount
                    : detail?.athlete?.profile?.instagramFollowerCount < 1000000
                    ? (detail?.athlete?.profile?.instagramFollowerCount %
                        1000 ===
                      0
                        ? (
                            detail?.athlete?.profile?.instagramFollowerCount /
                            1000
                          ).toFixed(0)
                        : (
                            detail?.athlete?.profile?.instagramFollowerCount /
                            1000
                          ).toFixed(1)) + "K"
                    : detail?.athlete?.profile?.instagramFollowerCount %
                        1000000 ===
                      0
                    ? (
                        detail?.athlete?.profile?.instagramFollowerCount /
                        1000000
                      ).toFixed(0) + "M"
                    : (
                        detail?.athlete?.profile?.instagramFollowerCount /
                        1000000
                      ).toFixed(1) + "M"}
                </Typography>
              )}
            </Box>
          )}
          {detail?.athlete?.profile?.facebookLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() =>
                  window.open(detail?.athlete?.profile?.facebookLink, "_blank")
                }
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={FacebookIcon}
                  alt="Facebook Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {detail?.athlete?.profile?.twitterLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() =>
                  window.open(detail?.athlete?.profile?.twitterLink, "_blank")
                }
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TwitterIcon}
                  alt="Twitter Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {detail?.athlete?.profile?.linkedinLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() =>
                  window.open(detail?.athlete?.profile?.linkedinLink, "_blank")
                }
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={LinkedInIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {detail?.athlete?.profile?.youtubeLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() =>
                  window.open(detail?.athlete?.profile?.youtubeLink, "_blank")
                }
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={Youtubecon}
                  alt="LinkedIn Icon"
                />
              </Box>
              <Typography>N/A</Typography>
            </Box>
          )}
          {detail?.athlete?.profile?.tiktokLink && (
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                onClick={() =>
                  window.open(detail?.athlete?.profile?.tiktokLink, "_blank")
                }
                backgroundColor={theme.colors.background.socialMedia}
                height="20px"
                width="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                boxShadow="0 0 6px -2px white"
                sx={{
                  "&:hover": {
                    background: theme.colors.background.socialMediaLight,
                    color: theme.colors.text.linkedIn,
                    transition: "all 0.2s ease",
                  },
                  cursor: "pointer",
                }}
              >
                <img
                  width="12px"
                  height="12px"
                  src={TiktokIcon}
                  alt="LinkedIn Icon"
                />
              </Box>
              {detail?.athlete?.profile?.tiktokFollowerCount && (
                <Typography>
                  {detail?.athlete?.profile?.tiktokFollowerCount < 1000
                    ? detail?.athlete?.profile?.tiktokFollowerCount
                    : detail?.athlete?.profile?.tiktokFollowerCount < 1000000
                    ? (detail?.athlete?.profile?.tiktokFollowerCount % 1000 ===
                      0
                        ? (
                            detail?.athlete?.profile?.tiktokFollowerCount / 1000
                          ).toFixed(0)
                        : (
                            detail?.athlete?.profile?.tiktokFollowerCount / 1000
                          ).toFixed(1)) + "K"
                    : detail?.athlete?.profile?.tiktokFollowerCount %
                        1000000 ===
                      0
                    ? (
                        detail?.athlete?.profile?.tiktokFollowerCount / 1000000
                      ).toFixed(0) + "M"
                    : (
                        detail?.athlete?.profile?.tiktokFollowerCount / 1000000
                      ).toFixed(1) + "M"}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {/* <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          backgroundColor: "rgb(255,255,255,0.2)",
          "& .MuiDialog-paper": {
            width: { xs: "90%", sm: "80%", md: "70%", lg: "50%" },
            maxWidth: "unset",
            height: "95%",
            maxHeight: "unset",
          },
        }}
      >
        <SignedAthleteupdateModal
          handleDialogClose={handleDialogClose}
          detail={detail}
          profile={detail?.athlete?.profile?.profileImageLink || DefaultImg}
        />
      </Dialog> */}
    </Box>
  );
};

export default SignedAthleteCard;
