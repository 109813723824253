import { Box, Button } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

import Articles from "../../../../components/afterLogin/athletes/home/sections/articles";
import Campaigns from "../../../../components/afterLogin/athletes/home/sections/campaigns";
import Brands from "../../../../components/afterLogin/athletes/home/sections/brands";
import Statistics from "../../../../components/afterLogin/athletes/home/sections/statistics";
import TodaysTask from "../../../../components/afterLogin/athletes/home/sections/todaysTask";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Home | Burrst</title>
      </Helmet>
      <Box>
        <TodaysTask />
        <Statistics />
        <Brands />
        <Campaigns />
      </Box>
    </>
  );
};

export default Home;
