import React from "react";
import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import MatchingCampaignCards from "../../../../../components/afterLogin/brands/atheletsProfile/subComponents/matchingCampaign.cards";

const MatchingDetails = ({ detail }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{ background: "black", borderRadius: "5px", my: "10px", p: "20px" }}
    >
      <Typography
        fontFamily={theme.fonts.livvic}
        fontWeight={theme.fonts.fontWeightBold}
        fontSize={theme.fonts.sectionHeading}
      >
        Matching Campaigns
      </Typography>
      <Divider
        sx={{ border: `1px solid ${theme.colors.text.greyDark}`, mb: 4, mt: 1 }}
      />
      <Grid container spacing={2}>
        {detail?.matchingCampaigns.length > 0 ? (
          detail?.matchingCampaigns?.map((detail, index) => (
            <MatchingCampaignCards key={index} detail={detail} />
          ))
        ) : (
          <Box display="flex" justifyContent="center" width="100%">
            <Typography
              color={theme.colors.text.greyLight}
              my="40px"
              fontFamily={theme.fonts.livvic}
              sx={{ fontSize: { xs: "18px", md: "20px" } }}
            >
              No Matching Campaigns
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default MatchingDetails;
