import {
  campaignDetailsApi,
  campaignFeatured,
  createCampaignApi,
  createContractApi,
  createDeliverableApi,
  getappliedAthletesApi,
  myCampaignsListApi,
  removeApplicationApi,
  removeProjectFilesApi,
  respondToApplicantApi,
  getCampaignsApi,
  postCampaignsApi,
  selectedAthleteApi,
  removeUnsignedApplicationApi,
  respondToProofApi,
  getAiRecommendedAthletesApi,
  brandDealTrackerApi,
  campaignAddRevenueApi,
  campaignEditRevenueApi,
  dealTrackerApi,
  getCampaignPaymentModelListApi,
  showInterestAiAthleteApi,
  campaignRevenueMakePaymentApi,
  updateCampaignApi,
  updateCampaignDeliverableApi,
  getTrackingApi,
  deleteDeliverableApi,
} from "./api.request";

const CAMPAIGN_PATH_FEATURED = "/campaign/featuredCampaigns";
const CREATE_CAMPAIGN_PATH = "/campaign/createCampaign";
const CREATE_DELIVERABLE_PATH = "/campaign/createDeliverable";
const CREATE_CONTRACT_PATH = "/campaign/createContract";
const MYCAMPAIGNS_PATH = "/campaign/myCampaigns";
const CAMPAIGN_DETAILS_PATH = "/campaign/campaignDetails";
const GET_APPLIED_ATHLETE_PATH = "/campaign/appliedAthletes";
const RESPOND_TO_APPLICANT_PATH = "/campaign/respondToApplicants";
const REMOVE_APPLICATION_PATH = "/campaign/removeApplication";
const REMOVE_PROJECTFILES_PATH = "/campaign/removeProjectFile";
const CAMPAIGN_SELECTED_ATHLETES = "/campaign/selectedAthletes";
const CAMPAIGN_RESPONDTOPROOF = "/campaign/respondToProof";
const CAMPAIGN_AIRECOMMENDEDATHLETES = "/campaign/burrstRecommendedAthletes";
const CAMPAIGNS_AI_SHOWINTEREST = "/campaign/showInterest";
const CAMPAIGN_ADDREVENUE = "/campaign/addRevenue";
const CAMPAIGN_EDITREVENUE = "/campaign/editRevenue";

const CAMPAIGNS_LIST_PATH = "/campaign/campaignList";
const CAMPAIGNS_BURRST_RECOMMENDED_PATH =
  "/campaign/burrstRecommendedCampaigns";
const CAMPAIGNS_VIEW_DETAILS_PATH = "/campaign/campaignDetails";
const APPLIED_CAMPAIGNS_PATH = "/campaign/appliedCampaigns";
const CAMPAIGNS_APPLY_TO_CAMPAIGN_PATH = "/campaign/applyToCampaign";
const CAMPAIGNS_WITHDRAW_FROM_CAMPAIGN_PATH = "/campaign/applicationWithdrawal";

const CAMPAIGNS_UPLOAD_PROOF_PATH = "/campaign/uploadProof";
const CAMPAIGNS_SIGN_CONTRACT_PATH = "/campaign/signContract";
const CAMPAIGNS_CONTRACT_DETAILS_PATH = "/campaign/contractDetails";
const CAMPAIGNS_MY_TASKS_PATH = "/campaign/myTasks";
const CAMPAIGNS_COMPLETED_PROJECTS_PATH = "/campaign/completedProjects";

const AI_SUMMARY_PATH = "/ai/summarizeContract";

const CAMPAIGNS_BRAND_DEAL_TRACKER = "/campaign/brandDealTracker";
const CAMPAIGNS_AThLETE_DEAL_TRACKER = "/campaign/athleteDealTracker";

const CAMPAIGNS_PAYMENTMODALLIST = "/campaign/paymentModelList";
const CAMPAIGNS_REVENUEMAKEPAYMENT = "/campaign/makePayment";
const CAMPAIGNS_UPDATEDETAILS = "/campaign/updateCampaignDetails";
const CAMPAIGNS_UPDATEDELIVERABLE = "/campaign/updateDeliverable";
const DELETE_DELIVERABLE = "/campaign/deleteDeliverable";
const PRODUCT_LINK = "/product/link";
const PRODUCT_CLICK_COUNT = "/product/clickCount";

export const updateCampaign = async (body) =>
  updateCampaignApi(`${CAMPAIGNS_UPDATEDETAILS}`, body);

export const updateCampaignDeliverable = async (body) =>
  updateCampaignDeliverableApi(`${CAMPAIGNS_UPDATEDELIVERABLE}`, body);

export const deleteDeliverable = async (body) =>
  deleteDeliverableApi(`${DELETE_DELIVERABLE}`, body);

export const getCampaignPaymentModelList = async () =>
  getCampaignPaymentModelListApi(`${CAMPAIGNS_PAYMENTMODALLIST}`);

export const campaignRevenueMakePayment = async (body) =>
  campaignRevenueMakePaymentApi(`${CAMPAIGNS_REVENUEMAKEPAYMENT}`, body);

export const featuredCampaign = async () =>
  campaignFeatured(`${CAMPAIGN_PATH_FEATURED}`);

export const createCampaign = async (body) =>
  createCampaignApi(`${CREATE_CAMPAIGN_PATH}`, body);

export const createDeliverable = async (body) =>
  createDeliverableApi(`${CREATE_DELIVERABLE_PATH}`, body);

export const createContract = async (body) =>
  createContractApi(`${CREATE_CONTRACT_PATH}`, body);

export const myCampaignsList = async (body) =>
  myCampaignsListApi(`${MYCAMPAIGNS_PATH}`, body);

export const getCampaignDetails = async (campaignId) =>
  campaignDetailsApi(`${CAMPAIGN_DETAILS_PATH}`, campaignId);

export const getappliedAthletes = async (body) =>
  getappliedAthletesApi(`${GET_APPLIED_ATHLETE_PATH}`, body);

export const respondToProof = async (body) =>
  respondToProofApi(`${CAMPAIGN_RESPONDTOPROOF}`, body);

export const respondToApplicant = async (selectedId, ignoredId) =>
  respondToApplicantApi(`${RESPOND_TO_APPLICANT_PATH}`, selectedId, ignoredId);

export const getAiRecommendedAthletes = async (body) =>
  getAiRecommendedAthletesApi(`${CAMPAIGN_AIRECOMMENDEDATHLETES}`, body);

export const showInterestAiAthlete = async (body) =>
  showInterestAiAthleteApi(`${CAMPAIGNS_AI_SHOWINTEREST}`, body);

export const campaignAddRevenue = async (body) =>
  campaignAddRevenueApi(`${CAMPAIGN_ADDREVENUE}`, body);

export const campaignEditRevenue = async (body) =>
  campaignEditRevenueApi(`${CAMPAIGN_EDITREVENUE}`, body);

export const removeApplication = async (body) =>
  removeApplicationApi(`${REMOVE_APPLICATION_PATH}`, body);

export const removeProjectFiles = async (id, data) =>
  removeProjectFilesApi(`${REMOVE_PROJECTFILES_PATH}`, id, data);

export const selectedAthlete = async () =>
  selectedAthleteApi(`${CAMPAIGN_SELECTED_ATHLETES}`);

export const getCampaignsList = async () => {
  return getCampaignsApi(`${CAMPAIGNS_LIST_PATH}`);
};
export const getBurrstRecommendedList = async (sortBy, sortOrder) => {
  return getCampaignsApi(
    `${CAMPAIGNS_BURRST_RECOMMENDED_PATH}`,
    sortBy,
    sortOrder
  );
};

export const getCampaignsViewDetails = async (campaignId) => {
  return getCampaignsApi(
    `${CAMPAIGNS_VIEW_DETAILS_PATH}?campaignId=${campaignId}`
  );
};

export const getAppliedCampaigns = async () => {
  return getCampaignsApi(`${APPLIED_CAMPAIGNS_PATH}`);
};

export const getAISummary = async (campaignId) => {
  return getCampaignsApi(`${AI_SUMMARY_PATH}?campaignId=${campaignId}`);
};

export const applyToCampaign = async (campaignId) => {
  return getCampaignsApi(
    `${CAMPAIGNS_APPLY_TO_CAMPAIGN_PATH}?campaignId=${campaignId}`
  );
};

export const withdrawFromCampaign = async (campaignId, isSelected) => {
  return getCampaignsApi(
    `${CAMPAIGNS_WITHDRAW_FROM_CAMPAIGN_PATH}?campaignId=${campaignId}&selected=${isSelected}`
  );
};

export const uploadProof = async (body) => {
  return postCampaignsApi(`${CAMPAIGNS_UPLOAD_PROOF_PATH}`, body);
};

export const signContract = async (body) => {
  return postCampaignsApi(`${CAMPAIGNS_SIGN_CONTRACT_PATH}`, body);
};

export const getContractDetails = async (campaignId) => {
  return getCampaignsApi(
    `${CAMPAIGNS_CONTRACT_DETAILS_PATH}?campaignId=${campaignId}`
  );
};

export const getMyTasks = async () => {
  return getCampaignsApi(`${CAMPAIGNS_MY_TASKS_PATH}`);
};

export const getCompletedProjects = async () => {
  return getCampaignsApi(`${CAMPAIGNS_COMPLETED_PROJECTS_PATH}`);
};

export const brandDealTracker = async () =>
  dealTrackerApi(`${CAMPAIGNS_BRAND_DEAL_TRACKER}`);

export const athleteDealTracker = async () =>
  dealTrackerApi(`${CAMPAIGNS_AThLETE_DEAL_TRACKER}`);

export const getTrackingLink = async (
  campaignId,
  deliverableId,
  athleteId,
  deliverableUrl
) => {
  return getTrackingApi(
    `${PRODUCT_LINK}?campaignId=${campaignId}&deliverableId=${deliverableId}&athleteId=${athleteId}&url=${deliverableUrl}`
  );
};
export const getClickCount = async (campaignId, deliverableId, athleteId) => {
  return getTrackingApi(
    `${PRODUCT_CLICK_COUNT}?campaignId=${campaignId}&deliverableId=${deliverableId}&athleteId=${athleteId}`
  );
};
