import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ChevronRight } from "@mui/icons-material";
import { getAppliedCampaigns } from "../../../../../services/campaign/campaign.service";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const Statistics = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [campaignsList, setCampaignsList] = useState({});
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleActiveCampaigns = () => {
    navigate("/dashboard/home/statistics", { state: { isActive: true } });
  };
  const handleAppliedCampaigns = () => {
    navigate("/dashboard/home/statistics", { state: { isActive: false } });
  };

  const fetchAppliedCampaigns = async () => {
    setIsAllDataLoading(true);
    const { data } = await getAppliedCampaigns();
    setIsAllDataLoading(false);
    setCampaignsList(data?.data[0]);
  };

  useEffect(() => {
    fetchAppliedCampaigns();
  }, []);

  return (
    <Box
      sx={{
        mt: "60px",
        mx: { xs: "10px", sm: "30px" },
        px: "20px",
        py: "20px",
        overflow: "hidden",
        background: theme.colors.dashboard.grey200,
        borderRadius: "5px",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              background: "black",
              border: `1px solid ${theme.colors.text.greyDarker}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100px",
              p: "20px",
              cursor: "pointer",
            }}
            onClick={isAllDataLoading ? null : handleActiveCampaigns}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  color: "red",
                  border: "1px dotted grey",
                  borderRadius: "50%",
                  padding: "10px",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "24px",
                  fontFamily: theme.fonts.russoOne,
                  minWidth: "50px",
                  minHeight: "50px",
                }}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton type="circle" />
                ) : (
                  (campaignsList?.selected?.length < 10
                    ? `0${campaignsList?.selected?.length}`
                    : campaignsList?.selected?.length) || "00"
                )}
              </Box>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
                fontSize={{ xs: "20px", lg: "25px" }}
                // noWrap
              >
                Active Campaigns
              </Typography>
            </Box>
            <ChevronRight />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              background: "black",
              border: "1px solid grey",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              height: "100px",
              p: "20px",
              cursor: "pointer",
            }}
            onClick={isAllDataLoading ? null : handleAppliedCampaigns}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  color: "red",
                  border: "1px dotted grey",
                  borderRadius: "50%",
                  padding: "10px",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "24px",
                  fontFamily: theme.fonts.russoOne,
                  minWidth: "50px",
                  minHeight: "50px",
                }}
              >
                {isAllDataLoading ? (
                  <CustomSkeleton type="circle" />
                ) : campaignsList?.pending?.length < 10 ? (
                  `0${campaignsList?.pending?.length}`
                ) : (
                  campaignsList?.pending?.length
                )}
              </Box>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightBold}
                fontSize={{ xs: "20px", lg: "25px" }}
                // noWrap
              >
                Applied Campaigns
              </Typography>
            </Box>
            <ChevronRight />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Statistics;
