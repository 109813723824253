import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

import SupportAgentRedIcon from "../../../../assets/icons/SupportAgentRedIcon.svg";
import ChatLeftSidebar from "../../../../components/afterLogin/athletes/chat/section/chat.leftSidebar";
import ChatRightSidebar from "../../../../components/afterLogin/athletes/chat/section/chat.rightSidebar";
import { chatSocket, initChatSocket } from "../../../../utils/socket";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { useNavigate } from "react-router";
import {
  getChatRoomId,
  getChatUsers,
  setChatRoomId,
  setIsUserSelected,
  setSelectedUser,
} from "../../../../store/actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { Chat, ChatBubble } from "@mui/icons-material";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";

const ChatPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedUser, isAllDataLoading, isUserSelected } = useSelector(
    (state) => state?.chat
  );

  const [showRightSideBar, setShowRightSideBar] = useState(
    window.innerWidth > 600
  );

  const chatSocketRemove = async () => {
    await chatSocket.emit("remove");
  };

  const handleSupportAgent = () => {
    navigate("/dashboard/chat/admin");
  };

  useEffect(() => {
    initChatSocket();
    if (chatSocket) {
      chatSocket?.on("_connection", async (isSuccess) => {
        if (isSuccess) {
          console.log("chatSocket connection succcess");
        } else {
          console.log("chatSocket connection failure");
        }
      });
    } else console.log("Unable to connect to socket server.");

    const handleResize = () => {
      setShowRightSideBar(window.innerWidth > 600);
    };
    window.addEventListener("resize", handleResize);

    dispatch(getChatUsers());
    if (selectedUser) {
      dispatch(
        getChatRoomId({
          messengerUserId: selectedUser?.brandId || selectedUser?._id,
        })
      );
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (chatSocket) {
        console.log("Chat Socket Disconnected....................");
        chatSocketRemove();
        chatSocket.disconnect();
        dispatch(setSelectedUser(null));
        dispatch(setChatRoomId(null));
        dispatch(setIsUserSelected(false));
      }
    };
  }, []);

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      mb="30px"
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "right", mb: 3 }}
      >
        {isAllDataLoading ? (
          <CustomSkeleton
            sx={{ width: "120px", height: "40px", borderRadius: "5px" }}
          />
        ) : (
          <Button
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            variant="contained"
            startIcon={
              <Box
                component="img"
                src={SupportAgentRedIcon}
                alt="Support Agent Icon"
                width="22px"
              ></Box>
            }
            sx={{
              background: "white",
              color: "black",
              fontWeight: "bold",
              width: "120px",
              height: "40px",
              display: "flex",
              gap: 1,
              textTransform: "none",
              "&:hover": {
                background: "rgba(255,255,255,0.9)",
              },
            }}
            onClick={handleSupportAgent}
          >
            Support
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          height: "500px",
          overflow: "hidden",
        }}
      >
        {(!isUserSelected || showRightSideBar) && (
          <Box
            sx={{
              minWidth: "280px",
              height: "100%",
              background: "rgba(14,14,14,1)",
              border: "1px solid grey",
            }}
          >
            <ChatLeftSidebar chatSocket={chatSocket} />
          </Box>
        )}
        {(isUserSelected || showRightSideBar) && (
          <Box
            sx={{
              minWidth: showRightSideBar ? "calc(100% - 280px)" : "100%",
              height: "100%",
              background: "black",
              border: "1px solid grey",
              position: "relative",
            }}
          >
            {selectedUser ? (
              <ChatRightSidebar chatSocket={chatSocket} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  gap: 1.5,
                  color: theme.colors.text.greyDark,
                }}
              >
                <Box
                  sx={{
                    width: "150px",
                    height: "150px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: "5px",
                    background: "rgba(14,14,14,1)",
                    borderRadius: "50%",
                  }}
                >
                  <Chat sx={{ fontSize: "60px" }} />
                </Box>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontWeight={theme.fonts.fontWeightBold}
                  fontSize={theme.fonts.subHeading}
                  color="white"
                >
                  No Chat Selected
                </Typography>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontWeight={theme.fonts.fontWeightBold}
                  fontSize={theme.fonts.sectionHeading}
                >
                  Select a user to chat.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChatPage;
