import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import ReactPlayer from "react-player";
import { AddCircle, ChevronRight, Close } from "@mui/icons-material";
//import AvatarImg from "../../../../../assets/images/BackgroundImage2.png";
// import Videos from "../../../../../assets/images/MoreArticles.webp";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import Crossicon from "../../../../../assets/icons/CrossIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import DefaultImg from "../../../../../assets/images/DefaultImage.png";
import DefaultVideo from "../../../../../assets/TestVideos/video2.mp4";
import { Swiper, SwiperSlide } from "swiper/react";

import { athleteUpdatePublicProfile } from "../../../../../services/athlete/athlete.service";
import { getToken } from "../../../../../routes";
import { useLocation } from "react-router-dom";

const VideosandImages = ({ detail, isUpdated, setIsUpdated }) => {
  const theme = useTheme();
  const token = getToken();

  const location = useLocation();
  const isMyProfile = location?.state?.isMyProfile;

  const [isImage, setIsImage] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const itemCount = 6;

  const images = detail?.images || [];
  const videos = detail?.videos || [];

  const handleImage = () => {
    setIsImage(true);
  };

  const handleUpload = async (e, uploadFileType) => {
    const file = e.target.files;
    let response;
    if (file) {
      if (uploadFileType === "IMAGE") {
        setIsImageLoading(true);
        response = await athleteUpdatePublicProfile({
          publicImages: file,
          appendImages: true,
          loginToken: token,
        });
        setIsImageLoading(false);
      } else if (uploadFileType === "VIDEO") {
        setIsVideoLoading(true);
        response = await athleteUpdatePublicProfile({
          videos: file,
          appendVideos: true,
          loginToken: token,
        });
        setIsVideoLoading(false);
      }
      if (response?.status === 200) {
        setIsUpdated(!isUpdated);
      } else {
        console.error("Failed Response VideosAndImages Page", response);
      }
    }
  };

  const handleVideo = () => {
    setIsImage(false);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (index, deleteType) => {
    let response;
    if (deleteType === "IMAGE") {
      response = await athleteUpdatePublicProfile({
        removeImages: images[index],
        loginToken: token,
      });
    } else if (deleteType === "VIDEO") {
      response = await athleteUpdatePublicProfile({
        removeVideos: videos[index],
        loginToken: token,
      });
    }
    if (response?.status === 200) {
      setIsUpdated(!isUpdated);
    } else {
      console.error("Failed Response VideosAndImages Page", response);
    }
  };

  const featuredImages = images.slice(
    0,
    images.length > itemCount ? itemCount : images.length
  );
  const featuredVideos = videos.slice(
    0,
    videos.length > itemCount ? itemCount : videos.length
  );

  const hasMoreItems = (isImage ? images.length : videos.length) > itemCount;

  return (
    <Box mt="30px" position="relative">
      {/* ------------Images/Videos Toggle---------------- */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: "85%",
            color: "white",
            p: "2px",
            background: "black",
            border: "1px solid rgba(144,5,5,1)",
            borderRadius: "25px",
            fontWeight: theme.fonts.fontWeightBold,
            fontFamily: theme.fonts.montserrat,
            zIndex: 1,
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Box
                sx={{
                  textAlign: "center",
                  py: 1.5,
                  background: isImage ? "rgba(243, 0, 0, 1)" : "black",
                  borderRadius: "25px",
                  cursor: "pointer",
                }}
                onClick={handleImage}
              >
                Images
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  textAlign: "center",
                  py: 1.5,
                  background: isImage ? "black" : "rgba(243, 0, 0, 1)",
                  borderRadius: "25px",
                  cursor: "pointer",
                }}
                onClick={handleVideo}
              >
                Videos
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* -----------------Images/Videos Container--------------- */}
      <Box
        sx={{
          background: theme.colors.background.greyBlackGradient,
          overflow: "hidden",
          position: "relative",
          top: "-22px",
          py: "60px",
          px: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", md: "85%", lg: "95%" },
          }}
        >
          {isMyProfile && (
            <Box
              component="label"
              sx={{
                border: "1px solid grey",
                borderRadius: "5px",
                width: { xs: "50px", sm: "80px", md: "100px", lg: "120px" },
                minHeight: { xs: "150px", lg: "160px", xl: "180px" },
                marginRight: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                float: "left",
                cursor: "pointer",
              }}
            >
              {isImageLoading || isVideoLoading ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                <AddCircle sx={{ fontSize: "30px" }} />
              )}
              {isImage
                ? !isImageLoading && (
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(e) => handleUpload(e, "IMAGE")}
                      style={{ display: "none" }}
                    />
                  )
                : !isVideoLoading && (
                    <input
                      type="file"
                      multiple
                      accept="video/*"
                      onChange={(e) => handleUpload(e, "VIDEO")}
                      style={{ display: "none" }}
                    />
                  )}
            </Box>
          )}
          {isImage ? (
            <Swiper
              grabCursor={true}
              initialSlide={0}
              spaceBetween={20}
              breakpoints={{
                0: { slidesPerView: 1 },
                400: { slidesPerView: 1.5 },
                500: { slidesPerView: 1.8 },
                600: { slidesPerView: 2 },
                700: { slidesPerView: 2.5 },
                900: { slidesPerView: 2.5 },
                1000: { slidesPerView: 2.8 },
                1200: { slidesPerView: 3.5 },
                1400: { slidesPerView: 4 },
                1500: { slidesPerView: 4.5 },
                1600: { slidesPerView: 5 },
              }}
            >
              {featuredImages?.map((img, index) => (
                <SwiperSlide key={index}>
                  <Box
                    sx={{
                      // border: `1px solid grey`,
                      // borderRadius: "5px",
                      // overflow: "hidden",
                      // p: "10px",
                      position: "relative",
                    }}
                  >
                    <Box
                      component="img"
                      src={img || DefaultImg}
                      alt="Avatar"
                      sx={{
                        width: "100%",
                        height: { xs: "150px", lg: "160px", xl: "180px" },
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                      onError={(e) => {
                        e.target.src = DefaultImg;
                      }}
                    ></Box>
                    {isMyProfile && (
                      <Close
                        sx={{
                          position: "absolute",
                          right: "5px",
                          top: 0,
                          color: theme.colors.text.greyLight,
                          cursor: "pointer",
                        }}
                        onClick={() => handleDelete(index, "IMAGE")}
                      />
                    )}
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              grabCursor={true}
              initialSlide={0}
              spaceBetween={20}
              breakpoints={{
                0: { slidesPerView: 1 },
                400: { slidesPerView: 1.5 },
                500: { slidesPerView: 1.8 },
                600: { slidesPerView: 2 },
                700: { slidesPerView: 2.5 },
                900: { slidesPerView: 2.5 },
                1000: { slidesPerView: 2.8 },
                1200: { slidesPerView: 3.5 },
                1400: { slidesPerView: 4 },
                1500: { slidesPerView: 4.5 },
                1600: { slidesPerView: 5 },
              }}
            >
              {featuredVideos?.map((video, index) => (
                <SwiperSlide key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: { xs: "150px", lg: "160px", xl: "180px" },
                    }}
                  >
                    <video
                      muted
                      loop
                      controls
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    >
                      <source
                        src={video || DefaultVideo}
                        type="video/mp4"
                        onError={(e) => {
                          e.target.src = DefaultVideo;
                        }}
                      />
                    </video>
                    {isMyProfile && (
                      <Close
                        sx={{
                          position: "absolute",
                          right: "5px",
                          top: 0,
                          color: theme.colors.text.greyLight,
                          cursor: "pointer",
                        }}
                        onClick={() => handleDelete(index, "VIDEO")}
                      />
                    )}
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Box>
        {hasMoreItems && (
          <Box
            sx={{
              position: "absolute",
              right: "20px",
              bottom: "20px",
              display: "flex",
              alignItems: "center",
              gap: "2px",
              cursor: "pointer",
            }}
            onClick={handleDialogOpen}
          >
            <Typography fontFamily={theme.fonts.livvic}>View More</Typography>
            <ChevronRight />
          </Box>
        )}
      </Box>

      {/* --------------------Images/Videos Dialog--------------------- */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            minWidth: "60vw",
            height: "80%",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
            pb: "20px",
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: "black",
            overflowY: "hidden",
          }}
        >
          <Box display="flex" justifyContent="end" height="40px">
            <CloseIcon
              onClick={handleDialogClose}
              sx={{
                color: theme.colors.text.greyLight,
                cursor: "pointer",
              }}
            />
          </Box>
          <Box
            sx={{
              height: "100%",
              p: "20px",
              backgroundColor: "#121212",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: theme.colors.background.formInput,
                borderRadius: "4px",
              },
            }}
          >
            <Grid container spacing={2} pb="20px">
              {isImage
                ? images?.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Box sx={{ position: "relative" }}>
                        <Box
                          component="img"
                          src={image || DefaultImg}
                          alt="image"
                          id="image"
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                          onError={(e) => {
                            e.target.src = DefaultImg;
                          }}
                        />
                        {isMyProfile && (
                          <CloseIcon
                            onClick={() => handleDelete(index, "IMAGE")}
                            sx={{
                              color: theme.colors.text.greyLight,
                              cursor: "pointer",
                              position: "absolute",
                              top: 0,
                              right: "5px",
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  ))
                : videos?.map((video, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Box sx={{ position: "relative" }}>
                        <video
                          muted
                          loop
                          controls
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        >
                          <source
                            src={video || DefaultVideo}
                            type="video/mp4"
                            onError={(e) => {
                              e.target.src = DefaultVideo;
                            }}
                          />
                        </video>
                        {isMyProfile && (
                          <CloseIcon
                            onClick={() => handleDelete(index, "VIDEO")}
                            sx={{
                              color: theme.colors.text.greyLight,
                              cursor: "pointer",
                              position: "absolute",
                              top: 0,
                              right: "5px",
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  ))}
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default VideosandImages;
