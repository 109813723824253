import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect, useState } from "react";

// const userAgent = window.navigator.userAgent;
// const instagramBrowser = /Instagram|instagram|instagram-app/i.test(userAgent);

let instagramBrowser;

if ("serviceWorker" in navigator) {
  // alert("service worker supported.");
  instagramBrowser = true;
} else {
  // alert("Not supported");
  instagramBrowser = false;
}

let getFirebaseToken;
let onMessageListener;

if (instagramBrowser === true) {
  // TODO: Replace the following with your app's Firebase project configuration
  // See: https://firebase.google.com/docs/web/learn-more#config-object
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  const publicKey = process.env.REACT_APP_VAPID_KEY;

  getFirebaseToken = () => {
    return getToken(messaging, { vapidKey: publicKey })
      .then((currentToken) => {
        if (currentToken) {
          // Perform any other neccessary action with the token
          return currentToken;
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };

  onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
} else {
  // Firebase is disabled for Instagram browser
  console.log("Firebase disabled for Instagram browser");
}

export { getFirebaseToken, onMessageListener };
