import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { Circle } from "@mui/icons-material";

import EducationBackground from "../../../../assets/images/EducationBackground.png";
import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
import SettingsIcon from "../../../../assets/icons/SettingsIcon.svg";
import CreditedIcon from "../../../../assets/icons/CreditedIcon.svg";
import DebitedIcon from "../../../../assets/icons/DebitedIcon.svg";
import { Helmet } from "react-helmet-async";
import { getWalletBalance } from "../../../../services/accounts/accounts.service";
import dayjs from "dayjs";

const Wallet = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const [walletBalance, setWalletBalance] = useState(null);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const [filterBy, setFilterBy] = useState({
    month: "",
    category: "",
    status: "",
  });
  const [filterCount, setFilterCount] = useState({
    month: 0,
    category: 0,
    status: 0,
  });

  const totalFilterSelected =
    filterCount?.month + filterCount?.category + filterCount?.status;

  const handleDetailClick = (detail) => {
    // navigate("/dashboard/wallet/transactions", { state: { detail } });
    navigate(`/dashboard/campaigns/view-details/${detail?.campaignId}`);
  };

  const handleFilters = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (value !== "") {
      setFilterCount((preCount) => ({ ...preCount, [name]: 1 }));
    } else if (value === "") {
      setFilterCount((preCount) => ({ ...preCount, [name]: 0 }));
    }

    setFilterBy((preData) => ({ ...preData, [name]: value }));
  };

  const handleApply = async () => {
    let append = "";
    if (filterBy?.month) {
      append += `&month=${filterBy?.month}`;
    }
    if (filterBy?.category) {
      append += `&category=${filterBy?.category}`;
    }
    if (filterBy?.status) {
      append += `&status=${filterBy?.status}`;
    }
    if (append) {
      append = append.slice(1);
    }
    fetchWalletBalance(append);
    handleFilterDialogClose();
  };

  const fetchWalletBalance = async (append) => {
    const response = await getWalletBalance(append);
    setWalletBalance(response?.data?.data);
  };

  useEffect(() => {
    fetchWalletBalance();
  }, []);
  return (
    <>
      <Helmet>
        <title>Wallet | Burrst</title>
      </Helmet>
      <Box
        sx={{
          paddingX: { xs: "10px", sm: "30px" },
          mt: { xs: "95px", sm: "115px" },
        }}
      >
        {/* -----------------Header------------------------- */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: "30px" }}>
          <Box
            component="img"
            src={LeftArrow}
            alt="Left Arrow"
            sx={{ cursor: "pointer", width: { xs: "24px", sm: "30px" } }}
            onClick={handleBack}
          ></Box>
          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: theme.fonts.heading,
              fontWeight: theme.fonts.fontWeightBold,
            }}
          >
            My Wallet
          </Typography>
        </Box>

        <Box
          p="20px"
          mb="30px"
          sx={{
            background: theme.colors.dashboard.grey200,
            overflow: "hidden",
          }}
        >
          {/* -------------------Total Earned------------------- */}
          <Box
            sx={{
              position: "relative",
              height: "200px",
              border: `1px solid ${theme.colors.text.greyLight}`,
              mb: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "black",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.subHeading,
                  fontWeight: theme.fonts.fontWeightBold,
                }}
              >
                {`$${walletBalance?.totalSpendings || "0"}`}
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  color: theme.colors.text.greyLight,
                }}
              >
                Amount Spent
              </Typography>
            </Box>
          </Box>

          {/* ---------------------Transactions Container------------------------- */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: {
                  xs: theme.fonts.subHeading_xs,
                  sm: theme.fonts.subHeading,
                },
                mb: 2,
              }}
            >
              Transactions
            </Typography>
            <Box
              sx={{
                border: `1px solid ${theme.colors.text.greyLight}`,
                p: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                background: "black",
                cursor: "pointer",
              }}
              onClick={handleFilters}
            >
              <Box sx={{ position: "relative" }}>
                <Box
                  component="img"
                  src={SettingsIcon}
                  alt="Settings Icon"
                  sx={{ cursor: "pointer", width: "20px" }}
                ></Box>
                {totalFilterSelected > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: -7,
                      right: -7,
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      background: "red",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    {totalFilterSelected}
                  </Box>
                )}
              </Box>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                }}
              >
                Filters
              </Typography>
            </Box>
          </Box>

          <Dialog
            open={filterDialogOpen}
            onClose={handleFilterDialogClose}
            PaperProps={{
              sx: {
                minWidth: "50vw",
                background: "black",
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
              },
            }}
            sx={{
              backdropFilter: "blur(3px)",
              backgroundColor: "rgb(255,255,255,0.3)",
            }}
          >
            <DialogContent>
              <Box sx={{ mb: 2 }}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize={theme.fonts.sectionSubHeading}
                >
                  Month
                </Typography>
                <Select
                  name="month"
                  value={filterBy?.month}
                  onChange={handleFilterChange}
                  sx={{
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    height: "45px",
                    width: "100%",
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    },
                  }}
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                    (element, index) => (
                      <MenuItem
                        key={index}
                        value={index === 0 ? "" : (element - 1).toString()}
                      >
                        {index === 0 ? "No Selection" : element}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Box>
              {/* <Box sx={{ mb: 2 }}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize={theme.fonts.sectionSubHeading}
                >
                  Category
                </Typography>
                <Select
                  name="category"
                  value={filterBy?.category}
                  onChange={handleFilterChange}
                  sx={{
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    height: "45px",
                    width: "100%",
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    },
                  }}
                >
                  <MenuItem value="">No Selection</MenuItem>
                  <MenuItem value="credit">Credit</MenuItem>
                  <MenuItem value="debit">Debit</MenuItem>
                </Select>
              </Box> */}
              <Box sx={{ mb: 2 }}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize={theme.fonts.sectionSubHeading}
                >
                  Status
                </Typography>
                <Select
                  name="status"
                  value={filterBy?.status}
                  onChange={handleFilterChange}
                  sx={{
                    border: `1px solid ${theme.colors.text.greyDarker}`,
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: "black",
                    color: "white",
                    height: "45px",
                    width: "100%",
                    "& .MuiSelect-icon": {
                      color: "white",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    },
                  }}
                >
                  <MenuItem value="">No Selection</MenuItem>
                  <MenuItem value="true">Successful</MenuItem>
                  <MenuItem value="false">Failed</MenuItem>
                </Select>
              </Box>
              <Button
                onClick={handleApply}
                sx={{
                  width: "150px",
                  height: "45px",
                  background: "#FF0101",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightBold,
                  textTransform: "none",
                  fontSize: { xs: "16px", md: "20px" },
                }}
              >
                Apply
              </Button>
            </DialogContent>
          </Dialog>

          <Box
            sx={{
              borderRadius: "10px",
              mt: "10px",
            }}
          >
            {walletBalance?.transactions?.map((detail, index) => {
              const transactionType = detail?.type;
              const DEBIT = "debit";
              return (
                <Box
                  key={index}
                  sx={{
                    border: `1px solid ${theme.colors.text.greyLight}`,
                    borderTop:
                      index === 0
                        ? `1px solid ${theme.colors.text.greyLight}`
                        : "none",
                    background: "black",
                    py: "15px",
                    px: "10px",
                    overflow: "hidden",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: { xs: "flex-start", sm: "center" },
                    gap: 1.5,
                  }}
                  onClick={() => handleDetailClick(detail)}
                >
                  <Box sx={{ width: "25px", height: "25px", mt: "3px" }}>
                    <Box
                      component="img"
                      src={
                        transactionType === DEBIT ? CreditedIcon : DebitedIcon
                      }
                      alt={
                        transactionType === DEBIT ? CreditedIcon : DebitedIcon
                      }
                      sx={{ width: "100%", height: "100%" }}
                    ></Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "80%", sm: "90%" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          mb: "2px",
                          overflow: "hidden",
                          // textOverflow: "ellipsis",
                          // whiteSpace: "noWrap",
                        }}
                      >
                        {`${
                          transactionType === DEBIT
                            ? "Added for campaign"
                            : "Paid to athlete"
                        } - `}
                        <span style={{ color: theme.colors.text.greyLight }}>
                          {detail?.campaignName}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: theme.fonts.livvic,
                          color: theme.colors.text.greyLight,
                          fontSize: "12px",
                        }}
                      >
                        {transactionType === DEBIT
                          ? `Debited on ${dayjs(detail?.createdAt).format(
                              "MM-DD-YYYY hh:mm:ss"
                            )}`
                          : `Credited on ${dayjs(detail?.createdAt).format(
                              "MM-DD-YYYY hh:mm:ss"
                            )}`}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        width: { xs: "20%", sm: "10%" },
                        fontFamily: theme.fonts.livvic,
                        color:
                          transactionType === DEBIT
                            ? "rgba(16, 210, 12, 1)"
                            : "rgba(255, 1, 1, 1)",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      {`$${detail?.amount}`}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Wallet;
