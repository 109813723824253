const Joi = require("joi");
const isMyProfile = localStorage.getItem("isMyProfile");

const Schema = Joi.object({
  loginToken: isMyProfile ? Joi.string() : null,
  publicImages:
    isMyProfile === "true"
      ? Joi.array()
      : Joi.array().required().min(1).messages({
          "any.required": `At least 1 public image is required`,
          "array.min": `At least 1 public image is required`,
        }),
  coverImage: isMyProfile
    ? Joi.object()
    : Joi.object().messages({
        "object.base": `Cover image is required and must be a valid file.`,
      }),
  videos: isMyProfile ? Joi.array() : Joi.array().messages({}),
  bio: isMyProfile
    ? Joi.string().max(5000).min(50).trim()
    : Joi.string().max(5000).min(50).required().trim().messages({
        "string.max": `Bio must be at most 5000 characters long`,
        "string.min": `Bio must be minimum 50 characters long`,
        "string.empty": `Bio cannot be empty`,
        "any.required": `Bio is required`,
      }),
  instagramLink: Joi.string()
    .uri()
    .regex(
      /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im
    )
    .allow("")
    .messages({
      "string.uri": `Instagram link must be a valid URL`,
      "string.pattern.base": `Invalid Instagram Profile Link`,
    }),
  facebookLink: Joi.string()
    .uri()
    .regex(
      /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/
    )
    .allow("")
    .messages({
      "string.uri": `Facebook link must be a valid URL`,
      "string.pattern.base": `Invalid Facebook Profile Link`,
    }),
  twitterLink: Joi.string()
    .uri()
    .regex(
      /(?:https?:)?\/\/(?:[A-z]+\.)?(twitter|x)\.com\/@?(?!home|share|privacy|tos)([A-z0-9_]+)\/?/i
    )
    .allow("")
    .messages({
      "string.uri": `Twitter link must be a valid URL`,
      "string.pattern.base": `Invalid Twitter Profile Link`,
    }),

  linkedinLink: Joi.string()
    .uri()
    .regex(
      /(?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/(?:in|company)\/([\w\-\_À-ÿ%]+)\/?/
    )
    .allow("")
    .messages({
      "string.uri": `Linkedin link must be a valid URL`,
      "string.pattern.base": `Invalid Linkedin Profile Link`,
    }),

  tiktokLink: Joi.string()
    .uri()
    .regex(
      /(?:https?:)?\/\/(?:[A-z]+\.)?tiktok\.com\/@?(?!home|share|privacy|tos)([A-z0-9_]+)\/?/i
    )
    .allow("")
    .messages({
      "string.uri": `Tiktok link must be a valid URL`,
      "string.pattern.base": `Invalid TikTok Profile Link`,
    }),
  youtubeLink: Joi.string()
    .uri()
    .regex(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/
    )
    .allow("")
    .messages({
      "string.uri": `Youtube link must be a valid URL`,
      "string.pattern.base": `Invalid Youtube Profile Link`,
    }),
  registrationToken: Joi.string(),
  appendImages: Joi.boolean(),
  appendVideos: Joi.boolean(),
}).custom((value, helpers) => {
  const socialMediaLinks = [
    "instagramLink",
    "facebookLink",
    "twitterLink",
    "linkedinLink",
    "tiktokLink",
    "youtubeLink",
  ];

  const nonEmptyLinks = socialMediaLinks.filter(
    (link) => value[link] && value[link].trim() !== ""
  );

  if (nonEmptyLinks.length < 2) {
    return helpers.message({
      custom: `At least 2 social media links are required.`,
    });
  }
});

export default Schema;
