import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const OnGoingRoiAnalyticschartCard = ({ handleClick, details }) => {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        width: { xs: "90%", md: "45%" },
        my: "20px",
        mx: "auto",
        borderRadius: "5px",
        background: "#272727",
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          my: "10px",
          fontFamily: theme.fonts.livvic,
          fontWeight: 600,
          ml: "15px",
          fontSize: { xs: "18px", md: "20px" },
        }}
      >
        {details?.campaignName}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: { xs: "wrap", lg: "nowrap" },
          flexDirection: "row",
        }}
      >
        {details?.athletes?.slice(0, 2).map((data, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
              backgroundColor: "#1F1F1F",
              borderRadius: "6px",
              my: "15px",
              mx: "15px",
              p: "10px",
            }}
          >
            <Typography
              fontFamily={theme.fonts.livvic}
              color="#C5C5C5"
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 500,
                whiteSpace: "nowrap",
              }}
            >
              {data?.athleteName}
            </Typography>
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: { xs: "20px", md: "20px" },
                fontWeight: 700,
                mt: "3px",
              }}
            >
              {data?.likes + data?.plays + data?.comments}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "right",
                mt: "10px",
              }}
            >
              {data?.change ? (
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    height: "25px",
                    display: "flex",
                    alignItems: "center",
                    px: "10px",
                    backgroundColor:
                      data?.change > 0 ? "#34BD311F" : "#FF000021", //#34BD311F
                    borderRadius: "20px",
                    color: data?.change > 0 ? "#24B821" : "#FF0000", //#24B821(green)
                  }}
                >
                  {data?.change < 0 ? (
                    <ArrowDownwardIcon fontSize="25px" />
                  ) : (
                    <ArrowUpwardIcon />
                  )}
                  {data?.change < 0 ? data?.change * -1 : data?.change}
                </Typography>
              ) : (
                <Box sx={{ height: "25px" }}></Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          my: "12px",
          mr: "15px",
        }}
        onClick={() => handleClick(details?._id, details?.campaignName)}
      >
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            color: "#AFACAC",
            cursor: "pointer",
          }}
        >
          View more
        </Typography>
      </Box>
    </Grid>
  );
};

export default OnGoingRoiAnalyticschartCard;
