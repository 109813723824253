import { alpha } from "@mui/material";

const colors = {
  common: { black: "#000", white: "#fff" },
  divider: {
    grey: "rgba(121, 121, 121, 1)",
    red: "rgba(144, 5, 5, 1)",
  },
  text: {
    greyLight: "rgba(197, 197, 197, 1)",
    greyLightDark: "rgba(175,172,172,1)",
    greyDark: "rgba(145, 142, 142, 1)",
    greyDarker: "rgba(121, 121, 121, 1)",
    yellow: "rgba(255, 166, 2, 1)",
    red: "rgba(255, 0, 0, 1)",
    lightPink: "#FFECEC",
    creamLight: "rgba(255, 236, 236, 1)",
    twitter: "white",
    facebook: "blue",
    linkedIn: "blue",
    youtube: "crimson",
  },
  background: {
    red100: "rgba(61, 44, 44, 1)",
    red200: "rgba(37, 8, 8, 0.67)",
    redBlackGradient: `linear-gradient(270deg, #4B0505 0%, #190505 100%);`,
    // socialMedia: "rgb(50,50,50)",
    socialMedia: "rgba(0,0,0,1)",
    socialMediaLight: "rgb(50,50,50,0.4)",
    // formInput: "linear-gradient(0deg, #252525, #252525)",
    formInput: "rgba(37,37,37,1)",
    otpForm: "rgba(145,142,142,1)",
    registerButton: "rgba(243, 0, 0, 1)",
    autocompleteRenderTagsLightgray: "#414141",
    calenderGray: "#5c5f64",
    gradientBlackRedCorevalues: `linear-gradient(138.82deg, rgba(0, 0, 0, 0.96) 38.68%, #FF0000 130.97%, #FF0000 131.65%),linear-gradient(0deg, #414141, #414141)`,
    gradientRedBlackCorevalues: `linear-gradient(0deg, #414141, #414141),linear-gradient(138.82deg, rgba(0, 0, 0, 0.96) 38.68%, #FF0000 130.97%, #FF0000 131.65%)`,
    yellow: "rgba(255,229,0,1)",
    greyBlackGradient: `linear-gradient(to right,rgba(0, 0, 0, 0.96),rgba(45, 43,43, 0.97),rgba(0, 0, 0, 0.96))`,
    othersMessage: "rgba(70, 35, 35, 1)",
    myMessage: "rgba(128, 11, 11, 1)",
  },
  dashboard: {
    grey100: "rgba(31,31,31,1)",
    grey200: "rgba(18,18,18,1)",
  },
  gradientBlackRed: `linear-gradient(to right,rgba(0, 0, 0, 0.96),rgba(255, 0, 0, 1))`,
  gradientRedBlack: `linear-gradient(to left,rgba(0, 0, 0, 0.96),rgba(255, 0, 0, 1))`,
  gradientRedBlackTopToBottom: `linear-gradient(to top,rgba(0, 0, 0, 0.96),rgba(255, 0, 0, 1))`,
  gradientBlackRedDot: `radial-gradient(rgba(37, 8, 8, 0.67),rgba(255, 0, 0, 1))`,
  testimonialsBackground: `linear-gradient(rgba(0, 0, 0, 0.96) 50%,rgba(139, 6, 6, 0.6))`,
  exploreAthletesBackground: `linear-gradient(to top,rgba(0, 0, 0, 0.96) 40%,rgba(139, 6, 6, 0.6))`,
  ecosystemBorder: `linear-gradient(to right,rgba(0, 0, 0, 0.96),rgba(70, 0, 0, 0.97),rgba(255, 0, 0, 1))`,
  teamCardBackground: "linear-gradient(rgba(23,23,23,1),rgba(48,0,0,0.7))",
};

//border-image-source: linear-gradient(90.36deg, #868686 -9.59%, #4A4A4A -9.59%, #FF0000 -9.57%, rgba(112, 111, 111, 0.96) 48.33%, #FF0000 132.97%);

export default colors;
