import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  AttachFile,
  MoreVert,
  Search,
  Send,
  SentimentSatisfied,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

import { useDispatch, useSelector } from "react-redux";
import { chatSocket, initChatSocket } from "../../../../utils/socket";
import { v4 as uuidv4 } from "uuid";
import EmojiPicker from "emoji-picker-react";
import dayjs from "dayjs";
import BurrstSmallLogo from "../../../../assets/logo/BurrstSmallLogo.svg";

const ChatAdminPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUserId = localStorage.getItem("userId");
  const scrollRef = useRef();

  // const { selectedUser, chatRoomId } = useSelector((state) => state?.chat);

  const [typedMessage, setTypedMessage] = useState("");

  const [messageData, setMessageData] = useState([]);
  const [aiResponse, setAiResponse] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [chatRoomId, setChatRoomId] = useState("");
  const [chatRoomResponse, setChatRoomResponse] = useState({});
  const [isAiMessageLoading, setIsAiMessageLoading] = useState(false);
  const [connectAdminRequest, setConnectAdminRequest] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const pickEmoji = (e) => {
    setTypedMessage((prevData) => prevData + e?.emoji);
  };

  const checkUser = (userId) => {
    if (!userId) {
      return "center";
    }
    if (userId !== currentUserId) {
      return "left";
    } else return "right";
  };

  const handleTypedMessage = (e) => {
    setTypedMessage(e.target.value);
  };

  const handleMessageSend = async () => {
    if (typedMessage.trim().length !== 0) {
      const id = uuidv4();

      aiResponse?.name === null && setIsAiMessageLoading(true);

      await chatSocket?.emit("message", id, chatRoomId, typedMessage);
      setTypedMessage("");
    }
  };

  const handleKeyDownSendMessage = (event) => {
    if ((event.keyCode === 13 || event.key === "enter") && event.shiftKey) {
      handleMessageSend();
      event.preventDefault();
    }
  };

  const handleFeedback = async (isLiked) => {
    const randomId = uuidv4();
    await chatSocket.emit(
      "message_helpful",
      randomId,
      aiResponse?._id,
      aiResponse?.aiMessageId,
      isLiked
    );
  };

  const chatSocketRemove = async () => {
    await chatSocket.emit("remove");
  };

  const lastMessages = async (chat_room_id) => {
    const randomId = uuidv4();
    await chatSocket?.emit("last_messages", randomId, chat_room_id);
  };

  const connectChatRoom = async (socketId, chat_room_id) => {
    chatSocket?.off("chatroom_connected");

    chatSocket?.emit("chatroom_connect", socketId, chat_room_id);

    chatSocket?.on("chatroom_connected", async (id, res) => {
      if (res === "connection successful") {
        lastMessages(chat_room_id);

        chatSocket?.on("get_last_messages", (res, data) => {
          setMessageData(data);
        });

        chatSocket?.on("new_message", (res) => {
          setIsAiMessageLoading(false);
          setMessageData((prevData) => [...prevData, res]);
          setAiResponse(res);
        });

        chatSocket?.on("your_message", (res) => {
          setMessageData((prevData) => [...prevData, res]);
        });
      } else {
        console.log("chatRoom not Connected");
      }
    });
  };

  useEffect(() => {
    initChatSocket();
    if (chatSocket) {
      chatSocket?.on("_connection", async (isSuccess) => {
        if (isSuccess) {
          const randomId = uuidv4();
          chatSocket.emit("chatroom", randomId);

          chatSocket.on("get_chatroom", (id, res) => {
            setChatRoomId(res?._id);
            setChatRoomResponse(res);
            connectChatRoom(id, res?._id);
          });
          console.log("chatSocket connection succcess");
        } else {
          console.log("chatSocket connection failure");
        }
      });
    } else console.log("Unable to connect to socket server.");

    return () => {
      if (chatSocket) {
        console.log("Chat Socket Disconnected....................");
        chatSocketRemove();
        chatSocket.disconnect();
      }

      chatSocket?.off("chatroom_connected");
      chatSocket?.off("get_last_messages");
      chatSocket?.off("new_message");
      chatSocket?.off("your_message");
    };
  }, []);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageData]);
  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      mb="30px"
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      <ArrowBack
        onClick={handleBack}
        sx={{
          cursor: "pointer",
          fontSize: { xs: "30px", sm: "40px" },
          color: "white",
          mb: 1,
        }}
      />
      <Box
        sx={{
          // paddingX: { xs: "10px", md: "30px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            // minWidth: { xs: "300px", sm: "400px", md: "500px" },
            // maxWidth: { xs: "400px", sm: "600px", md: "700px" },
            width: {
              xs: "100%",
              sm: "500px",
              md: "500px",
              lg: "600px",
              xl: "700px",
            },
            height: "100%",
            background: "black",
            border: "1px solid grey",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              minWidth: "100%",
              height: "500px",
              overflow: "hidden",
            }}
          >
            <Box sx={{ minWidth: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  height: "70px",
                  background: "rgba(14,14,14,1)",
                  px: "10px",
                  py: "5px",
                  borderBottom: `1px solid ${theme.colors.text.greyDark}`,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "rgba(217,217,217,1)",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <Box
                      component="img"
                      src={BurrstSmallLogo}
                      alt="Burrst Small Logo"
                      sx={{ width: "90%", height: "90%" }}
                    ></Box>
                  </Box>
                  <Box>
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      fontSize="16px"
                      fontWeight={theme.fonts.fontWeightMedium}
                    >
                      Support
                    </Typography>
                    {/* <Typography
                    fontFamily={theme.fonts.livvic}
                    fontSize="10px"
                    color={theme.colors.text.greyDark}
                  >
                    last seen {FormatTime(chatRoomResponse?.lastMessage?.date)}
                  </Typography> */}
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Search />
                  <MoreVert />
                </Box> */}
              </Box>

              <Box
                sx={{
                  height: "calc(500px - 130px)",
                  px: "20px",
                  py: "10px",
                  mb: "70px",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "grey",
                    borderRadius: "4px",
                  },
                }}
                ref={scrollRef}
              >
                {messageData?.map((message, index) => {
                  return (
                    <Box key={message?._id}>
                      <Stack
                        direction="row"
                        justifyContent={() => checkUser(message?.userId)}
                      >
                        <Box
                          sx={{
                            minWidth: "60px",
                            maxWidth: "60%",
                            my: !message?.userId ? "10px" : "3px",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: theme.fonts.livvic,
                                fontSize: { xs: "7px", sm: "8px" },
                                display: "flex",
                                justifyContent: "right",
                                color: theme.colors.text.greyDark,
                              }}
                            >
                              {`${
                                message?.userId &&
                                message?.userId !== currentUserId &&
                                message?.name
                                  ? `${message?.name}, `
                                  : ""
                              }${dayjs(message?.timestamp).format("HH:mm")}`}
                            </Typography>
                            <Box
                              sx={{
                                px: "10px",
                                py: "5px",
                                ...(message?.userId === currentUserId
                                  ? {
                                      borderTopLeftRadius: "3px",
                                      background:
                                        theme.colors.background.myMessage,
                                    }
                                  : {
                                      borderTopLeftRadius: !message?.userId
                                        ? "3px"
                                        : 0,
                                      borderTopRightRadius: "3px",
                                      background: !message?.userId
                                        ? theme.colors.text.greyDarker
                                        : theme.colors.background.othersMessage,
                                    }),
                                borderBottomLeftRadius: "3px",
                                borderBottomRightRadius: "3px",
                                position: "relative",
                                "&::before": {
                                  content: '""',
                                  position: "absolute",
                                  top: 0,
                                  width: "10px",
                                  height: "10px",
                                  display: !message?.userId ? "none" : "block",
                                  ...(message?.userId === currentUserId
                                    ? {
                                        right: 0,
                                        transform:
                                          "translateX(10px) rotate(-90deg)",
                                        clipPath:
                                          "polygon(100% 0%, 50% 0%, 100% 100%)",
                                        background:
                                          theme.colors.background.myMessage,
                                      }
                                    : {
                                        left: 0,
                                        transform:
                                          "translateX(-10px) rotate(90deg)",
                                        clipPath:
                                          "polygon(0% 0%, 0% 100%, 50% 0%)",
                                        background:
                                          theme.colors.background.othersMessage,
                                      }),
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: theme.fonts.livvic,
                                  fontSize: { xs: "12px", sm: "14px" },
                                }}
                              >
                                {message?.message}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Stack>
                      {message?.last &&
                        message?.aiMessageId &&
                        message?.message?.slice(-1) !== "?" &&
                        index === messageData?.length - 1 && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              my: 2,
                            }}
                          >
                            <Box
                              sx={{
                                background: "rgba(14,14,14,1)",
                                py: "5px",
                                px: "10px",
                                borderRadius: "10px",
                              }}
                            >
                              <Typography>
                                {connectAdminRequest
                                  ? "Do you want to connect to Admin?"
                                  : "Is your issue resolved?"}
                              </Typography>
                              <Box
                                sx={{
                                  fontSize: { xs: "12px", sm: "14px" },
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "right",
                                  gap: 1,
                                  mt: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    cursor: "pointer",
                                    color: theme.colors.text.greyDarker,
                                    "&:hover": {
                                      color: "white",
                                    },
                                  }}
                                  onClick={
                                    connectAdminRequest
                                      ? () => handleFeedback(false)
                                      : () => navigate(-1)
                                  }
                                >
                                  Yes
                                </Typography>
                                <Typography
                                  sx={{
                                    cursor: "pointer",
                                    color: theme.colors.text.greyDarker,
                                    "&:hover": {
                                      color: "white",
                                    },
                                  }}
                                  onClick={
                                    connectAdminRequest
                                      ? () => handleFeedback(true)
                                      : () => setConnectAdminRequest(true)
                                  }
                                >
                                  No
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                    </Box>
                  );
                })}
              </Box>

              <Box
                sx={{
                  px: "20px",
                  position: "absolute",
                  bottom: "10px",
                  width: "100%",
                }}
              >
                {!isAiMessageLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      border: "none",
                      background: theme.colors.text.greyLight,
                      color: "black",
                      borderRadius: "10px",
                      height: "40px",
                      overflow: "hidden",
                    }}
                  >
                    <SentimentSatisfied
                      sx={{
                        cursor: "pointer",
                        color: showEmojiPicker
                          ? "black"
                          : theme.colors.text.greyDarker,
                        zIndex: 1,
                        width: "40px",
                      }}
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    />
                    <TextField
                      name="message"
                      placeholder="Message"
                      multiline
                      value={typedMessage || ""}
                      onChange={handleTypedMessage}
                      onKeyDown={handleKeyDownSendMessage}
                      fullWidth
                      autoComplete="off"
                      InputProps={{
                        sx: {
                          border: "0",
                          padding: 0,
                          background: theme.colors.text.greyLight,
                          color: "black",
                          borderRadius: "10px",
                          width: "100%",
                          "&::-webkit-scrollbar": {
                            width: "0px",
                          },
                          "& textArea": {
                            overflow: "hidden",
                            maxHeight: "40px",
                          },
                        },
                      }}
                      sx={{
                        "& fieldset": { border: "none" },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused": {
                            fieldset: {
                              border: "0",
                              transition: "border 0.2s ease-out",
                            },
                          },
                          "&:hover": {
                            border: "0",
                          },
                        },
                      }}
                    />
                    <Send
                      onClick={handleMessageSend}
                      sx={{
                        cursor: "pointer",
                        zIndex: 1,
                        width: "40px",
                        color: theme.colors.text.greyDarker,
                      }}
                    />
                  </Box>
                )}

                {showEmojiPicker && (
                  <EmojiPicker
                    onEmojiClick={pickEmoji}
                    theme="dark"
                    lazyLoadEmojis={true}
                    previewConfig={{ showPreview: false }}
                    style={{
                      minWidth: "280px",
                      maxWidth: "300px",
                      height: "320px",
                      position: "absolute",
                      bottom: "50px",
                    }}
                  />
                )}
                {isAiMessageLoading && (
                  <CircularProgress
                    size="16px"
                    sx={{
                      position: "absolute",
                      bottom: "10px",
                      left: "50%",
                      color: "white",
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatAdminPage;
