import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

import LatestArticles from "../../../../../assets/images/LatestArticles.webp";
import MoreArticles from "../../../../../assets/images/MoreArticles.webp";
import ArticlesUserIcon from "../../../../../assets/icons/ArticlesUserIcon.svg";
import ArticlesCalendarIcon from "../../../../../assets/icons/ArticlesCalendarIcon.svg";

import { BASE_IMAGE_URL } from "../../../../../utils/constants";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const LatestArticlesCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const PodcastsNextGeneration = `${BASE_IMAGE_URL}/PodcastsNextGeneration.png`;

  const handleImageError = (e) => {
    e.target.src = LatestArticles;
  };

  const handleCardClick = () => {
    navigate(`/dashboard/resources/newsletter/${detail?._id}`);
  };

  return (
    <Box
      p="15px"
      sx={{
        border: `1px solid grey`,
        background: theme.colors.common.black,
        overflow: "hidden",
        maxWidth: "600px",
        minWidth: "300px",
      }}
      data-aos="fade-up"
      data-aos-duration="1500"
      onClick={isAllDataLoading ? null : handleCardClick}
    >
      {isAllDataLoading ? (
        <CustomSkeleton
          type="img"
          sx={{ width: "100%", height: "200px", mb: 1 }}
        />
      ) : (
        <Box
          component="img"
          src={detail?.featuredImage}
          onError={handleImageError}
          alt="Latest Articles"
          id="latestArticles"
          sx={{ width: "100%", height: "200px", objectFit: "cover", mb: 1 }}
        ></Box>
      )}

      <Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          fontSize="16px"
          mb={2}
          sx={{
            height: "50px",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {/* Find the Best Web3 Opportunities in the Sports Industry Right Now. */}
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            detail?.articleTitle
          )}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                gap: "4px",
              }}
            >
              <Box
                component="img"
                src={ArticlesUserIcon}
                alt="Stopwatch Icon"
              ></Box>
              <Typography
                variant="h4"
                fontFamily={theme.fonts.livvic}
                fontSize="12px"
                color={theme.colors.text.red}
              >
                {detail?.authorName}
              </Typography>
            </Box>
          )}
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            detail?.createdAt && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                  gap: "4px",
                }}
              >
                <Box
                  component="img"
                  src={ArticlesCalendarIcon}
                  alt="Calendar Icon"
                ></Box>
                <Typography
                  variant="h4"
                  fontFamily={theme.fonts.livvic}
                  fontSize="12px"
                >
                  {dayjs(detail?.createdAt).format("MMM DD YYYY")}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LatestArticlesCard;
