import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { NotificationsOff } from "@mui/icons-material";
import TimeAgo from "react-timeago";
import { useDispatch, useSelector } from "react-redux";
import { markNotificationsAsRead } from "../../../../store/notificationsReducer";

const Notifications = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/dashboard/home");
  };

  const dispatch = useDispatch();
  const notificationsList = useSelector((state) => state?.notifications?.list);
  const unreadCount = notificationsList?.[0]?.unreadCount;
  let timeoutId;

  const fetchAndUpdateNotifications = async () => {
    if (unreadCount > 0) {
      timeoutId = setTimeout(async () => {
        dispatch(markNotificationsAsRead());
      }, 3000);
    }
  };

  useEffect(() => {
    fetchAndUpdateNotifications();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [notificationsList]);

  return (
    <Box
      sx={{
        paddingX: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
        mb: "30px",
        minHeight: "95vh",
      }}
    >
      {/* -----------------Header------------------------- */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: "30px" }}>
        <Box
          component="img"
          src={LeftArrow}
          alt="Left Arrow"
          sx={{ cursor: "pointer", width: { xs: "30px", sm: "40px" } }}
          onClick={handleBack}
        ></Box>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontSize: theme.fonts.heading,
          }}
        >
          Notifications
        </Typography>
      </Box>
      {notificationsList[0]?.notifications?.length > 0 ? (
        <>
          <Box>
            {notificationsList[0]?.notifications?.map((detail, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    px: 2,
                    py: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                    border: `1px solid ${theme.colors.text.greyDark}`,
                    width: "100%",
                    background: !detail?.read
                      ? "rgba(30,30,30,1)"
                      : "rgba(4,4,4,1)",
                    mb: 2,
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      {detail?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        color: !detail?.read
                          ? theme.colors.text.greyLight
                          : theme.colors.text.greyDark,
                        fontSize: "14px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {detail?.body}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        color: !detail?.read
                          ? theme.colors.text.greyLight
                          : theme.colors.text.greyDarker,
                        fontSize: "10px",
                      }}
                    >
                      <TimeAgo date={detail?.createdAt} title="" />
                    </Typography>
                  </Box>
                  {/* <Close
                      sx={{
                        color: theme.colors.text.greyDark,
                        cursor: "pointer",
                        width: "5%",
                      }}
                      onClick={handleNotificationDelete}
                    /> */}
                </Box>
              );
            })}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
            gap: 2,
            color: theme.colors.text.greyDark,
            // border: `1px solid ${theme.colors.text.greyDark}`,
          }}
        >
          <Box
            sx={{
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: "5px",
              background: "rgba(14,14,14,1)",
              borderRadius: "50%",
            }}
          >
            <NotificationsOff />
          </Box>
          <Typography
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
          >
            No New Notifications
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Notifications;
