const fonts = {
  livvic: "Livvic",
  russoOne: "Russo One",
  workSans: "Work Sans",
  poppins: "Poppins",
  roboto: "Roboto",
  montserrat: "Montserrat",
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  smallBorderRadius: "10px",
  mediumBorderRadius: "20px",
  largeBorderRadius: "30px",
  registrationHeading_xs: "24px",
  registrationHeading_sm: "26px",
  registrationSubHeading_xs: "14px",
  registrationSubHeading_sm: "16px",
  heading: "2.2rem",
  heading_xs: "1.5rem",
  subHeading: "1.8rem",
  subHeading_xs: "1.3rem",
  sectionHeading: "1.3rem",
  sectionSubHeading: "1.1rem",
  sectionBodyText: "1rem",
  cardHeading: "1.5rem",
  cardSubHeading: "1.3rem",
  cardHighlights: "1.1rem",
  cardTexts: "1rem",
  beforeLogin: {
    heading: { xl: "40px" },
    subHeading: { xl: "30px" },
    body: { lg: "16px", xl: "18px" },
  },
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
  },
  body1: {
    lineHeight: 1.5,
  },
  body2: {
    lineHeight: 22 / 14,
  },
  caption: {
    lineHeight: 1.5,
  },
};

export default fonts;
