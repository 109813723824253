// import AthleteImage1 from "../assets/images/composite-image-athlete-man-star-1.png";
// import AthleteImage2 from "../assets/images/composite-image-athlete-man-star-2.png";
// import AthleteImage3 from "../assets/images/composite-image-athlete-man-star-3.png";
// import AthleteImage4 from "../assets/images/composite-image-athlete-man-star-4.png";

// import CampaignImage1 from "../assets/images/basketball-equipment-1.png";
// import CampaignImage2 from "../assets/images/basketball-equipment-2.png";
// import CampaignImage3 from "../assets/images/basketball-equipment-3.png";

// import ArticleImage1 from "../assets/images/RecentNews&Articles-1.png";
// import ArticleImage2 from "../assets/images/RecentNews&Articles-2.png";
// import ArticleImage3 from "../assets/images/RecentNews&Articles-3.png";

import OurPartnersImage1 from "../assets/images/UtkalLabs.png";
import OurPartnersImage2 from "../assets/images/Fincent.png";
import OurPartnersImage3 from "../assets/images/Top5Hoops.jpg";
import OurPartnersImage4 from "../assets/images/Gs.png";
import OurPartnersImage5 from "../assets/images/Vt.png";
import OurPartnersImage6 from "../assets/images/Ptt.png";
import OurPartnersImage7 from "../assets/images/Oklahoma.png";
import OurPartnersImage8 from "../assets/images/Atlantis.png";
import OurPartnersImage9 from "../assets/images/Arizonalogo.png";
import OurPartnersImage10 from "../assets/images/Partner8.png";
import OurPartnersImage11 from "../assets/images/FirstString.jpg";
import OurPartnersImage12 from "../assets/images/RaveOn.png";

import { BASE_IMAGE_URL } from "../utils/constants";

const AthleteImage1 = `${BASE_IMAGE_URL}/composite-image-athlete-man-star-1.png`;
const AthleteImage2 = `${BASE_IMAGE_URL}/composite-image-athlete-man-star-2.png`;
const AthleteImage3 = `${BASE_IMAGE_URL}/composite-image-athlete-man-star-3.png`;
const AthleteImage4 = `${BASE_IMAGE_URL}/composite-image-athlete-man-star-4.png`;

const CampaignImage1 = `${BASE_IMAGE_URL}/basketball-equipment-1.png`;
const CampaignImage2 = `${BASE_IMAGE_URL}/basketball-equipment-2.png`;
const CampaignImage3 = `${BASE_IMAGE_URL}/basketball-equipment-3.png`;

const ArticleImage1 = `${BASE_IMAGE_URL}/RecentNews&Articles-1.png`;
const ArticleImage2 = `${BASE_IMAGE_URL}/RecentNews&Articles-2.png`;
const ArticleImage3 = `${BASE_IMAGE_URL}/RecentNews&Articles-3.png`;

const AdidasImage = `${BASE_IMAGE_URL}/adidas.png`;
const DashboardAthleteImage = `${BASE_IMAGE_URL}/athletesImage1.png`;
const DashboardAthleteAvatar = `${BASE_IMAGE_URL}/athletesAvatar.png`;
const IdeaImage = `${BASE_IMAGE_URL}/IdeaImage.svg`;

export const atheleteCardDetails = [
  {
    id: 1,
    imgUrl: AthleteImage1,
    athleteName: "Allie Anderson",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
  },
  {
    id: 2,
    imgUrl: AthleteImage2,
    athleteName: "LeBron James",
    games: "Football, Basketball",
    academy: "United States Sports Adademy",
  },
  {
    id: 3,
    imgUrl: AthleteImage3,
    athleteName: "Simone Biles",
    games: "Gymnasticals, Cricket",
    academy: "United States Sports Adademy",
  },
  {
    id: 4,
    imgUrl: AthleteImage4,
    athleteName: "Cristiano Ronaldo",
    games: "Football, Soccer",
    academy: "United States Sports Adademy",
  },
  {
    id: 5,
    imgUrl: AthleteImage1,
    athleteName: "Allie Anderson",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
  },
  {
    id: 6,
    imgUrl: AthleteImage2,
    athleteName: "LeBron James",
    games: "Football, Basketball",
    academy: "United States Sports Adademy",
  },
  {
    id: 7,
    imgUrl: AthleteImage3,
    athleteName: "Simone Biles",
    games: "Gymnasticals, Cricket",
    academy: "United States Sports Adademy",
  },
  {
    id: 8,
    imgUrl: AthleteImage4,
    athleteName: "Cristiano Ronaldo",
    games: "Football, Soccer",
    academy: "United States Sports Adademy",
  },
];
export const campaignCardDetails = [
  {
    id: 1,
    imgUrl: CampaignImage1,
    quote: "Just Do It",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$200",
    activities: "Instagram posts",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 2,
    imgUrl: CampaignImage2,
    quote: "Impossible is Nothing",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$400",
    activities: "Instagram posts",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 3,
    imgUrl: CampaignImage3,
    quote: "Be Like Mike",
    description:
      "It became a cultural phenomenon and helped solidify Gatorade's position in the sports drink market.",
    compensation: "$600",
    activities: "Instagram posts",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 4,
    imgUrl: CampaignImage1,
    quote: "Just Do It",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$200",
    activities: "Instagram posts",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 5,
    imgUrl: CampaignImage2,
    quote: "Impossible is Nothing",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$400",
    activities: "Instagram posts",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 6,
    imgUrl: CampaignImage3,
    quote: "Be Like Mike",
    description:
      "It became a cultural phenomenon and helped solidify Gatorade's position in the sports drink market.",
    compensation: "$600",
    activities: "Instagram posts",
    endDate: "27 October 2023",
    status: "Open",
  },
];
export const recentNewsAndArticlesCardDetails = [
  {
    id: 1,
    imgUrl: ArticleImage1,
    date: "27TH NOV 2023",
    description: "Game-Changing Trade: All-Star Player Joins Contending",
  },

  {
    id: 2,
    imgUrl: ArticleImage2,
    date: "27TH NOV 2023",
    description: "Next Earth Soon to Launch Staking and Other Updates",
  },

  {
    id: 3,
    imgUrl: ArticleImage3,
    date: "27TH NOV 2023",
    description: "Next Earth Soon to Launch Staking Value From Burn",
  },
  {
    id: 4,
    imgUrl: ArticleImage1,
    date: "27TH NOV 2023",
    description: "Game-Changing Trade: All-Star Player Joins Contending",
  },

  {
    id: 5,
    imgUrl: ArticleImage2,
    date: "27TH NOV 2023",
    description: "Next Earth Soon to Launch Staking and Other Updates",
  },

  {
    id: 6,
    imgUrl: ArticleImage3,
    date: "27TH NOV 2023",
    description: "Next Earth Soon to Launch Staking Value From Burn",
  },
];

export const ourPartnersDetails = [
  {
    id: 1,
    imgUrl: OurPartnersImage1,
    link: "https://utkallabs.com/",
  },
  {
    id: 2,
    imgUrl: OurPartnersImage2,
    link: "https://fincent.com/",
  },
  {
    id: 3,
    imgUrl: OurPartnersImage3,
    link: "https://lnk.bio/Top5Hoops/",
  },
  // {
  //   id: 4,
  //   imgUrl: OurPartnersImage4,
  //   link: "https://www.colorado.edu/",
  // },
  // {
  //   id: 5,
  //   imgUrl: OurPartnersImage5,
  //   link: "https://www.vt.edu/",
  // },
  // {
  //   id: 6,
  //   imgUrl: OurPartnersImage6,
  //   link: "https://www.pitt.edu/",
  // },
  // {
  //   id: 7,
  //   imgUrl: OurPartnersImage7,
  //   link: "https://www.ou.edu/",
  // },
  // {
  //   id: 8,
  //   imgUrl: OurPartnersImage8,
  //   link: "https://atlantisuniversity.edu/",
  // },
  // {
  //   id: 9,
  //   imgUrl: OurPartnersImage9,
  //   link: "https://www.arizona.edu/",
  // },
  // {
  //   id: 10,
  //   imgUrl: OurPartnersImage10,
  //   link: "https://www.ufl.edu/",
  // },
  {
    id: 11,
    imgUrl: OurPartnersImage11,
    link: "https://firststring.io/",
  },
  {
    id: 12,
    imgUrl: OurPartnersImage12,
    link: "https://raveonsports.com/",
  },
];
export const brandDetails = [
  {
    id: 1,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 2,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 3,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 4,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 5,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 6,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 7,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 8,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 9,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 10,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 11,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 12,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 13,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 14,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 15,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
  {
    id: 16,
    imgUrl: AdidasImage,
    brandName: "Adidas",
    activeCampaign: "24 Active Campaigns",
  },
];
export const ecosystem = {
  id1: {
    name: "Blockchain",
    details:
      "Blockchain.com is a cryptocurrency financial services company. The company began as the first Bitcoin blockchain explorer in 2011 and later created a cryptocurrency wallet that accounted for 28% of bitcoin transactions between 2012 and 2020.",
  },
  id2: {
    name: "AI",
    details:
      "Artificial intelligence is the intelligence of machines or software, as opposed to the intelligence of humans or animals. It is also the field of study in computer science that develops and studies intelligent machines. 'AI' may also refer to the machines themselves.",
  },
  id3: {
    name: "Smart Contracts",
    details:
      "Smart contracts defined. Smart contracts are simply programs stored on a blockchain that run when predetermined conditions are met. They typically are used to automate the execution of an agreement so that all participants can be immediately certain of the outcome, without any intermediary's involvement or time loss.",
  },
};
export const dashboardAthletesDetails = [
  {
    id: 1,
    coverImgUrl: DashboardAthleteImage,
    avatarUrl: DashboardAthleteAvatar,
    athleteName: "James Smith",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 2,
    coverImgUrl: DashboardAthleteImage,
    avatarUrl: DashboardAthleteAvatar,
    athleteName: "James Smith",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 3,
    coverImgUrl: DashboardAthleteImage,
    avatarUrl: DashboardAthleteAvatar,
    athleteName: "James Smith",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 4,
    coverImgUrl: DashboardAthleteImage,
    avatarUrl: DashboardAthleteAvatar,
    athleteName: "James Smith",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 5,
    coverImgUrl: DashboardAthleteImage,
    avatarUrl: DashboardAthleteAvatar,
    athleteName: "James Smith",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 6,
    coverImgUrl: DashboardAthleteImage,
    avatarUrl: DashboardAthleteAvatar,
    athleteName: "James Smith",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
];

export const dashboardCampaignDetails = [
  {
    id: 1,
    imgUrl: CampaignImage1,
    quote: "Just Do It",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$200",
    activities: "Instagram posts,Other",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 2,
    imgUrl: CampaignImage2,
    quote: "Impossible is Nothing",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$400",
    activities: "Instagram posts,Other",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 3,
    imgUrl: CampaignImage3,
    quote: "Be Like Mike",
    description:
      "It became a cultural phenomenon and helped solidify Gatorade's position in the sports drink market.",
    compensation: "$600",
    activities: "Instagram posts,Other",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 4,
    imgUrl: CampaignImage1,
    quote: "Just Do It",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$200",
    activities: "Instagram posts,Other",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 5,
    imgUrl: CampaignImage2,
    quote: "Impossible is Nothing",
    description:
      "It featured athletes of various sports and encouraged people to push their limits and overcome challenges.",
    compensation: "$400",
    activities: "Instagram posts,Other",
    endDate: "27 October 2023",
    status: "Open",
  },
  {
    id: 6,
    imgUrl: CampaignImage3,
    quote: "Be Like Mike",
    description:
      "It became a cultural phenomenon and helped solidify Gatorade's position in the sports drink market.",
    compensation: "$600",
    activities: "Instagram posts,Other",
    endDate: "27 October 2023",
    status: "Open",
  },
];

export const ideas = [
  {
    id: 1,
    image: IdeaImage,
    name: "John S",
    title: "OPTION TO SHARE VIDEO CLIPS",
    idea: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam.
    eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur.
    voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
    Lorem ipsum dolor sit amet consectetur adipisicing elit.voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
    Lorem ipsum dolor sit amet consectetur adipisicing elit.`,
  },
  {
    id: 2,
    image: IdeaImage,
    name: "John S",
    title: "OPTION TO SHARE VIDEO CLIPS",
    idea: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam.
    eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur.
    voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
    Lorem ipsum dolor sit amet consectetur adipisicing elit.voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
    Lorem ipsum dolor sit amet consectetur adipisicing elit.`,
  },
  {
    id: 3,
    image: IdeaImage,
    name: "John S",
    title: "OPTION TO SHARE VIDEO CLIPS",
    idea: `Lorem ipsum dolor sit amet consectetur adipisicing elit.
    Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam.
    eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur.
    voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
    Lorem ipsum dolor sit amet consectetur adipisicing elit.voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
    Lorem ipsum dolor sit amet consectetur adipisicing elit.`,
  },
];
export const contractListItems = [
  "Clearly outline the responsibilities, deliverables, and expectations of each party.",
  "Include clauses to protect sensitive information and trade secrets.",
  "Specify the commencement date, duration of the agreement and conditions where either party can terminate the contract",
];

export const campaignActivityDetails = [
  {
    id: 1,
    activities: "Instagram posts",
    additionalskills: "Swimming",
    compensation: "$200",
    additionalProjectfiles: [
      {
        fid: 1,
        file: CampaignImage1,
      },
      {
        fid: 2,
        file: CampaignImage2,
      },
    ],
  },
  {
    id: 2,
    activities: "Instagram posts",
    additionalskills: "Swimming",
    compensation: "$200",
    additionalProjectfiles: [
      {
        fid: 1,
        file: CampaignImage2,
      },
      {
        fid: 2,
        file: CampaignImage1,
      },
    ],
  },
  {
    id: 3,
    activities: "Instagram posts",
    additionalskills: "Swimming",
    compensation: "$200",
    additionalProjectfiles: [
      {
        fid: 1,
        file: CampaignImage1,
      },
      {
        fid: 2,
        file: CampaignImage2,
      },
    ],
  },
  {
    id: 4,
    activities: "Instagram posts",
    additionalskills: "Swimming",
    compensation: "$200",
    additionalProjectfiles: [
      {
        fid: 1,
        file: CampaignImage2,
      },
      {
        fid: 2,
        file: CampaignImage1,
      },
    ],
  },
];

export const myCampaignViewApplicants = [
  {
    id: 1,
    imgUrl: AthleteImage1,
    athleteName: "Allie Anderson",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 2,
    imgUrl: AthleteImage2,
    athleteName: "LeBron James",
    games: "Football, Basketball",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 3,
    imgUrl: AthleteImage3,
    athleteName: "Simone Biles",
    games: "Gymnasticals, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 4,
    imgUrl: AthleteImage4,
    athleteName: "Cristiano Ronaldo",
    games: "Football, Soccer",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  // {
  //   id: 5,
  //   imgUrl: AthleteImage1,
  //   athleteName: "Allie Anderson",
  //   games: "Football, Cricket",
  //   academy: "United States Sports Adademy",
  // instagramFollower: "25.1k",
  //   facebookFollower: "25.1k",
  //   twitterFollower: "25.1k",
  //   linkedInFollower: "25.1k",
  // },
  // {
  //   id: 6,
  //   imgUrl: AthleteImage2,
  //   athleteName: "LeBron James",
  //   games: "Football, Basketball",
  //   academy: "United States Sports Adademy",
  // instagramFollower: "25.1k",
  //   facebookFollower: "25.1k",
  //   twitterFollower: "25.1k",
  //   linkedInFollower: "25.1k",
  // },
];

export const myCampaignAiRecommended = [
  {
    id: 1,
    imgUrl: AthleteImage1,
    athleteName: "Allie Anderson",
    games: "Football, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 2,
    imgUrl: DashboardAthleteAvatar,
    athleteName: "LeBron James",
    games: "Football, Basketball",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 3,
    imgUrl: AthleteImage3,
    athleteName: "Simone Biles",
    games: "Gymnasticals, Cricket",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 4,
    imgUrl: AthleteImage4,
    athleteName: "Cristiano Ronaldo",
    games: "Football, Soccer",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
];

export const myCampaignSignedAthlete = [
  {
    id: 1,
    signedDate: "07/09/23",
    imgUrl: AthleteImage1,
    athleteName: "Allie Anderson",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 2,
    signedDate: "07/13/23",
    imgUrl: AthleteImage2,
    athleteName: "LeBron James",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },

  {
    id: 3,
    signedDate: "07/09/23",
    imgUrl: AthleteImage3,
    athleteName: "Simone Biles",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 4,
    signedDate: "07/13/23",
    imgUrl: AthleteImage4,
    athleteName: "Cristiano Ronaldo",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  // {
  //   id: 5,
  //   signedDate: "07/13/23",
  //   imgUrl: AthleteImage2,
  //   athleteName: "LeBron James",
  //   academy: "United States Sports Adademy",
  //   instagramFollower: "25.1k",
  //   facebookFollower: "25.1k",
  //   twitterFollower: "25.1k",
  //   linkedInFollower: "25.1k",
  // },
];

export const myCampaignUnsignedAthlete = [
  {
    id: 2,
    imgUrl: DashboardAthleteAvatar,
    athleteName: "Simone Biles",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  {
    id: 1,
    imgUrl: DashboardAthleteAvatar,
    athleteName: "Cristiano Ronaldo",
    academy: "United States Sports Adademy",
    instagramFollower: "25.1k",
    facebookFollower: "25.1k",
    twitterFollower: "25.1k",
    linkedInFollower: "25.1k",
  },
  // {
  //   id: 5,
  //   imgUrl: AthleteImage1,
  //   athleteName: "Allie Anderson",
  //   academy: "United States Sports Adademy",
  //   instagramFollower: "25.1k",
  //   facebookFollower: "25.1k",
  //   twitterFollower: "25.1k",
  //   linkedInFollower: "25.1k",
  // },
  // {
  //   id: 6,
  //   imgUrl: AthleteImage2,
  //   athleteName: "LeBron James",
  //   academy: "United States Sports Adademy",
  //   instagramFollower: "25.1k",
  //   facebookFollower: "25.1k",
  //   twitterFollower: "25.1k",
  //   linkedInFollower: "25.1k",
  // },
];

export const brandProfileImageList = [
  {
    id: 1,
    avatarUrl: DashboardAthleteAvatar,
  },
  {
    id: 2,
    avatarUrl: DashboardAthleteAvatar,
  },
  {
    id: 3,
    avatarUrl: DashboardAthleteAvatar,
  },
  {
    id: 4,
    avatarUrl: DashboardAthleteAvatar,
  },
  {
    id: 5,
    avatarUrl: DashboardAthleteAvatar,
  },
];
