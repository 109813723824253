import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Pagination,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";

import CampaignsListCard from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/campaigns.list.card";

import { dashboardCampaignDetails } from "../../../../../_mock/dummyData";
import { myCampaignsList } from "../../../../../services/campaign/campaign.service";

const CampaignsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const clientSecretString = params.get("page");
  const clientSecret = parseInt(clientSecretString);

  const [formData, setFormData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const value = [1, 2, 3, 4];
  const [apiData, setApiData] = useState({
    page: clientSecret ? clientSecret : 1,
    pageSize: 10,
  });

  // const totalItems = formData?.length;
  // const totalPages = Math.ceil(totalItems / 10);

  // const handlePageChange = (pageNo) => {
  // setApiData((prevFormData) => ({
  //   ...prevFormData,
  //   page: pageNo,
  // }));
  // };

  // const { items } = usePagination({
  //   count: totalPages,
  //   onChange: (e, page) => handlePageChange(page),
  // });

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `/dashboard/campaigns?page=${apiData?.page}`
    );
    callApi();
  }, [apiData]);

  const callApi = async (body) => {
    try {
      const response = await myCampaignsList(apiData);

      if (response.status === 200) {
        setFormData([...response.data]);
        setDataStatus(true);
        setLoading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        console.error("Failed to fetch campaigns list:", response.message);
      }
    } catch (error) {
      console.error("Error fetching campaigns list:", error);
    }
  };

  const handlePrev = () => {
    if (apiData?.page > 1) {
      setApiData((prevFormData) => ({
        ...prevFormData,
        page: prevFormData.page - 1,
      }));
    }
  };

  const handleNext = () => {
    setApiData((prevFormData) => ({
      ...prevFormData,
      page: prevFormData.page + 1,
    }));
  };

  const handleBack = () => {
    navigate("/dashboard/home");
  };

  const handleAddNewCampaign = () => {
    navigate("/dashboard/campaigns/basic-info");
  };

  return (
    <>
      <Helmet>
        <title>Campaigns | Burrst</title>
      </Helmet>
      <Box
        sx={{
          paddingX: "30px",
          "@media (max-width:600px)": {
            paddingX: "10px",
          },
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          mt="115px"
          mb="30px"
        >
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
            <Box
              component="img"
              src={LeftArrow}
              alt="Left Arrow"
              width="30px"
              sx={{ cursor: "pointer" }}
              onClick={handleBack}
            ></Box>
            <Typography
              color={theme.colors.common.white}
              fontFamily={theme.fonts.russoOne}
              sx={{ fontSize: { xs: "30px", md: theme.fonts.heading } }}
            >
              My Campaigns
            </Typography>
          </Box>
        </Box>

        <Box
          mt="30px"
          p="20px"
          overflow="hidden"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            background: theme.colors.dashboard.grey200,
          }}
        >
          <Box
            sx={{
              width: "100%",
              p: 3,
            }}
          >
            <Box
              sx={{
                borderRadius: "5px",
                p: "5px 10px",
                height: "42px",
                width: "200px",
                float: "right",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.colors.divider.red,
                background: "rgba(255,230,230,1)",
                cursor: "pointer",
              }}
              onClick={handleAddNewCampaign}
            >
              + Add a new campaign
            </Box>
          </Box>
          {/* {dashboardCampaignDetails?.map((detail, index) => (
            <CampaignsListCard detail={detail} key={index} />
          ))} */}
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              width="90%"
            >
              {value?.map((index) => (
                <Box
                  key={index}
                  ml="20px"
                  mb="20px"
                  mx="20px"
                  sx={{ minWidth: { xs: "90%", sm: "500px" } }}
                >
                  <Skeleton
                    sx={{
                      bgcolor: "grey.900",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    variant="rectangular"
                    height={220}
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <>
              {formData && formData?.length > 0 ? (
                formData?.map((detail, index) => (
                  <CampaignsListCard detail={detail} key={index} />
                ))
              ) : (
                <Typography
                  py="100px"
                  fontFamily={theme.fonts.livvic}
                  fontWeight={theme.fonts.fontWeightMedium}
                  sx={{ fontSize: "20px" }}
                >
                  No Campaign Found
                </Typography>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            gap: "20px",
            mb: "30px",
            pb: "30px",
            px: "120px",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: theme.colors.dashboard.grey200,
          }}
        >
          <Button
            onClick={handlePrev}
            disabled={apiData?.page === 1}
            sx={{
              px: { xs: "50px", md: "70px" },
              textTransform: "none",
              color: "white",
              fontSize: { xs: "18px", md: "20px" },
              borderRadius: "5px",
              backgroundColor: "#DD0000",
              marginTop: "10px",
              fontWeight: 500,
              "&.MuiButton-root.Mui-disabled": {
                background: "#252525",
                color: "#c5c5c5",
              },
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            Previous
          </Button>
          <Typography sx={{ mt: "5px", fontSize: "20px", fontWeight: 600 }}>
            {apiData?.page}
          </Typography>
          <Button
            onClick={handleNext}
            disabled={formData?.length < 10}
            sx={{
              px: { xs: "50px", md: "80px" },
              textTransform: "none",
              color: "white",
              fontSize: { xs: "18px", md: "20px" },
              borderRadius: "5px",
              backgroundColor: "#DD0000",
              marginTop: "10px",
              fontWeight: 500,
              "&.MuiButton-root.Mui-disabled": {
                background: "#252525",
                color: "#c5c5c5",
              },
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CampaignsList;
