import React from "react";

import { Box, Container, Grid, Typography, useTheme } from "@mui/material";

import CorevaluesImage1 from "../../../../assets/images/CoreValues1.png";
import CorevaluesImage2 from "../../../../assets/images/CoreValues2.png";
import CorevaluesImage3 from "../../../../assets/images/CoreValues3.png";
import CorevaluesImage4 from "../../../../assets/images/CoreValues4.png";

import Pulse from "../../../../themes/@overrides/pulse";
import { PADDING_X, BASE_IMAGE_URL } from "../../../../utils/constants";

const CoreValues = () => {
  const theme = useTheme();
  // const CorevaluesImage1 = `${BASE_IMAGE_URL}/CorevaluesImage1.svg`;
  // const CorevaluesImage2 = `${BASE_IMAGE_URL}/CorevaluesImage2.svg`;
  // const CorevaluesImage3 = `${BASE_IMAGE_URL}/CorevaluesImage3.svg`;
  // const CorevaluesImage4 = `${BASE_IMAGE_URL}/CorevaluesImage4.svg`;

  const borderStyle = {
    fontSize: "20px",
    fontWeight: "700px",
    fontFamily: theme.fonts.livvic,
    borderTop: "2px solid",
    borderBottom: "2px solid",
    borderImage:
      "linear-gradient(to right, rgba(134, 134, 134, 1),rgba(74, 74, 74, 1),rgba(255, 0, 0, 1),rgba(112, 111, 111, 0.96),rgba(255, 0, 0, 1))",
    borderImageSlice: 1,
    textAlign: "center",
    borderRadius: "5px",
    py: 4,
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "2px",
      background: "red",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      right: 0,
      top: 0,
      height: "100%",
      width: "2px",
      background: "red",
    },
  };

  return (
    <Box
      sx={{
        padding: { xs: "10px", sm: PADDING_X },
      }}
    >
      {/* --------------------------Heading--------------------- */}
      <Box marginBottom="30px">
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="left"
          alignItems="center"
          textAlign="center"
          gap={2}
        >
          <Pulse />
          <Typography
            color={theme.colors.text.greyDark}
            fontFamily={theme.fonts.russoOne}
            fontSize={{
              xs: theme.fonts.heading_xs,
              sm: theme.fonts.heading,
              xl: theme.fonts.beforeLogin.heading.xl,
            }}
          >
            Core <span style={{ color: "red" }}>Values</span>
          </Typography>
        </Box>
      </Box>

      {/* -----------------------------Body------------------------- */}
      <Box
        sx={{
          px: { xs: "10px", sm: "20px" },
          py: { xs: "10px", sm: "20px" },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={9}
            sm={6}
            md={3}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            {/* -----------------------Content Box----------------------------- */}
            <Box sx={borderStyle}>Integrity</Box>

            {/* --------------------------Image Box------------------------------------ */}
            <Box
              sx={{
                borderRadius: "5px",
                border: "1px solid #414141",
                background: theme.colors.background.gradientBlackRedCorevalues,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "15%",
                }}
              >
                <img
                  src={CorevaluesImage1}
                  alt="images"
                  style={{
                    width: "66%",
                    height: "66%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={9}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "column-reverse" },
              gap: 2,
            }}
          >
            <Box sx={borderStyle}>Growth Mindset</Box>
            <Box
              sx={{
                borderRadius: "5px",
                border: "1px solid #414141",
                background: theme.colors.background.gradientBlackRedCorevalues,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "15%",
                }}
              >
                <img
                  src={CorevaluesImage3}
                  alt="images"
                  style={{
                    width: "60%",
                    height: "60%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={9}
            sm={6}
            md={3}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Box sx={borderStyle}>Creative Approach</Box>
            <Grid
              sx={{
                borderRadius: "5px",
                border: "1px solid #414141",
                background: theme.colors.background.gradientBlackRedCorevalues,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "15%",
                }}
              >
                <img
                  src={CorevaluesImage2}
                  alt="images"
                  style={{
                    width: "66.5%",
                    height: "66.5%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={9}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "column-reverse" },
              gap: 2,
            }}
          >
            <Box sx={borderStyle}>One Team</Box>
            <Grid
              sx={{
                borderRadius: "5px",
                border: "1px solid #414141",
                background: theme.colors.background.gradientBlackRedCorevalues,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "15%",
                }}
              >
                <img
                  src={CorevaluesImage4}
                  alt="images"
                  style={{
                    width: "79%",
                    height: "79%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CoreValues;
