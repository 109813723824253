import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
  useTheme,
} from "@mui/material";

// import CampaignBrand from "../../../../../../assets/images/CampaignBrand.png";
// import CampaignBrandBackground from "../../../../../../assets/images/CampaignBrandBackground.png";
import DefaultBrandLogo from "../../../../../../assets/logo/DefaultBrandLogo.png";

import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import dayjs from "dayjs";
import { ChevronRight } from "@mui/icons-material";
import {
  applyToCampaign,
  withdrawFromCampaign,
} from "../../../../../../services/campaign/campaign.service";
import { useNavigate } from "react-router-dom";
import CustomSkeleton from "../../../../../../themes/@overrides/customSkeleton";
import ConnectPlaidAccModal from "../../connectPlaidAcc.modal";

const CampaignsCard = ({ detail, isAllDataLoading }) => {
  const theme = useTheme();
  // const CampaignBrand = `${BASE_IMAGE_URL}/CampaignBrand.png`;
  // const CampaignBrandBackground = `${BASE_IMAGE_URL}/CampaignBrandBackground.png`;
  const AIRecommended = `${BASE_IMAGE_URL}/AIRecommended.png`;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isApplied, setIsApplied] = useState(null);
  const [isPlaidConnected, setIsPlaidConnected] = useState(false);
  const profileState = "dashbordPlaidConnectModal";

  useEffect(() => {
    if (detail) {
      setIsApplied(detail?.applied);
    }
  }, [detail]);

  const handleImageError = (e) => {
    e.target.src = DefaultBrandLogo;
  };

  const handlePlaidConnectedModalClose = () => {
    setIsPlaidConnected(false);
  };

  const confirmAction = async () => {
    return new Promise((resolve) => {
      const confirmed = window.confirm(
        "Are you sure you want to apply for this campaign?"
      );
      resolve(confirmed);
    });
  };

  const handleApplyNow = async () => {
    const confirmed = await confirmAction();
    if (confirmed) {
      setLoading(true);
      const { data } = await applyToCampaign(detail?._id);
      setLoading(false);
      if (data?.status === 200) {
        setIsApplied(true);
      } else if (data?.status === 400 && data?.data?.plaidAccount === false) {
        setIsPlaidConnected(true);
      }
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);
    const { data } = await withdrawFromCampaign(detail?._id, detail?.selected);
    if (data?.status === 200) setIsApplied(false);
    setLoading(false);
  };

  const backState = "home";

  const handleViewDetails = () => {
    localStorage.setItem("back", backState);
    navigate(`/dashboard/campaigns/view-details/${detail?._id}`);
  };

  return (
    <Box
      sx={{
        mx: "auto",
        mb: "30px",
        border: `1px solid grey`,
        borderRadius: "5px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          background: "rgba(37,37,37,1)",
          p: "15px",
          height: "120px",
        }}
      >
        {isAllDataLoading ? (
          <CustomSkeleton type="img" sx={{ width: "140px", height: "100%" }} />
        ) : (
          <Box
            sx={{
              width: "120px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={detail?.brandLogo}
              alt="Brand Logo"
              id="brandLogo"
              onError={handleImageError}
              sx={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            ></Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "calc(100% - 120px)",
          }}
        >
          <Box sx={{ height: "20px" }}></Box>
          <Typography
            fontSize={theme.fonts.cardSubHeading}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
          >
            {isAllDataLoading ? <CustomSkeleton type="p" /> : detail?.brandName}
          </Typography>

          <Typography
            sx={{
              fontFamily: theme.fonts.livvic,
              fontSize: "14px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.endDate && (
                <>
                  <span style={{ color: "rgba(217,217,217,1)" }}>
                    {" "}
                    End Date
                  </span>
                  -{` ${dayjs(detail?.endDate).format("MMM DD YYYY")}`}
                </>
              )
            )}
          </Typography>
        </Box>

        {isAllDataLoading ? (
          <CustomSkeleton
            type="img"
            sx={{
              height: "30px",
              width: "120px",
              position: "absolute",
              top: "5px",
              right: "0",
            }}
          />
        ) : (
          <Box
            component="img"
            src={AIRecommended}
            alt="AI Recommended"
            id="aiRecommended"
            sx={{
              height: "auto",
              width: "120px",
              position: "absolute",
              top: "0px",
              right: "0",
            }}
          ></Box>
        )}
      </Box>

      {/* -------------------------Below Image Box---------------------------- */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: "15px",
        }}
      >
        <Typography
          fontSize={theme.fonts.cardSubHeading}
          fontWeight={theme.fonts.fontWeightBold}
          fontFamily={theme.fonts.livvic}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            detail?.campaignName
          )}
        </Typography>
        {/* <Typography
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            height: "50px",
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum is
          simply dummy text of the printing.
        </Typography> */}
        <Typography
          fontSize={theme.fonts.cardHighlights}
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            // gap: 1,
          }}
        >
          Compensation{" "}
          <span
            style={{
              color: "red",
              textAlign: "right",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : detail?.sharePercentage ? (
              `${detail?.sharePercentage.toFixed(2)}% of monthly revenue.`
            ) : (
              `$${detail?.totalCompensation}`
            )}
          </span>
        </Typography>
        <Box
          color={theme.colors.text.greyLight}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Typography
            fontSize={theme.fonts.cardHighlights}
            // fontWeight={theme.fonts.fontWeightMedium}
            fontFamily={theme.fonts.livvic}
          >
            Activities
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontFamily: theme.fonts.livvic,
            }}
          >
            {/* {detail?.activities?.map((activity, index) => {
              const activitiesLength = detail?.activities?.length;
              const remainingActivities = activitiesLength - 2;
              return index < 2 ? (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid grey",
                    p: "4px 10px",
                    borderRadius: "2px",
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: {
                      xs: activitiesLength <= 2 ? "90%" : "10px",
                      sm: activitiesLength <= 2 ? "90%" : "10px",
                    },
                  }}
                >
                  {activity}
                </Box>
              ) : index === 2 ? (
                <Box
                  key={index}
                  sx={{
                    border: "1px solid grey",
                    p: "4px 10px",
                    borderRadius: "2px",
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: {
                      xs: activitiesLength <= 2 ? "90%" : "10px",
                      sm: activitiesLength <= 2 ? "90%" : "10px",
                    },
                  }}
                >
                  {`+${remainingActivities} Other${
                    remainingActivities !== 1 ? "s" : ""
                  }`}
                </Box>
              ) : null;
            })} */}
            {isAllDataLoading ? (
              <CustomSkeleton type="p" />
            ) : (
              detail?.activities?.map((activity, index) => {
                const activitiesLength = detail?.activities?.length;
                const remainingActivities = activitiesLength - 1;
                return index < 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: "12px",
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {activity}
                  </Box>
                ) : index === 1 ? (
                  <Box
                    key={index}
                    sx={{
                      border: "1px solid grey",
                      p: "4px 10px",
                      borderRadius: "2px",
                      fontSize: "12px",
                      maxWidth: "90px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`+${remainingActivities} Other${
                      remainingActivities !== 1 ? "s" : ""
                    }`}
                  </Box>
                ) : null;
              })
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent:
              detail?.signed || !detail?.selected ? "space-between" : "right",
            alignItems: "center",
            height: "40px",
            mt: 1.5,
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            (detail?.signed || !detail?.selected) && (
              <Button
                variant="contained"
                disabled={detail?.signed}
                sx={{
                  background: "red",
                  maxWidth: "150px",
                  height: "40px",
                  textTransform: "none",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: { xs: "16px", sm: "18px" },
                  fontFamily: theme.fonts.livvic,
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                  "@media (max-width:350px)": {
                    maxWidth: "100px",
                    fontSize: "12px",
                    height: "35px",
                  },
                }}
                onClick={isApplied ? handleWithdraw : handleApplyNow}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : detail?.signed ? (
                  "Signed"
                ) : isApplied ? (
                  "Withdraw"
                ) : (
                  "Apply Now"
                )}
              </Button>
            )
          )}

          {isAllDataLoading ? (
            <CustomSkeleton type="p" />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "rgba(217,217,217,1)",
                cursor: "pointer",
                float: "right",
              }}
              onClick={handleViewDetails}
            >
              <Typography fontFamily={theme.fonts.livvic}>
                View Details
              </Typography>
              <ChevronRight />
            </Box>
          )}
        </Box>
      </Box>
      <Dialog
        open={isPlaidConnected}
        onClose={handlePlaidConnectedModalClose}
        PaperProps={{
          sx: {
            width: "100vw",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <ConnectPlaidAccModal
          profileState={profileState}
          handleClose={handlePlaidConnectedModalClose}
        />
      </Dialog>
    </Box>
  );
};

export default CampaignsCard;
