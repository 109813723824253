import { articleFeatured, getListApi } from "./api.request";

const ARTICLE_PATH_FEATURED = "/article/featuredArticles";
const ARTICLES_LIST_PATH = "/article/beehiivArticleList";
const ARTICLES_DETAILS_PATH = "/article/beehiivArticleDetails";

export const featuredArticles = async () =>
  articleFeatured(`${ARTICLE_PATH_FEATURED}`);

export const getArticlesList = async () => getListApi(`${ARTICLES_LIST_PATH}`);

export const getArticlesDetails = async (articleId) =>
  getListApi(`${ARTICLES_DETAILS_PATH}?articleId=${articleId}&maxWidth=900px`);
