import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";

import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import CongratulationsIcon from "../../../../../../assets/icons/CongratulationsIcon.svg";
import AI_Icon from "../../../../../../assets/icons/AI_Icon.svg";
import ErrorIcon from "../../../../../../assets/icons/ErrorIcon.svg";
import BlueCrossIcon from "../../../../../../assets/icons/BlueCrossIcon.svg";
import dayjs from "dayjs";
import { getContractDetails } from "../../../../../../services/campaign/campaign.service";

const CongratulationsModal = ({ handleCongratulationsModalClose }) => {
  const theme = useTheme();
  const location = useLocation();

  const [contractDetails, setContractDetails] = useState(null);
  const fetchContractDetails = async () => {
    const response = await getContractDetails(
      location?.state?.campaignDetails?._id
    );
    setContractDetails(response?.data?.data);
  };

  useEffect(() => {
    fetchContractDetails();
  }, []);

  return (
    <DialogContent
      sx={{
        px: "30px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "grey",
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleCongratulationsModalClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "center",
          textAlign: "center",
          gap: 1,
        }}
      >
        <Box
          component="img"
          src={CongratulationsIcon}
          alt="Congratulations Icon"
          width="180px"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h5"
        >
          Congratulations!
        </Typography>
        <Typography fontFamily={theme.fonts.livvic}>
          Your contract file is uploaded
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: "100%",
            border: `1px solid ${theme.colors.text.greyDarker}`,
            padding: "5px 10px",
            background: "rgba(29,29,29,1)",
            my: 1,
          }}
        >
          <Box component="img" src={AI_Icon} alt="AI Icon"></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontWeight: theme.fonts.fontWeightMedium,
                fontSize: "20px",
              }}
            >
              AI suggestions for the uploaded Contract File
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                color: "rgba(197,197,197,1)",
              }}
            >
              Idea is to keep it simple and easy to understand
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            background: "black",
            p: "20px",
            width: "100%",
            border: "1px solid grey",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontWeight: theme.fonts.fontWeightMedium,
                fontSize: "20px",
              }}
            >
              Status
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                width: "140px",
                background: "rgba(217,255,217,0.58)",
                color: "rgba(7,93,5,1)",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(7,93,5,1)",
                }}
              >
                <DoneIcon
                  sx={{
                    color: "white",
                    zIndex: 1,
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                  fontSize: "20px",
                }}
              >
                Success
              </Typography>
            </Box>
          </Box>

          {/* -----------Status Content---------------------- */}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", sm: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                textAlign: { xs: "left", sm: "right" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionSubHeading,
                  color: theme.colors.text.greyLight,
                }}
              >
                From
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionBodyText,
                  wordBreak: "break-all",
                }}
              >
                {contractDetails?.transaction?.owner}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", sm: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                textAlign: { xs: "left", sm: "right" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionSubHeading,
                  color: theme.colors.text.greyLight,
                }}
              >
                To
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionBodyText,
                  wordBreak: "break-all",
                }}
              >
                {contractDetails?.transaction?.id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", sm: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                textAlign: { xs: "left", sm: "right" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionSubHeading,
                  color: theme.colors.text.greyLight,
                }}
              >
                Timestamp
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionBodyText,
                  wordBreak: "break-all",
                }}
              >
                {dayjs(contractDetails?.transaction?.createdAt).format(
                  "MMM DD YYYY hh:mm:ss"
                )}
              </Typography>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", sm: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                textAlign: { xs: "left", sm: "right" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionSubHeading,
                  color: theme.colors.text.greyLight,
                }}
              >
                Height
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionBodyText,
                  wordBreak: "break-all",
                }}
              >
                1,234,876
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", sm: "flex" },
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                textAlign: { xs: "left", sm: "right" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionSubHeading,
                  color: theme.colors.text.greyLight,
                }}
              >
                Size
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: theme.fonts.sectionBodyText,
                  wordBreak: "break-all",
                }}
              >
                {`${contractDetails?.transaction?.data_size} MB`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default CongratulationsModal;
