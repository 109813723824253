import { getApi, updateApi } from "./api.request";

const notificationPath = "/notification/notificationsList";
const notificationMarkReadPath = "/notification/markRead";

export const getNotifications = async () => {
  return getApi(`${notificationPath}`);
};
export const markRead = async () => {
  return updateApi(`${notificationMarkReadPath}`);
};
