import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";

import LatestArticles from "../../../../assets/images/LatestArticles.webp";

import ArticlesUserIcon from "../../../../assets/icons/ArticlesUserIcon.svg";
import ArticlesCalendarIcon from "../../../../assets/icons/ArticlesCalendarIcon.svg";
import LeftArrow from "../../../../assets/icons/LeftArrow.svg";

import { BASE_IMAGE_URL } from "../../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { getArticlesDetails } from "../../../../services/articles/article.service";
import dayjs from "dayjs";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";

const ArticlesDetails = () => {
  const theme = useTheme();

  const { newsletterId } = useParams();
  const navigate = useNavigate();

  const [articlesDetails, setArticlesDetails] = useState({});
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleImageError = (e) => {
    e.target.src = LatestArticles;
  };

  const handleBack = () => {
    navigate(-1);
  };

  const fetchArticlesDetails = async () => {
    setIsAllDataLoading(true);
    const { data } = await getArticlesDetails(newsletterId);
    setIsAllDataLoading(false);
    setArticlesDetails(data);
  };

  useEffect(() => {
    fetchArticlesDetails();
  }, []);

  return (
    <Box
      sx={{
        paddingX: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
        pb: "20px",
      }}
    >
      <Box
        component="img"
        src={LeftArrow}
        alt="Left Arrow"
        sx={{ cursor: "pointer", width: "30px", mb: 3 }}
        onClick={handleBack}
      ></Box>

      {isAllDataLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "900px" }}>
            <CustomSkeleton type="p" sx={{ minWidth: "100%" }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                mt: 1,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CustomSkeleton type="circle" />
                <CustomSkeleton type="p" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CustomSkeleton type="circle" />
                <CustomSkeleton type="circle" />
                <CustomSkeleton type="circle" />
              </Box>
            </Box>
            <CustomSkeleton
              sx={{ width: "100%", maxWidth: "300px", height: "100px", mb: 2 }}
            />
            <CustomSkeleton type="p" sx={{ minWidth: "80%" }} />
            <CustomSkeleton sx={{ width: "100%", height: "300px", my: 2 }} />
          </Box>
        </Box>
      ) : (
        <Typography
          fontFamily={theme.fonts.livvic}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            wordSpacing: 1.5,
          }}
          dangerouslySetInnerHTML={{
            __html: articlesDetails?.articleContent,
          }}
        ></Typography>
      )}
    </Box>
  );
};

export default ArticlesDetails;
