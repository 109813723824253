import React, { useState } from "react";
import {
  Box,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import {
  campaignEditRevenue,
  campaignRevenueMakePayment,
} from "../../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";

const MonthlyRevenueModal = ({
  handleDialogClose,
  details,
  callApi,
  paymentModel,
  deliverableId,
}) => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    deliverableId: details?._id,
    revenueId: "",
    amount: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editableRevenueId, setEditableRevenueId] = useState(null);
  const [makePayment, setMakePayment] = useState({
    deliverableId: deliverableId,
    revenueId: "",
  });

  const handlePayNow = async (revenueId) => {
    setMakePayment({
      ...makePayment,
      revenueId: revenueId,
    });
    try {
      const response = await campaignRevenueMakePayment({
        deliverableId: deliverableId,
        revenueId: revenueId,
      });
      if (response.status === 200) {
        toast.success("Payment done");
        callApi();
      } else {
        toast.error(response.message || response.status);
      }
    } catch (error) {
      toast.error(error.message || error.status);
    }
  };

  const handleEditClick = (revenueId, currentAmount) => {
    setEditMode(true);
    setEditableRevenueId(revenueId);
    setFormData({
      ...formData,
      revenueId: revenueId,
      amount: currentAmount,
    });
  };

  const handleDoneClick = async () => {
    try {
      const response = await campaignEditRevenue(formData);
      if (response.status === 200) {
        setEditMode(false);
        setFormData({
          ...formData,
          revenueId: "",
          amount: "",
        });
        setEditableRevenueId(null);
        toast.success("Revenue edited successfully");
        callApi();
      } else {
        toast.error(response.message || response.status);
      }
    } catch (error) {
      toast.error(error.message || error.status);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      amount: e.target.value,
    });
  };

  return (
    <DialogContent>
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer", width: "15px" }}
        onClick={handleDialogClose}
      ></Box>
      <Box>
        <Typography
          fontWeight={theme.fonts.fontWeightBold}
          fontFamily={theme.fonts.livvic}
          my="10px"
          sx={{ fontSize: { xs: "22px", md: "26px" } }}
        >
          Manage monthly revenue
        </Typography>
        <Box display="flex" flexWrap="wrap">
          {details?.revenues?.map((data, index) => (
            <Box key={index} ml="15px" mt="15px">
              <Typography>
                {dayjs(data?.date).locale("en").format("MMMM YYYY")}
              </Typography>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <TextField
                  name="compensation"
                  placeholder="Amount"
                  autoComplete="off"
                  value={
                    editableRevenueId === data._id
                      ? formData.amount
                      : data.amount
                  }
                  onChange={handleInputChange}
                  //disabled={!editMode || editableRevenueId !== data._id}
                  // value={formData.compensation}
                  // onChange={(e) =>
                  //   handleSelectionsChange("compensation", e.target.value)
                  // }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          color: theme.colors.common.white,
                        }}
                      >
                        <Typography
                          sx={{
                            borderRight: `1px solid ${theme.colors.text.greyLight}`,
                            pr: "8px",
                            fontSize: "18px",
                            fontWeight: theme.fonts.fontWeightMedium,
                          }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    sx: {
                      border: `1px solid ${theme.colors.text.greyLight}`,
                      background: theme.colors.background.formInput,

                      color: theme.colors.common.white,
                      borderRadius: theme.fonts.smallBorderRadius,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    width: "180px",
                    mt: "5px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                  }}
                />
                {editableRevenueId === data._id ? (
                  <Box
                    sx={{
                      backgroundColor: "#FF0101",
                      padding: "13px",
                      borderRadius: "5px",
                      mt: "5px",
                      ml: "10px",
                    }}
                    onClick={handleDoneClick}
                  >
                    <DoneOutlinedIcon
                      sx={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#FF0101",
                      padding: "14px",
                      borderRadius: "5px",
                      mt: "5px",
                      ml: "10px",
                    }}
                    onClick={() => handleEditClick(data._id, data.amount)}
                  >
                    <EditIcon sx={{ cursor: "pointer" }} />
                  </Box>
                )}
                {paymentModel === "Revenue Share" &&
                  (data.athletePaid ? (
                    <Box
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        fontWeight: 500,
                        cursor: "pointer",
                        backgroundColor: "#797979",
                        padding: "16px",
                        px: "32px",
                        borderRadius: "5px",
                        mt: "5px",
                        ml: "10px",
                      }}
                    >
                      Paid
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        fontWeight: 500,
                        cursor: "pointer",
                        backgroundColor: "#FF0101",
                        padding: "16px",
                        borderRadius: "5px",
                        mt: "5px",
                        ml: "10px",
                      }}
                      onClick={() => handlePayNow(data._id)}
                    >
                      Pay now
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
        </Box>
        {details?.revenues?.length === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography
              fontWeight={theme.fonts.fontWeightRegular}
              fontFamily={theme.fonts.livvic}
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                mt: "50px",
              }}
            >
              There is no monthly revenue
            </Typography>
          </Box>
        )}
      </Box>
    </DialogContent>
  );
};

export default MonthlyRevenueModal;
