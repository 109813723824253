import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";

import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import ConfirmationIcon from "../../../../../../assets/icons/ConfirmationIcon.svg";
import { useState } from "react";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { signContract } from "../../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";

const ConfirmationModal = ({
  handleModalClose,
  setCongratulationsModalOpen,
  uploadedData,
  campaignDetails,
  signatureData,
  isCanvasEmpty,
}) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (uploadedData?.name && campaignDetails?._id) {
      const response = await signContract({
        campaignId: campaignDetails?._id,
        signedDocument: uploadedData,
      });
      if (response?.data?.status === 200) {
        handleModalClose();
        setCongratulationsModalOpen(true);
      } else toast.error(response);
    } else if (!isCanvasEmpty && campaignDetails?._id) {
      const formData = new FormData();
      const file = dataURLtoBlob(signatureData);
      formData.append("signedDocument", file, "signature.png");
      formData.append("campaignId", campaignDetails._id);
      setIsLoading(true);
      const response = await signContract(formData);
      setIsLoading(false);
      if (response?.data?.status === 200) {
        handleModalClose();
        setCongratulationsModalOpen(true);
      } else toast.error(response);
    }
  };

  return (
    <DialogContent
      sx={{
        p: "40px",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "grey",
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="40px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleModalClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "center",
          textAlign: "center",
          gap: 1,
        }}
      >
        <Box
          component="img"
          src={ConfirmationIcon}
          alt="Confirmation Icon"
          width="180px"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h5"
        >
          Are you sure?
        </Typography>
        <Typography fontFamily={theme.fonts.livvic}>
          You are about to sign a contract with the brand. This is a binding
          agreement that requires you to complete the campaign and provide proof
          of your work. Are you sure you want to proceed?
        </Typography>
        <Button
          fullWidth
          disabled={isLoading}
          sx={{
            background: theme.colors.background.registerButton,
            color: "white",
            height: "50px",
            maxWidth: "400px",
            fontSize: "22px",
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            borderRadius: "10px",
            mt: 3,
            py: 3.5,
            "&:hover": {
              background: theme.colors.divider.red,
            },
            "&.MuiButton-root.Mui-disabled": {
              background: "rgba(255,0,0,0.5)",
              color: "white",
            },
          }}
          onClick={handleSubmit}
        >
          {isLoading ? <CircularProgress sx={{ color: "white" }} /> : "Submit"}
        </Button>
      </Box>
    </DialogContent>
  );
};

export default ConfirmationModal;
