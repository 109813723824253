const Joi = require("joi");

const Schema = Joi.object({
  firstName: Joi.string().trim().required().min(3).max(20).messages({
    "any.required": `First Name is required.`,
    "string.empty": `First Name is required.`,
    "string.min": "First Name must be of atleast 3 characters.",
    "string.max": "First Name must not contain more than 20 characters.",
  }),
  lastName: Joi.string().trim().required().min(3).max(20).messages({
    "any.required": `Last Name is required.`,
    "string.empty": `Last Name is required.`,
    "string.min": "Last Name must be of atleast 3 characters.",
    "string.max": "Last Name must not contain more than 20 characters.",
  }),
  email: Joi.string()
    .trim()
    .min(5)
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": `Email is required.`,
      "string.empty": `Email is required.`,
      "string.min": "Email must be of atleast 5 characters.",
      "string.max": "Email must not contain more than 50 characters.",
    }),
  message: Joi.string().trim().required().min(3).max(5000).messages({
    "any.required": `Message is required.`,
    "string.empty": `Message is required.`,
    "string.min": "Message must be of atleast 3 characters.",
    "string.max": "Message must not contain more than 5000 characters.",
  }),
});

export default Schema;
