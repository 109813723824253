import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Circle } from "@mui/icons-material";

import EducationBackground from "../../../../assets/images/EducationBackground.png";
import LeftArrow from "../../../../assets/icons/LeftArrow.svg";
import { getEarnings } from "../../../../services/accounts/accounts.service";
import dayjs from "dayjs";
import CustomSkeleton from "../../../../themes/@overrides/customSkeleton";

const Earnings = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const [earnings, setEarnings] = useState(null);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleDetailClick = (detail) => {
    // navigate("/dashboard/earnings/details", { state: { detail } });
    navigate(`/dashboard/campaigns/view-details/${detail?.campaignId}`);
  };

  const fetchEarningsBalance = async () => {
    const response = await getEarnings();
    setIsAllDataLoading(false);
    setEarnings(response?.data?.data);
  };

  useEffect(() => {
    fetchEarningsBalance();
  }, []);

  return (
    <Box
      sx={{
        px: { xs: "10px", sm: "30px" },
        mt: { xs: "95px", sm: "115px" },
        minHeight: { xs: "calc(100vh - 95px)", sm: "calc(100vh - 115px)" },
      }}
    >
      {/* -----------------Header------------------------- */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: "30px" }}>
        <Box
          component="img"
          src={LeftArrow}
          alt="Left Arrow"
          sx={{ cursor: "pointer", width: { xs: "30px", sm: "40px" } }}
          onClick={handleBack}
        ></Box>
        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontSize: { xs: theme.fonts.heading_xs, sm: theme.fonts.heading },
          }}
        >
          My Earnings
        </Typography>
      </Box>

      <Box
        p="20px"
        mb="30px"
        sx={{
          background: theme.colors.dashboard.grey200,
          overflow: "hidden",
        }}
      >
        {/* -------------------Total Earned------------------- */}
        <Box
          sx={{
            position: "relative",
            height: "200px",
            border: `1px solid ${theme.colors.text.greyLight}`,
            mb: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                fontSize: theme.fonts.subHeading,
                fontWeight: theme.fonts.fontWeightBold,
              }}
            >
              {isAllDataLoading ? (
                <CustomSkeleton
                  type="circle"
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              ) : (
                `$${earnings?.totalEarnings || "0"}`
              )}
            </Typography>
            <Typography
              sx={{
                fontFamily: theme.fonts.livvic,
                color: theme.colors.text.greyLight,
              }}
            >
              Total Earned
            </Typography>
          </Box>
        </Box>

        {/* ---------------------Transactions Container------------------------- */}

        <Typography
          sx={{
            fontFamily: theme.fonts.livvic,
            fontSize: {
              xs: theme.fonts.subHeading_xs,
              sm: theme.fonts.subHeading,
            },
            mb: 2,
          }}
        >
          Transactions
        </Typography>

        <Box
          sx={{
            // borderRadius: "10px",
            width: "100%",
          }}
        >
          {isAllDataLoading ? (
            <CustomSkeleton
              count={3}
              sx={{ width: "100%", height: "60px", mb: 1 }}
            />
          ) : (
            earnings?.transactions?.map((detail, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    borderTop:
                      index === 0 && `1px solid ${theme.colors.text.greyLight}`,
                    borderLeft: `1px solid ${theme.colors.text.greyLight}`,
                    borderRight: `1px solid ${theme.colors.text.greyLight}`,
                    borderBottom: `1px solid ${theme.colors.text.greyLight}`,
                    background: "black",
                    py: "15px",
                    px: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    overflow: "hidden",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  onClick={() => handleDetailClick(detail)}
                >
                  <Box
                    sx={{
                      width: { xs: "85%", sm: "90%" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        mb: "2px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "noWrap",
                      }}
                    >
                      {`${detail?.deliverable?.activities} - ${detail?.brandName} - `}
                      <span style={{ color: theme.colors.text.greyLight }}>
                        {detail?.campaignName}
                      </span>
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        color: theme.colors.text.greyLight,
                        fontSize: "12px",
                      }}
                    >
                      {`Credited on
                   ${dayjs(detail?.createdAt).format("MM-DD-YYYY hh:mm:ss")}`}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      width: { xs: "15%", sm: "10%" },
                      fontFamily: theme.fonts.livvic,
                      color: "rgba(16, 210, 12, 1)",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    {`$${detail?.amount}`}
                  </Typography>
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Earnings;
